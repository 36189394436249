import { createApi } from "../../utils/createApi";

const apiResources = [
  [`group/?page_size=2027`, "groupArray"],
  [`group/tree/?page_size=1000`, "groupTrees"],
  [`group/?page_size=10000`, "parentGroups"],
];

async function getResource(resource: string[]) {
  const AxiosUI = createApi(``);
  const { data } = await AxiosUI.get(resource[0]);
  localStorage.setItem(`${resource[1]}`, JSON.stringify(data.results));
}

export async function getUpdatedSitesPageInfo() {
  const apiPromises = apiResources.map(getResource);
  await Promise.all(apiPromises);
}
