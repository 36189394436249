// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import {
  AllAlertsData,
  FalsePositiveData,
  LoadingData,
  ParentGroupsAlertData,
  PercetageOfMotionData,
} from "./analyticsInterfaces";
import { getMax7Array } from "./getMax7Array";
import { getConversionArray } from "./getConversionArray";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { handleGetDownloadURL } from "./handleGetDownloadURL";

export const getAllAnalyticsData = (
  setAllAlertsData: Dispatcher<AllAlertsData[]>,
  groupId: string,
  start_date: string,
  end_date: string,
  siteIdsArray: string[],
  cameraIdsArray: string[],
  navigate: NavigateFunction,
  setLoadingData: Dispatcher<LoadingData>,
  setParentGroupsAlertsData: Dispatcher<ParentGroupsAlertData[]>,
  siteIds: string[],
  setDisplayOnLeftBool: Dispatcher<boolean>,
  setVisibleArray: Dispatcher<any>,
  setConversionArray: Dispatcher<any>,
  setShowMoreVisible: Dispatcher<any>,
  setShowMoreOpen: Dispatcher<boolean>,
  userFilters: any,
  setAlertsGraphName: any,
  setFalsePostiveData: Dispatcher<FalsePositiveData[]>,
  parent_group: string,
  setPercetageOfMotionData: Dispatcher<PercetageOfMotionData[]>,
  setAveragePercentageOfMotion: Dispatcher<number>,
  setParentAccountsLoading: Dispatcher<boolean>,
  setParentAccounts: any,
  setParentIds: any,
  setParentNames: any,
  setDownloadURL: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getAllAnalyticsData(
      setAllAlertsData,
      groupId,
      start_date,
      end_date,
      siteIdsArray,
      cameraIdsArray,
      navigate,
      setLoadingData,
      setParentGroupsAlertsData,
      siteIds,
      setDisplayOnLeftBool,
      setVisibleArray,
      setConversionArray,
      setShowMoreVisible,
      setShowMoreOpen,
      userFilters,
      setAlertsGraphName,
      setFalsePostiveData,
      parent_group,
      setPercetageOfMotionData,
      setAveragePercentageOfMotion,
      setParentAccountsLoading,
      setParentAccounts,
      setParentIds,
      setParentNames,
      setDownloadURL
    );
  };

  let searchParamsString = ``;

  if (!groupId && !end_date && start_date) {
    searchParamsString = `?start_date=${start_date}`;
  }
  if (!groupId && end_date && !start_date) {
    searchParamsString = `?end_date=${end_date}`;
  }
  if (groupId && !end_date && start_date) {
    searchParamsString = `?group=${groupId}&start_date=${start_date}`;
  }
  if (groupId && end_date && !start_date) {
    searchParamsString = `?group=${groupId}&end_date=${end_date}`;
  }
  if (groupId && end_date && start_date) {
    searchParamsString = `?group=${groupId}&start_date=${start_date}&end_date=${end_date}`;
  }
  if (!groupId && end_date && start_date) {
    searchParamsString = `?start_date=${start_date}&end_date=${end_date}`;
  }
  if (siteIdsArray[0]) {
    let additionalSiteString = `&site=${siteIdsArray[0]}`;
    if (siteIdsArray.length > 1) {
      siteIdsArray.forEach((siteId: string, index: number) => {
        if (index !== 0) {
          additionalSiteString += `,${siteId}`;
        }
      });
    }
    searchParamsString += additionalSiteString;
  }
  if (cameraIdsArray[0]) {
    let additionalCamerasString = `${cameraIdsArray[0]}`;
    if (cameraIdsArray.length > 1) {
      cameraIdsArray.forEach((cameraId: string, index: number) => {
        if (index !== 0) {
          additionalCamerasString += `,${cameraId}`;
        }
      });
    }
    searchParamsString = `?camera=${additionalCamerasString}&start_date=${start_date}&end_date=${end_date}`;
  }

  const parentIds = new Map();
  const parentNames = new Map();

  AxiosUI.get(`group/?parent_account=true&page_size=10000`).then(
    (response: AxiosResponse) => {
      setParentAccounts(response.data.results);
      response.data.results.forEach((object: any) => {
        parentIds.set(object.name, object.id);
      });
      response.data.results.forEach((object: any) => {
        parentNames.set(object.id, object.name);
      });
      setParentIds(parentIds);
      setParentNames(parentNames);
      setParentAccountsLoading(false);
      AxiosUI.get(`analytics/alert_volume/${searchParamsString}`).then(
        (response: AxiosResponse) => {
          setAllAlertsData(response.data);
          setLoadingData((previousState) => {
            return { ...previousState, allAlertsData: false };
          });
          AxiosUI.get(`analytics/alerts_by_parent/${searchParamsString}`).then(
            (response: AxiosResponse) => {
              const updatedData = response.data;
              for (const key in updatedData) {
                const updatedKey = updatedData[key];
                if (updatedKey.name.length > 4) {
                  updatedKey.abreviated_name = `${updatedKey.name.slice(
                    0,
                    3
                  )}...`;
                } else {
                  updatedKey.abreviated_name = updatedKey.name;
                }
              }
              setParentGroupsAlertsData(updatedData);
              setVisibleArray(
                getMax7Array(
                  getConversionArray(
                    updatedData,
                    setConversionArray,
                    setShowMoreVisible
                  )
                )
              );
              setShowMoreOpen(false);
              setLoadingData((previousState) => {
                return { ...previousState, parentGroupsData: false };
              });
              AxiosUI.get(
                `analytics/false_positive/${searchParamsString}`
              ).then(
                (response: AxiosResponse) => {
                  setFalsePostiveData(response.data);
                  setLoadingData((previousState) => {
                    return { ...previousState, falsePositiveData: false };
                  });
                  AxiosUI.get(
                    `analytics/percentage_motion_with_average/${searchParamsString}`
                  ).then(
                    (response: AxiosResponse) => {
                      const newDataArray: PercetageOfMotionData[] = [];
                      if (response.data.report) {
                        const data = response.data.report;
                        if (data[0]) {
                          data.forEach((object: PercetageOfMotionData) => {
                            const newObject = object;
                            const newPercentFiltered =
                              object.percentage_filtered.toFixed(2);
                            newObject.percentage_filtered =
                              Number(newPercentFiltered);
                            newDataArray.push(newObject);
                          });
                        }
                      }

                      if (response.data.average) {
                        setAveragePercentageOfMotion(response.data.average);
                      }
                      if (!response.data.average) {
                        setAveragePercentageOfMotion(0);
                      }
                      setPercetageOfMotionData(newDataArray);
                      setLoadingData((previousState) => {
                        return {
                          ...previousState,
                          percetageOfMotionData: false,
                        };
                      });
                      handleGetDownloadURL(
                        userFilters,
                        setDownloadURL,
                        navigate
                      );
                    },
                    (reason: AxiosError) => {
                      universalAPIErrorHandler(
                        reason,
                        navigate,
                        retryFunction,
                        () => {
                          setLoadingData((previousState) => {
                            return {
                              ...previousState,
                              percetageOfMotionData: false,
                            };
                          });
                        }
                      );
                    }
                  );
                },
                (reason: AxiosError) => {
                  universalAPIErrorHandler(
                    reason,
                    navigate,
                    retryFunction,
                    () => {
                      setLoadingData((previousState) => {
                        return { ...previousState, falsePositiveData: false };
                      });
                    }
                  );
                }
              );
            },
            (reason: AxiosError) => {
              universalAPIErrorHandler(reason, navigate, retryFunction, () => {
                if (userFilters.parent_group === "") {
                  setAlertsGraphName("Parent Group");
                }
                if (userFilters.parent_group) {
                  setAlertsGraphName("Site");
                }
                if (userFilters.site[0]) {
                  setAlertsGraphName("Camera");
                }
                console.log(`${reason}`);
                setLoadingData((previousState) => {
                  return { ...previousState, parentGroupsData: false };
                });
              });
            }
          );
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setLoadingData((previousState) => {
              return { ...previousState, allAlertsData: false };
            });
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setParentAccounts([]);
        setParentAccountsLoading(false);
      });
    }
  );
};
