import React from "react";
import { temporaryProductRequirementData } from "../../utils/temporaryProductRequirementData";
import { Link } from "react-router-dom";
import { createApi } from "../../utils/createApi";
import { getRefreshToken } from "../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const createMinReqHelperText = (
  setHelperText: Dispatcher<any>,
  productsArray: any[],
  options: any,
  navigate: NavigateFunction
) => {
  const baseValues = {
    resolution: "480p",
    fps: 2,
    aspect_ratio: "4:3",
    codec: "H264",
  };

  const optionIds = new Map();

  options.forEach((array: any[]) => {
    optionIds.set(array[0], array[1]);
  });

  let numberString = ``;

  productsArray.forEach((name: string, index: number) => {
    if (index === 0) {
      numberString += `${optionIds.get(name)}`;
    } else {
      numberString += `,${optionIds.get(name)}`;
    }
  });

  const AxiosUI = createApi(``);

  const retryFunction = () => {
    createMinReqHelperText(setHelperText, productsArray, options, navigate);
  };

  temporaryProductRequirementData.forEach((object: any) => {
    if (productsArray.includes(object.product)) {
      if (
        Number(object.resolution.slice(0, 3)) >
        Number(baseValues.resolution.slice(0, 3))
      ) {
        baseValues.resolution = `${Number(object.resolution.slice(0, 3))}p`;
      }
      if (object.fps > baseValues.fps) {
        baseValues.fps = object.fps;
      }
      if (object.aspect_ratio !== "4:3") {
        baseValues.aspect_ratio === object.aspect_ratio;
      }
    }
  });

  if (productsArray.length === 1 && productsArray[0] === "Healthcheck") {
    setHelperText(``);
    return;
  } else if (location.href.includes("addcamera") && productsArray[0]) {
    AxiosUI.get(
      `product_requirements/per_products/?products=${numberString}`
    ).then(
      (response: AxiosResponse) => {
        const data = response.data;
        setHelperText(
          <>
            <p className="font-bold text-[13px] text-red-600">
              {`For the selected ${
                productsArray.length === 1 ? "detection" : "detections"
              } we suggest that your cameras are onboarded with the following settings:`}
            </p>
            <p className="font-bold text-[13px] text-red-600">
              &nbsp; {`- Resolution: ${data.height}p`}
            </p>
            <p className=" font-bold text-[13px] text-red-600">
              &nbsp; {`- Frame Rate: ${data.frame_rate_range}`}
            </p>
            <p className="font-bold text-[13px] text-red-600">
              &nbsp; {`- Aspect Ratio: ${data.aspect_ratio}`}
            </p>
            <p className=" font-bold text-[13px] text-red-600">
              &nbsp; {`- Codec: ${data.codec}`}
            </p>
            <div className="text-[#0000EE]">
              <Link to="/productrequirements" target="_blank">
                CLICK HERE to see all product requirements.
              </Link>
            </div>
          </>
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setHelperText(``);
        });
      }
    );
  } else {
    setHelperText(``);
  }
};
