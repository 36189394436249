import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { handleBulkGroupAction } from "./SitesPageUtils/handleBulkGroupAction";
import { NavigateFunction } from "react-router-dom";

const options = ["ARM", "DISARM", "SYNC"];

export const ActionButtonSitesPages = ({
  selected,
  renderedRows,
  setSiteScreenLoading,
  groupId,
  setPageLoading,
  setSelected,
  setRenderedRows,
  setSiteBreadcrumbs,
  setSiteScreenAlerts,
  navigate,
}: {
  selected: readonly string[];
  renderedRows: any;
  setSiteScreenLoading: any;
  groupId: string;
  setPageLoading: any;
  setSelected: any;
  setRenderedRows: any;
  setSiteBreadcrumbs: any;
  setSiteScreenAlerts: any;
  navigate: NavigateFunction;
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    if (selectedIndex === 0) {
      handleBulkGroupAction(
        "arm",
        selected,
        renderedRows,
        setSiteScreenLoading,
        groupId,
        setPageLoading,
        setSelected,
        setRenderedRows,
        setSiteBreadcrumbs,
        setSiteScreenAlerts,
        navigate
      );
    }
    if (selectedIndex === 1) {
      handleBulkGroupAction(
        "disarm",
        selected,
        renderedRows,
        setSiteScreenLoading,
        groupId,
        setPageLoading,
        setSelected,
        setRenderedRows,
        setSiteBreadcrumbs,
        setSiteScreenAlerts,
        navigate
      );
    }
    if (selectedIndex === 2) {
      handleBulkGroupAction(
        "deploy",
        selected,
        renderedRows,
        setSiteScreenLoading,
        groupId,
        setPageLoading,
        setSelected,
        setRenderedRows,
        setSiteBreadcrumbs,
        setSiteScreenAlerts,
        navigate
      );
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Site Group Actions"
        disabled={!selected[0]}
        color="warning"
      >
        <Button
          onClick={handleClick}
          sx={{
            backgroundColor: "#FF8400",
            "&:hover": { backgroundColor: "#E67300" },
          }}
        >
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select group action"
          aria-haspopup="menu"
          sx={{
            backgroundColor: "#FF8400",
            "&:hover": { backgroundColor: "#E67300" },
          }}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className="absolute"
                  id="split-button-menu"
                  autoFocusItem
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      // disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
