import React from "react";

export const handleCreateDuskToDawnLocationPlusBuffer = (
  siteSchedules: any
) => {
  const returnArray = [
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
  ];

  siteSchedules.forEach((object: any) => {
    object.day_of_week.forEach((day: string) => {
      const dayIndex = Number(day);
      if (object.location_dusk_dawn) {
        returnArray[dayIndex] = [object.location_dusk_dawn, object.buffer_time];
      }
    });
  });
  return returnArray;
};
