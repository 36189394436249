import React from "react";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import CheckIcon from "@mui/icons-material/Check";
import { handleActivateCamera } from "./SiteAboutPageUtils/handleActivateCamera";
import { useNavigate } from "react-router-dom";
import {
  AlertState,
  SiteCameras,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const ActivateButton = ({
  selected,
  setSiteCameras,
  setAlertState,
  setSelected,
  setSyncNeeded,
}: {
  selected: readonly string[];
  setSiteCameras: Dispatcher<SiteCameras>;
  setAlertState: Dispatcher<AlertState>;
  setSelected: Dispatcher<readonly string[]>;
  setSyncNeeded: Dispatcher<boolean>;
}) => {
  const text = (
    <>
      <CheckIcon fontSize="small" />
      &nbsp;ACTIVATE
    </>
  );
  const submittedObject = {
    camera_ids: selected,
  };
  const navigate = useNavigate();

  return (
    <OrangeTextButton
      disabled={!selected[0]}
      onClick={() => {
        handleActivateCamera(
          submittedObject,
          navigate,
          setSiteCameras,
          setAlertState,
          setSelected,
          setSyncNeeded
        );
      }}
      text={text}
    />
  );
};
