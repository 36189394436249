import React from "react";

export const handleAddOpenAlertSearchParams = (
  row: any,
  searchParams: any,
  setSearchParams: any
) => {
  const params = new URLSearchParams(searchParams);
  params.set(
    "open_alert",
    `${row.camera_id}-${row.error_type}-${row.created_timestamp}`
  );

  setSearchParams(params);
};
