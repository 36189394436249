import * as React from "react";
import { ModuleScreen } from "../../common/ModuleScreen";
import { IntegrationSelectionScreen } from "./IntegrationSelectionScreen";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { ImmixSetupScreen } from "./ImmixSetupScreen";
import ErrorBoundary from "../../../ErrorBoundary";
import { AddWebhookScreen } from "./AddWebhookScreen";

export const AddSite = ({
  groupTrees,
  setGroupTrees,
}: {
  groupTrees: any;
  setGroupTrees: Dispatcher<any>;
}) => {
  const [visibleScreen, setVisibleScreen] = React.useState(<></>);

  React.useEffect(() => {
    setVisibleScreen(
      // <AddWebhookScreen />
      <IntegrationSelectionScreen setVisibleScreen={setVisibleScreen} />
    );
    localStorage.removeItem("siteJustDeleted");
    localStorage.setItem("immixAlertsSite", "false");
    localStorage.setItem("sentinelAlertsSite", "true");
    localStorage.setItem("sureviewAlertsSite", "false");
    localStorage.setItem(
      "immixAlertPostObject",
      JSON.stringify({
        id: -1,
        immix_server: "",
        immix_port: "",
      })
    );
  }, []);

  return (
    <ModuleScreen
      text="Add Site"
      visibleScreen={visibleScreen}
      styling={
        "text-white absolute top-[1px] right-[485px] font-mulish text-[18px] p-3"
      }
      groupTrees={groupTrees}
      setGroupTrees={setGroupTrees}
    />
  );
};
