import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { ArrowDownward } from "@mui/icons-material";
import { getApiUrl } from "../../utils/getApiUrl";

export const DownloadReportButton = ({
  downloadURL,
}: {
  downloadURL: string;
}) => {
  return (
    <OrangeTextButton
      onClick={() => {}}
      text={
        <>
          <ArrowDownward />
          &nbsp;
          <a href={downloadURL} download>
            Download Report
          </a>
        </>
      }
      disabled={!downloadURL}
    />
  );
};
