import React from "react";
import { ParentGroupsAlertData } from "./analyticsInterfaces";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const getConversionArray = (
  parentGroupsAlertsData: ParentGroupsAlertData[],
  setConversionArray: any,
  setShowMoreVisible: Dispatcher<boolean>
) => {
  const conversionArray: {
    [x: string]: string | number;
    name: string;
    abreviated_name: string;
  }[] = [];

  parentGroupsAlertsData.forEach((object: ParentGroupsAlertData) => {
    if (!conversionArray[0]) {
      const pushedObject = {
        name: object.name,
        abreviated_name: object.abreviated_name,
        [object.label.name]: object.alert_volume,
      };
      conversionArray.push(pushedObject);
    }
    let nameExists = false;
    conversionArray.forEach((obj, index: number) => {
      if (object.name === obj.name) {
        nameExists = true;
        conversionArray[index][object.label.name] = object.alert_volume;
      }
      if (index === conversionArray.length - 1 && !nameExists) {
        conversionArray.push({
          name: object.name,
          abreviated_name: object.abreviated_name,
          [object.label.name]: object.alert_volume,
        });
      }
    });
  });

  if (conversionArray.length > 7) {
    setShowMoreVisible(true);
  }
  if (conversionArray.length < 8) {
    setShowMoreVisible(false);
  }
  setConversionArray(conversionArray);
  return conversionArray;
};
