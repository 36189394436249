import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { handleTurnOffCameraHealth } from "./SiteAboutPageUtils/handleTurnOffCameraHealth";
import { NavigateFunction } from "react-router-dom";

export const TurnOffCameraHealthDialog = ({
  turnOffHealthDialogOpen,
  healthCheckData,
  cameraHealthPatchObject,
  setCameraHealthPatchObject,
  setCameraHealthChangesMade,
  setActiveTab,
  setBackdropLoadingScreenOn,
  siteInfo,
  setAddCameraHealthDialogOpen,
  setSiteInfo,
  setTurnOffHealthDialogOpen,
  setEditMode,
  navigate,
}: {
  turnOffHealthDialogOpen: boolean;
  healthCheckData: any;
  cameraHealthPatchObject: any;
  setCameraHealthPatchObject: any;
  setCameraHealthChangesMade: Dispatcher<boolean>;
  setActiveTab: any;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  siteInfo: any;
  setAddCameraHealthDialogOpen: Dispatcher<boolean>;
  setSiteInfo: any;
  setTurnOffHealthDialogOpen: Dispatcher<boolean>;
  setEditMode: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  return (
    <Dialog
      open={turnOffHealthDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>
          Are you sure you&apos;d like to turn off Camera Health Monitoring for
          this site?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setTurnOffHealthDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />

        <OrangeTextButton
          onClick={() => {
            setTurnOffHealthDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleTurnOffCameraHealth(
              healthCheckData,
              cameraHealthPatchObject,
              setCameraHealthPatchObject,
              setCameraHealthChangesMade,
              setActiveTab,
              setBackdropLoadingScreenOn,
              siteInfo,
              setAddCameraHealthDialogOpen,
              setSiteInfo,
              setTurnOffHealthDialogOpen,
              setEditMode,
              navigate
            );
          }}
          text="Yes turn off"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
