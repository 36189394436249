import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { LoadingData, PercetageOfMotionData } from "./analyticsInterfaces";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getPercentageMotionFilteredData = (
  setPercetageOfMotionData: Dispatcher<PercetageOfMotionData[]>,
  setAveragePercentageOfMotion: Dispatcher<number>,
  navigate: NavigateFunction,
  setLoadingData: Dispatcher<LoadingData>,
  start_date: string,
  end_date: string,
  siteIdsArray: string[],
  cameraIdsArray: string[],
  parent_group: string
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    getPercentageMotionFilteredData(
      setPercetageOfMotionData,
      setAveragePercentageOfMotion,
      navigate,
      setLoadingData,
      start_date,
      end_date,
      siteIdsArray,
      cameraIdsArray,
      parent_group
    );
  };

  let searchParamsString = ``;
  if (
    !siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    !parent_group &&
    !end_date &&
    start_date
  ) {
    searchParamsString = `?start_date=${start_date}`;
  }
  if (
    !siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    !parent_group &&
    end_date &&
    !start_date
  ) {
    searchParamsString = `?end_date=${end_date}`;
  }
  if (
    !siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    !parent_group &&
    end_date &&
    start_date
  ) {
    searchParamsString = `?end_date=${end_date}&start_date=${start_date}`;
  }

  if (
    !siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    parent_group &&
    !end_date &&
    start_date
  ) {
    searchParamsString = `?group=${parent_group}&start_date=${start_date}`;
  }
  if (
    !siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    parent_group &&
    end_date &&
    !start_date
  ) {
    searchParamsString = `?group=${parent_group}&end_date=${end_date}`;
  }
  if (
    !siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    parent_group &&
    end_date &&
    start_date
  ) {
    searchParamsString = `?group=${parent_group}&end_date=${end_date}&start_date=${start_date}`;
  }

  if (
    parent_group &&
    siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    !end_date &&
    start_date
  ) {
    let additionalSitesString = `${siteIdsArray[0]}`;
    if (siteIdsArray.length > 1) {
      siteIdsArray.forEach((siteId: string, index: number) => {
        if (index !== 0) {
          additionalSitesString += `,${siteId}`;
        }
      });
    }
    searchParamsString = `?site=${additionalSitesString}&start_date=${start_date}`;
  }
  if (
    parent_group &&
    siteIdsArray[0] &&
    cameraIdsArray[0] &&
    !end_date &&
    start_date
  ) {
    let additionalCamerasString = `${cameraIdsArray[0]}`;
    if (cameraIdsArray.length > 1) {
      cameraIdsArray.forEach((cameraId: string, index: number) => {
        if (index !== 0) {
          additionalCamerasString += `,${cameraId}`;
        }
      });
    }
    searchParamsString = `?camera=${additionalCamerasString}&start_date=${start_date}`;
  }
  if (
    parent_group &&
    siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    end_date &&
    !start_date
  ) {
    let additionalSitesString = `${siteIdsArray[0]}`;
    if (siteIdsArray.length > 1) {
      siteIdsArray.forEach((siteId: string, index: number) => {
        if (index !== 0) {
          additionalSitesString += `,${siteId}`;
        }
      });
    }
    searchParamsString = `?site=${additionalSitesString}&end_date=${end_date}`;
  }
  if (
    parent_group &&
    siteIdsArray[0] &&
    cameraIdsArray[0] &&
    end_date &&
    !start_date
  ) {
    let additionalCamerasString = `${cameraIdsArray[0]}`;
    if (cameraIdsArray.length > 1) {
      cameraIdsArray.forEach((cameraId: string, index: number) => {
        if (index !== 0) {
          additionalCamerasString += `,${cameraId}`;
        }
      });
    }
    searchParamsString = `?camera=${additionalCamerasString}&end_date=${end_date}`;
  }
  if (
    parent_group &&
    siteIdsArray[0] &&
    !cameraIdsArray[0] &&
    end_date &&
    start_date
  ) {
    let additionalSitesString = `${siteIdsArray[0]}`;
    if (siteIdsArray.length > 1) {
      siteIdsArray.forEach((siteId: string, index: number) => {
        if (index !== 0) {
          additionalSitesString += `,${siteId}`;
        }
      });
    }
    searchParamsString = `?site=${additionalSitesString}&start_date=${start_date}&end_date=${end_date}`;
  }
  if (
    parent_group &&
    siteIdsArray[0] &&
    cameraIdsArray[0] &&
    end_date &&
    start_date
  ) {
    let additionalCamerasString = `${cameraIdsArray[0]}`;
    if (cameraIdsArray.length > 1) {
      cameraIdsArray.forEach((cameraId: string, index: number) => {
        if (index !== 0) {
          additionalCamerasString += `,${cameraId}`;
        }
      });
    }
    searchParamsString = `?camera=${additionalCamerasString}&start_date=${start_date}&end_date=${end_date}`;
  }

  setAveragePercentageOfMotion(NaN);

  AxiosUI.get(
    `analytics/percentage_motion_with_average/${searchParamsString}`
  ).then(
    (response: AxiosResponse) => {
      const newDataArray: PercetageOfMotionData[] = [];
      if (response.data.report) {
        const data = response.data.report;
        if (data[0]) {
          data.forEach((object: PercetageOfMotionData) => {
            const newObject = object;
            const newPercentFiltered = object.percentage_filtered.toFixed(2);
            newObject.percentage_filtered = Number(newPercentFiltered);
            newDataArray.push(newObject);
          });
        }
      }

      if (response.data.average) {
        setAveragePercentageOfMotion(response.data.average);
      }
      if (!response.data.average) {
        setAveragePercentageOfMotion(0);
      }
      setPercetageOfMotionData(newDataArray);
      setLoadingData((previousState) => {
        return { ...previousState, percetageOfMotionData: false };
      });
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setLoadingData((previousState) => {
          return { ...previousState, percetageOfMotionData: false };
        });
      });
    }
  );
};
