export const AllDataDummy = [
  {
    display_name: "Site 1",
    camera_name: "Camera 1",
    formatted_date: "10/23/2023, 05:21:11 PM",
    error_type: "connectivity",
    severity: "high",
    status: "resolved",
  },
  {
    display_name: "Site 2",
    camera_name: "Camera 2",
    formatted_date: "10/23/2023, 05:08:43 PM",
    error_type: "scene_change",
    severity: "medium",
    status: "pending",
  },
  {
    display_name: "Site 3",
    camera_name: "Camera 3",
    formatted_date: "10/23/2023, 04:08:43 PM",
    error_type: "recording_status",
    severity: "low",
    status: "pending",
  },
  {
    display_name: "Site 4",
    camera_name: "Camera 4",
    formatted_date: "10/22/2023, 03:03:43 PM",
    error_type: "motion_status",
    severity: "high",
    status: "resolved",
  },
  {
    display_name: "Site 5",
    camera_name: "Camera 5",
    formatted_date: "10/20/2023, 01:03:43 PM",
    error_type: "image_quality",
    severity: "medium",
    status: "ongoing",
  },
  {
    display_name: "Site 6",
    camera_name: "Camera 6",
    formatted_date: "10/19/2023, 11:03:43 PM",
    error_type: "stream_quality",
    severity: "low",
    status: "resolved",
  },
  {
    display_name: "Site 7",
    camera_name: "Camera 7",
    formatted_date: "10/19/2023, 01:03:43 PM",
    error_type: "license_info",
    severity: "low",
    status: "ongoing",
  },
];
