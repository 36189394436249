import React from "react";

export const handleConvertTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const estDateString = date.toLocaleString("en-US", {
    timeZone: "America/New_York",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return estDateString;
};
