import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { checkArmedStatus } from "./checkArmedStatus";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handleDisarmSite = async (
  setBackdropLoadingScreenOn: any,
  setSiteInfo: any,
  setAlertState: any,
  setArmedStatus: any,
  navigate: any,
  setArmButtonDisabled: any
) => {
  const AxiosUI = createApi(``);
  setBackdropLoadingScreenOn(true);
  const retryFunction = () => {
    handleDisarmSite(
      setBackdropLoadingScreenOn,
      setSiteInfo,
      setAlertState,
      setArmedStatus,
      navigate,
      setArmButtonDisabled
    );
  };
  let disarmSiteFail = false;

  await AxiosUI.post(`customer/${localStorage.siteId}/action/disarm/`).then(
    () => {
      disarmSiteFail = false;
      setAlertState((previousState: any) => {
        return {
          ...previousState,
          changeSuccess: false,
          activateSuccess: false,
          deactivateSuccess: false,
          disarmSuccess: true,
          armSuccess: false,
          syncSuccess: false,
          cameraDeleteSuccess: false,
          armFail: false,
        };
      });
    },
    (reason: AxiosError) => {
      disarmSiteFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setSiteInfo((previousState: any) => {
          return { ...previousState, armed: true };
        });
        setBackdropLoadingScreenOn(false);
        alert(`Error disarming site, please try again.`);
      });
    }
  );
  !disarmSiteFail &&
    (await AxiosUI.get(`customer/${localStorage.siteId}/about/`).then(
      (response: AxiosResponse) => {
        setArmedStatus(false);
        setSiteInfo(response.data);
        checkArmedStatus(
          response.data,
          setArmButtonDisabled,
          navigate,
          setSiteInfo,
          setArmedStatus
        );
        setBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
