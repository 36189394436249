import React from "react";

export const handleAddSeverityToHealthCheckSites = (healthCheckSites: any) => {
  const returnedHealthCheckSites = [...healthCheckSites];

  returnedHealthCheckSites.forEach((object: any, index: number) => {
    if (object.error_type === "connectivity") {
      returnedHealthCheckSites[index].severity = 1;
    }
    if (object.error_type === "image_quality") {
      returnedHealthCheckSites[index].severity = 2;
    }
    if (object.error_type === "motion_status") {
      returnedHealthCheckSites[index].severity = 2;
    }
    if (object.error_type === "recording_status") {
      returnedHealthCheckSites[index].severity = 2;
    }
    if (object.error_type === "scene_change") {
      returnedHealthCheckSites[index].severity = 2;
    }
    if (object.error_type === "stream_quality") {
      returnedHealthCheckSites[index].severity = 3;
    }
  });
  return returnedHealthCheckSites;
};
