import React from "react";

export const createCameraAlarmType = (defaultAlarmNumber: number) => {
  const alarmOptions = JSON.parse(localStorage.alarmOptions);

  const alarmNames = new Map();
  alarmOptions.forEach((object: any) => {
    alarmNames.set(object.value, object.name);
  });

  return alarmNames.get(defaultAlarmNumber);
};
