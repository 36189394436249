/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  cancelIconRed,
  cancelIconRedSmall,
} from "../../../common/cancelIconRed";
import {
  checkedCircleIcon,
  checkedCircleIconSmall,
} from "../../../common/checkedCircleIcon";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { timeSinceConvertor } from "./SiteAboutPageUtils/timeSinceConvertor";
import { roundToHundredth } from "./SiteAboutPageUtils/roundToHundredth";
import { pointsCreator } from "./SiteAboutPageUtils/pointsCreator";
import { pointsCreatorAiLink } from "./SiteAboutPageUtils/pointsCreatorAiLink";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

import {
  ResposneObject,
  IgnoreZone,
  SiteCamerasResult,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { createCameraSettingsIcon } from "./SiteAboutPageUtils/createCameraSettingsIcon";
import { createCameraViewIcon } from "./SiteAboutPageUtils/createCameraViewIcon";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { resizeImage } from "./SiteAboutPageUtils/resizeImage";
import { Tooltip } from "@mui/material";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const CameraInfoRow = ({
  obj,
  expanded,
  setBackdropLoadingScreenOn,
  screenWidth,
  siteInfo,
  permissions,
  srcImages,
}: {
  obj: SiteCamerasResult;
  expanded: boolean;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  screenWidth: number;
  siteInfo: any;
  permissions: any;
  srcImages: any;
}) => {
  const [allIgnoreZones, setAllIgnoreZones] = React.useState<IgnoreZone[]>([]);

  const [visibleIgnoreZones, setVisibleIgnoreZones] = React.useState<
    IgnoreZone[]
  >([]);

  const displayNewInfo = true;

  const editCameraText = (
    <>
      {permissions.edit_cameras ? (
        <>
          <EditIcon fontSize="small" />
          &nbsp;Edit Camera
        </>
      ) : (
        <>Full Camera Info</>
      )}
    </>
  );

  const navigate = useNavigate();

  const [responseObject, setResponseObject] = React.useState<ResposneObject>({
    connected: "",
    frame_since_launch: "",
    good_frame: "",
    blur_metric: "",
    native_frame_rate: "",
    frame_dimensions: "",
    last_motion: "",
    preview_url: "",
    health_status: "",
    is_blurry: false,
    codec: "",
    aspect_ratio: "",
    comments: "",
    camera_view: "",
    aspect_ratio_status: "unknown",
    frame_rate_status: "unknown",
    resolution_status: "unknown",
  });

  const aPICall = (
    navigate: NavigateFunction,
    id: string,
    setResponseObject: Dispatcher<ResposneObject>,
    setAllIgnoreZones: Dispatcher<IgnoreZone[]>,
    setVisibleIgnoreZones: Dispatcher<IgnoreZone[]>
  ) => {
    const AxiosUI = createApi(``);

    const retryFunction = () => {
      aPICall(
        navigate,
        id,
        setResponseObject,
        setAllIgnoreZones,
        setVisibleIgnoreZones
      );
    };
    AxiosUI.get(`/camera/${id}/status/`).then(
      (response: AxiosResponse) => {
        setResponseObject(response.data);
        localStorage.setItem(
          "preview_url",
          JSON.stringify(response.data.preview_url)
        );
        AxiosUI.get(`/camera/${obj.id}/ignore_zones/`).then(
          (response: AxiosResponse) => {
            setAllIgnoreZones(response.data);
            setVisibleIgnoreZones(response.data);
            localStorage.setItem("ignoreZones", JSON.stringify(response.data));
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
          }
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    );
  };

  React.useEffect(() => {
    obj.id &&
      aPICall(
        navigate,
        obj.id,
        setResponseObject,
        setAllIgnoreZones,
        setVisibleIgnoreZones
      );
  }, []);

  const [dimensions, setDimensions] = React.useState<{
    width: number;
    height: number;
  } | null>(null);

  // React.useEffect(() => {
  //   const name = `${obj.id}full`;
  //   if (srcImages[name]) {
  //     setDimensions(resizeImage(srcImages[name].frame_dimensions));
  //   }
  // }, [srcImages]);

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;

    setDimensions(resizeImage(naturalWidth, naturalHeight));
  };

  const oldInfoLayout = (
    <div>
      <div className="absolute top-[40px] left-[575px] w-[200px] font-mulish text-[20px] text-actuate-blue">
        General Information
      </div>
      {siteInfo.integration_type_name !== "SMTP_per_camera" && (
        <div className="absolute top-[80px] left-[575px] w-[200px]  font-mulish text-[16px] text-actuate-blue">
          {responseObject.connected ? checkedCircleIcon : cancelIconRed}
          &nbsp;&nbsp;Connected
        </div>
      )}
      <div className="absolute top-[110px] left-[575px] w-[200px]  font-mulish text-[16px] text-actuate-blue">
        {responseObject.frame_since_launch ? checkedCircleIcon : cancelIconRed}
        &nbsp;&nbsp;Frame Since Launch
      </div>
      <div className="absolute top-[140px] left-[575px] w-[200px]  font-mulish text-[16px] text-actuate-blue">
        {responseObject.good_frame ? checkedCircleIcon : cancelIconRed}
        &nbsp;&nbsp;Stream Stability
      </div>
      <div className="absolute top-[170px] left-[575px] w-[200px]  font-mulish text-[16px] text-actuate-blue">
        {!responseObject.is_blurry ? checkedCircleIcon : cancelIconRed}
        &nbsp;&nbsp;Blur Metric
      </div>
      <div className="absolute top-[210px] left-[575px] w-[200px]  font-mulish font-bold text-[16px] text-black">
        Native Frame Rate
      </div>
      <div className="absolute top-[235px] left-[575px] w-[200px]  font-mulish text-[16px] text-black">
        {responseObject.native_frame_rate
          ? roundToHundredth(Number(responseObject.native_frame_rate) / 100)
          : "--"}
      </div>
      <div className="absolute top-[265px] left-[575px] w-[200px]  font-mulish font-bold text-[16px] text-black">
        Frame Dimensions
      </div>
      <div className="absolute top-[290px] left-[575px] w-[200px]  font-mulish text-[16px] text-black">
        {responseObject.frame_dimensions
          ? responseObject.frame_dimensions
          : "--"}
      </div>
      <div className="absolute top-[320px] left-[575px] w-[200px]  font-mulish font-bold text-[16px] text-black">
        Last Motion Time
      </div>
      <div className="absolute top-[345px] left-[575px] w-[200px]  font-mulish text-[16px] text-black">
        {responseObject.last_motion
          ? timeSinceConvertor(Number(responseObject.last_motion))
          : "--"}
      </div>
      <div className="absolute top-[380px] left-[575px] w-[200px]  font-mulish font-bold text-[16px] text-black">
        Camera Start Time
      </div>
      <div className="absolute top-[405px] left-[575px] w-[200px]  font-mulish text-[16px] text-black">
        {responseObject.camera_start_time
          ? timeSinceConvertor(Number(responseObject.camera_start_time))
          : "--"}
      </div>
    </div>
  );

  const newInfoLayout = (
    <div>
      <div className="absolute top-[20px] left-[575px] w-[200px] font-mulish text-[18px] text-actuate-blue">
        General Information
      </div>
      {siteInfo.integration_type_name !== "SMTP_per_camera" && (
        <div className="absolute top-[60px] left-[575px] w-[200px]  font-mulish text-[14px] text-actuate-blue">
          {responseObject.connected
            ? checkedCircleIconSmall
            : cancelIconRedSmall}
          &nbsp;&nbsp;Connected
        </div>
      )}
      <div className="absolute top-[95px] left-[575px] w-[200px]  font-mulish text-[14px] text-actuate-blue">
        {responseObject.frame_since_launch
          ? checkedCircleIconSmall
          : cancelIconRedSmall}
        &nbsp;&nbsp;Frame Since Launch
      </div>
      <div className="absolute top-[130px] left-[575px] w-[200px]  font-mulish text-[14px] text-actuate-blue">
        {responseObject.good_frame
          ? checkedCircleIconSmall
          : cancelIconRedSmall}
        &nbsp;&nbsp;Stream Stability
      </div>
      <div className="absolute top-[165px] left-[575px] w-[200px]  font-mulish text-[14px] text-actuate-blue">
        {!responseObject.is_blurry
          ? checkedCircleIconSmall
          : cancelIconRedSmall}
        &nbsp;&nbsp;Blur Metric
      </div>
      <div className="absolute top-[215px] left-[575px] w-[200px] font-mulish text-[18px] text-actuate-blue">
        Camera Settings
      </div>
      <div className="absolute top-[255px] left-[570px] w-[200px]  font-mulish text-[14px] text-black">
        {createCameraSettingsIcon(
          responseObject.frame_rate_status,
          "Frame Rate",
          responseObject.comments,
          responseObject.camera_view,
          responseObject
        )}
        &nbsp;&nbsp;Frame Rate
      </div>
      <div className="absolute top-[255px] left-[700px] w-[200px]  font-mulish font-bold  text-[14px] text-black">
        {responseObject.native_frame_rate
          ? roundToHundredth(Number(responseObject.native_frame_rate) / 100)
          : "N/A"}
      </div>
      <div className="absolute top-[290px] left-[570px] w-[200px]  font-mulish text-[14px] text-black">
        {createCameraSettingsIcon(
          responseObject.resolution_status,
          "Resolution",
          responseObject.comments,
          responseObject.camera_view,
          responseObject
        )}
        &nbsp;&nbsp;Resolution
      </div>
      <div className="absolute top-[290px] left-[700px] w-[200px]  font-mulish font-bold text-[14px] text-black">
        {responseObject.frame_dimensions
          ? responseObject.frame_dimensions
          : "N/A"}
      </div>
      <div className="absolute top-[325px] left-[570px] w-[200px]  font-mulish text-[14px] text-black">
        {createCameraSettingsIcon(
          responseObject.aspect_ratio_status,
          "Aspect Ratio",
          responseObject.comments,
          responseObject.camera_view,
          responseObject
        )}
        &nbsp;&nbsp;Aspect Ratio
      </div>
      <div className="absolute top-[325px] left-[700px] w-[200px]  font-mulish font-bold text-[14px] text-black">
        {responseObject.aspect_ratio ? responseObject.aspect_ratio : "N/A"}
      </div>
      {responseObject.dynamic_slicing && (
        <div className="absolute top-[360px] left-[570px] w-[200px]  font-mulish text-[14px] text-black">
          {checkedCircleIconSmall}
          &nbsp;&nbsp;Dynamic Slicing
        </div>
      )}
      {/* <div className="absolute top-[291px] left-[575px] w-[200px]  font-mulish text-[14px] text-black">
        {checkedCircleIconSmall}&nbsp;&nbsp;Codec
      </div>
      <div className="absolute top-[291px] left-[700px] w-[200px]  font-mulish font-bold text-[14px] text-black">
        {responseObject.codec ? responseObject.codec : "N/A"}
      </div> */}
      <div className="absolute top-[220px] left-[825px] w-[200px]  font-mulish font-bold text-[14px] text-black">
        Last Motion Time
      </div>
      <div className="absolute top-[245px] left-[825px] w-[200px]  font-mulish text-[14px] text-black">
        {responseObject.last_motion
          ? timeSinceConvertor(Number(responseObject.last_motion))
          : "--"}
      </div>
      <div className="absolute top-[276px] left-[825px] w-[200px]  font-mulish font-bold text-[14px] text-black">
        Camera Start Time
      </div>
      <div className="absolute top-[301px] left-[825px] w-[200px]  font-mulish text-[14px] text-black">
        {responseObject.camera_start_time
          ? timeSinceConvertor(Number(responseObject.camera_start_time))
          : "--"}
      </div>
      {srcImages && (
        <>
          <div className="absolute top-[330px] left-[825px] w-[200px]  font-mulish font-bold text-[14px] text-black">
            Camera View
          </div>
          <div className="absolute top-[355px] left-[825px] w-[200px]  font-mulish text-[14px] text-black">
            {createCameraViewIcon(responseObject, srcImages)}
          </div>
        </>
      )}
    </div>
  );

  const oldIgnoreZones = (
    <div className="absolute top-[40px] left-[825px] w-[200px] h-[300px]">
      <div className="font-mulish text-[20px] text-actuate-blue flex">
        <p>Ignore Zones</p>
        <div className="pt-[-3px] pl-[-4px]">
          <OrangeTextButton onClick={() => {}} text="Edit" disabled={false} />
        </div>
      </div>
      <div className="overflow-auto absolute left-0 top-[40px] min-h-[360px] w-[305px]">
        <div className="absolute left-[0px] top-[0px] w-[275px]">
          {allIgnoreZones.map((object: IgnoreZone) => {
            return (
              <div key={object.label}>
                <div
                  key={object.label}
                  style={{
                    height: 25,
                    width: 6,
                    background: `${object.line_color}`,
                  }}
                  className="absolute"
                ></div>
                <p className="font-mulish text-[16px] indent-5">
                  {object.label_display_name}
                </p>
                &nbsp;
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const newIgnoreZones = (
    <div className="absolute top-[20px] left-[825px] w-[200px] h-[300px]">
      <div className="font-mulish text-[18px] text-actuate-blue flex">
        <p>Ignore Zones</p>
        <div>
          <OrangeTextButton
            onClick={() => {
              setBackdropLoadingScreenOn(true);
              localStorage.setItem("currentCameraId", JSON.stringify(obj.id));
              localStorage.setItem("currentCamera", obj.camera_name);
              localStorage.setItem("currentCameraInfo", JSON.stringify(obj));
              localStorage.setItem(
                "preview_url",
                JSON.stringify(responseObject.preview_url)
              );
              localStorage.setItem(
                "ignoreZones",
                JSON.stringify(allIgnoreZones)
              );
              navigate(
                `/sites/${siteInfo.id}/editcamera/${obj.id}/editignorezones`
              );
            }}
            text={
              <Tooltip
                title={"Edit Ignore Zones"}
                placement="top"
                sx={{ fontSize: "20px", fontFamily: "mulish" }}
              >
                <EditIcon />
              </Tooltip>
            }
            disabled={false}
          />
        </div>
      </div>
      <div className="overflow-auto absolute left-0 top-[40px] min-h-[150px] w-[305px]">
        <div className="absolute left-[0px] top-[0px] w-[275px]">
          {allIgnoreZones.map((object: IgnoreZone) => {
            return (
              <div key={object.label}>
                <div
                  key={object.label}
                  style={{
                    height: 20,
                    width: 6,
                    background: `${object.line_color}`,
                  }}
                  className="absolute"
                ></div>
                <p className="font-mulish text-[14px] indent-5">
                  {object.label_display_name}
                </p>
                &nbsp;
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  return obj.id ? (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 0 }}>
            <div className="relative h-[450px]">
              {!window.location.href.includes("editcamera") && (
                <div
                  className={
                    screenWidth > 1300
                      ? "absolute top-[10px] right-[0px] w-[160px]"
                      : "absolute top-[10px] left-[957px] w-[160px]"
                  }
                >
                  <OrangeTextButton
                    onClick={() => {
                      setBackdropLoadingScreenOn(true);
                      localStorage.setItem(
                        "currentCameraId",
                        JSON.stringify(obj.id)
                      );
                      localStorage.setItem("currentCamera", obj.camera_name);
                      localStorage.setItem(
                        "currentCameraInfo",
                        JSON.stringify(obj)
                      );
                      localStorage.setItem(
                        "preview_url",
                        JSON.stringify(responseObject.preview_url)
                      );
                      localStorage.setItem(
                        "ignoreZones",
                        JSON.stringify(allIgnoreZones)
                      );
                      navigate(`/sites/${siteInfo.id}/editcamera/${obj.id}`);
                    }}
                    text={editCameraText}
                    disabled={false}
                  />
                </div>
              )}
              {siteInfo.integration_type_name !== "ailink" && !displayNewInfo
                ? oldInfoLayout
                : newInfoLayout}

              {!displayNewInfo ? oldIgnoreZones : newIgnoreZones}

              {siteInfo.integration_type_name !== "ailink" ? (
                <div className="absolute top-[20px]">
                  <img
                    src={responseObject.preview_url}
                    style={{
                      width: dimensions ? `${dimensions.width}px` : "auto",
                      height: dimensions ? `${dimensions.height}px` : "auto",
                    }}
                    onLoad={handleImageLoad}
                    alt="TestCameraPhoto"
                  />
                  {visibleIgnoreZones.map((object: IgnoreZone) => {
                    return (
                      <div className="absolute top-[0px]" key={object.label}>
                        {object.coordinates.map(
                          (array: number[], index: number) => (
                            <svg
                              className="absolute top-[0px]"
                              key={index}
                              style={{
                                width: dimensions
                                  ? `${dimensions.width}px`
                                  : "auto",
                                height: dimensions
                                  ? `${dimensions.height}px`
                                  : "auto",
                              }}
                            >
                              {dimensions && (
                                <polygon
                                  points={pointsCreator(
                                    object.coordinates[index],
                                    dimensions.height,
                                    dimensions.width
                                  )}
                                  stroke={object.line_color}
                                  strokeWidth="5"
                                  fill={object.line_color}
                                  fillOpacity="50%"
                                />
                              )}
                            </svg>
                          )
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="absolute top-[20px]">
                  <img
                    src={responseObject.preview_url}
                    onLoad={handleImageLoad}
                    style={{
                      width: dimensions ? `${dimensions.width}px` : "auto",
                      height: dimensions ? `${dimensions.height}px` : "auto",
                    }}
                    alt="TestCameraPhoto"
                  />
                  {visibleIgnoreZones.map((object: IgnoreZone) => {
                    return (
                      <div className="absolute top-[0px]" key={object.label}>
                        {object.coordinates.map(
                          (array: number[], index: number) => {
                            return (
                              <svg
                                className="absolute top-[0px]"
                                key={array[0]}
                                style={{
                                  width: dimensions
                                    ? `${dimensions.width}px`
                                    : "auto",
                                  height: dimensions
                                    ? `${dimensions.height}px`
                                    : "auto",
                                }}
                              >
                                {dimensions && (
                                  <polygon
                                    points={pointsCreator(
                                      object.coordinates[index],
                                      dimensions.height,
                                      dimensions.width
                                    )}
                                    stroke={object.line_color}
                                    strokeWidth="5"
                                    fill={object.line_color}
                                    fillOpacity="50%"
                                  />
                                )}
                              </svg>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
};
