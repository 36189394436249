import React from "react";
import { useContext, createContext } from "react";

const login = () => {
  localStorage.setItem("loggedIn", "true");
};

// const logout = () => {
// localStorage.removeItem("loggedIn");
// Behold the code that almost ruined my nerves:
// setTimeout(() => {
//   // localStorage.clear();
// }, 100);
// };

export type MyComponentProps = React.PropsWithChildren<{
  login: () => void;
}>;

const AuthContext = createContext<MyComponentProps>({ login });

export const AuthProvider = ({ children }: MyComponentProps) => {
  return (
    <AuthContext.Provider
      value={{
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
