import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { handleAddCalendarDates } from "./CalendarsUtils/handleAddCalendarDates";
import { NavigateFunction } from "react-router-dom";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AddDateDialog = ({
  addDateDialogOpen,
  setAddDateDialogOpen,
  setCalendarEvents,
  calendarId,
  setShowMoreDialogOpen,
  setEditingCalendar,
  navigate,
}: {
  addDateDialogOpen: boolean;
  setAddDateDialogOpen: Dispatcher<boolean>;
  setCalendarEvents: any;
  calendarId: any;
  setShowMoreDialogOpen: Dispatcher<boolean>;
  setEditingCalendar: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  const [newDate, setNewDate] = React.useState({
    calendar: calendarId,
    name: "",
    event_date: yyyymmddGenerator(new Date()),
  });

  const [calendarDate, setCalendarDate] = React.useState(new Date());

  return (
    <Dialog open={addDateDialogOpen} TransitionComponent={Transition}>
      <DialogTitle id="add-date" sx={{ font: "mulish" }}>
        ADD DATE
      </DialogTitle>
      <DialogContent sx={{ width: 450, height: 175 }}>
        <br></br>
        <TextField
          onChange={(event) => {
            setNewDate((previousState: any) => {
              return {
                ...previousState,
                name: event.target.value,
                calendar: calendarId,
              };
            });
          }}
          label="Event Name"
          // variant="outlined"
          sx={{ fontFamily: "Mulish", width: 400 }}
        />
        <br></br>
        <br></br>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Event Date"
            value={calendarDate}
            onChange={(newValue: any) => {
              setCalendarDate(newValue);
              setNewDate((previousState: any) => {
                return {
                  ...previousState,
                  event_date: yyyymmddGenerator(newValue),
                };
              });
            }}
            renderInput={(params) => (
              <TextField sx={{ width: 150 }} {...params} />
            )}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddDateDialogOpen(false);
            setShowMoreDialogOpen(true);
          }}
          text="Cancel"
          disabled={false}
        />
        &nbsp;
        <OrangeTextButton
          onClick={() => {
            setEditingCalendar(true);
            handleAddCalendarDates(
              newDate,
              setCalendarEvents,
              setEditingCalendar,
              navigate
            );
            setAddDateDialogOpen(false);
            setShowMoreDialogOpen(true);
          }}
          text="Save"
          disabled={!newDate.name}
        />
      </DialogActions>
    </Dialog>
  );
};
