export const errorsCopy = {
  //Connectivity
  connectivity_default_cause: "Unexpected connection interruption.",
  connectivity_default_next_steps:
    "Our health check has concluded that we are unable to connect to this camera though the IP, port, and credentials seem to be valid.",

  invalid_credentials_cause: "Invalid credentials.",
  invalid_credentials_next_steps:
    "Our health check has concluded that we are unable to connect to this camera because the credentials are invalid. Check the camera credentials and ensure they match what is entered on Actuate.",

  filtered_port_cause: "RTSP port filtered.",
  filtered_port_next_steps:
    "Our health check has concluded that we are unable to connect to this camera because the port is filtered. Check that the port information entered on Actuate is correct and reachable.",

  connection_interuption_cause: "Unexpected connection interruption.",
  connection_interuption_next_steps:
    "Our health check has concluded that we are unable to connect to this camera though the IP, port, and credentials seem to be valid.",

  ip_unreachable_cause: "IP/DNS unreachable.",
  ip_unreachable_next_steps:
    "Our health check has concluded that we are unable to connect to this camera because the IP and/or port is down. Confirm that the IP and port entered on Actuate are correct and reachable.",

  //Stream Quality
  stream_quality_default_cause: "Camera configuration issue.",
  stream_quality_default_next_steps:
    "Our health check has concluded that the camera stream connection is unstable.",

  fps_too_high_cause: "Frame rate (FPS) and/or resolution too high",
  fps_too_high_next_steps:
    "Our health check has concluded that the camera stream connection is unstable while the frame rate is high. Adjust the frame rate of the substream to 2 FPS and confirm there are no bandwidth limitations.",

  resolution_too_high_cause: "Resolution too high ( > 1080 p)",
  resolution_too_high_next_steps:
    "Our health check has concluded that the camera stream connection is unstable while the resolution is high. Adjust the resolution of the substream to 480p to reduce bandwidth consumption and prevent streaming issues.",

  fps_too_low_cause: "Frame rate (FPS) too low.",
  fps_too_low_next_steps:
    "Our health check has concluded that this camera stream has an invalid frame rate. Adjust the frame rate of the substream to 2 FPS and confirm there are no bandwidth limitations. ",

  //Image Quality
  image_quality_default_cause: "Resolution too high or too low.",
  image_quality_default_next_steps:
    "Our health check has concluded that this camera stream has an invalid resolution. Adjust the resolution of the substream.",

  resolution_low_cause: "Resolution too low( < 480 p)",
  resolution_low_next_steps:
    "Our health check has concluded that this camera stream has an invalid resolution. Adjust the resolution of the substream to 480p and confirm there are no bandwidth limitations.",

  resolution_high_cause: "Resolution too high ( > 1080 p) ",
  resolution_high_next_steps:
    "Our health check has concluded that this camera stream has an invalid resolution. Adjust the resolution of the substream to 480p to reduce bandwidth consumption and prevent streaming issues.",

  //Motion Status
  motion_status_default_cause: "Low activity",
  motion_status_default_next_steps:
    " Our health check has concluded that there has been no motion on this camera in 24 hours.",

  //Recording Status
  recording_status_default_cause: "Camera livestream is not being recorded.",
  recording_status_default_next_steps:
    "Ensure that the recording settings on your camera or NVR are correct.",

  //Scene Change
  scene_change_default_cause: "Possible tampering.",
  scene_change_default_next_steps:
    "Please review camera view. If camera view has changed, respond to and resolve change at earliest convenience.",

  camera_moved_cause: "Camera physically moved.",
  camera_moved_next_steps:
    "Please review camera view and ensure proper camera position. If camera has been rotated, reposition camera at earliest convenience.",

  camera_tampering_cause: "Possible tampering.",
  camera_tampering_next_steps:
    "Please review camera view. If camera has been tampered with, respond to and resolve tampered camera at earliest convenience.",

  camera_blocked_cause: "Camera view blocked.",
  camera_blocked_next_steps:
    "Please review camera view. If camera has been blocked, respond to and resolve blockage at earliest convenience.",
};
