import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../utils/themeCreator";
import { Button } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const AddCalendarButton = ({
  setAddCalendarScreenOpen,
}: {
  setAddCalendarScreenOpen: Dispatcher<boolean>;
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        type="button"
        color="primary"
        onClick={() => {
          setAddCalendarScreenOpen(true);
        }}
      >
        + Add Calendar
      </Button>
    </ThemeProvider>
  );
};
