import React from "react";
import { yyyymmddGenerator } from "./yyyymmddGenerator";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleGetDownloadURL = (
  userFilters: any,
  setDownloadURL: Dispatcher<string>,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    handleGetDownloadURL(userFilters, setDownloadURL, navigate);
  };

  let url = `analytics/download/?start_date=${yyyymmddGenerator(
    userFilters.start_date
  )}&end_date=${yyyymmddGenerator(userFilters.end_date)}`;

  if (userFilters.parent_group) {
    url += `&group=${userFilters.parent_group}`;
  }

  if (userFilters.site[0]) {
    let additionalSitesString = `${userFilters.site[0]}`;
    if (userFilters.site.length > 1) {
      userFilters.site.forEach((siteId: string, index: number) => {
        if (index !== 0) {
          additionalSitesString += `,${siteId}`;
        }
      });
    }
    url += `&site=${additionalSitesString}`;
  }

  if (userFilters.camera[0]) {
    let additionalCameraString = `${userFilters.camera[0]}`;
    if (userFilters.camera.length > 1) {
      userFilters.camera.forEach((cameraId: string, index: number) => {
        if (index !== 0) {
          additionalCameraString += `,${cameraId}`;
        }
      });
    }
    url += `&camera=${additionalCameraString}`;
  }

  const AxiosUI = createApi(``);

  AxiosUI.get(`${url}`).then(
    (response: AxiosResponse) => {
      setDownloadURL(response.data.url);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
