import React from "react";
import { NavBar } from "../common/NavBar";
import { Dispatcher } from "./Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const PageNotFound = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  return (
    <div>
      <div className="bg-actuate-grey absolute w-screen h-screen">
        <div className="relative top-[100px] text-center">
          <p className="text-mulish text-actuate-blue text-[80px]">404</p>
          <p className="text-mulish text-actuate-blue text-[30px]">
            Page not found.
          </p>
          <br></br>
          <p className="text-mulish text-actuate-blue text-[14px]">
            “Our greatest weakness lies in giving up. The most certain way to
            succeed is always to try just one more time.”
          </p>
          <p className="text-mulish text-actuate-blue text-[16px]">
            - Thomas Edison
          </p>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
