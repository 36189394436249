import React from "react";
import { NavBar } from "../../common/NavBar";
import { AddGroupUserButton } from "./AddGroupUserButton";
import { GroupUsersSearchBar } from "./GroupsUsersSearchBar";
import { SearchButtonGroupsUsers } from "./SeachButtonGroupUsers";
import { GroupUsersTable } from "./GroupUsersTable";
import { useNavigate } from "react-router-dom";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { getGroupUsersData } from "./GroupUsersUtils/getGroupUsersData";
import { AboutUserDialog } from "./AboutUserDialog";
import { AddGroupUserScreen } from "./AddGroupUserScreen";
import { Backdrop } from "@mui/material";
import DeleteGroupUserDialog from "./DeleteGroupUserDialog";
import { GroupUserAlerts } from "./GroupUserAlerts";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const GroupUsers = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [groupUserData, setGroupUserData] = React.useState<any[]>([]);

  const [filteredData, setFilteredData] = React.useState<any[]>([]);

  const [filteredDataExists, setFilteredDataExists] = React.useState(false);

  const [groupUsersSearchString, setGroupUsersSearchString] =
    React.useState("");

  const [fetchingGroupUserData, setFetchingGroupUserData] =
    React.useState(true);

  const [backgroundLoadingData, setBackgroundLoadingData] =
    React.useState(true);

  const [deleteGroupUserDialogOpen, setDeleteGroupUserDialogOpen] =
    React.useState(false);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [aboutUserDialogOpen, setAboutUserDialogOpen] = React.useState(false);

  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const [selectedRowIndex, setSelectedRowIndex] = React.useState(0);

  const [groupUserId, setGroupUserId] = React.useState("");

  const [updatingGroupUserData, setUpdatingGroupUserData] =
    React.useState(false);

  const [alertState, setAlertState] = React.useState({
    updateUserSuccess: false,
    deleteUserSuccess: false,
    addUserSuccess: false,
    emailSent: false,
    noData: false,
  });

  const [addGroupUserScreenOpen, setAddGroupUserScreenOpen] =
    React.useState(false);

  const [userNameString, setUserNameString] = React.useState("");

  const [activeData, setActiveData] = React.useState(groupUserData);

  React.useEffect(() => {
    if (filteredData[0]) {
      setActiveData(filteredData);
    } else {
      setActiveData(groupUserData);
    }
  }, [filteredData, groupUserData]);

  const navigate = useNavigate();

  React.useEffect(() => {
    getGroupUsersData(
      setGroupUserData,
      [],
      setFetchingGroupUserData,
      1,
      "",
      setBackgroundLoadingData,
      setAlertState,
      navigate
    );
  }, []);

  return (
    <div>
      <GroupUserAlerts
        alertState={alertState}
        setAlertState={setAlertState}
        userNameString={userNameString}
      />
      <div className="bg-actuate-grey absolute w-screen h-screen">
        <BackdropLoadingScreen openState={fetchingGroupUserData} />
        <BackdropLoadingScreen openState={updatingGroupUserData} />
        {aboutUserDialogOpen && (
          <AboutUserDialog
            aboutUserDialogOpen={aboutUserDialogOpen}
            setAboutUserDialogOpen={setAboutUserDialogOpen}
            row={selectedRow}
            selectedRowIndex={selectedRowIndex}
            setGroupUserData={setGroupUserData}
            setUpdatingGroupUserData={setUpdatingGroupUserData}
            setAlertState={setAlertState}
            navigate={navigate}
          />
        )}
        {deleteGroupUserDialogOpen && groupUserData && (
          <DeleteGroupUserDialog
            deleteGroupUserDialogOpen={deleteGroupUserDialogOpen}
            setDeleteGroupUserDialogOpen={setDeleteGroupUserDialogOpen}
            setAlertState={setAlertState}
            groupUserId={groupUserId}
            activeData={activeData}
            setActiveData={setActiveData}
            setUpdatingGroupUserData={setUpdatingGroupUserData}
            navigate={navigate}
          />
        )}
        {addGroupUserScreenOpen && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={addGroupUserScreenOpen}
          >
            <AddGroupUserScreen
              setAddGroupUserScreenOpen={setAddGroupUserScreenOpen}
              setUserNameString={setUserNameString}
              setAlertState={setAlertState}
              groupUsersSearchString={groupUsersSearchString}
              activeData={activeData}
              setActiveData={setActiveData}
            />
          </Backdrop>
        )}
        <div
          className={
            !navBarCollapsed
              ? "absolute top-[60px] left-[150px] right-[1px] h-[19px]] bg-white"
              : "absolute top-[60px] left-[75px] right-[1px] h-[19px]] bg-white"
          }
        >
          {" "}
          <p className="font-black font-mulish text-[20px] p-3 indent-6">
            Group Users
          </p>
          <div className="absolute top-[7px] right-[30px]">
            <AddGroupUserButton
              setAddGroupUserScreenOpen={setAddGroupUserScreenOpen}
              setAlertState={setAlertState}
            />
          </div>
        </div>
        <div
          className={
            !navBarCollapsed
              ? "absolute left-[135px] top-[10px]"
              : "absolute left-[60px] top-[10px]"
          }
        >
          {" "}
          <GroupUsersSearchBar
            setGroupUsersSearchString={setGroupUsersSearchString}
          />
        </div>
        <div
          className={
            !navBarCollapsed
              ? "absolute left-[500px] top-[10px]"
              : "absolute left-[425px] top-[10px]"
          }
        >
          {" "}
          <SearchButtonGroupsUsers
            setGroupUserData={setGroupUserData}
            setFetchingGroupUserData={setFetchingGroupUserData}
            groupUsersSearchString={groupUsersSearchString}
            backgroundLoadingData={backgroundLoadingData}
            setBackgroundLoadingData={setBackgroundLoadingData}
            setAlertState={setAlertState}
            setPage={setPage}
            setFilteredDataExists={setFilteredDataExists}
            setFilteredData={setFilteredData}
            navigate={navigate}
          />
        </div>
        {!fetchingGroupUserData && (
          <div
            className={
              !navBarCollapsed
                ? "absolute left-[150px] top-[130px]"
                : "absolute left-[75px] top-[130px]"
            }
          >
            {" "}
            <GroupUsersTable
              groupUserData={groupUserData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setSelectedRow={setSelectedRow}
              setAboutUserDialogOpen={setAboutUserDialogOpen}
              setSelectedRowIndex={setSelectedRowIndex}
              setGroupUserId={setGroupUserId}
              setDeleteGroupUserDialogOpen={setDeleteGroupUserDialogOpen}
              setUpdatingGroupUserData={setUpdatingGroupUserData}
              setAlertState={setAlertState}
              setUserNameString={setUserNameString}
              filteredDataExists={filteredDataExists}
              filteredData={filteredData}
              activeData={activeData}
              navigate={navigate}
            />
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
