import React from "react";
import { cancelIconRed } from "../../../../common/cancelIconRed";
import WarningIcon from "@mui/icons-material/Warning";
import {
  checkedCircleIcon,
  checkedCircleIconGrey,
} from "../../../../common/checkedCircleIcon";
import { Tooltip } from "@mui/material";

export const createOptimalSettingsIcon = (srcImages: any, object: any) => {
  const optimal_settings = object.optimal_settings;

  const optimal_settings_note = object.optimal_settings_note;

  const srcImageString = `${object.id}full`;

  let cameraViewString = "";

  let dynamicSlicing = false;

  if (srcImages[srcImageString as keyof typeof srcImages]) {
    cameraViewString =
      srcImages[srcImageString as keyof typeof srcImages].camera_view;
    if (srcImages[srcImageString as keyof typeof srcImages].comments) {
      if (
        srcImages[srcImageString as keyof typeof srcImages].comments.includes(
          "Dynamic slicing is enabled"
        )
      ) {
        dynamicSlicing = true;
      }
    }
  }

  if (cameraViewString === "fisheye" || cameraViewString === "thermal") {
    return (
      <Tooltip title="Optimal settings can not be properly determined due to camera view type or enabled slicing.">
        {checkedCircleIconGrey}
      </Tooltip>
    );
  }

  if (dynamicSlicing) {
    return (
      <Tooltip title="Optimal settings can not be properly determined due to dynamic slicing.">
        {checkedCircleIconGrey}
      </Tooltip>
    );
  }

  if (optimal_settings === "warning") {
    return (
      <Tooltip title={optimal_settings_note}>
        <WarningIcon sx={{ color: "#FF8400" }} />
      </Tooltip>
    );
  }

  if (optimal_settings === "bad") {
    return <Tooltip title={optimal_settings_note}>{cancelIconRed}</Tooltip>;
  }

  if (optimal_settings === "good") {
    return <Tooltip title={optimal_settings_note}>{checkedCircleIcon}</Tooltip>;
  }

  if (optimal_settings === "unknown") {
    return (
      <Tooltip title="Unable to determine or camera information is still not available">
        <WarningIcon sx={{ color: "#9e9e9e" }} />
      </Tooltip>
    );
  }
};
