import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { NavigateFunction } from "react-router-dom";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { handlePatchCalendarEvent } from "./CalendarsUtils/handlePatchCalendarEvent";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const EditEventDialog = ({
  patchEvent,
  editEventDialogOpen,
  setEditEventDialogOpen,
  setEditingCalendar,
  setCalendarEvents,
  navigate,
}: {
  patchEvent: any;
  editEventDialogOpen: boolean;
  setEditEventDialogOpen: Dispatcher<boolean>;
  setEditingCalendar: Dispatcher<boolean>;
  setCalendarEvents: any;
  navigate: NavigateFunction;
}) => {
  const parseDate = (dateString: string) => {
    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);
    return new Date(year, month, day);
  };

  const [updatedRow, setUpdatedRow] = React.useState(patchEvent);

  const [changesMade, setChangesMade] = React.useState(false);

  const [date, setDate] = React.useState(parseDate(updatedRow.event_date));

  return (
    <Dialog open={editEventDialogOpen} TransitionComponent={Transition}>
      <DialogTitle id="edit-event" sx={{ font: "mulish" }}>
        Edit Event
      </DialogTitle>
      <DialogContent sx={{ width: 450, height: 175 }}>
        <br></br>
        <TextField
          value={updatedRow.name}
          onChange={(event) => {
            setChangesMade(true);
            setUpdatedRow((previousState: any) => {
              return {
                ...previousState,
                name: event.target.value,
              };
            });
          }}
          label="Event Name"
          sx={{ fontFamily: "Mulish", width: 400 }}
        />
        <br></br>
        <br></br>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Event Date"
            value={date}
            onChange={(newValue: any) => {
              setChangesMade(true);
              setDate(newValue);
              setUpdatedRow((previousState: any) => {
                return {
                  ...previousState,
                  event_date: yyyymmddGenerator(newValue),
                };
              });
            }}
            renderInput={(params) => (
              <TextField sx={{ width: 150 }} {...params} />
            )}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setEditEventDialogOpen(false);
            setEditingCalendar(false);
          }}
          text="Cancel"
          disabled={false}
        />
        &nbsp;
        <OrangeTextButton
          onClick={() => {
            setEditEventDialogOpen(false);
            setEditingCalendar(true);
            handlePatchCalendarEvent(
              updatedRow,
              setCalendarEvents,
              setEditingCalendar,
              navigate
            );
          }}
          text="Save"
          disabled={!changesMade}
        />
      </DialogActions>
    </Dialog>
  );
};
