import React from "react";

export const resizeImage = (
  inputWidth: number,
  inputHeight: number
): { width: number; height: number } => {
  if (inputWidth && inputHeight) {
    const widthStr = inputWidth;
    const heightStr = inputHeight;
    const aspectRatio = widthStr / heightStr;

    let adjustedWidth = widthStr;
    let adjustedHeight = heightStr;

    if (adjustedWidth / aspectRatio > 420) {
      adjustedWidth = 560;
      adjustedHeight = adjustedWidth / aspectRatio;
    } else {
      adjustedHeight = 420;
      adjustedWidth = adjustedHeight * aspectRatio;
    }

    if (adjustedWidth > 560) {
      adjustedWidth = 560;
      adjustedHeight = adjustedWidth / aspectRatio;
    }

    if (adjustedHeight > 420) {
      adjustedHeight = 420;
      adjustedWidth = adjustedHeight * aspectRatio;
    }

    return {
      width: adjustedWidth,
      height: adjustedHeight,
    };
  } else {
    return {
      height: 420,
      width: 560,
    };
  }
};
