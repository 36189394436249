import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import React from "react";
import { checkOrX } from "./EditCameraUtils/checkOrX";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleDeleteRecipient } from "./EditCameraUtils/handleDeleteRecipient";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import CircularProgress from "@mui/material/CircularProgress";

export const RecipientsConfiguredTable = ({
  recipientsConfiguredData,
  titlesArray,
  navigate,
  setBackdropLoadingScreenOn,
  setRecipientsConfiguredData,
  setTitlesArray,
  setActiveTab,
  actionRulesData,
  setActionRulesData,
  actionRulesTitlesArray,
  setActionRulesTitlesArray,
  applicationsConfiguredData,
  cameraGeneralInfo,
  axisFinished,
}: {
  recipientsConfiguredData: any;
  titlesArray: any;
  navigate: NavigateFunction;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setRecipientsConfiguredData: any;
  setTitlesArray: any;
  setActiveTab: any;
  actionRulesData: any;
  setActionRulesData: any;
  actionRulesTitlesArray: any;
  setActionRulesTitlesArray: any;
  applicationsConfiguredData: any;
  cameraGeneralInfo: any;
  axisFinished: any;
}) => {
  const currentCameraInfo = JSON.parse(localStorage.currentCameraInfo);

  return axisFinished.recipient ? (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          {recipientsConfiguredData.map((object: any) => {
            return (
              <TableRow key={object.id}>
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", minWidth: "125px" }}
                >
                  {object.display_name ? object.display_name : "--"}
                </TableCell>
                {titlesArray.includes("Recipient Type") && (
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", minWidth: "136px" }}
                  >
                    {object.recipient_type ? object.recipient_type : "--"}
                  </TableCell>
                )}
                {titlesArray.includes("Host") && (
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", minWidth: "200px" }}
                  >
                    {object.host ? (
                      <>
                        {checkOrX(object.is_host_ok)} {object.host}
                      </>
                    ) : (
                      "--"
                    )}
                  </TableCell>
                )}
                {titlesArray.includes("Port") && (
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", minWidth: "100px" }}
                  >
                    {object.port ? (
                      <>
                        {checkOrX(object.is_port_ok)} {object.port}
                      </>
                    ) : (
                      "--"
                    )}
                  </TableCell>
                )}
                {titlesArray.includes("Email From") && (
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", minWidth: "210px" }}
                  >
                    {object.email_from ? (
                      <>
                        {checkOrX(object.is_email_from_ok)} {object.email_from}
                      </>
                    ) : (
                      "--"
                    )}
                  </TableCell>
                )}
                {titlesArray.includes("Email To") && (
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", minWidth: "150px" }}
                  >
                    {object.email_to ? object.email_to : "--"}
                  </TableCell>
                )}
                {titlesArray.includes("Login") && (
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", minWidth: "150px" }}
                  >
                    {object.login ? object.login : "--"}
                  </TableCell>
                )}
                {titlesArray.includes("Password") && (
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", minWidth: "150px" }}
                  >
                    {object.password ? object.password : "--"}
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", minWidth: "100px" }}
                >
                  <OrangeTextButton
                    onClick={() => {
                      setBackdropLoadingScreenOn(true);
                      handleDeleteRecipient(
                        currentCameraInfo.id,
                        object.id,
                        navigate,
                        setBackdropLoadingScreenOn,
                        setRecipientsConfiguredData,
                        setTitlesArray,
                        setActiveTab,
                        recipientsConfiguredData,
                        titlesArray,
                        actionRulesData,
                        setActionRulesData,
                        actionRulesTitlesArray,
                        setActionRulesTitlesArray,
                        applicationsConfiguredData,
                        cameraGeneralInfo,
                        axisFinished
                      );
                    }}
                    text="Delete"
                    disabled={false}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div className="pl-[20px] flex">
      <span className="mr-2">
        <CircularProgress color="primary" size={30} />
      </span>
      <span className="pl-[10px] pt-[5px]">Loading Data...</span>
    </div>
  );
};
