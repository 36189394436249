import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { addSelectedParam } from "./addSelectedParam";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { totalUpPrecisionRecallData } from "./totalUpPrecisionRecallData";

export const formatSentinalDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11, +1 to make it 1-12
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getSentinelClipsWithQuerry = (
  setSentinelData: any,
  setPageLoadingSentinel: Dispatcher<boolean>,
  setSelectedRowSentinel: any,
  setPrecisionRecallDataSentinel: any,
  setVideoURLSentinel: any,
  setVideoClipLoadingSentinel: Dispatcher<boolean>,
  setClipsIndexOrderSentinel: any,
  selectedRowSentinel: any,
  setNoDataToDisplaySentinel: Dispatcher<boolean>,
  setSiteOptionsSentinel: any,
  setLoadingSitesSentinel: any,
  userFiltersSentinel: any,
  setFetchingFilteredDataSentinel: Dispatcher<boolean>,
  setPageSentinel: any,
  lastTimeStamp: string,
  lastFileId: string,
  moreThan100: boolean,
  setLoadingAdditionalData: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    getSentinelClipsWithQuerry(
      setSentinelData,
      setPageLoadingSentinel,
      setSelectedRowSentinel,
      setPrecisionRecallDataSentinel,
      setVideoURLSentinel,
      setVideoClipLoadingSentinel,
      setClipsIndexOrderSentinel,
      selectedRowSentinel,
      setNoDataToDisplaySentinel,
      setSiteOptionsSentinel,
      setLoadingSitesSentinel,
      userFiltersSentinel,
      setFetchingFilteredDataSentinel,
      setPageSentinel,
      lastTimeStamp,
      lastFileId,
      moreThan100,
      setLoadingAdditionalData,
      navigate
    );
  };

  let searchParamsString = ``;

  if (userFiltersSentinel.site_ids[0]) {
    searchParamsString += `&site_ids=${userFiltersSentinel.site_ids[0]}`;
  }

  if (userFiltersSentinel.camera_ids[0]) {
    searchParamsString += `&camera_ids=${userFiltersSentinel.camera_ids[0]}`;
  }

  if (userFiltersSentinel.start_date) {
    searchParamsString += `&start_date=${formatSentinalDate(
      userFiltersSentinel.start_date
    )}`;
  }

  if (userFiltersSentinel.end_date) {
    searchParamsString += `&end_date=${formatSentinalDate(
      userFiltersSentinel.end_date
    )}`;
  }

  if (lastTimeStamp) {
    searchParamsString += `&last_timestamp=${lastTimeStamp}`;
  }

  if (lastFileId) {
    searchParamsString += `&last_file_id=${lastFileId}`;
  }

  searchParamsString += `&page_size=100`;

  let dataResponse: any;

  let last_timestamp = lastTimeStamp;
  let last_file_id = lastFileId;

  AxiosUI.get(
    `clip/?username=${userFiltersSentinel.user}${searchParamsString}`
  ).then(
    (response: AxiosResponse) => {
      dataResponse = response.data;
      let lastFileIdSentinal = "";
      if (localStorage.last_file_id_sentinal) {
        lastFileIdSentinal = JSON.parse(localStorage.last_file_id_sentinal);
      }
      let lastTimeStampSentinal = "";
      if (localStorage.last_timestamp_sentinal) {
        lastTimeStampSentinal = JSON.parse(
          localStorage.last_timestamp_sentinal
        );
      }
      if (
        (lastFileIdSentinal === lastFileId &&
          lastTimeStampSentinal === lastTimeStamp) ||
        !lastFileId ||
        !lastTimeStamp
      ) {
        const results = response.data.results;
        if (response.data.count === 0) {
          setFetchingFilteredDataSentinel(false);
        }
        if (response.data.count === 100) {
          setNoDataToDisplaySentinel(false);
          setSentinelData((previousState: any) => {
            return addSelectedParam(
              [...previousState, ...results],
              setClipsIndexOrderSentinel,
              lastFileId
            );
          });
          const responsePrecisionRecall = totalUpPrecisionRecallData(
            response.data.summary
          );
          setPrecisionRecallDataSentinel((previousState: any) => {
            let precisionResponse = previousState.precision;
            let recallResposne = previousState.recall;
            if (!precisionResponse) {
              precisionResponse = responsePrecisionRecall.precision;
            }
            if (!recallResposne) {
              recallResposne = responsePrecisionRecall.recall;
            }

            return {
              date: responsePrecisionRecall.date,
              date_str: responsePrecisionRecall.date_str,
              true_positives:
                responsePrecisionRecall.true_positives +
                previousState.true_positives,
              false_positives:
                responsePrecisionRecall.false_positives +
                previousState.false_positives,
              false_negatives:
                responsePrecisionRecall.false_negatives +
                previousState.false_negatives,
              true_negatives:
                responsePrecisionRecall.true_negatives +
                previousState.true_negatives,
              unclassified:
                responsePrecisionRecall.unclassified +
                previousState.unclassified,
              total_clips:
                responsePrecisionRecall.total_clips + previousState.total_clips,
              precision: precisionResponse,
              recall: recallResposne,
            };
          });
          localStorage.setItem(
            "last_file_id_sentinal",
            JSON.stringify(response.data.last_file_id)
          );
          localStorage.setItem(
            "last_timestamp_sentinal",
            JSON.stringify(response.data.last_timestamp)
          );
          last_timestamp = response.data.last_timestamp;
          last_file_id = response.data.last_file_id;
          if (lastFileId === "") {
            setSelectedRowSentinel(results[0]);
            AxiosUI.get(
              `clip/info/?username=${userFiltersSentinel.user}&file_id=${results[0].file_id}`
            ).then((response: AxiosResponse) => {
              if (response.data.file_id) {
                for (const key in response.data) {
                  setSelectedRowSentinel((previousState: any) => {
                    return {
                      ...previousState,
                      [key]: response.data[key],
                    };
                  });
                }
                localStorage.setItem(
                  "investigationSelectedRow",
                  JSON.stringify(selectedRowSentinel)
                );
                AxiosUI.get(
                  `clip/url/?username=${userFiltersSentinel.user}&file_id=${response.data.file_id}`
                ).then(
                  (response: AxiosResponse) => {
                    setVideoURLSentinel(response.data);
                    setVideoClipLoadingSentinel(false);
                    setFetchingFilteredDataSentinel(false);
                    setPageLoadingSentinel(false);
                    if (
                      last_timestamp &&
                      last_file_id &&
                      window.location.href.includes("investigation")
                    ) {
                      getSentinelClipsWithQuerry(
                        setSentinelData,
                        setPageLoadingSentinel,
                        setSelectedRowSentinel,
                        setPrecisionRecallDataSentinel,
                        setVideoURLSentinel,
                        setVideoClipLoadingSentinel,
                        setClipsIndexOrderSentinel,
                        selectedRowSentinel,
                        setNoDataToDisplaySentinel,
                        setSiteOptionsSentinel,
                        setLoadingSitesSentinel,
                        userFiltersSentinel,
                        setFetchingFilteredDataSentinel,
                        setPageSentinel,
                        last_timestamp,
                        last_file_id,
                        true,
                        setLoadingAdditionalData,
                        navigate
                      );
                    } else {
                      if (dataResponse.results[0]) {
                        if (dataResponse.results[0].file_id) {
                          AxiosUI.get(
                            `clip/info/?username=${userFiltersSentinel.user}&file_id=${results[0].file_id}`
                          ).then((response: AxiosResponse) => {
                            if (
                              response.data.event_timestamp &&
                              window.location.href.includes("investigation")
                            ) {
                              localStorage.setItem(
                                "last_file_id_sentinal",
                                JSON.stringify(response.data.file_id)
                              );
                              localStorage.setItem(
                                "last_timestamp_sentinal",
                                JSON.stringify(response.data.event_timestamp)
                              );
                              getSentinelClipsWithQuerry(
                                setSentinelData,
                                setPageLoadingSentinel,
                                setSelectedRowSentinel,
                                setPrecisionRecallDataSentinel,
                                setVideoURLSentinel,
                                setVideoClipLoadingSentinel,
                                setClipsIndexOrderSentinel,
                                selectedRowSentinel,
                                setNoDataToDisplaySentinel,
                                setSiteOptionsSentinel,
                                setLoadingSitesSentinel,
                                userFiltersSentinel,
                                setFetchingFilteredDataSentinel,
                                setPageSentinel,
                                response.data.event_timestamp,
                                response.data.file_id,
                                true,
                                setLoadingAdditionalData,
                                navigate
                              );
                            }
                          });
                        }
                      }
                    }
                  },
                  (reason: AxiosError) => {
                    universalAPIErrorHandler(
                      reason,
                      navigate,
                      retryFunction,
                      () => {
                        setNoDataToDisplaySentinel(false);
                        setVideoClipLoadingSentinel(false);
                        setFetchingFilteredDataSentinel(false);
                      }
                    );
                  }
                );
              }
            });
          } else {
            localStorage.setItem(
              "last_file_id_sentinal",
              JSON.stringify(response.data.last_file_id)
            );
            localStorage.setItem(
              "last_timestamp_sentinal",
              JSON.stringify(response.data.last_timestamp)
            );
            if (
              last_timestamp &&
              last_file_id &&
              window.location.href.includes("investigation")
            ) {
              getSentinelClipsWithQuerry(
                setSentinelData,
                setPageLoadingSentinel,
                setSelectedRowSentinel,
                setPrecisionRecallDataSentinel,
                setVideoURLSentinel,
                setVideoClipLoadingSentinel,
                setClipsIndexOrderSentinel,
                selectedRowSentinel,
                setNoDataToDisplaySentinel,
                setSiteOptionsSentinel,
                setLoadingSitesSentinel,
                userFiltersSentinel,
                setFetchingFilteredDataSentinel,
                setPageSentinel,
                last_timestamp,
                last_file_id,
                true,
                setLoadingAdditionalData,
                navigate
              );
            } else {
              if (dataResponse.results[0]) {
                if (dataResponse.results[0].file_id) {
                  AxiosUI.get(
                    `clip/info/?username=${userFiltersSentinel.user}&file_id=${results[0].file_id}`
                  ).then((response: AxiosResponse) => {
                    if (
                      response.data.event_timestamp &&
                      window.location.href.includes("investigation")
                    ) {
                      localStorage.setItem(
                        "last_file_id_sentinal",
                        JSON.stringify(response.data.file_id)
                      );
                      localStorage.setItem(
                        "last_timestamp_sentinal",
                        JSON.stringify(response.data.event_timestamp)
                      );
                      getSentinelClipsWithQuerry(
                        setSentinelData,
                        setPageLoadingSentinel,
                        setSelectedRowSentinel,
                        setPrecisionRecallDataSentinel,
                        setVideoURLSentinel,
                        setVideoClipLoadingSentinel,
                        setClipsIndexOrderSentinel,
                        selectedRowSentinel,
                        setNoDataToDisplaySentinel,
                        setSiteOptionsSentinel,
                        setLoadingSitesSentinel,
                        userFiltersSentinel,
                        setFetchingFilteredDataSentinel,
                        setPageSentinel,
                        response.data.event_timestamp,
                        response.data.file_id,
                        true,
                        setLoadingAdditionalData,
                        navigate
                      );
                    }
                  });
                }
              }
            }
          }
        }
        if (response.data.count < 100) {
          if (results[0] && moreThan100) {
            setSentinelData((previousState: any) => {
              return addSelectedParam(
                [...previousState, ...results],
                setClipsIndexOrderSentinel,
                lastFileId
              );
            });
            const responsePrecisionRecall = totalUpPrecisionRecallData(
              response.data.summary
            );
            setPrecisionRecallDataSentinel((previousState: any) => {
              let precisionResponse = previousState.precision;
              let recallResposne = previousState.recall;
              if (!precisionResponse) {
                precisionResponse = responsePrecisionRecall.precision;
              }
              if (!recallResposne) {
                recallResposne = responsePrecisionRecall.recall;
              }

              return {
                date: responsePrecisionRecall.date,
                date_str: responsePrecisionRecall.date_str,
                true_positives:
                  responsePrecisionRecall.true_positives +
                  previousState.true_positives,
                false_positives:
                  responsePrecisionRecall.false_positives +
                  previousState.false_positives,
                false_negatives:
                  responsePrecisionRecall.false_negatives +
                  previousState.false_negatives,
                true_negatives:
                  responsePrecisionRecall.true_negatives +
                  previousState.true_negatives,
                unclassified:
                  responsePrecisionRecall.unclassified +
                  previousState.unclassified,
                total_clips:
                  responsePrecisionRecall.total_clips +
                  previousState.total_clips,
                precision: precisionResponse,
                recall: recallResposne,
              };
            });
            setNoDataToDisplaySentinel(false);
            setFetchingFilteredDataSentinel(false);
            setLoadingAdditionalData(false);
          }
          if (results[0] && !moreThan100) {
            setNoDataToDisplaySentinel(false);
            setSentinelData((previousState: any) => {
              return addSelectedParam(
                [...previousState, ...results],
                setClipsIndexOrderSentinel,
                lastFileId
              );
            });
            const responsePrecisionRecall = totalUpPrecisionRecallData(
              response.data.summary
            );
            setPrecisionRecallDataSentinel((previousState: any) => {
              let precisionResponse = previousState.precision;
              let recallResposne = previousState.recall;
              if (!precisionResponse) {
                precisionResponse = responsePrecisionRecall.precision;
              }
              if (!recallResposne) {
                recallResposne = responsePrecisionRecall.recall;
              }

              return {
                date: responsePrecisionRecall.date,
                date_str: responsePrecisionRecall.date_str,
                true_positives:
                  responsePrecisionRecall.true_positives +
                  previousState.true_positives,
                false_positives:
                  responsePrecisionRecall.false_positives +
                  previousState.false_positives,
                false_negatives:
                  responsePrecisionRecall.false_negatives +
                  previousState.false_negatives,
                true_negatives:
                  responsePrecisionRecall.true_negatives +
                  previousState.true_negatives,
                unclassified:
                  responsePrecisionRecall.unclassified +
                  previousState.unclassified,
                total_clips:
                  responsePrecisionRecall.total_clips +
                  previousState.total_clips,
                precision: precisionResponse,
                recall: recallResposne,
              };
            });
            setSelectedRowSentinel(results[0]);
            AxiosUI.get(
              `clip/info/?username=${userFiltersSentinel.user}&file_id=${results[0].file_id}`
            ).then((response: AxiosResponse) => {
              if (response.data.file_id) {
                for (const key in response.data) {
                  setSelectedRowSentinel((previousState: any) => {
                    return {
                      ...previousState,
                      [key]: response.data[key],
                    };
                  });
                }
                localStorage.setItem(
                  "investigationSelectedRow",
                  JSON.stringify(selectedRowSentinel)
                );
                AxiosUI.get(
                  `clip/url/?username=${userFiltersSentinel.user}&file_id=${response.data.file_id}`
                ).then(
                  (response: AxiosResponse) => {
                    setVideoURLSentinel(response.data);
                    setVideoClipLoadingSentinel(false);
                    setFetchingFilteredDataSentinel(false);
                    setLoadingAdditionalData(false);
                  },
                  (reason: AxiosError) => {
                    universalAPIErrorHandler(
                      reason,
                      navigate,
                      retryFunction,
                      () => {
                        setNoDataToDisplaySentinel(false);
                        setVideoClipLoadingSentinel(false);
                        setFetchingFilteredDataSentinel(false);
                        setLoadingAdditionalData(false);
                      }
                    );
                  }
                );
              }
            });
          }
        }
        if (!results[0] && !moreThan100) {
          setNoDataToDisplaySentinel(true);
          setFetchingFilteredDataSentinel(false);
          setLoadingAdditionalData(false);
        }
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setFetchingFilteredDataSentinel(false);
        setPageLoadingSentinel(false);
        setLoadingAdditionalData(false);
      });
    }
  );
};
