import { Alert } from "@mui/material";
import React from "react";

export const CalendarAlerts = ({
  calendarAlerts,
  setCalendarAlerts,
}: {
  calendarAlerts: any;
  setCalendarAlerts: any;
}) => {
  return (
    <div>
      {calendarAlerts.addCalendarSuccess && (
        <Alert
          onClose={() => {
            setCalendarAlerts((previousState: any) => {
              return { ...previousState, addCalendarSuccess: false };
            });
          }}
        >
          {`Calendar ${localStorage.newCalendarName} added successfully.`}
        </Alert>
      )}
      {calendarAlerts.deleteCalendarSuccess && (
        <Alert
          onClose={() => {
            setCalendarAlerts((previousState: any) => {
              return { ...previousState, deleteCalendarSuccess: false };
            });
          }}
        >
          {`Calendar ${localStorage.newCalendarName} deleted successfully.`}
        </Alert>
      )}
      {calendarAlerts.addSitesToCalendarSuccess && (
        <Alert
          onClose={() => {
            setCalendarAlerts((previousState: any) => {
              return { ...previousState, addSitesToCalendarSuccess: false };
            });
          }}
        >
          {`Sites for Calendar ${localStorage.newCalendarName} have been updated successfully.`}
        </Alert>
      )}
    </div>
  );
};
