import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import {
  LoadingData,
  ParentGroupsAlertData,
  UserFilters,
} from "./AnalyticsUtils/analyticsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getCamerasBySite } from "./AnalyticsUtils/getCamerasBySite";
import { NavigateFunction } from "react-router-dom";
import { getFalsePositiveDataAnalyticsPage } from "./AnalyticsUtils/getFalsePositiveDataAnalyticsPage";
import { yyyymmddGenerator } from "./AnalyticsUtils/yyyymmddGenerator";
import { getPercentageMotionFilteredData } from "./AnalyticsUtils/getPercentageMotionFilteredData";
import { getParentGroupsAlertData } from "./AnalyticsUtils/getParentGroupsAlertData";

export const SiteDropDown = ({
  setUserFilters,
  siteOptions,
  setCameraOptions,
  setPageLoading,
  navigate,
  setFalsePostiveData,
  setLoadingData,
  userFilters,
  setPercetageOfMotionData,
  siteDropDownValue,
  setSiteDropDownValue,
  setCameraDropDownValue,
  setAveragePercentageOfMotion,
  setParentGroupsAlertsData,
  setDisplayOnLeftBool,
  setVisibleArray,
  setConversionArray,
  setShowMoreVisible,
  setShowMoreOpen,
  setAlertsGraphName,
  loadingSites,
  setLoadingCameras,
  setSiteOptions,
}: {
  setUserFilters: Dispatcher<any>;
  siteOptions: any;
  setCameraOptions: Dispatcher<any>;
  setPageLoading: Dispatcher<boolean>;
  navigate: NavigateFunction;
  setFalsePostiveData: Dispatcher<any>;
  setLoadingData: Dispatcher<LoadingData>;
  userFilters: any;
  setPercetageOfMotionData: Dispatcher<any>;
  siteDropDownValue: any[];
  setSiteDropDownValue: Dispatcher<any[]>;
  setCameraDropDownValue: Dispatcher<any[]>;
  setAveragePercentageOfMotion: Dispatcher<number>;
  setParentGroupsAlertsData: Dispatcher<ParentGroupsAlertData[]>;
  setDisplayOnLeftBool: Dispatcher<boolean>;
  setVisibleArray: Dispatcher<any[]>;
  setConversionArray: any;
  setShowMoreVisible: Dispatcher<boolean>;
  setShowMoreOpen: Dispatcher<boolean>;
  setAlertsGraphName: any;
  loadingSites: boolean;
  setLoadingCameras: Dispatcher<boolean>;
  setSiteOptions: any;
}) => {
  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  return (
    <div key="site_dropdown">
      <Autocomplete
        multiple
        limitTags={1}
        // fullWidth={true}
        value={siteDropDownValue}
        disablePortal
        options={
          siteOptions[0]
            ? siteOptions.map((object: any) => {
                return object.name;
              })
            : []
        }
        loading={loadingSites}
        onChange={(event, value: string[]) => {
          if (value[0]) {
            const siteIdsArray: string[] = [];
            const siteValuesArray: string[] = [];
            value.forEach((element: string) => {
              siteIdsArray.push(siteIds.get(element));
              siteValuesArray.push(element);
            });
            if (localStorage.filtersSavedClicked === "true") {
              localStorage.setItem(
                "siteDropDownValues",
                JSON.stringify(siteValuesArray)
              );
              localStorage.setItem(
                "defaultUserFilters",
                JSON.stringify({
                  parent_group: userFilters.parent_group,
                  site: siteIdsArray,
                  camera: [],
                  start_date: userFilters.start_date,
                  end_date: userFilters.end_date,
                })
              );
            }
            setSiteDropDownValue(siteValuesArray);
            setCameraDropDownValue([]);
            localStorage.setItem("cameraDropDownValues", "[]");
            // setShowMoreVisible(false);
            // setShowMoreOpen(false);
            getCamerasBySite(
              siteIdsArray,
              navigate,
              setCameraOptions,
              setLoadingCameras
            );
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                site: siteIdsArray,
                camera: [],
              };
            });
            // setLoadingData((previousState) => {
            //   return {
            //     ...previousState,
            //     parentGroupsData: true,
            //     falsePositiveData: true,
            //     percetageOfMotionData: true,
            //   };
            // });
            // getParentGroupsAlertData(
            //   setParentGroupsAlertsData,
            //   userFilters.parent_group,
            //   yyyymmddGenerator(userFilters.start_date),
            //   yyyymmddGenerator(userFilters.end_date),
            //   siteIdsArray,
            //   navigate,
            //   setLoadingData,
            //   setDisplayOnLeftBool,
            //   setVisibleArray,
            //   setConversionArray,
            //   setShowMoreVisible,
            //   setShowMoreOpen,
            //   userFilters,
            //   setAlertsGraphName
            // );
            // siteIdsArray[0]
            //   ? getFalsePositiveDataAnalyticsPage(
            //       setFalsePostiveData,
            //       navigate,
            //       setLoadingData,
            //       yyyymmddGenerator(userFilters.start_date),
            //       yyyymmddGenerator(userFilters.end_date),
            //       siteIdsArray,
            //       userFilters.camera,
            //       userFilters.parent_group
            //     )
            //   : getFalsePositiveDataAnalyticsPage(
            //       setFalsePostiveData,
            //       navigate,
            //       setLoadingData,
            //       yyyymmddGenerator(userFilters.start_date),
            //       yyyymmddGenerator(userFilters.end_date),
            //       siteIdsArray,
            //       [],
            //       userFilters.parent_group
            //     );
            // siteIdsArray[0]
            //   ? getPercentageMotionFilteredData(
            //       setPercetageOfMotionData,
            //       setAveragePercentageOfMotion,
            //       navigate,
            //       setLoadingData,
            //       yyyymmddGenerator(userFilters.start_date),
            //       yyyymmddGenerator(userFilters.end_date),
            //       siteIdsArray,
            //       userFilters.camera,
            //       userFilters.parent_group
            //     )
            //   : getPercentageMotionFilteredData(
            //       setPercetageOfMotionData,
            //       setAveragePercentageOfMotion,
            //       navigate,
            //       setLoadingData,
            //       yyyymmddGenerator(userFilters.start_date),
            //       yyyymmddGenerator(userFilters.end_date),
            //       siteIdsArray,
            //       [],
            //       userFilters.parent_group
            //     );
          } else {
            setSiteDropDownValue([]);
            setCameraDropDownValue([]);
            setCameraOptions([]);
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                site: [],
                camera: [],
              };
            });
            if (localStorage.filtersSavedClicked === "true") {
              localStorage.setItem("siteDropDownValues", JSON.stringify([]));
              localStorage.setItem("cameraDropDownValues", JSON.stringify([]));
              localStorage.setItem(
                "defaultUserFilters",
                JSON.stringify({
                  parent_group: userFilters.parent_group,
                  site: [],
                  camera: [],
                  start_date: userFilters.start_date,
                  end_date: userFilters.end_date,
                })
              );
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sites"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingSites ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
            sx={{ width: 285 }}
          />
        )}
      />
    </div>
  );
};
