import React from "react";
import { AlertData, EnhancedTableProps } from "./AlertsUtils/alertsInterfaces";
import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { HeadCell } from "./AlertsUtils/alertsInterfaces";
import { visuallyHidden } from "@mui/utils";
import { handleSelectAllClick } from "./AlertsUtils/alertsTableFunctions";

const headCells: readonly HeadCell[] = [
  {
    id: "display_name",
    numeric: false,
    label: "Site",
  },
  {
    id: "camera_name",
    numeric: false,
    label: "Camera",
  },
  {
    id: "formatted_date",
    numeric: true,
    label: `Site's Time`,
  },
  {
    id: "alert_labels",
    numeric: false,
    label: "Type",
  },
  {
    id: "detection_type",
    numeric: false,
    label: "Detection",
  },
];

export const EnhancedAlertsTableHead = (props: EnhancedTableProps) => {
  const {
    onRequestSort,
    setSelected,
    alertData,
    orderBy,
    order,
    rowCount,
    numSelected,
    setAlertData,
  } = props;
  const createSortHandler =
    (property: keyof AlertData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          key={"seen"}
          align="left"
          sortDirection={orderBy === "seen" ? order : false}
          sx={{ width: "5px" }}
        >
          <TableSortLabel
            active={orderBy === "seen"}
            direction={orderBy === "seen" ? order : "asc"}
            onClick={createSortHandler("seen")}
          >
            {orderBy === "seen" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            onChange={(event: any) => {
              handleSelectAllClick(event, alertData, setAlertData);
            }}
            inputProps={{
              "aria-label": "select all",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            // className="font-bold"
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: `20%`,
            }}
            sx={{
              fontFamily: "mulish",
              fontWeight: 700,
              fontSize: 10,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell /> */}
      </TableRow>
    </TableHead>
  );
};
