import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { addSelectedParam } from "./addSelectedParam";
import { handleButtonShading } from "./handleButtonShading";
import { yyyymmddGenerator } from "../../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { totalUpPrecisionRecallData } from "./totalUpPrecisionRecallData";

export const getAILinkClipsWithQuerry = (
  setInvestigationData: any,
  setPageLoading: Dispatcher<boolean>,
  setSelectedRow: any,
  setPrecisionRecallData: any,
  setVideoURL: any,
  setVideoClipLoading: Dispatcher<boolean>,
  setClipsIndexOrder: any,
  setVerifiedClicked: Dispatcher<boolean>,
  setUnverifiedClicked: Dispatcher<boolean>,
  selectedRow: any,
  setNoDataToDisplay: Dispatcher<boolean>,
  setSiteOptions: any,
  setLoadingSites: any,
  userFilters: any,
  setFetchingFilteredData: Dispatcher<boolean>,
  setPage: any,
  moreThan100: boolean,
  lastTimeStamp: string,
  lastFileId: string,
  setLoadingAdditionalData: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    getAILinkClipsWithQuerry(
      setInvestigationData,
      setPageLoading,
      setSelectedRow,
      setPrecisionRecallData,
      setVideoURL,
      setVideoClipLoading,
      setClipsIndexOrder,
      setVerifiedClicked,
      setUnverifiedClicked,
      selectedRow,
      setNoDataToDisplay,
      setSiteOptions,
      setLoadingSites,
      userFilters,
      setFetchingFilteredData,
      setPage,
      moreThan100,
      lastTimeStamp,
      lastFileId,
      setLoadingAdditionalData,
      navigate
    );
  };

  let searchParamsString = `&start_date=${yyyymmddGenerator(
    userFilters.start_date
  )}&end_date=${yyyymmddGenerator(userFilters.end_date)}`;

  if (userFilters.ground_truth) {
    searchParamsString += `&ground_truth=${userFilters.ground_truth}`;
  }

  if (userFilters.clip_result) {
    searchParamsString += `&clip_result=${userFilters.clip_result}`;
  }

  if (userFilters.site_ids[0]) {
    searchParamsString += `&site_ids=${userFilters.site_ids[0]}`;
  }

  if (userFilters.camera_ids[0]) {
    searchParamsString += `&camera_ids=${userFilters.camera_ids[0]}`;
  }

  if (lastTimeStamp) {
    searchParamsString += `&last_timestamp=${lastTimeStamp}`;
  }

  if (lastFileId) {
    searchParamsString += `&last_file_id=${lastFileId}`;
  }

  searchParamsString += `&page_size=100`;

  let dataResponse: any;

  let last_timestamp = lastTimeStamp;
  let last_file_id = lastFileId;

  AxiosUI.get(`clip/?username=${userFilters.user}${searchParamsString}`).then(
    (response: AxiosResponse) => {
      dataResponse = response.data;
      let lastFileIdAILink = "";
      if (localStorage.last_file_id_ailink) {
        lastFileIdAILink = JSON.parse(localStorage.last_file_id_ailink);
      }
      let lastTimeStampAILink = "";
      if (localStorage.last_timestamp_ailink) {
        lastTimeStampAILink = JSON.parse(localStorage.last_timestamp_ailink);
      }
      if (
        lastFileIdAILink === lastFileId &&
        lastTimeStampAILink === lastTimeStamp
      ) {
        const results = response.data.results;
        if (response.data.count === 0) {
          setNoDataToDisplay(true);
          setPageLoading(false);
          setVideoClipLoading(false);
          setFetchingFilteredData(false);
        }
        if (response.data.count === 100) {
          setInvestigationData((previousState: any) => {
            return addSelectedParam(
              [...previousState, ...results],
              setClipsIndexOrder,
              lastFileId
            );
          });
          const responsePrecisionRecall = totalUpPrecisionRecallData(
            response.data.summary
          );
          setPrecisionRecallData((previousState: any) => {
            let precisionResponse = previousState.precision;
            let recallResposne = previousState.recall;
            if (!precisionResponse) {
              precisionResponse = responsePrecisionRecall.precision;
            }
            if (!recallResposne) {
              recallResposne = responsePrecisionRecall.recall;
            }

            return {
              date: responsePrecisionRecall.date,
              date_str: responsePrecisionRecall.date_str,
              true_positives:
                responsePrecisionRecall.true_positives +
                previousState.true_positives,
              false_positives:
                responsePrecisionRecall.false_positives +
                previousState.false_positives,
              false_negatives:
                responsePrecisionRecall.false_negatives +
                previousState.false_negatives,
              true_negatives:
                responsePrecisionRecall.true_negatives +
                previousState.true_negatives,
              unclassified:
                responsePrecisionRecall.unclassified +
                previousState.unclassified,
              total_clips:
                responsePrecisionRecall.total_clips + previousState.total_clips,
              precision: precisionResponse,
              recall: recallResposne,
            };
          });
          localStorage.setItem(
            "last_file_id_ailink",
            JSON.stringify(response.data.last_file_id)
          );
          localStorage.setItem(
            "last_timestamp_ailink",
            JSON.stringify(response.data.last_timestamp)
          );
          last_timestamp = response.data.last_timestamp;
          last_file_id = response.data.last_file_id;
          if (lastFileId === "") {
            setSelectedRow(results[0]);
            handleButtonShading(
              results[0],
              setVerifiedClicked,
              setUnverifiedClicked
            );
            AxiosUI.get(
              `clip/info/?username=${userFilters.user}&file_id=${results[0].file_id}`
            ).then(
              (response: AxiosResponse) => {
                if (response.data.file_id) {
                  for (const key in response.data) {
                    setSelectedRow((previousState: any) => {
                      return {
                        ...previousState,
                        [key]: response.data[key],
                      };
                    });
                  }
                  localStorage.setItem(
                    "investigationsSelectedRow",
                    JSON.stringify(selectedRow)
                  );
                  AxiosUI.get(
                    `clip/url/?username=${userFilters.user}&file_id=${response.data.file_id}`
                  ).then(
                    (response: AxiosResponse) => {
                      setNoDataToDisplay(false);
                      setVideoURL(response.data);
                      setPageLoading(false);
                      setVideoClipLoading(false);
                      setFetchingFilteredData(false);
                      if (last_timestamp && last_file_id) {
                        getAILinkClipsWithQuerry(
                          setInvestigationData,
                          setPageLoading,
                          setSelectedRow,
                          setPrecisionRecallData,
                          setVideoURL,
                          setVideoClipLoading,
                          setClipsIndexOrder,
                          setVerifiedClicked,
                          setUnverifiedClicked,
                          selectedRow,
                          setNoDataToDisplay,
                          setSiteOptions,
                          setLoadingSites,
                          userFilters,
                          setFetchingFilteredData,
                          setPage,
                          true,
                          last_timestamp,
                          last_file_id,
                          setLoadingAdditionalData,
                          navigate
                        );
                      } else {
                        if (dataResponse.results[0]) {
                          if (dataResponse.results[0].file_id) {
                            AxiosUI.get(
                              `clip/info/?username=${userFilters.user}&file_id=${results[0].file_id}`
                            ).then((response: AxiosResponse) => {
                              if (response.data.event_timestamp) {
                                localStorage.setItem(
                                  "last_file_id_ailink",
                                  JSON.stringify(response.data.file_id)
                                );
                                localStorage.setItem(
                                  "last_timestamp_ailink",
                                  JSON.stringify(response.data.event_timestamp)
                                );
                                getAILinkClipsWithQuerry(
                                  setInvestigationData,
                                  setPageLoading,
                                  setSelectedRow,
                                  setPrecisionRecallData,
                                  setVideoURL,
                                  setVideoClipLoading,
                                  setClipsIndexOrder,
                                  setVerifiedClicked,
                                  setUnverifiedClicked,
                                  selectedRow,
                                  setNoDataToDisplay,
                                  setSiteOptions,
                                  setLoadingSites,
                                  userFilters,
                                  setFetchingFilteredData,
                                  setPage,
                                  true,
                                  response.data.event_timestamp,
                                  response.data.file_id,
                                  setLoadingAdditionalData,
                                  navigate
                                );
                              }
                            });
                          }
                        }
                      }
                    },
                    (reason: AxiosError) => {
                      universalAPIErrorHandler(
                        reason,
                        navigate,
                        retryFunction,
                        () => {
                          setNoDataToDisplay(false);
                          setPageLoading(false);
                          setVideoClipLoading(false);
                          setFetchingFilteredData(false);
                          setLoadingAdditionalData(false);
                        }
                      );
                    }
                  );
                }
              },
              (reason: AxiosError) => {
                universalAPIErrorHandler(
                  reason,
                  navigate,
                  retryFunction,
                  () => {
                    setPageLoading(false);
                    setVideoClipLoading(false);
                    setFetchingFilteredData(false);
                  }
                );
              }
            );
          } else {
            localStorage.setItem(
              "last_file_id_ailink",
              JSON.stringify(response.data.last_file_id)
            );
            localStorage.setItem(
              "last_timestamp_ailink",
              JSON.stringify(response.data.last_timestamp)
            );
            if (last_timestamp && last_file_id) {
              getAILinkClipsWithQuerry(
                setInvestigationData,
                setPageLoading,
                setSelectedRow,
                setPrecisionRecallData,
                setVideoURL,
                setVideoClipLoading,
                setClipsIndexOrder,
                setVerifiedClicked,
                setUnverifiedClicked,
                selectedRow,
                setNoDataToDisplay,
                setSiteOptions,
                setLoadingSites,
                userFilters,
                setFetchingFilteredData,
                setPage,
                true,
                last_timestamp,
                last_file_id,
                setLoadingAdditionalData,
                navigate
              );
            } else {
              if (dataResponse.results[0]) {
                if (dataResponse.results[0].file_id) {
                  AxiosUI.get(
                    `clip/info/?username=${userFilters.user}&file_id=${results[0].file_id}`
                  ).then((response: AxiosResponse) => {
                    if (response.data.event_timestamp) {
                      localStorage.setItem(
                        "last_file_id_ailink",
                        JSON.stringify(response.data.file_id)
                      );
                      localStorage.setItem(
                        "last_timestamp_ailink",
                        JSON.stringify(response.data.event_timestamp)
                      );
                      getAILinkClipsWithQuerry(
                        setInvestigationData,
                        setPageLoading,
                        setSelectedRow,
                        setPrecisionRecallData,
                        setVideoURL,
                        setVideoClipLoading,
                        setClipsIndexOrder,
                        setVerifiedClicked,
                        setUnverifiedClicked,
                        selectedRow,
                        setNoDataToDisplay,
                        setSiteOptions,
                        setLoadingSites,
                        userFilters,
                        setFetchingFilteredData,
                        setPage,
                        true,
                        response.data.event_timestamp,
                        response.data.file_id,
                        setLoadingAdditionalData,
                        navigate
                      );
                    }
                  });
                }
              }
            }
          }
        }
        if (response.data.count < 100) {
          if (results[0] && moreThan100) {
            setInvestigationData((previousState: any) => {
              return addSelectedParam(
                [...previousState, ...results],
                setClipsIndexOrder,
                lastFileId
              );
            });
            const responsePrecisionRecall = totalUpPrecisionRecallData(
              response.data.summary
            );
            setPrecisionRecallData((previousState: any) => {
              let precisionResponse = previousState.precision;
              let recallResposne = previousState.recall;
              if (!precisionResponse) {
                precisionResponse = responsePrecisionRecall.precision;
              }
              if (!recallResposne) {
                recallResposne = responsePrecisionRecall.recall;
              }

              return {
                date: responsePrecisionRecall.date,
                date_str: responsePrecisionRecall.date_str,
                true_positives:
                  responsePrecisionRecall.true_positives +
                  previousState.true_positives,
                false_positives:
                  responsePrecisionRecall.false_positives +
                  previousState.false_positives,
                false_negatives:
                  responsePrecisionRecall.false_negatives +
                  previousState.false_negatives,
                true_negatives:
                  responsePrecisionRecall.true_negatives +
                  previousState.true_negatives,
                unclassified:
                  responsePrecisionRecall.unclassified +
                  previousState.unclassified,
                total_clips:
                  responsePrecisionRecall.total_clips +
                  previousState.total_clips,
                precision: precisionResponse,
                recall: recallResposne,
              };
            });
            setNoDataToDisplay(false);
            setPageLoading(false);
            setFetchingFilteredData(false);
            setLoadingAdditionalData(false);
          }
          if (results[0] && !moreThan100) {
            setSelectedRow(results[0]);
            handleButtonShading(
              results[0],
              setVerifiedClicked,
              setUnverifiedClicked
            );
            setInvestigationData((previousState: any) => {
              return addSelectedParam(
                [...previousState, ...results],
                setClipsIndexOrder,
                lastFileId
              );
            });
            const responsePrecisionRecall = totalUpPrecisionRecallData(
              response.data.summary
            );
            setPrecisionRecallData((previousState: any) => {
              let precisionResponse = previousState.precision;
              let recallResposne = previousState.recall;
              if (!precisionResponse) {
                precisionResponse = responsePrecisionRecall.precision;
              }
              if (!recallResposne) {
                recallResposne = responsePrecisionRecall.recall;
              }

              return {
                date: responsePrecisionRecall.date,
                date_str: responsePrecisionRecall.date_str,
                true_positives:
                  responsePrecisionRecall.true_positives +
                  previousState.true_positives,
                false_positives:
                  responsePrecisionRecall.false_positives +
                  previousState.false_positives,
                false_negatives:
                  responsePrecisionRecall.false_negatives +
                  previousState.false_negatives,
                true_negatives:
                  responsePrecisionRecall.true_negatives +
                  previousState.true_negatives,
                unclassified:
                  responsePrecisionRecall.unclassified +
                  previousState.unclassified,
                total_clips:
                  responsePrecisionRecall.total_clips +
                  previousState.total_clips,
                precision: precisionResponse,
                recall: recallResposne,
              };
            });
            AxiosUI.get(
              `clip/info/?username=${userFilters.user}&file_id=${results[0].file_id}`
            ).then(
              (response: AxiosResponse) => {
                if (response.data.file_id) {
                  for (const key in response.data) {
                    setSelectedRow((previousState: any) => {
                      return {
                        ...previousState,
                        [key]: response.data[key],
                      };
                    });
                  }
                  localStorage.setItem(
                    "investigationsSelectedRow",
                    JSON.stringify(selectedRow)
                  );
                  AxiosUI.get(
                    `clip/url/?username=${userFilters.user}&file_id=${response.data.file_id}`
                  ).then(
                    (response: AxiosResponse) => {
                      setNoDataToDisplay(false);
                      setVideoURL(response.data);
                      setPageLoading(false);
                      setVideoClipLoading(false);
                      setFetchingFilteredData(false);
                      setLoadingAdditionalData(false);
                    },
                    (reason: AxiosError) => {
                      universalAPIErrorHandler(
                        reason,
                        navigate,
                        retryFunction,
                        () => {
                          setPageLoading(false);
                          setVideoClipLoading(false);
                          setLoadingAdditionalData(false);
                        }
                      );
                    }
                  );
                }
              },
              (reason: AxiosError) => {
                universalAPIErrorHandler(
                  reason,
                  navigate,
                  retryFunction,
                  () => {
                    setNoDataToDisplay(false);
                    setPageLoading(false);
                    setVideoClipLoading(false);
                    setFetchingFilteredData(false);
                    setLoadingAdditionalData(false);
                  }
                );
              }
            );
          }
          if (!results[0] && !moreThan100) {
            setNoDataToDisplay(true);
            setPageLoading(false);
            setLoadingAdditionalData(false);
          }
        }
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setPageLoading(false);
        setLoadingAdditionalData(false);
      });
    }
  );
};
