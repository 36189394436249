import React from "react";

export const handleCreateMatchingSchedules = (grid: any) => {
  const newMatchingSchedules: number[][] = [];
  for (let i = 0; i < grid.length; i++) {
    const matchingScheduleIndexes: number[] = [];
    for (let j = 0; j < grid.length; j++) {
      if (grid[i].toString() === grid[j].toString()) {
        matchingScheduleIndexes.push(j);
      }
    }
    newMatchingSchedules.push(matchingScheduleIndexes);
  }
  // check the newMatchingSchedules to see if any subarrays are the same, if they are, remove the duplicate subarray
  for (let i = 0; i < newMatchingSchedules.length; i++) {
    for (let j = i + 1; j < newMatchingSchedules.length; j++) {
      if (
        newMatchingSchedules[i].toString() ===
        newMatchingSchedules[j].toString()
      ) {
        newMatchingSchedules.splice(j, 1);
      }
    }
  }
  for (let i = 0; i < newMatchingSchedules.length; i++) {
    for (let j = i + 1; j < newMatchingSchedules.length; j++) {
      if (
        newMatchingSchedules[i].toString() ===
        newMatchingSchedules[j].toString()
      ) {
        newMatchingSchedules.splice(j, 1);
      }
    }
  }
  return newMatchingSchedules;
};
