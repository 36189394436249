import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { handleSubmitNewImmixAlert } from "./EditCameraUtils/handleSubmitNewImmixAlert";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const AddImmixAlertDialog = ({
  setAddImmixAlertDialogOpen,
  addImmixAlertDialogOpen,
  immixAlerts,
  setBackdropLoadingScreenOn,
  setCameraGeneralInfo,
  setCameraGeneralInfoBackup,
  setGeneralInfoChangesMade,
  setPatchObject,
  setImmixAlerts,
  setActiveTab,
  cameraGeneralInfoBackup,
  setIgnoreZonesBreadCrumbs,
  handleSitesBreadcrumbClick,
  handleSingularSiteBreadcrumbClick,
  cameraGeneralInfo,
  setSendingImmixTest,
  setImmixTestSuccessDialogOpen,
  setImmixTestFailDialogOpen,
}: {
  setAddImmixAlertDialogOpen: any;
  addImmixAlertDialogOpen: any;
  immixAlerts: any;
  setBackdropLoadingScreenOn: any;
  setCameraGeneralInfo: any;
  setCameraGeneralInfoBackup: any;
  setGeneralInfoChangesMade: any;
  setPatchObject: any;
  setImmixAlerts: any;
  setActiveTab: any;
  cameraGeneralInfoBackup: any;
  setIgnoreZonesBreadCrumbs: any;
  handleSitesBreadcrumbClick: any;
  handleSingularSiteBreadcrumbClick: any;
  cameraGeneralInfo: any;
  setSendingImmixTest: Dispatcher<boolean>;
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>;
  setImmixTestFailDialogOpen: Dispatcher<boolean>;
}) => {
  const navigate = useNavigate();

  const [postObject, setPostObject] = React.useState({
    id: -1,
    immix_server: "",
    immix_port: "",
    immix_email: "",
    immix_input1: "",
  });

  immixAlerts = [];

  React.useEffect(() => {
    if (immixAlerts[0]) {
      setPostObject({
        id: -1,
        immix_server: immixAlerts[0].immix_server,
        immix_port: immixAlerts[0].immix_port,
        immix_email: immixAlerts[0].immix_email,
        immix_input1: immixAlerts[0].immix_input1,
      });
    }
  }, []);

  return (
    <Dialog
      open={addImmixAlertDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Immix Alert</DialogTitle>
      <DialogContent>
        <TextField
          id="immix_server"
          variant="standard"
          label="Immix Server"
          defaultValue={immixAlerts[0] ? immixAlerts[0].immix_server : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, immix_server: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          helperText="What is the url for your immix server."
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="immix_port"
          variant="standard"
          label="Immix Port"
          defaultValue={immixAlerts[0] ? immixAlerts[0].immix_port : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, immix_port: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          helperText="What is the port for your immix server."
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="immix_email"
          variant="standard"
          label="Immix email"
          defaultValue={immixAlerts[0] ? immixAlerts[0].immix_email : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, immix_email: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          id="immix_input1"
          variant="standard"
          label="Immix Input1"
          defaultValue={immixAlerts[0] ? immixAlerts[0].immix_input1 : ""}
          onChange={(event) => {
            setPostObject((previousState: any) => {
              return { ...previousState, immix_input1: event.target.value };
            });
          }}
          sx={{ width: "400px" }}
          type="string"
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddImmixAlertDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setAddImmixAlertDialogOpen(false);
            setBackdropLoadingScreenOn(true);
            handleSubmitNewImmixAlert(
              cameraGeneralInfo,
              immixAlerts,
              postObject,
              navigate,
              setBackdropLoadingScreenOn,
              setCameraGeneralInfo,
              setCameraGeneralInfoBackup,
              setGeneralInfoChangesMade,
              setPatchObject,
              setImmixAlerts,
              setActiveTab,
              setAddImmixAlertDialogOpen,
              cameraGeneralInfoBackup,
              setIgnoreZonesBreadCrumbs,
              handleSitesBreadcrumbClick,
              handleSingularSiteBreadcrumbClick,
              setSendingImmixTest,
              setImmixTestSuccessDialogOpen,
              setImmixTestFailDialogOpen
            );
          }}
          text="Submit"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
