import React from "react";
import { yyyymmddGenerator } from "./yyyymmddGenerator";

export const getDatesFromDefault = (
  inputDate: string,
  setUserFilters: any,
  inputString: string,
  amountToReduce = 0
) => {
  if (amountToReduce > 93) {
    return;
  }
  const date = new Date();
  date.setDate(date.getDate() - amountToReduce);
  if (inputDate === yyyymmddGenerator(date)) {
    if (inputString === "start") {
      setUserFilters((previousState: any) => {
        return { ...previousState, start_date: date };
      });
    }
    if (inputString === "end") {
      setUserFilters((previousState: any) => {
        return { ...previousState, end_date: date };
      });
    }
    return;
  }
  if (inputDate !== yyyymmddGenerator(date)) {
    getDatesFromDefault(
      inputDate,
      setUserFilters,
      inputString,
      (amountToReduce += 1)
    );
  }
};
