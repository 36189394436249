import axios from "axios";
import { getCookie } from "./getCookie";
import { getApiUrl } from "./getApiUrl";

export const createMonitoringAPICall = (path: string) => {
  const baseURL = getApiUrl();
  return axios.create({
    baseURL: `${baseURL}monitoring-api/${path}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    },
  });
};
