import React from "react";

export const createErrorsOverviewData = (
  alertsData: any,
  setErrorsOverviewData: any
) => {
  const updatedErrorsOverviewData = {
    connectivity: 0,
    image_quality: 0,
    // license_info: 0,
    motion_status: 0,
    recording_status: 0,
    scene_change: 0,
    stream_quality: 0,
  };
  alertsData.all.forEach((object: any) => {
    if (object.status === "ongoing" || object.status === "pending") {
      if (object.error_type === "connectivity") {
        updatedErrorsOverviewData.connectivity =
          updatedErrorsOverviewData.connectivity + 1;
      }
      if (object.error_type === "image_quality") {
        updatedErrorsOverviewData.image_quality =
          updatedErrorsOverviewData.image_quality + 1;
      }
      // if (object.error_type === "license_info") {
      //   updatedErrorsOverviewData.license_info =
      //     updatedErrorsOverviewData.license_info + 1;
      // }
      if (object.error_type === "motion_status") {
        updatedErrorsOverviewData.motion_status =
          updatedErrorsOverviewData.motion_status + 1;
      }
      if (object.error_type === "recording_status") {
        updatedErrorsOverviewData.recording_status =
          updatedErrorsOverviewData.recording_status + 1;
      }
      if (object.error_type === "scene_change") {
        updatedErrorsOverviewData.scene_change =
          updatedErrorsOverviewData.scene_change + 1;
      }
      if (object.error_type === "stream_quality") {
        updatedErrorsOverviewData.stream_quality =
          updatedErrorsOverviewData.stream_quality + 1;
      }
    }
  });
  setErrorsOverviewData(updatedErrorsOverviewData);
};
