import React from "react";
import { NavigateFunction } from "react-router-dom";

export const handleNavigateToAnalytics = (
  siteName: string,
  parentGroupId: string,
  siteId: string,
  navigate: NavigateFunction
) => {
  localStorage.setItem("navigatedToAnalyticsFromSitesPage", "true");
  localStorage.setItem("analyticsSiteName", siteName);
  localStorage.setItem("analyticsParentGroupId", parentGroupId);
  localStorage.setItem("analyticsSiteId", siteId);
  navigate("/analytics");
};
