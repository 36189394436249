import React from "react";
import { UnverifiedButton } from "./UnverifiedButton";
import { VerifiedButton } from "./VerifiedButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";

export const ClipVerificationBox = ({
  verifiedClicked,
  unverifiedClicked,
  setVerifiedClicked,
  setUnverifiedClicked,
  selectedRow,
  investigationData,
  setInvestigationData,
  setSelectedRow,
  setPageLoading,
  navBarCollapsed,
  navigate,
}: {
  verifiedClicked: boolean;
  unverifiedClicked: boolean;
  setVerifiedClicked: Dispatcher<boolean>;
  setUnverifiedClicked: Dispatcher<boolean>;
  selectedRow: any;
  investigationData: any;
  setInvestigationData: any;
  setSelectedRow: any;
  setPageLoading: any;
  navBarCollapsed: boolean;
  navigate: NavigateFunction;
}) => {
  return (
    <div className="relative left-[0px] w-[600px] h-[60px] rounded-lg  bg-white">
      <div className="flex items-center justify-between w-[580px] pl-[15px] pt-[10px]">
        <div className="text-actuate-blue font-mulish text-[16px]">
          Clip Verification Status
        </div>
        <div className="w-[170px]">
          <UnverifiedButton
            unverifiedClicked={unverifiedClicked}
            setVerifiedClicked={setVerifiedClicked}
            setUnverifiedClicked={setUnverifiedClicked}
            selectedRow={selectedRow}
            investigationData={investigationData}
            setInvestigationData={setInvestigationData}
            setSelectedRow={setSelectedRow}
            setPageLoading={setPageLoading}
            navigate={navigate}
          />
        </div>
        <div className={"w-[170px]"}>
          <VerifiedButton
            verifiedClicked={verifiedClicked}
            setVerifiedClicked={setVerifiedClicked}
            setUnverifiedClicked={setUnverifiedClicked}
            selectedRow={selectedRow}
            investigationData={investigationData}
            setInvestigationData={setInvestigationData}
            setSelectedRow={setSelectedRow}
            setPageLoading={setPageLoading}
            navigate={navigate}
          />
        </div>
      </div>
    </div>
  );
};
