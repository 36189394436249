import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { handleAddImmixToAddCameraForm } from "../../../AddSite/handleAddImmixToAddCameraForm";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const fetchAddCameraInfo = async (id: string, navigate: any) => {
  const AxiosUI = createApi(`customer/${id}/camera_onboarding_forms/`);
  const retryFunction = () => {
    fetchAddCameraInfo(id, navigate);
  };
  await AxiosUI.get("").then(
    (response: AxiosResponse) => {
      // handleAddImmixToAddCameraForm(response.data);
      localStorage.setItem("addCameraInfo", JSON.stringify(response.data));
      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(response.data.initial_data.products)
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert(`${reason}`);
      });
    }
  );
};
