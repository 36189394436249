import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createDetctionTypeIcon } from "./AlertsUtils/createDetctionTypeIcon";
import EditIcon from "@mui/icons-material/Edit";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { trimSelectedUserName } from "./AlertsUtils/trimSelectedUserName";
import { handleCreateAlertTypeNoUnderscore } from "./AlertsTable";

const CameraInfoTable = ({
  selectedRow,
  setNoteText,
  setNotesDialogOpen,
}: {
  selectedRow: any;
  setNoteText: any;
  setNotesDialogOpen: any;
}) => {
  const epochToDateTime = (epoch: number) => {
    const date = new Date(epoch * 1000);
    let month = (date.getMonth() + 1).toString();
    if (month.length !== 2) {
      month = `0${month}`;
    }
    let day = date.getDate().toString();
    if (day.length !== 2) {
      day = `0${day}`;
    }
    const year = date.getFullYear();
    let hour = date.getHours();
    if (hour >= 12) {
      hour = hour - 12;
    }
    let newHour = hour.toString();
    if (newHour.length !== 2) {
      newHour = `0${newHour}`;
    }
    let minute = date.getMinutes().toString();
    if (minute.length !== 2) {
      minute = `0${minute}`;
    }
    let second = date.getSeconds().toString();
    if (second.length !== 2) {
      second = `0${second}`;
    }
    const ampm = date.getHours() >= 12 ? "PM" : "AM";
    return `${month}/${day}/${year}, ${newHour}:${minute}:${second} ${ampm}`;
  };
  return (
    <Paper sx={{ width: "100%", overflow: "scroll" }}>
      <TableContainer>
        <Table sx={{ minWidth: 500, height: 170, fontFamily: "Mulish" }}>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  fontSize: "11px",
                  fontFamily: "mulish",
                  fontWeight: "bold",
                  width: "20%",
                }}
              >
                Alert Type
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontSize: "11px",
                  fontFamily: "mulish",
                  fontWeight: "bold",
                  width: "20%",
                }}
              >
                Time Stamp
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontSize: "11px",
                  fontFamily: "mulish",
                  fontWeight: "bold",
                  width: "20%",
                }}
              >
                Outcome
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontSize: "11px",
                  fontFamily: "mulish",
                  fontWeight: "bold",
                  width: "20%",
                }}
              >
                User
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontSize: "11px",
                  fontFamily: "mulish",
                  fontWeight: "bold",
                  width: "20%",
                }}
              >
                Date Resolved
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontSize: "11px",
                  fontFamily: "mulish",
                  fontWeight: "bold",
                  width: "20%",
                }}
              >
                Notes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="left"
                sx={{ fontSize: "12px", fontFamily: "mulish" }}
              >
                {handleCreateAlertTypeNoUnderscore(selectedRow.alert_labels)}
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "12px", fontFamily: "mulish" }}
              >
                {selectedRow.formatted_date}
                &nbsp;
                <Tooltip
                  title={selectedRow.timezone}
                  placement="top"
                  sx={{ fontSize: "12px", fontFamily: "mulish" }}
                >
                  <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                </Tooltip>
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "12px", fontFamily: "mulish" }}
              >
                {selectedRow.detection_type
                  ? createDetctionTypeIcon(selectedRow.detection_type)
                  : "--"}
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "12px", fontFamily: "mulish" }}
              >
                {selectedRow.user
                  ? trimSelectedUserName(selectedRow.user)
                  : "--"}
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "12px", fontFamily: "mulish" }}
              >
                {selectedRow.updated ? (
                  <div>
                    {epochToDateTime(selectedRow.updated)}
                    &nbsp;
                    <Tooltip
                      title={selectedRow.timezone}
                      placement="top"
                      sx={{ fontSize: "14px", fontFamily: "mulish" }}
                    >
                      <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                  </div>
                ) : (
                  "--"
                )}
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "12px", fontFamily: "mulish" }}
              >
                {/* {selectedRow.updated ? ( */}
                <OrangeTextButton
                  text={<EditIcon fontSize="small" />}
                  onClick={() => {
                    setNoteText(
                      selectedRow.detection_type
                        ? selectedRow.notes
                        : selectedRow.unresolved_notes
                    );
                    setNotesDialogOpen(true);
                  }}
                  disabled={false}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CameraInfoTable;
