import React from "react";

export const createCameraView = (cameraViewString: string) => {
  switch (cameraViewString) {
    case null:
      return "Normal";
    case "normal":
      return "Normal";
    case "fisheye":
      return "Fisheye";
    case "thermal":
      return "Thermal";
  }
};
