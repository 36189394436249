import React from "react";

const getCurrentDate = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  // getMonth() returns month from 0-11; add 1 to get 1-12
  const month = `0${today.getMonth() + 1}`.slice(-2); // Add leading 0 if needed
  const day = `0${today.getDate()}`.slice(-2); // Add leading 0 if needed
  return `${year}${month}${day}`;
};

const formatDate = (date: string): string => {
  return date.replace(/-/g, "");
};

export const checkIfCurrentDate = (inputDate: string) => {
  const currentDate = getCurrentDate();

  const endDate = formatDate(inputDate);

  return Number(currentDate) - Number(endDate) > 1;
};
