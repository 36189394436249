import React from "react";
import { StatusNotificationScreen } from "../../../common/StatusNotificationScreen";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../../Onboarding/OnboardingProgressDiv";
import { handleCreateAutoAddObject } from "./handleCreateAutoAddObject";
import { CameraAutoAddScreen } from "../CameraAutoAddScreen";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { ProgressFinishedScreen } from "../../AddSchedule/ProgressFinishedScreen";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { handleCheckCameras } from "../../CameraCheck/handleCheckCameras";

export const handleSubmitAutoAddCameras = async (
  camerasObject: any,
  setVisibleScreen: any,
  setLargerScreen: Dispatcher<boolean>,
  largerScreen: boolean,
  setCameraCheckFinsihedAlertOpen: any,
  setGoodCameras: any,
  setConnectivityCameras: any,
  setLowResolutionCameras: any,
  setCameraCheckResultDownloadURL: any,
  navigate: any
) => {
  const retryFunction = () => {
    handleSubmitAutoAddCameras(
      camerasObject,
      setVisibleScreen,
      setLargerScreen,
      largerScreen,
      setCameraCheckFinsihedAlertOpen,
      setGoodCameras,
      setConnectivityCameras,
      setLowResolutionCameras,
      setCameraCheckResultDownloadURL,
      navigate
    );
  };
  setLargerScreen(false);
  setVisibleScreen(
    <StatusNotificationScreen
      styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
      text={
        <p>
          Camera Add in process.
          <br />
          This might take a few seconds.
        </p>
      }
    />
  );
  const AxiosUI = createApi(``);
  const ActuateUI = createApi(``);
  let nvrCameraFetchFail = false;
  let siteCamerasFetchFail = false;

  await AxiosUI.post(
    "/nvr_camera/auto_add/",
    handleCreateAutoAddObject(camerasObject)
  ).then(
    (response: AxiosResponse) => {
      nvrCameraFetchFail = false;
      localStorage.setItem("cameraId", response.data.id);
    },
    (reason: AxiosError) => {
      nvrCameraFetchFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        localStorage.setItem("autoAddError", "true");
        setLargerScreen(true);
        setVisibleScreen(
          <CameraAutoAddScreen
            setVisibleScreen={setVisibleScreen}
            setLargerScreen={setLargerScreen}
            largerScreen={largerScreen}
            setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
            setGoodCameras={setGoodCameras}
            setConnectivityCameras={setConnectivityCameras}
            setLowResolutionCameras={setLowResolutionCameras}
            setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
          />
        );
      });
    }
  );
  !nvrCameraFetchFail &&
    (await ActuateUI.get(
      `camera/site/?customer__id=${localStorage.siteId}`
    ).then(
      (response: AxiosResponse) => {
        siteCamerasFetchFail = false;
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
      },
      (reason: AxiosError) => {
        siteCamerasFetchFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !nvrCameraFetchFail &&
    !siteCamerasFetchFail &&
    (await AxiosUI.post(
      `customer/${localStorage.siteId}/action/deploy_next/`
    ).then(
      () => {
        localStorage.setItem("syncNeeded", "false");
        setVisibleScreen(
          <StatusNotificationScreen
            styling="absolute first-line:font-black font-mulish text-[20px] right-[190px] text-center text-[#283E6D] top-80"
            text="Camera Add successful!"
          />
        );
        setTimeout(() => {
          if (
            localStorage.addAdditionalCamera === "true" ||
            localStorage.clickedFromSiteAboutPage === "true"
          ) {
            if (
              validIntegrationTypes.includes(
                localStorage.selectedIntegrationType
              )
            ) {
              localStorage.setItem("cameraCheckAlertOpen", "true");
              localStorage.setItem("clickedId", localStorage.siteId);
              localStorage.setItem("navigatedFromSitesAboutPage", "true");
              localStorage.setItem("level0Click", "true");
              localStorage.setItem("cameraChecksiteId", localStorage.siteId);
              handleCheckCameras(
                setCameraCheckFinsihedAlertOpen,
                setGoodCameras,
                setConnectivityCameras,
                setLowResolutionCameras,
                localStorage.siteId,
                setCameraCheckResultDownloadURL,
                navigate
              );
            }
            navigate(`/sites/aboutsite/${localStorage.siteId}`);
            localStorage.removeItem("addAdditionalCamera");
          } else if (localStorage.healthcheckOnly === "true") {
            setVisibleScreen(<ProgressFinishedScreen />);
            localStorage.setItem("healthcheckOnly", "false");
          } else {
            setVisibleScreen(
              <OnboardingProgressDiv
                percentage={
                  validIntegrationTypes.includes(
                    localStorage.selectedIntegrationType
                  )
                    ? 50
                    : 66
                }
                step={2}
                setVisibleScreen={setVisibleScreen}
              />
            );
          }
        }, 2000);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
