import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, TableHead } from "@mui/material";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleGetEventData } from "../Calendars/CalendarsUtils/handleGetEventData";

export const CalendarsTableAddSchedule = ({
  calendarsData,
  setLoadingEventData,
  setSelectedRow,
  setCalendarEvents,
  navigate,
  setShowMoreDialogOpen,
  selectedCalendars,
  setSelectedCalendars,
}: {
  calendarsData: any;
  setLoadingEventData: any;
  setSelectedRow: any;
  setCalendarEvents: any;
  navigate: any;
  setShowMoreDialogOpen: any;
  selectedCalendars: any;
  setSelectedCalendars: any;
}) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 250, width: 1420 }}>
        <Table
          sx={{ fontFamily: "Mulish" }}
          aria-label="calendars_table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  fontFamily: "mulish",
                  fontWeight: "Bold",
                  maxWidth: 250,
                  minWidth: 200,
                }}
                align="left"
              >
                Group
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  fontFamily: "mulish",
                  fontWeight: "Bold",
                  maxWidth: 250,
                  minWidth: 200,
                }}
                align="left"
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  fontFamily: "mulish",
                  fontWeight: "Bold",
                  maxWidth: 250,
                  minWidth: 200,
                }}
                align="left"
              >
                More Info
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendarsData.map((obj: any, index: any) => {
              return (
                <React.Fragment key={obj.id}>
                  <TableRow role="checkbox" tabIndex={-1}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        defaultChecked={false}
                        onChange={(event, checked) => {
                          // add the obj.id to the selectedCalendars array if checked, if not remove it from the array
                          if (checked) {
                            setSelectedCalendars([
                              ...selectedCalendars,
                              obj.id,
                            ]);
                          } else {
                            setSelectedCalendars(
                              selectedCalendars.filter(
                                (calendarId: number) => calendarId !== obj.id
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                      {obj.group.name}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                      {obj.name}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                      <OrangeTextButton
                        text="SHOW MORE"
                        onClick={() => {
                          setLoadingEventData(true);
                          setSelectedRow(obj);
                          handleGetEventData(
                            setCalendarEvents,
                            setLoadingEventData,
                            obj,
                            navigate
                          );
                          setShowMoreDialogOpen(true);
                        }}
                        disabled={false}
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
