import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher, SiteInfo } from "./siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const siteArmButtonDisableCheckRefresh = (
  siteId: string,
  setArmButtonDisabled: Dispatcher<boolean>,
  navigate: NavigateFunction,
  setSiteInfo: Dispatcher<SiteInfo>,
  setArmedStatus: any
) => {
  const retryFunction = () => {
    siteArmButtonDisableCheckRefresh(
      siteId,
      setArmButtonDisabled,
      navigate,
      setSiteInfo,
      setArmedStatus
    );
  };

  const AxiosUI = createApi(``);

  AxiosUI.get(`customer/${siteId}/about/`).then(
    (response: AxiosResponse) => {
      setArmedStatus(
        response.data.armed_status === "ARMED_OUTSIDE_SCHEDULE"
          ? true
          : response.data.armed
      );
      if (
        response.data.armed_status === "DISARMING" ||
        response.data.armed_status === "ARMING"
      ) {
        setTimeout(() => {
          if (localStorage.armButtonCheckURL === window.location.pathname) {
            siteArmButtonDisableCheckRefresh(
              siteId,
              setArmButtonDisabled,
              navigate,
              setSiteInfo,
              setArmedStatus
            );
          } else {
            localStorage.setItem("armButtonCheckURL", "");
          }
        }, 5000);
      } else {
        setArmButtonDisabled(false);
        setSiteInfo((previousState: SiteInfo) => {
          return {
            ...previousState,
            armed:
              response.data.armed_status === "ARMED_OUTSIDE_SCHEDULE"
                ? true
                : response.data.armed,
            armed_status: response.data.armed_status,
          };
        });
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
