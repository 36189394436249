// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { MobotixTab } from "../MobotixTab";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleAddTimeTable = (
  cameraId: string,
  navigate: NavigateFunction,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setTimeTablesData: any,
  setActiveTab: any,
  iPNotifyData: any,
  setIPNotifyData: any,
  actionHandlerData: any,
  setActionHandlerData: any
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleAddTimeTable(
      cameraId,
      navigate,
      setBackdropLoadingScreenOn,
      setTimeTablesData,
      setActiveTab,
      iPNotifyData,
      setIPNotifyData,
      actionHandlerData,
      setActionHandlerData
    );
  };

  AxiosUI.post(`/mobotix/${cameraId}/timetable/`, {}).then(
    (response: AxiosResponse) => {
      setTimeTablesData(response.data);
      setBackdropLoadingScreenOn(false);
      setActiveTab(<></>);
      setActiveTab(
        <MobotixTab
          timeTablesData={response.data}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setTimeTablesData={setTimeTablesData}
          setActiveTab={setActiveTab}
          iPNotifyData={iPNotifyData}
          setIPNotifyData={setIPNotifyData}
          actionHandlerData={actionHandlerData}
          setActionHandlerData={setActionHandlerData}
        />
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        alert("Problem adding time table, please try again");
      });
    }
  );
};
