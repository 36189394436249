import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const replaceHashWithPercent23 = (inputString: string): string => {
  if (inputString) {
    return inputString.replace(/#/g, "%23");
  } else {
    return "";
  }
};

export const getImageURLS = (
  row: any,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    getImageURLS(row, setBackgroundFrame, setDetectedFrame, navigate);
  };

  const AxiosUI = createApi(``);

  AxiosUI.get(
    `/images/get_image_url/?frame_key=${replaceHashWithPercent23(
      row.background_frame_key
    )}`
  ).then(
    (response: AxiosResponse) => {
      setBackgroundFrame(response.data.frame_url);
      AxiosUI.get(
        `/images/get_image_url/?frame_key=${replaceHashWithPercent23(
          row.detected_frame_key
        )}`
      ).then(
        (response: AxiosResponse) => {
          setDetectedFrame(response.data.frame_url);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
