import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { AlertData, Order } from "../Alerts/AlertsUtils/alertsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import {
  getComparator,
  stableSort,
} from "../Alerts/AlertsUtils/alertsTableFunctions";
import EnhancedCalendarsTableHead from "./EnhancedCalendarsTableHead";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleGetEventData } from "./CalendarsUtils/handleGetEventData";
import { NavigateFunction } from "react-router-dom";
import useWindowDimensions from "../../common/useWindowDimensions";
import { cancelIconRed } from "../../common/cancelIconRed";
import { checkedCircleIcon } from "../../common/checkedCircleIcon";

export const CalendarsTable = ({
  calendarData,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleSelectAllClick,
  isSelected,
  handleSingleCheckboxClick,
  setSelectedRow,
  setShowMoreDialogOpen,
  setLoadingEventData,
  setCalendarEvents,
  setCalendarId,
  setDeleteCalendarDialogOpen,
  setDeletedCalendarId,
  setRowIndex,
  setAssignedSitesDialogOpen,
  navigate,
}: {
  calendarData: any;
  page: number;
  setPage: Dispatcher<number>;
  rowsPerPage: number;
  setRowsPerPage: Dispatcher<number>;
  handleSelectAllClick: any;
  isSelected: any;
  handleSingleCheckboxClick: any;
  setSelectedRow: any;
  setShowMoreDialogOpen: Dispatcher<boolean>;
  setLoadingEventData: any;
  setCalendarEvents: any;
  setCalendarId: any;
  setDeleteCalendarDialogOpen: Dispatcher<boolean>;
  setDeletedCalendarId: Dispatcher<string>;
  setRowIndex: any;
  setAssignedSitesDialogOpen: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("formatted_date");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(calendarData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage]
  );

  const { height, width } = useWindowDimensions();

  React.useEffect(() => {
    setCalculatedHeight(height - 205);
    setCalculatedWidth(width - 150);
  }, [height, width]);

  const [calcuatedHeight, setCalculatedHeight] = React.useState(0);

  const [calcuatedWidth, setCalculatedWidth] = React.useState(0);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{ maxHeight: calcuatedHeight, minWidth: calcuatedWidth }}
      >
        <Table sx={{ fontFamily: "Mulish" }} aria-label="calendars_table">
          <EnhancedCalendarsTableHead
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
          />
          <TableBody>
            {calendarData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((obj: any, index: any) => {
                const isItemSelected = isSelected(obj.id);
                return (
                  <React.Fragment key={obj.id}>
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleSingleCheckboxClick(event, obj.id)
                          }
                        />
                      </TableCell> */}
                      <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                        {obj.name}
                      </TableCell>
                      <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                        {obj.group.name}
                      </TableCell>
                      <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                        {obj.customer[0] ? checkedCircleIcon : cancelIconRed}
                      </TableCell>
                      <TableCell sx={{ fontFamily: "Mulish" }} align="center">
                        <OrangeTextButton
                          text="SHOW MORE"
                          onClick={() => {
                            setRowIndex(index);
                            setLoadingEventData(true);
                            setSelectedRow(obj);
                            setCalendarId(obj.id);
                            handleGetEventData(
                              setCalendarEvents,
                              setLoadingEventData,
                              obj,
                              navigate
                            );
                            setShowMoreDialogOpen(true);
                          }}
                          disabled={false}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(event) => {
                            handleClick(event);
                            setDeletedCalendarId(obj.id);
                            setSelectedRow(obj);
                          }}
                        >
                          <GridMoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          <MenuItem
                            key="apply-to-schedules"
                            onClick={() => {
                              handleClose();
                              setAssignedSitesDialogOpen(true);
                            }}
                          >
                            Assigned Sites
                          </MenuItem>
                          <MenuItem
                            key="delete"
                            onClick={() => {
                              setDeleteCalendarDialogOpen(true);
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={calendarData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
