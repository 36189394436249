import React from "react";
import ErrorBoundary from "../../../ErrorBoundary";
import { StepsStepper } from "./StepsStepper";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { handleSubmitWebhooks } from "./handleSubmitWebhooks";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const AddWebhookScreen = ({
  setVisibleScreen,
  siteId,
}: {
  setVisibleScreen: any;
  siteId: number;
}) => {
  const [webhooksInfo, setWebhooksInfo] = React.useState({
    customer: siteId,
    display_name: "",
    target_url: "",
    method: "POST",
    message_body: "",
  });

  const navigate = useNavigate();

  return (
    <ErrorBoundary>
      <div>
        <div className="absolute top-20 right-[15px]">
          <div className="flex justify-end">
            <StepsStepper activeStep={1} />
          </div>
        </div>
        <p
          className={
            "font-mulish absolute top-[130px] text-[#283E6D] right-[130px] text-[18px]"
          }
        >
          Please complete the following Webhooks information:
        </p>
        <div className="absolute right-[215px] top-[180px]">
          <div className="pb-[20px]">
            <TextField
              onChange={(event) => {
                setWebhooksInfo((previousState: any) => {
                  return { ...previousState, display_name: event.target.value };
                });
              }}
              value={webhooksInfo.display_name}
              label="Display Name"
              helperText=""
              variant="standard"
              sx={{ fontFamily: "Mulish", width: 350 }}
            />
          </div>
          <div className="pb-[20px]">
            <TextField
              onChange={(event) => {
                setWebhooksInfo((previousState: any) => {
                  return { ...previousState, target_url: event.target.value };
                });
              }}
              value={webhooksInfo.target_url}
              label="Target URL"
              helperText=""
              variant="standard"
              sx={{ fontFamily: "Mulish", width: 350 }}
            />
          </div>
          <div className="pb-[20px]">
            <TextField
              multiline
              rows={4}
              onChange={(event) => {
                setWebhooksInfo((previousState: any) => {
                  return { ...previousState, message_body: event.target.value };
                });
              }}
              value={webhooksInfo.message_body}
              label="Message body*"
              helperText="*optional"
              variant="outlined"
              sx={{ fontFamily: "Mulish", width: 350 }}
            />
          </div>

          <div className="relative left-[400px]">
            <ThemeProvider theme={theme}>
              <Button
                className="shadow-button bg-actuate-orange rounded font-mulish text-white text-base p-1.5"
                type="submit"
                disabled={
                  !webhooksInfo.display_name || !webhooksInfo.target_url
                }
                onClick={() => {
                  handleSubmitWebhooks(
                    webhooksInfo,
                    setVisibleScreen,
                    navigate
                  );
                }}
              >
                Submit
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
