import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleGetNewDataUsageData = (
  dateRangeString: string,
  typeString: string,
  setLoadingData: Dispatcher<boolean>,
  navigate: NavigateFunction,
  setUsageData: Dispatcher<any>,
  setDateRangeName: Dispatcher<string>
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleGetNewDataUsageData(
      dateRangeString,
      typeString,
      setLoadingData,
      navigate,
      setUsageData,
      setDateRangeName
    );
  };
  AxiosUI.get(
    `analytics/data_usage/?date_range=${dateRangeString}&order_by=${typeString}`
  ).then(
    (response: AxiosResponse) => {
      const updatedData = response.data;
      for (const key in updatedData) {
        const updatedKey: any = updatedData[key];
        updatedKey.abreviated_name = `${updatedKey.display_name.slice(
          0,
          7
        )}...`;
        if (updatedKey.kbs) {
          const updatedKBS = Math.round(updatedKey.kbs / 10000) / 100;
          updatedKey.kbs = updatedKBS;
        }
      }
      setUsageData(updatedData);
      localStorage.setItem("bandwidthData", JSON.stringify(updatedData));
      setLoadingData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setUsageData(JSON.parse(localStorage.top5DataUsage));
        setDateRangeName("Past 24 Hours");
        setLoadingData(false);
        // alert("Error retreiving date range data. Please try again.");
      });
    }
  );
};
