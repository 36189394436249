import React from "react";
import { yyyymmddGenerator } from "../../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { createApi } from "../../../utils/createApi";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";

export const handleRetryBandwidthDataFetch = (
  filters: any,
  setBandwidthData: any,
  setLoadingBandwidthData: Dispatcher<boolean>,
  setBandwidthLoadingError: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  let searchQueryString = `?start_date=${yyyymmddGenerator(
    filters.start_date
  )}&end_date=${yyyymmddGenerator(filters.end_date)}`;

  if (filters.parent_group) {
    searchQueryString += `&group=${filters.parent_group}`;
  }

  if (filters.site) {
    searchQueryString += `&site=${filters.site}`;
  }

  if (filters.camera) {
    searchQueryString += `&camera=${filters.camera}`;
  }

  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleRetryBandwidthDataFetch(
      filters,
      setBandwidthData,
      setLoadingBandwidthData,
      setBandwidthLoadingError,
      navigate
    );
  };

  AxiosUI.get(`analytics/data_usage/${searchQueryString}&limit=20`).then(
    (response: any) => {
      const updatedData = response.data;
      for (const key in updatedData) {
        const updatedKey: any = updatedData[key];
        updatedKey.abreviated_name = `${updatedKey.display_name.slice(
          0,
          7
        )}...`;
        if (updatedKey.kbs) {
          const updatedKBS = Math.round(updatedKey.kbs / 10000) / 100;
          updatedKey.kbs = updatedKBS;
        }
      }
      setBandwidthData(updatedData);
      setLoadingBandwidthData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setLoadingBandwidthData(false);
        localStorage.setItem("bandwidthLoadingError", "true");
        setBandwidthLoadingError(true);
      });
    }
  );
};
