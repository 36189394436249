import { createApi } from "../../utils/createApi";
import { AxiosResponse, AxiosError } from "axios";
import { IntegrationSelectionScreen } from "./IntegrationSelectionScreen";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../Onboarding/OnboardingProgressDiv";
import { cameraRefreshCheck } from "./cameraRefreshCheck";
import { handleAddImmixToAddCameraForm } from "./handleAddImmixToAddCameraForm";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";

export const fetchAddCameraInfo = async (
  id: number,
  setVisibleScreen: any,
  navigate: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    fetchAddCameraInfo(id, setVisibleScreen, navigate);
  };

  if (localStorage.autoAdd === "true") {
    cameraRefreshCheck(setVisibleScreen, navigate);
  } else {
    await AxiosUI.get(`customer/${id}/camera_onboarding_forms/`).then(
      (response: AxiosResponse) => {
        // handleAddImmixToAddCameraForm(response.data);
        localStorage.setItem("addCameraInfo", JSON.stringify(response.data));
        localStorage.setItem(
          "selectedProducts",
          JSON.stringify(response.data.initial_data.products)
        );
        setVisibleScreen(
          <StatusNotificationScreen
            styling="absolute first-line:font-black font-mulish text-[20px] right-[190px] text-center text-[#283E6D] top-80"
            text="Site created successfully!"
          />
        );
        setTimeout(() => {
          setVisibleScreen(
            <OnboardingProgressDiv
              percentage={
                validIntegrationTypes.includes(
                  localStorage.selectedIntegrationType
                )
                  ? 25
                  : 33
              }
              step={1}
              setVisibleScreen={setVisibleScreen}
            />
          );
        }, 2000);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          // eslint-disable-next-line no-console
          console.log(`Error: ${reason}`);
          setVisibleScreen(
            <IntegrationSelectionScreen setVisibleScreen={setVisibleScreen} />
          );
        });
      }
    );
  }
};
