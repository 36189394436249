import React from "react";
import { createApi } from "./createApi";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "./universalAPIErrorHandler";

export const syncSite = async (siteId: string, navigate: any) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    syncSite(siteId, navigate);
  };
  await AxiosUI.post(`customer/${siteId}/action/deploy_next/`).then(
    () => {
      localStorage.setItem("syncNeeded", "false");
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
