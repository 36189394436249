import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export const MuiMultipleChoiceFieldType = ({
  id,
  options,
  onChange,
  stylingWidth,
  label,
  helperText,
  defaultValue,
}: {
  id: string;
  options: any;
  onChange: any;
  stylingWidth: number;
  label: string;
  helperText: string;
  defaultValue: any;
}) => {
  return (
    <div>
      <Autocomplete
        multiple
        disablePortal
        id={id}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
        sx={{ width: stylingWidth }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            name={id}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
};
