import React from "react";

interface MyObject {
  error_type: string;
  status: string;
}

export const filterAllObjects = (objects: MyObject[]): MyObject[] => {
  return objects.filter((obj) => {
    // Filter based on error_type and status
    return obj.status !== "healthy" && obj.status !== "resolved";
  });
};

export const filterWarningObjects = (objects: MyObject[]): MyObject[] => {
  return objects.filter((obj) => {
    // Filter based on error_type and status
    return (
      (obj.error_type === "motion_status" ||
        obj.error_type === "image_quality" ||
        obj.error_type === "stream_quality" ||
        obj.error_type === "license_info" ||
        obj.error_type === "recording_status") &&
      obj.status === "ongoing"
    );
  });
};

export const filterErrorObjects = (objects: MyObject[]): MyObject[] => {
  return objects.filter((obj) => {
    // Filter based on error_type and status
    return (
      (obj.error_type === "connectivity" ||
        obj.error_type === "scene_change") &&
      obj.status === "ongoing"
    );
  });
};

export const filterResolvedObjects = (objects: MyObject[]): MyObject[] => {
  return objects.filter((obj) => {
    // Filter based on error_type and status
    return obj.status === "resolved";
  });
};

export const filterPendingObjects = (objects: MyObject[]): MyObject[] => {
  return objects.filter((obj) => {
    // Filter based on error_type and status
    return obj.status === "pending";
  });
};
