import React from "react";
import { getAutoAddData } from "./getAutoAddData";
import { createApi } from "../../../utils/createApi";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleRefreshCameraList = (
  setCamerasList: any,
  setCamerasObject: any,
  setFetchingAutoAddData: any,
  checkboxesChecked: any,
  setCheckboxesChecked: any,
  setClientProducts: any,
  setImmixSite: any,
  setSureviewSite: any,
  setProductsArray: any,
  setSelectedProducts: any,
  setAutoAddError: any,
  navigate: any
) => {
  setCamerasList({});
  setCamerasObject([]);
  setFetchingAutoAddData(true);
  setClientProducts([]);
  setSelectedProducts([]);
  setCheckboxesChecked({});
  setImmixSite(false);
  setSureviewSite(false);
  setAutoAddError(false);
  setProductsArray([]);

  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleRefreshCameraList(
      setCamerasList,
      setCamerasObject,
      setFetchingAutoAddData,
      checkboxesChecked,
      setCheckboxesChecked,
      setClientProducts,
      setImmixSite,
      setSureviewSite,
      setProductsArray,
      setSelectedProducts,
      setAutoAddError,
      navigate
    );
  };

  AxiosUI.post(
    `customer/${localStorage.siteId}/action/refresh_camera_list/`
  ).then(
    () => {
      getAutoAddData(
        setCamerasList,
        setCamerasObject,
        setFetchingAutoAddData,
        checkboxesChecked,
        setCheckboxesChecked,
        setClientProducts,
        setImmixSite,
        setSureviewSite,
        setProductsArray,
        navigate
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        getAutoAddData(
          setCamerasList,
          setCamerasObject,
          setFetchingAutoAddData,
          checkboxesChecked,
          setCheckboxesChecked,
          setClientProducts,
          setImmixSite,
          setSureviewSite,
          setProductsArray,
          navigate
        );
      });
    }
  );
};
