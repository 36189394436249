import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleChangeStatus } from "./handleChangeStatus";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleResolveSuddenSceneChange = (
  valueString: string,
  selectedObject: any,
  setUpdatingStatus: Dispatcher<boolean>,
  setAlertsData: any,
  alertsData: any,
  setFectchingAllData: any,
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  clickedTab: any,
  setActiveTable: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setSortedData: any,
  singleCellActivated: any,
  activeName: any,
  filters: any,
  alertFilter: any,
  setSceneChangeOutcomesDialogOpen: any,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setSelectedRows: any,
  selectedRows: any,
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: any,
  cameraDropDownValue: any,
  setFilteredDataLengths: any,
  navigate: NavigateFunction
) => {
  //   const extractSubstring = (inputString: string): string => {
  //     const startIndex = inputString.indexOf(".com/") + 5; // Add 5 to include ".com/"
  //     const endIndex = inputString.indexOf("?AWS");

  //     if (startIndex >= 5 && endIndex !== -1 && endIndex > startIndex) {
  //       return inputString.substring(startIndex, endIndex);
  //     }

  //     // Return an empty string if the pattern is not found
  //     return "";
  //   };

  const AxiosUI = createApi(``);

  //   const detectedFrameKey = extractSubstring(selectedObject.detected_frame);

  //   const backgroundFrameKey = extractSubstring(selectedObject.background_frame);

  const submittedObject = {
    camera_id: selectedObject.camera_id,
    action: "clear",
    detected_frame_key: selectedObject.detected_frame_key,
    background_frame_key: selectedObject.background_frame_key,
    incident_timestamp: selectedObject.start_timestamp,
  };

  const postedAlert = selectedObject;

  postedAlert.status = "resolved";

  const retryFunction = () => {
    handleResolveSuddenSceneChange(
      valueString,
      selectedObject,
      setUpdatingStatus,
      setAlertsData,
      alertsData,
      setFectchingAllData,
      setUnhealthyCamsData,
      unhealthyCamsData,
      clickedTab,
      setActiveTable,
      setAlertStatusDialogOpen,
      setSelectedRow,
      setSelectedRowIndex,
      setSortedData,
      singleCellActivated,
      activeName,
      filters,
      alertFilter,
      setSceneChangeOutcomesDialogOpen,
      setBackgroundFrame,
      setDetectedFrame,
      setSelectedRows,
      selectedRows,
      setErrorsOverviewData,
      setCameraStatsData,
      setLoadingCameraStatsData,
      setBandwidthData,
      setLoadingBandwidthData,
      setShowSiteStats,
      cameraDropDownValue,
      setFilteredDataLengths,
      navigate
    );
  };

  if (valueString === "reset") {
    AxiosUI.post(
      `/healthcheck_result/update_sudden_scene/?camera_id=${selectedObject.camera_id}&action=clear&detected_frame_key=${selectedObject.detected_frame_key}&background_frame_key=${selectedObject.background_frame_key}&incident_timestamp=${selectedObject.start_timestamp}`,
      submittedObject
    ).then(
      () => {
        handleChangeStatus(
          setAlertsData,
          "resolved",
          "ongoing",
          selectedObject,
          alertsData,
          setFectchingAllData,
          setUnhealthyCamsData,
          unhealthyCamsData,
          clickedTab,
          setActiveTable,
          setAlertStatusDialogOpen,
          setSelectedRow,
          setSelectedRowIndex,
          setSortedData,
          singleCellActivated,
          activeName,
          setUpdatingStatus,
          filters,
          alertFilter,
          setSceneChangeOutcomesDialogOpen,
          setBackgroundFrame,
          setDetectedFrame,
          setSelectedRows,
          selectedRows,
          setErrorsOverviewData,
          setCameraStatsData,
          setLoadingCameraStatsData,
          setBandwidthData,
          setLoadingBandwidthData,
          setShowSiteStats,
          cameraDropDownValue,
          setFilteredDataLengths,
          navigate
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Unable to update status, please try again");
          setUpdatingStatus(false);
        });
      }
    );
  }
  if (valueString === "add") {
    submittedObject.action = "update";
    AxiosUI.post(
      `/healthcheck_result/update_sudden_scene/`,
      submittedObject
    ).then(
      () => {
        handleChangeStatus(
          setAlertsData,
          "resolved",
          "ongoing",
          selectedObject,
          alertsData,
          setFectchingAllData,
          setUnhealthyCamsData,
          unhealthyCamsData,
          clickedTab,
          setActiveTable,
          setAlertStatusDialogOpen,
          setSelectedRow,
          setSelectedRowIndex,
          setSortedData,
          singleCellActivated,
          activeName,
          setUpdatingStatus,
          filters,
          alertFilter,
          setSceneChangeOutcomesDialogOpen,
          setBackgroundFrame,
          setDetectedFrame,
          setSelectedRows,
          selectedRows,
          setErrorsOverviewData,
          setCameraStatsData,
          setLoadingCameraStatsData,
          setBandwidthData,
          setLoadingBandwidthData,
          setShowSiteStats,
          cameraDropDownValue,
          setFilteredDataLengths,
          navigate
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Unable to update status, please try again");
          setUpdatingStatus(false);
        });
      }
    );
  }
  if (valueString === "proceed") {
    handleChangeStatus(
      setAlertsData,
      "resolved",
      "ongoing",
      selectedObject,
      alertsData,
      setFectchingAllData,
      setUnhealthyCamsData,
      unhealthyCamsData,
      clickedTab,
      setActiveTable,
      setAlertStatusDialogOpen,
      setSelectedRow,
      setSelectedRowIndex,
      setSortedData,
      singleCellActivated,
      activeName,
      setUpdatingStatus,
      filters,
      alertFilter,
      setSceneChangeOutcomesDialogOpen,
      setBackgroundFrame,
      setDetectedFrame,
      setSelectedRows,
      selectedRows,
      setErrorsOverviewData,
      setCameraStatsData,
      setLoadingCameraStatsData,
      setBandwidthData,
      setLoadingBandwidthData,
      setShowSiteStats,
      cameraDropDownValue,
      setFilteredDataLengths,
      navigate
    );
  }
};
