import React from "react";
import { NavBar } from "../common/NavBar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { TooltipTableHeadComponent } from "../common/TableHead";
import { temporaryProductRequirementData } from "../utils/temporaryProductRequirementData";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Link } from "@mui/material";
import { Dispatcher } from "./Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const titlesArray = [
  ["Product", "product"],
  ["Resolution", "resolution"],
  ["Frame Rate", "frame_rate"],
  ["Aspect Ratio", "aspect_ratio"],
  ["Codec", "codec"],
];

const toolTipText = {
  resolution: `The sharpness/clarity of an image based on the number of image pixels.`,
  frame_rate: `How many frames are contained within a second of video (fps). If the frame rate is too low, we run the risk of missing key frames and key detections.`,
  aspect_ratio: `We run frames through our models at a specific width and height (i.e. aspect ratio).`,
  codec: `Compresses/decompresses large amounts of video data for the sake of creating, playing, and sending media files.`,
};

export const ProductRequirements = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const requirementsData = JSON.parse(localStorage.productRequirements);

  return (
    <div>
      <div>
        <div className="bg-actuate-grey absolute w-screen h-screen">
          <div
            className={
              !navBarCollapsed
                ? "absolute top-[8px] left-[150px] right-[1px] h-[19px]] min-w-[600px] bg-white"
                : "absolute top-[8px] left-[75px] right-[1px] h-[19px]] min-w-[600px] bg-white"
            }
          >
            <p className="font-black font-mulish text-[20px] p-3 indent-6">
              Product Requirements for Optimal Performance
            </p>
          </div>
          <div
            className={
              !navBarCollapsed
                ? "absolute top-[70px] left-[150px] right-[1px] h-[60px]] min-w-[1000px] bg-white"
                : "absolute top-[70px] left-[75px] right-[1px] h-[60px]] min-w-[1000px] bg-white"
            }
          >
            <p className="text-actuate-blue font-mulish text-[16px] p-3">
              {`Our DS team recommends the following camera settings to support “optimal” / desired AI performance. Adhering to these recommendations will decrease your likelihood of errors such as false positives and misses.`}
            </p>
            <p className="text-actuate-blue font-mulish text-[16px] p-3">
              <span className="font-bold">Please note: </span> While these
              settings support desired AI performance, there are factors that
              may require additional tuning. To learn more about Actuate’s
              features for tuning AI performance, contact
              <span>
                &nbsp;
                <a className="text-[#0000EE]" href="mailto:support@actuate.ai">
                  support@actuate.ai.
                </a>
              </span>
            </p>
          </div>
          <div
            className={
              !navBarCollapsed
                ? "absolute top-[225px] left-[150px] right-[1px] bg-white"
                : "absolute top-[225px] left-[75px] right-[1px] bg-white"
            }
          >
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ height: 440, overflow: "scroll" }}>
                <Table
                  sx={{ minWidth: 1000, fontFamily: "Mulish" }}
                  stickyHeader
                >
                  <TooltipTableHeadComponent
                    titlesArray={titlesArray}
                    toolTipText={toolTipText}
                  />
                  <TableBody>
                    {requirementsData.map((row: any) => {
                      return (
                        <TableRow key={row.product.value}>
                          <TableCell>
                            {row.product.name === "Intruder+" ? (
                              <>
                                {row.product.name}
                                &nbsp;
                                <Tooltip
                                  title={`Actuate's primary model works in 99.9% of cases, similar to a real human operator. This excellently addresses the use cases of most of our customers. For customers that are interested in going above and beyond the industry standard accuracy, we also offer a bigger deep learning network that has more layers and costs more for us to compute: Intruder+. This bumps that accuracy up to 99.99%, which is worth the cost to some.`}
                                  placement="top"
                                  sx={{
                                    fontSize: "15px",
                                    fontFamily: "mulish",
                                  }}
                                >
                                  <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                                </Tooltip>
                              </>
                            ) : (
                              <>{row.product.name}</>
                            )}
                          </TableCell>
                          <TableCell>{`${row.width_optimal} x ${row.height_optimal}`}</TableCell>
                          <TableCell>{`${row.frame_rate_range.slice(
                            0,
                            3
                          )} fps`}</TableCell>
                          <TableCell>{`${row.aspect_ratio_width}:${row.aspect_ratio_height}`}</TableCell>
                          <TableCell>{row.codec}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
