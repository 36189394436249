import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { AddCameraButton } from "./AddCameraButton";
import { CameraIcon } from "../../../common/CameraIcon";
import { NavigateFunction } from "react-router-dom";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const NoCameras = ({
  navigate,
  setNoAutoAddCamerasDialogOpen,
}: {
  navigate: NavigateFunction;
  setNoAutoAddCamerasDialogOpen: Dispatcher<boolean>;
}) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            textIndent: "33px",
            paddingTop: "10px",
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Site Cameras
        </Typography>

        <div className="w-[88vw] h-[27.5vh] bg-white">
          <div className="flex items-center justify-center font-black">
            <CameraIcon />
          </div>
          <div className="flex items-center justify-center font-black font-mulish text-xl">
            This Site doesn&apos;t have any cameras yet.
          </div>
          <br></br>
          <div className="flex items-center justify-center font-black font-mulish text-xl">
            <AddCameraButton
              navigate={navigate}
              setNoAutoAddCamerasDialogOpen={setNoAutoAddCamerasDialogOpen}
            />
          </div>
        </div>
      </TableContainer>
    </Paper>
  );
};
