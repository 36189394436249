import React from "react";
import { createApi } from "../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";

export const handleSubmitAuthentication = (
  authenticationData: any,
  setPageLoading: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    handleSubmitAuthentication(authenticationData, setPageLoading, navigate);
  };

  const AxiosUI = createApi(``);

  AxiosUI.post(`auth/mfa_setup/`, authenticationData).then(
    (response: any) => {
      window.location.href = response.data.logout_url;
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Failed to send authentication, please try again");
        setPageLoading(false);
      });
    }
  );
};
