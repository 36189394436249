import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { StatusNotificationScreen } from "../../../common/StatusNotificationScreen";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleSubmitAddCalendar = (
  newCalendarInfo: any,
  calendarEvents: any,
  assignedSites: any,
  setVisibleScreen: any,
  calendarData: any,
  setCalendarData: any,
  setCalendarAlerts: any,
  setAddCalendarScreenOpen: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleSubmitAddCalendar(
      newCalendarInfo,
      calendarEvents,
      assignedSites,
      setVisibleScreen,
      calendarData,
      setCalendarData,
      setCalendarAlerts,
      setAddCalendarScreenOpen,
      navigate
    );
  };

  AxiosUI.post(`calendar/`, newCalendarInfo).then(
    (response: AxiosResponse) => {
      const calendarID = response.data.id;
      if (calendarEvents[0]) {
        calendarEvents.forEach(
          (object: any) => {
            AxiosUI.post(`calendar_event/`, {
              calendar: calendarID,
              event_date: object.event_date,
              name: object.name,
            });
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              alert("Error adding one or more Calendar events");
              setAddCalendarScreenOpen(false);
            });
          }
        );
      }
      AxiosUI.post(`calendar/${calendarID}/apply/`, {
        customer: assignedSites,
      }).then(
        () => {
          AxiosUI.get(`calendar/${calendarID}/`).then(
            (response: AxiosResponse) => {
              setCalendarData([...calendarData, response.data]);
              setVisibleScreen(
                <StatusNotificationScreen
                  styling="absolute first-line:font-black font-mulish text-[20px] left-[110px] text-center text-[#283E6D] w-[400px] top-80"
                  text={<p>Calendar added successfully!</p>}
                />
              );
              setTimeout(() => {
                localStorage.setItem("newCalendarName", response.data.name);
                setCalendarAlerts({
                  addCalendarSuccess: true,
                  deleteCalendarSuccess: false,
                  addSitesToCalendarSuccess: false,
                });
                setAddCalendarScreenOpen(false);
              }, 3000);
            },
            (reason: AxiosError) => {
              universalAPIErrorHandler(reason, navigate, retryFunction, () => {
                setAddCalendarScreenOpen(false);
              });
            }
          );
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert("Error applying sites to new Calendar");
            setAddCalendarScreenOpen(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error adding Calendar, please try again");
        setAddCalendarScreenOpen(false);
      });
    }
  );
};
