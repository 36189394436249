import { NavBar } from "../common/NavBar";

export const User = (navBarCollapsed, setNavBarCollapsed) => {
  return (
    <div>
      <div className="bg-actuate-grey absolute w-full h-full">
        <div className="absolute top-[55px] left-[150px] right-[1px] h-[19px]] bg-white">
          <p className="font-black font-mulish text-[20px] p-3 indent-6">
            User Information
          </p>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
