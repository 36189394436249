import React from "react";
import { ActuateButton } from "../../../common/ActuateButton";
import { NavigateFunction } from "react-router-dom";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const AddCameraButton = ({
  navigate,
  setNoAutoAddCamerasDialogOpen,
}: {
  navigate: NavigateFunction;
  setNoAutoAddCamerasDialogOpen: Dispatcher<boolean>;
}) => {
  localStorage.setItem("clickedFromSiteAboutPage", "true");
  return (
    <div>
      <ActuateButton
        onClick={() => {
          if (
            localStorage.noAutoAddCamerasDialogOpen === "true" &&
            localStorage.autoAdd === "true"
          ) {
            setNoAutoAddCamerasDialogOpen(true);
          } else {
            localStorage.setItem("firstCameraAdded", "true");
            localStorage.setItem("copyValues", "true");
            if (localStorage.autoAdd === "false") {
              navigate("/sites/addcamera");
            } else {
              navigate("/sites/autoaddcamera");
            }
          }
        }}
        text="Add Camera"
        disabled={false}
      />
    </div>
  );
};
