import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { handleDeleteCamera } from "./SiteAboutPageUtils/handleDeleteCamera";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import {
  AlertState,
  SiteCameras,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const DeleteCameraCheckDialog = ({
  selected,
  deleteDialogOpen,
  setDeleteDialogOpen,
  setSiteCameras,
  setAlertState,
  setSelected,
  setSyncNeeded,
  setBackdropLoadingScreenOn,
}: {
  selected: readonly string[];
  deleteDialogOpen: boolean;
  setDeleteDialogOpen: Dispatcher<boolean>;
  setSiteCameras: Dispatcher<SiteCameras>;
  setAlertState: Dispatcher<AlertState>;
  setSelected: Dispatcher<readonly string[]>;
  setSyncNeeded: Dispatcher<boolean>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
}) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={deleteDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {selected.length <= 1 ? (
        <DialogTitle sx={{ fontFamily: "mulish" }}>
          {" Are you sure you want to delete this camera?"}
        </DialogTitle>
      ) : (
        <DialogTitle sx={{ fontFamily: "mulish" }}>
          {" Are you sure you want to delete these cameras?"}
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText sx={{ fontFamily: "mulish" }}>
          Once deleted this action can&apos;t be undone.
        </DialogContentText>
        <br></br>
        <DialogContentText sx={{ fontFamily: "mulish" }}>
          <strong>PLEASE NOTE:</strong> cameras that are deleted from
          Actuate&apos;s systems and then re-added may result in unwanted
          discrepancies in Actuate&apos;s billing records. To avoid this, please
          follow the troubleshooting procedures on our support page and contact
          support@actuate.ai for additional assistance.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setDeleteDialogOpen(false);
            setSyncNeeded(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setBackdropLoadingScreenOn(true);
            setDeleteDialogOpen(false);
            handleDeleteCamera(
              selected,
              setSiteCameras,
              setAlertState,
              setSelected,
              setSyncNeeded,
              navigate,
              setBackdropLoadingScreenOn
            );
          }}
          text="Delete"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
