import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleDeleteSite } from "./SiteAboutPageUtils/handleDeleteSite";
import { NavigateFunction } from "react-router-dom";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const DeleteSiteCheckDialog = ({
  deleteSiteDialogOpen,
  setDeleteSiteDialogOpen,
  navigate,
  setBackdropLoadingScreenOn,
  setSyncNeeded,
}: {
  deleteSiteDialogOpen: boolean;
  setDeleteSiteDialogOpen: Dispatcher<boolean>;
  navigate: NavigateFunction;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setSyncNeeded: Dispatcher<boolean>;
}) => {
  return (
    <Dialog
      open={deleteSiteDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {" Are you sure you want to delete this site?"}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once deleted this action can&apos;t be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setDeleteSiteDialogOpen(false);
            setSyncNeeded(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setDeleteSiteDialogOpen(false);
            handleDeleteSite(
              localStorage.siteId,
              navigate,
              setBackdropLoadingScreenOn
            );
          }}
          text="Delete"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
