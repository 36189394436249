import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const ImmixTestFailDialog = ({
  immixTestFailDialogOpen,
  setImmixTestFailDialogOpen,
}: {
  immixTestFailDialogOpen: boolean;
  setImmixTestFailDialogOpen: Dispatcher<boolean>;
}) => {
  return (
    <Dialog open={immixTestFailDialogOpen}>
      <DialogContent>
        <p className="font-mulish text-[20px]">Immix test failed.</p>
        <br></br>
        <p>
          The test alert failed to reach your Immix server. Please ensure the
          information entered matches the generic stream device on your Immix
          Summary Report and that the site is armed in Immix. If you are still
          experiencing issues, please contact support@actuate.ai.{" "}
        </p>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setImmixTestFailDialogOpen(false);
          }}
          text="Ok"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
