import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const handleButtonShading = (
  row: any,
  setVerifiedClicked: Dispatcher<boolean>,
  setUnverifiedClicked: Dispatcher<boolean>
) => {
  if (row.ground_truth === "Verified") {
    setVerifiedClicked(true);
    setUnverifiedClicked(false);
  } else if (row.ground_truth === "Unverified") {
    setVerifiedClicked(false);
    setUnverifiedClicked(true);
  } else {
    setVerifiedClicked(true);
    setUnverifiedClicked(true);
  }
};
