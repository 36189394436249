import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Autocomplete } from "@mui/material";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleAddNewCalendar } from "./CalendarsUtils/handleAddNewCalendar";
import { NavigateFunction } from "react-router-dom";
import { handleEditCalendar } from "./CalendarsUtils/handleEditCalendar";

export const EditCalendarDialog = ({
  selectedRow,
  editCalendarDialogOpen,
  setEditCalendarDialogOpen,
  calendarData,
  setCalendarData,
  setCalendarAlerts,
  rowIndex,
  setSelectedRow,
  setEditingCalendar,
  navigate,
}: {
  selectedRow: any;
  editCalendarDialogOpen: boolean;
  setEditCalendarDialogOpen: Dispatcher<boolean>;
  calendarData: any;
  setCalendarData: any;
  setCalendarAlerts: any;
  rowIndex: any;
  setSelectedRow: any;
  setEditingCalendar: any;
  navigate: NavigateFunction;
}) => {
  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const parentIds = new Map();
  const parentNames = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });
  parentAccounts.forEach((object: any) => {
    parentNames.set(object.id, object.name);
  });

  const [changesMade, setChangesMade] = React.useState(false);

  const [updatedCalendarInfo, setUpdatedCalendarInfo] = React.useState<any>({
    group: selectedRow.group.id,
    name: selectedRow.name,
  });

  return (
    <Dialog open={editCalendarDialogOpen}>
      <DialogTitle id="add-calendar">Edit Calendar</DialogTitle>
      <DialogContent sx={{ width: 450, height: 375 }}>
        <TextField
          variant="standard"
          label="Calendar Name"
          value={updatedCalendarInfo.name}
          onChange={(event) => {
            setChangesMade(true);
            setUpdatedCalendarInfo((previousState: any) => {
              return { ...previousState, name: event.target.value };
            });
          }}
          sx={{ width: "350px" }}
          helperText=""
          type="string"
        />
        <br></br>
        <br></br>
        <Autocomplete
          disablePortal
          value={parentNames.get(updatedCalendarInfo.group)}
          options={parentAccounts.map((object: any) => {
            return object.name;
          })}
          onChange={(event: any, newInputValue: any) => {
            setChangesMade(true);
            setUpdatedCalendarInfo((previousState: any) => {
              return {
                ...previousState,
                group: parentIds.get(newInputValue),
              };
            });
          }}
          sx={{ width: 350 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Group"
              autoComplete="off"
              id="group_name"
              name="group_name"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setEditCalendarDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setEditCalendarDialogOpen(false);
            setEditingCalendar(true);
            handleEditCalendar(
              rowIndex,
              selectedRow.id,
              selectedRow.group.name,
              updatedCalendarInfo,
              calendarData,
              setCalendarData,
              setCalendarAlerts,
              setSelectedRow,
              setEditingCalendar,
              navigate
            );
          }}
          text="Submit"
          disabled={!changesMade}
        />
      </DialogActions>
    </Dialog>
  );
};
