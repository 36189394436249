import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { CircularProgress } from "@mui/material";
import { LoadingData } from "./AnalyticsUtils/analyticsInterfaces";

const CustomizedLabel = (props: any) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x + 40} y={y - 5} fill={stroke} fontSize={13} textAnchor="middle">
      {`${value}%`}
    </text>
  );
};

export const PercentageofMotionFilteredGraph = ({
  percetageOfMotionData,
  loadingData,
  displayOnLeftBool,
  navBarCollapsed,
}: {
  percetageOfMotionData: any;
  loadingData: LoadingData;
  displayOnLeftBool: boolean;
  navBarCollapsed: boolean;
}) => {
  const toPercent = (decimal: number) => `${decimal}%`;

  const displayOnLeft = (
    <div
      className={
        navBarCollapsed
          ? "absolute top-[885px] left-[20px] right-[1px] h-[370px] w-[652px] bg-[#BDBDBD]"
          : "absolute top-[885px] left-[20px] right-[1px] h-[370px] w-[610px] bg-[#BDBDBD]"
      }
    >
      <div className="absolute top-[1px] left-[1px] right-[1px] h-[368px] w-[650px] bg-white">
        <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[7px] left-[40px]">
          Percentage of Motion Counts Filtered
        </p>
        <p className="text-actuate-blue font-mulish text-[14px] absolute top-[36px] left-[40px]">
          Motion counts the system filtered during the selected dates
        </p>
        {loadingData.percetageOfMotionData && (
          <div className="absolute top-[170px] left-[230px]">
            <CircularProgress
              size={25}
              sx={{ position: "relative", left: 40 }}
            />
            <div className="font-mulish text-actuate-blue test-bold">
              Fetching Data...
            </div>
          </div>
        )}
        {!loadingData.percetageOfMotionData && (
          <div className="absolute top-[60px] left-[0px]">
            <BarChart
              width={!navBarCollapsed ? 590 : 630}
              barSize={percetageOfMotionData.length < 4 ? 120 : "auto"}
              height={310}
              data={percetageOfMotionData}
              margin={{
                top: 5,
                right: 0,
                left: 5,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis tickFormatter={toPercent} allowDecimals={false} />
              {percetageOfMotionData[0] && <Tooltip />}
              <Legend verticalAlign="top" height={36} />
              <Bar
                dataKey="percentage_filtered"
                fill="#283E6D"
                name="Motion Counts % Filtered"
                // label={<CustomizedLabel />}
              />
            </BarChart>
          </div>
        )}
      </div>
    </div>
  );

  const displayOnRight = (
    <div
      className={
        navBarCollapsed
          ? "absolute top-[495px] left-[690px] right-[1px] h-[370px] w-[656px] bg-[#BDBDBD]"
          : "absolute top-[495px] left-[650px] right-[1px] h-[370px] w-[620px] bg-[#BDBDBD]"
      }
    >
      <div className="absolute top-[1px] left-[1px] right-[1px] h-[368px] w-[654px] bg-white">
        <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[7px] left-[40px]">
          Percentage of Motion Counts Filtered
        </p>
        <p className="text-actuate-blue font-mulish text-[14px] absolute top-[36px] left-[40px]">
          Motion counts the system filtered during the selected dates
        </p>
        {loadingData.percetageOfMotionData && (
          <div className="absolute top-[170px] left-[230px]">
            <CircularProgress
              size={25}
              sx={{ position: "relative", left: 40 }}
            />
            <div className="font-mulish text-actuate-blue test-bold">
              Fetching Data...
            </div>
          </div>
        )}
        {!loadingData.percetageOfMotionData && (
          <div className="absolute top-[60px] left-[0px]">
            <BarChart
              width={!navBarCollapsed ? 590 : 630}
              barSize={percetageOfMotionData.length < 4 ? 120 : "auto"}
              height={310}
              data={percetageOfMotionData}
              margin={{
                top: 5,
                right: 0,
                left: 5,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis tickFormatter={toPercent} allowDecimals={false} />
              {percetageOfMotionData[0] && <Tooltip />}
              <Legend verticalAlign="top" height={36} />
              <Bar
                dataKey="percentage_filtered"
                fill="#283E6D"
                name="Motion Counts % Filtered"
                // label={<CustomizedLabel />}
              />
            </BarChart>
          </div>
        )}
      </div>
    </div>
  );
  return displayOnLeftBool ? displayOnLeft : displayOnRight;
};
