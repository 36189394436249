import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
// import { epochToUPCCovertor } from "../Sites/SiteAboutPage/SiteAboutPageUtils/epochToUPCCovertor";
import { nameToTooltip } from "./DashbordUtils/nameToTooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { nameToTooltipLonger } from "./DashbordUtils/nameToTooltipLonger";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { getAdditionalData } from "./DashbordUtils/getAdditionalData";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";

const formatDateFromEpoch = (epoch: number): string => {
  const date = new Date(epoch * 1000); // Convert epoch seconds to milliseconds

  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "2-digit",
    year: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
};

export const ErrorTab = ({
  activeTab,
  camerasErrorInfo,
  camerasWarningInfo,
  page,
  setPage,
  setPageLoading,
  navBarCollapsed,
  errorPaginationNumber,
  setErrorPaginationNumber,
  setCameraErrorInfo,
  warningPaginationNumber,
  setWarningPaginationNumber,
  setCameraWarningInfo,
  navigate,
}: {
  activeTab: string;
  camerasErrorInfo: any;
  camerasWarningInfo: any;
  page: any;
  setPage: any;
  setPageLoading: any;
  navBarCollapsed: boolean;
  errorPaginationNumber: number;
  setErrorPaginationNumber: any;
  setCameraErrorInfo: any;
  warningPaginationNumber: number;
  setWarningPaginationNumber: any;
  setCameraWarningInfo: any;
  navigate: any;
}) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [displayedInfo, setDisplayedInfo] = React.useState([]);

  const [fetchingDisplayedInfo, setFetchingDisplayedInfo] =
    React.useState(true);

  const [displayShowMoreButton, setDisplayShowMoreButton] =
    React.useState(false);

  const [loadingMoreData, setLoadingMoreData] = React.useState(false);

  React.useEffect(() => {
    if (activeTab === "error") {
      setDisplayedInfo(camerasErrorInfo);
    }
    if (activeTab === "warning") {
      setDisplayedInfo(camerasWarningInfo);
    }
  }, [activeTab, camerasErrorInfo, camerasWarningInfo]);

  React.useEffect(() => {
    if (displayedInfo.length > 0) {
      setFetchingDisplayedInfo(false);
    }
  }, [displayedInfo]);

  React.useEffect(() => {
    if (displayedInfo.length % 100 !== 0) {
      setDisplayShowMoreButton(false);
    } else {
      if (activeTab === "error") {
        if (rowsPerPage === 100) {
          setDisplayShowMoreButton(errorPaginationNumber === page + 1);
        }
        if (rowsPerPage === 25) {
          setDisplayShowMoreButton(displayedInfo.length / 25 === page + 1);
        }
        if (rowsPerPage === 10) {
          setDisplayShowMoreButton(displayedInfo.length / 10 === page + 1);
        }
      }
      if (activeTab === "warning") {
        if (rowsPerPage === 100) {
          setDisplayShowMoreButton(warningPaginationNumber === page + 1);
        }
        if (rowsPerPage === 25) {
          setDisplayShowMoreButton(displayedInfo.length / 25 === page + 1);
        }
        if (rowsPerPage === 10) {
          setDisplayShowMoreButton(displayedInfo.length / 10 === page + 1);
        }
      }
    }
  }, [
    rowsPerPage,
    page,
    errorPaginationNumber,
    displayedInfo,
    activeTab,
    warningPaginationNumber,
  ]);

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <BackdropLoadingScreen openState={loadingMoreData} />
      <TableContainer
        sx={
          navBarCollapsed
            ? { maxHeight: 640, width: "625px" }
            : { maxHeight: 640, width: "550px" }
        }
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                key="site"
                align="left"
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: "Bold",
                  width: "137.5px",
                }}
              >
                Site
              </TableCell>
              <TableCell
                key="site"
                align="left"
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: "Bold",
                  width: "137.5px",
                }}
              >
                Camera
              </TableCell>
              <TableCell
                key="site"
                align="left"
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: "Bold",
                  width: "137.5px",
                }}
              >
                Date
              </TableCell>
              <TableCell
                key="site"
                align="left"
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: "Bold",
                  width: "137.5px",
                }}
              >
                Error
              </TableCell>
            </TableRow>
          </TableHead>
          {displayedInfo.length > 0 && (
            <TableBody>
              {displayedInfo
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((object: any) => {
                  return (
                    <TableRow
                      hover
                      key={object.id}
                      role="checkbox"
                      sx={{
                        cursor: "pointer",
                        fontFamily: "Mulish",
                      }}
                      onClick={() => {
                        navigate(
                          `/sites/${object.customer.id}/editcamera/${object.id}`
                        );
                      }}
                    >
                      {navBarCollapsed
                        ? nameToTooltipLonger(
                            object.customer.name,
                            "name",
                            setPageLoading,
                            object,
                            navigate
                          )
                        : nameToTooltip(
                            object.customer.name,
                            "name",
                            setPageLoading,
                            object,
                            navigate
                          )}

                      {navBarCollapsed
                        ? nameToTooltipLonger(
                            object.camera_name,
                            "name",
                            setPageLoading,
                            object,
                            navigate
                          )
                        : nameToTooltip(
                            object.camera_name,
                            "name",
                            setPageLoading,
                            object,
                            navigate
                          )}

                      <TableCell
                        onClick={() => {
                          navigate(
                            `/sites/${object.customer.id}/editcamera/${object.id}`
                          );
                        }}
                        align="left"
                        sx={{ fontFamily: "Mulish", width: "137.5px" }}
                      >
                        {object.date_added
                          ? formatDateFromEpoch(object.date_added)
                          : "--"}
                      </TableCell>
                      {navBarCollapsed
                        ? nameToTooltipLonger(
                            object.message,
                            "error",
                            setPageLoading,
                            object,
                            navigate
                          )
                        : nameToTooltip(
                            object.message,
                            "error",
                            setPageLoading,
                            object,
                            navigate
                          )}
                    </TableRow>
                  );
                })}
              {displayShowMoreButton && (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <OrangeTextButton
                      onClick={() => {
                        setLoadingMoreData(true);
                        setDisplayShowMoreButton(false);
                        getAdditionalData(
                          activeTab,
                          errorPaginationNumber,
                          setErrorPaginationNumber,
                          setCameraErrorInfo,
                          warningPaginationNumber,
                          setWarningPaginationNumber,
                          setCameraWarningInfo,
                          setDisplayedInfo,
                          setLoadingMoreData,
                          navigate
                        );
                      }}
                      text="Load More"
                      disabled={false}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
          {fetchingDisplayedInfo && activeTab === "error" && (
            <TableBody>
              <TableRow
                sx={
                  navBarCollapsed
                    ? { position: "relative", left: 240, top: 15 }
                    : { position: "relative", left: 220, top: 15 }
                }
              >
                <CircularProgress
                  size={25}
                  sx={{ position: "relative", left: 40 }}
                />
                <div className="font-mulish text-actuate-blue test-bold">
                  Fetching Data...
                </div>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          )}
          {fetchingDisplayedInfo && activeTab === "warning" && (
            <TableBody>
              <TableRow sx={{ position: "relative", left: 220, top: 15 }}>
                <CircularProgress
                  size={25}
                  sx={{ position: "relative", left: 40 }}
                />
                <div className="font-mulish text-actuate-blue test-bold">
                  Fetching Data...
                </div>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        {displayedInfo && (
          <TablePagination
            component="div"
            // sx={{font: "mulish"}}
            rowsPerPageOptions={[10, 25, 100]}
            count={displayedInfo.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={(event: any) => {
              setRowsPerPage(event.target.value);
              setPage(0);
            }}
          />
        )}
      </TableContainer>
      {/* </InfiniteScroll> */}
    </Paper>
  );
};
