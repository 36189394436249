import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const handleShowMoreButtonClick = (
  showMoreOpen: boolean,
  setShowMoreOpen: Dispatcher<boolean>,
  setVisibleArray: Dispatcher<any>,
  fullArray: any,
  max7Array: any,
  setBarChartHeight: Dispatcher<number>,
  barChartHeightFull: number,
  setOuterBoxHeight: Dispatcher<number>,
  outerBoxHeightFull: number,
  setInnerBoxHeight: Dispatcher<number>,
  innerBoxHeightFull: number,
  setDisplayOnLeftBool: Dispatcher<boolean>
) => {
  if (!showMoreOpen) {
    setDisplayOnLeftBool(true);
    setShowMoreOpen(true);
    setVisibleArray(fullArray);
    setBarChartHeight(barChartHeightFull);
    setOuterBoxHeight(outerBoxHeightFull);
    setInnerBoxHeight(innerBoxHeightFull);
  } else {
    setDisplayOnLeftBool(false);
    setShowMoreOpen(false);
    setVisibleArray(max7Array);
    setBarChartHeight(385);
    setOuterBoxHeight(425);
    setInnerBoxHeight(423);
  }
};
