import React from "react";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleCancelButtonClick } from "./SiteAboutPageUtils/handleCancelButtonClick";
import {
  SiteSchedule,
  SiteInfo,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const CancelButton = ({
  setEditMode,
  clickedTab,
  setActiveTab,
  vmsVariables,
  allSiteSchedules,
  siteInfo,
  setDeleteScheduleDialogOpen,
  recordingServerData,
  setRecordingServerPatchObject,
  healthCheckData,
  webhooksInfo,
}: {
  setEditMode: Dispatcher<boolean>;
  clickedTab: string;
  setActiveTab: Dispatcher<JSX.Element>;
  vmsVariables: (string | string[] | undefined)[][];
  allSiteSchedules: SiteSchedule[];
  siteInfo: SiteInfo;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  recordingServerData: any;
  setRecordingServerPatchObject: any;
  healthCheckData: any;
  webhooksInfo: any;
}) => {
  return (
    <div>
      <OrangeTextButton
        onClick={() =>
          handleCancelButtonClick(
            setEditMode,
            clickedTab,
            setActiveTab,
            vmsVariables,
            allSiteSchedules,
            siteInfo,
            setDeleteScheduleDialogOpen,
            recordingServerData,
            setRecordingServerPatchObject,
            healthCheckData,
            webhooksInfo
          )
        }
        text="CANCEL"
        disabled={false}
      />
    </div>
  );
};
