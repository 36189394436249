import React from "react";
import {
  cancelIconRed,
  cancelIconRedSmall,
} from "../../../../common/cancelIconRed";
import WarningIcon from "@mui/icons-material/Warning";
import {
  checkedCircleIcon,
  checkedCircleIconSmall,
} from "../../../../common/checkedCircleIcon";
import { Tooltip } from "@mui/material";

export const createOptimalSettingsIcon = (
  requirements_bad: number,
  requirements_warning: number
) => {
  if (requirements_warning > 0 && requirements_bad < 1) {
    return (
      <Tooltip title="Some cameras on this site have settings which are only acceptable, and may result in performance issues.">
        <WarningIcon sx={{ color: "#FF8400" }} fontSize="small" />
      </Tooltip>
    );
  } else if (requirements_bad > 0) {
    return (
      <Tooltip title="Some cameras on this site have settings which are below optimal, and will likely result in performance issues.">
        {cancelIconRedSmall}
      </Tooltip>
    );
  } else {
    return checkedCircleIconSmall;
  }
};
