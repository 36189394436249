import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import * as React from "react";
import "./ZwibblerComponent.module.css";
import { LightenDarkenColor } from "../../utils/colorLuminance";

declare global {
  interface Window {
    Zwibbler: any;
  }
}

const ZwibblerComponent = forwardRef((props, ref) => {
  // Zwibbler configuration, see
  // https://zwibbler.com/configurator.html
  // All names are lower case due to react convention.
  // const backgroundImage = JSON.parse(localStorage.preview_url)
  const [zwibblerConfig, setZwibblerConfig] = React.useState({
    zwibbler: "",
    showtoolbar: "true",
    // "z-controller": "mycontroller",
    defaultarrowsize: "10",
    defaultarrowstyle: "solid",
    defaultLineWidth: "5",
    adaptivebrushwidth: "true",
    defaultfillstyle: "transparent",
    background: "transparent",
    showarrowtool: "false",
    showbrushtool: "false",
    showcircletool: "false",
    showcopypaste: "false",
    showcurvetool: "false",
    showroundrecttool: "false",
    showshapebrushtool: "false",
    showsquaretool: "false",
    showtexttool: "false",
    allowzoom: "false",
    allowtextinshape: "false",
    defaultstrokestyle: "black",
    scrollbars: "false",
    allowresize: "false",
    confine: "view",
    showUndoredo: "false",
  });

  const dimensions = JSON.parse(localStorage.zwibblerDimensions);

  const zwibblerEl = useRef(null);
  const ctx: any = useRef(null);

  const [shapesObject, setShapesObject] = React.useState({});

  const [currentNode, setCurrentNode] = React.useState("");

  const [shiftedObj, setShiftedObj] = React.useState({});

  const shapesMoveHandler = (event: any) => {
    event.nodes.forEach((item: any) => {
      setShiftedObj((previousState) => {
        return { ...previousState, [item.id]: [item.shift.x, item.shift.y] };
      });
    });
  };

  const nodeChangeHandler = (node: string[]) => {
    setShapesObject((previousState: any) => {
      return {
        ...previousState,
        [node[0]]: coordinatesArrayCreator(
          ctx.current.summary.properties.commands
        ),
      };
    });
  };

  // ctx.current.deleteNode()

  // When component is unmounted, destroy Zwibbler.
  useEffect(() => {
    if (!zwibblerEl.current) {
      return;
    }

    let scope: any,
      cancel = false;

    waitForZwibblerLoad().then(() => {
      const nodeChecker = (node: any) => {
        if (
          // (scope.ctx.summary.properties.commands.length < 10 &&
          //   scope.ctx.summary.properties.commands[1] !==
          //     scope.ctx.summary.properties.commands[4]) ||
          scope.ctx.summary.properties.commands.length > 7 &&
          Number(
            scope.ctx.summary.properties.commands[
              scope.ctx.summary.properties.commands.length - 1
            ]
          ) !== 7
        ) {
          scope.ctx.deleteNode(node[0]);
        }
      };
      if (!cancel) {
        scope = window.Zwibbler.attach(zwibblerEl.current, {});
        ctx.current = scope.ctx;
        scope.ctx.on("drop-shape", shapesMoveHandler);
        scope.ctx.on("nodes-changed", nodeChangeHandler);
        scope.ctx.on("nodes-added", nodeChecker);
        const newShapesObject: any = {};
        JSON.parse(localStorage.ignoreZones).map((coordinatesArray: any[]) => {
          const shapeArray: any[] = [];
          coordinatesArray.forEach((array: any[]) => {
            shapeArray.push(array[0] * dimensions.width);
            shapeArray.push(array[1] * dimensions.height + 175);
          });
          ctx.current.createShape(shapeArray);
          const selectedNode = ctx.current.getNodeUnderPoint(
            coordinatesArray[0][0] * dimensions.width,
            coordinatesArray[0][1] * dimensions.height + 175
          );
          newShapesObject[selectedNode as keyof typeof newShapesObject] =
            convertedCoordinatesArrayCreator(shapeArray);
        });
        setShapesObject(newShapesObject);
        const deleteNode = (node: string) => {
          delete newShapesObject[node as keyof typeof newShapesObject];
          setShapesObject(newShapesObject);
        };
        const nodeDeleteHandler = (node: string[]) => {
          deleteNode(node[0]);
        };
        scope.ctx.on("nodes-removed", nodeDeleteHandler);
      }
    });
    return () => {
      cancel = true;
      if (scope) {
        scope.ctx.destroy();
      }
    };
  }, []);

  useEffect(() => {
    setZwibblerConfig((previousState) => {
      return {
        ...previousState,
        defaultStrokeStyle: localStorage.ignoreZoneColor,
        defaultfillstyle: LightenDarkenColor(localStorage.ignoreZoneColor, 70),
      };
    });
  }, []);

  const coordinatesArrayCreator = (inputArray: number[]) => {
    const returnArray = [];
    let y = 0;
    let pushedArray = [];
    for (let x = 0; x < inputArray.length - 4; x += 1) {
      if (y === 0) {
        y = 1;
        pushedArray = [];
      } else if (y === 1) {
        pushedArray.push(inputArray[x] / dimensions.width);
        y = 2;
      } else {
        pushedArray.push((inputArray[x] - 175) / dimensions.height);
        returnArray.push(pushedArray);
        y = 0;
      }
    }
    return returnArray;
  };

  const convertedCoordinatesArrayCreator = (inputArray: number[]) => {
    const returnArray = [];
    let pushedArray = [];
    for (let x = 0; x < inputArray.length; x += 1) {
      if (x % 2 === 0) {
        pushedArray.push(inputArray[x] / dimensions.width);
      } else {
        pushedArray.push((inputArray[x] - 175) / dimensions.height);
        returnArray.push(pushedArray);
        pushedArray = [];
      }
    }
    return returnArray;
  };

  const listener = (event: any) => {
    localStorage.setItem("shiftedObj", JSON.stringify(shiftedObj));

    const xAxis = event.clientX - 301;
    const yAxis = event.clientY - 224;
    const selectedNode = ctx.current.getNodeUnderPoint(xAxis, yAxis);
    if (selectedNode) {
      setCurrentNode(selectedNode);
    }

    const coordinatesArray = ctx.current.summary.properties.commands;
    if (
      coordinatesArray &&
      coordinatesArray[coordinatesArray.length - 1] === 7
    ) {
      const updatedShapesObject: any = shapesObject;
      updatedShapesObject[
        ctx.current.summary.nodes[0] as keyof typeof updatedShapesObject
      ] = coordinatesArrayCreator(coordinatesArray);
      setShapesObject(updatedShapesObject);
      const selectedNode = ctx.current.getNodeUnderPoint(
        event.clientX - 301,
        event.clientY - 224
      );
      if (ctx.current.summary.nodes[0] === selectedNode) {
        const newShapesObject: any = shapesObject;

        const currentCoordinates = coordinatesArrayCreator(coordinatesArray);

        newShapesObject[selectedNode as keyof typeof newShapesObject] =
          currentCoordinates;
        setShapesObject(newShapesObject);
      }
    }
    localStorage.setItem("shapesObject", JSON.stringify(shapesObject));
  };

  const deleteNode = (event: any) => {
    if (event.key === "Backspace") {
      const newShapesObject = shapesObject;
      delete newShapesObject[currentNode as keyof typeof newShapesObject];
      setShapesObject(newShapesObject);
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", listener);
    return () => {
      window.removeEventListener("mousemove", listener);
    };
  }),
    [];

  // useEffect(() => {
  //   window.addEventListener("keyup", deleteNode);
  //   return () => {
  //     window.removeEventListener("keyup", deleteNode);
  //   };
  // }),
  //   [];

  // useEffect(() => {
  //   window.addEventListener("keypress", deleteNode);
  //   return () => {
  //     window.removeEventListener("keypress", deleteNode);
  //   };
  // }),
  //   [];

  // Here we define things that we want to make available in our ref
  useImperativeHandle(ref, () => ({
    get ctx() {
      return ctx.current;
    },
  }));

  // You may freely mix react and Zwibbler-framework directives (eg, z-if, z-for) here.
  // But you will be unable to use z-on:click because of the colon.
  return (
    <div {...zwibblerConfig} ref={zwibblerEl} className="zwibbler">
      <div z-canvas="" className="stretch" />
      {/* <div>
        <button
          onClick={() => {
            console.log(shapesObject);
          }}
        >
          Test&nbsp;&nbsp;&nbsp;
        </button>
        <button
          onClick={() => {
          }}
        >
          Test 3
        </button>
      </div> */}
    </div>
  );
});

// HACK for STACKBLIZ ONLY. THIS IS NOT NECESSARY IF YOU INCLUDE
// zwibbler-demo.js in your index.html file or as part of  your project.
const loadPromise = new Promise<void>((resolve) => {
  const interval = setInterval(() => {
    if ("Zwibbler" in window) {
      // Zwibbler = window["Zwibbler"];
      clearInterval(interval);
      resolve();
      return;
    }
  }, 100);
});

function waitForZwibblerLoad() {
  return loadPromise;
}

ZwibblerComponent.displayName = "ZwibblerComponent";

export default ZwibblerComponent;
