import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "./siteAboutInterfaces";
import { getRefreshToken } from "../../../../utils/getRefreshToken";
import { SchedulesTab } from "../SchedulesTab";
import { NavigateFunction } from "react-router-dom";

export const handleSubmitDetailedScheduleChanges = (
  postObject: any,
  setActiveTab: any,
  setEditMode: Dispatcher<boolean>,
  setAllSiteSchedules: Dispatcher<any>,
  setSiteSchedules: Dispatcher<any>,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  siteInfo: any,
  setDeleteScheduleDialogOpen: Dispatcher<boolean>,
  setSiteInfo: Dispatcher<any>,
  allSiteSchedules: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);
  const allSiteSchedulesBackup = JSON.parse(
    localStorage.allSiteSchedulesBackup
  );

  const returnFunction = () => {
    handleSubmitDetailedScheduleChanges(
      postObject,
      setActiveTab,
      setEditMode,
      setAllSiteSchedules,
      setSiteSchedules,
      setBackdropLoadingScreenOn,
      siteInfo,
      setDeleteScheduleDialogOpen,
      setSiteInfo,
      allSiteSchedules,
      navigate
    );
  };

  setActiveTab(<></>);
  setEditMode(false);

  postObject.forEach((schedule: any, index: number) => {
    if (schedule.start_time === "") {
      postObject[index].start_time = null;
    }
    if (schedule.end_time === "") {
      postObject[index].end_time = null;
    }
  });

  AxiosUI.post(`schedule/`, postObject).then(
    () => {
      localStorage.setItem("scheduleChangeError", "false");
      localStorage.setItem(
        "allSiteSchedulesBackup",
        JSON.stringify(allSiteSchedulesBackup)
      );
      AxiosUI.get(`schedule/by_customer/${localStorage.siteId}/`).then(
        (response: AxiosResponse) => {
          localStorage.setItem("siteSchedules", JSON.stringify(response.data));
          localStorage.setItem("siteSchedule", JSON.stringify(response.data));
          setSiteSchedules(response.data);
          setAllSiteSchedules(response.data);
          localStorage.setItem(
            "allSiteSchedulesBackup",
            JSON.stringify(response.data)
          );
          setBackdropLoadingScreenOn(false);
          if (localStorage.scheduleSubmitError === "true") {
            localStorage.removeItem("scheduleSubmitError");
            setActiveTab(
              <SchedulesTab
                allSiteSchedules={response.data}
                siteInfo={siteInfo}
                setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
              />
            );
          } else {
            setActiveTab(
              <SchedulesTab
                allSiteSchedules={response.data}
                siteInfo={siteInfo}
                setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
              />
            );
          }
        },
        (reason: AxiosError) => {
          if (
            reason.request.response.slice(11, 53) ===
              "SocialToken matching query does not exist." ||
            reason.request.response.includes(
              "Returned invalid token from cache"
            ) ||
            reason.request.response.slice(11, 53) === "Token is expired"
          ) {
            getRefreshToken(navigate, returnFunction);
          } else {
            setSiteInfo(JSON.parse(localStorage.siteInfo));
            setEditMode(false);
            setActiveTab(
              <SchedulesTab
                allSiteSchedules={allSiteSchedules}
                siteInfo={siteInfo}
                setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
              />
            );
          }
        }
      );
    },
    (reason: AxiosError) => {
      localStorage.setItem("scheduleChangeError", "true");
      localStorage.setItem("scheduleSubmitError", "true");
      setAllSiteSchedules(allSiteSchedulesBackup);
      localStorage.setItem(
        "allSiteSchedules",
        JSON.stringify(allSiteSchedulesBackup)
      );
      if (
        reason.request.response.includes(
          "overlaps with existing enabled schedule"
        )
      ) {
        alert(
          `Schedule Changes not accepted: One or more days on changed schedule overlaps with day(s) from another scheulde at this Site.`
        );
        setEditMode(false);
        setBackdropLoadingScreenOn(false);
        setAllSiteSchedules(allSiteSchedulesBackup);
        setActiveTab(
          <SchedulesTab
            allSiteSchedules={allSiteSchedulesBackup}
            siteInfo={siteInfo}
            setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          />
        );
      } else if (
        reason.request.response.slice(11, 53) ===
          "SocialToken matching query does not exist." ||
        reason.request.response.includes("Returned invalid token from cache") ||
        reason.request.response.includes === "Token not found"
      ) {
        getRefreshToken(navigate, returnFunction);
        setActiveTab(
          <SchedulesTab
            allSiteSchedules={allSiteSchedulesBackup}
            siteInfo={siteInfo}
            setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          />
        );
      } else {
        alert(`Schedule Changes not accepted, please try again`);
        setEditMode(false);
        setBackdropLoadingScreenOn(false);
        setAllSiteSchedules(allSiteSchedulesBackup);
        setActiveTab(
          <SchedulesTab
            allSiteSchedules={allSiteSchedulesBackup}
            siteInfo={siteInfo}
            setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          />
        );
      }
    }
  );
};
