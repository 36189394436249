import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AlertsTableHealthMonitoring } from "./AlertsTableHealthMonitoring";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleClickedTabChangeHM } from "./HealthMonitoringUtils/handleClickedTabChangeHM";
import { NavigateFunction } from "react-router-dom";
import { FilterMenuAlerts } from "./FilterMenuAlerts";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const AlertSection = ({
  unhealthyCamsData,
  clickedTab,
  setClickedTab,
  alertsData,
  activeTable,
  setActiveTable,
  setAlertStatusDialogOpen,
  setSelectedRow,
  setSelectedRowIndex,
  setFectchingAllData,
  setAlertsData,
  updatingStatus,
  setUnhealthyCamsData,
  setSortedData,
  singleCellActivated,
  activeName,
  filteredDataLengths,
  setUpdatingStatus,
  cameraDropDownValue,
  filters,
  alertFilter,
  setAlertFilter,
  setFilteredDataLengths,
  setSceneChangeOutcomesDialogOpen,
  setBackgroundFrame,
  setDetectedFrame,
  setSelectedRows,
  selectedRows,
  setErrorsOverviewData,
  setCameraStatsData,
  setLoadingCameraStatsData,
  setBandwidthData,
  setLoadingBandwidthData,
  setShowSiteStats,
  navBarCollapsed,
  navigate,
}: {
  unhealthyCamsData: any;
  clickedTab: any;
  setClickedTab: any;
  alertsData: any;
  activeTable: any;
  setActiveTable: any;
  setAlertStatusDialogOpen: any;
  setSelectedRow: any;
  setSelectedRowIndex: any;
  setFectchingAllData: Dispatcher<boolean>;
  setAlertsData: any;
  updatingStatus: any;
  setUnhealthyCamsData: any;
  setSortedData: any;
  singleCellActivated: boolean;
  activeName: string;
  filteredDataLengths: any;
  setUpdatingStatus: any;
  cameraDropDownValue: string;
  filters: any;
  alertFilter: string;
  setAlertFilter: Dispatcher<string>;
  setFilteredDataLengths: any;
  setSceneChangeOutcomesDialogOpen: any;
  setBackgroundFrame: Dispatcher<string>;
  setDetectedFrame: Dispatcher<string>;
  setSelectedRows: any;
  selectedRows: any;
  setErrorsOverviewData: any;
  setCameraStatsData: any;
  setLoadingCameraStatsData: any;
  setBandwidthData: any;
  setLoadingBandwidthData: any;
  setShowSiteStats: any;
  navBarCollapsed: boolean;
  navigate: NavigateFunction;
}) => {
  React.useEffect(() => {
    setActiveTable(
      <AlertsTableHealthMonitoring
        alertsData={alertsData}
        setAlertStatusDialogOpen={setAlertStatusDialogOpen}
        setSelectedRow={setSelectedRow}
        setSelectedRowIndex={setSelectedRowIndex}
        setFectchingAllData={setFectchingAllData}
        setAlertsData={setAlertsData}
        setUnhealthyCamsData={setUnhealthyCamsData}
        unhealthyCamsData={unhealthyCamsData}
        clickedTab={clickedTab}
        setActiveTable={setActiveTable}
        setSortedData={setSortedData}
        singleCellActivated={singleCellActivated}
        activeName={activeName}
        setUpdatingStatus={setUpdatingStatus}
        filters={filters}
        alertFilter={alertFilter}
        setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
        setBackgroundFrame={setBackgroundFrame}
        setDetectedFrame={setDetectedFrame}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        setErrorsOverviewData={setErrorsOverviewData}
        setCameraStatsData={setCameraStatsData}
        setLoadingCameraStatsData={setLoadingCameraStatsData}
        setBandwidthData={setBandwidthData}
        setLoadingBandwidthData={setLoadingBandwidthData}
        setShowSiteStats={setShowSiteStats}
        cameraDropDownValue={cameraDropDownValue}
        setFilteredDataLengths={setFilteredDataLengths}
        navigate={navigate}
      />
    );
  }, [alertsData]);

  const tableWidth = !navBarCollapsed ? [1250, 1248] : [1325, 1323];

  return (
    <div
      className={
        singleCellActivated || cameraDropDownValue
          ? `absolute top-[50px] left-[20px] right-[1px] h-[455px] w-[${tableWidth[0]}px] bg-[#BDBDBD]`
          : `absolute top-[50px] left-[20px] right-[1px] h-[575px] w-[${tableWidth[0]}px] bg-[#BDBDBD]`
      }
    >
      <div
        className={
          singleCellActivated || cameraDropDownValue
            ? `absolute top-[1px] left-[1px] right-[1px] h-[453px] w-[${tableWidth[1]}px] bg-white`
            : `absolute top-[1px] left-[1px] right-[1px] h-[573px] w-[${tableWidth[1]}px] bg-white`
        }
      >
        <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[8px] left-[30px]">
          {singleCellActivated || cameraDropDownValue
            ? `Alerts ${activeName ? activeName : cameraDropDownValue}`
            : `Alerts`}
        </p>
        <div
          className={
            !navBarCollapsed
              ? "absolute top-[8px] left-[940px]"
              : "absolute top-[8px] left-[1015px]"
          }
        >
          {(filters.metrics.length === 0 || filters.metrics.length > 1) && (
            <FilterMenuAlerts
              alertFilter={alertFilter}
              setAlertFilter={setAlertFilter}
              alertsData={alertsData}
              setFilteredDataLengths={setFilteredDataLengths}
              singleCellActivated={singleCellActivated}
              setActiveTable={setActiveTable}
              setAlertStatusDialogOpen={setAlertStatusDialogOpen}
              setSelectedRow={setSelectedRow}
              setSelectedRowIndex={setSelectedRowIndex}
              setFectchingAllData={setFectchingAllData}
              setAlertsData={setAlertsData}
              setUnhealthyCamsData={setUnhealthyCamsData}
              unhealthyCamsData={unhealthyCamsData}
              clickedTab={clickedTab}
              setSortedData={setSortedData}
              setUpdatingStatus={setUpdatingStatus}
              filters={filters}
              activeName={activeName}
              setSceneChangeOutcomesDialogOpen={
                setSceneChangeOutcomesDialogOpen
              }
              setBackgroundFrame={setBackgroundFrame}
              setDetectedFrame={setDetectedFrame}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              setErrorsOverviewData={setErrorsOverviewData}
              setCameraStatsData={setCameraStatsData}
              setLoadingCameraStatsData={setLoadingCameraStatsData}
              setBandwidthData={setBandwidthData}
              setLoadingBandwidthData={setLoadingBandwidthData}
              setShowSiteStats={setShowSiteStats}
              cameraDropDownValue={cameraDropDownValue}
              navigate={navigate}
            />
          )}
        </div>
        {!singleCellActivated &&
          !cameraDropDownValue &&
          filters.metrics.length === 0 && (
            <div
              className={
                !navBarCollapsed
                  ? "absolute left-[50px]"
                  : "absolute left-[88px]"
              }
            >
              <div
                className={
                  "absolute top-[50px] left-[0px] right-[1px] h-[100px] w-[250px] bg-[#BDBDBD]"
                }
              >
                <div className="absolute top-[1px] left-[1px] right-[1px] h-[98px] w-[248px] bg-white">
                  <p className="font-mulish text-[18px] text-actuate-blue pt-[10px] pl-[15px]">
                    Unhealthy Cameras
                  </p>
                  <div>
                    <p className="absolute font-mulish text-[28px] text-actuate-blue top-[42px] left-[15px] font-bold">
                      {`${unhealthyCamsData.unhealthy_cameras}`}
                      {/* {`${alertsData.all.length}`} */}
                      <span className="font-mulish text-[28px] text-[#182E54]  opacity-30 font-bold">
                        {`/`}
                      </span>
                      <span className="font-mulish text-[28px] text-[#182E54]  opacity-30  font-bold">
                        {`${unhealthyCamsData.total_cameras}`}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute top-[50px] left-[300px] right-[1px] h-[100px] w-[250px] bg-[#BDBDBD]">
                <div className="absolute top-[1px] left-[1px] right-[1px] h-[98px] w-[248px] bg-white">
                  <p className="font-mulish text-[18px] text-actuate-blue pt-[10px] pl-[15px]">
                    <CancelIcon sx={{ color: "#D32F2F" }} /> &nbsp; Total Errors
                  </p>
                  <div>
                    <p className="absolute font-mulish text-[28px] text-actuate-blue top-[42px] left-[18px] font-bold">
                      {/* {`${unhealthyCamsData.errors}`} */}
                      {`${alertsData.errors.length}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute top-[50px] left-[600px] right-[1px] h-[100px] w-[250px] bg-[#BDBDBD]">
                <div className="absolute top-[1px] left-[1px] right-[1px] h-[98px] w-[248px] bg-white">
                  <p className="font-mulish text-[18px] text-actuate-blue pt-[10px] pl-[15px]">
                    <ErrorIcon sx={{ color: "#F57C00" }} /> &nbsp; Total
                    Warnings
                  </p>
                  <div>
                    <p className="absolute font-mulish text-[28px] text-actuate-blue top-[42px] left-[18px] font-bold">
                      {/* {`${unhealthyCamsData.warnings}`} */}
                      {`${alertsData.warnings.length}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute top-[50px] left-[900px] right-[1px] h-[100px] w-[250px] bg-[#BDBDBD]">
                <div className="absolute top-[1px] left-[1px] right-[1px] h-[98px] w-[248px] bg-white">
                  <p className="font-mulish text-[18px] text-actuate-blue pt-[10px] pl-[15px]">
                    <CheckCircleIcon sx={{ color: "#2E7D32" }} /> &nbsp; Total
                    Resolved
                  </p>
                  <div>
                    <p className="absolute font-mulish text-[28px] text-actuate-blue top-[42px] left-[18px] font-bold">
                      {!updatingStatus &&
                        // `${unhealthyCamsData.resolved}`
                        `${alertsData.resolved.length}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        <div
          className={
            singleCellActivated ||
            cameraDropDownValue ||
            filters.metrics.length > 0
              ? `absolute top-[60px] w-[${tableWidth[0]}px]`
              : `absolute top-[180px] w-[${tableWidth[0]}px]`
          }
        >
          <Box>
            <ThemeProvider theme={theme}>
              <Tabs
                value={clickedTab}
                onChange={(event, tabValue) => {
                  setActiveTable(<></>);
                  handleClickedTabChangeHM(
                    event,
                    tabValue,
                    setActiveTable,
                    setClickedTab,
                    alertsData,
                    setAlertStatusDialogOpen,
                    setSelectedRow,
                    setSelectedRowIndex,
                    setFectchingAllData,
                    setAlertsData,
                    setUnhealthyCamsData,
                    unhealthyCamsData,
                    clickedTab,
                    setSortedData,
                    singleCellActivated,
                    activeName,
                    setUpdatingStatus,
                    filters,
                    alertFilter,
                    setSceneChangeOutcomesDialogOpen,
                    setBackgroundFrame,
                    setDetectedFrame,
                    setSelectedRows,
                    selectedRows,
                    setErrorsOverviewData,
                    setCameraStatsData,
                    setLoadingCameraStatsData,
                    setBandwidthData,
                    setLoadingBandwidthData,
                    setShowSiteStats,
                    cameraDropDownValue,
                    setFilteredDataLengths,
                    navigate
                  );
                }}
                aria-label="intestigation_tabs"
                color="primary"
                variant="fullWidth"
                // sx={{ width: "500px" }}
              >
                <Tab
                  value="all"
                  label={
                    singleCellActivated ||
                    cameraDropDownValue ||
                    alertFilter !== "all"
                      ? `All (${filteredDataLengths.all})`
                      : `All (${alertsData.all.length})`
                  }
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "primary",
                  }}
                />
                <Tab
                  value="errors"
                  label={
                    singleCellActivated ||
                    cameraDropDownValue ||
                    alertFilter !== "all"
                      ? `Error (${filteredDataLengths.errors})`
                      : `Error (${alertsData.errors.length})`
                  }
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "primary",
                  }}
                />
                <Tab
                  value="warnings"
                  label={
                    singleCellActivated ||
                    cameraDropDownValue ||
                    alertFilter !== "all"
                      ? `Warning (${filteredDataLengths.warnings})`
                      : `Warning (${alertsData.warnings.length})`
                  }
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "primary",
                  }}
                />
                <Tab
                  value="pending"
                  label={
                    singleCellActivated ||
                    cameraDropDownValue ||
                    alertFilter !== "all"
                      ? `Pending (${filteredDataLengths.pending})`
                      : `Pending (${alertsData.pending.length})`
                  }
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "primary",
                  }}
                />
                <Tab
                  value="resolved"
                  label={
                    singleCellActivated ||
                    cameraDropDownValue ||
                    alertFilter !== "all"
                      ? `Resolved (${filteredDataLengths.resolved})`
                      : `Resolved (${alertsData.resolved.length})`
                  }
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "primary",
                  }}
                />
              </Tabs>
            </ThemeProvider>
          </Box>
          <div className={!navBarCollapsed ? "w-[1248px]" : "w-[1323px]"}>
            {activeTable}
          </div>
        </div>
      </div>
    </div>
  );
};
