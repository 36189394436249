import React from "react";
import { TrueThreatButton } from "./TrueThreatButton";
import { TrueNonThreatButton } from "./TrueNonThreatButton";
import { AlertData } from "./AlertsUtils/alertsInterfaces";
import { FalseButton } from "./FalseButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { handleCreateAlertTypeNoUnderscore } from "./AlertsTable";

export const AlertsIdentifierBox = ({
  selectedRow,
  alertData,
  setAlertData,
  setSelectedRow,
  selectdButtonsFilled,
  setSelectedButtonsFilled,
  navigate,
  setPageLoading,
  setButtonClicked,
}: {
  selectedRow: AlertData;
  alertData: AlertData[];
  setAlertData: Dispatcher<AlertData[]>;
  setSelectedRow: Dispatcher<AlertData>;
  selectdButtonsFilled: any;
  setSelectedButtonsFilled: any;
  navigate: NavigateFunction;
  setPageLoading: Dispatcher<boolean>;
  setButtonClicked: Dispatcher<boolean>;
}) => {
  return (
    <div className="w-[615px] h-[100px] rounded-lg  bg-white">
      <div className="relative left-[155px] top-[7px] w-[400px] text-actuate-blue font-mulish text-[16px]">
        {selectedRow.alert_labels
          ? `Was this alert a true ${handleCreateAlertTypeNoUnderscore(
              selectedRow.alert_labels
            )} detection?`
          : ""}
      </div>
      <div className="columns-3 w-[615px] pl-[13px] pt-[15px]">
        <div>
          <TrueThreatButton
            detection_type={selectedRow.detection_type}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
          />
        </div>
        <div>
          <TrueNonThreatButton
            detection_type={selectedRow.detection_type}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
          />
        </div>
        <div>
          <FalseButton
            detection_type={selectedRow.detection_type}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
          />
        </div>
      </div>
      {/* <div className="relative left-[20px] w-[300px] top-[20px]">
        
      </div>
      <div className="relative left-[220px] w-[300px] top-[20px]">
        
      </div>
      <div className="relative left-[420px] w-[140px] top-[20px]">
        
      </div> */}
    </div>
  );
};
