export const handleCreateAutoAddObject = (camerasObject: any) => {
  interface ReturnObject {
    items: any[];
  }

  const returnObject: ReturnObject = {
    items: [],
  };

  for (const key in camerasObject) {
    if (key !== "objectId" && key !== "key" && camerasObject[key].products[0]) {
      returnObject.items.push(camerasObject[key]);
    }
  }
  return returnObject;
};
