import React from "react";
import { TextField } from "@mui/material";
import { Field } from "formik";

export const PasswordFieldType = ({
  config,
  formik,
  setErrorState,
  stepNumber,
}: {
  config: any;
  formik: any;
  setErrorState: any;
  stepNumber: any;
}) => {
  React.useEffect(() => {
    if (
      localStorage.copyValues === "true" &&
      localStorage.firstCameraAdded === "false" &&
      stepNumber === 1
    ) {
      const values = JSON.parse(localStorage.copiedValues);
      formik.values.password = values.password;
    }
    if (config.validation) {
      setErrorState((previousState: any) => {
        return { ...previousState, [config.id]: true };
      });
    }
  }, []);

  return (
    <div key={config.id}>
      <Field
        id={config.id}
        label={config.label}
        name={config.field}
        variant="standard"
        sx={{ width: 350 }}
        helperText={config.helperText}
        type="password"
        as={TextComponent}
      />
    </div>
  );
};

const TextComponent = (props: any) => <TextField {...props} />;
