// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleTriggerMotionAxis = (
  cameraId: string,
  navigate: NavigateFunction,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setEditCameraAlerts: Dispatcher<any>
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleTriggerMotionAxis(
      cameraId,
      navigate,
      setBackdropLoadingScreenOn,
      setEditCameraAlerts
    );
  };

  AxiosUI.post(`/axis/${cameraId}/trigger_motion/`).then(
    () => {
      setBackdropLoadingScreenOn(false);
      setEditCameraAlerts((previousState: any) => {
        return {
          ...previousState,
          triggerMotionFail: false,
          triggerMotionSuccess: true,
        };
      });
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        setEditCameraAlerts((previousState: any) => {
          return {
            ...previousState,
            triggerMotionFail: true,
            triggerMotionSuccess: false,
          };
        });
      });
    }
  );
};
