export const colorsArray = [
  "#FF8400",
  "#01579B",
  "#D32F2F",
  "#2E7D32",
  "#8830FC",
  "#FFC208",
  "#AD1457",
  "#0288D1",
  "#019B8C",
  "#38019B",
  "#CCA409",
  "#019B4D",
  "#CC4809",
  "#9B0157",
  "#9B0152",
  "#01589B",
  "#C00030",
  "#90C000",
];
