import React from "react";

export const handlePushGroupsWithChildrenToTop = (groupsArray: any) => {
  // check each object in the groups array and if object.children[0] exists, push it to the top of the array
  const groupsArrayCopy = [...groupsArray];
  const groupsWithChildren = groupsArrayCopy.filter(
    (group: any) => group.children[0] || group.parent_account
  );
  const groupsWithoutChildren = groupsArrayCopy.filter(
    (group: any) => !group.children[0] && !group.parent_account
  );
  const sortedGroupsArray = [...groupsWithChildren, ...groupsWithoutChildren];
  return sortedGroupsArray;
};
