/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { NavBar } from "../../common/NavBar";
import useWindowDimensions from "../../common/useWindowDimensions";
import { TotalCamerasTable } from "./TotalCamerasTable";
import { DataUsageGraph } from "./DataUsageGraph";
import { FalsePositiveReductionGraph } from "./FalsePositiveReductionGraph";
import { useNavigate } from "react-router-dom";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { getTotalCamerasInfo } from "./DashbordUtils/getTotalCamerasInfo";
import { getFalsePositiveData } from "./DashbordUtils/getFalsePositiveData";
import { handleGetNewDataUsageData } from "./DashbordUtils/handleGetNewDataUsageData";
import { getRefreshTimeout } from "../../utils/getRefreshTimeout";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const Dashboard = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const userName = localStorage.username;

  const firstName = localStorage.first_name;

  const { width, height } = useWindowDimensions();

  const [dashboardTotal, setDashboardTotal] = React.useState<any>({});

  const [activeTab, setActiveTab] = React.useState("error");

  const [camerasErrorInfo, setCamerasErrorInfo] = React.useState<any>(
    // JSON.parse(localStorage.camerasErrorInfo)
    []
  );

  const [camerasWarningInfo, setCamerasWarningInfo] = React.useState<any>(
    // JSON.parse(localStorage.camerasWarningInfo)
    []
  );

  const [page, setPage] = React.useState(0);

  const [pageLoading, setPageLoading] = React.useState(false);

  const [currentData, setCurrentData] = React.useState("usageData");

  const [usageData, setUsageData] = React.useState(
    JSON.parse(localStorage.top5DataUsage)
  );

  const [noFPData, setNoFPData] = React.useState(false);

  const [falsePostiveData, setFalsePostiveData] = React.useState(
    JSON.parse(localStorage.falsePostiveData)
  );

  const [dateRangeName, setDateRangeName] = React.useState("Last 24 hours");

  const [dataIsLoading, setDataIsLoading] = React.useState(false);

  const [errorPaginationNumber, setErrorPaginationNumber] = React.useState(1);

  const [warningPaginationNumber, setWarningPaginationNumber] =
    React.useState(1);

  const navigate = useNavigate();

  React.useEffect(() => {
    // if (dashboardTotal) {
    //   setDashboardTotal(JSON.parse(localStorage.dashboardTotal));
    // }
    if (camerasErrorInfo) {
      setCamerasErrorInfo(JSON.parse(localStorage.camerasErrorInfo));
    }
    if (camerasWarningInfo) {
      setCamerasWarningInfo(JSON.parse(localStorage.camerasWarningInfo));
    }
  }, []);

  React.useEffect(() => {
    let cameraTotalBoolean = true;

    if (!dashboardTotal) {
      cameraTotalBoolean = false;
    }

    // let errorBoolean = true;

    // if (!camerasErrorInfo) {
    //   errorBoolean = false;
    // }

    // let warningBoolean = true;

    // if (!camerasWarningInfo) {
    //   warningBoolean = false;
    // }

    getTotalCamerasInfo(
      setDashboardTotal,
      setCamerasErrorInfo,
      setCamerasWarningInfo,
      setActiveTab,
      activeTab,
      cameraTotalBoolean,
      dashboardTotal,
      false,
      false,
      navigate
    );
  }, []);

  React.useEffect(() => {
    getRefreshTimeout(localStorage.groupID);
  }, []);

  React.useEffect(() => {
    if (!falsePostiveData) {
      getFalsePositiveData(setFalsePostiveData, navigate, setNoFPData);
    }
  }, []);

  React.useEffect(() => {
    if (!usageData[0]) {
      handleGetNewDataUsageData(
        "last_24_hours",
        "bandwidth",
        setDataIsLoading,
        navigate,
        setUsageData,
        setDateRangeName
      );
    }
  }, []);

  return (
    <div>
      <BackdropLoadingScreen openState={pageLoading} />
      <div>
        <div className="bg-actuate-grey absolute w-full h-full overflow-scroll">
          <div className="absolute top-[44px]">
            <svg width={width}>
              <line
                x1="0"
                y1="0"
                x2="100%"
                y2="0"
                style={{ stroke: `#BDBDBD`, strokeWidth: 4 }}
              />
            </svg>
          </div>
          <div
            className={
              navBarCollapsed
                ? "absolute left-[95px] top-2"
                : "absolute left-[170px] top-2"
            }
          >
            {!firstName ? (
              <p className="text-actuate-blue font-mulish text-[20px] font-bold">
                Welcome, {userName}
              </p>
            ) : (
              <p className="text-actuate-blue font-mulish text-[20px] font-bold">
                Welcome, {firstName}
              </p>
            )}
            <div></div>
          </div>
          {/* <div className="absolute right-[20px] top-3">
            <p className="text-[#FF0000] font-mulish text-[26px] font-bold">
              THIS PAGE IS ONLY FOR INTERNAL TESTING PURPOSES
            </p>
          </div> */}
          <div className="absolute">
            <div>
              <TotalCamerasTable
                dashboardTotal={dashboardTotal}
                setActiveTab={setActiveTab}
                setPage={setPage}
                activeTab={activeTab}
                camerasErrorInfo={camerasErrorInfo}
                camerasWarningInfo={camerasWarningInfo}
                page={page}
                setPageLoading={setPageLoading}
                navBarCollapsed={navBarCollapsed}
                errorPaginationNumber={errorPaginationNumber}
                setErrorPaginationNumber={setErrorPaginationNumber}
                setCameraErrorInfo={setCamerasErrorInfo}
                warningPaginationNumber={warningPaginationNumber}
                setWarningPaginationNumber={setWarningPaginationNumber}
                setCameraWarningInfo={setCamerasWarningInfo}
                navigate={navigate}
              />
            </div>
            <div>
              <DataUsageGraph
                usageData={usageData}
                currentData={currentData}
                setCurrentData={setCurrentData}
                setPageLoading={setPageLoading}
                setUsageData={setUsageData}
                dateRangeName={dateRangeName}
                setDateRangeName={setDateRangeName}
                dataIsLoading={dataIsLoading}
                setDataIsLoading={setDataIsLoading}
              />
            </div>
            <div>
              <FalsePositiveReductionGraph
                falsePostiveData={falsePostiveData}
                noFPData={noFPData}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
