import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const MetricsDropDown = ({
  navBarCollapsed,
  metricsSelected,
  setMetricsSelected,
}: {
  navBarCollapsed: boolean;
  metricsSelected: string[];
  setMetricsSelected: Dispatcher<string[]>;
}) => {
  const metricOptions = [
    "Connectivity",
    "Image Quality",
    "Scene Change",
    "Stream Quality",
    "Motion Status",
    "Recording Status",
  ];

  return (
    <div>
      <Autocomplete
        multiple
        value={metricsSelected}
        disablePortal
        options={metricOptions}
        onChange={(event, value: any) => {
          setMetricsSelected(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Metrics"
            autoComplete="off"
            sx={!navBarCollapsed ? { width: 175 } : { width: 200 }}
          />
        )}
      />
    </div>
  );
};
