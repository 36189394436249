import { removeNumberFromArray } from "./removeNumberFromArray";

export const handleSetSelectedIndexLevel = (
  selectedIndex: any,
  levelName: any,
  id: any,
  setSelectedIndex: any
) => {
  const storedSelectedIndex = JSON.parse(localStorage.storedSelectedIndex);

  if (selectedIndex[levelName].includes(id)) {
    setSelectedIndex((previousState: any) => {
      return {
        ...previousState,
        [levelName]: removeNumberFromArray(id, previousState[levelName]),
      };
    });
  } else if (!selectedIndex[levelName].includes(id)) {
    setSelectedIndex((previousState: any) => {
      const newArray = previousState[levelName];
      if (id && newArray) {
        newArray.push(id);
      }
      return {
        ...previousState,
        [levelName]: newArray,
      };
    });
  }
};
