import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getCamerasBySiteAlerts } from "../Alerts/AlertsUtils/getCamerasBySiteAlerts";
import { NavigateFunction } from "react-router-dom";

export const SiteDropDownSentinel = ({
  siteOptionsSentinel,
  loadingSitesSentinel,
  siteDropDownValueSentinel,
  setSiteDropDownValueSentinel,
  setCameraDropDownValueSentinel,
  setUserFiltersSentinel,
  setCameraOptionsSentinel,
  setLoadingCamerasSentinel,
  showUserDropDown,
  navBarCollapsed,
  navigate,
}: {
  siteOptionsSentinel: any;
  loadingSitesSentinel: boolean;
  siteDropDownValueSentinel: string;
  setSiteDropDownValueSentinel: Dispatcher<string>;
  setCameraDropDownValueSentinel: Dispatcher<string>;
  setUserFiltersSentinel: any;
  setCameraOptionsSentinel: any;
  setLoadingCamerasSentinel: Dispatcher<boolean>;
  showUserDropDown: boolean;
  navBarCollapsed: boolean;
  navigate: NavigateFunction;
}) => {
  const siteIds = new Map();
  siteOptionsSentinel.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  // const sx = showUserDropDown ? { width: 250 } : { width: 300 };

  let sx: any;

  if (showUserDropDown && navBarCollapsed) {
    sx = { width: 275 };
  }

  if (showUserDropDown && !navBarCollapsed) {
    sx = { width: 250 };
  }

  if (!showUserDropDown && navBarCollapsed) {
    sx = { width: 425 };
  }

  if (!showUserDropDown && !navBarCollapsed) {
    sx = { width: 380 };
  }

  return (
    <div key="site_dropdown">
      <Autocomplete
        value={siteDropDownValueSentinel}
        disablePortal
        options={
          siteOptionsSentinel[0]
            ? siteOptionsSentinel.map((object: any) => {
                return object.name;
              })
            : []
        }
        loading={loadingSitesSentinel}
        onChange={(event, value: any) => {
          if (value !== null) {
            setSiteDropDownValueSentinel(value);
            setCameraDropDownValueSentinel("");
            getCamerasBySiteAlerts(
              [siteIds.get(value)],
              navigate,
              setCameraOptionsSentinel,
              setLoadingCamerasSentinel
            );
            setUserFiltersSentinel((previousState: any) => {
              return {
                ...previousState,
                site_ids: [siteIds.get(value)],
                camera_ids: [],
              };
            });
          } else {
            setSiteDropDownValueSentinel(value);
            setCameraDropDownValueSentinel("");
            setCameraOptionsSentinel([]);
            setUserFiltersSentinel((previousState: any) => {
              return {
                ...previousState,
                site_ids: [],
                camera_ids: [],
              };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Site"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingSitesSentinel ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
            sx={sx}
          />
        )}
      />
    </div>
  );
};
