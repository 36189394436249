import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AlertData, Order } from "../Alerts/AlertsUtils/alertsInterfaces";
import {
  getComparator,
  stableSort,
} from "../Alerts/AlertsUtils/alertsTableFunctions";
import { EnhancedInvestigationTableHead } from "./EnhancedInvestigationTableHead";
import {
  formatDateInUTC,
  formatTimeStamp,
} from "./InvestigationUtilities/formatTimeStamp";
import { handleRowSelected } from "./InvestigationUtilities/handleRowSelected";
import { NavigateFunction } from "react-router-dom";
import { handleButtonShading } from "./InvestigationUtilities/handleButtonShading";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleReorderIndex } from "../Alerts/AlertsUtils/handleReorderIndex";
import { EnhancedSentinelTableHead } from "./EnhancedSentinelTableHead";

export const SentinelTable = ({
  sentinelData,
  setSentinelData,
  setSelectedRowSentinel,
  pageSentinel,
  setPageSentinel,
  setVideoClipLoadingSentinel,
  setVideoURLSentinel,
  scrollToTop,
  selectedRowSentinel,
  setClipsIndexOrderSentinel,
  userFiltersSentinel,
  navigate,
}: {
  sentinelData: any;
  setSentinelData: any;
  setSelectedRowSentinel: any;
  pageSentinel: any;
  setPageSentinel: any;
  setVideoClipLoadingSentinel: any;
  setVideoURLSentinel: any;
  scrollToTop: any;
  selectedRowSentinel: any;
  setClipsIndexOrderSentinel: any;
  userFiltersSentinel: any;
  navigate: NavigateFunction;
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("formatted_date");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AlertData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageSentinel(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageSentinel(0);
  };

  const emptyRows =
    pageSentinel > 0
      ? Math.max(0, (1 + pageSentinel) * rowsPerPage - sentinelData.length)
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(sentinelData, getComparator(order, orderBy)).slice(
        pageSentinel * rowsPerPage,
        pageSentinel * rowsPerPage + rowsPerPage
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, pageSentinel, rowsPerPage]
  );

  const roundToHundredth = (number: number) => {
    const newNumber = number * 100;
    return Math.round(newNumber * 100) / 100;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 490 }}>
        <Table
          sx={{ fontFamily: "Mulish", postion: "absolute" }}
          aria-labelledby="investigations-table"
          stickyHeader
        >
          <EnhancedSentinelTableHead
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
          />
          <TableBody>
            {visibleRows.map((row: any, index: number) => {
              let sx: any = { cursor: "pointer" };
              if (row.selected) {
                sx = {
                  cursor: "pointer",
                  backgroundColor: "rgba(2, 136, 209, 0.2)",
                };
              }
              return (
                <TableRow
                  hover
                  onClick={() => {
                    scrollToTop();
                    setVideoClipLoadingSentinel(true);
                    setSelectedRowSentinel(row);
                    localStorage.setItem(
                      "investigationsSelectedRow",
                      JSON.stringify(row)
                    );
                    handleRowSelected(
                      row,
                      setSelectedRowSentinel,
                      setVideoURLSentinel,
                      setVideoClipLoadingSentinel,
                      sentinelData,
                      setSentinelData,
                      selectedRowSentinel,
                      userFiltersSentinel,
                      "sentinel",
                      userFiltersSentinel.user,
                      navigate
                    );
                  }}
                  tabIndex={-1}
                  key={row.id}
                  sx={sx}
                >
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.file_id}
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.clip_header_received
                      ? formatDateInUTC(row.clip_header_received)
                      : ""}
                  </TableCell> */}
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.clip_received_local
                      ? formatTimeStamp(row.clip_received_local)
                      : ""}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.customer_name}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >
                    {row.camera_name}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "10px", fontFamily: "mulish" }}
                  >{`${roundToHundredth(row.probability)}%`}</TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sentinelData.length}
        rowsPerPage={rowsPerPage}
        page={pageSentinel}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
