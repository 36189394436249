import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { OrangeTextButton } from "../../common/OrangeTextButton";

export const NoAlertsDialog = ({
  noAlertsDialogOpen,
  setNoAlertsDialogOpen,
  cameraID,
  errorType,
  createdTimestamp,
  siteID,
}: {
  noAlertsDialogOpen: boolean;
  setNoAlertsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cameraID: string;
  errorType: string;
  createdTimestamp: string;
  siteID: any;
}) => {
  const [dialogText, setDialogText] = React.useState(
    `No individual alerts were found with the provided parameters: Camera id: ${cameraID}, Error Type: ${errorType}, Created Timestamp: ${createdTimestamp}.`
  );

  React.useEffect(() => {
    if (!siteID && !cameraID) {
      setDialogText(
        `No individual alerts were found with the provided parameters: Camera id: ${cameraID}, Error Type: ${errorType}, Created Timestamp: ${createdTimestamp}. Try adding both a site and camera id to the URL search paramaters, this can help generate more accurate alert results which may contain the desired alert.`
      );
    }
    if (siteID && !cameraID) {
      setDialogText(
        `No individual alerts were found with the provided parameters: Camera id: ${cameraID}, Error Type: ${errorType}, Created Timestamp: ${createdTimestamp}. Try adding a camera id to the URL search paramaters, this can help generate more accurate alert results which may contain the desired alert.`
      );
    }
  }, []);

  return (
    <Dialog open={noAlertsDialogOpen}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setNoAlertsDialogOpen(false);
          }}
          text="Ok"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
