import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleDeleteCalendarEvent = (
  eventId: string,
  setCalendarEvents: any,
  setEditingCalendar: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleDeleteCalendarEvent(
      eventId,
      setCalendarEvents,
      setEditingCalendar,
      navigate
    );
  };

  AxiosUI.delete(`calendar_event/${eventId}/`).then(
    (response: AxiosResponse) => {
      AxiosUI.get(
        `calendar_event/?calendar__id=${response.data.calendar}`
      ).then(
        (response: AxiosResponse) => {
          setCalendarEvents(response.data.results);
          setEditingCalendar(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setEditingCalendar(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setEditingCalendar(false);
      });
    }
  );
};
