import React from "react";

export const addSelectedParam = (
  data: any,
  setClipsIndexOrder: any,
  lastFileId: string
) => {
  const newData: any = [];
  const indexOrder: any[] = [];
  data.forEach((object: any, index: number) => {
    indexOrder.push(index);
    const newObject = object;
    if (!object.selected) {
      if (index === 0 && !lastFileId) {
        newObject.selected = true;
        newObject.index = index;
      } else {
        newObject.selected = false;
        newObject.index = index;
      }
    }
    newData.push(newObject);
  });
  setClipsIndexOrder(indexOrder);

  return newData;
};
