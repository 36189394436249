import React from "react";
import { ActuateButton } from "../../common/ActuateButton";
import { getCaledarsData } from "./CalendarsUtils/getCaledarsData";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";

export const SearchButtonCalendars = ({
  setFetchingCalendarData,
  setCalendarData,
  calendarsSearchString,
  setPage,
  setShowMoreDialogOpen,
  setSelectedRow,
  navigate,
}: {
  setFetchingCalendarData: Dispatcher<boolean>;
  setCalendarData: any;
  calendarsSearchString: string;
  setPage: any;
  setShowMoreDialogOpen: any;
  setSelectedRow: any;
  navigate: NavigateFunction;
}) => {
  return (
    <div>
      <ActuateButton
        onClick={() => {
          setFetchingCalendarData(true);
          setPage(0);
          getCaledarsData(
            setCalendarData,
            setFetchingCalendarData,
            calendarsSearchString,
            setPage,
            setShowMoreDialogOpen,
            setSelectedRow,
            navigate
          );
        }}
        text="Search"
        disabled={false}
      />
    </div>
  );
};
