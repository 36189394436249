import * as React from "react";
import { ModuleScreen } from "../../common/ModuleScreen";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Typography, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ErrorBoundary from "../../../ErrorBoundary";
import { handleNavigateToSitesPage } from "../Onboarding/handleNavigateToSitesPage";
import { useNavigate } from "react-router-dom";
import { handleCheckCameras } from "./handleCheckCameras";

export const CameraCheck = ({
  groupTrees,
  setGroupTrees,
  setCameraCheckFinsihedAlertOpen,
  setGoodCameras,
  setConnectivityCameras,
  setLowResolutionCameras,
  setCameraCheckResultDownloadURL,
}: {
  groupTrees: any;
  setGroupTrees: Dispatcher<any>;
  setCameraCheckFinsihedAlertOpen: Dispatcher<boolean>;
  setGoodCameras: Dispatcher<any>;
  setConnectivityCameras: Dispatcher<any>;
  setLowResolutionCameras: Dispatcher<any>;
  setCameraCheckResultDownloadURL: Dispatcher<string>;
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF8400",
      },
    },
  });

  const navigate = useNavigate();

  const onboardingScreen = (
    <ErrorBoundary>
      <div>
        <ThemeProvider theme={theme}>
          <div className="absolute top-[142px] right-44">
            <LinearProgress
              color="primary"
              variant="determinate"
              value={75}
              sx={{ width: 275 }}
            />
          </div>
          <div className="absolute top-32 right-32">
            <Typography variant="body1">{75}%</Typography>
          </div>
          <div className="absolute top-[195px] right-[150px] font-mulish text-actuate-blue">
            <p> Please complete the following information. </p>
          </div>
          <div className="absolute top-[220px] right-[190px] font-mulish text-actuate-blue">
            <p> Otherwise the site won&apos;t run.</p>
          </div>
          <div className="absolute top-[275px] right-[350px]">
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
              <FormControlLabel
                sx={{
                  fontFamily: "Mulish",
                  color: "#001943",
                }}
                label="Site Creation"
                control={<Checkbox color="primary" checked={true} />}
              />
              <br></br>
              <FormControlLabel
                sx={{
                  fontFamily: "Mulish",
                  color: "#001943",
                }}
                label="Camera Setup"
                control={<Checkbox color="primary" checked={true} />}
              />
              <br></br>
              {localStorage.healthcheckOnly === "false" && (
                <FormControlLabel
                  label="Schedule Setup"
                  control={<Checkbox color="primary" checked={true} />}
                />
              )}
              <br></br>
              <FormControlLabel
                label="Check Cameras"
                control={<Checkbox color="primary" checked={false} />}
              />
            </Box>
          </div>
          <div
            className={
              localStorage.healthcheckOnly === "false"
                ? "absolute top-[474px] right-24"
                : "absolute top-[411px] right-24"
            }
          >
            <Button
              color="primary"
              disabled={false}
              onClick={() => {
                localStorage.setItem("cameraCheckAlertOpen", "true");
                localStorage.setItem("clickedId", localStorage.siteId);
                handleNavigateToSitesPage(navigate);
                localStorage.setItem("navigatedFromSitesAboutPage", "true");
                localStorage.setItem("level0Click", "true");
                localStorage.setItem("cameraChecksiteId", localStorage.siteId);
                handleCheckCameras(
                  setCameraCheckFinsihedAlertOpen,
                  setGoodCameras,
                  setConnectivityCameras,
                  setLowResolutionCameras,
                  localStorage.siteId,
                  setCameraCheckResultDownloadURL,
                  navigate
                );
              }}
            >
              TEST CAMERAS
            </Button>
          </div>
        </ThemeProvider>
      </div>
    </ErrorBoundary>
  );

  return (
    <ModuleScreen
      text="Camera Check"
      visibleScreen={onboardingScreen}
      styling={
        "text-white absolute top-[1px] right-[450px] font-mulish text-[18px] p-3"
      }
      groupTrees={groupTrees}
      setGroupTrees={setGroupTrees}
    />
  );
};
