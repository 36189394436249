import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import React from "react";

export const ApplicationsConfiguredTable = ({
  applicationsConfiguredData,
}: {
  applicationsConfiguredData: any;
}) => {
  const titlesArray = ["Name", "Vendor", "Version", "Status"];

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          {applicationsConfiguredData.map((object: any) => {
            return (
              <TableRow key={object.id}>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.display_name ? object.display_name : "--"}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.vendor ? object.vendor : "--"}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.version ? object.version : "--"}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.status ? object.status : "--"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
