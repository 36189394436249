import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Stream } from "./siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const checkForImmixOrSureviewOrSentinel = async (
  id: number,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    checkForImmixOrSureviewOrSentinel(id, navigate);
  };
  await AxiosUI.get(`/camera/${id}/general_info/`).then(
    (response: AxiosResponse) => {
      if (response.data.streams[0]) {
        response.data.streams.forEach((obj: Stream) => {
          if (obj.sureview_alerts[0]) {
            localStorage.setItem("immixAlertsSite", "false");
            localStorage.setItem("sureviewAlertsSite", "true");
            localStorage.setItem("sentinelAlertsSite", "false");
          }
          if (obj.immix_alerts[0]) {
            localStorage.setItem("immixAlertsSite", "true");
            localStorage.setItem("sentinelAlertsSite", "false");
            localStorage.setItem("sureviewAlertsSite", "false");
            localStorage.setItem(
              "immixAlertPostObject",
              JSON.stringify({
                id: obj.immix_alerts[0].id,
                immix_server: obj.immix_alerts[0].immix_server,
                immix_port: obj.immix_alerts[0].immix_port,
              })
            );
          }
          if (obj.sentinel_alerts[0]) {
            localStorage.setItem("immixAlertsSite", "false");
            localStorage.setItem("sentinelAlertsSite", "true");
            localStorage.setItem("sureviewAlertsSite", "false");
            localStorage.setItem(
              "sentinelAlertPostObject",
              JSON.stringify({
                sentinel_server: obj.sentinel_alerts[0].sentinel_server,
                sentinel_site_id: obj.sentinel_alerts[0].sentinel_site_id,
                sentinel_device_address:
                  obj.sentinel_alerts[0].sentinel_device_address,
                draw_ignore_zones: obj.sentinel_alerts[0].draw_ignore_zones,
              })
            );
          }
          if (
            !obj.sureview_alerts[0] &&
            !obj.immix_alerts[0] &&
            !obj.sentinel_alerts[0]
          ) {
            localStorage.setItem("immixAlertsSite", "false");
            localStorage.setItem("sureviewAlertsSite", "false");
            localStorage.setItem("sentinelAlertsSite", "false");
            localStorage.setItem(
              "immixAlertPostObject",
              JSON.stringify({
                id: -1,
                immix_server: "",
                immix_port: "",
              })
            );
            localStorage.setItem(
              "sentinelAlertPostObject",
              JSON.stringify({
                sentinel_server: "",
                sentinel_site_id: "",
                sentinel_device_address: "",
                draw_ignore_zones: false,
              })
            );
          }
        });
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
