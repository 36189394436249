import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { NavigateFunction } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { theme } from "../AddSite/StepsStepper";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const AddGroupUserStepOne = ({
  newUserInfo,
  setNewUserInfo,
  parentAccounts,
  parentIds,
  parentNames,
  navigate,
}: {
  newUserInfo: any;
  setNewUserInfo: any;
  parentAccounts: any;
  parentIds: any;
  parentNames: any;
  navigate: NavigateFunction;
}) => {
  const [emailValue, setEmailValue] = React.useState(newUserInfo.email);

  const [firstNameValue, setFirstNameValue] = React.useState(
    newUserInfo.first_name
  );

  const [lastNameValue, setLastNameValue] = React.useState(
    newUserInfo.last_name
  );

  return (
    <div>
      <div className="absolute top-[160px] left-[30px]">
        <Autocomplete
          disablePortal
          value={parentNames.get(newUserInfo.group)}
          options={parentAccounts.map((object: any) => {
            return object.name;
          })}
          onChange={(event: any, newInputValue: any) => {
            setNewUserInfo((previousState: any) => {
              return {
                ...previousState,
                group: parentIds.get(newInputValue),
              };
            });
          }}
          sx={{ width: 350 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Group"
              autoComplete="off"
              id="group_name"
              name="group_name"
            />
          )}
        />
        <br></br>
        <TextField
          variant="standard"
          label="Email"
          value={emailValue}
          onChange={(event) => {
            setNewUserInfo((previousState: any) => {
              return { ...previousState, email: event.target.value };
            });
            setEmailValue(event.target.value);
          }}
          sx={{ width: "350px" }}
          helperText=""
          type="string"
        />
        <br></br>
        <br></br>
        <TextField
          variant="standard"
          label="First Name"
          value={firstNameValue}
          onChange={(event) => {
            setNewUserInfo((previousState: any) => {
              return { ...previousState, first_name: event.target.value };
            });
            setFirstNameValue(event.target.value);
          }}
          sx={{ width: "350px" }}
          helperText=""
          type="string"
        />
        <br></br>
        <br></br>{" "}
        <TextField
          variant="standard"
          label="Last Name"
          value={lastNameValue}
          onChange={(event) => {
            setNewUserInfo((previousState: any) => {
              return { ...previousState, last_name: event.target.value };
            });
            setLastNameValue(event.target.value);
          }}
          sx={{ width: "350px" }}
          helperText=""
          type="string"
        />
        <br></br>
        <br></br>{" "}
        {/* <ThemeProvider theme={theme}>
          <div>
            <p className="font-mulish text-[20px] font-bold">Access Level</p>
            <div className="pl-[10px] py-[10px]">
              <FormControl>
                <RadioGroup defaultValue={newUserInfo.access_level}>
                  <FormControlLabel
                    onClick={() => {
                      setNewUserInfo((previousState: any) => {
                        return { ...previousState, access_level: 1 };
                      });
                    }}
                    value={1}
                    control={<Radio />}
                    label="Arm and Disarm Only"
                    sx={{color:'#001943'}}
                  />
                  <FormControlLabel
                    onClick={() => {
                      setNewUserInfo((previousState: any) => {
                        return { ...previousState, access_level: 2 };
                      });
                    }}
                    value={2}
                    control={<Radio />}
                    label="Manage Existing Cameras"
                    sx={{color:'#001943'}}
                  />
                  <FormControlLabel
                    onClick={() => {
                      setNewUserInfo((previousState: any) => {
                        return { ...previousState, access_level: 3 };
                      });
                    }}
                    value={3}
                    control={<Radio />}
                    label="Self-Service (can add new cameras)"                    
                    sx={{color:'#001943'}}

                  />
                  <FormControlLabel
                    onClick={() => {
                      setNewUserInfo((previousState: any) => {
                        return { ...previousState, access_level: 4 };
                      });
                    }}
                    value={4}
                    control={<Radio />}
                    label="Self-Service with Delete Permissions"
                    sx={{color:'#001943'}}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <br></br>
          <div>
            <FormControl variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={newUserInfo.send_cognito_invitation}
                      onChange={(event, checked) => {
                        setNewUserInfo((previousState: any) => {
                          return {
                            ...previousState,
                            send_cognito_invitation: checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Send Cognito Invitation"
                  sx={{color:'#001943'}}
                />
              </FormGroup>
            </FormControl>
          </div>
          <br></br>
          <div>
            <FormControl variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={newUserInfo.create_user}
                      onChange={(event, checked) => {
                        setNewUserInfo((previousState: any) => {
                          return {
                            ...previousState,
                            create_user: checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Create User"
                  sx={{color:'#001943'}}
                />
              </FormGroup>
              <FormHelperText>
                User can create other users under their group
              </FormHelperText>
            </FormControl>
          </div>
          <div>
            <FormControl variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={newUserInfo.clips}
                      onChange={(event, checked) => {
                        setNewUserInfo((previousState: any) => {
                          return {
                            ...previousState,
                            clips: checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Clips / AI Link"
                  sx={{color:'#001943'}}
                />
              </FormGroup>
              <FormHelperText>User can view Clips / AI Link</FormHelperText>
            </FormControl>
          </div>
        </ThemeProvider> */}
      </div>
    </div>
  );
};
