import React from "react";
import { ActuateButton } from "../../common/ActuateButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { getGroupUsersData } from "./GroupUsersUtils/getGroupUsersData";

export const SearchButtonGroupsUsers = ({
  setGroupUserData,
  setFetchingGroupUserData,
  groupUsersSearchString,
  backgroundLoadingData,
  setBackgroundLoadingData,
  setAlertState,
  setPage,
  setFilteredDataExists,
  setFilteredData,
  navigate,
}: {
  setGroupUserData: any;
  setFetchingGroupUserData: Dispatcher<boolean>;
  groupUsersSearchString: string;
  backgroundLoadingData: boolean;
  setBackgroundLoadingData: Dispatcher<boolean>;
  setAlertState: any;
  setPage: any;
  setFilteredDataExists: any;
  setFilteredData: any;
  navigate: NavigateFunction;
}) => {
  return (
    <div>
      <button
        className={
          // backgroundLoadingData
          //   ? "shadow-button bg-actuate-grey border-2	border-actuate-grey rounded font-mulish text-white text-base p-1.5 w-[160px]"
          //   :
          "shadow-button bg-actuate-orange border-2	border-actuate-orange rounded font-mulish text-white text-base p-1.5 w-[120px]"
        }
        onClick={() => {
          setAlertState((previousState: any) => {
            setPage(0);
            return {
              ...previousState,
              updateUserSuccess: false,
              deleteUserSuccess: false,
              addUserSuccess: false,
              emailSent: false,
              noData: false,
            };
          });
          if (groupUsersSearchString) {
            setFilteredDataExists(true);
            setFetchingGroupUserData(true);
            setBackgroundLoadingData(true);
            getGroupUsersData(
              setFilteredData,
              [],
              setFetchingGroupUserData,
              1,
              groupUsersSearchString,
              setBackgroundLoadingData,
              setAlertState,
              navigate
            );
          } else {
            setFilteredDataExists(false);
            setFilteredData([]);
          }
        }}
        // disabled={backgroundLoadingData}
      >
        Search Users
        {/* {backgroundLoadingData ? "Loading Users.." : "Search Users"} */}
      </button>
    </div>
  );
};
