import React from "react";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { createApi } from "../../utils/createApi";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../Onboarding/OnboardingProgressDiv";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";
import { AddWebhookScreen } from "./AddWebhookScreen";
import { fetchAddCameraInfo } from "../Sites/SitesPage/SitesPageUtils/fetchAddCameraInfo";
import { handleAddImmixToAddCameraForm } from "./handleAddImmixToAddCameraForm";

export const handleSubmitWebhooks = (
  webhooksInfo: any,
  setVisibleScreen: any,
  navigate: NavigateFunction
) => {
  setVisibleScreen(
    <StatusNotificationScreen
      styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
      text={
        <p>
          Submitting Webhooks info.
          <br />
          This might take a few seconds.
        </p>
      }
    />
  );
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleSubmitWebhooks(webhooksInfo, setVisibleScreen, navigate);
  };
  AxiosUI.post("webhook_customer/", webhooksInfo).then(
    () => {
      AxiosUI.get(
        `customer/${webhooksInfo.customer}/camera_onboarding_forms/`
      ).then(
        (response: AxiosResponse) => {
          // handleAddImmixToAddCameraForm(response.data);
          localStorage.setItem("addCameraInfo", JSON.stringify(response.data));
          localStorage.setItem(
            "selectedProducts",
            JSON.stringify(response.data.initial_data.products)
          );
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setVisibleScreen(
              <StatusNotificationScreen
                styling="absolute first-line:font-black font-mulish text-[20px] right-[190px] text-center text-[#283E6D] top-80"
                text="Webhooks setup succesfull!"
              />
            );
            setTimeout(() => {
              setVisibleScreen(
                <OnboardingProgressDiv
                  percentage={
                    validIntegrationTypes.includes(
                      localStorage.selectedIntegrationType
                    )
                      ? 25
                      : 33
                  }
                  step={1}
                  setVisibleScreen={setVisibleScreen}
                />
              );
            }, 2000);
          });
        }
      );
      setVisibleScreen(
        <StatusNotificationScreen
          styling="absolute first-line:font-black font-mulish text-[20px] right-[190px] text-center text-[#283E6D] top-80"
          text="Webhooks setup succesfull!"
        />
      );
      setTimeout(() => {
        setVisibleScreen(
          <OnboardingProgressDiv
            percentage={
              validIntegrationTypes.includes(
                localStorage.selectedIntegrationType
              )
                ? 25
                : 33
            }
            step={1}
            setVisibleScreen={setVisibleScreen}
          />
        );
      }, 2000);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error submitting Webhooks info. Please try again.");
        setVisibleScreen(
          <AddWebhookScreen
            setVisibleScreen={setVisibleScreen}
            siteId={webhooksInfo.id}
          />
        );
      });
    }
  );
};
