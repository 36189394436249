import { Typography } from "@mui/material";
import React from "react";
import { RecipientsConfiguredTable } from "./RecipientsConfiguredTable";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { ActionRulesTable } from "./ActionRulesTable";
import { handleAddRecipient } from "./EditCameraUtils/handleAddRecipient";
import { handleAddAxisActionRule } from "./EditCameraUtils/handleAddAxisActionRule";
import { ApplicationsConfiguredTable } from "./ApplicationsConfiguredTable";
import CircularProgress from "@mui/material/CircularProgress";
import useAxisTabStore from "./AxisTabZusatnd/useAxisTabStore";
import { getAxisData } from "./EditCameraUtils/getAxisData";

export const AxisTab = ({
  recipientsConfiguredData,
  titlesArray,
  navigate,
  setBackdropLoadingScreenOn,
  setRecipientsConfiguredData,
  setTitlesArray,
  actionRulesData,
  setActionRulesData,
  actionRulesTitlesArray,
  setActionRulesTitlesArray,
  setActiveTab,
  applicationsConfiguredData,
  cameraGeneralInfo,
  axisFinished,
}: {
  recipientsConfiguredData: any;
  titlesArray: any;
  navigate: NavigateFunction;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setRecipientsConfiguredData: any;
  setTitlesArray: any;
  actionRulesData: any;
  setActionRulesData: Dispatcher<any>;
  actionRulesTitlesArray: any;
  setActionRulesTitlesArray: Dispatcher<any>;
  setActiveTab: any;
  applicationsConfiguredData: any;
  cameraGeneralInfo: any;
  axisFinished: any;
}) => {
  const currentCameraInfo: any = cameraGeneralInfo;

  // const {recipientsConfiguredDataZustand}: any = useAxisTabStore()

  const [actionRulesTable, setActionRulesTable] = React.useState(
    <div className="pl-[20px] flex">
      <span className="mr-2">
        <CircularProgress color="primary" size={30} />
      </span>
      <span className="pl-[10px] pt-[5px]">Loading Data...</span>
    </div>
  );

  // const { fetchRecipientsConfiguredData }: any = useAxisTabStore();

  React.useEffect(() => {
    if (axisFinished.actionRules) {
      // fetchRecipientsConfiguredData()
      setActionRulesTable(
        <div>
          {" "}
          {axisFinished.recipient &&
            axisFinished.actionRules &&
            axisFinished.application && (
              <div className="absolute top-[15px] left-[150px]">
                <OrangeTextButton
                  onClick={() => {
                    setBackdropLoadingScreenOn(true);
                    handleAddAxisActionRule(
                      currentCameraInfo.id,
                      navigate,
                      setBackdropLoadingScreenOn,
                      setActionRulesData,
                      setActionRulesTitlesArray,
                      setRecipientsConfiguredData,
                      setTitlesArray,
                      setActiveTab,
                      recipientsConfiguredData,
                      titlesArray,
                      actionRulesData,
                      actionRulesTitlesArray,
                      applicationsConfiguredData,
                      cameraGeneralInfo,
                      axisFinished
                    );
                  }}
                  text="+ Add Action Rule"
                  disabled={false}
                />
              </div>
            )}
          <ActionRulesTable
            actionRulesData={actionRulesData}
            setActionRulesData={setActionRulesData}
            setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
            actionRulesTitlesArray={actionRulesTitlesArray}
            setActionRulesTitlesArray={setActionRulesTitlesArray}
            navigate={navigate}
            setRecipientsConfiguredData={setRecipientsConfiguredData}
            setTitlesArray={setTitlesArray}
            setActiveTab={setActiveTab}
            recipientsConfiguredData={recipientsConfiguredData}
            titlesArray={titlesArray}
            applicationsConfiguredData={applicationsConfiguredData}
            cameraGeneralInfo={cameraGeneralInfo}
            axisFinished={axisFinished}
          />
        </div>
      );
    }
  }, [axisFinished]);

  return (
    <div className="relative min-w-[1150px] left-[0px] bg-white">
      <div key="recipients_configured">
        <div>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            fontWeight="bold"
            sx={{
              fontFamily: "Mulish",
              color: "#001943",
              paddingTop: 2,
              paddingLeft: 2,
              paddingBottom: 1,
            }}
          >
            Recipients Configured
          </Typography>
          {axisFinished.recipient &&
            axisFinished.actionRules &&
            axisFinished.application && (
              <div className="absolute top-[15px] left-[250px]">
                <OrangeTextButton
                  onClick={() => {
                    setBackdropLoadingScreenOn(true);
                    handleAddRecipient(
                      currentCameraInfo.id,
                      navigate,
                      setBackdropLoadingScreenOn,
                      setRecipientsConfiguredData,
                      setTitlesArray,
                      setActiveTab,
                      recipientsConfiguredData,
                      titlesArray,
                      actionRulesData,
                      setActionRulesData,
                      actionRulesTitlesArray,
                      setActionRulesTitlesArray,
                      applicationsConfiguredData,
                      cameraGeneralInfo,
                      axisFinished
                    );
                  }}
                  text="+ Add Recipient"
                  disabled={false}
                />
              </div>
            )}

          <RecipientsConfiguredTable
            recipientsConfiguredData={recipientsConfiguredData}
            titlesArray={titlesArray}
            navigate={navigate}
            setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
            setRecipientsConfiguredData={setRecipientsConfiguredData}
            setTitlesArray={setTitlesArray}
            setActiveTab={setActiveTab}
            actionRulesData={actionRulesData}
            setActionRulesData={setActionRulesData}
            actionRulesTitlesArray={actionRulesTitlesArray}
            setActionRulesTitlesArray={setActionRulesTitlesArray}
            applicationsConfiguredData={applicationsConfiguredData}
            cameraGeneralInfo={cameraGeneralInfo}
            axisFinished={axisFinished}
          />
        </div>
      </div>
      <div key="action_rules" className="relative min-w-[1150px]">
        <div>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            fontWeight="bold"
            sx={{
              fontFamily: "Mulish",
              color: "#001943",
              paddingTop: 2,
              paddingLeft: 2,
            }}
          >
            Action Rules
          </Typography>
          {actionRulesTable}
        </div>
      </div>
      <div key="applications_configured">
        <div>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            fontWeight="bold"
            sx={{
              fontFamily: "Mulish",
              color: "#001943",
              paddingTop: 2,
              paddingLeft: 2,
            }}
          >
            Applications Configured
          </Typography>
          {axisFinished.application ? (
            <div>
              <ApplicationsConfiguredTable
                applicationsConfiguredData={applicationsConfiguredData}
              />
              {/* <>{recipientsConfiguredDataZustand[0].host}</> */}
            </div>
          ) : (
            <div className="pl-[20px] flex">
              <span className="mr-2">
                <CircularProgress color="primary" size={30} />
              </span>
              <span className="pl-[10px] pt-[5px]">Loading Data...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
