import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getFalsePositiveData = async (
  setFalsePostiveData: any,
  navigate: any,
  setNoFPData: any
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    getFalsePositiveData(setFalsePostiveData, navigate, setNoFPData);
  };
  await AxiosUI.get(`analytics/false_positive/`).then(
    (response: AxiosResponse) => {
      localStorage.setItem("falsePostiveData", JSON.stringify(response.data));
      setFalsePostiveData(response.data);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setNoFPData(true);
      });
    }
  );
};
