import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getActiveIgnoreZones = (
  cameraId: string,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    getActiveIgnoreZones(
      cameraId,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      navigate
    );
  };

  const AxiosUI = createApi(``);

  let getCameraStatusFail = false;
  AxiosUI.get(`/camera/${cameraId}/status/`).then(
    (response: AxiosResponse) => {
      getCameraStatusFail = false;
      setSelectedAlertCameraInfo(response.data);
    },
    (reason: AxiosError) => {
      getCameraStatusFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setSelectedAlertCameraInfo({});
      });
    }
  );
  !getCameraStatusFail &&
    AxiosUI.get(`/camera/${cameraId}/ignore_zones/`).then(
      (response: AxiosResponse) => {
        setIgnoreZonesSelectedAlert(response.data);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setIgnoreZonesSelectedAlert([]);
        });
      }
    );
};
