import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleAddNewCalendar = (
  newCalendarInfo: any,
  setFetchingCalendarData: Dispatcher<boolean>,
  calendarData: any,
  setCalendarData: any,
  setCalendarAlerts: any,
  calendarsSearchString: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleAddNewCalendar(
      newCalendarInfo,
      setFetchingCalendarData,
      calendarData,
      setCalendarData,
      setCalendarAlerts,
      calendarsSearchString,
      navigate
    );
  };

  AxiosUI.post(`calendar/`, newCalendarInfo).then(
    (response: AxiosResponse) => {
      if (!calendarsSearchString) {
        setCalendarData([...calendarData, response.data]);
      }
      localStorage.setItem("newCalendarName", newCalendarInfo.name);
      setCalendarAlerts((previousState: any) => {
        return {
          ...previousState,
          addCalendarSuccess: true,
          deleteCalendarSuccess: false,
          setCalendarAlerts: false,
        };
      });
      setFetchingCalendarData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error adding calendar, please try again");
        setFetchingCalendarData(false);
      });
    }
  );
};
