import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../../common/TableHead";
import { TextField } from "@mui/material";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";

export const RecordingServerTabEditMode = ({
  recordingServerData,
  recordingServerPatchObject,
  setRecordingServerPatchObject,
  setRecordingServerChangesMade,
}: {
  recordingServerData: any;
  recordingServerPatchObject: any;
  setRecordingServerPatchObject: any;
  setRecordingServerChangesMade: Dispatcher<boolean>;
}) => {
  const titlesArray = ["Name", "Server IP", "Camera Count"];

  return (
    <TableContainer component={Paper} sx={{ width: "screen" }}>
      <Table
        sx={{
          postion: "absolute",
          width: "screen",
          fontFamily: "Mulish",
        }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          {recordingServerPatchObject.map((row: any, index: any) => {
            return (
              <TableRow key={row.id}>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  <TextField
                    id={row.name}
                    defaultValue={row.name}
                    variant="standard"
                    sx={{ width: 200 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newRecordingServerPatchObject =
                        recordingServerPatchObject;
                      newRecordingServerPatchObject[index].name =
                        event.target.value;
                      setRecordingServerPatchObject(
                        newRecordingServerPatchObject
                      );
                      setRecordingServerChangesMade(true);
                    }}
                  />
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  <TextField
                    id={row.server_ip}
                    defaultValue={row.server_ip}
                    variant="standard"
                    sx={{ width: 200 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newRecordingServerPatchObject =
                        recordingServerPatchObject;
                      newRecordingServerPatchObject[index].server_ip =
                        event.target.value;
                      setRecordingServerPatchObject(
                        newRecordingServerPatchObject
                      );
                      setRecordingServerChangesMade(true);
                    }}
                  />
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {row.cameras}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
