import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleUpdateAssignedSites = (
  selectedRow: any,
  sitesArray: number[],
  setUpdatingSites: Dispatcher<boolean>,
  setAssignedSitesDialogOpen: Dispatcher<boolean>,
  setCalendarAlerts: Dispatcher<any>,
  setCalendarData: any,
  calendarData: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleUpdateAssignedSites(
      selectedRow,
      sitesArray,
      setUpdatingSites,
      setAssignedSitesDialogOpen,
      setCalendarAlerts,
      setCalendarData,
      calendarData,
      navigate
    );
  };

  AxiosUI.post(`calendar/${selectedRow.id}/apply/`, {
    customer: sitesArray,
  }).then(
    () => {
      localStorage.setItem("newCalendarName", selectedRow.name);
      const updatedCalendarData = calendarData.filter(
        (obj: any) => obj.id !== selectedRow.id
      );
      const updatedRow = selectedRow;
      updatedRow.customer = sitesArray;
      updatedCalendarData.push(updatedRow);
      setCalendarData(updatedCalendarData);
      setCalendarAlerts((previousState: any) => {
        return {
          ...previousState,
          deleteCalendarSuccess: false,
          addCalendarSuccess: false,
          addSitesToCalendarSuccess: true,
        };
      });
      setUpdatingSites(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error updating calendar sites, please try again");
        setUpdatingSites(false);
      });
    }
  );
};
