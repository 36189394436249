/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  SiteSchedule,
  SiteInfo,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { ScheduleGrid } from "./ScheduleGrid";
import { EditScheduleTimesDialog } from "./EditScheduleTimesDialog";
import { handleCreateInitialTimeSchedules } from "./SiteAboutPageUtils/handleCreateInitialTimeSchedules";
import { handleConvertSchedulesToBooleanArrays } from "./SiteAboutPageUtils/handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "./SiteAboutPageUtils/handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";
import { useNavigate } from "react-router-dom";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const SchedulesTabEditMode = ({
  allSiteSchedules,
  siteInfo,
  setAllSiteSchedules,
  setScheduleChangesMade,
  setDeleteScheduleDialogOpen,
  setBackdropLoadingScreenOn,
  setActiveTab,
  setEditMode,
  setSiteSchedules,
  setSiteInfo,
}: {
  allSiteSchedules: SiteSchedule[];
  siteInfo: SiteInfo;
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>;
  setScheduleChangesMade: Dispatcher<boolean>;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setActiveTab: Dispatcher<JSX.Element>;
  setEditMode: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<SiteSchedule[]>;
  setSiteInfo: Dispatcher<SiteInfo>;
}) => {
  const navigate = useNavigate();

  const [editTimeScheduleDialogOpen, setEditTimeScheduleDialogOpen] =
    React.useState(false);

  const [selectedDayInfo, setSelectedDayInfo] = React.useState({});

  const [timeRanges, setTimeRanges] = React.useState(
    handleCreateInitialTimeSchedules(allSiteSchedules)
  );
  const [grid, setGrid] = React.useState(
    handleConvertSchedulesToBooleanArrays(allSiteSchedules)
  );

  const [duskToDawn, setDuskToDawn] = React.useState(
    handleCreateDuskToDawn(allSiteSchedules)
  );

  const [duskToDawnLocation, setDuskToDawnLocation] = React.useState(
    handleCreateDuskToDawnLocationPlusBuffer(allSiteSchedules)
  );

  const [matchingSchedules, setMatchingSchedules] = React.useState<number[][]>(
    []
  );

  return (
    <div>
      {editTimeScheduleDialogOpen && (
        <EditScheduleTimesDialog
          allSiteSchedules={allSiteSchedules}
          siteInfo={siteInfo}
          setAllSiteSchedules={setAllSiteSchedules}
          editTimeScheduleDialogOpen={editTimeScheduleDialogOpen}
          setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
          matchingSchedules={matchingSchedules}
          selectedDayInfo={selectedDayInfo}
          timeRanges={timeRanges}
          duskToDawnLocation={duskToDawnLocation}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setActiveTab={setActiveTab}
          setEditMode={setEditMode}
          setSiteSchedules={setSiteSchedules}
          setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          setSiteInfo={setSiteInfo}
          setTimeRanges={setTimeRanges}
          setGrid={setGrid}
          setMatchingSchedules={setMatchingSchedules}
          navigate={navigate}
        />
      )}
      <div className="relative top-[0px] h-[490px] bg-white overflow-scroll">
        <div className="flex">
          <p className="font-black font-mulish text-[20px] p-3 indent-4">
            Schedule Overview &nbsp;
            <Tooltip
              title={`Times shows are in ${siteInfo.timezone} time. Scheduled arming time ranges are highlighted in green.`}
              placement="top"
              sx={{ fontSize: "15px", fontFamily: "mulish" }}
            >
              <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
            </Tooltip>
          </p>
        </div>
        <div className="relative top-[0px] left-[15px] w-[1500px]">
          <ScheduleGrid
            editable={true}
            setSelectedDayInfo={setSelectedDayInfo}
            setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
            timeRanges={timeRanges}
            setTimeRanges={setTimeRanges}
            grid={grid}
            setGrid={setGrid}
            duskToDawn={duskToDawn}
            setDuskToDawn={setDuskToDawn}
            duskToDawnLocation={duskToDawnLocation}
            setDuskToDawnLocation={setDuskToDawnLocation}
            setMatchingSchedules={setMatchingSchedules}
            setScheduleChangesMade={setScheduleChangesMade}
            setAllSiteSchedules={setAllSiteSchedules}
          />
        </div>
      </div>
    </div>
  );
};
