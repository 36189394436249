import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import { SiteInfo } from "./SiteAboutPageUtils/siteAboutInterfaces";

export const VmsTab = ({
  vmsVariables,
  siteInfo,
}: {
  vmsVariables: any;
  siteInfo: SiteInfo;
}) => {
  const noVMS =
    siteInfo.integration_type_name === "rtsp" &&
    siteInfo.onboarding_integration === 1;

  return noVMS ? (
    <TableContainer component={Paper}>
      <div className="pt-[20px] pb-[20px] pl-[20px]">
        <p className="font-mulish">
          {" "}
          {`To view connection details for specific cameras, find the camera below, then > Show More > Edit Camera > Camera Information > Connection.`}
        </p>
      </div>
    </TableContainer>
  ) : (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {vmsVariables.map(
              (array: string[], index: number) =>
                index < 6 && (
                  <TableCell
                    key={array[0]}
                    align="left"
                    sx={{
                      width: 160,
                      fontFamily: "Mulish",
                      fontWeight: "Bold",
                    }}
                  >
                    {array[0]}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={siteInfo.id}>
            {vmsVariables.map((array: string[], index: number) => {
              if (array[3] === "checkbox" && index < 6) {
                return (
                  <TableCell
                    key={array[1]}
                    align="left"
                    sx={{ fontFamily: "Mulish" }}
                  >
                    <Switch
                      disabled
                      checked={array[1] === "true"}
                      color="warning"
                    ></Switch>
                  </TableCell>
                );
              }
              if (array[3] !== "checkbox" && index < 6) {
                return (
                  <TableCell
                    key={array[1]}
                    align="left"
                    sx={{ fontFamily: "Mulish", width: 160 }}
                  >
                    {siteInfo[array[2] as keyof typeof siteInfo]}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableBody>
      </Table>

      {vmsVariables.length > 5 && (
        <Table
          sx={{ minWidth: 1000, fontFamily: "Mulish" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {vmsVariables.map(
                (array: string[], index: number) =>
                  index > 5 &&
                  index < 12 && (
                    <TableCell
                      key={array[0]}
                      align="left"
                      sx={{
                        width: 160,
                        fontFamily: "Mulish",
                        fontWeight: "Bold",
                      }}
                    >
                      {array[0]}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={siteInfo.id}>
              {vmsVariables.map((array: string[], index: number) => {
                if (array[3] === "checkbox" && index > 5 && index < 12) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      <Switch
                        disabled
                        checked={array[1] === "true"}
                        color="warning"
                      ></Switch>
                    </TableCell>
                  );
                }
                if (array[3] !== "checkbox" && index > 5 && index < 12) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      {siteInfo[array[2] as keyof typeof siteInfo]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}
      {vmsVariables.length > 12 && (
        <Table
          sx={{ minWidth: 1000, fontFamily: "Mulish" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {vmsVariables.map(
                (array: string[], index: number) =>
                  index > 11 &&
                  index < 18 && (
                    <TableCell
                      key={array[0]}
                      align="left"
                      sx={{
                        width: 160,
                        fontFamily: "Mulish",
                        fontWeight: "Bold",
                      }}
                    >
                      {array[0]}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={siteInfo.id}>
              {vmsVariables.map((array: string[], index: number) => {
                if (array[3] === "checkbox" && index > 11 && index < 18) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      <Switch
                        disabled
                        checked={array[1] === "true"}
                        color="warning"
                      ></Switch>
                    </TableCell>
                  );
                }
                if (array[3] !== "checkbox" && index > 11 && index < 18) {
                  return (
                    <TableCell
                      key={array[1]}
                      align="left"
                      sx={{ fontFamily: "Mulish", width: 160 }}
                    >
                      {siteInfo[array[2] as keyof typeof siteInfo]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
