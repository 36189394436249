import React from "react";

export const checkFormsForImmixValues = (form: any) => {
  const immixAlertPostObject = JSON.parse(localStorage.immixAlertPostObject);

  const updatedForm = form;

  const formLength = form.length;

  const lastPage = updatedForm[formLength - 1];

  if (immixAlertPostObject.immix_server) {
    lastPage[lastPage.length - 4].defaultValue =
      immixAlertPostObject.immix_server;
  }

  if (immixAlertPostObject.immix_port) {
    lastPage[lastPage.length - 3].defaultValue =
      immixAlertPostObject.immix_port;
  }

  updatedForm[formLength - 1] = lastPage;

  return updatedForm;
};
