import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../../common/TableHead";
import { integrationsMatcher } from "../../../utils/integrationsMatcher";
import { SiteInfo } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { Switch } from "@mui/material";

export const GeneralInfoTab = ({ siteInfo }: { siteInfo: SiteInfo }) => {
  const alarmOptions = JSON.parse(localStorage.alarmOptions);

  const alarmIds = new Map();
  alarmOptions.forEach((object: any) => {
    alarmIds.set(object.value, object.name);
  });

  const titlesArray = [
    "Site Name",
    "Timezone",
    "Autoadd",
    "Integration Type",
    "Alarm Type",
    "Parent Group",
  ];

  if (siteInfo.show_ecs_nat_instance) {
    titlesArray.push("Nat Instance");
  }

  return (
    <TableContainer component={Paper} sx={{ width: "screen" }}>
      <Table
        sx={{
          postion: "absolute",
          width: "screen",
          fontFamily: "Mulish",
        }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          <TableRow key={siteInfo.id}>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.name}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.timezone}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.auto_add ? siteInfo.auto_add : "--"}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {integrationsMatcher("Name", siteInfo.integration_type)}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {alarmIds.get(siteInfo.default_alarm)}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.parent_group.name}
            </TableCell>
            {siteInfo.show_ecs_nat_instance && (
              <TableCell>
                {siteInfo.ecs_nat_instance ? (
                  <Switch defaultChecked disabled={true} color="warning" />
                ) : (
                  <Switch checked={false} disabled={true} color="warning" />
                )}
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
