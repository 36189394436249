/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import { ClipVerificationBox } from "./ClipVerificationBox";
import { ShowMoreDialog } from "./ShowMoreDialog";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { formatTimeStamp } from "./InvestigationUtilities/formatTimeStamp";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { InvestigationTable } from "./InvestigationTable";
import { PrecisionAndRecallTable } from "./PrecisionAndRecallTable";
import { NavigateFunction } from "react-router-dom";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { handleArrowButtonClick } from "./InvestigationUtilities/handleArrowButtonClick";
import { LoadingSpinner } from "../../common/LoadingSpinner";
import { saveAs } from "file-saver";
import ErrorBoundary from "../../../ErrorBoundary";
import { createSiteHyperlink } from "../Alerts/AlertsUtils/createSiteHyperlink";
import { createCameraHyperlink } from "../Alerts/AlertsUtils/createCameraHyperlink";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const AILinkTab = ({
  showMoreDialogOpen,
  setShowMoreDialogOpen,
  selectedRow,
  videoURL,
  investigationData,
  page,
  setPage,
  precisionRecallData,
  videoClipLoading,
  setVideoURL,
  setVideoClipLoading,
  setSelectedRow,
  setInvestigationData,
  scrollToTop,
  verifiedClicked,
  unverifiedClicked,
  setVerifiedClicked,
  setUnverifiedClicked,
  setPageLoading,
  clipsIndexOrder,
  setClipsIndexOrder,
  fetchingFilteredData,
  userFilters,
  loadingAdditionalData,
  navBarCollapsed,
  userInfo,
  navigate,
}: {
  showMoreDialogOpen: boolean;
  setShowMoreDialogOpen: Dispatcher<boolean>;
  selectedRow: any;
  videoURL: string;
  investigationData: any;
  page: any;
  setPage: any;
  precisionRecallData: any;
  videoClipLoading: boolean;
  setVideoURL: Dispatcher<string>;
  setVideoClipLoading: Dispatcher<boolean>;
  setSelectedRow: any;
  setInvestigationData: any;
  scrollToTop: any;
  verifiedClicked: boolean;
  unverifiedClicked: boolean;
  setVerifiedClicked: Dispatcher<boolean>;
  setUnverifiedClicked: Dispatcher<boolean>;
  setPageLoading: Dispatcher<boolean>;
  clipsIndexOrder: any;
  setClipsIndexOrder: any;
  fetchingFilteredData: boolean;
  userFilters: any;
  loadingAdditionalData: boolean;
  navBarCollapsed: boolean;
  userInfo: any;
  navigate: NavigateFunction;
}) => {
  return (
    <div
      className={
        navBarCollapsed
          ? userInfo.permission.sentinel && userInfo.permission.ailink
            ? "relative left-[75px] top-[150px] w-[600px]"
            : "relative left-[75px] top-[90px] w-[600px]"
          : userInfo.permission.sentinel && userInfo.permission.ailink
          ? "relative left-[150px] top-[150px] w-[600px]"
          : "relative left-[150px] top-[90px] w-[600px]"
      }
    >
      <p className="relative left-[20px] top-[15px] text-actuate-blue font-mulish text-[20px]">
        {selectedRow.display_name
          ? createSiteHyperlink(
              selectedRow.display_name,
              selectedRow.customer_pk
            )
          : createSiteHyperlink(
              selectedRow.customer_name,
              selectedRow.customer_pk
            )}{" "}
        |{" "}
        {createCameraHyperlink(
          selectedRow.camera_name,
          selectedRow.customer_pk,
          selectedRow.camera_pk
        )}{" "}
      </p>
      {!videoClipLoading && (
        <div className="absolute left-[1150px] top-[0px] w-[150px] pt-[10px]">
          <ShowMoreDialog
            showMoreDialogOpen={showMoreDialogOpen}
            setShowMoreDialogOpen={setShowMoreDialogOpen}
            selectedRow={selectedRow}
            videoURL={videoURL}
          />
        </div>
      )}
      <div className="flex pt-[20px] pl-[10px]">
        <div>
          {!fetchingFilteredData && (
            <div>
              <div>
                <div className={!navBarCollapsed ? "w-[655px]" : "w-[725px]"}>
                  <InvestigationTable
                    investigationData={investigationData}
                    setInvestigationData={setInvestigationData}
                    setSelectedRow={setSelectedRow}
                    page={page}
                    setPage={setPage}
                    setVideoClipLoading={setVideoClipLoading}
                    setVideoURL={setVideoURL}
                    scrollToTop={scrollToTop}
                    setVerifiedClicked={setVerifiedClicked}
                    setUnverifiedClicked={setUnverifiedClicked}
                    selectedRow={selectedRow}
                    setClipsIndexOrder={setClipsIndexOrder}
                    userFilters={userFilters}
                    navigate={navigate}
                  />
                </div>
                {loadingAdditionalData && (
                  <div className="relative pt-[5px]">
                    <div className="relative left-[10px]">
                      <LoadingSpinner />
                    </div>
                    <p className="absolute left-[58px] top-[7px] font-mulish text-actuate-orange">
                      Loading Addtional Data
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="w-[660px]">
            <div className="w-[660px]">
              <div>
                {videoClipLoading && (
                  <div className="text-center">
                    <ThemeProvider theme={theme}>
                      <div>
                        <CircularProgress color="primary" />
                      </div>
                      <br></br>
                      <p className="font-mulish text-[20px]">
                        Video Loading...
                      </p>
                    </ThemeProvider>
                  </div>
                )}
                {!videoClipLoading && (
                  <div className="flex items-center justify-between w-[660px]">
                    <div className="flex-none" id="arrow_back">
                      <OrangeTextButton
                        onClick={() => {
                          handleArrowButtonClick(
                            investigationData,
                            selectedRow,
                            clipsIndexOrder,
                            "back",
                            setSelectedRow,
                            setInvestigationData,
                            setVideoClipLoading,
                            setVerifiedClicked,
                            setUnverifiedClicked,
                            setVideoURL,
                            userFilters,
                            navigate
                          );
                        }}
                        text={
                          <ArrowBackIosIcon fontSize="large" id="BackButton" />
                        }
                        disabled={selectedRow.index === clipsIndexOrder[0]}
                      />
                    </div>
                    <div className="flex-auto" id="video">
                      <React.Fragment>
                        <video width="540" autoPlay controls loop>
                          <source src={videoURL} type="video/mp4" />
                        </video>
                      </React.Fragment>
                      <div>
                        <OrangeTextButton
                          onClick={() => saveAs(videoURL, "video.mp4")}
                          text="Download Video"
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="flex-none" id="arrow_forward">
                      <OrangeTextButton
                        onClick={() => {
                          handleArrowButtonClick(
                            investigationData,
                            selectedRow,
                            clipsIndexOrder,
                            "forward",
                            setSelectedRow,
                            setInvestigationData,
                            setVideoClipLoading,
                            setVerifiedClicked,
                            setUnverifiedClicked,
                            setVideoURL,
                            userFilters,
                            navigate
                          );
                        }}
                        text={
                          <ArrowForwardIosIcon
                            fontSize="large"
                            id="forwardButton"
                          />
                        }
                        disabled={
                          selectedRow.index ===
                          clipsIndexOrder[clipsIndexOrder.length - 1]
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="pt-[10px] pl-[30px]">
                {!videoClipLoading && (
                  <ErrorBoundary>
                    <ClipVerificationBox
                      verifiedClicked={verifiedClicked}
                      unverifiedClicked={unverifiedClicked}
                      setVerifiedClicked={setVerifiedClicked}
                      setUnverifiedClicked={setUnverifiedClicked}
                      selectedRow={selectedRow}
                      investigationData={investigationData}
                      setInvestigationData={setInvestigationData}
                      setSelectedRow={setSelectedRow}
                      setPageLoading={setPageLoading}
                      navBarCollapsed={navBarCollapsed}
                      navigate={navigate}
                    />
                  </ErrorBoundary>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div
        className={
          !navBarCollapsed
            ? "bg-white h-[55px] w-[1305px]"
            : "bg-white h-[55px] w-[1380px]"
        }
      >
        <p className="font-black font-mulish text-[20px] p-3 indent-4">
          Precision and Recall
        </p>
        <PrecisionAndRecallTable precisionRecallData={precisionRecallData} />
      </div>
    </div>
  );
};
