export const locationsMatcher = (type: string, input: string) => {
  const locations = JSON.parse(localStorage.locations);
  const locationIds = new Map();
  const locationNames = new Map();

  locations.forEach((element: any) => {
    locationNames.set(`${element.name}, ${element.region}`, element.id);
    locationIds.set(element.id, `${element.name}, ${element.region}`);
  });
  if (type === "Id") {
    return locationNames.get(input);
  } else if (type === "Name") {
    return locationIds.get(input);
  }
};
