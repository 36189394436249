import React from "react";
import { SiteOptions } from "../../Analytics/AnalyticsUtils/analyticsInterfaces";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getAllSitesInvestigations = (
  setSiteOptions: Dispatcher<SiteOptions[]>,
  setLoadingSites: Dispatcher<boolean>,
  groupID: any,
  navigate: NavigateFunction
) => {
  setLoadingSites(true);
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getAllSitesInvestigations(
      setSiteOptions,
      setLoadingSites,
      groupID,
      navigate
    );
  };
  AxiosUI.get(`customer/names/${groupID}/`).then(
    (response: AxiosResponse) => {
      setSiteOptions(response.data);
      setLoadingSites(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setLoadingSites(false);
      });
    }
  );
};
