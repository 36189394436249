export const handleRedirect = () => {
  localStorage.setItem("parentGroupId", "false");
  const redirectUrl = `${document.location.origin}/callback/`;
  window.location.href = `https://actuate-settings.auth.us-west-2.amazoncognito.com/login?client_id=2qbaet284cs7p5oq9ppg4llj1n&response_type=code&scope=email+openid+phone+profile+aws.cognito.signin.user.admin&redirect_uri=${redirectUrl}`;
  localStorage.setItem("redirectURL", window.location.href);
};

export const Login = () => {
  return (
    <div className="flex items-center justify-center font-black">
      <div className="absolute top-8 font-mulish text-xl">
        For security reasons you have been logged out.
      </div>
      <div className="absolute font-mulish top-20 text-xl">
        Please click below to log back in.
      </div>
      <button
        className="shadow-button bg-actuate-orange rounded font-mulish text-white text-base	p-1.5 absolute top-36"
        onClick={handleRedirect}
      >
        Login
      </button>
    </div>
  );
};
