import * as React from "react";
import { ModuleScreen } from "../../common/ModuleScreen";
import { CameraAutoAddScreen } from "./CameraAutoAddScreen";
import { useNavigate } from "react-router-dom";
import { handleNavigateToSitesPage } from "../Onboarding/handleNavigateToSitesPage";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { Tooltip } from "@mui/material";

export const CameraAutoAdd = ({
  groupTrees,
  setGroupTrees,
  setCameraCheckFinsihedAlertOpen,
  setGoodCameras,
  setConnectivityCameras,
  setLowResolutionCameras,
  setCameraCheckResultDownloadURL,
  ...props
}: {
  groupTrees: any;
  setGroupTrees: Dispatcher<any>;
  props: SvgIconProps;
  setCameraCheckFinsihedAlertOpen: any;
  setGoodCameras: any;
  setConnectivityCameras: any;
  setLowResolutionCameras: any;
  setCameraCheckResultDownloadURL: any;
}) => {
  const [visibleScreen, setVisibleScreen] = React.useState(<></>);

  const [largerScreen, setLargerScreen] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (localStorage.noAutoAddCamerasDialogOpen === "true") {
      handleNavigateToSitesPage(navigate);
    } else {
      setVisibleScreen(
        <CameraAutoAddScreen
          setVisibleScreen={setVisibleScreen}
          setLargerScreen={setLargerScreen}
          largerScreen={largerScreen}
          setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
          setGoodCameras={setGoodCameras}
          setConnectivityCameras={setConnectivityCameras}
          setLowResolutionCameras={setLowResolutionCameras}
          setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
        />
      );
    }
  }, []);

  return (
    <div>
      <div>
        <div className="bg-black fixed w-full h-full opacity-90">
          <div className="bg-black fixed w-full h-full opacity-10 pointer-events-none">
            {/* {localStorage.navigatedFromSitesAboutPage === "true" ? (
            <SiteAbout />
          ) : (
            <Sites groupTrees={groupTrees} setGroupTrees={setGroupTrees} />
          )} */}
          </div>
          <div className="flex justify-end text-left">
            <div
              className={
                largerScreen
                  ? "absolute top-[50px] h-full bg-white w-full"
                  : "absolute top-[50px] h-full bg-white w-[600px]"
              }
            ></div>
          </div>
        </div>
        <div className="flex justify-end text-left">
          <div
            className={
              largerScreen
                ? "absolute h-full bg-white w-full"
                : "absolute h-full bg-white w-[600px]"
            }
          >
            <div
              className={
                largerScreen
                  ? "bg-actuate-blue absolute w-full h-[50px]"
                  : "bg-actuate-blue absolute w-[600px] h-[50px]"
              }
            >
              <button
                className="absolute right-6 top-[10px] text-white text-[18px]"
                onClick={() => {
                  localStorage.setItem("cameraNumber", "1");
                  if (localStorage.navigatedFromSitesAboutPage === "true") {
                    navigate(-1);
                  } else {
                    navigate("/sites");
                  }
                }}
              >
                X
              </button>
              <div
                className={
                  largerScreen
                    ? "text-white absolute top-[1px] left-[0px] w-[220px] font-mulish text-[18px] p-3"
                    : "text-white absolute top-[1px] left-[0px]  w-[220px] font-mulish text-[18px] p-3"
                }
              >
                <button
                  onClick={() => {
                    setLargerScreen(!largerScreen);
                    setVisibleScreen(
                      <CameraAutoAddScreen
                        setVisibleScreen={setVisibleScreen}
                        setLargerScreen={setLargerScreen}
                        largerScreen={!largerScreen}
                        setCameraCheckFinsihedAlertOpen={
                          setCameraCheckFinsihedAlertOpen
                        }
                        setGoodCameras={setGoodCameras}
                        setConnectivityCameras={setConnectivityCameras}
                        setLowResolutionCameras={setLowResolutionCameras}
                        setCameraCheckResultDownloadURL={
                          setCameraCheckResultDownloadURL
                        }
                      />
                    );
                  }}
                >
                  {largerScreen ? (
                    <Tooltip title="Collapse" placement="right">
                      <SvgIcon {...props}>
                        <path
                          fill="currentColor"
                          d="M4 6h2v12H4zm4 7h8.586l-4.293 4.293 1.414 1.414L20.414 12l-6.707-6.707-1.414 1.414L16.586 11H8z"
                        />
                      </SvgIcon>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Expand" placement="right">
                      <SvgIcon {...props}>
                        <path
                          fill="currentColor"
                          d="M18 6h2v12h-2zm-2 5H7.414l4.293-4.293-1.414-1.414L3.586 12l6.707 6.707 1.414-1.414L7.414 13H16z"
                        />
                      </SvgIcon>
                    </Tooltip>
                  )}
                </button>
                &nbsp; Auto Add Camera
              </div>
            </div>
            <div>{visibleScreen}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
