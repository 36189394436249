import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getCamerasBySiteAlerts } from "../Alerts/AlertsUtils/getCamerasBySiteAlerts";

export const SiteDropDownHM = ({
  userFilters,
  setUserFilters,
  siteOptions,
  setCameraOptions,
  setCameraDropDownValue,
  siteDropDownValue,
  setSiteDropDownValue,
  loadingSites,
  setLoadingCameras,
  navBarCollapsed,
  navigate,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  siteOptions: any;
  setCameraOptions: Dispatcher<any>;
  setCameraDropDownValue: Dispatcher<string>;
  siteDropDownValue: string;
  setSiteDropDownValue: Dispatcher<string>;
  loadingSites: boolean;
  setLoadingCameras: Dispatcher<boolean>;
  navBarCollapsed: boolean;
  navigate: NavigateFunction;
}) => {
  const siteIds = new Map();
  const siteNames = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });
  siteOptions.forEach((object: any) => {
    siteNames.set(object.id, object.name);
  });

  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const parentIds = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });

  return (
    <div>
      <Autocomplete
        value={siteOptions[0] ? siteNames.get(Number(userFilters.site)) : ""}
        disablePortal
        options={
          siteOptions[0]
            ? siteOptions.map((object: any) => {
                return object.name;
              })
            : []
        }
        loading={loadingSites}
        onChange={(event, value: any) => {
          if (value !== null) {
            setSiteDropDownValue(value);
            setCameraDropDownValue("");
            getCamerasBySiteAlerts(
              [siteIds.get(value)],
              navigate,
              setCameraOptions,
              setLoadingCameras
            );
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                site: siteIds.get(value),
                camera: "",
              };
            });
          } else {
            setSiteDropDownValue(value);
            setCameraDropDownValue("");
            setCameraOptions([]);
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                site: siteIds.get(value),
                camera: "",
              };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Site"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingSites ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
            sx={!navBarCollapsed ? { width: 175 } : { width: 200 }}
          />
        )}
      />
    </div>
  );
};
