import React from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderIcon from "@mui/icons-material/Folder";
import { CameraIcon } from "../../../common/CameraIcon";
import { handleUnnestedLevelClick } from "./SitesPageUtils/handleUnnestedLevelClick";

export const FilteredSitesGroupList = ({
  editedparentsGroupsList,
  setPage,
  setCurrentTitle,
  setRenderedRows,
  setPageLoading,
  navigate,
  setSiteBreadcrumbs,
  siteBreadcrumbs,
  setSelected,
}: {
  editedparentsGroupsList: any;
  setPage: any;
  setCurrentTitle: any;
  setRenderedRows: any;
  setPageLoading: any;
  navigate: any;
  setSiteBreadcrumbs: any;
  siteBreadcrumbs: any;
  setSelected: any;
}) => {
  return (
    <div className="absolut5">
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            sx={{ fontSize: "18px", fontFamily: "Mulish" }}
            component="div"
            id="all-parent-group-subheader"
          >
            {`Filtered Groups (${editedparentsGroupsList.length})`}
          </ListSubheader>
        }
      >
        {editedparentsGroupsList.map((arrayLvl1Item: any) => {
          {
            if (arrayLvl1Item.parent_account) {
              return (
                <ListItemButton
                  key={arrayLvl1Item.id}
                  onClick={() => {
                    handleUnnestedLevelClick(
                      setPage,
                      setCurrentTitle,
                      arrayLvl1Item.name,
                      arrayLvl1Item.id,
                      setRenderedRows,
                      setPageLoading,
                      navigate,
                      setSiteBreadcrumbs,
                      setSelected
                    );
                  }}
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={arrayLvl1Item.name}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontFamily: "Mulish",
                    }}
                  />
                </ListItemButton>
              );
            } else {
              return (
                <ListItemButton
                  key={arrayLvl1Item.id}
                  onClick={() => {
                    handleUnnestedLevelClick(
                      setPage,
                      setCurrentTitle,
                      arrayLvl1Item.name,
                      arrayLvl1Item.id,
                      setRenderedRows,
                      setPageLoading,
                      navigate,
                      setSiteBreadcrumbs,
                      setSelected
                    );
                  }}
                >
                  <div className="relative left-[-15px]">
                    <ListItemIcon>
                      <CameraIcon />
                    </ListItemIcon>
                  </div>
                  <div className="relative">
                    <ListItemText
                      primary={arrayLvl1Item.name}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontFamily: "Mulish",
                      }}
                    />
                  </div>
                </ListItemButton>
              );
            }
          }
        })}
      </List>
    </div>
  );
};
