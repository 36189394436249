// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import {
  AllAlertsData,
  CameraOptions,
  FalsePositiveData,
  LoadingData,
  ParentGroupsAlertData,
  PercetageOfMotionData,
  SiteOptions,
  UserFilters,
} from "./analyticsInterfaces";
import { NavigateFunction } from "react-router-dom";
import { yyyymmddGenerator } from "./yyyymmddGenerator";
import Cookies from "universal-cookie";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { getAllAnalyticsData } from "./getAllAnalyticsData";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

const cookies = new Cookies();
const token = cookies.get("token");

export const handleNavigatedFromSitesPage = (
  setLoadingData: Dispatcher<LoadingData>,
  navigate: NavigateFunction,
  startDate: Date,
  endDate: Date,
  setAllAlertsData: Dispatcher<AllAlertsData[]>,
  setSiteOptions: Dispatcher<SiteOptions[]>,
  setCameraOptions: Dispatcher<CameraOptions[]>,
  setPageLoading: Dispatcher<boolean>,
  setParentGroupsAlertsData: Dispatcher<ParentGroupsAlertData[]>,
  setUserFilters: Dispatcher<UserFilters>,
  setFalsePostiveData: Dispatcher<FalsePositiveData[]>,
  setPercetageOfMotionData: Dispatcher<PercetageOfMotionData[]>,
  setAveragePercentageOfMotion: Dispatcher<number>,
  parentGroupId: string,
  siteId: string,
  setDisplayOnLeftBool: Dispatcher<boolean>,
  setAlertsGraphName: Dispatcher<string>,
  setVisibleArray: Dispatcher<any[]>,
  setConversionArray: any,
  setShowMoreVisible: Dispatcher<boolean>,
  setShowMoreOpen: Dispatcher<boolean>,
  setLoadingSites: Dispatcher<boolean>,
  userFilters: any,
  setParentAccountsLoading: any,
  setParentAccounts: any,
  setParentIds: any,
  setParentNames: any,
  setDownloadURL: any
) => {
  setPageLoading(true);

  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleNavigatedFromSitesPage(
      setLoadingData,
      navigate,
      startDate,
      endDate,
      setAllAlertsData,
      setSiteOptions,
      setCameraOptions,
      setPageLoading,
      setParentGroupsAlertsData,
      setUserFilters,
      setFalsePostiveData,
      setPercetageOfMotionData,
      setAveragePercentageOfMotion,
      localStorage.analyticsParentGroupId,
      localStorage.analyticsSiteId,
      setDisplayOnLeftBool,
      setAlertsGraphName,
      setVisibleArray,
      setConversionArray,
      setShowMoreVisible,
      setShowMoreOpen,
      setLoadingSites,
      userFilters,
      setParentAccountsLoading,
      setParentAccounts,
      setParentIds,
      setParentNames,
      setDownloadURL
    );
  };

  const idArray = [localStorage.analyticsSiteId];

  let idString = "";

  if (idArray.length === 1) {
    idString = `${idArray[0]}`;
  }
  if (idArray.length > 1) {
    idString = `${idArray[0]}`;
    idArray.forEach((id: string, index: number) => {
      if (index > 0) {
        idString += `,${id}`;
      }
    });
  }

  AxiosUI.get(`customer/by_group_id/${parentGroupId}/?page_size=1000`).then(
    (response: AxiosResponse) => {
      // if (response.data.count === 1 && setAlertsGraphName) {
      //   setAlertsGraphName("Camera");
      // }
      setSiteOptions(response.data.results);
      setLoadingSites(false);
      AxiosUI.get(`camera/site/?customers=${idString}`).then(
        (response: AxiosResponse) => {
          setCameraOptions(response.data.results);
          setPageLoading(false);
          if (idArray.length === 0) {
            setCameraOptions([]);
          }
          getAllAnalyticsData(
            setAllAlertsData,
            parentGroupId,
            yyyymmddGenerator(startDate),
            yyyymmddGenerator(endDate),
            [siteId],
            [],
            navigate,
            setLoadingData,
            setParentGroupsAlertsData,
            [siteId],
            setDisplayOnLeftBool,
            setVisibleArray,
            setConversionArray,
            setShowMoreVisible,
            setShowMoreOpen,
            userFilters,
            setAlertsGraphName,
            setFalsePostiveData,
            parentGroupId,
            setPercetageOfMotionData,
            setAveragePercentageOfMotion,
            setParentAccountsLoading,
            setParentAccounts,
            setParentIds,
            setParentNames,
            setDownloadURL
          );
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert("Error retrieving sites. Please try again");
            setPageLoading(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error retrieving sites. Please try again");
        setLoadingSites(false);
      });
    }
  );
  localStorage.setItem("navigatedToAnalyticsFromSitesPage", "false");
};
