import * as React from "react";
import { Alert } from "@mui/material";

export const GroupUserAlerts = ({
  alertState,
  setAlertState,
  userNameString,
}: {
  alertState: any;
  setAlertState: any;
  userNameString: string;
}) => {
  return (
    <div>
      {alertState.deleteUserSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: any) => {
              return { ...previousState, deleteUserSuccess: false };
            });
          }}
        >
          {`User ${userNameString} deleted successfully.`}
        </Alert>
      )}
      {alertState.updateUserSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: any) => {
              return { ...previousState, updateUserSuccess: false };
            });
          }}
        >
          {`User ${userNameString} updated successfully.`}
        </Alert>
      )}
      {alertState.addUserSuccess && (
        <Alert
          onClose={() => {
            setAlertState((previousState: any) => {
              return { ...previousState, addUserSuccess: false };
            });
          }}
        >
          {`User ${userNameString} added successfully.`}
        </Alert>
      )}
      {alertState.emailSent && (
        <Alert
          onClose={() => {
            setAlertState((previousState: any) => {
              return { ...previousState, emailSent: false };
            });
          }}
        >
          {`Temporary password email sent to ${userNameString}`}
        </Alert>
      )}
      {alertState.noData && (
        <Alert
          severity="warning"
          onClose={() => {
            setAlertState((previousState: any) => {
              return { ...previousState, noData: false };
            });
          }}
        >
          {`No users matched your search query.`}
        </Alert>
      )}
    </div>
  );
};
