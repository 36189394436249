/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Dialog, DialogActions, DialogTitle, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { getQRCode } from "./getQRCode";
import { useNavigate } from "react-router-dom";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { handleSubmitAuthentication } from "./handleSubmitAuthentication";
// import * as TestQRCode from "../../../../assets/TestQRCode.png";

export const MFASetup = () => {
  const [authenticationMethod, setAuthenticationMethod] = React.useState("sms");
  const [authenticationData, setAuthenticationData] = React.useState<any>({
    authentication_method: "sms",
  });

  const [maxWidth, setMaxWidth] = React.useState<any>("sm");

  const [height, setHeight] = React.useState(540);

  const [qRCode, setQRCode] = React.useState("");

  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = React.useState(true);

  React.useEffect(() => {
    getQRCode(setQRCode, setPageLoading, navigate);
  }, []);

  return (
    <div>
      <BackdropLoadingScreen openState={pageLoading} />
      <div className="bg-actuate-grey absolute w-screen h-screen">
        <Dialog
          open={true}
          fullWidth={true}
          maxWidth={maxWidth}
          sx={{ height: height }}
        >
          <DialogTitle sx={{ color: "#001943" }}>
            Multi-factor Authentication Setup
          </DialogTitle>
          <div
            className={
              maxWidth === "sm"
                ? "relative h-[530px] w-[520px] bg-white"
                : "relative h-[630px] w-[870px] bg-white"
            }
          >
            <p className=" pt-[10px] pl-[35px] text-mulish">
              Please select how you would like to authenticate with
            </p>
            <p className="pt-[10px] pl-[35px] text-mulish font-bold">
              {localStorage.username}
            </p>
            <p className="pt-[40px] pl-[35px] text-mulish">
              <FormControl>
                <FormLabel>Authentication Method</FormLabel>
                <RadioGroup defaultValue={authenticationMethod}>
                  <FormControlLabel
                    value="sms"
                    control={<Radio />}
                    label="SMS"
                    onClick={() => {
                      setMaxWidth("sm");
                      setHeight(530);
                      setAuthenticationMethod("sms");
                      setAuthenticationData({
                        authentication_method: "sms",
                      });
                    }}
                  />
                  <FormControlLabel
                    value="app"
                    control={<Radio />}
                    label="Authenticator App"
                    onClick={() => {
                      setMaxWidth("md");
                      setHeight(620);
                      setAuthenticationMethod("app");
                      setAuthenticationData({
                        authentication_method: "app",
                        // authenticator_name: "",
                      });
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </p>
            <div className="pt-[20px] pl-[35px] text-mulish">
              {maxWidth === "sm" ? (
                <TextField
                  onChange={(event) => {
                    setAuthenticationData((previousState: any) => {
                      return {
                        ...previousState,
                        phone_number: event.target.value,
                      };
                    });
                  }}
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  sx={{ width: 350 }}
                  helperText="Enter a phone number to receive codes via SMS. Include country code, and only numbers. For Example +12223334444"
                />
              ) : (
                <TextField
                  onChange={(event) => {
                    setAuthenticationData((previousState: any) => {
                      return {
                        ...previousState,
                        authenticator_code: event.target.value,
                      };
                    });
                  }}
                  id="outlined-basic"
                  label="Authentication Code"
                  variant="outlined"
                  sx={{ width: 350 }}
                  helperText="Enter the code from your authenticator app once you've scanned the QR code"
                />
              )}
            </div>
            {maxWidth !== "sm" && (
              <div className="pt-[20px] pl-[35px] text-mulish">
                <TextField
                  onChange={(event) => {
                    setAuthenticationData((previousState: any) => {
                      return {
                        ...previousState,
                        authenticator_name: event.target.value,
                      };
                    });
                  }}
                  id="outlined-basic"
                  label="Friendly Name *"
                  variant="outlined"
                  sx={{ width: 350 }}
                  helperText="Optional. Add a friendly name for the authenticator."
                />
              </div>
            )}
            {maxWidth !== "sm" && (
              <p className="absolute top-[10px] right-[35px]">
                Scan this QR Code with your Authenticator App:
              </p>
            )}
            {maxWidth !== "sm" && qRCode && (
              <div className="absolute top-[70px] right-[75px]">
                <img
                  className="w-[250px]"
                  src={qRCode}
                  alt="Video_clip_coming_soon_image"
                />
              </div>
            )}
            {maxWidth !== "sm" && !qRCode && (
              <p className="absolute top-[130px] right-[100px] text-red-500 font-bold text-center">
                <div>QR CODE NOT AVAILABLE.</div>
                <div>Try refreshing screen</div>
                <div>or use SMS method.</div>
              </p>
            )}
          </div>
          <DialogActions>
            <OrangeTextButton
              onClick={() => {
                setPageLoading(true);
                handleSubmitAuthentication(
                  authenticationData,
                  setPageLoading,
                  navigate
                );
              }}
              text="Save"
              disabled={false}
            />
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
