import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const getIgnoreZoneDialogInfo = (
  row: any,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  setLoadingIgnoreZonesInfo: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    getIgnoreZoneDialogInfo(
      row,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      setLoadingIgnoreZonesInfo,
      navigate
    );
  };

  AxiosUI.get(`/camera/${row.camera_pk}/status/`).then(
    (response: AxiosResponse) => {
      setSelectedAlertCameraInfo(response.data);
      AxiosUI.get(`/camera/${row.camera_pk}/ignore_zones/`).then(
        (response: AxiosResponse) => {
          setIgnoreZonesSelectedAlert(response.data);
          setLoadingIgnoreZonesInfo(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setIgnoreZonesSelectedAlert([]);
            setLoadingIgnoreZonesInfo(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setSelectedAlertCameraInfo({});
        setLoadingIgnoreZonesInfo(false);
      });
    }
  );
};
