import React from "react";
import { createApi } from "../../../utils/createApi";
import { getNumbersAfterEditCamera } from "../../EditCamera/EditCamera";
import { getNumbersAfterSites } from "../../EditCamera/EditCamera";
import { AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { resizeImageIgnoreZones } from "./resizeImageIgnoreZones";

export const getEditIgnoreZonesInfo = (
  setPageLoading: Dispatcher<boolean>,
  setBreadCrumbs: Dispatcher<any>,
  setVisibleIgnoreZones: Dispatcher<any>,
  setDimensions: Dispatcher<any>
) => {
  const AxiosUI = createApi(``);

  const cameraId: any = getNumbersAfterEditCamera(location.href);

  AxiosUI.get(`/camera/${cameraId}/status/`).then((response: AxiosResponse) => {
    localStorage.setItem(
      "zwibblerDimensions",
      JSON.stringify(resizeImageIgnoreZones(response.data.frame_dimensions))
    );
    localStorage.setItem(
      "preview_url",
      JSON.stringify(response.data.preview_url)
    );
    setDimensions(resizeImageIgnoreZones(response.data.frame_dimensions));
    AxiosUI.get(`/camera/${cameraId}/ignore_zones/`).then(
      (response: AxiosResponse) => {
        setVisibleIgnoreZones(response.data);
        AxiosUI.get(
          `customer/${getNumbersAfterSites(location.href)}/about/`
        ).then((response: AxiosResponse) => {
          setBreadCrumbs(response.data.breadcrumbs);
          if (!localStorage.currentCamera) {
            AxiosUI.get(`/camera/${cameraId}/general_info/`).then(
              (response: AxiosResponse) => {
                localStorage.setItem(
                  "currentCamera",
                  response.data.camera_name
                );
                setPageLoading(false);
              }
            );
          } else {
            setPageLoading(false);
          }
        });
      }
    );
  });
};
