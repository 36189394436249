import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";

export const getAdditionalData = (
  activeTab: string,
  errorPaginationNumber: number,
  setErrorPaginationNumber: any,
  setCameraErrorInfo: any,
  warningPaginationNumber: number,
  setWarningPaginationNumber: any,
  setCameraWarningInfo: any,
  setDisplayedInfo: any,
  setLoadingMoreData: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    getAdditionalData(
      activeTab,
      errorPaginationNumber,
      setErrorPaginationNumber,
      setCameraErrorInfo,
      warningPaginationNumber,
      setWarningPaginationNumber,
      setCameraWarningInfo,
      setDisplayedInfo,
      setLoadingMoreData,
      navigate
    );
  };

  if (activeTab === "error") {
    AxiosUI.get(
      `camera/dashboard/?page=${errorPaginationNumber + 1}&status=error`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem(
          `camerasErrorInfo`,
          JSON.stringify(response.data.results)
        );
        setErrorPaginationNumber(errorPaginationNumber + 1);
        setCameraErrorInfo((previousState: any) => [
          ...previousState,
          ...response.data.results,
        ]);
        setDisplayedInfo((previousState: any) => [
          ...previousState,
          ...response.data.results,
        ]);
        setLoadingMoreData(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    );
  } else if (activeTab === "warning") {
    AxiosUI.get(
      `camera/dashboard/?page=${warningPaginationNumber + 1}&status=warning`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem(
          `camerasWarningInfo`,
          JSON.stringify(response.data.results)
        );
        setWarningPaginationNumber(warningPaginationNumber + 1);
        setCameraWarningInfo((previousState: any) => [
          ...previousState,
          ...response.data.results,
        ]);
        setDisplayedInfo((previousState: any) => [
          ...previousState,
          ...response.data.results,
        ]);
        setLoadingMoreData(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    );
  }
};
