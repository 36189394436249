/* eslint-disable no-console */
import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "../utils/getApiUrl";
import { useEffect } from "react";
import { useAuth } from "../utils/auth";
import { useCookies } from "react-cookie";
import { getAllResources } from "../utils/dataFetchers";
import { handleNavigatedFromExternalLink } from "./Sites/SitesPage/SitesPageUtils/handleNavigatedFromExternalLink";
import { fetchGroupTrees } from "../utils/fetchGroupTrees";
import { getRefreshTimeout } from "../utils/getRefreshTimeout";
import { handleSetPermissions } from "../utils/handleSetPermissions";

export const Callback = ({
  setGroupTrees,
  setPermissions,
}: {
  setGroupTrees: any;
  setPermissions: any;
}) => {
  const queryString = window.location.search;
  const code = queryString.slice(6);
  const auth = useAuth();
  const navigate = useNavigate();
  const baseURL = getApiUrl();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(undefined);

  const AcuateUI = axios.create({
    baseURL: `${baseURL}api/auth/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${code}`,
    },
  });

  localStorage.setItem("getMFA", "false");

  const navigateAfter = async () => {
    await getAllResources()
      .then(() => {
        handleSetPermissions(navigate, setPermissions);
        if (localStorage.getMFA === "true") {
          navigate(`/MFA`);
        }
        // Add an if statement to check if localStorage.redirectURL exists, and if so navigate to that URL string minus the what comes in the string before the first forwared slash.
        else if (localStorage.redirectURL) {
          if (!localStorage.redirectURL.includes("login")) {
            //if nothing is in the redirectURL, navigate to the dashboard
            if (!localStorage.redirectURL.indexOf("/")[0]) {
              navigate("/dashboard");
              localStorage.setItem("getMFA", "false");
            } else {
              navigate(
                localStorage.redirectURL.slice(
                  localStorage.redirectURL.indexOf("/")
                )
              );
              localStorage.setItem("getMFA", "false");
            }
          } else {
            navigate("/dashboard");
            localStorage.setItem("getMFA", "false");
          }
          localStorage.removeItem("redirectURL");
        } else {
          navigate("/dashboard");
          localStorage.setItem("getMFA", "false");
        }
      })
      .catch((err: AxiosError) => console.log("error", err));
  };

  const getToken = async () => {
    await AcuateUI.get(`token/${queryString}`)
      .then((response: AxiosResponse) => {
        setCookie("token", response.data.token, { path: "/", maxAge: 3600 });
        setCookie("token_secret", response.data.token_secret, {
          path: "/",
        });
        auth.login();
        navigateAfter();
        getRefreshTimeout(localStorage.groupID);
        fetchGroupTrees(navigate, setGroupTrees);
      })
      .catch((err: AxiosError) => alert(`${err}`));
  };

  useEffect(() => {
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center align-center h-screen m-auto">
      <div className="lds-facebook font-black">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
