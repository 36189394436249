import { NavBar } from "../../../common/NavBar";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { SitesScreen } from "./SitesScreen";
import { AddSiteButton } from "./AddSiteButton";
import { GroupSearchBar } from "./GroupsSearchBar";
import BackdropLoadingScreen from "../../../common/BackdropLoadingScreen";
import Alert from "@mui/material/Alert";
import { getIndiviualGroup } from "./SitesPageUtils/getIndiviualGroup";
import { AddGroupDialog } from "./AddGroupDialog";
import { Link } from "react-router-dom";
import { Dispatcher } from "../SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { fetchGroupTrees } from "../../../utils/fetchGroupTrees";
import { SitePageAlerts } from "./SitePageAlerts";

export const Sites = ({
  groupTrees,
  setGroupTrees,
  permissions,
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  groupTrees: any;
  setGroupTrees: Dispatcher<any>;
  permissions: any;
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(localStorage.sitesTablePage);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    localStorage.sitesRowsPerPage
  );

  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    setGroupTrees(JSON.parse(localStorage.groupTrees));
  }, []);

  React.useEffect(() => {
    const currentGroupsTrees = JSON.parse(localStorage.groupTrees);
    setTimeout(() => {
      if (!currentGroupsTrees[0]) {
        fetchGroupTrees(navigate, setGroupTrees);
      }
    }, 5000);
  }, []);

  const [selectedIndex, setSelectedIndex] = React.useState({
    level1: [],
    level2: [],
    level3: [],
    level4: [],
    level5: [],
    level6: [],
    // level7: [],
  });

  const [renderedRows, setRenderedRows] = React.useState([]);

  const [pageLoading, setPageLoading] = React.useState(false);

  const [currentTitle, setCurrentTitle] = React.useState("");

  const [searchBarValue, setSearchBarValue] = React.useState("");

  const [editedparentsGroupsList, setEditedParentGroupList] = React.useState(
    []
  );

  const [siteDeletedAlert, setSiteDeltedAlert] = React.useState(
    localStorage.siteDeletedAlert
  );

  const [groupAddedAlert, setGroupAddedAlert] = React.useState(false);

  const [backdropLoadingScreenOn, setBackdropLoadingScreenOn] =
    React.useState(false);

  const [siteBreadcrumbs, setSiteBreadcrumbs] = React.useState(
    // JSON.parse(localStorage.currentSiteBreadcrumbs)
    []
  );

  const [addGroupDialogOpen, setAddGroupDialogOpen] = React.useState(false);

  const [siteScreenAlerts, setSiteScreenAlerts] = React.useState({
    armGroupSuccess: false,
    disarmGroupSuccess: false,
    deployGroupSuccess: false,
  });

  const [siteScreenLoading, setSiteScreenLoading] = React.useState(false);

  React.useEffect(
    () => () => {
      localStorage.setItem("siteDeletedAlert", "false");
    },
    []
  );

  React.useEffect(() => {
    if (
      (localStorage.navigatedFromSitesAboutPage === "true" &&
        localStorage.level0Click === "true") ||
      !localStorage.clickedId
    ) {
      setSiteBreadcrumbs([]);
      localStorage.setItem("navigatedFromSitesAboutPage", "false");
    } else if (
      localStorage.navigatedFromSitesAboutPage === "true" &&
      localStorage.level0Click === "false"
    ) {
      setPageLoading(true);
      getIndiviualGroup(
        localStorage.clickedId,
        setRenderedRows,
        setPageLoading,
        setSiteBreadcrumbs,
        setSelected,
        navigate
      );
      localStorage.setItem("navigatedFromSitesAboutPage", "false");
    } else if (
      localStorage.navigatedFromSitesAboutPage === "false" &&
      localStorage.level0Click === "false" &&
      localStorage.siteJustDeleted !== "true"
    ) {
      setSelectedIndex(JSON.parse(localStorage.storedSelectedIndex));
      getIndiviualGroup(
        localStorage.clickedId,
        setRenderedRows,
        setPageLoading,
        setSiteBreadcrumbs,
        setSelected,
        navigate
      );
      localStorage.setItem("navigatedFromSitesAboutPage", "false");
    }
  }, []);

  return (
    <div>
      <div>
        <SitePageAlerts
          siteScreenAlerts={siteScreenAlerts}
          setSiteScreenAlerts={setSiteScreenAlerts}
        />
        {siteDeletedAlert === "true" && (
          <Alert
            onClose={() => {
              setSiteDeltedAlert("false");
              localStorage.setItem("siteDeletedAlert", "false");
            }}
          >
            Site deleted successfully!
          </Alert>
        )}
        {groupAddedAlert && (
          <Alert
            onClose={() => {
              setGroupAddedAlert(false);
            }}
          >
            New Group added successfully! New Group will not appear in Parent
            Groups & Sites list until you&nbsp;
            <Link to="/sites/addsite" className="underline underline-offset-1">
              Add Site
            </Link>
            &nbsp; to the group.
          </Alert>
        )}
        {addGroupDialogOpen && (
          <AddGroupDialog
            addGroupDialogOpen={addGroupDialogOpen}
            setAddGroupDialogOpen={setAddGroupDialogOpen}
            setGroupTrees={setGroupTrees}
            setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
            setGroupAddedAlert={setGroupAddedAlert}
          />
        )}
        <BackdropLoadingScreen openState={backdropLoadingScreenOn} />
        <BackdropLoadingScreen openState={siteScreenLoading} />
        <div className="bg-actuate-grey absolute w-screen h-screen">
          <div
            className={
              navBarCollapsed
                ? "absolute top-[48px] left-[75px] right-[1px] h-[19px]] bg-white"
                : "absolute top-[48px] left-[150px] right-[1px] h-[19px]] bg-white"
            }
          >
            <p className="font-black font-mulish text-[20px] p-3 indent-6">
              Sites
            </p>
          </div>
          <div
            className={
              navBarCollapsed
                ? "absolute left-[60px] top-[5px]"
                : "absolute left-[135px] top-[5px]"
            }
          >
            <GroupSearchBar
              setSearchBarValue={setSearchBarValue}
              setEditedParentGroupList={setEditedParentGroupList}
              setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
            />
          </div>
          <div>
            <SitesScreen
              searchBarValue={searchBarValue}
              currentTitle={currentTitle}
              pageLoading={pageLoading}
              renderedRows={renderedRows}
              page={page}
              rowsPerPage={rowsPerPage}
              setPageLoading={setPageLoading}
              navigate={navigate}
              groupTrees={groupTrees}
              selectedIndex={selectedIndex}
              setPage={setPage}
              setCurrentTitle={setCurrentTitle}
              setSelectedIndex={setSelectedIndex}
              setRenderedRows={setRenderedRows}
              editedparentsGroupsList={editedparentsGroupsList}
              setRowsPerPage={setRowsPerPage}
              siteBreadcrumbs={siteBreadcrumbs}
              setSiteBreadcrumbs={setSiteBreadcrumbs}
              setAddGroupDialogOpen={setAddGroupDialogOpen}
              navBarCollapsed={navBarCollapsed}
              permissions={permissions}
              selected={selected}
              setSelected={setSelected}
              setSiteScreenAlerts={setSiteScreenAlerts}
              setSiteScreenLoading={setSiteScreenLoading}
            />
          </div>
          {permissions.add_cameras && (
            <div>
              <AddSiteButton navigate={navigate} />
            </div>
          )}
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
