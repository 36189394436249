import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleShowMoreButtonClick } from "./AnalyticsUtils/handleShowMoreButtonClick";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const ShowMoreButton = ({
  showMoreOpen,
  setShowMoreOpen,
  setVisibleArray,
  fullArray,
  max7Array,
  setBarChartHeight,
  barChartHeightFull,
  setOuterBoxHeight,
  outerBoxHeightFull,
  setInnerBoxHeight,
  innerBoxHeightFull,
  setDisplayOnLeftBool,
}: {
  showMoreOpen: boolean;
  setShowMoreOpen: Dispatcher<boolean>;
  setVisibleArray: Dispatcher<any>;
  fullArray: any;
  max7Array: any;
  setBarChartHeight: Dispatcher<number>;
  barChartHeightFull: number;
  setOuterBoxHeight: Dispatcher<number>;
  outerBoxHeightFull: number;
  setInnerBoxHeight: Dispatcher<number>;
  innerBoxHeightFull: number;
  setDisplayOnLeftBool: Dispatcher<boolean>;
}) => {
  return (
    <OrangeTextButton
      onClick={() =>
        handleShowMoreButtonClick(
          showMoreOpen,
          setShowMoreOpen,
          setVisibleArray,
          fullArray,
          max7Array,
          setBarChartHeight,
          barChartHeightFull,
          setOuterBoxHeight,
          outerBoxHeightFull,
          setInnerBoxHeight,
          innerBoxHeightFull,
          setDisplayOnLeftBool
        )
      }
      text={!showMoreOpen ? "+ SHOW MORE" : "- SHOW LESS"}
      disabled={false}
    />
  );
};
