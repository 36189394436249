import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handleSearchBarFilter = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setSearchBarValue: any,
  setEditedParentGroupList: any,
  navigate: any,
  setBackdropLoadingScreenOn: any
) => {
  setSearchBarValue(event.target.value);
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleSearchBarFilter(
      event,
      setSearchBarValue,
      setEditedParentGroupList,
      navigate,
      setBackdropLoadingScreenOn
    );
  };
  await AxiosUI.get(`group/?search=${event.target.value}`).then(
    (response: AxiosResponse) => {
      setBackdropLoadingScreenOn(false);
      setEditedParentGroupList(response.data.results);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
