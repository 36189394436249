import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { checkArmedStatus } from "./checkArmedStatus";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handleArmSite = async (
  setBackdropLoadingScreenOn: any,
  setSiteInfo: any,
  setAlertState: any,
  setSyncNeeded: any,
  setArmedStatus: any,
  navigate: any,
  setArmButtonDisabled: any
) => {
  const AxiosUI = createApi(``);
  setBackdropLoadingScreenOn(true);
  const retryFunction = () => {
    handleArmSite(
      setBackdropLoadingScreenOn,
      setSiteInfo,
      setAlertState,
      setSyncNeeded,
      setArmedStatus,
      navigate,
      setArmButtonDisabled
    );
  };
  let siteArmFail = false;

  await AxiosUI.post(`customer/${localStorage.siteId}/action/arm/`).then(
    () => {
      siteArmFail = false;
    },
    (reason: AxiosError) => {
      siteArmFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        setSiteInfo((previousState: any) => {
          return { ...previousState, armed: false };
        });
      });
    }
  );
  !siteArmFail &&
    (await AxiosUI.get(`customer/${localStorage.siteId}/about/`).then(
      (response: AxiosResponse) => {
        checkArmedStatus(
          response.data,
          setArmButtonDisabled,
          navigate,
          setSiteInfo,
          setArmedStatus
        );
        setSiteInfo(response.data);
        if (response.data.settings_deployed !== true) {
          setSyncNeeded(true);
        }
        if (
          response.data.armed_status === "ARMING" ||
          response.data.armed_status === "ARMED_OUTSIDE_SCHEDULE"
        ) {
          setAlertState((previousState: any) => {
            return {
              ...previousState,
              changeSuccess: false,
              activateSuccess: false,
              deactivateSuccess: false,
              disarmSuccess: false,
              armSuccess: true,
              syncSuccess: false,
              cameraDeleteSuccess: false,
              armFail: false,
            };
          });
        }
        setArmedStatus(true);
        setBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          {
            setAlertState((previousState: any) => {
              return {
                ...previousState,
                changeSuccess: false,
                activateSuccess: false,
                deactivateSuccess: false,
                disarmSuccess: false,
                armSuccess: false,
                syncSuccess: false,
                cameraDeleteSuccess: false,
                armFail: true,
              };
            });
          }
          setBackdropLoadingScreenOn(false);
          {
            setAlertState((previousState: any) => {
              return {
                ...previousState,
                changeSuccess: false,
                activateSuccess: false,
                deactivateSuccess: false,
                disarmSuccess: false,
                armSuccess: false,
                syncSuccess: false,
                cameraDeleteSuccess: false,
                armFail: true,
              };
            });
          }
          setBackdropLoadingScreenOn(false);
          console.log(reason);
        });
      }
    ));
};
