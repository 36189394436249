export const productsMatcher = (
  type: string,
  input: string | number,
  returnedProducts: any
) => {
  const products = JSON.parse(localStorage.products);
  const productIds = new Map();
  const productNames = new Map();

  if (returnedProducts[0][0]) {
    returnedProducts.forEach((array: any[]) => {
      productNames.set(`${array[0]}`, array[1]);
      productIds.set(array[1], `${array[0]}`);
    });
  } else {
    products.forEach((element: any) => {
      productNames.set(`${element.name}`, element.id);
      productIds.set(element.id, `${element.name}`);
    });
  }
  if (type === "Id") {
    return productNames.get(input);
  } else if (type === "Name") {
    return productIds.get(input);
  }
};
