import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Autocomplete } from "@mui/material";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleAddNewCalendar } from "./CalendarsUtils/handleAddNewCalendar";
import { NavigateFunction } from "react-router-dom";

export const AddCalendarDialog = ({
  addCalendarDialogOpen,
  setAddCalendarDialogOpen,
  setFetchingCalendarData,
  calendarData,
  setCalendarData,
  setCalendarAlerts,
  calendarsSearchString,
  navigate,
}: {
  addCalendarDialogOpen: boolean;
  setAddCalendarDialogOpen: Dispatcher<boolean>;
  setFetchingCalendarData: Dispatcher<boolean>;
  calendarData: any;
  setCalendarData: any;
  setCalendarAlerts: any;
  calendarsSearchString: any;
  navigate: NavigateFunction;
}) => {
  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const parentIds = new Map();
  const parentNames = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });
  parentAccounts.forEach((object: any) => {
    parentNames.set(object.id, object.name);
  });

  const [newCalendarInfo, setNewCalendarInfo] = React.useState<any>({
    group: "",
    name: "",
  });

  return (
    <Dialog open={addCalendarDialogOpen}>
      <DialogTitle id="add-calendar">Add Calendar</DialogTitle>
      <DialogContent sx={{ width: 450, height: 375 }}>
        <TextField
          id="sureview_subject"
          variant="standard"
          label="Calendar Name"
          value={newCalendarInfo.name}
          onChange={(event) => {
            setNewCalendarInfo((previousState: any) => {
              return { ...previousState, name: event.target.value };
            });
          }}
          sx={{ width: "350px" }}
          helperText=""
          type="string"
        />
        <br></br>
        <br></br>
        <Autocomplete
          disablePortal
          options={parentAccounts.map((object: any) => {
            return object.name;
          })}
          onChange={(event: any, newInputValue: any) => {
            setNewCalendarInfo((previousState: any) => {
              return {
                ...previousState,
                group: parentIds.get(newInputValue),
              };
            });
          }}
          sx={{ width: 350 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Group"
              autoComplete="off"
              id="group_name"
              name="group_name"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddCalendarDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setFetchingCalendarData(true);
            setAddCalendarDialogOpen(false);
            handleAddNewCalendar(
              newCalendarInfo,
              setFetchingCalendarData,
              calendarData,
              setCalendarData,
              setCalendarAlerts,
              calendarsSearchString,
              navigate
            );
          }}
          text="Submit"
          disabled={!newCalendarInfo.group || !newCalendarInfo.name}
        />
      </DialogActions>
    </Dialog>
  );
};
