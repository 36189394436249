// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { Dispatcher } from "../../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const createViewTypeHelperText = (
  selectedString: string,
  setHelperText: Dispatcher<string>
) => {
  if (selectedString === "Thermal") {
    setHelperText(
      `We recommend contacting support@actuate.ai about Actuate's thermal model after onboarding this camera.`
    );
  }
  if (selectedString === "Fisheye") {
    setHelperText(
      `For fisheye cameras with only intruder (person or vehicle) detection, we recommend contacting support@actuate.ai about fisheye dewarping after onboarding this camera.`
    );
  }
};
