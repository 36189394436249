import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleDeleteGroupUser = (
  groupUserId: any,
  setAlertState: any,
  setUpdatingGroupUserData: any,
  activeData: any,
  setActiveData: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleDeleteGroupUser(
      groupUserId,
      setAlertState,
      setUpdatingGroupUserData,
      activeData,
      setActiveData,
      navigate
    );
  };

  AxiosUI.delete(`group_user/${groupUserId}/`).then(
    () => {
      setActiveData(activeData.filter((obj: any) => obj.id !== groupUserId));
      setAlertState((previousState: any) => {
        return {
          ...previousState,
          updateUserSuccess: false,
          deleteUserSuccess: true,
          addUserSuccess: false,
          emailSent: false,
          noData: false,
        };
      });
      setUpdatingGroupUserData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error deleting user, please try again");
        setUpdatingGroupUserData(false);
      });
    }
  );
};
