import React from "react";
import { handleRowSelected } from "./handleRowSelected";

export const filterByDetectionType = (
  visibleRows: any,
  detectionType: string,
  setSelectedRow: any,
  alertData: any,
  setAlertData: any,
  setSelectedButtonsFilled: any,
  setSeeMoreURL: any,
  navigate: any,
  setMP4URL: any,
  setVideoClipLoading: any,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  refreshingToken: any,
  setCount: any,
  setAlertsIndexOrder: any,
  selectedRow: any
) => {
  //create a variable that is detection type with the first letter lowercased
  let detectionTypeLower =
    detectionType.slice(0, 1).toLowerCase() + detectionType.slice(1);

  //check if there is a space in the detection type and if so replace it with an underscore
  if (detectionTypeLower.includes(" ")) {
    detectionTypeLower = detectionTypeLower.replace(" ", "_");
  }

  const indexOrder: number[] = [];

  //if detectionType is "All" return all rows else return only rows whose detection_label with the first letter lowercased is equal to detectionType

  const returnRows =
    detectionType === "All"
      ? visibleRows
      : visibleRows.filter(
          (row: any) => row.alert_labels === detectionTypeLower
        );

  //if the detectionType is "All" remove any row whose alert_labels is "UNIDENTIFIABLE"
  if (detectionType === "All") {
    returnRows.forEach((row: any, index: number) => {
      if (row.alert_labels === "UNIDENTIFIABLE") {
        returnRows.splice(index, 1);
      }
    });
  }

  setCount(returnRows.length);

  let selectedRowStillPresent = false;
  returnRows.forEach((object: any, index: number) => {
    if (
      object.approx_capture_timestamp === selectedRow.approx_capture_timestamp
    ) {
      selectedRowStillPresent = true;
    }
  });

  if (!selectedRowStillPresent) {
    setSelectedRow(returnRows[0]);
    handleRowSelected(
      returnRows[0],
      alertData,
      setAlertData,
      setSelectedButtonsFilled,
      setSeeMoreURL,
      navigate,
      setMP4URL,
      setVideoClipLoading,
      setSelectedRow,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      refreshingToken
    );
  }

  returnRows.forEach((row: any) => {
    indexOrder.push(row.index);
  });

  setAlertsIndexOrder(indexOrder);

  return returnRows;
};
