import React from "react";
import { NavigateFunction } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { theme } from "../AddSite/StepsStepper";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Alert, DialogActions } from "@mui/material";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { handleSubmitNewGroupUser } from "./GroupUsersUtils/handleSubmitNewGroupUser";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const AddGroupUserStepTwo = ({
  newUserInfo,
  setNewUserInfo,
  setVisibleScreen,
  setAddGroupUserScreenOpen,
  setUserNameString,
  setAlertState,
  groupUsersSearchString,
  activeData,
  setActiveData,
  setErrorStateMessage,
  setSubmitErrorState,
  setActiveStep,
  navigate,
}: {
  newUserInfo: any;
  setNewUserInfo: any;
  setVisibleScreen: any;
  setAddGroupUserScreenOpen: Dispatcher<boolean>;
  setUserNameString: Dispatcher<string>;
  setAlertState: any;
  groupUsersSearchString: any;
  activeData: any;
  setActiveData: any;
  setErrorStateMessage: any;
  setSubmitErrorState: any;
  setActiveStep: Dispatcher<number>;
  navigate: NavigateFunction;
}) => {
  const [stepTwoInfo, setStepTwoInfo] = React.useState({
    ...newUserInfo,
    send_cognito_invitation: newUserInfo.send_cognito_invitation,
    create_user: newUserInfo.create_user,
    clips: newUserInfo.clips,
  });

  return (
    <div>
      <div className="absolute top-[160px] left-[30px] w-[540px]">
        <ThemeProvider theme={theme}>
          <div>
            <p className="font-mulish text-[18px]  text-actuate-blue">
              Access Level
            </p>
            <div className="pl-[10px] py-[10px]">
              <FormControl>
                <RadioGroup defaultValue={newUserInfo.access_level}>
                  <FormControlLabel
                    onClick={() => {
                      setStepTwoInfo((previousState: any) => {
                        return { ...previousState, access_level: 1 };
                      });
                    }}
                    value={1}
                    control={<Radio />}
                    label="Arm and Disarm Only (Lowest)"
                    sx={{ color: "#001943" }}
                  />

                  <FormControlLabel
                    onClick={() => {
                      setStepTwoInfo((previousState: any) => {
                        return { ...previousState, access_level: 2 };
                      });
                    }}
                    value={2}
                    control={<Radio />}
                    label="Manage Existing Cameras"
                    sx={{ color: "#001943" }}
                  />
                  <FormControlLabel
                    onClick={() => {
                      setStepTwoInfo((previousState: any) => {
                        return { ...previousState, access_level: 3 };
                      });
                    }}
                    value={3}
                    control={<Radio />}
                    label="Self-Service (can add new cameras)"
                    sx={{ color: "#001943" }}
                  />
                  <FormControlLabel
                    onClick={() => {
                      setStepTwoInfo((previousState: any) => {
                        return { ...previousState, access_level: 4 };
                      });
                    }}
                    value={4}
                    control={<Radio />}
                    label="Self-Service with Delete Permissions (Highest)"
                    sx={{ color: "#001943" }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <br></br>
          <div>
            <FormControl variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={stepTwoInfo.send_cognito_invitation}
                      onChange={(event, checked) => {
                        setNewUserInfo((previousState: any) => {
                          return {
                            ...previousState,
                            send_cognito_invitation: checked,
                          };
                        });
                        setStepTwoInfo((previousState: any) => {
                          return {
                            ...previousState,
                            send_cognito_invitation: checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Send Cognito Invitation"
                  sx={{ color: "#001943" }}
                />
              </FormGroup>
              <FormHelperText>
                Send new user an email to create their password.
              </FormHelperText>
            </FormControl>
          </div>
          <br></br>
          <div>
            <FormControl variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={stepTwoInfo.create_user}
                      onChange={(event, checked) => {
                        setNewUserInfo((previousState: any) => {
                          return {
                            ...previousState,
                            create_user: checked,
                          };
                        });
                        setStepTwoInfo((previousState: any) => {
                          return {
                            ...previousState,
                            create_user: checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Create User"
                  sx={{ color: "#001943" }}
                />
              </FormGroup>
              <FormHelperText>
                User can create other users under their group
              </FormHelperText>
            </FormControl>
          </div>
          <div>
            <FormControl variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={stepTwoInfo.clips}
                      onChange={(event, checked) => {
                        setNewUserInfo((previousState: any) => {
                          return {
                            ...previousState,
                            clips: checked,
                          };
                        });
                        setStepTwoInfo((previousState: any) => {
                          return {
                            ...previousState,
                            clips: checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Clips / AI Link"
                  sx={{ color: "#001943" }}
                />
              </FormGroup>
              <FormHelperText>User can view Clips / AI Link</FormHelperText>
            </FormControl>
          </div>
        </ThemeProvider>
        <DialogActions>
          <div>
            <OrangeTextButton
              text="Submit"
              disabled={false}
              onClick={() => {
                setActiveStep(3);
                setVisibleScreen(
                  <StatusNotificationScreen
                    styling="absolute first-line:font-black font-mulish text-[20px] left-[130px] text-center text-[#283E6D] w-[400px] top-80"
                    text={
                      <p>
                        Adding new User.
                        <br />
                        This might take a few seconds.
                      </p>
                    }
                  />
                );
                handleSubmitNewGroupUser(
                  stepTwoInfo,
                  setVisibleScreen,
                  setAddGroupUserScreenOpen,
                  setUserNameString,
                  setAlertState,
                  groupUsersSearchString,
                  activeData,
                  setActiveData,
                  setNewUserInfo,
                  setErrorStateMessage,
                  setSubmitErrorState,
                  setActiveStep,
                  navigate
                );
              }}
            />
          </div>
        </DialogActions>
      </div>
    </div>
  );
};
