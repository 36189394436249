import React from "react";
import { WizardContainer } from "../../common/formikContainer/WizardContainer";
import Alert from "@mui/material/Alert";
import ErrorBoundary from "../../../ErrorBoundary";
import { handleSubmitCamera } from "./handleSubmitCamera";

export const WizardCameraScreen = ({
  setVisibleScreen,
  responseObject,
  setCameraCheckFinsihedAlertOpen,
  setGoodCameras,
  setConnectivityCameras,
  setLowResolutionCameras,
  setCameraCheckResultDownloadURL,
}: {
  setVisibleScreen: any;
  responseObject: any;
  setCameraCheckFinsihedAlertOpen: any;
  setGoodCameras: any;
  setConnectivityCameras: any;
  setLowResolutionCameras: any;
  setCameraCheckResultDownloadURL: any;
}) => {
  const [addCameraError, setAddCameraError] = React.useState(
    localStorage.addCameraError
  );

  const [choicesChanger, setChoicesChanger] = React.useState(true);

  const [cameraNamingError, setCameraNamingError] = React.useState(
    localStorage.cameraNamingError
  );

  return (
    <ErrorBoundary>
      <div>
        {addCameraError && (
          <div className="absolute w-[600px] h-[50px]">
            <Alert
              severity="error"
              onClose={() => {
                setAddCameraError(false);
                localStorage.removeItem("addCameraError");
              }}
            >
              <p className="font-mulish font-bold text-[16px]">
                Problem adding Camera. Please try again.
              </p>
            </Alert>
          </div>
        )}
        {cameraNamingError && (
          <div className="absolute w-[600px] h-[50px]">
            <Alert
              severity="error"
              onClose={() => {
                setCameraNamingError(false);
                localStorage.removeItem("cameraNamingError");
              }}
            >
              <p className="font-mulish font-bold text-[15px]">
                Camera name already exists. Select a different name.
              </p>
            </Alert>
          </div>
        )}
        <p
          className={
            "font-mulish absolute top-[80px] text-[#283E6D] right-[160px] text-[21px]"
          }
        >
          Please complete the camera&apos;s information
        </p>
        <p
          className={
            "font-mulish absolute top-[130px] font-bold text-[#283E6D] right-[490px] text-[16px]"
          }
        >
          Camera {localStorage.cameraNumber}
        </p>
        <div className="absolute right-[215px] top-[180px]">
          <WizardContainer
            handleSubmitData={handleSubmitCamera}
            response={responseObject}
            setVisibleScreen={setVisibleScreen}
            responseObject={responseObject}
            choicesChanger={choicesChanger}
            setChoicesChanger={setChoicesChanger}
            setHealthCheckSelected={() => {}}
            healthCheckSelected={false}
            setActiveWizardContainer={() => {}}
            handleSubmitSite={() => {}}
            setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
            setGoodCameras={setGoodCameras}
            setConnectivityCameras={setConnectivityCameras}
            setLowResolutionCameras={setLowResolutionCameras}
            setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};
