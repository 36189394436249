// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxisTab } from "../AxisTab";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleDeleteRecipient = (
  cameraId: string,
  recipientId: string,
  navigate: NavigateFunction,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  setActiveTab: any,
  recipientsConfiguredData: any,
  titlesArray: any,
  actionRulesData: any,
  setActionRulesData: any,
  actionRulesTitlesArray: any,
  setActionRulesTitlesArray: any,
  applicationsConfiguredData: any,
  cameraGeneralInfo: any,
  axisFinished: any
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleDeleteRecipient(
      cameraId,
      recipientId,
      navigate,
      setBackdropLoadingScreenOn,
      setRecipientsConfiguredData,
      setTitlesArray,
      setActiveTab,
      recipientsConfiguredData,
      titlesArray,
      actionRulesData,
      setActionRulesData,
      actionRulesTitlesArray,
      setActionRulesTitlesArray,
      applicationsConfiguredData,
      cameraGeneralInfo,
      axisFinished
    );
  };

  AxiosUI.delete(`/axis/${cameraId}/recipient/${recipientId}/`).then(
    (response: AxiosResponse) => {
      setRecipientsConfiguredData(response.data);
      const newTitlesArray = ["Name"];
      response.data.forEach((object: any) => {
        object.additional_fields.forEach((obj: any) => {
          if (
            obj.id === "recipient_type" &&
            !newTitlesArray.includes("Recipient Type")
          ) {
            newTitlesArray.push("Recipient Type");
          }
          if (obj.id === "host" && !newTitlesArray.includes("Host")) {
            newTitlesArray.push("Host");
          }
          if (obj.id === "port" && !newTitlesArray.includes("Port")) {
            newTitlesArray.push("Port");
          }
          if (
            obj.id === "email_from" &&
            !newTitlesArray.includes("Email From")
          ) {
            newTitlesArray.push("Email From");
          }
          if (obj.id === "email_to" && !newTitlesArray.includes("Email To")) {
            newTitlesArray.push("Email To");
          }
          if (obj.id === "login" && !newTitlesArray.includes("Login")) {
            newTitlesArray.push("Login");
          }
          if (obj.id === "password" && !newTitlesArray.includes("Password")) {
            newTitlesArray.push("Password");
          }
        });
      });
      newTitlesArray.push("");
      setTitlesArray(newTitlesArray);
      setBackdropLoadingScreenOn(false);
      setActiveTab(<></>);
      setActiveTab(
        <AxisTab
          recipientsConfiguredData={response.data}
          titlesArray={newTitlesArray}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setRecipientsConfiguredData={setRecipientsConfiguredData}
          setTitlesArray={setTitlesArray}
          actionRulesData={actionRulesData}
          setActionRulesData={setActionRulesData}
          actionRulesTitlesArray={actionRulesTitlesArray}
          setActionRulesTitlesArray={setActionRulesTitlesArray}
          setActiveTab={setActiveTab}
          applicationsConfiguredData={applicationsConfiguredData}
          cameraGeneralInfo={cameraGeneralInfo}
          axisFinished={axisFinished}
        />
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        alert("Problem deleting recipient, please try again");
      });
    }
  );
};
