import React from "react";
import { handleConvertSchedulesToBooleanArrays } from "../Sites/SiteAboutPage/SiteAboutPageUtils/handleConvertSchedulesToBooleanArrays";

export const handleUpdateTimeSchedules = (
  dayOfWeekArray: number[],
  startTime: string,
  endTime: string,
  timeRanges: string[],
  siteSchedules: any,
  alwaysOn: boolean,
  duskToDawn: boolean,
  bufferTime: number,
  duskToDawnLocation: string,
  setTimeRanges: React.Dispatch<React.SetStateAction<string[]>>,
  setSiteSchedules: React.Dispatch<React.SetStateAction<any[]>>,
  setGrid: React.Dispatch<React.SetStateAction<boolean[][]>>,
  setMatchingSchedules: React.Dispatch<React.SetStateAction<number[][]>>
) => {
  const filterArrays = (arr1: number[], arr2: number[]): number[] => {
    return arr1.filter((num) => !arr2.includes(num));
  };

  const updatedStartTime = duskToDawn ? "17:00:00" : startTime;

  const updatedEndTime = duskToDawn ? "08:00:00" : endTime;

  let timeRangeString = "";

  if (!duskToDawn && !alwaysOn) {
    timeRangeString = `${updatedStartTime.slice(0, 5)} - ${updatedEndTime.slice(
      0,
      5
    )}`;
  }

  if (duskToDawn) {
    timeRangeString = "Dusk to Dawn";
  }

  if (alwaysOn) {
    timeRangeString = "Always On";
  }

  const newSiteScheduleObject = {
    always_on: alwaysOn,
    buffer_time: Number(bufferTime),
    customer: localStorage.siteId,
    day_of_week: dayOfWeekArray,
    enabled: true,
    end_time: updatedEndTime,
    is_overide: false,
    location_dusk_dawn: duskToDawn,
    start_time: updatedStartTime,
  };

  const updatedSiteSchedules = [...siteSchedules];

  updatedSiteSchedules.forEach((siteSchedule: any, index: number) => {
    updatedSiteSchedules[index].day_of_week = filterArrays(
      siteSchedule.day_of_week,
      dayOfWeekArray
    );
  });

  updatedSiteSchedules.push(newSiteScheduleObject);

  const updatedTimeRanges = [...timeRanges];

  updatedTimeRanges.forEach((timeRange, index) => {
    if (dayOfWeekArray.includes(index)) {
      updatedTimeRanges[index] = timeRangeString;
    }
  });

  const updatedMatchingSchdules: number[][] = [];

  updatedSiteSchedules.forEach((siteSchedule: any) => {
    updatedMatchingSchdules.push(siteSchedule.day_of_week);
  });

  //check the number arrays in updatedMatchingSchdules, and if between all of them any of the numbers 0 through 6 are missing, create a new array with those numbers and push it to updatedMatchingSchdules
  const missingDays: number[] = [];
  for (let i = 0; i < 7; i++) {
    let found = false;
    updatedMatchingSchdules.forEach((arr) => {
      if (arr.includes(i)) {
        found = true;
      }
    });
    if (!found) {
      missingDays.push(i);
    }
  }

  if (missingDays.length > 0) {
    updatedMatchingSchdules.push(missingDays);
  }

  const finalSiteSchedulesArray: any[] = [];

  updatedSiteSchedules.forEach((siteSchedule: any) => {
    if (siteSchedule.day_of_week.length > 0) {
      finalSiteSchedulesArray.push(siteSchedule);
    }
  });

  setMatchingSchedules(updatedMatchingSchdules);

  setSiteSchedules(finalSiteSchedulesArray);

  setTimeRanges(updatedTimeRanges);

  setGrid(handleConvertSchedulesToBooleanArrays(finalSiteSchedulesArray));
};
