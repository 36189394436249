/* eslint-disable jsx-a11y/media-has-caption */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import * as VideoShot from "../../../../assets/VideoShot.png";
import { TransitionProps } from "@mui/material/transitions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { cancelIconRed } from "../../common/cancelIconRed";
import { checkedCircleIcon } from "../../common/checkedCircleIcon";
import { formatTimeStamp } from "./InvestigationUtilities/formatTimeStamp";

export const ShowMoreDialog = ({
  showMoreDialogOpen,
  setShowMoreDialogOpen,
  selectedRow,
  videoURL,
}: {
  showMoreDialogOpen: boolean;
  setShowMoreDialogOpen: Dispatcher<boolean>;
  selectedRow: any;
  videoURL: string;
}) => {
  const handleClickOpen = () => {
    setShowMoreDialogOpen(true);
  };

  const handleClose = () => {
    setShowMoreDialogOpen(false);
  };

  return (
    <div>
      <OrangeTextButton
        onClick={handleClickOpen}
        text="Full Clip Info"
        disabled={false}
      />
      <Dialog
        fullScreen
        open={showMoreDialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedRow.customer_name} | {selectedRow.camera_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="relative w-[1300px] top-[20px] left-[20px]">
          <React.Fragment>
            <video width="550" controls autoPlay loop>
              <source src={videoURL} type="video/mp4" />
            </video>
          </React.Fragment>
          <br></br>
          <TableContainer component={Paper}>
            <Table
              sx={{ fontFamily: "Mulish", width: "100%" }}
              aria-label="simple table"
            >
              <TableHeadComponent
                titlesArray={[
                  "AI Result",
                  "Ground Truth",
                  "Updated by",
                  "Clip FPS",
                  "Analyzed FPS",
                  "Slice",
                  "Stationary",
                ]}
              />
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {selectedRow.clip_result}
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {selectedRow.ground_truth}
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {selectedRow.username}
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {selectedRow.fps}
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {selectedRow.stream_fps}
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {selectedRow.slice ? checkedCircleIcon : cancelIconRed}
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {selectedRow.stationary_filter
                      ? checkedCircleIcon
                      : cancelIconRed}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <p className="absolute left-[620px] top-[110px] w-[250px] text-black font-mulish text-[16px] font-bold">
          Site
        </p>
        <p className="absolute left-[620px] top-[135px] w-[250px] text-black font-mulish text-[16px]">
          {selectedRow.customer_name}
        </p>
        <p className="absolute left-[950px] top-[110px] w-[250px] text-black font-mulish text-[16px] font-bold">
          Camera Name
        </p>
        <p className="absolute left-[950px] top-[135px] w-[250px] text-black font-mulish text-[16px]">
          {selectedRow.camera_name}
        </p>
        <p className="absolute left-[620px] top-[195px] w-[250px] text-black font-mulish text-[16px] font-bold">
          Local Time
        </p>
        <p className="absolute left-[620px] top-[220px] w-[250px] text-black font-mulish text-[16px]">
          {formatTimeStamp(selectedRow.clip_received_local)}
        </p>
        <p className="absolute left-[950px] top-[195px] w-[250px] text-black font-mulish text-[16px] font-bold">
          File ID
        </p>
        <p className="absolute left-[950px] top-[220px] w-[250px] text-black font-mulish text-[16px]">
          {selectedRow.file_id}
        </p>
        <p className="absolute left-[620px] top-[285px] w-[250px] text-black font-mulish text-[16px] font-bold">
          Event ID
        </p>
        <p className="absolute left-[620px] top-[310px] w-[250px] text-black font-mulish text-[16px]">
          {selectedRow.event_id}
        </p>
        <p className="absolute left-[950px] top-[285px] w-[250px] text-black font-mulish text-[16px] font-bold">
          Internal Log ID
        </p>
        <p className="absolute left-[950px] top-[310px] w-[250px] text-black font-mulish text-[16px]">
          {selectedRow.internal_id}
        </p>
        <p className="absolute left-[620px] top-[380px] w-[250px] text-black font-mulish text-[16px] font-bold">
          Camera ID
        </p>
        <p className="absolute left-[620px] top-[400px] w-[250px] text-black font-mulish text-[16px]">
          {selectedRow.camera_id}
        </p>
        <div className="relative min-w-[1450px] "></div>
      </Dialog>
    </div>
  );
};
