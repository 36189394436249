import { Tooltip } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import React from "react";
import {
  checkedCircleIconGrey,
  checkedCircleIconGreySmall,
  checkedCircleIconSmall,
} from "../../../../common/checkedCircleIcon";
import { cancelIconRedSmall } from "../../../../common/cancelIconRed";
import { extractFrameRateInfo } from "./extractFrameRateInfo";
import { extractResolutionInfo } from "./extractResolutionInfo";
import { extractAspectRationInfo } from "./extractAspectRationInfo";

export const createCameraSettingsIcon = (
  statusString: string,
  settingString: string,
  commentString: string,
  cameraView: string,
  responseObject: any
) => {
  if (settingString === "Frame Rate") {
    if (cameraView === "thermal" || cameraView === "fisheye") {
      return (
        <Tooltip
          title={`Product requirements have been overriden due to camera view type.`}
          placement="top"
          sx={{ fontFamily: "mulish" }}
        >
          {checkedCircleIconGreySmall}
        </Tooltip>
      );
    } else if (
      commentString &&
      commentString.includes("Dynamic slicing is enabled")
    ) {
      return (
        <Tooltip
          title={`Product requirements have been overriden due to dynamic slicing.`}
          placement="top"
          sx={{ fontFamily: "mulish" }}
        >
          {checkedCircleIconGreySmall}
        </Tooltip>
      );
    } else {
      const frameRateCommentString = extractFrameRateInfo(commentString);

      if (statusString === "unknown") {
        return (
          <Tooltip
            title={`Unable to determine, camera information is still not available.`}
            placement="top"
            sx={{ fontFamily: "mulish" }}
          >
            <WarningIcon sx={{ color: "#9e9e9e" }} fontSize="small" />
          </Tooltip>
        );
      }

      if (statusString === "optimal" || statusString === "good") {
        return (
          <Tooltip
            title={frameRateCommentString}
            placement="top"
            sx={{ fontFamily: "mulish" }}
          >
            {checkedCircleIconSmall}
          </Tooltip>
        );
      }

      if (statusString === "warning" || statusString === "bad") {
        if (
          frameRateCommentString &&
          frameRateCommentString.includes("above")
        ) {
          return (
            <Tooltip
              title={frameRateCommentString}
              placement="top"
              sx={{ fontFamily: "mulish" }}
            >
              <WarningIcon sx={{ color: "#FF8400" }} fontSize="small" />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={frameRateCommentString}
              placement="top"
              sx={{ fontFamily: "mulish" }}
            >
              {cancelIconRedSmall}
            </Tooltip>
          );
        }
      }
    }
  }

  if (settingString === "Resolution") {
    if (cameraView === "thermal" || cameraView === "fisheye") {
      return (
        <Tooltip
          title={`Product requirements have been overriden due to camera view type.`}
          placement="top"
          sx={{ fontFamily: "mulish" }}
        >
          {checkedCircleIconGreySmall}
        </Tooltip>
      );
    } else if (
      commentString &&
      commentString.includes("Dynamic slicing is enabled")
    ) {
      return (
        <Tooltip
          title={`Product requirements have been overriden due to dynamic slicing.`}
          placement="top"
          sx={{ fontFamily: "mulish" }}
        >
          {checkedCircleIconGreySmall}
        </Tooltip>
      );
    } else {
      const resolutionCommentString = extractResolutionInfo(commentString);

      if (statusString === "unknown") {
        return (
          <Tooltip
            title={`Unable to determine, camera information is still not available.`}
            placement="top"
            sx={{ fontFamily: "mulish" }}
          >
            <WarningIcon sx={{ color: "#9e9e9e" }} fontSize="small" />
          </Tooltip>
        );
      }

      if (statusString === "optimal" || statusString === "good") {
        return (
          <Tooltip
            title={resolutionCommentString}
            placement="top"
            sx={{ fontFamily: "mulish" }}
          >
            {checkedCircleIconSmall}
          </Tooltip>
        );
      }

      if (statusString === "warning" || statusString === "bad") {
        if (
          resolutionCommentString &&
          resolutionCommentString.includes("above")
        ) {
          return (
            <Tooltip
              title={resolutionCommentString}
              placement="top"
              sx={{ fontFamily: "mulish" }}
            >
              <WarningIcon sx={{ color: "#FF8400" }} fontSize="small" />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={resolutionCommentString}
              placement="top"
              sx={{ fontFamily: "mulish" }}
            >
              {cancelIconRedSmall}
            </Tooltip>
          );
        }
      }
    }
  }

  if (settingString === "Aspect Ratio") {
    if (cameraView === "thermal" || cameraView === "fisheye") {
      return (
        <Tooltip
          title={`Product requirements have been overriden due to camera view type.`}
          placement="top"
          sx={{ fontFamily: "mulish" }}
        >
          {checkedCircleIconGreySmall}
        </Tooltip>
      );
    } else if (
      commentString &&
      commentString.includes("Dynamic slicing is enabled")
    ) {
      return (
        <Tooltip
          title={`Product requirements have been overriden due to dynamic slicing.`}
          placement="top"
          sx={{ fontFamily: "mulish" }}
        >
          {checkedCircleIconGreySmall}
        </Tooltip>
      );
    } else {
      const aspectRatioCommentString = extractAspectRationInfo(commentString);

      if (statusString === "unknown") {
        return (
          <Tooltip
            title={`Unable to determine, camera information is still not available.`}
            placement="top"
            sx={{ fontFamily: "mulish" }}
          >
            <WarningIcon sx={{ color: "#9e9e9e" }} fontSize="small" />
          </Tooltip>
        );
      }

      if (statusString === "optimal" || statusString === "good") {
        return (
          <Tooltip
            title={aspectRatioCommentString}
            placement="top"
            sx={{ fontFamily: "mulish" }}
          >
            {checkedCircleIconSmall}
          </Tooltip>
        );
      }

      if (statusString === "warning" || statusString === "bad") {
        return (
          <Tooltip
            title={aspectRatioCommentString}
            placement="top"
            sx={{ fontFamily: "mulish" }}
          >
            <WarningIcon sx={{ color: "#FF8400" }} fontSize="small" />
          </Tooltip>
        );
      }
    }
  }

  //   switch (statusString) {
  //     case "unknown":
  //       return (
  //         <Tooltip
  //           title={`Unable to determine, camera information is still not available.`}
  //           placement="top"
  //           sx={{ fontFamily: "mulish" }}
  //         >
  //           <WarningIcon sx={{ color: "#9e9e9e" }} fontSize="small" />
  //         </Tooltip>
  //       );
  //     case "optimal":
  //       return (
  //         <Tooltip
  //           title={`${extractFrameRateInfo(commentString)}`}
  //           placement="top"
  //           sx={{ fontFamily: "mulish" }}
  //         >
  //           {checkedCircleIconSmall}
  //         </Tooltip>
  //       );
  //     case "good":
  //       return (
  //         <Tooltip
  //           title={`${extractFrameRateInfo(commentString)}`}
  //           placement="top"
  //           sx={{ fontFamily: "mulish" }}
  //         >
  //           {checkedCircleIconSmall}
  //         </Tooltip>
  //       );
  //     case "warning":
  //       return (
  //         <Tooltip
  //           title={`${extractFrameRateInfo(commentString)}`}
  //           placement="top"
  //           sx={{ fontFamily: "mulish" }}
  //         >
  //           <WarningIcon sx={{ color: "#FF8400" }} fontSize="small" />
  //         </Tooltip>
  //       );
  //     case "bad":
  //       return (
  //         <Tooltip
  //           title={`${extractFrameRateInfo(commentString)}`}
  //           placement="top"
  //           sx={{ fontFamily: "mulish" }}
  //         >
  //           {cancelIconRedSmall}
  //         </Tooltip>
  //       );
  //   }
};
