import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleVerificationSelection } from "./InvestigationUtilities/handleVerificationSelection";
import { NavigateFunction } from "react-router-dom";

export const UnverifiedButton = ({
  unverifiedClicked,
  setVerifiedClicked,
  setUnverifiedClicked,
  selectedRow,
  investigationData,
  setInvestigationData,
  setSelectedRow,
  setPageLoading,
  navigate,
}: {
  unverifiedClicked: boolean;
  setVerifiedClicked: Dispatcher<boolean>;
  setUnverifiedClicked: Dispatcher<boolean>;
  selectedRow: any;
  investigationData: any;
  setInvestigationData: any;
  setSelectedRow: any;
  setPageLoading: any;
  navigate: NavigateFunction;
}) => {
  const filledButton = (
    <button
      className="shadow-button bg-[#D32F2F] border-2	border-[#D32F2F] rounded font-mulish text-white text-base p-1.5 w-[170px]"
      onClick={() => {
        setVerifiedClicked(false);
        setUnverifiedClicked(true);
        handleVerificationSelection(
          "Unverified",
          selectedRow,
          investigationData,
          setInvestigationData,
          setSelectedRow,
          setPageLoading,
          setVerifiedClicked,
          setUnverifiedClicked,
          navigate
        );
      }}
      disabled={false}
    >
      Unverified
    </button>
  );

  const unfilledButton = (
    <button
      className="shadow-button bg-white border-2	border-[#D32F2F] rounded font-mulish text-[#D32F2F] text-base p-1.5 w-[170px]"
      onClick={() => {
        setVerifiedClicked(false);
        setUnverifiedClicked(true);
        handleVerificationSelection(
          "Unverified",
          selectedRow,
          investigationData,
          setInvestigationData,
          setSelectedRow,
          setPageLoading,
          setVerifiedClicked,
          setUnverifiedClicked,
          navigate
        );
      }}
      disabled={false}
    >
      Unverified
    </button>
  );

  return unverifiedClicked ? filledButton : unfilledButton;
};
