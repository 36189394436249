import React from "react";
import { TextField } from "@mui/material";

export const MuiTextFieldType = ({
  id,
  defaultValue,
  value,
  onChange,
  helperText,
  stylingWidth,
  label,
  error,
}: {
  id: any;
  defaultValue: any;
  value: any;
  onChange: any;
  helperText: any;
  stylingWidth: any;
  label: any;
  error: any;
}) => {
  if (value) {
    return (
      <div key={id}>
        <TextField
          error={error}
          id={id}
          value={value}
          variant="standard"
          onChange={onChange}
          sx={{ width: stylingWidth }}
          helperText={helperText}
          type="string"
          label={label}
        />
      </div>
    );
  } else {
    return (
      <div key={id}>
        <TextField
          error={error}
          id={id}
          defaultValue={defaultValue}
          variant="standard"
          onChange={onChange}
          sx={{ width: stylingWidth }}
          helperText={helperText}
          type="string"
          label={label}
        />
      </div>
    );
  }
};
