import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getGroupUsersData = (
  setGroupUserData: any,
  groupUserData: any,
  setFetchingGroupUserData: Dispatcher<boolean>,
  pageNumber: number,
  searchString: string,
  setBackgroundLoadingData: Dispatcher<boolean>,
  setAlertState: any,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    getGroupUsersData(
      setGroupUserData,
      groupUserData,
      setFetchingGroupUserData,
      pageNumber,
      searchString,
      setBackgroundLoadingData,
      setAlertState,
      navigate
    );
  };

  let searchParam = `?`;

  if (searchString) {
    searchParam += `search=${searchString}&`;
  }

  searchParam += `page=${pageNumber}`;

  const AxiosUI = createApi(``);

  const allGroupUserData: any[] = groupUserData;

  AxiosUI.get(`group_user/${searchParam}`).then(
    (response: AxiosResponse) => {
      response.data.results.forEach((object: any) => {
        allGroupUserData.push(object);
      });
      if (!allGroupUserData[0]) {
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            noData: true,
            updateUserSuccess: false,
            deleteUserSuccess: false,
            addUserSuccess: false,
            emailSent: false,
          };
        });
      }
      setGroupUserData(allGroupUserData);
      setFetchingGroupUserData(false);
      if (response.data.next) {
        getGroupUsersData(
          setGroupUserData,
          groupUserData,
          setFetchingGroupUserData,
          (pageNumber += 1),
          searchString,
          setBackgroundLoadingData,
          setAlertState,
          navigate
        );
      } else {
        setBackgroundLoadingData(false);
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setFetchingGroupUserData(false);
      });
    }
  );
};
