import React from "react";
import { Dispatcher } from "./siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { siteArmButtonDisableCheckRefresh } from "./siteArmButtonDisableCheckRefresh";

export const checkArmedStatus = (
  siteInfo: any,
  setArmButtonDisabled: Dispatcher<boolean>,
  navigate: NavigateFunction,
  setSiteInfo: any,
  setArmedStatus: any
) => {
  if (
    !siteInfo.active ||
    siteInfo.armed_status === "DISARMING" ||
    siteInfo.armed_status === "ARMING"
  ) {
    setArmButtonDisabled(true);
    localStorage.setItem("armButtonCheckURL", window.location.pathname);
    siteArmButtonDisableCheckRefresh(
      siteInfo.id,
      setArmButtonDisabled,
      navigate,
      setSiteInfo,
      setArmedStatus
    );
  }
};
