import React from "react";
import { getAILinkClipsWithQuerry } from "./InvestigationUtilities/getAILinkClipsWithQuerry";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";

export const ApplyFiltersButton = ({
  setInvestigationData,
  setPageLoading,
  setSelectedRow,
  setPrecisionRecallData,
  setVideoURL,
  setVideoClipLoading,
  setClipsIndexOrder,
  setVerifiedClicked,
  setUnverifiedClicked,
  selectedRow,
  setNoDataToDisplay,
  setSiteOptions,
  setLoadingSites,
  userFilters,
  setFetchingFilteredData,
  setPage,
  setAiLinkClipsTotal,
  setLoadingAdditionalData,
  setFiltersApplied,
  navigate,
}: {
  setInvestigationData: any;
  setPageLoading: Dispatcher<boolean>;
  setSelectedRow: any;
  setPrecisionRecallData: any;
  setVideoURL: any;
  setVideoClipLoading: Dispatcher<boolean>;
  setClipsIndexOrder: any;
  setVerifiedClicked: Dispatcher<boolean>;
  setUnverifiedClicked: Dispatcher<boolean>;
  selectedRow: any;
  setNoDataToDisplay: Dispatcher<boolean>;
  setSiteOptions: any;
  setLoadingSites: any;
  userFilters: any;
  setFetchingFilteredData: Dispatcher<boolean>;
  setPage: any;
  setAiLinkClipsTotal: any;
  setLoadingAdditionalData: Dispatcher<boolean>;
  setFiltersApplied: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  return (
    <button
      className="shadow-button bg-actuate-orange border-2	border-actuate-orange rounded font-mulish text-white text-base p-1.5 w-[120px]"
      onClick={() => {
        setFiltersApplied(true);
        setLoadingAdditionalData(true);
        setAiLinkClipsTotal(0);
        setSelectedRow({});
        setNoDataToDisplay(true);
        setFetchingFilteredData(true);
        setInvestigationData([]);
        setPage(0);
        setPrecisionRecallData({
          date: "",
          date_str: "",
          true_positives: 0,
          false_positives: 0,
          false_negatives: 0,
          true_negatives: 0,
          unclassified: 0,
          total_clips: 0,
          precision: null,
          recall: null,
        });
        localStorage.setItem("last_file_id_ailink", "");
        localStorage.setItem("last_timestamp_ailink", "");
        getAILinkClipsWithQuerry(
          setInvestigationData,
          setPageLoading,
          setSelectedRow,
          setPrecisionRecallData,
          setVideoURL,
          setVideoClipLoading,
          setClipsIndexOrder,
          setVerifiedClicked,
          setUnverifiedClicked,
          selectedRow,
          setNoDataToDisplay,
          setSiteOptions,
          setLoadingSites,
          userFilters,
          setFetchingFilteredData,
          setPage,
          false,
          "",
          "",
          setLoadingAdditionalData,
          navigate
        );
      }}
      disabled={false}
    >
      Apply Filters
    </button>
  );
};
