import React from "react";
import { AlertsTableHealthMonitoring } from "../AlertsTableHealthMonitoring";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const handleSingleCameraGraphClick = (
  alertsData: any,
  selectedName: string,
  singleCellActivated: boolean,
  setActiveTable: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setFectchingAllData: any,
  setAlertsData: any,
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  clickedTab: any,
  setSortedData: any,
  setFilteredDataLengths: any,
  setUpdatingStatus: any,
  activeName: string,
  entryName: string,
  showSiteStats: boolean,
  filters: any,
  alertFilter: string,
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setSelectedRows: any,
  selectedRows: any,
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: any,
  cameraDropDownValue: any,
  navigate: any
) => {
  if (singleCellActivated || activeName !== entryName) {
    const updatedAlertsAllData: any = [];
    const updatedAlertsErrorsData: any = [];
    const updatedAlertsWarningsData: any = [];
    const updatedAlertsPendingData: any = [];
    const updatedAlertsResolvedData: any = [];

    if (!showSiteStats) {
      alertsData.all.forEach((object: any) => {
        if (object.camera_name === selectedName) {
          updatedAlertsAllData.push(object);
        }
      });

      alertsData.errors.forEach((object: any) => {
        if (object.camera_name === selectedName) {
          updatedAlertsErrorsData.push(object);
        }
      });
      alertsData.warnings.forEach((object: any) => {
        if (object.camera_name === selectedName) {
          updatedAlertsWarningsData.push(object);
        }
      });
      alertsData.pending.forEach((object: any) => {
        if (object.camera_name === selectedName) {
          updatedAlertsPendingData.push(object);
        }
      });
      alertsData.resolved.forEach((object: any) => {
        if (object.camera_name === selectedName) {
          updatedAlertsResolvedData.push(object);
        }
      });
    } else {
      alertsData.all.forEach((object: any) => {
        if (object.site_name === selectedName) {
          updatedAlertsAllData.push(object);
        }
      });

      alertsData.errors.forEach((object: any) => {
        if (object.site_name === selectedName) {
          updatedAlertsErrorsData.push(object);
        }
      });
      alertsData.warnings.forEach((object: any) => {
        if (object.site_name === selectedName) {
          updatedAlertsWarningsData.push(object);
        }
      });
      alertsData.pending.forEach((object: any) => {
        if (object.site_name === selectedName) {
          updatedAlertsPendingData.push(object);
        }
      });
      alertsData.resolved.forEach((object: any) => {
        if (object.site_name === selectedName) {
          updatedAlertsResolvedData.push(object);
        }
      });
    }

    setFilteredDataLengths({
      all: updatedAlertsAllData.length,
      errors: updatedAlertsErrorsData.length,
      warnings: updatedAlertsWarningsData.length,
      pending: updatedAlertsPendingData.length,
      resolved: updatedAlertsResolvedData.length,
    });

    setTimeout(() => {
      setActiveTable(
        <AlertsTableHealthMonitoring
          alertsData={alertsData}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={clickedTab}
          setActiveTable={setActiveTable}
          setSortedData={setSortedData}
          singleCellActivated={true}
          activeName={selectedName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          navigate={navigate}
        />
      );
    }, 1);
  } else {
    setActiveTable(
      <AlertsTableHealthMonitoring
        alertsData={alertsData}
        setAlertStatusDialogOpen={setAlertStatusDialogOpen}
        setSelectedRow={setSelectedRow}
        setSelectedRowIndex={setSelectedRowIndex}
        setFectchingAllData={setFectchingAllData}
        setAlertsData={setAlertsData}
        setUnhealthyCamsData={setUnhealthyCamsData}
        unhealthyCamsData={unhealthyCamsData}
        clickedTab={clickedTab}
        setActiveTable={setActiveTable}
        setSortedData={setSortedData}
        singleCellActivated={false}
        activeName={selectedName}
        setUpdatingStatus={setUpdatingStatus}
        filters={filters}
        alertFilter={alertFilter}
        setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
        setBackgroundFrame={setBackgroundFrame}
        setDetectedFrame={setDetectedFrame}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        setErrorsOverviewData={setErrorsOverviewData}
        setCameraStatsData={setCameraStatsData}
        setLoadingCameraStatsData={setLoadingCameraStatsData}
        setBandwidthData={setBandwidthData}
        setLoadingBandwidthData={setLoadingBandwidthData}
        setShowSiteStats={setShowSiteStats}
        cameraDropDownValue={cameraDropDownValue}
        setFilteredDataLengths={setFilteredDataLengths}
        navigate={navigate}
      />
    );
  }
};
