import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { getNumbersAfterActionLogs } from "./handleFetchActionLogsData";

export const handleGetMoreActionLogsData = (
  setActionLogsData: any,
  setLoadingMoreActionLogsData: Dispatcher<boolean>,
  paginationNumber: number,
  setPaginationNumber: Dispatcher<number>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleGetMoreActionLogsData(
      setActionLogsData,
      setLoadingMoreActionLogsData,
      paginationNumber,
      setPaginationNumber,
      navigate
    );
  };

  AxiosUI.get(
    `command_history/action_log/?customer=${getNumbersAfterActionLogs(
      location.href
    )}&page=${paginationNumber + 1}`
  ).then(
    (response: AxiosResponse) => {
      const results = response.data.results;
      setPaginationNumber(paginationNumber + 1);
      setActionLogsData((previousState: any) => [...previousState, ...results]);
      setLoadingMoreActionLogsData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error fetching more audit logs data, please try again.");
        setActionLogsData((previousState: any) => [...previousState]);
        setLoadingMoreActionLogsData(false);
      });
    }
  );
};
