import React from "react";
import { ChipMenu } from "../ChipMenu";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";

export const statusTypeCreator = (
  statusString: string,
  row: any,
  setFectchingAllData: Dispatcher<boolean>,
  setAlertsData: any,
  alertsData: any,
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  clickedTab: string,
  setActiveTable: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setSortedData: any,
  singleCellActivated: boolean,
  activeName: any,
  setUpdatingStatus: Dispatcher<boolean>,
  filters: any,
  alertFilter: any,
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setSelectedRows: any,
  selectedRows: any,
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: any,
  cameraDropDownValue: any,
  setFilteredDataLengths: any,
  navigate: NavigateFunction
) => {
  switch (statusString) {
    case "ongoing":
      return (
        <ChipMenu
          labelString="ongoing"
          row={row}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          alertsData={alertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={clickedTab}
          setActiveTable={setActiveTable}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setSortedData={setSortedData}
          singleCellActivated={singleCellActivated}
          activeName={activeName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          navigate={navigate}
        />
      );
    case "pending":
      return (
        <ChipMenu
          labelString="pending"
          row={row}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          alertsData={alertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={clickedTab}
          setActiveTable={setActiveTable}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setSortedData={setSortedData}
          singleCellActivated={singleCellActivated}
          activeName={activeName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          navigate={navigate}
        />
      );
    case "resolved":
      return (
        <div>Resolved</div>
        // <ChipMenu
        //   labelString="resolved"
        //   row={row}
        //   setFectchingAllData={setFectchingAllData}
        //   setAlertsData={setAlertsData}
        //   alertsData={alertsData}
        //   setUnhealthyCamsData={setUnhealthyCamsData}
        //   unhealthyCamsData={unhealthyCamsData}
        //   clickedTab={clickedTab}
        //   setActiveTable={setActiveTable}
        //   setAlertStatusDialogOpen={setAlertStatusDialogOpen}
        //   setSelectedRow={setSelectedRow}
        //   setSelectedRowIndex={setSelectedRowIndex}
        //   setSortedData={setSortedData}
        //   singleCellActivated={singleCellActivated}
        //   activeName={activeName}
        //   setUpdatingStatus={setUpdatingStatus}
        //   filters={filters}
        //   alertFilter={alertFilter}
        //   setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
        //   setBackgroundFrame={setBackgroundFrame}
        //   setDetectedFrame={setDetectedFrame}
        //   setSelectedRows={setSelectedRows}
        //   selectedRows={selectedRows}
        //   setErrorsOverviewData={setErrorsOverviewData}
        //   setCameraStatsData={setCameraStatsData}
        //   setLoadingCameraStatsData={setLoadingCameraStatsData}
        //   setBandwidthData={setBandwidthData}
        //   setLoadingBandwidthData={setLoadingBandwidthData}
        //   setShowSiteStats={setShowSiteStats}
        //   cameraDropDownValue={cameraDropDownValue}
        //   navigate={navigate}
        // />
      );
  }
};
