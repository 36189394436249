import React from "react";
import { NavBar } from "../../common/NavBar";
import { getCaledarsData } from "./CalendarsUtils/getCaledarsData";
import { useNavigate } from "react-router-dom";
import { CalendarsSearchBar } from "./CalendarsSearchBar";
import { SearchButtonCalendars } from "./SearchButtonCalendars";
import { CalendarsTable } from "./CalendarsTable";
import { AddCalendarButton } from "./AddCalendarButton";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { ShowMoreDialogCalendars } from "./ShowMoreDialogCalendars";
import { AddDateDialog } from "./AddDateDialog";
import { Backdrop } from "@mui/material";
import { EditEventDialog } from "./EditEventDialog";
import { AddCalendarDialog } from "./AddCalendarDialog";
import { CalendarAlerts } from "./CalendarAlerts";
import { DeleteCalendarDialog } from "./DeleteCalendarDialog";
import { EditCalendarDialog } from "./EditCalendarDialog";
import { AssignedSitesDialog } from "./AssignedSitesDialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { AddCalendarScreen } from "./AddCalendarScreen";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Calendars = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [calendarData, setCalendarData] = React.useState([]);

  const [calendarsSearchString, setCalendarsSearchString] = React.useState("");

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const [fetchingCalendarData, setFetchingCalendarData] = React.useState(true);

  const [showMoreDialogOpen, setShowMoreDialogOpen] = React.useState(false);

  const [loadingEventData, setLoadingEventData] = React.useState(false);

  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const [calendarEvents, setCalendarEvents] = React.useState([]);

  const [calendarId, setCalendarId] = React.useState<any>("");

  const [addDateDialogOpen, setAddDateDialogOpen] = React.useState(false);

  const [editingCalendar, setEditingCalendar] = React.useState(false);

  const [patchEvent, setPatchEvent] = React.useState({});

  const [editEventDialogOpen, setEditEventDialogOpen] = React.useState(false);

  const [addCalendarDialogOpen, setAddCalendarDialogOpen] =
    React.useState(false);

  const [deleteCalendarDialogOpen, setDeleteCalendarDialogOpen] =
    React.useState(false);

  const [deletedCalendarId, setDeletedCalendarId] = React.useState<any>("");

  const [editCalendarDialogOpen, setEditCalendarDialogOpen] =
    React.useState(false);

  const [rowIndex, setRowIndex] = React.useState<any>("");

  const [addCalendarScreenOpen, setAddCalendarScreenOpen] =
    React.useState(false);

  const [calendarAlerts, setCalendarAlerts] = React.useState({
    addCalendarSuccess: false,
    deleteCalendarSuccess: false,
    addSitesToCalendarSuccess: false,
  });

  const [assignedSitesDialogOpen, setAssignedSitesDialogOpen] =
    React.useState(false);

  const [updatingSites, setUpdatingSites] = React.useState(false);

  const [backButtonHidden, setBackButtonHidden] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    getCaledarsData(
      setCalendarData,
      setFetchingCalendarData,
      calendarsSearchString,
      setPage,
      setShowMoreDialogOpen,
      setSelectedRow,
      navigate
    );
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = calendarData.map((obj: any) => obj.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSingleCheckboxClick = (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  return (
    <div>
      <CalendarAlerts
        calendarAlerts={calendarAlerts}
        setCalendarAlerts={setCalendarAlerts}
      />
      <div className="bg-actuate-grey absolute w-screen h-screen">
        <BackdropLoadingScreen openState={fetchingCalendarData} />
        <BackdropLoadingScreen openState={updatingSites} />
        {addCalendarScreenOpen && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={addCalendarScreenOpen}
            >
              <AddCalendarScreen
                setAddCalendarScreenOpen={setAddCalendarScreenOpen}
                calendarData={calendarData}
                setCalendarData={setCalendarData}
                setCalendarAlerts={setCalendarAlerts}
                backButtonHidden={backButtonHidden}
                setBackButtonHidden={setBackButtonHidden}
              />
            </Backdrop>
          </div>
        )}
        {showMoreDialogOpen && (
          <div className="absolute">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showMoreDialogOpen}
            >
              <ShowMoreDialogCalendars
                setShowMoreDialogOpen={setShowMoreDialogOpen}
                selectedRow={selectedRow}
                calendarEvents={calendarEvents}
                setCalendarEvents={setCalendarEvents}
                setAddDateDialogOpen={setAddDateDialogOpen}
                editingCalendar={editingCalendar}
                loadingEventData={loadingEventData}
                setEditingCalendar={setEditingCalendar}
                setPatchEvent={setPatchEvent}
                setEditEventDialogOpen={setEditEventDialogOpen}
                setEditCalendarDialogOpen={setEditCalendarDialogOpen}
              />
            </Backdrop>
          </div>
        )}
        {addDateDialogOpen && (
          <AddDateDialog
            addDateDialogOpen={addDateDialogOpen}
            setAddDateDialogOpen={setAddDateDialogOpen}
            setCalendarEvents={setCalendarEvents}
            calendarId={calendarId}
            setShowMoreDialogOpen={setShowMoreDialogOpen}
            setEditingCalendar={setEditingCalendar}
            navigate={navigate}
          />
        )}
        {editEventDialogOpen && (
          <EditEventDialog
            patchEvent={patchEvent}
            editEventDialogOpen={editEventDialogOpen}
            setEditEventDialogOpen={setEditEventDialogOpen}
            setEditingCalendar={setEditingCalendar}
            setCalendarEvents={setCalendarEvents}
            navigate={navigate}
          />
        )}
        {addCalendarDialogOpen && (
          <AddCalendarDialog
            addCalendarDialogOpen={addCalendarDialogOpen}
            setAddCalendarDialogOpen={setAddCalendarDialogOpen}
            setFetchingCalendarData={setFetchingCalendarData}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
            setCalendarAlerts={setCalendarAlerts}
            calendarsSearchString={calendarsSearchString}
            navigate={navigate}
          />
        )}
        {deleteCalendarDialogOpen && (
          <DeleteCalendarDialog
            deleteCalendarDialogOpen={deleteCalendarDialogOpen}
            setDeleteCalendarDialogOpen={setDeleteCalendarDialogOpen}
            deletedCalendarId={deletedCalendarId}
            setFetchingCalendarData={setFetchingCalendarData}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
            setCalendarAlerts={setCalendarAlerts}
            navigate={navigate}
          />
        )}
        {editCalendarDialogOpen && (
          <EditCalendarDialog
            selectedRow={selectedRow}
            editCalendarDialogOpen={editCalendarDialogOpen}
            setEditCalendarDialogOpen={setEditCalendarDialogOpen}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
            setCalendarAlerts={setCalendarAlerts}
            rowIndex={rowIndex}
            setSelectedRow={setSelectedRow}
            setEditingCalendar={setEditingCalendar}
            navigate={navigate}
          />
        )}
        {assignedSitesDialogOpen && (
          <AssignedSitesDialog
            groupId={selectedRow.group.id}
            siteArray={selectedRow.customer}
            assignedSitesDialogOpen={assignedSitesDialogOpen}
            setAssignedSitesDialogOpen={setAssignedSitesDialogOpen}
            setUpdatingSites={setUpdatingSites}
            selectedRow={selectedRow}
            setCalendarAlerts={setCalendarAlerts}
            setCalendarData={setCalendarData}
            calendarData={calendarData}
            Transition={Transition}
            navigate={navigate}
          />
        )}
        <div
          className={
            !navBarCollapsed
              ? "absolute top-[60px] left-[150px] right-[1px] h-[19px]] bg-white"
              : "absolute top-[60px] left-[75px] right-[1px] h-[19px]] bg-white"
          }
        >
          <p className="font-black font-mulish text-[20px] p-3 indent-6">
            Calendars
          </p>
          <div className="absolute top-[7px] right-[30px]">
            <AddCalendarButton
              setAddCalendarScreenOpen={setAddCalendarScreenOpen}
            />
          </div>
        </div>
        <div
          className={
            !navBarCollapsed
              ? "absolute left-[135px] top-[10px]"
              : "absolute left-[60px] top-[10px]"
          }
        >
          <CalendarsSearchBar
            setCalendarsSearchString={setCalendarsSearchString}
          />
        </div>
        <div
          className={
            !navBarCollapsed
              ? "absolute left-[500px] top-[10px]"
              : "absolute left-[425px] top-[10px]"
          }
        >
          <SearchButtonCalendars
            setFetchingCalendarData={setFetchingCalendarData}
            setCalendarData={setCalendarData}
            calendarsSearchString={calendarsSearchString}
            setPage={setPage}
            setShowMoreDialogOpen={setShowMoreDialogOpen}
            setSelectedRow={setSelectedRow}
            navigate={navigate}
          />
        </div>
        {!fetchingCalendarData && (
          <div
            className={
              !navBarCollapsed
                ? "absolute left-[150px] top-[130px]"
                : "absolute left-[75px] top-[130px]"
            }
          >
            <CalendarsTable
              calendarData={calendarData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleSelectAllClick={handleSelectAllClick}
              isSelected={isSelected}
              handleSingleCheckboxClick={handleSingleCheckboxClick}
              setSelectedRow={setSelectedRow}
              setShowMoreDialogOpen={setShowMoreDialogOpen}
              setLoadingEventData={setLoadingEventData}
              setCalendarEvents={setCalendarEvents}
              setCalendarId={setCalendarId}
              setDeleteCalendarDialogOpen={setDeleteCalendarDialogOpen}
              setDeletedCalendarId={setDeletedCalendarId}
              setRowIndex={setRowIndex}
              setAssignedSitesDialogOpen={setAssignedSitesDialogOpen}
              navigate={navigate}
            />
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
