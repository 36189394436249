export const createLinksArray = (pushedArray: any) => {
  if (pushedArray === undefined) {
    return;
  }
  const linksArray = [{ name: "Sites" }];
  if (pushedArray[0]) {
    pushedArray.forEach((object: any) => {
      linksArray.push({ name: object.name });
    });
  }
  return linksArray;
};
