import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getPreviewURL } from "./getPreviewURL";
import { getImageURLS } from "./getImageURLS";

export const handleOpenAlertsDialogFromSearchParams = (
  setSearchParams: any,
  setAlertStatusDialogOpen: any,
  activeData: any,
  setSelectedRowIndex: Dispatcher<number>,
  setSelectedRow: Dispatcher<any>,
  setNotesValue: Dispatcher<string>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setNoAlertsDialogOpen: Dispatcher<boolean>,
  setClickedTab: Dispatcher<string>,
  navigate: any
) => {
  const params = new URLSearchParams(window.location.search);
  const openAlert = params.get("open_alert");

  if (openAlert) {
    const [cameraId, errorType, createdTimestamp] = openAlert.split("-");

    let activeIndex = -1;

    let alertFound = false;

    let alertFoundResolved = false;

    activeData.all.forEach((chmAlert: any, index: number) => {
      if (
        Number(chmAlert.camera_id) === Number(cameraId) &&
        chmAlert.error_type === errorType &&
        Number(chmAlert.created_timestamp) === Number(createdTimestamp)
      ) {
        alertFound = true;
        activeIndex = index;
        getPreviewURL(
          activeData.all[index].camera_id,
          () => {},
          () => {},
          navigate
        );
        setBackgroundFrame("");
        setDetectedFrame("");
        if (activeData.all[index].error_type === "scene_change") {
          getImageURLS(
            activeData.all[index],
            setBackgroundFrame,
            setDetectedFrame,
            navigate
          );
        }
        setSelectedRowIndex(index);
        setSelectedRow(activeData.all[index]);
        setNotesValue(activeData.all[index].notes);
        setAlertStatusDialogOpen(true);
      }
      if (index === activeData.all.length - 1 && !alertFound) {
        activeData.resolved.forEach((chmAlert: any, index: number) => {
          if (
            Number(chmAlert.camera_id) === Number(cameraId) &&
            chmAlert.error_type === errorType &&
            Number(chmAlert.created_timestamp) === Number(createdTimestamp)
          ) {
            alertFoundResolved = true;
            activeIndex = index;
            getPreviewURL(
              activeData.resolved[index].camera_id,
              () => {},
              () => {},
              navigate
            );
            setBackgroundFrame("");
            setDetectedFrame("");
            if (activeData.resolved[index].error_type === "scene_change") {
              getImageURLS(
                activeData.resolved[index],
                setBackgroundFrame,
                setDetectedFrame,
                navigate
              );
            }
            setSelectedRowIndex(index);
            setSelectedRow(activeData.resolved[index]);
            setNotesValue(activeData.resolved[index].notes);
            setAlertStatusDialogOpen(true);
          }
          if (index === activeData.resolved.length - 1 && !alertFoundResolved) {
            setNoAlertsDialogOpen(true);
          }
          if (index === activeData.resolved.length - 1 && alertFoundResolved) {
            setClickedTab("resolved");
          }
        });
      }
    });
    if (activeIndex === -1) {
      params.delete("open_alert");
      setSearchParams(params);
    }
  }
};
