import React from "react";

export const handleCheckForDisabledProducts = (
  productsData: any,
  setSelectedGroups: any
) => {
  const productsActive = {
    intruder: false,
    bike: false,
    vehicle: false,
    intruder_plus: false,
    bike_plus: false,
    vehicle_plus: false,
  };

  productsData.forEach((product: any) => {
    const metrics: any = product.metrics;
    if (product.name === "Intruder" || product.name === "Intruder Plus") {
      for (const key in metrics) {
        if (key === "intruder") {
          productsActive.intruder = metrics[key].active;
        } else if (key === "intruder_plus") {
          productsActive.intruder_plus = metrics[key].active;
        } else if (key === "bike") {
          productsActive.bike = metrics[key].active;
        } else if (key === "bike_plus") {
          productsActive.bike_plus = metrics[key].active;
        } else if (key === "vehicle") {
          productsActive.vehicle = metrics[key].active;
        } else if (key === "vehicle_plus") {
          productsActive.vehicle_plus = metrics[key].active;
        }
      }
      // metrics.forEach((metric: any) => {
      //   if (metric.name === "intruder") {
      //     productsActive.intruder = metric.active;
      //   } else if (metric.name === "intruder_plus") {
      //     productsActive.intruder_plus = metric.active;
      //   } else if (metric.name === "bike") {
      //     productsActive.bike = metric.active;
      //   } else if (metric.name === "bike_plus") {
      //     productsActive.bike_plus = metric.active;
      //   } else if (metric.name === "vehicle") {
      //     productsActive.vehicle = metric.active;
      //   }
      // });
    }
  });

  if (
    (productsActive.intruder ||
      productsActive.bike ||
      productsActive.vehicle) &&
    !productsActive.intruder_plus &&
    !productsActive.bike_plus &&
    !productsActive.vehicle_plus
  ) {
    setSelectedGroups((previousState: any) => {
      const updatedSelectedGroups = { ...previousState };
      updatedSelectedGroups.intruderPlus.disabled = true;
      updatedSelectedGroups.intruder.intruder = productsActive.intruder;
      updatedSelectedGroups.intruder.bike = productsActive.bike;
      updatedSelectedGroups.intruder.vehicle = productsActive.vehicle;
      return updatedSelectedGroups;
    });
  }
  if (
    (productsActive.intruder_plus ||
      productsActive.bike_plus ||
      productsActive.vehicle_plus) &&
    !productsActive.intruder &&
    !productsActive.bike &&
    !productsActive.vehicle
  ) {
    setSelectedGroups((previousState: any) => {
      const updatedSelectedGroups = { ...previousState };
      updatedSelectedGroups.intruder.disabled = true;
      updatedSelectedGroups.intruderPlus.intruderPlus =
        productsActive.intruder_plus;
      updatedSelectedGroups.intruderPlus.bikePlus = productsActive.bike_plus;
      updatedSelectedGroups.intruderPlus.vehiclePlus =
        productsActive.vehicle_plus;
      return updatedSelectedGroups;
    });
  }
};
