import { IntegerFieldType } from "../../common/onBoardingWizardFieldTypes/integerFieldType";
import { StringFieldType } from "../../common/onBoardingWizardFieldTypes/stringFieldType";
import { PasswordFieldType } from "../../common/onBoardingWizardFieldTypes/passwordFieldType";
import { ChoiceFieldType } from "../../common/onBoardingWizardFieldTypes/choiceFieldType";
import { RadioFieldType } from "../../common/onBoardingWizardFieldTypes/radioFieldType";
import { MultipleChoiceFieldType } from "../../common/onBoardingWizardFieldTypes/multiChoiceFieldType";
import { TextAreaFieldType } from "../../common/onBoardingWizardFieldTypes/textAreaFieldType";
import { CheckBoxFieldType } from "../../common/onBoardingWizardFieldTypes/checkBoxFieldType";
import { Dispatcher } from "../../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const RecursiveContainer = ({
  config,
  formik,
  setErrorState,
  choicesChanger,
  setChoicesChanger,
  stepNumber,
  setHealthCheckSelected,
  healthCheckSelected,
  setActiveWizardContainer,
  handleSubmitSite,
  responseObject,
  setVisibleScreen,
}: {
  config: any;
  formik: any;
  setErrorState: any;
  choicesChanger: any;
  setChoicesChanger: any;
  stepNumber: any;
  setHealthCheckSelected: Dispatcher<boolean>;
  healthCheckSelected: boolean;
  setActiveWizardContainer: any;
  handleSubmitSite: any;
  responseObject: any;
  setVisibleScreen: any;
}) => {
  const builder = (individualConfig: any) => {
    switch (individualConfig.type) {
      case "integer":
        return (
          <>
            <IntegerFieldType
              config={individualConfig}
              formik={formik}
              setErrorState={setErrorState}
              stepNumber={stepNumber}
            />
            <br></br>
          </>
        );
      case "string":
        return (
          <>
            <StringFieldType
              config={individualConfig}
              formik={formik}
              setErrorState={setErrorState}
              stepNumber={stepNumber}
            />
            <br></br>
          </>
        );
      case "password":
        return (
          <>
            <PasswordFieldType
              config={individualConfig}
              formik={formik}
              setErrorState={setErrorState}
              stepNumber={stepNumber}
            />
            <br></br>
          </>
        );
      case "choice":
        return (
          <>
            <ChoiceFieldType
              config={individualConfig}
              formik={formik}
              setErrorState={setErrorState}
              setChoicesChanger={setChoicesChanger}
              choicesChanger={choicesChanger}
            />
            <br></br>
          </>
        );
      case "checkbox":
        formik.values[individualConfig.name] = false;
        return (
          <>
            <CheckBoxFieldType config={individualConfig} formik={formik} />
            <br></br>
          </>
        );
      case "radio":
        return (
          <>
            <RadioFieldType config={individualConfig} formik={formik} />
            <br></br>
          </>
        );
      case "textarea":
        return (
          <>
            <TextAreaFieldType
              config={individualConfig}
              formik={formik}
              setErrorState={setErrorState}
            />
            <br></br>
          </>
        );
      case "multiChoice":
        return (
          <>
            <MultipleChoiceFieldType
              config={individualConfig}
              formik={formik}
              setErrorState={setErrorState}
              setChoicesChanger={setChoicesChanger}
              choicesChanger={choicesChanger}
              setHealthCheckSelected={setHealthCheckSelected}
              setActiveWizardContainer={setActiveWizardContainer}
              handleSubmitSite={handleSubmitSite}
              responseObject={responseObject}
              setVisibleScreen={setVisibleScreen}
            />
            <br></br>
          </>
        );
      case "array":
        return (
          <RecursiveContainer
            config={individualConfig.children || []}
            formik={formik}
            setErrorState={setErrorState}
            choicesChanger={choicesChanger}
            setChoicesChanger={setChoicesChanger}
            stepNumber={stepNumber}
            setHealthCheckSelected={setHealthCheckSelected}
            healthCheckSelected={healthCheckSelected}
            setActiveWizardContainer={setActiveWizardContainer}
            handleSubmitSite={handleSubmitSite}
            responseObject={responseObject}
            setVisibleScreen={setVisibleScreen}
          />
        );
      default:
        return <div>Unsupported field</div>;
    }
  };

  return (
    <div>
      {config.map((c: any) => {
        if (c.label === "Alert emails") {
          if (healthCheckSelected) {
            return builder(c);
          }
        } else if (c.label === "Report emails") {
          if (healthCheckSelected) {
            return builder(c);
          }
          return;
        } else {
          return builder(c);
        }
      })}
    </div>
  );
};

export default RecursiveContainer;
