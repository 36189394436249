import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { sortHighToLow } from "./HealthMonitoringUtils/sortHighToLow";
import { sortLowToHigh } from "./HealthMonitoringUtils/sortLowToHigh";
import { sortAtoZ } from "./HealthMonitoringUtils/sortAtoZ";
import { sortZtoA } from "./HealthMonitoringUtils/sortZtoA";

export const SortingMenu = ({
  currentSort,
  setCurrentSort,
  setSortedData,
  volumeSortString,
  nameSortString,
  data,
}: {
  currentSort: string;
  setCurrentSort: Dispatcher<string>;
  setSortedData: any;
  volumeSortString: string;
  nameSortString: string;
  data: any;
}) => {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <Button
            variant="outlined"
            color="warning"
            sx={{ minWidth: 200 }}
            {...bindTrigger(popupState)}
          >
            {currentSort}
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              id="high_to_low_item"
              onClick={() => {
                popupState.close;
                setCurrentSort(`Volume (High to Low)`);
                setSortedData(sortHighToLow(data, volumeSortString));
              }}
            >{`Volume (High to Low)`}</MenuItem>
            <MenuItem
              id="low_to_high_item"
              onClick={() => {
                popupState.close;
                setCurrentSort(`Volume (Low to High)`);
                setSortedData(sortLowToHigh(data, volumeSortString));
              }}
            >{`Volume (Low to High)`}</MenuItem>
            <MenuItem
              id="a_to_z_item"
              onClick={() => {
                popupState.close;
                setCurrentSort(`Site Name (A to Z)`);
                setSortedData(sortAtoZ(data, nameSortString));
              }}
            >{`Site Name (A to Z)`}</MenuItem>
            <MenuItem
              id="z_to_a_item"
              onClick={() => {
                popupState.close;
                setCurrentSort(`Site Name (Z to A)`);
                setSortedData(sortZtoA(data, nameSortString));
              }}
            >{`Site Name (Z to A)`}</MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};
