import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleGetEventData = (
  setCalendarEvents: any,
  setLoadingEventData: Dispatcher<boolean>,
  selectedRow: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleGetEventData(
      setCalendarEvents,
      setLoadingEventData,
      selectedRow,
      navigate
    );
  };

  AxiosUI.get(`calendar_event/?calendar__id=${selectedRow.id}`).then(
    (response: AxiosResponse) => {
      setCalendarEvents(response.data.results);
      setLoadingEventData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setLoadingEventData(false);
      });
    }
  );
};
