import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handleDeleteSite = async (
  id: number,
  navigate: any,
  setBackdropLoadingScreenOn: any
) => {
  const AxiosUI = createApi(``);
  setBackdropLoadingScreenOn(true);
  const retryFunction = () => {
    handleDeleteSite(id, navigate, setBackdropLoadingScreenOn);
  };
  await AxiosUI.delete(`customer/${id}/`).then(
    async () => {
      await AxiosUI.get("group/tree/?page_size=1000").then(
        (response: AxiosResponse) => {
          localStorage.setItem("siteDeletedAlert", "true");
          localStorage.setItem("siteJustDeleted", "true");
          localStorage.setItem(
            "groupTrees",
            JSON.stringify(response.data.results)
          );
          setBackdropLoadingScreenOn(false);
          navigate("/sites");
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        alert(`Error deleting site, please try again.`);
      });
    }
  );
};
