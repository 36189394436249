import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleRowSelected = (
  row: any,
  setSelectedRow: any,
  setVideoURL: Dispatcher<string>,
  setVideoClipLoading: Dispatcher<boolean>,
  investigationData: any,
  setInvestigationData: any,
  selectedRow: any,
  userFilters: any,
  tabString: string,
  username: string,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleRowSelected(
      row,
      setSelectedRow,
      setVideoURL,
      setVideoClipLoading,
      investigationData,
      setInvestigationData,
      selectedRow,
      userFilters,
      tabString,
      username,
      navigate
    );
  };

  const newInvestigationData = investigationData;

  newInvestigationData.forEach((object: any) => {
    object.selected = false;
  });

  newInvestigationData.forEach((object: any, index: number) => {
    if (row.file_id === object.file_id) {
      newInvestigationData[index].selected = true;
    }
  });

  setInvestigationData(newInvestigationData);

  AxiosUI.get(`clip/info/?username=${username}&file_id=${row.file_id}`).then(
    (response: AxiosResponse) => {
      if (response.data.file_id) {
        for (const key in response.data) {
          setSelectedRow((previousState: any) => {
            return {
              ...previousState,
              [key]: response.data[key],
            };
          });
        }
        localStorage.setItem(
          "investigationsSelectedRow",
          JSON.stringify(selectedRow)
        );
        AxiosUI.get(
          `clip/url/?username=${userFilters.user}&file_id=${response.data.file_id}`
        ).then(
          (response: AxiosResponse) => {
            setVideoURL(response.data);
            setVideoClipLoading(false);
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              setVideoClipLoading(false);
            });
          }
        );
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setVideoClipLoading(false);
      });
    }
  );
};
