import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "./siteAboutInterfaces";
import { CameraHealthTabEditMode } from "../CameraHealthTabEditMode";
import { CameraHealthTab } from "../CameraHealthTab";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handleTurnOffCameraHealth = (
  healthCheckData: any,
  cameraHealthPatchObject: any,
  setCameraHealthPatchObject: any,
  setCameraHealthChangesMade: Dispatcher<boolean>,
  setActiveTab: any,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  siteInfo: any,
  setAddCameraHealthDialogOpen: Dispatcher<boolean>,
  setSiteInfo: any,
  setTurnOffHealthDialogOpen: Dispatcher<boolean>,
  setEditMode: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleTurnOffCameraHealth(
      healthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      setActiveTab,
      setBackdropLoadingScreenOn,
      siteInfo,
      setAddCameraHealthDialogOpen,
      setSiteInfo,
      setTurnOffHealthDialogOpen,
      setEditMode,
      navigate
    );
  };

  AxiosUI.patch(`/healthcheck/by_customer/${localStorage.siteId}/`, {
    chm: false,
  }).then(
    () => {
      const newSiteInfo = siteInfo;
      newSiteInfo.chm = false;
      setEditMode(false);
      setActiveTab(<></>);
      setSiteInfo((previousState: any) => {
        return { ...previousState, chm: false };
      });
      setTimeout(() => {
        setActiveTab(
          <CameraHealthTab
            healthCheckData={cameraHealthPatchObject}
            siteInfo={newSiteInfo}
          />
        );
      }, 1);
      setBackdropLoadingScreenOn(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setActiveTab(<></>);
        setTimeout(() => {
          setActiveTab(
            <CameraHealthTabEditMode
              healthCheckData={healthCheckData}
              setCameraHealthPatchObject={setCameraHealthPatchObject}
              setCameraHealthChangesMade={setCameraHealthChangesMade}
              siteInfo={siteInfo}
              setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
              setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
            />
          );
        }, 1);
        alert("Error turning off Camera Health, please try again.");
        setBackdropLoadingScreenOn(false);
      });
    }
  );
};
