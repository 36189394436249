import React from "react";
import { createApi } from "../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
// import { getAllResourcesExceptSelectedProducts } from "../../utils/dataFetchersNoSelectedProducts";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { ProgressFinishedScreen } from "./ProgressFinishedScreen";
import { ScheduleScreen } from "./ScheduleScreen";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../Onboarding/OnboardingProgressDiv";

export const handleSubmitSchedule = async (
  submittedArray: any,
  setVisibleScreen: any,
  visibleScreen: any,
  setLargerScreen: any,
  navigate: any,
  navigateSitesPage: any,
  selectedCalendars: number[]
) => {
  const retryFunction = () => {
    handleSubmitSchedule(
      submittedArray,
      setVisibleScreen,
      visibleScreen,
      setLargerScreen,
      navigate,
      navigateSitesPage,
      selectedCalendars
    );
  };

  const resizeScreenPlusSetVisibleScreen = () => {
    setLargerScreen(true);
    setVisibleScreen(<ProgressFinishedScreen />);
  };

  const setTimeoutAndNavigate = () => {
    setTimeout(() => {
      setVisibleScreen(
        <StatusNotificationScreen
          styling="absolute first-line:font-black font-mulish text-[20px] right-[170px] text-center text-[#283E6D] top-80"
          text="Schedule Added successfully!"
        />
      );
      setTimeout(() => {
        localStorage.addAnotherScheduleScreen === "true"
          ? navigateSitesPage()
          : validIntegrationTypes.includes(localStorage.selectedIntegrationType)
          ? navigate(`/sites/cameracheck`)
          : resizeScreenPlusSetVisibleScreen();
      }, 2000);
    }, 2000);
  };

  setVisibleScreen(
    <StatusNotificationScreen
      styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
      text={
        <p>
          We&apos;re submitting this information.
          <br />
          This might take a few seconds.
        </p>
      }
    />
  );
  const AxiosUI = createApi(``);
  let submitScheduleFail = false;
  let setInfoFetchFail = false;
  let siteCamerasFetchFail = false;
  await AxiosUI.post(
    `schedule/`,
    submittedArray.filter((schedule: any) => schedule.start_time !== ":00")
  ).then(
    () => {
      submitScheduleFail = false;
      localStorage.removeItem("addScheduleError");
      if (selectedCalendars[0]) {
        let anyFailures = false;
        selectedCalendars.forEach((calendarId, index) => {
          AxiosUI.post(`calendar/${calendarId}/apply/`, {
            customer: [Number(localStorage.siteId)],
          }).then(
            () => {
              if (index === selectedCalendars.length - 1) {
                setTimeoutAndNavigate();
                if (anyFailures) {
                  alert(
                    "One or more calendars failed adding to the site.  Please try to add again on Calendars page."
                  );
                }
              }
            },
            () => {
              anyFailures = true;
              if (index === selectedCalendars.length - 1) {
                setTimeoutAndNavigate();
                alert(
                  "One or more calendars failed adding to the site.  Please try to add again on Calendars page."
                );
              }
            }
          );
        });
      } else {
        setTimeoutAndNavigate();
      }
      // getAllResourcesExceptSelectedProducts();
    },
    (reason: AxiosError) => {
      submitScheduleFail = true;
      if (
        reason.request.response.includes(
          "overlaps with existing enabled schedule"
        )
      ) {
        alert(
          `Schedule Changes not accepted: One or more days overlaps with day(s) from another scheulde at this Site.`
        );
        localStorage.setItem("addScheduleError", "true");
        setLargerScreen(true);
        setVisibleScreen(
          <ScheduleScreen
            setVisibleScreen={setVisibleScreen}
            visibleScreen={visibleScreen}
            setLargerScreen={setLargerScreen}
          />
        );
      } else {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          localStorage.setItem("addScheduleError", "true");
          setLargerScreen(true);
          setVisibleScreen(
            <ScheduleScreen
              setVisibleScreen={setVisibleScreen}
              visibleScreen={visibleScreen}
              setLargerScreen={setLargerScreen}
            />
          );
        });
      }
    }
  );
  !submitScheduleFail &&
    (await AxiosUI.get(`customer/${localStorage.siteId}/about/`).then(
      (response: AxiosResponse) => {
        setInfoFetchFail = false;
        localStorage.setItem("siteInfo", JSON.stringify(response.data));
      },
      (reason: AxiosError) => {
        setInfoFetchFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !submitScheduleFail &&
    !setInfoFetchFail &&
    (await AxiosUI.get(`camera/site/?customer__id=${localStorage.siteId}`).then(
      (response: AxiosResponse) => {
        siteCamerasFetchFail = false;
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
      },
      (reason: AxiosError) => {
        siteCamerasFetchFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !submitScheduleFail &&
    !setInfoFetchFail &&
    !siteCamerasFetchFail &&
    (await AxiosUI.get(`schedule/by_customer/${localStorage.siteId}/`).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteSchedule", JSON.stringify(response.data));
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
