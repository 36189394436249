import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { locationsMatcher } from "../../../utils/locationsMatcher";
import { TableHeadComponent } from "../../../common/TableHead";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { ScheduleGrid } from "./ScheduleGrid";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  SiteSchedule,
  SiteInfo,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import EditIcon from "@mui/icons-material/Edit";
import { handleCreateInitialTimeSchedules } from "./SiteAboutPageUtils/handleCreateInitialTimeSchedules";
import { handleConvertSchedulesToBooleanArrays } from "./SiteAboutPageUtils/handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "./SiteAboutPageUtils/handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";
import { setSeconds } from "date-fns";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const SchedulesTab = ({
  allSiteSchedules,
  siteInfo,
  setDeleteScheduleDialogOpen,
}: {
  allSiteSchedules: SiteSchedule[];
  siteInfo: SiteInfo;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
}) => {
  const label = { inputProps: { "aria-label": "Checkbox label" } };
  const disableCheckedCheckbox = <Checkbox {...label} disabled checked />;
  const disableUncheckedCheckbox = <Checkbox {...label} disabled />;
  const delteScheduleButtonLabel = (
    <>
      <CancelIcon />
      &nbsp;Delete Schedule
    </>
  );

  const [timeRanges, setTimeRanges] = React.useState(
    handleCreateInitialTimeSchedules(allSiteSchedules)
  );

  const [grid, setGrid] = React.useState(
    handleConvertSchedulesToBooleanArrays(allSiteSchedules)
  );

  const [duskToDawn, setDuskToDawn] = React.useState(
    handleCreateDuskToDawn(allSiteSchedules)
  );

  const [duskToDawnLocation, setDuskToDawnLocation] = React.useState(
    handleCreateDuskToDawnLocationPlusBuffer(allSiteSchedules)
  );

  return (
    <div>
      <div className="relative top-[0px] h-[440px] bg-white overflow-scroll">
        <div className="flex">
          <p className="font-black font-mulish text-[20px] p-3 indent-4">
            Schedule Overview &nbsp;
            <Tooltip
              title={`Times shows are in ${siteInfo.timezone} time. Scheduled arming time ranges are highlighted in green.`}
              placement="top"
              sx={{ fontSize: "15px", fontFamily: "mulish" }}
            >
              <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
            </Tooltip>
          </p>
        </div>
        <div className="relative top-[0px] left-[15px] w-[1500px]">
          <ScheduleGrid
            editable={false}
            setSelectedDayInfo={() => {}}
            setEditTimeScheduleDialogOpen={() => {}}
            timeRanges={timeRanges}
            setTimeRanges={setTimeRanges}
            grid={grid}
            setGrid={setGrid}
            duskToDawn={duskToDawn}
            setDuskToDawn={setDuskToDawn}
            duskToDawnLocation={duskToDawnLocation}
            setDuskToDawnLocation={setDuskToDawnLocation}
            setMatchingSchedules={() => {}}
            setScheduleChangesMade={() => {}}
            setAllSiteSchedules={() => {}}
          />
        </div>
      </div>
    </div>
  );
};
