import React from "react";
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getSitesByParentGroupAlerts } from "../Alerts/AlertsUtils/getSitesByParentGroupAlerts";
import { handleUpdateAssignedSites } from "./CalendarsUtils/handleUpdateAssignedSites";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const AssignedSitesDialog = ({
  groupId,
  siteArray,
  assignedSitesDialogOpen,
  setAssignedSitesDialogOpen,
  setUpdatingSites,
  selectedRow,
  setCalendarAlerts,
  setCalendarData,
  calendarData,
  Transition,
  navigate,
}: {
  groupId: any;
  siteArray: any;
  assignedSitesDialogOpen: any;
  setAssignedSitesDialogOpen: any;
  setUpdatingSites: Dispatcher<boolean>;
  selectedRow: any;
  setCalendarAlerts: any;
  setCalendarData: any;
  calendarData: any;
  Transition: any;
  navigate: NavigateFunction;
}) => {
  const [assignedSites, setAssignedSites] = React.useState<number[]>(siteArray);

  const [changesMade, setChangesMade] = React.useState(false);

  const [siteOptions, setSiteOptions] = React.useState<any>([]);

  const [loadingSites, setLoadingSites] = React.useState(true);

  const [saveChangesDialogOpen, setSaveChangesDialogOpen] =
    React.useState(false);

  const siteIds = new Map();

  const siteNames = new Map();

  const allSiteIds: number[] = [];

  React.useEffect(() => {
    getSitesByParentGroupAlerts(
      groupId,
      navigate,
      setSiteOptions,
      setLoadingSites
    );

    siteOptions.forEach((object: any) => {
      allSiteIds.push(object.id);
      siteIds.set(object.name, object.id);
      siteNames.set(object.id, object.name);
    });
  }, []);

  return (
    <div>
      {saveChangesDialogOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={saveChangesDialogOpen}
        ></Backdrop>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={saveChangesDialogOpen}
      >
        <Dialog open={saveChangesDialogOpen} TransitionComponent={Transition}>
          <DialogContent>
            <DialogContentText sx={{ fontFamily: "Mulish" }}>
              You have made changes to selected sites, would you like to save
              these changes?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <OrangeTextButton
              onClick={() => {
                setSaveChangesDialogOpen(false);
                setAssignedSitesDialogOpen(false);
              }}
              text="Don't Save"
              disabled={false}
            />
            <OrangeTextButton
              onClick={() => {
                setUpdatingSites(true);
                setSaveChangesDialogOpen(false);
                setAssignedSitesDialogOpen(false);
                handleUpdateAssignedSites(
                  selectedRow,
                  assignedSites,
                  setUpdatingSites,
                  setAssignedSitesDialogOpen,
                  setCalendarAlerts,
                  setCalendarData,
                  calendarData,
                  navigate
                );
              }}
              text="Save Changes"
              disabled={false}
            />
          </DialogActions>
        </Dialog>
      </Backdrop>
      <Dialog open={assignedSitesDialogOpen} TransitionComponent={Transition}>
        <div>
          <DialogTitle id="edit-event" sx={{ font: "mulish" }}>
            <div className="font-mulish">Assigned Sites</div>
          </DialogTitle>
          <Tooltip title="Close Screen">
            <IconButton
              aria-label="close"
              onClick={() => {
                changesMade
                  ? setSaveChangesDialogOpen(true)
                  : setAssignedSitesDialogOpen(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <DialogContent sx={{ width: 500, height: 500 }}>
            {loadingSites && (
              <div className="absolute top-[270] left-[230]">
                <CircularProgress />
              </div>
            )}
            {!loadingSites && (
              <div className="font-mulish">
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 460,
                    maxHeight: 400,
                    bgcolor: "background.paper",
                  }}
                >
                  {siteOptions.map((site: any) => {
                    return (
                      <ListItem key={site.id}>
                        <ListItemButton>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={assignedSites.includes(site.id)}
                              tabIndex={-1}
                              disableRipple
                              onChange={(event, checked) => {
                                setChangesMade(true);
                                if (checked) {
                                  setAssignedSites([...assignedSites, site.id]);
                                } else {
                                  setAssignedSites(
                                    assignedSites.filter(
                                      (number) => number !== site.id
                                    )
                                  );
                                }
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ fontFamily: "Mulish" }}
                            id={site.name}
                            primary={`${site.name}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <OrangeTextButton
              onClick={() => {
                setUpdatingSites(true);
                setAssignedSitesDialogOpen(false);
                handleUpdateAssignedSites(
                  selectedRow,
                  assignedSites,
                  setUpdatingSites,
                  setAssignedSitesDialogOpen,
                  setCalendarAlerts,
                  setCalendarData,
                  calendarData,
                  navigate
                );
              }}
              text="Save"
              disabled={!changesMade}
            />
          </DialogActions>
        </div>
        {!loadingSites && (
          <div className="absolute left-[12px] top-[55px] font-bold font-mulish bg-white h-[50px] w-[600px]">
            <Checkbox
              onChange={(event, checked) => {
                setChangesMade(true);
                const allSiteIds: number[] = [];
                siteOptions.forEach((object: any) => {
                  allSiteIds.push(object.id);
                });
                if (checked) {
                  setAssignedSites(allSiteIds);
                } else {
                  setAssignedSites([]);
                }
              }}
            />
            Select All
          </div>
        )}
      </Dialog>
    </div>
  );
};
