import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosResponse } from "axios";

export const getAllCameras = (
  customerId: number,
  cameraId: number,
  setAllCamerasData: any,
  setCameraIndex: any
) => {
  const AxiosUI = createApi(``);

  AxiosUI.get(`camera/site/?customer__id=${customerId}`).then(
    (response: AxiosResponse) => {
      if (response.data.results) {
        setAllCamerasData(response.data.results);

        if (response.data.results.length > 0) {
          response.data.results.forEach((camera: any, index: number) => {
            if (camera.id === cameraId) {
              setCameraIndex(index);
              localStorage.setItem(
                "currentCamera",
                response.data.results[index].camera_name
              );
              localStorage.setItem(
                "currentCameraId",
                response.data.results[index].id
              );
            }
          });
        } else {
          localStorage.setItem(
            "currentCamera",
            response.data.results[0].camera_name
          );
          localStorage.setItem("currentCameraId", response.data.results[0].id);
        }
      }
    }
  );
};
