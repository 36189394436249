// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { SiteOptions } from "./analyticsInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getSitesByParentGroup = (
  id: string,
  navigate: NavigateFunction,
  setSiteOptions: Dispatcher<SiteOptions[]>,
  setLoadingSites: Dispatcher<boolean>
) => {
  setLoadingSites(true);
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getSitesByParentGroup(id, navigate, setSiteOptions, setLoadingSites);
  };

  AxiosUI.get(`customer/names/${id}/`).then(
    (response: AxiosResponse) => {
      // if (response.data.count === 1 && setAlertsGraphName) {
      //   setAlertsGraphName("Camera");
      // }
      setSiteOptions(response.data);
      setLoadingSites(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error retrieving sites. Please try again");
        setLoadingSites(false);
      });
    }
  );
};
