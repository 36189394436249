import React from "react";
import {
  Dispatcher,
  SiteInfo,
  SiteSchedule,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { Dialog } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { handleDayOfWeekCheckbox } from "./SiteAboutPageUtils/handleDayofWeekCheckbox";
import { Autocomplete, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TableHeadComponent } from "../../../common/TableHead";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { numbersOnly } from "../../../utils/numbersOnly";
import { locationsMatcher } from "../../../utils/locationsMatcher";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { set } from "date-fns";
import { handleCreateScheduleGridObjectToSubmit } from "./SiteAboutPageUtils/handleCreateScheduleGridObjectToSubmit";
import { handleSubmitDetailedScheduleChanges } from "./SiteAboutPageUtils/handleSubmitDetailedScheduleChanges";
import { NavigateFunction } from "react-router-dom";
import { padTo2Digits } from "../../AddSchedule/padTo2Digits";
import { handleUpdateTimeSchedules } from "../../AddSchedule/handleUpdateTimeSchedules";
// import { padTo2Digits } from "../../AddSchedule/padTo2Digits";
// import { handleUpdateTimeSchedules } from "../../AddSchedule/handleUpdateTimeSchedules";

export const EditScheduleTimesDialog = ({
  allSiteSchedules,
  siteInfo,
  setAllSiteSchedules,
  editTimeScheduleDialogOpen,
  setEditTimeScheduleDialogOpen,
  matchingSchedules,
  selectedDayInfo,
  timeRanges,
  duskToDawnLocation,
  setBackdropLoadingScreenOn,
  setActiveTab,
  setEditMode,
  setSiteSchedules,
  setDeleteScheduleDialogOpen,
  setSiteInfo,
  setTimeRanges,
  setGrid,
  setMatchingSchedules,
  navigate,
}: {
  allSiteSchedules: SiteSchedule[];
  siteInfo: any;
  setAllSiteSchedules: any;
  editTimeScheduleDialogOpen: boolean;
  setEditTimeScheduleDialogOpen: Dispatcher<boolean>;
  matchingSchedules: number[][];
  selectedDayInfo: any;
  timeRanges: any;
  duskToDawnLocation: any;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setActiveTab: Dispatcher<any>;
  setEditMode: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<any>;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  setSiteInfo: Dispatcher<any>;
  setTimeRanges: Dispatcher<any>;
  setGrid: Dispatcher<boolean[][]>;
  setMatchingSchedules: Dispatcher<number[][]>;
  navigate: NavigateFunction;
}) => {
  const label = { inputProps: { "aria-label": "Checkbox label" } };

  const [dayOfWeekArray, setDayOfWeekArray] = React.useState<any>(
    matchingSchedules.find((array) => array.includes(selectedDayInfo.day))
  );

  const [startTime, setStartTime] = React.useState(
    selectedDayInfo.timeRanges !== "Always On" &&
      selectedDayInfo.timeRanges !== "Dusk to Dawn"
      ? selectedDayInfo.timeRanges.slice(0, 5)
      : null
  );

  const [endTime, setEndTime] = React.useState(
    selectedDayInfo.timeRanges !== "Always On" &&
      selectedDayInfo.timeRanges !== "Dusk to Dawn"
      ? selectedDayInfo.timeRanges.slice(8, 13)
      : null
  );

  const [alwaysOn, setAlwaysOn] = React.useState(
    selectedDayInfo.timeRanges === "Always On"
  );

  const [duskToDawn, setDuskToDawn] = React.useState(
    selectedDayInfo.duskToDawn
  );

  const [bufferTime, setBufferTime] = React.useState(
    selectedDayInfo.bufferTime
  );

  return (
    <Dialog
      open={editTimeScheduleDialogOpen}
      onClose={() => setEditTimeScheduleDialogOpen(false)}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ background: "#001943" }}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Schedule Times
          </Typography>
        </Toolbar>
      </AppBar>
      <div>
        <div className="h-[425px]">
          <div className=" bg-white">
            <div className="pt-[20px] pl-[20px]">
              <FormControl component="fieldset">
                <FormGroup aria-label="siteSchedule" row>
                  &nbsp;
                  <FormControlLabel
                    sx={{ fontFamily: "Mulish" }}
                    value="sunday"
                    control={
                      <Checkbox
                        {...label}
                        defaultChecked={dayOfWeekArray.includes(0)}
                        onChange={() => {
                          if (dayOfWeekArray.includes(0)) {
                            setDayOfWeekArray(
                              dayOfWeekArray.filter((day: number) => day !== 0)
                            );
                          } else {
                            setDayOfWeekArray([...dayOfWeekArray, 0]);
                          }
                        }}
                      />
                    }
                    label="Sunday"
                    labelPlacement="start"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    sx={{ fontFamily: "Mulish" }}
                    value="monday"
                    control={
                      <Checkbox
                        {...label}
                        defaultChecked={dayOfWeekArray.includes(1)}
                        onChange={() => {
                          if (dayOfWeekArray.includes(1)) {
                            setDayOfWeekArray(
                              dayOfWeekArray.filter((day: number) => day !== 1)
                            );
                          } else {
                            setDayOfWeekArray([...dayOfWeekArray, 1]);
                          }
                        }}
                      />
                    }
                    label="Monday"
                    labelPlacement="start"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    sx={{ fontFamily: "Mulish" }}
                    value="tuesday"
                    control={
                      <Checkbox
                        {...label}
                        defaultChecked={dayOfWeekArray.includes(2)}
                        onChange={() => {
                          if (dayOfWeekArray.includes(2)) {
                            setDayOfWeekArray(
                              dayOfWeekArray.filter((day: number) => day !== 2)
                            );
                          } else {
                            setDayOfWeekArray([...dayOfWeekArray, 2]);
                          }
                        }}
                      />
                    }
                    label="Tuesday"
                    labelPlacement="start"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    sx={{ fontFamily: "Mulish" }}
                    value="wednesday"
                    control={
                      <Checkbox
                        {...label}
                        defaultChecked={dayOfWeekArray.includes(3)}
                        onChange={() => {
                          if (dayOfWeekArray.includes(3)) {
                            setDayOfWeekArray(
                              dayOfWeekArray.filter((day: number) => day !== 3)
                            );
                          } else {
                            setDayOfWeekArray([...dayOfWeekArray, 3]);
                          }
                        }}
                      />
                    }
                    label="Wednesday"
                    labelPlacement="start"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    sx={{ fontFamily: "Mulish" }}
                    value="thursday"
                    control={
                      <Checkbox
                        {...label}
                        defaultChecked={dayOfWeekArray.includes(4)}
                        onChange={() => {
                          if (dayOfWeekArray.includes(4)) {
                            setDayOfWeekArray(
                              dayOfWeekArray.filter((day: number) => day !== 4)
                            );
                          } else {
                            setDayOfWeekArray([...dayOfWeekArray, 4]);
                          }
                        }}
                      />
                    }
                    label="Thursday"
                    labelPlacement="start"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    sx={{ fontFamily: "Mulish" }}
                    value="friday"
                    control={
                      <Checkbox
                        {...label}
                        defaultChecked={dayOfWeekArray.includes(5)}
                        onChange={() => {
                          if (dayOfWeekArray.includes(5)) {
                            setDayOfWeekArray(
                              dayOfWeekArray.filter((day: number) => day !== 5)
                            );
                          } else {
                            setDayOfWeekArray([...dayOfWeekArray, 5]);
                          }
                        }}
                      />
                    }
                    label="Friday"
                    labelPlacement="start"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    sx={{ fontFamily: "Mulish" }}
                    value="saturday"
                    control={
                      <Checkbox
                        {...label}
                        defaultChecked={dayOfWeekArray.includes(6)}
                        onChange={() => {
                          if (dayOfWeekArray.includes(6)) {
                            setDayOfWeekArray(
                              dayOfWeekArray.filter((day: number) => day !== 6)
                            );
                          } else {
                            setDayOfWeekArray([...dayOfWeekArray, 6]);
                          }
                        }}
                      />
                    }
                    label="Saturday"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <div className="pt-[5px] pl-[40px]">
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={alwaysOn}
                  color="warning"
                  onChange={(value) => {
                    setAlwaysOn(!alwaysOn);
                    if (!value.target.checked) {
                      setStartTime(null);
                      setEndTime(null);
                    } else {
                      setStartTime("00:00:00");
                      setEndTime("23:59:59");
                    }
                  }}
                />
              }
              label="Always On"
            />
          </div>
          <div className="flex pt-[20px]">
            <div className="pl-[35px]">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {alwaysOn || duskToDawn ? (
                  <div>
                    <DesktopTimePicker
                      label="Arm Start Time"
                      disabled={true}
                      value={null}
                      onChange={() => {}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            duskToDawn && (!bufferTime || bufferTime === 0)
                              ? "Will arm at dusk."
                              : bufferTime
                              ? `Will arm ${bufferTime} minutes before dusk.`
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                ) : (
                  <DesktopTimePicker
                    label="Arm Start Time"
                    disabled={false}
                    value={
                      !startTime
                        ? null
                        : new Date(`August 19, 1975 ${startTime} GMT-04:00`)
                    }
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        setStartTime(
                          `${padTo2Digits(newValue.getHours())}:${padTo2Digits(
                            newValue.getMinutes()
                          )}:00`
                        );
                      }
                      if (newValue === null) setStartTime(null);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </LocalizationProvider>
            </div>
            <div className="pl-[40px]">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {alwaysOn || duskToDawn ? (
                  <div>
                    <DesktopTimePicker
                      label="Arm End Time"
                      disabled={true}
                      value={null}
                      onChange={() => {}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            duskToDawn && (!bufferTime || bufferTime === 0)
                              ? "Will disarm at dawn."
                              : bufferTime
                              ? `Will disarm ${bufferTime} minutes after dawn.`
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                ) : (
                  <DesktopTimePicker
                    label="Arm End Time"
                    disabled={false}
                    value={
                      !endTime
                        ? null
                        : new Date(`August 19, 1975 ${endTime} GMT-04:00`)
                    }
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        setEndTime(
                          `${padTo2Digits(newValue.getHours())}:${padTo2Digits(
                            newValue.getMinutes()
                          )}:00`
                        );
                      }
                      if (newValue === null) setEndTime(null);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </LocalizationProvider>
            </div>
          </div>
          <br />
          <div className="flex">
            <div className="pl-[40px]">
              <Autocomplete
                disablePortal
                options={JSON.parse(localStorage.locations).map(
                  (object: any) => {
                    return `${object.name}, ${object.region}`;
                  }
                )}
                sx={{ width: 225 }}
                value={locationsMatcher("Name", duskToDawn)}
                disabled={alwaysOn}
                onChange={(newValue, text) => {
                  // if blank, set to null
                  if (text === "") {
                    setDuskToDawn(null);
                  } else {
                    setDuskToDawn(locationsMatcher("Id", text));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    autoComplete="off"
                    label="Location dusk to dawn"
                    helperText="If selected will always arm at dusk and disarm at dawn."
                  />
                )}
              />
            </div>
            <div className="pl-[40px]">
              <TextField
                error={bufferTime ? !numbersOnly.test(bufferTime) : false}
                id=""
                label="Buffer Time"
                autoComplete="off"
                disabled={alwaysOn || !duskToDawn}
                value={bufferTime}
                variant="standard"
                sx={{ width: 225 }}
                onChange={(newValue) => {
                  if (newValue.target.value === "") {
                    setBufferTime("");
                  } else if (numbersOnly.test(newValue.target.value)) {
                    setBufferTime(newValue.target.value);
                  }
                }}
                helperText={
                  bufferTime && !numbersOnly.test(bufferTime)
                    ? "Must be a number"
                    : "Amount of minutes to start before dusk and end after dawn."
                }
              />
            </div>
          </div>

          <div className="flex pr-[20px] pt-[20px]" style={{ display: "flex" }}>
            <div style={{ marginLeft: "auto" }}>
              <OrangeTextButton
                onClick={() => {
                  setEditTimeScheduleDialogOpen(false);
                }}
                text="Cancel"
                disabled={false}
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            {!window.location.href.includes("addschedule") && (
              <div>
                <OrangeTextButton
                  onClick={() => {
                    setEditMode(false);
                    setEditTimeScheduleDialogOpen(false);
                    setBackdropLoadingScreenOn(true);
                    handleSubmitDetailedScheduleChanges(
                      handleCreateScheduleGridObjectToSubmit(
                        dayOfWeekArray,
                        startTime,
                        endTime,
                        alwaysOn,
                        duskToDawn,
                        bufferTime,
                        timeRanges,
                        duskToDawnLocation
                      ),
                      setActiveTab,
                      setEditMode,
                      setAllSiteSchedules,
                      setSiteSchedules,
                      setBackdropLoadingScreenOn,
                      siteInfo,
                      setDeleteScheduleDialogOpen,
                      setSiteInfo,
                      allSiteSchedules,
                      navigate
                    );
                  }}
                  text="Save Changes"
                  disabled={false}
                />
              </div>
            )}
            {window.location.href.includes("addschedule") && (
              <div>
                <OrangeTextButton
                  onClick={() => {
                    handleUpdateTimeSchedules(
                      dayOfWeekArray,
                      startTime,
                      endTime,
                      timeRanges,
                      allSiteSchedules,
                      alwaysOn,
                      duskToDawn,
                      bufferTime,
                      duskToDawnLocation,
                      setTimeRanges,
                      setSiteSchedules,
                      setGrid,
                      setMatchingSchedules
                    );
                    setEditTimeScheduleDialogOpen(false);
                  }}
                  text="Ok"
                  disabled={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
