import React from "react";
import { GeneralInfoEditCameraTab } from "../GeneralInfoEditCameraTab";
import { GeneralInfoEditCameraTabEditMode } from "../GeneralInfoEditCameraTabEditMode";
import { ConnectionsTab } from "../ConnectionsTab";
import { ConnectionsTabEditMode } from "../ConnectionsTabEditMode";
import { NotificationsTab } from "../NotificationsTab";
import { NotificationsTabEditMode } from "../NotificationsTabEditMode";
import { ProductsTab } from "../ProductsTab";
import { ProductsTabEditMode } from "../ProductsTabEditMode";
import { ImmixAlertsTab } from "../ImmixAlertsTab";
import { ImmixAlertsTabEditMode } from "../ImmixAlertsTabEditMode";
import { SureviewAlertsTab } from "../SureviewAlertsTab";
import { SureviewAlertsTabEditMode } from "../SureviewAlertsTabEditMode";
import { AxisTab } from "../AxisTab";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { MobotixTab } from "../MobotixTab";
import { WebhooksCameraTab } from "../WebhooksCameraTab";
import ErrorBoundary from "../../../../ErrorBoundary";
import { WebhooksCameraTabEditMode } from "../WebhooksCameraTabEditMode";
import { SentinelAlertsTab } from "../SentinelAlertsTab";
import { SentinelAlertsTabEditMode } from "../SentinelAlertsTabEditMode";

export const handleTabChangeEditCamera = (
  tabValue: any,
  editMode: boolean,
  setClickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  cameraGeneralInfo: any,
  setCameraGeneralInfo: any,
  setPatchObject: any,
  setGeneralInfoChangesMade: any,
  immixAlerts: any,
  setActiveChangeMade: any,
  sureviewAlerts: any,
  setAddImmixAlertDialogOpen: any,
  setAddSureviewAlertDialogOpen: any,
  streamsObject: any,
  activeProducts: any,
  recipientsConfiguredData: any,
  titlesArray: any,
  navigate: NavigateFunction,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  actionRulesData: any,
  setActionRulesData: Dispatcher<any>,
  actionRulesTitlesArray: any,
  setActionRulesTitlesArray: Dispatcher<any>,
  applicationsConfiguredData: any,
  timeTablesData: any,
  setTimeTablesData: any,
  iPNotifyData: any,
  setIPNotifyData: any,
  actionHandlerData: any,
  setActionHandlerData: any,
  axisFinished: any,
  setSendingImmixTest: Dispatcher<boolean>,
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>,
  setImmixTestFailDialogOpen: Dispatcher<boolean>,
  cameraWebhookData: any,
  setCameraWebhooksPatchObject: any,
  setCameraWebhooksChangesMade: Dispatcher<boolean>,
  productsData: any,
  setProductsData: Dispatcher<any>,
  allTabsOpen: boolean,
  setAllTabsOpen: Dispatcher<boolean>,
  setBackupProductsData: any,
  sentinelAlerts: any,
  setAddSentinelAlertDialogOpen: any
) => {
  setClickedTab(tabValue);
  localStorage.setItem("editCamerActiveTab", tabValue);
  if (tabValue === "one" && !editMode) {
    setActiveTab(
      <GeneralInfoEditCameraTab
        siteInfo={siteInfo}
        cameraGeneralInfo={cameraGeneralInfo}
      />
    );
  } else if (tabValue === "one" && editMode) {
    setActiveTab(
      <GeneralInfoEditCameraTabEditMode
        siteInfo={siteInfo}
        cameraGeneralInfo={cameraGeneralInfo}
        setCameraGeneralInfo={setCameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        setActiveChangeMade={setActiveChangeMade}
      />
    );
  } else if (tabValue === "two" && !editMode) {
    setActiveTab(<ConnectionsTab cameraGeneralInfo={cameraGeneralInfo} />);
  } else if (tabValue === "two" && editMode) {
    setActiveTab(
      <ConnectionsTabEditMode
        cameraGeneralInfo={cameraGeneralInfo}
        setCameraGeneralInfo={setCameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  } else if (tabValue === "three" && !editMode) {
    setActiveTab(<NotificationsTab cameraGeneralInfo={cameraGeneralInfo} />);
  } else if (tabValue === "three" && editMode) {
    setActiveTab(
      <NotificationsTabEditMode
        cameraGeneralInfo={cameraGeneralInfo}
        setCameraGeneralInfo={setCameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  } else if (tabValue === "four" && !editMode) {
    setActiveTab(
      <ProductsTab
        productsData={productsData}
        setProductsData={setProductsData}
        allTabsOpen={allTabsOpen}
        setAllTabsOpen={setAllTabsOpen}
        setBackupProductsData={setBackupProductsData}
      />
    );
  } else if (tabValue === "four" && editMode) {
    setActiveTab(
      <ProductsTabEditMode
        activeProducts={activeProducts}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        cameraGeneralInfo={cameraGeneralInfo}
        productsData={productsData}
        setProductsData={setProductsData}
        allTabsOpen={allTabsOpen}
        setAllTabsOpen={setAllTabsOpen}
        setBackupProductsData={setBackupProductsData}
      />
    );
  } else if (tabValue === "five" && !editMode) {
    setActiveTab(
      <ImmixAlertsTab
        immixAlerts={immixAlerts}
        setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
        setSendingImmixTest={setSendingImmixTest}
        setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
        setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
        navigate={navigate}
      />
    );
  } else if (tabValue === "five" && editMode) {
    setActiveTab(
      <ImmixAlertsTabEditMode
        immixAlerts={immixAlerts}
        cameraGeneralInfo={cameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  } else if (tabValue === "six" && !editMode) {
    setActiveTab(
      <SureviewAlertsTab
        sureviewAlerts={sureviewAlerts}
        setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
      />
    );
  } else if (tabValue === "six" && editMode) {
    setActiveTab(
      <SureviewAlertsTabEditMode
        sureviewAlerts={sureviewAlerts}
        cameraGeneralInfo={cameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  } else if (tabValue === "seven") {
    setActiveTab(
      <AxisTab
        recipientsConfiguredData={recipientsConfiguredData}
        titlesArray={titlesArray}
        navigate={navigate}
        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
        setRecipientsConfiguredData={setRecipientsConfiguredData}
        setTitlesArray={setTitlesArray}
        actionRulesData={actionRulesData}
        setActionRulesData={setActionRulesData}
        actionRulesTitlesArray={actionRulesTitlesArray}
        setActionRulesTitlesArray={setActionRulesTitlesArray}
        setActiveTab={setActiveTab}
        applicationsConfiguredData={applicationsConfiguredData}
        cameraGeneralInfo={cameraGeneralInfo}
        axisFinished={axisFinished}
      />
    );
  } else if (tabValue === "eight") {
    setActiveTab(
      <MobotixTab
        timeTablesData={timeTablesData}
        navigate={navigate}
        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
        setTimeTablesData={setTimeTablesData}
        setActiveTab={setActiveTab}
        iPNotifyData={iPNotifyData}
        setIPNotifyData={setIPNotifyData}
        actionHandlerData={actionHandlerData}
        setActionHandlerData={setActionHandlerData}
      />
    );
  } else if (tabValue === "nine" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <WebhooksCameraTab cameraWebhookData={cameraWebhookData} />
      </ErrorBoundary>
    );
  } else if (tabValue === "nine" && editMode) {
    setActiveTab(
      <WebhooksCameraTabEditMode
        cameraWebhookData={cameraWebhookData}
        setCameraWebhooksPatchObject={setCameraWebhooksPatchObject}
        setCameraWebhooksChangesMade={setCameraWebhooksChangesMade}
      />
    );
  } else if (tabValue === "ten" && !editMode) {
    setActiveTab(
      <ErrorBoundary>
        <SentinelAlertsTab
          sentinelAlerts={sentinelAlerts}
          setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
        />
      </ErrorBoundary>
    );
  } else if (tabValue === "ten" && editMode) {
    setActiveTab(
      <SentinelAlertsTabEditMode
        sentinelAlerts={sentinelAlerts}
        cameraGeneralInfo={cameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  }
};
