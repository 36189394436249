import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stepper, Step, StepLabel } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Mulish",
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          fontFamily: "Mulish",
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          fontFamily: "Mulish",
        },
      },
    },
  },
});

export const StepsStepper = ({ activeStep }: { activeStep: any }) => {
  const steps = ["Integration", `Site's Information`];

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Stepper activeStep={activeStep} sx={{ width: 565 }}>
          {steps.map((label) => (
            <Step key={label} color="primary" sx={{ fontFamily: "Mulish" }}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </ThemeProvider>
    </div>
  );
};
