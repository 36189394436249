import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { Order } from "../Alerts/AlertsUtils/alertsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import useWindowDimensions from "../../common/useWindowDimensions";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { EnhancedActionLogsTableHead } from "./EnhancedActionLogsTableHead";
import { handleGetMoreActionLogsData } from "./ActionLogsUtils/handleGetMoreActionLogsData";

export const ActionLogsTable = ({
  auditLogsData,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  paginationNumber,
  setLoadingMoreActionLogsData,
  setActionLogsData,
  setPaginationNumber,
  navigate,
}: {
  auditLogsData: any;
  page: number;
  setPage: Dispatcher<number>;
  rowsPerPage: number;
  setRowsPerPage: Dispatcher<number>;
  paginationNumber: number;
  setLoadingMoreActionLogsData: Dispatcher<boolean>;
  setActionLogsData: Dispatcher<any>;
  setPaginationNumber: Dispatcher<number>;
  navigate: NavigateFunction;
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("formatted_date");
  const [displayLoadMoreButton, setDisplayLoadMoreButton] =
    React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: any[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const formatDateString = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(auditLogsData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage]
  );

  const { height, width } = useWindowDimensions();

  React.useEffect(() => {
    setCalculatedHeight(height - 205);
    setCalculatedWidth(width - 150);
  }, [height, width]);

  React.useEffect(() => {
    if (auditLogsData.length % 100 !== 0) {
      setDisplayLoadMoreButton(false);
    } else {
      if (rowsPerPage === 100) {
        setDisplayLoadMoreButton(paginationNumber === page + 1);
      }
      if (rowsPerPage === 25) {
        setDisplayLoadMoreButton(auditLogsData.length / 25 === page + 1);
      }
      if (rowsPerPage === 10) {
        setDisplayLoadMoreButton(auditLogsData.length / 10 === page + 1);
      }
    }
  }, [rowsPerPage, page, paginationNumber, auditLogsData]);

  const [calcuatedHeight, setCalculatedHeight] = React.useState(0);

  const [calcuatedWidth, setCalculatedWidth] = React.useState(0);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{ maxHeight: calcuatedHeight, minWidth: calcuatedWidth }}
      >
        <Table sx={{ fontFamily: "Mulish" }} aria-label="audit_logs_table">
          <EnhancedActionLogsTableHead
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            order={order}
          />
          <TableBody>
            {visibleRows.map((obj: any, index: any) => {
              return (
                <TableRow key={obj.id}>
                  <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                    {formatDateString(obj.request_date)}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                    {obj.description}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Mulish" }} align="left">
                    {obj.user}
                  </TableCell>
                </TableRow>
              );
            })}
            {displayLoadMoreButton && (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <OrangeTextButton
                    onClick={() => {
                      setLoadingMoreActionLogsData(true);
                      setDisplayLoadMoreButton(false);
                      handleGetMoreActionLogsData(
                        setActionLogsData,
                        setLoadingMoreActionLogsData,
                        paginationNumber,
                        setPaginationNumber,
                        navigate
                      );
                    }}
                    text="Load More"
                    disabled={false}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={auditLogsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
