import React from "react";
import { errorsCopy } from "./errorsCopy";

export const createNextSteps = (selectedRow: any) => {
  if (selectedRow.error_type === "connectivity") {
    if (selectedRow.next_steps) {
      return selectedRow.next_steps;
    }
    // if (selectedRow.error_msg) {
    //   if (selectedRow.error_msg.includes("OpenCV(4.7.0) ")) {
    //     return errorsCopy.invalid_credentials_next_steps;
    //   } else if (selectedRow.error_msg.includes("failed: Connection timed out")) {
    //     return errorsCopy.filtered_port_next_steps;
    //   } else if (selectedRow.error_msg.includes("Failed to resolve hostname")) {
    //     return errorsCopy.connection_interuption_next_steps;
    //   }
    //   // else if (selectedRow.error_msg.includes("Failed to resolve hostname x: Name or service not known")){
    //   //   return errorsCopy.ip_unreachable_next_steps
    //   // }
    //   else return errorsCopy.connectivity_default_next_steps;
    // }
    else return errorsCopy.connectivity_default_next_steps;
  }
  if (selectedRow.error_type === "image_quality") {
    if (selectedRow.next_steps) {
      return selectedRow.next_steps;
    }
    // if (selectedRow.error_msg) {
    //   if (selectedRow.error_msg.includes("resolution too low")) {
    //     return errorsCopy.resolution_low_next_steps;
    //   } else if (selectedRow.error_msg.includes("resolution too high")) {
    //     return errorsCopy.resolution_high_next_steps;
    //   } else return errorsCopy.image_quality_default_next_steps;
    // }
    else return errorsCopy.image_quality_default_next_steps;
  }
  if (selectedRow.error_type === "stream_quality") {
    if (selectedRow.next_steps) {
      return selectedRow.next_steps;
    }
    // if (selectedRow.error_msg) {
    //   if (selectedRow.error_msg.includes("FPS") && selectedRow.error_msg.includes("too high")) {
    //     return errorsCopy.fps_too_high_next_steps;
    //   } else if (
    //     selectedRow.error_msg.includes("FPS") &&
    //     selectedRow.error_msg.includes("too low")
    //   ) {
    //     return errorsCopy.fps_too_low_next_steps;
    //   } else if (selectedRow.error_msg.includes("resolution too high")) {
    //     return errorsCopy.resolution_too_high_next_steps;
    //   } else return errorsCopy.stream_quality_default_next_steps;
    // }
    else return errorsCopy.stream_quality_default_next_steps;
  }
  if (selectedRow.error_type === "motion_status") {
    if (selectedRow.next_steps) {
      return selectedRow.next_steps;
    } else return errorsCopy.motion_status_default_next_steps;
  }
  if (selectedRow.error_type === "recording_status") {
    if (selectedRow.next_steps) {
      return selectedRow.next_steps;
    } else return errorsCopy.recording_status_default_next_steps;
  }
  if (selectedRow.error_type === "scene_change") {
    if (selectedRow.next_steps) {
      return selectedRow.next_steps;
    }
    // if (selectedRow.error_msg) {
    //   if (selectedRow.error_msg.includes("translation")) {
    //     return errorsCopy.camera_moved_next_steps;
    //   } else if (selectedRow.error_msg.includes("tampering")) {
    //     return errorsCopy.camera_tampering_next_steps;
    //   } else if (selectedRow.error_msg.includes("occlusion")) {
    //     return errorsCopy.camera_blocked_next_steps;
    //   } else return errorsCopy.scene_change_default_next_steps;
    // }
    else return errorsCopy.scene_change_default_next_steps;
  }
  // switch (selectedRow.error_type) {
  //   case "connectivity":
  //     return errorsCopy.connectivity_next_steps;
  //   case "image_quality":
  //     return errorsCopy.image_quality_next_steps;
  //   case "motion_status":
  //     return errorsCopy.motion_status_next_steps;
  //   case "recording_status":
  //     return errorsCopy.recording_status_next_steps;
  //   case "scene_change":
  //     return errorsCopy.scene_change_next_steps;
  //   // case "license_info":
  //   //   return errorsCopy.license_info_next_steps;
  //   case "stream_quality":
  //     return errorsCopy.stream_quality_next_steps;
  // }
};
