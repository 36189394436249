const numericallySortObject = (object: any) => {
  const resortedObject = object;
  for (let i = 1; i < resortedObject.length; i++)
    for (let j = 0; j < i; j++)
      if (resortedObject[i].id < resortedObject[j].id) {
        const x = resortedObject[i];
        resortedObject[i] = resortedObject[j];
        resortedObject[j] = x;
      }
  return resortedObject;
};

export const numericallySortAlerts = (streams: any, string: any) => {
  const sortedStreams = streams;
  sortedStreams.forEach((object: any, index: any) => {
    const sortedObject = object;
    if (string === "sureview_alerts") {
      const numericalSureviewAlerts = sortedObject.sureview_alerts;
      numericallySortObject(numericalSureviewAlerts);
      sortedObject.sureview_alerts = numericalSureviewAlerts;
    }
    if (string === "immix_alerts") {
      const numericalImmixViewAlerts = sortedObject.immix_alerts;
      numericallySortObject(numericalImmixViewAlerts);
      sortedObject.immix_alerts = numericalImmixViewAlerts;
    }
    sortedStreams[index] = sortedObject;
  });
  return sortedStreams;
};

export const matchAllStreams = (streams: any, string: any) => {
  const sortedStreams = streams;
  if (string === "sureview_alerts") {
    const comparedArray = streams[0].sureview_alerts;
    sortedStreams.forEach((object: any, sortedStreamsIndex: any) => {
      const newlySortedAlerts = object.sureview_alerts;
      newlySortedAlerts.forEach((object: any) => {
        for (let i = 0; i < comparedArray.length; i++) {
          if (
            object.sureview_server === comparedArray[i].sureview_server &&
            object.sureview_subject === comparedArray[i].sureview_subject &&
            object.sureview_cc === comparedArray[i].sureview_cc &&
            object.sureview_email === comparedArray[i].sureview_email
          ) {
            newlySortedAlerts[i] = object;
          }
        }
      });
      sortedStreams[sortedStreamsIndex].sureview_alerts = newlySortedAlerts;
    });
    return sortedStreams;
  }

  if (string === "immix_alerts") {
    const comparedArray = streams[0].immix_alerts;
    sortedStreams.forEach((object: any, sortedStreamsIndex: any) => {
      const newlySortedAlerts = object.immix_alerts;
      newlySortedAlerts.forEach((object: any) => {
        for (let i = 0; i < comparedArray.length; i++) {
          if (
            object.immix_server === comparedArray[i].immix_server &&
            object.immix_port === comparedArray[i].immix_port &&
            object.immix_email === comparedArray[i].immix_email &&
            object.immix_input1 === comparedArray[i].immix_input1
          ) {
            newlySortedAlerts[i] = object;
          }
        }
      });
      sortedStreams[sortedStreamsIndex].immix_alerts = newlySortedAlerts;
    });
  }
  return sortedStreams;
};
