import { Link } from "@mui/material";

export const createCameraHyperlink = (
  cameraName: string,
  siteId: string,
  cameraId: string
) => {
  const href = `/sites/${siteId}/editcamera/${cameraId}`;

  if (siteId && cameraId) {
    return (
      <Link
        underline="hover"
        color="inherit"
        href={href}
        sx={{
          fontSize: "20px",
          fontFamily: "Mulish",
        }}
        variant="h5"
      >
        {cameraName}
      </Link>
    );
  } else {
    return <>{cameraName}</>;
  }
};
