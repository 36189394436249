import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { checkOrX } from "./EditCameraUtils/checkOrX";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleDeleteAxisActionRule } from "./EditCameraUtils/handleDeleteAxisActionRule";

export const ActionRulesTable = ({
  actionRulesData,
  setActionRulesData,
  setBackdropLoadingScreenOn,
  actionRulesTitlesArray,
  setActionRulesTitlesArray,
  navigate,
  setRecipientsConfiguredData,
  setTitlesArray,
  setActiveTab,
  recipientsConfiguredData,
  titlesArray,
  applicationsConfiguredData,
  cameraGeneralInfo,
  axisFinished,
}: {
  actionRulesData: any;
  setActionRulesData: any;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  actionRulesTitlesArray: any;
  setActionRulesTitlesArray: Dispatcher<any>;
  navigate: NavigateFunction;
  setRecipientsConfiguredData: any;
  setTitlesArray: any;
  setActiveTab: any;
  recipientsConfiguredData: any;
  titlesArray: any;
  applicationsConfiguredData: any;
  cameraGeneralInfo: any;
  axisFinished: any;
}) => {
  const currentCameraInfo = JSON.parse(localStorage.currentCameraInfo);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={actionRulesTitlesArray} />
        <TableBody>
          {actionRulesData.map((object: any) => {
            return (
              <TableRow key={object.id}>
                {object.is_actuate ? (
                  <TableCell
                    align="left"
                    sx={{ fontFamily: "Mulish", minWidth: "125px" }}
                  >
                    {object.display_name ? object.display_name : "--"}
                  </TableCell>
                ) : (
                  <TableCell
                    align="left"
                    sx={{
                      fontFamily: "Mulish",
                      minWidth: "125px",
                      color: "#7E7F7B",
                    }}
                  >
                    {object.display_name ? object.display_name : "--"}
                  </TableCell>
                )}
                {object.is_actuate
                  ? actionRulesTitlesArray.includes("Host") && (
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Mulish", minWidth: "150px" }}
                      >
                        {object.host ? object.host : "--"}
                      </TableCell>
                    )
                  : actionRulesTitlesArray.includes("Host") && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "Mulish",
                          minWidth: "150px",
                          color: "#7E7F7B",
                        }}
                      >
                        {object.host ? object.host : "--"}
                      </TableCell>
                    )}
                {object.is_actuate
                  ? actionRulesTitlesArray.includes("Port") && (
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Mulish", minWidth: "100px" }}
                      >
                        {object.port ? object.port : "--"}
                      </TableCell>
                    )
                  : actionRulesTitlesArray.includes("Port") && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "Mulish",
                          minWidth: "100px",
                          color: "#7E7F7B",
                        }}
                      >
                        {object.port ? object.port : "--"}
                      </TableCell>
                    )}
                {object.is_actuate
                  ? actionRulesTitlesArray.includes("E-mail to") && (
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Mulish", minWidth: "200px" }}
                      >
                        {object.email_to ? object.email_to : "--"}
                      </TableCell>
                    )
                  : actionRulesTitlesArray.includes("E-mail to") && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "Mulish",
                          minWidth: "200px",
                          color: "#7E7F7B",
                        }}
                      >
                        {object.email_to ? object.email_to : "--"}
                      </TableCell>
                    )}
                {object.is_actuate
                  ? actionRulesTitlesArray.includes("E-mail from") && (
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Mulish", minWidth: "250px" }}
                      >
                        {object.email_from ? (
                          <>
                            {checkOrX(object.is_email_from_ok)}{" "}
                            {object.email_from}
                          </>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    )
                  : actionRulesTitlesArray.includes("E-mail from") && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "Mulish",
                          minWidth: "250px",
                          color: "#7E7F7B",
                        }}
                      >
                        {object.email_from ? (
                          <>
                            {checkOrX(object.is_email_from_ok)}{" "}
                            {object.email_from}
                          </>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    )}
                {object.is_actuate
                  ? actionRulesTitlesArray.includes("Message") && (
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Mulish", minWidth: "150px" }}
                      >
                        {object.message ? (
                          <>
                            {checkOrX(object.is_message_ok)} {object.message}
                          </>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    )
                  : actionRulesTitlesArray.includes("Message") && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "Mulish",
                          minWidth: "150px",
                          color: "#7E7F7B",
                        }}
                      >
                        {object.message ? (
                          <>
                            {checkOrX(object.is_message_ok)} {object.message}
                          </>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    )}
                {object.is_actuate
                  ? actionRulesTitlesArray.includes("Enabled") && (
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Mulish", minWidth: "100px" }}
                      >
                        {object.enabled ? (
                          <>
                            {checkOrX(object.is_enabled)} {object.enabled}
                          </>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    )
                  : actionRulesTitlesArray.includes("Enabled") && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "Mulish",
                          minWidth: "100px",
                          color: "#7E7F7B",
                        }}
                      >
                        {object.enabled ? (
                          <>{checkOrX(object.is_enabled)}</>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    )}
                {object.is_actuate
                  ? actionRulesTitlesArray.includes("Profile") && (
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Mulish", minWidth: "150px" }}
                      >
                        {object.profile_selected ? (
                          <>{checkOrX(object.is_profile_ok)}</>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    )
                  : actionRulesTitlesArray.includes("Profile") && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "Mulish",
                          minWidth: "150px",
                          color: "#7E7F7B",
                        }}
                      >
                        {object.profile_selected ? (
                          <>{checkOrX(object.is_profile_ok)}</>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    )}
                <TableCell
                  align="left"
                  sx={{ fontFamily: "Mulish", minWidth: "100px" }}
                >
                  <OrangeTextButton
                    onClick={() => {
                      setBackdropLoadingScreenOn(true);
                      handleDeleteAxisActionRule(
                        currentCameraInfo.id,
                        object.id,
                        navigate,
                        setBackdropLoadingScreenOn,
                        setActionRulesData,
                        setActionRulesTitlesArray,
                        setRecipientsConfiguredData,
                        setTitlesArray,
                        setActiveTab,
                        recipientsConfiguredData,
                        titlesArray,
                        actionRulesData,
                        actionRulesTitlesArray,
                        applicationsConfiguredData,
                        cameraGeneralInfo,
                        axisFinished
                      );
                    }}
                    text="Delete"
                    disabled={false}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
