import React from "react";
import { useFormikContext } from "formik";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleSubmitAndAddAnotherCamera } from "../../pages/AddCamera/handleSubmitAndAddAnotherCamera";
import { useNavigate } from "react-router-dom";

const HandSubmitAndAddAnotherCameraButton = ({
  isLastStep,
  setVisibleScreen,
  responseObject,
  buttonDisabled,
  setCameraCheckFinsihedAlertOpen,
  setGoodCameras,
  setConnectivityCameras,
  setLowResolutionCameras,
  setCameraCheckResultDownloadURL,
}: {
  isLastStep: boolean;
  setVisibleScreen: any;
  responseObject: any;
  buttonDisabled: any;
  setCameraCheckFinsihedAlertOpen: any;
  setGoodCameras: any;
  setConnectivityCameras: any;
  setLowResolutionCameras: any;
  setCameraCheckResultDownloadURL: any;
}) => {
  const formik = useFormikContext();
  const navigate = useNavigate();

  const increaseCameraNumber = (passedNumber: number) => {
    return passedNumber + 1;
  };

  return (
    <div className="absolute right-[-40px]">
      {!isLastStep || !location.href.includes("addcamera") ? (
        <></>
      ) : (
        <OrangeTextButton
          onClick={() => {
            localStorage.setItem(
              "cameraNumber",
              `${increaseCameraNumber(Number(localStorage.cameraNumber))}`
            );
            // localStorage.setItem("values", JSON.stringify(formik.values));
            handleSubmitAndAddAnotherCamera(
              formik.values,
              setVisibleScreen,
              responseObject,
              setCameraCheckFinsihedAlertOpen,
              setGoodCameras,
              setConnectivityCameras,
              setLowResolutionCameras,
              setCameraCheckResultDownloadURL,
              navigate
            );
          }}
          text="+ Submit and Add Another Camera"
          disabled={buttonDisabled}
        />
      )}
    </div>
  );
};

export default HandSubmitAndAddAnotherCameraButton;
