import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "./siteAboutInterfaces";
import { CameraHealthTab } from "../CameraHealthTab";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handlePatchCameraHealthData = (
  healthCheckData: any,
  setHealthCheckData: any,
  cameraHealthPatchObject: any,
  setCameraHealthPatchObject: any,
  setCameraHealthChangesMade: Dispatcher<boolean>,
  setActiveTab: any,
  setAlertState: any,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setEditMode: Dispatcher<boolean>,
  siteInfo: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handlePatchCameraHealthData(
      healthCheckData,
      setHealthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      setActiveTab,
      setAlertState,
      setBackdropLoadingScreenOn,
      setEditMode,
      siteInfo,
      navigate
    );
  };

  AxiosUI.patch(
    `/healthcheck/${healthCheckData.id}/`,
    cameraHealthPatchObject
  ).then(
    (response: AxiosResponse) => {
      setHealthCheckData(response.data);
      setCameraHealthPatchObject({});
      setCameraHealthChangesMade(false);
      setEditMode(false);
      setAlertState((previousState: any) => {
        return { ...previousState, healthCheckUpdateSuccess: true };
      });
      setActiveTab(
        <CameraHealthTab healthCheckData={response.data} siteInfo={siteInfo} />
      );
      setBackdropLoadingScreenOn(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setCameraHealthPatchObject({});
        setCameraHealthChangesMade(false);
        setEditMode(false);
        setAlertState((previousState: any) => {
          return { ...previousState, healthCheckUpdateFail: true };
        });
        setActiveTab(
          <CameraHealthTab
            healthCheckData={healthCheckData}
            siteInfo={siteInfo}
          />
        );
        setBackdropLoadingScreenOn(false);
      });
    }
  );
};
