import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { DialogActions } from "@mui/material";
import useWindowDimensions from "../../common/useWindowDimensions";
import { handleSubmitAddCalendar } from "./CalendarsUtils/handleSubmitAddCalendar";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import ErrorBoundary from "../../../ErrorBoundary";

export const StepThreeAddCalendar = ({
  siteOptions,
  loadingSites,
  assignedSites,
  setAssignedSites,
  newCalendarInfo,
  calendarEvents,
  setVisibleScreen,
  calendarData,
  setCalendarData,
  setCalendarAlerts,
  setAddCalendarScreenOpen,
  setBackButtonHidden,
  navigate,
}: {
  siteOptions: any;
  loadingSites: boolean;
  assignedSites: any;
  setAssignedSites: any;
  newCalendarInfo: any;
  calendarEvents: any;
  setVisibleScreen: any;
  calendarData: any;
  setCalendarData: any;
  setCalendarAlerts: any;
  setAddCalendarScreenOpen: any;
  setBackButtonHidden: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  const siteIds = new Map();

  const siteNames = new Map();

  const allSiteIds: number[] = [];

  const [calcuatedHeight, setCalculatedHeight] = React.useState(0);

  const { width, height } = useWindowDimensions();

  React.useEffect(() => {
    setCalculatedHeight(height - 225);
  }, [height, width]);

  React.useEffect(() => {
    siteOptions.forEach((object: any) => {
      allSiteIds.push(object.id);
      siteIds.set(object.name, object.id);
      siteNames.set(object.id, object.name);
    });
  }, []);

  const [updatedAssignedSites, setUpdatedAssignedSites] =
    React.useState<number[]>(assignedSites);

  return (
    <ErrorBoundary>
      <div>
        {loadingSites && (
          <div className="absolute top-[270] left-[230]">
            <CircularProgress />
          </div>
        )}
        {!loadingSites && (
          <div className="absolute top-[170px] left-[30px] font-mulish overflow-scroll">
            <TableContainer
              sx={{
                maxHeight: calcuatedHeight,
                width: 540,
                overflow: "scroll",
              }}
            >
              <List
              // sx={{
              //   width: "100%",
              //   maxWidth: 460,
              //   maxHeight: 400,
              // }}
              >
                {siteOptions.map((site: any) => {
                  return (
                    <ListItem key={site.id} sx={{ width: 400 }}>
                      <ListItemButton>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={updatedAssignedSites.includes(site.id)}
                            tabIndex={-1}
                            disableRipple
                            onChange={(event, checked) => {
                              if (checked) {
                                setAssignedSites([
                                  ...updatedAssignedSites,
                                  site.id,
                                ]);
                                setUpdatedAssignedSites([
                                  ...updatedAssignedSites,
                                  site.id,
                                ]);
                              } else {
                                setAssignedSites(
                                  updatedAssignedSites.filter(
                                    (number) => number !== site.id
                                  )
                                );
                                setUpdatedAssignedSites(
                                  updatedAssignedSites.filter(
                                    (number) => number !== site.id
                                  )
                                );
                              }
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontFamily: "Mulish",
                            color: "black",
                          }}
                          id={site.name}
                          primary={`${site.name}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </TableContainer>
            <DialogActions>
              <div>
                <OrangeTextButton
                  text="Submit"
                  disabled={false}
                  onClick={() => {
                    setBackButtonHidden(true);
                    setVisibleScreen(
                      <StatusNotificationScreen
                        styling="absolute first-line:font-black font-mulish text-[20px] left-[130px] text-center text-[#283E6D] w-[400px] top-80"
                        text={
                          <p>
                            Adding new Calendar.
                            <br />
                            This might take a few seconds.
                          </p>
                        }
                      />
                    );
                    handleSubmitAddCalendar(
                      newCalendarInfo,
                      calendarEvents,
                      updatedAssignedSites,
                      setVisibleScreen,
                      calendarData,
                      setCalendarData,
                      setCalendarAlerts,
                      setAddCalendarScreenOpen,
                      navigate
                    );
                  }}
                />
              </div>
            </DialogActions>
          </div>
        )}
        {!loadingSites && (
          <div className="absolute left-[20px] top-[150px] font-bold font-mulish bg-white h-[50px] w-[600px] text-black">
            <Checkbox
              onChange={(event, checked) => {
                const allSiteIds: number[] = [];
                siteOptions.forEach((object: any) => {
                  allSiteIds.push(object.id);
                });
                if (checked) {
                  setAssignedSites(allSiteIds);
                  setUpdatedAssignedSites(allSiteIds);
                } else {
                  setAssignedSites([]);
                  setUpdatedAssignedSites([]);
                }
              }}
            />
            Select All
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};
