import React from "react";

export const handleUpdateNotes = (
  selectedRow: any,
  noteText: string,
  alertsData: any,
  setAlertsData: any
) => {
  const updatedAlertsAllData = alertsData.all;
  const updatedAlertsErrorsData = alertsData.errors;
  const updatedAlertsWarningsData = alertsData.warnings;
  const updatedAlertsPendingData = alertsData.pending;
  const updatedAlertsResolvedData = alertsData.resolved;

  const updatedSelectedRow = selectedRow;

  updatedSelectedRow.notes = noteText;

  if (
    updatedSelectedRow.error_type === "connectivity" ||
    updatedSelectedRow.error_type === "scene_change"
  ) {
    const selectedIndexAllData = updatedAlertsAllData.findIndex(
      (object: any) =>
        object.start_timestamp === updatedSelectedRow.start_timestamp
    );
    updatedAlertsAllData[selectedIndexAllData] = updatedSelectedRow;

    const selectedIndexErrorsData = updatedAlertsErrorsData.findIndex(
      (object: any) =>
        object.start_timestamp === updatedSelectedRow.start_timestamp
    );
    updatedAlertsErrorsData[selectedIndexErrorsData] = updatedSelectedRow;
  }

  if (
    updatedSelectedRow.error_type === "motion_status" ||
    updatedSelectedRow.error_type === "image_quality" ||
    updatedSelectedRow.error_type === "stream_quality" ||
    updatedSelectedRow.error_type === "recording_status"
    // ||
    // updatedSelectedRow.error_type === "license_info"
  ) {
    const selectedIndexAllData = updatedAlertsAllData.findIndex(
      (object: any) =>
        object.start_timestamp === updatedSelectedRow.start_timestamp
    );
    updatedAlertsAllData[selectedIndexAllData] = updatedSelectedRow;

    const selectedIndexWarningsData = updatedAlertsWarningsData.findIndex(
      (object: any) =>
        object.start_timestamp === updatedSelectedRow.start_timestamp
    );
    updatedAlertsWarningsData[selectedIndexWarningsData] = updatedSelectedRow;
  }

  if (updatedSelectedRow.status === "resolved") {
    updatedAlertsResolvedData.push(updatedSelectedRow);
  }

  if (updatedSelectedRow.status === "pending") {
    updatedAlertsPendingData.push(updatedSelectedRow);
  }

  setAlertsData({
    all: updatedAlertsAllData,
    errors: updatedAlertsErrorsData,
    warnings: updatedAlertsWarningsData,
    pending: updatedAlertsPendingData,
    resolved: updatedAlertsResolvedData,
  });
};
