import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const AlertsDataToggleSwitch = ({
  setCurrentData,
  dataUsageOn,
  setDataUsageOn,
}: {
  setCurrentData: any;
  dataUsageOn: boolean;
  setDataUsageOn: Dispatcher<boolean>;
}) => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#283E6D",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#FF8400",
      boxSizing: "border-box",
    },
  }));

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography className="text-actuate-orange ">Data Usage</Typography>
      <AntSwitch
        checked={dataUsageOn}
        inputProps={{ "aria-label": "ant design" }}
        onChange={(event: any, value: any) => {
          if (value) {
            setDataUsageOn(true);
            setCurrentData("alertVolumeData");
          } else {
            setDataUsageOn(false);
            setCurrentData("usageData");
          }
        }}
      />
      <Typography className="text-actuate-blue ">Alert Volume</Typography>
    </Stack>
  );
};
