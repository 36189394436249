import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Field } from "formik";
import { createViewTypeHelperText } from "./createViewTypeHelperText";

export const ChoiceFieldType = ({
  config,
  formik,
  setErrorState,
  setChoicesChanger,
  choicesChanger,
}: {
  config: any;
  formik: any;
  setErrorState: any;
  setChoicesChanger: any;
  choicesChanger: any;
}) => {
  const options = config.options;
  const optionsIds = new Map();
  const optionNames = new Map();
  options.forEach((option: any) => {
    optionsIds.set(option[0], option[1]);
  });
  options.forEach((option: any) => {
    optionNames.set(option[1], option[0]);
  });

  const [helperText, setHelperText] = React.useState(config.helperText);

  React.useEffect(() => {
    if (config.validation) {
      setErrorState((previousState: any) => {
        return { ...previousState, [config.id]: true };
      });
    }
  }, []);

  return (
    <div key={config.id}>
      <Autocomplete
        disablePortal
        id={config.id}
        options={config.options.map((option: string[]) => {
          return option[0];
        })}
        value={optionNames.get(formik.values[config.field])}
        onChange={(event: any, newInputValue: any) => {
          formik.values[config.field] = optionsIds.get(newInputValue);
          setChoicesChanger(!choicesChanger);
          if (
            config.id === "default_alarm" &&
            newInputValue === "Webhooks" &&
            location.href.includes("addsite")
          ) {
            localStorage.setItem(
              "showWebHooksOnboardingScreen",
              JSON.stringify(true)
            );
          }
          if (
            config.id === "default_alarm" &&
            newInputValue !== "Webhooks" &&
            location.href.includes("addsite")
          ) {
            localStorage.setItem(
              "showWebHooksOnboardingScreen",
              JSON.stringify(false)
            );
          }
          if (
            config.id === "camera_view" &&
            location.href.includes("addcamera")
          ) {
            createViewTypeHelperText(newInputValue, setHelperText);
          }
          if (config.id === "default_alarm") {
            if (newInputValue === "Actuate Only") {
              localStorage.setItem("immixAlertsSite", "false");
              localStorage.setItem("sentinelAlertsSite", "false");
              localStorage.setItem("sureviewAlertsSite", "false");
            } else if (newInputValue === "Immix") {
              localStorage.setItem("immixAlertsSite", "true");
              localStorage.setItem("sentinelAlertsSite", "false");
              localStorage.setItem("sureviewAlertsSite", "false");
            } else if (newInputValue === "SureView") {
              localStorage.setItem("immixAlertsSite", "false");
              localStorage.setItem("sureviewAlertsSite", "true");
              localStorage.setItem("sentinelAlertsSite", "false");
            } else if (newInputValue === "Sentinel") {
              localStorage.setItem("immixAlertsSite", "false");
              localStorage.setItem("sureviewAlertsSite", "false");
              localStorage.setItem("sentinelAlertsSite", "true");
            } else {
              localStorage.setItem("immixAlertsSite", "false");
              localStorage.setItem("sentinelAlertsSite", "false");
              localStorage.setItem("sureviewAlertsSite", "false");
            }
          }
          if (config.id === "group" && location.href.includes("addsite")) {
            localStorage.setItem("groupSelected", newInputValue);
          }
        }}
        sx={{ width: 350 }}
        renderInput={(params) => (
          <Field
            {...params}
            variant="standard"
            label={config.label}
            autoComplete="off"
            id={config.id}
            name={config.id}
            helperText={helperText}
            as={TextFieldComponent}
          />
        )}
      />
    </div>
  );
};

const TextFieldComponent = (props: any) => <TextField {...props} />;
