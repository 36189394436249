import TableCell from "@mui/material/TableCell";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

const armedText =
  "A metric that shares if the site is scheduled to send alerts at the time of viewing or not. If the icon is a yellow or grey alert symbol, hovering over it will specify any issues that are present. A green check indicates the site is scheduled to send alerts at the time of viewing. A red X indicates the site is disarmed.";
const deployedText =
  "An indication of whether the site has been edited since its last deployment. A green checkmark will show if the most current version of the site is running and a red x will show if the changes to the site have not been synced - in which case it should be re-synced";
const motionPercentageText =
  "The percentage of the time Actuate receives motion from the site across all it's cameras. Normal numbers are between 1% and 10%.";

export const createAboutTableInfoLabel = (labelString: string) => {
  let itemText = "";
  if (labelString === "Armed") {
    itemText = armedText;
  } else if (labelString === "Motion %") {
    itemText = motionPercentageText;
  } else if (labelString === "Synced") {
    itemText = deployedText;
  }
  return (
    <TableCell
      key={labelString === "Motion %" ? "motion_percentage" : labelString}
      align="left"
      sx={{ fontFamily: "Mulish", fontWeight: "Bold", fontSize: "15px" }}
    >
      {labelString}
      &nbsp;
      <Tooltip
        title={itemText}
        placement="top"
        sx={{ fontSize: "15px", fontFamily: "mulish" }}
      >
        <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
      </Tooltip>
    </TableCell>
  );
};
