import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { getRefreshTimeout } from "../../utils/getRefreshTimeout";
import { getSitesByParentGroupAlerts } from "../Alerts/AlertsUtils/getSitesByParentGroupAlerts";

export const ParentGroupsDropDownHM = ({
  userFilters,
  setUserFilters,
  setSiteOptions,
  setSiteDropDownValue,
  setCameraOptions,
  setCameraDropDownValue,
  setLoadingSites,
  navBarCollapsed,
  navigate,
}: {
  userFilters: any;
  setUserFilters: any;
  setSiteOptions: any;
  setSiteDropDownValue: any;
  setCameraOptions: any;
  setCameraDropDownValue: any;
  setLoadingSites: any;
  navBarCollapsed: boolean;
  navigate: NavigateFunction;
}) => {
  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const parentIds = new Map();
  const parentNames = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });
  parentAccounts.forEach((object: any) => {
    parentNames.set(object.id, object.name);
  });

  return (
    <div key="parent_groups_dropdown">
      <Autocomplete
        // size="small"
        disablePortal
        options={parentAccounts.map((object: any) => {
          return object.name;
        })}
        defaultValue={parentNames.get(Number(userFilters.parent_group))}
        onChange={(event, value: string | null) => {
          setSiteOptions([]);
          setCameraOptions([]);
          setSiteDropDownValue("");
          setCameraDropDownValue("");
          if (value !== null) {
            getSitesByParentGroupAlerts(
              parentIds.get(value),
              navigate,
              setSiteOptions,
              setLoadingSites
            );
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                parent_group: parentIds.get(value),
                site: "",
                camera: "",
              };
            });
          } else {
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                parent_group: "",
                site: "",
                camera: "",
              };
            });
          }
        }}
        sx={!navBarCollapsed ? { width: 175 } : { width: 200 }}
        renderInput={(params) => (
          <TextField {...params} label="Parent Group" autoComplete="off" />
        )}
      />
    </div>
  );
};
