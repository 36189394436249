import React from "react";

export const handleCreateScheduleGridObjectToSubmit = (
  dayOfWeekArray: number[],
  startTime: string,
  endTime: string,
  alwaysOn: boolean,
  duskToDawn: string,
  bufferTime: string,
  timeRanges: any,
  duskToDawnLocation: any
) => {
  const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];

  const returnArray = [];

  //remove any numbers from the daysOfWeek array that are included in the dayOfWeekArray
  const filteredDaysOfWeek = daysOfWeek.filter(
    (day) => !dayOfWeekArray.includes(day)
  );

  returnArray.push({
    always_on: alwaysOn,
    buffer_time: bufferTime ? bufferTime : 0,
    customer: localStorage.siteId,
    day_of_week: dayOfWeekArray,
    enabled: true,
    end_time: endTime
      ? endTime.length === 5
        ? `${endTime}:00`
        : endTime
      : null,
    is_overide: false,
    location_dusk_dawn: duskToDawn,
    start_time: startTime
      ? startTime.length === 5
        ? `${startTime}:00`
        : startTime
      : null,
  });

  filteredDaysOfWeek.forEach((day) => {
    returnArray.push({
      always_on: timeRanges[day] === "Always On",
      buffer_time: duskToDawnLocation[day][1] ? duskToDawnLocation[day][1] : 0,
      customer: localStorage.siteId,
      day_of_week: [day],
      enabled: true,
      end_time:
        timeRanges[day] === "Always On" || timeRanges[day] === "Dusk to Dawn"
          ? "23:59:59"
          : timeRanges[day].slice(8, 13),
      is_overide: false,
      location_dusk_dawn: duskToDawnLocation[day][0],
      start_time:
        timeRanges[day] === "Always On" || timeRanges[day] === "Dusk to Dawn"
          ? "00:00:00"
          : timeRanges[day].slice(0, 5),
    });
  });

  return returnArray;
};
