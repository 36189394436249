import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const OrangeTextButton = ({
  onClick,
  text,
  disabled,
}: {
  onClick: any;
  text: any;
  disabled: boolean;
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        type="button"
        color="primary"
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
};
