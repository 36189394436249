import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../../common/TableHead";

export const RecordingServerTab = ({
  recordingServerData,
}: {
  recordingServerData: any;
}) => {
  const titlesArray = ["Name", "Server IP", "Camera Count"];

  return (
    <TableContainer component={Paper} sx={{ width: "screen" }}>
      <Table
        sx={{
          postion: "absolute",
          width: "screen",
          fontFamily: "Mulish",
        }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          {recordingServerData.map((row: any) => {
            return (
              <TableRow key={row.id}>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {row.name}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {row.server_ip}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {row.cameras}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
