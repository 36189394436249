import React from "react";

export const capitalizeMetrics = (metrics: string): string[] => {
  return metrics.split(",").map((metric) =>
    metric
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
  );
};
