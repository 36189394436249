import { createApi } from "../../utils/createApi";
import { AxiosResponse, AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";

export const cameraRefreshCheck = async (
  setVisibleScreen: any,
  navigate: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    cameraRefreshCheck(setVisibleScreen, navigate);
  };
  localStorage.setItem("noAutoAddCamerasDialogOpen", "true");
  await AxiosUI.get(`nvr_camera/${localStorage.siteId}/refresh_status/`).then(
    async (response: AxiosResponse) => {
      let getNVRCamerasFail = false;
      if (response.data.is_refreshed && !response.data.refresh_failed) {
        await AxiosUI.get(
          `nvr_camera/?customer__id=${localStorage.siteId}`
        ).then(
          (response: AxiosResponse) => {
            if (response.data.count === 0) {
              localStorage.setItem("noAutoAddCamerasDialogOpen", "true");
            }
            if (response.data.count > 0) {
              localStorage.setItem("noAutoAddCamerasDialogOpen", "false");
            }
            getNVRCamerasFail = false;
          },
          (reason: AxiosError) => {
            getNVRCamerasFail = true;
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
          }
        );
        !getNVRCamerasFail &&
          (await AxiosUI.get(
            `nvr_camera/extra_fields/?customer__id=${localStorage.siteId}`
          ).then(
            (response: any) => {
              localStorage.setItem(
                "additionalFieldNamesArray",
                JSON.stringify(response.data)
              );
              navigate("/sites/autoaddcamera");
            },
            (reason: AxiosError) => {
              universalAPIErrorHandler(
                reason,
                navigate,
                retryFunction,
                () => {}
              );
            }
          ));
      } else if (!response.data.is_refreshed && !response.data.refresh_failed) {
        cameraRefreshCheck(setVisibleScreen, navigate);
      } else {
        navigate(`/sites/aboutsite/${localStorage.siteId}`);
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
