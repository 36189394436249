import React from "react";

export const handleRemoveOpenAlertSearchParams = (
  searchParams: any,
  setSearchParams: any
) => {
  const params = new URLSearchParams(searchParams);
  params.delete("open_alert");

  setSearchParams(params);
};
