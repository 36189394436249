import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import StepOneAddCalendar from "./StepOneAddCalendar";
import { ThemeProvider } from "@mui/material/styles";
import { Stepper, Step, StepLabel } from "@mui/material";
import { theme } from "../AddSite/StepsStepper";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { StepTwoAddCalendar } from "./StepTwoAddCalendar";
import { StepThreeAddCalendar } from "./StepThreeAddCalendar";
import { useNavigate } from "react-router-dom";

export const AddCalendarScreen = ({
  setAddCalendarScreenOpen,
  calendarData,
  setCalendarData,
  setCalendarAlerts,
  backButtonHidden,
  setBackButtonHidden,
}: {
  setAddCalendarScreenOpen: Dispatcher<boolean>;
  calendarData: any;
  setCalendarData: any;
  setCalendarAlerts: any;
  backButtonHidden: boolean;
  setBackButtonHidden: Dispatcher<boolean>;
}) => {
  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const parentIds = new Map();
  const parentNames = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });
  parentAccounts.forEach((object: any) => {
    parentNames.set(object.id, object.name);
  });

  const [newCalendarInfo, setNewCalendarInfo] = React.useState<any>({
    group: "",
    name: "",
  });

  const navigate = useNavigate();

  const steps = ["Add New Calendar", "Add Events", "Add Sites"];

  const [activeStep, setActiveStep] = React.useState(0);

  const [calendarEvents, setCalendarEvents] = React.useState<any[]>([]);

  const [loadingSites, setLoadingSites] = React.useState(true);

  const [siteOptions, setSiteOptions] = React.useState<any[]>([]);

  const [assignedSites, setAssignedSites] = React.useState<number[]>([]);

  const [visibleScreen, setVisibleScreen] = React.useState(
    <StepOneAddCalendar
      newCalendarInfo={newCalendarInfo}
      setNewCalendarInfo={setNewCalendarInfo}
      parentAccounts={parentAccounts}
      parentIds={parentIds}
      parentNames={parentNames}
      navigate={navigate}
      setSiteOptions={setSiteOptions}
      setLoadingSites={setLoadingSites}
    />
  );

  return (
    <div>
      <div className="absolute top-0 right-[600px] h-full bg-white">
        <div className="absolute h-full bg-white w-[600px]">
          <div className="bg-actuate-blue absolute w-[600px] h-[50px]">
            <button
              className="absolute right-6 top-[10px] text-white text-[18px]"
              onClick={() => {
                setAddCalendarScreenOpen(false);
              }}
            >
              X
            </button>
            <div
              className={
                "text-white absolute top-[1px] right-[450px] font-mulish text-[18px] p-3"
              }
            >
              Add Calendar
            </div>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <div className="absolute top-[70px] left-[20px]">
                <Stepper activeStep={activeStep} sx={{ width: 565 }}>
                  {steps.map((label) => (
                    <Step
                      key={label}
                      color="primary"
                      sx={{ fontFamily: "Mulish" }}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </ThemeProvider>
            {activeStep !== 2 && (
              <div className="absolute right-4 top-28">
                <OrangeTextButton
                  disabled={!newCalendarInfo.name || !newCalendarInfo.group}
                  onClick={() => {
                    if (activeStep === 0) {
                      setActiveStep(1);
                      setVisibleScreen(
                        <StepTwoAddCalendar
                          calendarEvents={calendarEvents}
                          setCalendarEvents={setCalendarEvents}
                        />
                      );
                    }
                    if (activeStep === 1) {
                      setActiveStep(2);
                      setVisibleScreen(
                        <StepThreeAddCalendar
                          siteOptions={siteOptions}
                          loadingSites={loadingSites}
                          assignedSites={assignedSites}
                          setAssignedSites={setAssignedSites}
                          newCalendarInfo={newCalendarInfo}
                          calendarEvents={calendarEvents}
                          setVisibleScreen={setVisibleScreen}
                          calendarData={calendarData}
                          setCalendarData={setCalendarData}
                          setCalendarAlerts={setCalendarAlerts}
                          setAddCalendarScreenOpen={setAddCalendarScreenOpen}
                          setBackButtonHidden={setBackButtonHidden}
                          navigate={navigate}
                        />
                      );
                    }
                  }}
                  text="Next"
                />
              </div>
            )}
          </div>
          {activeStep !== 0 && (
            <div className="absolute right-[520px] top-28">
              {!backButtonHidden && (
                <OrangeTextButton
                  disabled={false}
                  onClick={() => {
                    if (activeStep === 1) {
                      setActiveStep(0);
                      setVisibleScreen(
                        <StepOneAddCalendar
                          newCalendarInfo={newCalendarInfo}
                          setNewCalendarInfo={setNewCalendarInfo}
                          parentAccounts={parentAccounts}
                          parentIds={parentIds}
                          parentNames={parentNames}
                          navigate={navigate}
                          setSiteOptions={setSiteOptions}
                          setLoadingSites={setLoadingSites}
                        />
                      );
                    }
                    if (activeStep === 2) {
                      setActiveStep(1);
                      setVisibleScreen(
                        <StepTwoAddCalendar
                          calendarEvents={calendarEvents}
                          setCalendarEvents={setCalendarEvents}
                        />
                      );
                    }
                  }}
                  text="Back"
                />
              )}
            </div>
          )}
        </div>

        <div>{visibleScreen}</div>
      </div>
    </div>
  );
};
