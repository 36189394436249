export const handleChangeRowsPerPage = (
  event: React.ChangeEvent<HTMLInputElement>,
  setRowsPerPage: any,
  setPage: any
) => {
  setRowsPerPage(event.target.value);
  localStorage.setItem("sitesRowsPerPage", event.target.value);
  setPage(0);
  localStorage.setItem("sitesTablePage", "0");
};
