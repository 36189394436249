import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../utils/themeCreator";
import { Button } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const AddGroupUserButton = ({
  setAddGroupUserScreenOpen,
  setAlertState,
}: {
  setAddGroupUserScreenOpen: Dispatcher<boolean>;
  setAlertState: any;
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        type="button"
        color="primary"
        onClick={() => {
          setAddGroupUserScreenOpen(true);
          setAlertState((previousState: any) => {
            return {
              ...previousState,
              updateUserSuccess: false,
              deleteUserSuccess: false,
              addUserSuccess: false,
              emailSent: false,
              noData: false,
            };
          });
        }}
      >
        + Add Group User
      </Button>
    </ThemeProvider>
  );
};
