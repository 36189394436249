import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const ImmixTestSuccessDialog = ({
  immixTestSuccessDialogOpen,
  setImmixTestSuccessDialogOpen,
}: {
  immixTestSuccessDialogOpen: boolean;
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>;
}) => {
  return (
    <Dialog open={immixTestSuccessDialogOpen}>
      <DialogContent>
        <p className="font-mulish text-[20px]">Immix test succeeded!</p>
        <br></br>
        <p>
          The test alert successfully reached your Immix server. If you are
          still experiencing issues viewing alerts in Immix, please contact
          support@actuate.ai.{" "}
        </p>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setImmixTestSuccessDialogOpen(false);
          }}
          text="Ok"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
