import {
  Dispatcher,
  SiteCameras,
  SiteCamerasResult,
} from "./siteAboutInterfaces";

export const checkIfAllCamerasDecativated = (
  camerasObject: SiteCameras,
  setDeativateInquiryDialogOpen: Dispatcher<boolean>
) => {
  let allFalse = true;
  camerasObject.results.forEach((item: SiteCamerasResult) => {
    if (item.active) {
      allFalse = false;
    }
  });
  if (camerasObject.count === 0 || location.href.includes("addcamera")) {
    allFalse = false;
  }
  if (allFalse) {
    setDeativateInquiryDialogOpen(true);
  }
};
