import React from "react";

export const totalUpPrecisionRecallData = (precisionRecallData: any) => {
  const returnedData = {
    date: "",
    date_str: precisionRecallData[0].date_str,
    true_positives: 0,
    false_positives: 0,
    false_negatives: 0,
    true_negatives: 0,
    unclassified: 0,
    total_clips: 0,
    precision: null,
    recall: null,
  };
  if (precisionRecallData.length > 1) {
    returnedData.date_str = `${
      precisionRecallData[precisionRecallData.length - 1].date_str
    } - ${returnedData.date_str}`;
  }
  precisionRecallData.forEach((object: any) => {
    returnedData.true_positives =
      returnedData.true_positives + object.true_positives;
    returnedData.false_positives =
      returnedData.false_positives + object.false_positives;
    returnedData.false_negatives =
      returnedData.false_negatives + object.false_negatives;
    returnedData.true_negatives =
      returnedData.true_negatives + object.true_negatives;
    returnedData.unclassified = returnedData.unclassified + object.unclassified;
    returnedData.total_clips = returnedData.total_clips + object.total_clips;
    if (object.precision) {
      returnedData.precision = object.precision;
    }
    if (object.recall) {
      returnedData.recall = object.recall;
    }
  });
  return returnedData;
};
