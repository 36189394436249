import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { handleImmixServerInput } from "./handleImmixServerInput";
import { handleImmixPortInput } from "./handleImmixPortInput";
import { productsMatcher } from "../../../utils/productsMatcher";
import { createCamerasObject } from "./createCamerasObject";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getAutoAddData = (
  setCamerasList: any,
  setCamerasObject: any,
  setFetchingAutoAddData: Dispatcher<boolean>,
  checkboxesChecked: any,
  setCheckboxesChecked: any,
  setClientProducts: any,
  setImmixSite: Dispatcher<boolean>,
  setSureviewSite: Dispatcher<boolean>,
  setProductsArray: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    getAutoAddData(
      setCamerasList,
      setCamerasObject,
      setFetchingAutoAddData,
      checkboxesChecked,
      setCheckboxesChecked,
      setClientProducts,
      setImmixSite,
      setSureviewSite,
      setProductsArray,
      navigate
    );
  };

  AxiosUI.get(`nvr_camera/?customer__id=${localStorage.siteId}`).then(
    (response: AxiosResponse) => {
      if (response.data.count === 0) {
        localStorage.setItem("noAutoAddCamerasDialogOpen", "true");
      }
      if (response.data.count > 0) {
        localStorage.setItem("noAutoAddCamerasDialogOpen", "false");
      }
      setCamerasList(response.data);
      setCamerasObject(createCamerasObject(response.data.results));
      const createdCamerasObject = createCamerasObject(response.data.results);
      const immixAlertPostObject = JSON.parse(
        localStorage.immixAlertPostObject
      );
      if (immixAlertPostObject.immix_server) {
        handleImmixServerInput(
          createdCamerasObject,
          setCamerasObject,
          immixAlertPostObject.immix_server
        );
      }
      if (immixAlertPostObject.immix_port) {
        handleImmixPortInput(
          createdCamerasObject,
          setCamerasObject,
          immixAlertPostObject.immix_port
        );
      }
      const nvrResults = response.data.results;
      AxiosUI.get(
        `nvr_camera/product/?customer__id=${localStorage.siteId}`
      ).then((response: AxiosResponse) => {
        const productResponse = response.data;
        setProductsArray(response.data);
        AxiosUI.get(
          `customer/${localStorage.siteId}/camera_onboarding_forms/`
        ).then(
          (response: AxiosResponse) => {
            const selectedProducts = response.data.initial_data.products;
            selectedProducts.forEach((id: any) => {
              if (productsMatcher("Name", id, productResponse)) {
                const name = productsMatcher("Name", id, productResponse);
                setCheckboxesChecked((previousState: any) => {
                  return { ...previousState, [name]: false };
                });
                checkboxesChecked[name] = false;
              }
              nvrResults.forEach((object: any) => {
                const name = productsMatcher("Name", id, productResponse);
                const newName = `${name}${object.id}`;
                setCheckboxesChecked((previousState: any) => {
                  return { ...previousState, [newName]: false };
                });
              });
            });
            const productsArray: any = [];
            selectedProducts.forEach((id: any) => {
              if (productsMatcher("Name", id, productResponse)) {
                productsArray.push(
                  productsMatcher("Name", id, productResponse)
                );
              }
            });
            setClientProducts(productsArray);
            if (localStorage.immixAlertsSite === "true") {
              setImmixSite(true);
            }
            if (localStorage.sureviewAlertsSite === "true") {
              setSureviewSite(true);
            }
            setFetchingAutoAddData(false);
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              JSON.parse(localStorage.selectedProducts).forEach((id: any) => {
                if (productsMatcher("Name", id, productResponse)) {
                  const name = productsMatcher("Name", id, productResponse);
                  setCheckboxesChecked((previousState: any) => {
                    return { ...previousState, [name]: false };
                  });
                  checkboxesChecked[name] = false;
                }
                nvrResults.forEach((object: any) => {
                  const name = productsMatcher("Name", id, productResponse);
                  const newName = `${name}${object.id}`;
                  setCheckboxesChecked((previousState: any) => {
                    return { ...previousState, [newName]: false };
                  });
                });
              });

              const productsArray: any = [];
              JSON.parse(localStorage.selectedProducts).forEach(
                (id: any) => {
                  if (productsMatcher("Name", id, productResponse)) {
                    productsArray.push(
                      productsMatcher("Name", id, productResponse)
                    );
                  }
                }
                // (array: any) => {
                //   productsArray.push(array[0])
                // }
              );
              setClientProducts(productsArray);
              if (localStorage.immixAlertsSite === "true") {
                setImmixSite(true);
              }
              if (localStorage.sureviewAlertsSite === "true") {
                setSureviewSite(true);
              }

              setFetchingAutoAddData(false);
            });
          }
        );
      }),
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert("Error retrieving data, please refresh and try again");
            setFetchingAutoAddData(false);
          });
        };
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setFetchingAutoAddData(false);
      });
    }
  );
};
