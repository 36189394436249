import React from "react";
import { NavBar } from "../../common/NavBar";
import { ActionLogsTable } from "./ActionLogsTable";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";

import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs } from "@mui/material";
import {
  getNumbersAfterActionLogs,
  handleFetchActionLogsData,
} from "./ActionLogsUtils/handleFetchActionLogsData";

export const ActionLogs = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: any;
  setNavBarCollapsed: any;
}) => {
  const [auditLogsData, setActionLogsData] = React.useState([]);

  const [fetchingActionLogsData, setFetchingActionLogsData] =
    React.useState(true);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [paginationNumber, setPaginationNumber] = React.useState(1);

  const [loadingMoreActionLogsData, setLoadingMoreActionLogsData] =
    React.useState(false);

  const [siteName, setSiteName] = React.useState("");

  const navigate = useNavigate();

  React.useEffect(() => {
    handleFetchActionLogsData(
      setActionLogsData,
      setFetchingActionLogsData,
      setSiteName,
      navigate
    );
  }, []);

  return (
    <div>
      <div className="bg-actuate-grey absolute w-screen h-screen">
        <BackdropLoadingScreen openState={fetchingActionLogsData} />
        <BackdropLoadingScreen openState={loadingMoreActionLogsData} />
        <div
          className={
            !navBarCollapsed
              ? "absolute top-[20px] left-[150px] right-[1px] h-[19px]] bg-white"
              : "absolute top-[20px] left-[75px] right-[1px] h-[19px]] bg-white"
          }
        >
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="medium" />}
              aria-label="breadcrumb"
              sx={{
                paddingLeft: "20px",
              }}
            >
              {siteName && (
                <Link
                  underline="hover"
                  color="inherit"
                  href={`/sites/aboutsite/${getNumbersAfterActionLogs(
                    location.href
                  )}`}
                  sx={{
                    fontSize: "20px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                >
                  {siteName}
                </Link>
              )}
              <p className="text-black font-bold font-mulish text-[20px] pt-3 pb-3">
                Action Logs
              </p>
            </Breadcrumbs>
          </Stack>
        </div>
        {!fetchingActionLogsData && (
          <div
            className={
              !navBarCollapsed
                ? "absolute left-[150px] top-[95px]"
                : "absolute left-[75px] top-[95px]"
            }
          >
            <ActionLogsTable
              auditLogsData={auditLogsData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              paginationNumber={paginationNumber}
              setLoadingMoreActionLogsData={setLoadingMoreActionLogsData}
              setActionLogsData={setActionLogsData}
              setPaginationNumber={setPaginationNumber}
              navigate={navigate}
            />
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
