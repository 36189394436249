import React from "react";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleCreateSelectedProducts } from "./EditCameraUtils/handleCreateSelectedProducts";

export const ProductsTab = ({
  productsData,
  setProductsData,
  allTabsOpen,
  setAllTabsOpen,
  setBackupProductsData,
}: {
  productsData: any;
  setProductsData: Dispatcher<any>;
  allTabsOpen: boolean;
  setAllTabsOpen: Dispatcher<boolean>;
  setBackupProductsData: any;
}) => {
  const handleOpenNewTab = () => {
    // Use window.open to open a new tab
    window.open("/productrequirements", "_blank");
  };

  const [tabsOpen, setTabsOpen] = React.useState(allTabsOpen);

  const [localProductsData, setLocalProductsData] =
    React.useState(productsData);

  const [selectedGroups, setSelectedGroups] = React.useState<any>({
    intruder: {
      selected: false,
      disabled: false,
      intruder: false,
      bike: false,
      vehicle: false,
    },
    intruderPlus: {
      selected: false,
      disabled: false,
      intruder_plus: false,
      bike_plus: false,
      vehicle_plus: false,
    },
    gun: {
      selected: false,
      gun: false,
      pistol: false,
    },
    crowd: {
      selected: false,
      crowd: false,
    },
    loitering: {
      selected: false,
      loiterer: false,
      vehicle_loiterer: false,
    },
    fallPerson: {
      selected: false,
      fall_person: false,
    },
    noHardHat: {
      selected: false,
      no_hard_hat: false,
    },
    mask: {
      selected: false,
      mask: false,
      mask_compliance: false,
      no_mask: false,
    },
    socialDistancing: {
      selected: false,
      social_distancing: false,
    },
    leftObject: {
      selected: false,
      left_object: false,
    },
    package: {
      selected: false,
      package: false,
    },
    fire: {
      selected: false,
      fire: false,
      smoke: false,
    },
    peopleFlow: {
      selected: false,
      people_flow: false,
    },
    fall: {
      selected: false,
      fall: false,
    },
    vehicleLogoId: {
      selected: false,
      amazon: false,
      dhl: false,
      fedex: false,
      school_bus: false,
      ups: false,
      usps: false,
      envera: false,
      fire_truck: false,
    },
    nonUps: {
      selected: false,
      non_ups: false,
    },
  });

  const [updatingSelectedGroups, setUpdatingSelectedGroups] =
    React.useState(true);

  React.useEffect(() => {
    handleCreateSelectedProducts(
      productsData,
      setSelectedGroups,
      selectedGroups,
      setUpdatingSelectedGroups
    );
    setBackupProductsData(productsData);
    // localStorage.setItem("backupProductsData", JSON.stringify(productsData));
  }, []);

  return (
    <div>
      {!updatingSelectedGroups && (
        <div>
          <div
            className={"relative right-[1px] h-[38px] bg-white min-w-[1250px]"}
          >
            <div className="absolute right-[50px] top-[5px]">
              {!tabsOpen ? (
                <OrangeTextButton
                  onClick={() => {
                    const newTesterData = [...localProductsData];
                    newTesterData.forEach((object: any) => {
                      object.open = true;
                    });
                    setProductsData(newTesterData);
                    setLocalProductsData(newTesterData);
                    setTabsOpen(true);
                    setAllTabsOpen(true);
                  }}
                  text="Open All"
                  disabled={false}
                />
              ) : (
                <OrangeTextButton
                  onClick={() => {
                    const newTesterData = [...localProductsData];
                    newTesterData.forEach((object: any) => {
                      object.open = false;
                    });
                    setProductsData(newTesterData);
                    setLocalProductsData(setLocalProductsData);
                    setTabsOpen(false);
                    setAllTabsOpen(false);
                  }}
                  text="Collapse All"
                  disabled={false}
                />
              )}
            </div>
          </div>
          {localProductsData.map((object: any, index: number) => {
            const objectLabelName = object.label_name;
            return (
              <div key={object.name} className={index === 0 ? "" : "pt-[1px]"}>
                <div
                  className={
                    "relative right-[1px] h-[58px] bg-white min-w-[1250px]"
                  }
                >
                  <div className="absolute left-[20px]">
                    <Typography
                      variant="h6"
                      gutterBottom
                      component="div"
                      fontWeight="bold"
                      sx={{
                        fontFamily: "Mulish",
                        color: "#001943",
                        paddingTop: "10px",
                      }}
                    >
                      {object.name}
                    </Typography>
                  </div>
                  <div className="absolute left-[200px] top-[8px]">
                    <Switch
                      checked={selectedGroups[object.label_name].selected}
                      color="warning"
                      disabled={true}
                    />
                  </div>
                  <div className="absolute right-[150] top-[10px]">
                    <OrangeTextButton
                      onClick={handleOpenNewTab}
                      text="See product requirements"
                      disabled={false}
                    />
                  </div>
                  <div className="absolute right-[50px] top-[10px]">
                    {object.open ? (
                      <button>
                        <KeyboardArrowUpIcon
                          fontSize="large"
                          onClick={() => {
                            const newTesterData = [...localProductsData];
                            newTesterData[index].open =
                              !newTesterData[index].open;
                            setProductsData(newTesterData);
                            setLocalProductsData(newTesterData);
                          }}
                        />
                      </button>
                    ) : (
                      <button>
                        <KeyboardArrowDownIcon
                          fontSize="large"
                          onClick={() => {
                            const newTesterData = [...localProductsData];
                            newTesterData[index].open =
                              !newTesterData[index].open;
                            setProductsData(newTesterData);
                            setLocalProductsData(newTesterData);
                          }}
                        />
                      </button>
                    )}
                  </div>
                </div>
                {object.open && (
                  // create an outlined table that is padded with 10px inside the div
                  <div className="bg-white">
                    <div className="pt-[1px] pl-[40px] pr-[40px] pb-[10px]">
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ fontFamily: "Mulish" }}
                          aria-label="simple table"
                        >
                          {object.name === "Crowd" &&
                            object.metrics.crowd.display_distance && (
                              <TableHeadComponent
                                titlesArray={[
                                  "METRIC",
                                  "ACTIVE",
                                  "SENSITIVITY",
                                  "MIN CROWD SIZE",
                                  "DISTANCE",
                                ]}
                              />
                            )}

                          {object.display_name === "Crowd" &&
                            !object.metrics.crowd.display_distance && (
                              <TableHeadComponent
                                titlesArray={[
                                  "METRIC",
                                  "ACTIVE",
                                  "SENSITIVITY",
                                  "MIN CROWD SIZE",
                                  "",
                                ]}
                              />
                            )}
                          {object.name === "Social Distancing" &&
                            object.metrics.social_distancing
                              .display_distance && (
                              <TableHeadComponent
                                titlesArray={[
                                  "METRIC",
                                  "ACTIVE",
                                  "SENSITIVITY",
                                  "DISTANCE",
                                  "",
                                ]}
                              />
                            )}
                          {object.name === "Social Distancing" &&
                            !object.metrics.social_distancing
                              .display_distance && (
                              <TableHeadComponent
                                titlesArray={[
                                  "METRIC",
                                  "ACTIVE",
                                  "SENSITIVITY",
                                  "",
                                  "",
                                ]}
                              />
                            )}
                          {(object.name === "Left Object" ||
                            object.name === "Loitering") && (
                            <TableHeadComponent
                              titlesArray={[
                                "METRIC",
                                "ACTIVE",
                                "SENSITIVITY",
                                "FRAME THRESH",
                                "",
                              ]}
                            />
                          )}
                          {object.name !== "Crowd" &&
                            object.name !== "Left Object" &&
                            object.name !== "Loitering" &&
                            object.name !== "Social Distancing" && (
                              <TableHeadComponent
                                titlesArray={[
                                  "METRIC",
                                  "ACTIVE",
                                  "SENSITIVITY",
                                  "",
                                  "",
                                ]}
                              />
                            )}
                          <TableBody>
                            {Object.entries(object.metrics).map(
                              ([key, metricsObject]: [string, any]) => {
                                const options = metricsObject.options;
                                const optionsIds = new Map();
                                const distanceOptions =
                                  metricsObject.distance_options;
                                const distanceOptionsIds = new Map();
                                if (metricsObject.options) {
                                  options.forEach((option: any) => {
                                    optionsIds.set(option[1], option[0]);
                                  });
                                }
                                if (metricsObject.distance_options) {
                                  distanceOptions.forEach((option: any) => {
                                    distanceOptionsIds.set(
                                      option[1],
                                      option[0]
                                    );
                                  });
                                }
                                return (
                                  <TableRow key={localStorage.currentCameraId}>
                                    <TableCell
                                      align="left"
                                      sx={{
                                        fontFamily: "Mulish",
                                        width: "200px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {metricsObject.display_name}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{
                                        fontFamily: "Mulish",
                                        width: "200px",
                                      }}
                                    >
                                      {
                                        <Switch
                                          checked={
                                            selectedGroups[objectLabelName][
                                              metricsObject.label_name
                                            ]
                                          }
                                          color="warning"
                                          disabled={true}
                                        />
                                      }
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{
                                        fontFamily: "Mulish",
                                        width: "200px",
                                      }}
                                    >
                                      {metricsObject.option_id
                                        ? optionsIds.get(
                                            Number(metricsObject.option_id)
                                          )
                                        : "--"}
                                    </TableCell>
                                    {object.name === "Social Distancing" &&
                                    object.metrics.social_distancing
                                      .display_distance ? (
                                      <TableCell
                                        align="left"
                                        sx={{
                                          fontFamily: "Mulish",
                                          width: "200px",
                                        }}
                                      >
                                        {object.metrics.social_distancing
                                          .distance
                                          ? distanceOptionsIds.get(
                                              object.metrics.social_distancing
                                                .distance
                                            )
                                          : ""}
                                      </TableCell>
                                    ) : metricsObject.user_input_label ? (
                                      <TableCell
                                        align="left"
                                        sx={{
                                          fontFamily: "Mulish",
                                          width: "200px",
                                        }}
                                      >
                                        {metricsObject.user_input_value}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        align="left"
                                        sx={{
                                          fontFamily: "Mulish",
                                          width: "200px",
                                        }}
                                      ></TableCell>
                                    )}
                                    {object.name === "Crowd" &&
                                    object.metrics.crowd.display_distance ? (
                                      <TableCell
                                        align="left"
                                        sx={{
                                          fontFamily: "Mulish",
                                          width: "200px",
                                        }}
                                      >
                                        {object.metrics.crowd.distance
                                          ? distanceOptionsIds.get(
                                              object.metrics.crowd.distance
                                            )
                                          : ""}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        align="left"
                                        sx={{
                                          fontFamily: "Mulish",
                                          width: "200px",
                                        }}
                                      ></TableCell>
                                    )}
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
