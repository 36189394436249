export const handleImmixPortInput = (
  camerasObject: any,
  setCamerasObject: any,
  inputString: string
) => {
  const newCamerasObject = camerasObject;

  for (const key in newCamerasObject) {
    newCamerasObject[key].immix.immix_port = inputString;
  }

  setCamerasObject(newCamerasObject);
};
