import React from "react";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import { checkedCircleIconSmall } from "../../../../common/checkedCircleIcon";
import { createCameraView } from "../../../EditCamera/EditCameraUtils/createCameraView";

const fishEyeWarningMessage = `Your camera has been flagged as fisheye. For cameras with exclusively intruder(person or vehicle detection), we recommend fisheye dewarping. If interested, please contact support@actuate.ai.`;

const thermalWarningMessage = `Your camera has been flagged as thermal. We recommend our thermal model to improve detection performance. If interested, please contact support@actuate.ai.`;

export const createCameraViewIcon = (obj: any, srcImages: any) => {
  const srcImageString = `${obj.id}full`;
  if (srcImages[srcImageString as keyof typeof srcImages]) {
    const object = srcImages[srcImageString as keyof typeof srcImages];
    if (
      !object.camera_view ||
      object.camera_view_status === "unknown" ||
      obj.camera_view === "normal"
    ) {
      return "Normal";
    } else {
      let warningMessage = object.camera_view_comments;

      if (
        (object.camera_view_status === "warning" ||
          !object.camera_view_status) &&
        object.camera_view === "fisheye"
      ) {
        warningMessage = fishEyeWarningMessage;
      }

      if (
        (object.camera_view_status === "warning" ||
          !object.camera_view_status) &&
        object.camera_view === "thermal"
      ) {
        warningMessage = thermalWarningMessage;
      }

      return (
        <Tooltip title={warningMessage} placement="top">
          <div>
            {createCameraView(obj.camera_view)}&nbsp;
            {object.camera_view_status === "healthy" ? (
              checkedCircleIconSmall
            ) : (
              <WarningIcon sx={{ color: "#F57C00" }} fontSize="small" />
            )}
          </div>
        </Tooltip>
      );
    }
  } else {
    return obj.camera_name;
  }
};
