import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleDownloadReport = (
  userFilters: any,
  setDownloadURL: Dispatcher<string>,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    handleDownloadReport(userFilters, setDownloadURL, navigate);
  };

  const AxiosUI = createApi(``);

  AxiosUI.get(
    `healthcheck_result/download/?group=${userFilters.parent_group}`
  ).then(
    (response: AxiosResponse) => {
      setDownloadURL(response.data.url);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
