import * as React from "react";
import { ScheduleScreen } from "./ScheduleScreen";
import { ModuleScreen } from "../../common/ModuleScreen";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import ErrorBoundary from "../../../ErrorBoundary";
import { useNavigate } from "react-router-dom";

export const AddSchedule = ({
  groupTrees,
  setGroupTrees,
}: {
  groupTrees: any;
  setGroupTrees: Dispatcher<any>;
}) => {
  const [visibleScreen, setVisibleScreen] = React.useState(<></>);

  const [largerScreen, setLargerScreen] = React.useState(true);

  React.useEffect(() => {
    setVisibleScreen(
      <ScheduleScreen
        setVisibleScreen={setVisibleScreen}
        visibleScreen={visibleScreen}
        setLargerScreen={setLargerScreen}
      />
    );
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-black fixed w-full h-full opacity-90">
        <div className="bg-black fixed w-full h-full opacity-10 pointer-events-none"></div>
        <div className="flex justify-end text-left">
          <div
            className={
              largerScreen
                ? "absolute top-[50px] h-full bg-white w-[1450px]"
                : "absolute top-[50px] h-full bg-white w-[600px]"
            }
          ></div>
        </div>
      </div>
      <div className="flex justify-end text-left">
        <div
          className={
            largerScreen
              ? "absolute h-full bg-white w-[1450]px]"
              : "absolute h-full bg-white w-[600px]"
          }
        >
          <div
            className={
              largerScreen
                ? "bg-actuate-blue absolute w-[1450px] h-[50px]"
                : "bg-actuate-blue absolute w-[600px] h-[50px]"
            }
          >
            <button
              className="absolute right-6 top-[10px] text-white text-[18px]"
              onClick={() => {
                localStorage.setItem("cameraNumber", "1");
                if (localStorage.navigatedFromSitesAboutPage === "true") {
                  navigate(-1);
                } else {
                  navigate("/sites");
                }
              }}
            >
              X
            </button>
            <div
              className={
                largerScreen
                  ? "text-white absolute top-[1px] right-[1300px] font-mulish text-[18px] p-3"
                  : "text-white absolute top-[1px] right-[450px] font-mulish text-[18px] p-3"
              }
            >
              Add Schedule
            </div>
          </div>
          <div>{visibleScreen}</div>
        </div>
      </div>
    </div>
  );
};
