import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { createCamerasObject } from "./createCamerasObject";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleCameraSearchAutoAdd = (
  setCamerasList: any,
  setCamerasObject: any,
  searchString: string,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    handleCameraSearchAutoAdd(
      setCamerasList,
      setCamerasObject,
      searchString,
      navigate
    );
  };

  const AxiosUI = createApi(``);

  AxiosUI.get(
    `nvr_camera/?customer__id=${localStorage.siteId}&search=${searchString}`
  ).then(
    (response: AxiosResponse) => {
      setCamerasList(response.data);
      setCamerasObject(createCamerasObject(response.data.results));
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
