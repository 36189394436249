import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { TextField } from "@mui/material";
import {
  OnboardingProgressDiv,
  validIntegrationTypes,
} from "../Onboarding/OnboardingProgressDiv";
import ErrorBoundary from "../../../ErrorBoundary";

export const ImmixSetupScreen = ({
  setVisibleScreen,
}: {
  setVisibleScreen: any;
}) => {
  const [postObject, setPostObject] = React.useState({
    id: -1,
    immix_server: "",
    immix_port: "",
  });

  return (
    <ErrorBoundary>
      <div>
        <p
          className={
            "font-mulish absolute top-[90px] w-[300px] font-bold text-[#283E6D] left-[25px] text-[16px]"
          }
        >
          {`Immix Alert Information`}
        </p>
        <div className="pt-[140px] pl-[30px]">
          <TextField
            value={postObject.immix_server}
            id="immix_server"
            variant="standard"
            label="Immix Server"
            onChange={(event) => {
              setPostObject((previousState: any) => {
                return { ...previousState, immix_server: event.target.value };
              });
            }}
            sx={{ width: "400px" }}
            helperText="What is the url for your immix server."
            type="string"
          />
          <br></br>
          <br></br>
          <TextField
            value={postObject.immix_port}
            id="immix_port"
            variant="standard"
            label="Immix Port"
            onChange={(event) => {
              setPostObject((previousState: any) => {
                return { ...previousState, immix_port: event.target.value };
              });
            }}
            sx={{ width: "400px" }}
            helperText="What is the port for your immix server."
            type="string"
          />
        </div>
        <div className="pt-10 pl-[420px]">
          <div style={{ display: "flex" }}>
            &nbsp;&nbsp;&nbsp;
            <OrangeTextButton
              onClick={() => {
                localStorage.setItem(
                  "immixAlertPostObject",
                  JSON.stringify(postObject)
                );
                setVisibleScreen(
                  <OnboardingProgressDiv
                    percentage={
                      validIntegrationTypes.includes(
                        localStorage.selectedIntegrationType
                      )
                        ? 25
                        : 33
                    }
                    step={1}
                    setVisibleScreen={setVisibleScreen}
                  />
                );
              }}
              text="Submit"
              disabled={!postObject.immix_port || !postObject.immix_server}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
