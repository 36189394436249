import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../common/SearchBar";
import { handleCameraSearchAutoAdd } from "./CameraAutoAddUtils/handleCameraSearchAutoAdd";

export const AutoAddSearchBar = ({
  setCamerasList,
  setCamerasObject,
  navigate,
}: {
  setCamerasList: any;
  setCamerasObject: any;
  navigate: any;
}) => {
  return (
    <Search sx={{ width: 150 }}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search Cameras..."
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleCameraSearchAutoAdd(
            setCamerasList,
            setCamerasObject,
            event.target.value,
            navigate
          );
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </Search>
  );
};
