import React from "react";
import { createApi } from "../../../../utils/createApi";
import { Dispatcher } from "../../SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";

export const handleBulkGroupAction = (
  actionString: string,
  selected: readonly string[],
  renderedRows: any,
  setSiteScreenLoading: Dispatcher<boolean>,
  groupId: string,
  setPageLoading: Dispatcher<boolean>,
  setSelected: Dispatcher<any>,
  setRenderedRows: any,
  setSiteBreadcrumbs: any,
  setSiteScreenAlerts: Dispatcher<any>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);
  setSiteScreenLoading(true);

  const retryFunction = () => {
    handleBulkGroupAction(
      actionString,
      selected,
      renderedRows,
      setSiteScreenLoading,
      groupId,
      setPageLoading,
      setSelected,
      setRenderedRows,
      setSiteBreadcrumbs,
      setSiteScreenAlerts,
      navigate
    );
  };

  if (selected.length === renderedRows.length && actionString !== "deploy") {
    AxiosUI.post(`group/${groupId}/action/${actionString}/`).then(
      () => {
        setSiteScreenLoading(false);
        setPageLoading(true);
        setSelected([]);
        AxiosUI.get(`customer/by_group_id/${groupId}/?page_size=1000`).then(
          (response: AxiosResponse) => {
            const data = response.data.results;
            setRenderedRows(data);
            setPageLoading(false);
            if (actionString === "arm") {
              setSiteScreenAlerts((previousState: any) => {
                return { ...previousState, armGroupSuccess: true };
              });
            }
            if (actionString === "disarm") {
              setSiteScreenAlerts((previousState: any) => {
                return { ...previousState, disarmGroupSuccess: true };
              });
            }
            // if (actionString === "deploy") {
            //   setSiteScreenAlerts((previousState: any) => {
            //     return { ...previousState, deployGroupSuccess: true };
            //   });
            // }
            if (data) {
              const breadcrumbs = response.data.results[0].breadcrumbs;
              setSiteBreadcrumbs(breadcrumbs);
              localStorage.setItem(
                "currentSiteBreadcrumbs",
                JSON.stringify(breadcrumbs)
              );
              if (localStorage.navigatedFromSitesAboutPage === "true") {
                localStorage.setItem("navigatedFromSitesAboutPage", "false");
              }
            }
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              alert("Error Retrieving site data.");
              setRenderedRows([]);
              setPageLoading(false);
            });
          }
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert(`Error ${actionString}ing site. Please try again.`);
          setSiteScreenLoading(false);
        });
      }
    );
  } else {
    selected.forEach(async (cameraId, index) => {
      let action = actionString;
      if (actionString === "deploy") {
        action = "deploy_next";
      }
      await AxiosUI.post(`customer/${cameraId}/action/${action}/`).then(
        () => {
          if (selected.length - 1 === index) {
            setSiteScreenLoading(false);
            setPageLoading(true);
            setSelected([]);
            AxiosUI.get(`customer/by_group_id/${groupId}/?page_size=1000`).then(
              (response: AxiosResponse) => {
                const data = response.data.results;
                setRenderedRows(data);
                setPageLoading(false);
                if (actionString === "arm") {
                  setSiteScreenAlerts((previousState: any) => {
                    return { ...previousState, armGroupSuccess: true };
                  });
                }
                if (actionString === "disarm") {
                  setSiteScreenAlerts((previousState: any) => {
                    return { ...previousState, disarmGroupSuccess: true };
                  });
                }
                if (actionString === "deploy") {
                  setSiteScreenAlerts((previousState: any) => {
                    return { ...previousState, deployGroupSuccess: true };
                  });
                }
                if (data) {
                  const breadcrumbs = response.data.results[0].breadcrumbs;
                  setSiteBreadcrumbs(breadcrumbs);
                  localStorage.setItem(
                    "currentSiteBreadcrumbs",
                    JSON.stringify(breadcrumbs)
                  );
                  if (localStorage.navigatedFromSitesAboutPage === "true") {
                    localStorage.setItem(
                      "navigatedFromSitesAboutPage",
                      "false"
                    );
                  }
                }
              },
              (reason: AxiosError) => {
                universalAPIErrorHandler(
                  reason,
                  navigate,
                  retryFunction,
                  () => {
                    alert("Error Retrieving site data.");
                    setRenderedRows([]);
                    setPageLoading(false);
                  }
                );
              }
            );
          }
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert(`Error ${actionString}ing site ${cameraId}.`);
            setSiteScreenLoading(false);
          });
        }
      );
    });
  }
};
