import * as React from "react";
import Box from "@mui/material/Box";
import { createSvgIcon } from "@mui/material/utils";

const CreateCameraIcon = createSvgIcon(
  <path
    d="M20.916 9.564a.998.998 0 0 0-.513-1.316L7.328 2.492c-.995-.438-2.22.051-2.645 1.042l-2.21 5.154a2.001 2.001 0 0 0 1.052 2.624L9.563 13.9L8.323 17H4v-3H2v8h2v-3h4.323c.823 0 1.552-.494 1.856-1.258l1.222-3.054l5.205 2.23a1 1 0 0 0 1.31-.517l.312-.71l1.701.68l2-5l-1.536-.613l.523-1.194zm-4.434 5.126L4.313 9.475l2.208-5.152l12.162 5.354l-2.201 5.013z"
    fontSize="small"
  />,
  "Home"
);

export const CameraIcon = () => {
  return (
    <Box
      sx={{
        "& > :not(style)": {
          m: 2,
        },
      }}
    >
      <CreateCameraIcon />
    </Box>
  );
};
