import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from "recharts";
import { sortHighToLow } from "./HealthMonitoringUtils/sortHighToLow";
import { SortingMenu } from "./SortingMenu";

export const BandwidthBarGraph = ({
  bandwidthData,
}: {
  bandwidthData: any;
}) => {
  const calculatedWidth =
    bandwidthData.length < 5 ? 580 : bandwidthData.length * 145;

  const [sortedData, setSortedData] = React.useState(bandwidthData);

  const [currentBandwidthSort, setCurrentBandwidthSort] =
    React.useState(`Volume (High to Low)`);

  React.useEffect(() => {
    sortHighToLow(bandwidthData, "kbs");
  }, []);

  return (
    <div>
      <BarChart
        width={calculatedWidth}
        height={375}
        data={sortedData}
        barSize={120}
        margin={{
          top: 55,
          right: 0,
          left: 18,
          bottom: 25,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="display_name" type="category" hide />
        <YAxis
          label={{
            value: "Bandwidth Usage",
            angle: -90,
            position: "insideLeft",
            offset: -10,
          }}
          scale="sqrt"
        />
        <Tooltip />
        <Bar
          // cursor="pointer"
          dataKey="kbs"
          fill="#CD4A81"
          name="GB"
          onClick={() => {}}
        >
          <LabelList dataKey="abreviated_name" position="bottom" />
        </Bar>
      </BarChart>
      {/* <div className="absolute top-[0px] left-[390px]">
            <SortingMenu
              currentSort={currentBandwidthSort}
              setCurrentSort={setCurrentBandwidthSort}
              setSortedData={setSortedData}
              volumeSortString={`kbs`}
              nameSortString={`display_name`}
              data={sortedData}
            />
          </div> */}
    </div>
  );
};
