import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { Switch, TextField } from "@mui/material";

export const ConnectionsTabEditMode = ({
  cameraGeneralInfo,
  setCameraGeneralInfo,
  setPatchObject,
  setGeneralInfoChangesMade,
}: {
  cameraGeneralInfo: any;
  setCameraGeneralInfo: any;
  setPatchObject: any;
  setGeneralInfoChangesMade: any;
}) => {
  const connectionsTitlesArray = JSON.parse(
    localStorage.connectionsTitlesArray
  );

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={connectionsTitlesArray} />
        <TableBody>
          <TableRow key={`connections_table_row_1`}>
            {cameraGeneralInfo.ip && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.ip}>
                  <TextField
                    id={cameraGeneralInfo.ip}
                    defaultValue={cameraGeneralInfo.ip}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          ip: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return { ...previousState, ip: event.target.value };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.rtsp && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.rtsp}>
                  <TextField
                    id={cameraGeneralInfo.rtsp}
                    defaultValue={cameraGeneralInfo.rtsp}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          rtsp: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return { ...previousState, rtsp: event.target.value };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.http && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.http}>
                  <TextField
                    id={cameraGeneralInfo.http}
                    defaultValue={cameraGeneralInfo.http}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          http: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return { ...previousState, http: event.target.value };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.server_port && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.server_port}>
                  <TextField
                    id={cameraGeneralInfo.server_port}
                    defaultValue={cameraGeneralInfo.server_port}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          server_port: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          server_port: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.username && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.username}>
                  <TextField
                    id={cameraGeneralInfo.username}
                    defaultValue={cameraGeneralInfo.username}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          username: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          username: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.password && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.password}>
                  <TextField
                    id={cameraGeneralInfo.password}
                    defaultValue={cameraGeneralInfo.password}
                    variant="standard"
                    type="password"
                    sx={{ width: 150 }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          password: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          password: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.channel && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.channel}>
                  <TextField
                    id={cameraGeneralInfo.channel}
                    defaultValue={cameraGeneralInfo.channel}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          channel: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          channel: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.stream_parameters && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.stream_parameters}>
                  <TextField
                    id={cameraGeneralInfo.stream_parameters}
                    defaultValue={cameraGeneralInfo.stream_parameters}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          stream_parameters: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          stream_parameters: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
            {cameraGeneralInfo.slice && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.slice ? (
                  <Switch
                    defaultChecked
                    color="warning"
                    disabled={false}
                    onChange={(event: any) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          slice: event.target.checked,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          slice: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                ) : (
                  <Switch
                    color="warning"
                    disabled={false}
                    onChange={(event: any) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          slice: event.target.checked,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          slice: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                )}
              </TableCell>
            )}
            {cameraGeneralInfo.split && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {cameraGeneralInfo.split ? (
                  <Switch
                    defaultChecked
                    color="warning"
                    disabled={false}
                    onChange={(event: any) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          split: event.target.checked,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          split: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                ) : (
                  <Switch
                    color="warning"
                    disabled={false}
                    onChange={(event: any) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          split: event.target.checked,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          split: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                )}
              </TableCell>
            )}
            {cameraGeneralInfo.use_stream_quality && (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={cameraGeneralInfo.stream_quality}>
                  <TextField
                    id={cameraGeneralInfo.stream_quality}
                    defaultValue={cameraGeneralInfo.stream_quality}
                    variant="standard"
                    sx={{ width: 150 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCameraGeneralInfo((previousState: any) => {
                        return {
                          ...previousState,
                          stream_quality: event.target.value,
                        };
                      });
                      setPatchObject((previousState: any) => {
                        return {
                          ...previousState,
                          stream_quality: event.target.value,
                        };
                      });
                      setGeneralInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
