import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { addSeletedAndDetectionType } from "./addSeletedAndDetectionType";
import { getAlertMP4 } from "./getAlertMP4";
import { handleAlertButtonsFill } from "./handleAlertButtonsFill";
import { timeSlicer } from "../timeSlicer";
import { getActiveIgnoreZones } from "./getActiveIgnoreZones";
import { getAlertsRefresh } from "./getAlertsRefresh";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

// const isDateWithinRange = (date: any, startDate: any, endDate: any) => {
//   // Check if the date is within the range
//   if (date >= startDate && date <= endDate) {
//     return true;
//   } else {
//     return false;
//   }
// };

// export const reformatDate = (dateString: any) => {
//   return `${dateString.slice(6, 10)}-${dateString.slice(
//     0,
//     2
//   )}-${dateString.slice(3, 5)}`;
// };

const isCurrentDate = (inputDate: string): boolean => {
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().slice(0, 10); // Get the current date in YYYY-MM-DD format

  return inputDate === formattedCurrentDate;
};

export const getAlertsWithQuerry = (
  setAlertData: Dispatcher<any>,
  navigate: NavigateFunction,
  setSelectedRow: Dispatcher<any>,
  setFetchingFilteredData: Dispatcher<boolean>,
  setSeeMoreURL: Dispatcher<string>,
  setMP4URL: Dispatcher<string>,
  groupId: string,
  siteId: string,
  cameraId: string,
  startDate: string,
  endDate: string,
  startTime: Date,
  endTime: Date,
  filterType: string,
  setSelectedButtonsFilled: any,
  setThumbnailVideos: any,
  setDateOutsideRange: Dispatcher<boolean>,
  setPage: Dispatcher<number>,
  setVideoClipLoading: Dispatcher<boolean>,
  setAlertsToDisplay: Dispatcher<boolean>,
  setPageLoading: Dispatcher<boolean>,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  setAlertsIndexOrder: any,
  setRefreshInterval: any,
  setCurrentName: any,
  setUserFilters: any,
  setRefreshingData: any,
  pageLoadedNumber: any,
  refreshInterval: number,
  userFilters: any,
  cameraOptions: any,
  siteOptions: any,
  setRefreshingToken: Dispatcher<boolean>,
  refreshingToken: boolean,
  setAlertLabels: Dispatcher<string[]>,
  setPeriodHours: Dispatcher<number>,
  setQueryLimitHit: Dispatcher<boolean>
) => {
  const AxiosUI = createMonitoringAPICall(``);
  const retryFunction = () => {
    getAlertsWithQuerry(
      setAlertData,
      navigate,
      setSelectedRow,
      setFetchingFilteredData,
      setSeeMoreURL,
      setMP4URL,
      groupId,
      siteId,
      cameraId,
      startDate,
      endDate,
      startTime,
      endTime,
      filterType,
      setSelectedButtonsFilled,
      setThumbnailVideos,
      setDateOutsideRange,
      setPage,
      setVideoClipLoading,
      setAlertsToDisplay,
      setPageLoading,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      setAlertsIndexOrder,
      setRefreshInterval,
      setCurrentName,
      setUserFilters,
      setRefreshingData,
      pageLoadedNumber,
      refreshInterval,
      userFilters,
      cameraOptions,
      siteOptions,
      setRefreshingToken,
      refreshingToken,
      setAlertLabels,
      setPeriodHours,
      setQueryLimitHit
    );
  };

  let searchParamsString = ``;

  if (startDate) {
    searchParamsString += `&start=${startDate}`;
  }

  if (endDate) {
    searchParamsString += `&end=${endDate}`;
  }

  if (groupId) {
    if (groupId !== "undefined") {
      searchParamsString += `&group_id=${groupId}`;
    }
  }

  if (siteId) {
    if (siteId !== "undefined") {
      searchParamsString += `&customer_id=${siteId}`;
    }
  }

  if (cameraId) {
    if (cameraId !== "undefined") {
      searchParamsString += `&camera_id=${cameraId}`;
    }
  }

  if (startDate.toString() === endDate.toString() && startTime !== null) {
    searchParamsString += `&start_time=${timeSlicer(startTime)}`;
  }

  if (startDate.toString() === endDate.toString() && endTime !== null) {
    searchParamsString += `&end_time=${timeSlicer(endTime)}`;
  }

  // if (
  //   (startDate && !isCurrentDate(startDate)) ||
  //   (endDate && !isCurrentDate(endDate)) ||
  //   startTime ||
  //   endTime
  // ) {
  //   searchParamsString += `&limit=100000`;
  // }

  // let noAlertsOutsideRange = true;

  localStorage.setItem(
    "queryString",
    JSON.stringify(`${filterType}${searchParamsString}`)
  );

  AxiosUI.get(`alert/?window_filter=${filterType}${searchParamsString}`).then(
    (response: AxiosResponse) => {
      const data = response.data;
      if (data.parameters) {
        if (data.parameters.period_hours) {
          setPeriodHours(data.parameters.period_hours);
        }
      }
      setAlertData(
        addSeletedAndDetectionType(
          data.records,
          setAlertsIndexOrder,
          setAlertLabels
        )
      );
      if (data.records[0]) {
        if (
          startDate &&
          endDate &&
          !siteId &&
          !cameraId &&
          data.parameters.query_limit
        ) {
          setQueryLimitHit(true);
          // alert("The selected parent group is too large. Only the 100 most recent alerts are displayed. Please select a specific site to view the accurate number of alerts.")
        }
        getAlertMP4(data.records[0], navigate, setMP4URL, setVideoClipLoading);
        setAlertsToDisplay(true);
        setPage(0);
        setSeeMoreURL(data.records[0].alert_url);
        setSelectedRow(data.records[0]);
        data.records[0].camera_pk &&
          getActiveIgnoreZones(
            data.records[0].camera_pk,
            setSelectedAlertCameraInfo,
            setIgnoreZonesSelectedAlert,
            navigate
          );
        localStorage.setItem(
          "selectedRowAlerts",
          JSON.stringify(data.records[0])
        );
        handleAlertButtonsFill(data.records[0], setSelectedButtonsFilled);
        setAlertsToDisplay(true);
        setFetchingFilteredData(false);
        setPageLoading(false);
        // if (userFilters.start_date || userFilters.end_date) {
        //   data.records.forEach((object: any) => {
        //     if (
        //       !isDateWithinRange(
        //         reformatDate(object.formatted_date),
        //         startDate,
        //         endDate
        //       )
        //     ) {
        //       noAlertsOutsideRange = false;
        //     }
        //   });
        // }
        // if (!noAlertsOutsideRange) {
        //   setDateOutsideRange(true);
        //   getAlertMP4(
        //     data.records[0],
        //     navigate,
        //     setMP4URL,
        //     setVideoClipLoading
        //   );
        //   handleAlertButtonsFill(data.records[0], setSelectedButtonsFilled);
        //   setFetchingFilteredData(false);
        //   setPageLoading(false);
        // }
        // if (noAlertsOutsideRange) {
        // }
      }
      if (!data.records[0]) {
        setAlertsToDisplay(false);
        setPageLoading(false);
        setFetchingFilteredData(false);
      }
      if (localStorage.tokenRefreshing === "false") {
        let dataRecordTimeStamp = "";
        if (data.records[0]) {
          dataRecordTimeStamp = data.records[0].approx_capture_timestamp;
        }
        if (
          (!startDate && !endDate) ||
          (startDate && !endDate && isCurrentDate(startDate)) ||
          (endDate && !startDate && isCurrentDate(endDate)) ||
          (startDate &&
            endDate &&
            isCurrentDate(startDate) &&
            isCurrentDate(startDate))
        ) {
          if (!endTime) {
            setTimeout(() => {
              getAlertsRefresh(
                setAlertData,
                navigate,
                groupId,
                siteId,
                cameraId,
                startDate,
                endDate,
                startTime,
                endTime,
                filterType,
                setSelectedRow,
                setRefreshingData,
                setSelectedAlertCameraInfo,
                setIgnoreZonesSelectedAlert,
                setAlertsIndexOrder,
                refreshInterval,
                setRefreshInterval,
                pageLoadedNumber,
                setSeeMoreURL,
                setMP4URL,
                setVideoClipLoading,
                setSelectedButtonsFilled,
                setAlertsToDisplay,
                setCurrentName,
                setPage,
                setDateOutsideRange,
                setFetchingFilteredData,
                setPageLoading,
                setUserFilters,
                userFilters,
                cameraOptions,
                siteOptions,
                dataRecordTimeStamp,
                setRefreshingToken,
                refreshingToken
              );
            }, refreshInterval);
          }
        }
      }
      localStorage.setItem("newQueryRunning", "false");
    },
    (reason: AxiosError) => {
      setPage(0);
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setAlertsToDisplay(false);
        setPageLoading(false);
        setFetchingFilteredData(false);
      });
    }
  );
};
