import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Typography, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { fetchAddCameraInfo } from "../AddSite/fetchAddCameraInfo";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { handleNavigateToSitesPage } from "./handleNavigateToSitesPage";
import ErrorBoundary from "../../../ErrorBoundary";
import { handleCheckCameras } from "../CameraCheck/handleCheckCameras";
import { set } from "date-fns";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const validIntegrationTypes = [
  "Avigilon",
  "Hanwha / Digital Watchdog / Nx",
  "Hikcentral",
  "Milestone",
  "Milestone RTSP",
  "Openeye",
  "Orchid",
  "RTSP",
  "Uniview / Alibi",
  "Axis",
  "Dahua / SmartPSS - NVR",
  "Dahua / SmartPSS - Per Camera",
  "Hikvision / LTS / Alibi",
  "Mobotix",
  "Uniview / Alibi",
  "Wisenet RTSP",
];

export const OnboardingProgressDiv = ({
  percentage,
  step,
  setVisibleScreen,
}: {
  percentage: number;
  step: number;
  setVisibleScreen: any;
}) => {
  const navigate = useNavigate();

  const [activeOnboardingScreen, setActiveOnboardingScreen] = React.useState(
    <></>
  );

  const validCameraCheckIntegration = validIntegrationTypes.includes(
    localStorage.selectedIntegrationType
  );

  React.useEffect(() => {
    setActiveOnboardingScreen(onboardingScreen);
  }, []);

  const onboardingScreen = (
    <ErrorBoundary>
      <div>
        <ThemeProvider theme={theme}>
          {/* <div className="absolute right-4 top-[80px]">
            <Button
              color="primary"
              onClick={() => {
                localStorage.setItem("clickedId", localStorage.siteId);
                handleNavigateToSitesPage(navigate);
                localStorage.setItem("navigatedFromSitesAboutPage", "true");
                localStorage.setItem("level0Click", "true");
              }}
            >
              GO TO SITE
              <ArrowForwardIcon />
            </Button>
          </div> */}
          <div className="absolute top-[142px] right-44">
            <LinearProgress
              color="primary"
              variant="determinate"
              value={percentage}
              sx={{ width: 275 }}
            />
          </div>
          <div className="absolute top-32 right-32">
            <Typography variant="body1">{percentage}%</Typography>
          </div>
          {/* <div className="absolute top-[160px] right-[215px] font-mulish text-actuate-blue text-xl">
            We&apos;re almost done
          </div> */}
          <div className="absolute top-[195px] right-[150px] font-mulish text-actuate-blue">
            <p> Please complete the following information. </p>
          </div>
          <div className="absolute top-[220px] right-[190px] font-mulish text-actuate-blue">
            <p> Otherwise the site won&apos;t run.</p>
          </div>
          <div className="absolute top-[275px] right-[350px]">
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
              <FormControlLabel
                sx={{
                  fontFamily: "Mulish",
                  color: "#001943",
                }}
                label="Site Creation"
                control={<Checkbox color="primary" checked={true} />}
              />
              <br></br>
              <FormControlLabel
                sx={{
                  fontFamily: "Mulish",
                  color: "#001943",
                }}
                label="Camera Setup"
                control={<Checkbox color="primary" checked={step > 1} />}
              />
              <br></br>
              {localStorage.healthcheckOnly === "false" && (
                <FormControlLabel
                  label="Schedule Setup"
                  control={<Checkbox color="primary" checked={step > 2} />}
                />
              )}
              <br></br>
              {validCameraCheckIntegration && (
                <FormControlLabel
                  label="Check Cameras"
                  control={<Checkbox color="primary" checked={false} />}
                />
              )}
            </Box>
          </div>
          {step === 1 && (
            <div className="absolute top-[346px] right-24">
              <Button
                color="primary"
                onClick={() => {
                  localStorage.removeItem("addAdditionalCamera");
                  localStorage.setItem("clickedFromSiteAboutPage", "false");
                  if (localStorage.autoAdd === "false") {
                    localStorage.setItem("firstCameraAdded", "true");
                    localStorage.setItem("copyValues", "true");
                    navigate("/sites/addcamera");
                    localStorage.setItem("cameraNumber", "1");
                  } else {
                    setActiveOnboardingScreen(camerasLoadingScreen);
                    fetchAddCameraInfo(
                      localStorage.siteId,
                      setVisibleScreen,
                      navigate
                    );
                  }
                }}
              >
                + Add Camera
              </Button>
            </div>
          )}
          {localStorage.healthcheckOnly === "false" && step < 3 && (
            <div className="absolute top-[411px] right-24">
              <Button
                color="primary"
                disabled={step !== 2}
                onClick={() => {
                  navigate("/sites/addschedule");
                  localStorage.setItem("cameraNumber", "1");
                }}
              >
                + Add Schedule
              </Button>
            </div>
          )}
          {validCameraCheckIntegration && (
            <div
              className={
                localStorage.healthcheckOnly === "false"
                  ? "absolute top-[474px] right-24"
                  : "absolute top-[411px] right-24"
              }
            >
              <Button
                color="primary"
                disabled={step !== 3}
                onClick={() => {
                  localStorage.setItem("cameraCheckAlertOpen", "true");
                  localStorage.setItem("clickedId", localStorage.siteId);
                  handleNavigateToSitesPage(navigate);
                  localStorage.setItem("navigatedFromSitesAboutPage", "true");
                  localStorage.setItem("level0Click", "true");
                }}
              >
                TEST CAMERAS
              </Button>
            </div>
          )}
        </ThemeProvider>
      </div>
    </ErrorBoundary>
  );

  const camerasLoadingScreen = (
    <StatusNotificationScreen
      styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
      text={
        <p>
          Fetching cameras.
          <br />
          This might take a few seconds.
        </p>
      }
    />
  );

  return <div>{activeOnboardingScreen}</div>;
};
