/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { NavBar } from "../../common/NavBar";
import { ParentGroupsDropDown } from "./ParentGroupsDropDown";
import { SiteDropDown } from "./SiteDropDown";
import { CameraDropDown } from "./CameraDropDown";
import { StartDateDropDown } from "./StartDateDropDown";
import { EndDateDropDown } from "./EndDateDropDown";
import { SVGLine } from "./SVGLine";
import { DownloadButton } from "./DownloadButton";
import Checkbox from "@mui/material/Checkbox";
import { AlertsByDateGraph } from "./AlertsByDateGraph";
import {
  AllAlertsData,
  ParentGroupsAlertData,
  UserFilters,
} from "./AnalyticsUtils/analyticsInterfaces";
import { getAllAlertsData } from "./AnalyticsUtils/getAllAlertsData";
import { useNavigate } from "react-router-dom";
import { yyyymmddGenerator } from "./AnalyticsUtils/yyyymmddGenerator";
import { getParentGroupsAlertData } from "./AnalyticsUtils/getParentGroupsAlertData";
import { AlertsByParentGroupsGraph } from "./AlertsByParentGroupsGraph";
import { FalsePositiveReductionGraphAnalytics } from "./FalsePositiveReductionGraphAnalytics";
import { getFalsePositiveDataAnalyticsPage } from "./AnalyticsUtils/getFalsePositiveDataAnalyticsPage";
import { PercentageofMotionFilteredGraph } from "./PercentageofMotionFilteredGraph";
import { getPercentageMotionFilteredData } from "./AnalyticsUtils/getPercentageMotionFilteredData";
import { handleNavigatedFromSitesPage } from "./AnalyticsUtils/handleNavigatedFromSitesPage";
import { AnalyticsAlerts } from "./AnalyticsAlerts";
import { getDatesFromDefault } from "./AnalyticsUtils/getDatesFromDefault";
import { handleFilterCameras } from "./AnalyticsUtils/handleFilterCameras";
import { handleGetDownloadURL } from "./AnalyticsUtils/handleGetDownloadURL";
import { getAllAnalyticsData } from "./AnalyticsUtils/getAllAnalyticsData";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const Analytics = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [loadingData, setLoadingData] = React.useState({
    allAlertsData: true,
    parentGroupsData: true,
    falsePositiveData: true,
    percetageOfMotionData: true,
  });

  const navigate = useNavigate();

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 5);

  const endDate = new Date();

  const [siteDropDownValue, setSiteDropDownValue] = React.useState<any[]>(
    localStorage.analyticsSiteName ? [localStorage.analyticsSiteName] : []
  );

  const [cameraDropDownValue, setCameraDropDownValue] = React.useState<any[]>(
    []
  );

  const [allAlertsData, setAllAlertsData] = React.useState<AllAlertsData[]>([]);

  const [siteOptions, setSiteOptions] = React.useState<any[]>([]);

  const [cameraOptions, setCameraOptions] = React.useState<any[]>([]);

  const [pageLoading, setPageLoading] = React.useState(false);

  const [visibleArray, setVisibleArray] = React.useState<any[]>([]);

  const [conversionArray, setConversionArray] = React.useState<any>([]);

  const [showMoreVisible, setShowMoreVisible] = React.useState(false);

  const [showMoreOpen, setShowMoreOpen] = React.useState(false);

  const [parentGroupsAlertsData, setParentGroupsAlertsData] = React.useState<
    ParentGroupsAlertData[]
  >([]);

  const [displayOnLeftBool, setDisplayOnLeftBool] = React.useState(false);

  const [userFilters, setUserFilters] = React.useState<UserFilters>({
    parent_group: "",
    site: [],
    camera: [],
    start_date: startDate,
    end_date: endDate,
  });

  const [alertGraphName, setAlertsGraphName] = React.useState("Parent Group");

  const [falsePostiveData, setFalsePostiveData] = React.useState(
    JSON.parse(localStorage.falsePostiveData)
  );

  const [percetageOfMotionData, setPercetageOfMotionData] = React.useState(
    JSON.parse(localStorage.percetageOfMotionData)
  );

  const [averagePercentageOfMotion, setAveragePercentageOfMotion] =
    React.useState<number>(NaN);

  const [alertState, setAlertState] = React.useState({
    dateTooFar: false,
    startAfterEnd: false,
    endBeforeStart: false,
  });

  const [filtersSavedClicked, setFiltersSavedClicked] = React.useState<boolean>(
    localStorage.filtersSavedClicked === "true"
  );

  const [loadingSites, setLoadingSites] = React.useState(false);

  const [loadingCameras, setLoadingCameras] = React.useState(false);

  const [dateChangesMade, setDateChangesMade] = React.useState(false);

  const [dateGraphName, setDateGraphName] = React.useState("");

  const [parentAccounts, setParentAccounts] = React.useState([]);

  const [parentAccountsLoading, setParentAccountsLoading] =
    React.useState(true);

  const [parentIds, setParentIds] = React.useState(new Map());

  const [parentNames, setParentNames] = React.useState(new Map());

  const [downloadURL, setDownloadURL] = React.useState("");

  // React.useEffect(() => {
  //   if (userFilters.parent_group === "") {
  //     setAlertsGraphName("Parent Group");
  //   }
  //   if (userFilters.parent_group) {
  //     setAlertsGraphName("Site");
  //   }
  //   if (userFilters.site[0]) {
  //     setAlertsGraphName("Camera");
  //   }
  // }, [userFilters]);

  React.useEffect(() => {
    if (
      localStorage.navigatedToAnalyticsFromSitesPage === "false" &&
      localStorage.filtersSavedClicked === "false"
    ) {
      getAllAnalyticsData(
        setAllAlertsData,
        "",
        yyyymmddGenerator(startDate),
        yyyymmddGenerator(endDate),
        [],
        [],
        navigate,
        setLoadingData,
        setParentGroupsAlertsData,
        [],
        setDisplayOnLeftBool,
        setVisibleArray,
        setConversionArray,
        setShowMoreVisible,
        setShowMoreOpen,
        userFilters,
        setAlertsGraphName,
        setFalsePostiveData,
        userFilters.parent_group,
        setPercetageOfMotionData,
        setAveragePercentageOfMotion,
        setParentAccountsLoading,
        setParentAccounts,
        setParentIds,
        setParentNames,
        setDownloadURL
      );
    } else if (localStorage.navigatedToAnalyticsFromSitesPage === "true") {
      setAlertsGraphName("Camera");
      handleNavigatedFromSitesPage(
        setLoadingData,
        navigate,
        startDate,
        endDate,
        setAllAlertsData,
        setSiteOptions,
        setCameraOptions,
        setPageLoading,
        setParentGroupsAlertsData,
        setUserFilters,
        setFalsePostiveData,
        setPercetageOfMotionData,
        setAveragePercentageOfMotion,
        localStorage.analyticsParentGroupId,
        localStorage.analyticsSiteId,
        setDisplayOnLeftBool,
        setAlertsGraphName,
        setVisibleArray,
        setConversionArray,
        setShowMoreVisible,
        setShowMoreOpen,
        setLoadingSites,
        userFilters,
        setParentAccountsLoading,
        setParentAccounts,
        setParentIds,
        setParentNames,
        setDownloadURL
      );
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem("analyticsSiteName", "");
  }, []);

  React.useEffect(() => {
    if (
      localStorage.navigatedToAnalyticsFromSitesPage === "false" &&
      localStorage.filtersSavedClicked === "true"
    ) {
      setSiteDropDownValue(JSON.parse(localStorage.siteDropDownValues));
      setCameraDropDownValue(JSON.parse(localStorage.cameraDropDownValues));
      setUserFilters(JSON.parse(localStorage.defaultUserFilters));
      const tempUserFilters = JSON.parse(localStorage.defaultUserFilters);
      const start_date = tempUserFilters.start_date.slice(0, 10);
      const end_date = tempUserFilters.end_date.slice(0, 10);
      if (tempUserFilters.parent_group) {
        setAlertsGraphName("Site");
      }
      if (tempUserFilters.site[0]) {
        setAlertsGraphName("Camera");
      }
      getDatesFromDefault(start_date, setUserFilters, "start");
      getDatesFromDefault(end_date, setUserFilters, "end");
      setUserFilters((previousState: any) => {
        return {
          ...previousState,
          parent_group: tempUserFilters.parent_group,
          site: tempUserFilters.site,
          camera: tempUserFilters.camera,
        };
      });
      setLoadingData((previousState) => {
        return {
          ...previousState,
          parentGroupsData: true,
          falsePositiveData: true,
          percetageOfMotionData: true,
        };
      });

      handleNavigatedFromSitesPage(
        setLoadingData,
        navigate,
        startDate,
        endDate,
        setAllAlertsData,
        setSiteOptions,
        setCameraOptions,
        setPageLoading,
        setParentGroupsAlertsData,
        setUserFilters,
        setFalsePostiveData,
        setPercetageOfMotionData,
        setAveragePercentageOfMotion,
        tempUserFilters.parent_group,
        tempUserFilters.site,
        setDisplayOnLeftBool,
        setAlertsGraphName,
        setVisibleArray,
        setConversionArray,
        setShowMoreVisible,
        setShowMoreOpen,
        setLoadingSites,
        userFilters,
        setParentAccountsLoading,
        setParentAccounts,
        setParentIds,
        setParentNames,
        setDownloadURL
      );
    }
  }, []);

  // React.useEffect(() => {
  //   handleGetDownloadURL(userFilters, setDownloadURL, navigate);
  // }, []);

  return (
    <div>
      <AnalyticsAlerts alertState={alertState} setAlertState={setAlertState} />
      {/* <BackdropLoadingScreen openState={pageLoading} /> */}
      <div className="bg-actuate-grey absolute w-full h-full overflow-scroll">
        <div
          className={
            !navBarCollapsed
              ? "absolute left-[150px] right-[1px] h-full bg-white overflow-scroll"
              : "absolute left-[75px] right-[1px] h-full bg-white overflow-scroll"
          }
        >
          <div className="font-mulish absolute left-[20px] top-[20px]">
            <ParentGroupsDropDown
              userFilters={userFilters}
              setUserFilters={setUserFilters}
              navigate={navigate}
              setSiteOptions={setSiteOptions}
              setPageLoading={setPageLoading}
              setParentGroupsAlertsData={setParentGroupsAlertsData}
              setLoadingData={setLoadingData}
              setFalsePostiveData={setFalsePostiveData}
              setPercetageOfMotionData={setPercetageOfMotionData}
              setSiteDropDownValue={setSiteDropDownValue}
              setCameraDropDownValue={setCameraDropDownValue}
              setAveragePercentageOfMotion={setAveragePercentageOfMotion}
              setAllAlertsData={setAllAlertsData}
              setDisplayOnLeftBool={setDisplayOnLeftBool}
              setAlertsGraphName={setAlertsGraphName}
              setVisibleArray={setVisibleArray}
              setConversionArray={setConversionArray}
              setShowMoreVisible={setShowMoreVisible}
              setShowMoreOpen={setShowMoreOpen}
              setCameraOptions={setCameraOptions}
              setLoadingSites={setLoadingSites}
              parentAccounts={parentAccounts}
              parentIds={parentIds}
              parentNames={parentNames}
              parentAccountsLoading={parentAccountsLoading}
              navBarCollapsed={navBarCollapsed}
            />
          </div>
          <div
            className={
              !navBarCollapsed
                ? "font-mulish relative left-[233px] top-[20px] max-w-[800px] max-h-full"
                : "font-mulish relative left-[308px] top-[20px] max-w-[800px] max-h-full"
            }
          >
            <div className="relative top-0 left-0 w-[580px] columns-2">
              <div>
                {" "}
                <SiteDropDown
                  setUserFilters={setUserFilters}
                  siteOptions={siteOptions}
                  setCameraOptions={setCameraOptions}
                  setPageLoading={setPageLoading}
                  navigate={navigate}
                  setFalsePostiveData={setFalsePostiveData}
                  setLoadingData={setLoadingData}
                  userFilters={userFilters}
                  setPercetageOfMotionData={setPercetageOfMotionData}
                  siteDropDownValue={siteDropDownValue}
                  setSiteDropDownValue={setSiteDropDownValue}
                  setCameraDropDownValue={setCameraDropDownValue}
                  setAveragePercentageOfMotion={setAveragePercentageOfMotion}
                  setParentGroupsAlertsData={setParentGroupsAlertsData}
                  setDisplayOnLeftBool={setDisplayOnLeftBool}
                  setVisibleArray={setVisibleArray}
                  setConversionArray={setConversionArray}
                  setShowMoreVisible={setShowMoreVisible}
                  setShowMoreOpen={setShowMoreOpen}
                  setAlertsGraphName={setAlertsGraphName}
                  loadingSites={loadingSites}
                  setLoadingCameras={setLoadingCameras}
                  setSiteOptions={setSiteOptions}
                />
              </div>
              <div>
                <CameraDropDown
                  userFilters={userFilters}
                  setUserFilters={setUserFilters}
                  cameraOptions={cameraOptions}
                  setLoadingData={setLoadingData}
                  setFalsePostiveData={setFalsePostiveData}
                  setPercetageOfMotionData={setPercetageOfMotionData}
                  navigate={navigate}
                  cameraDropDownValue={cameraDropDownValue}
                  setCameraDropDownValue={setCameraDropDownValue}
                  setAveragePercentageOfMotion={setAveragePercentageOfMotion}
                  setShowMoreVisible={setShowMoreVisible}
                  setShowMoreOpen={setShowMoreOpen}
                  loadingCameras={loadingCameras}
                />
              </div>
            </div>
          </div>
          <div
            className={
              !navBarCollapsed
                ? "font-mulish absolute left-[830px] top-[20px]"
                : "font-mulish absolute left-[905px] top-[20px]"
            }
          >
            <StartDateDropDown
              userFilters={userFilters}
              setUserFilters={setUserFilters}
              setAllAlertsData={setAllAlertsData}
              navigate={navigate}
              setLoadingData={setLoadingData}
              setParentGroupsAlertsData={setParentGroupsAlertsData}
              setFalsePostiveData={setFalsePostiveData}
              setPercetageOfMotionData={setPercetageOfMotionData}
              setAveragePercentageOfMotion={setAveragePercentageOfMotion}
              setAlertState={setAlertState}
              setDisplayOnLeftBool={setDisplayOnLeftBool}
              setVisibleArray={setVisibleArray}
              setConversionArray={setConversionArray}
              setShowMoreVisible={setShowMoreVisible}
              setShowMoreOpen={setShowMoreOpen}
              setAlertsGraphName={setAlertsGraphName}
              setDateChangesMade={setDateChangesMade}
            />
          </div>
          <div
            className={
              !navBarCollapsed
                ? "font-mulish absolute left-[990px] top-[20px]"
                : "font-mulish absolute left-[1065px] top-[20px]"
            }
          >
            <EndDateDropDown
              userFilters={userFilters}
              setUserFilters={setUserFilters}
              setAllAlertsData={setAllAlertsData}
              navigate={navigate}
              setLoadingData={setLoadingData}
              setParentGroupsAlertsData={setParentGroupsAlertsData}
              setFalsePostiveData={setFalsePostiveData}
              setPercetageOfMotionData={setPercetageOfMotionData}
              setAveragePercentageOfMotion={setAveragePercentageOfMotion}
              setAlertState={setAlertState}
              setDisplayOnLeftBool={setDisplayOnLeftBool}
              setVisibleArray={setVisibleArray}
              setConversionArray={setConversionArray}
              setShowMoreVisible={setShowMoreVisible}
              setShowMoreOpen={setShowMoreOpen}
              setAlertsGraphName={setAlertsGraphName}
              setDateChangesMade={setDateChangesMade}
            />
          </div>
          <div
            className={
              !navBarCollapsed
                ? "absolute top-[25px] left-[1150px]"
                : "absolute top-[25px] left-[1225px]"
            }
          >
            <button
              className={
                loadingData.allAlertsData ||
                loadingData.falsePositiveData ||
                loadingData.parentGroupsData ||
                loadingData.percetageOfMotionData
                  ? "shadow-button bg-actuate-grey border-2	border-actuate-grey rounded font-mulish text-white text-base p-1.5 w-[120px]"
                  : "shadow-button bg-actuate-orange border-2	border-actuate-orange rounded font-mulish text-white text-base p-1.5 w-[120px]"
              }
              onClick={() => {
                setDownloadURL("");
                handleGetDownloadURL(userFilters, setDownloadURL, navigate);
                if (userFilters.parent_group === "") {
                  setAlertsGraphName("Parent Group");
                  setDateGraphName("");
                }
                if (userFilters.parent_group) {
                  setAlertsGraphName("Site");
                  setDateGraphName("Parent Group");
                }
                if (userFilters.site[0]) {
                  setAlertsGraphName("Camera");
                  setDateGraphName("Site");
                }
                if (
                  dateChangesMade ||
                  userFilters.parent_group ||
                  userFilters.camera[0] ||
                  userFilters.site[0]
                ) {
                  setLoadingData((previousState) => {
                    return {
                      ...previousState,
                      allAlertsData: true,
                    };
                  });
                  getAllAlertsData(
                    setAllAlertsData,
                    userFilters.parent_group,
                    yyyymmddGenerator(userFilters.start_date),
                    yyyymmddGenerator(userFilters.end_date),
                    userFilters.site,
                    userFilters.camera,
                    navigate,
                    setLoadingData
                  );
                  setDateChangesMade(false);
                }
                if (!userFilters.camera[0]) {
                  setShowMoreVisible(false);
                  setShowMoreOpen(false);
                  setLoadingData((previousState) => {
                    return {
                      ...previousState,
                      parentGroupsData: true,
                      falsePositiveData: true,
                      percetageOfMotionData: true,
                    };
                  });
                  getParentGroupsAlertData(
                    setParentGroupsAlertsData,
                    userFilters.parent_group,
                    yyyymmddGenerator(userFilters.start_date),
                    yyyymmddGenerator(userFilters.end_date),
                    userFilters.site,
                    navigate,
                    setLoadingData,
                    setDisplayOnLeftBool,
                    setVisibleArray,
                    setConversionArray,
                    setShowMoreVisible,
                    setShowMoreOpen,
                    userFilters,
                    setAlertsGraphName
                  );
                }
                if (userFilters.camera[0]) {
                  setDateGraphName("Camera");
                  setLoadingData((previousState) => {
                    return {
                      ...previousState,
                      parentGroupsData: true,
                      falsePositiveData: true,
                      percetageOfMotionData: true,
                    };
                  });
                  handleFilterCameras(
                    userFilters.camera,
                    parentGroupsAlertsData,
                    setParentGroupsAlertsData,
                    setLoadingData,
                    setVisibleArray,
                    setConversionArray,
                    setShowMoreVisible,
                    setShowMoreOpen
                  );
                }
                getFalsePositiveDataAnalyticsPage(
                  setFalsePostiveData,
                  navigate,
                  setLoadingData,
                  yyyymmddGenerator(userFilters.start_date),
                  yyyymmddGenerator(userFilters.end_date),
                  userFilters.site,
                  userFilters.camera,
                  userFilters.parent_group
                );
                getPercentageMotionFilteredData(
                  setPercetageOfMotionData,
                  setAveragePercentageOfMotion,
                  navigate,
                  setLoadingData,
                  yyyymmddGenerator(userFilters.start_date),
                  yyyymmddGenerator(userFilters.end_date),
                  userFilters.site,
                  userFilters.camera,
                  userFilters.parent_group
                );
              }}
              disabled={
                loadingData.allAlertsData ||
                loadingData.falsePositiveData ||
                loadingData.parentGroupsData ||
                loadingData.percetageOfMotionData
              }
            >
              {loadingData.allAlertsData ||
              loadingData.falsePositiveData ||
              loadingData.parentGroupsData ||
              loadingData.percetageOfMotionData
                ? `Loading...`
                : ` Apply Filters`}
            </button>
          </div>
          <div
            className={
              !navBarCollapsed
                ? "font-mulish absolute left-[1140px] top-[80px]"
                : "font-mulish absolute left-[1215px] top-[80px]"
            }
          >
            <DownloadButton downloadURL={downloadURL} />
          </div>
          <div className="font-mulish relative left-[20px] top-[25px] w-[500px]">
            <Checkbox
              checked={filtersSavedClicked}
              onChange={(event: any, result: boolean) => {
                if (result === true) {
                  setFiltersSavedClicked(true);
                  localStorage.setItem("filtersSavedClicked", "true");
                  localStorage.setItem(
                    "defaultUserFilters",
                    JSON.stringify(userFilters)
                  );
                  localStorage.setItem(
                    "siteDropDownValues",
                    JSON.stringify(siteDropDownValue)
                  );
                  localStorage.setItem(
                    "cameraDropDownValues",
                    JSON.stringify(cameraDropDownValue)
                  );
                } else {
                  setFiltersSavedClicked(false);
                  localStorage.setItem("filtersSavedClicked", "false");
                }
              }}
            />
            &nbsp; Set these filters as default configuration.
          </div>
          <div className="relative">
            <SVGLine navBarCollapsed={navBarCollapsed} />
            <AlertsByDateGraph
              allAlertsData={allAlertsData}
              loadingData={loadingData}
              dateGraphName={dateGraphName}
              navBarCollapsed={navBarCollapsed}
            />
            <AlertsByParentGroupsGraph
              parentGroupsAlertsData={parentGroupsAlertsData}
              loadingData={loadingData}
              setDisplayOnLeftBool={setDisplayOnLeftBool}
              alertGraphName={alertGraphName}
              setPageLoading={setPageLoading}
              navigate={navigate}
              visibleArray={visibleArray}
              setVisibleArray={setVisibleArray}
              conversionArray={conversionArray}
              showMoreVisible={showMoreVisible}
              showMoreOpen={showMoreOpen}
              setShowMoreOpen={setShowMoreOpen}
              navBarCollapsed={navBarCollapsed}
            />
            <FalsePositiveReductionGraphAnalytics
              falsePostiveData={falsePostiveData}
              loadingData={loadingData}
              navBarCollapsed={navBarCollapsed}
            />
            <PercentageofMotionFilteredGraph
              percetageOfMotionData={percetageOfMotionData}
              loadingData={loadingData}
              displayOnLeftBool={displayOnLeftBool}
              navBarCollapsed={navBarCollapsed}
            />
            {displayOnLeftBool ? (
              <div
                className={
                  navBarCollapsed
                    ? "absolute top-[1270px] left-[20px] h-10 text-actuate-blue font-mulish text-[14px] font-bold"
                    : "absolute top-[1270px] left-[20px] h-10 text-actuate-blue font-mulish text-[14px] font-bold"
                }
              >
                {averagePercentageOfMotion
                  ? `Average reduction in False Positives for selected range is ${Number(
                      averagePercentageOfMotion.toFixed(2)
                    )} %`
                  : ``}
              </div>
            ) : (
              <div className="absolute top-[870px] left-[700px] h-10  text-actuate-blue font-mulish text-[14px] font-bold">
                {averagePercentageOfMotion
                  ? `Average reduction in False Positives for selected range is ${Number(
                      averagePercentageOfMotion.toFixed(2)
                    )} %`
                  : ``}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
