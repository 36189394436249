import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { TableHeadComponent } from "../../common/TableHead";
import { TextField } from "@mui/material";

export const NotificationsTabEditMode = ({
  cameraGeneralInfo,
  setCameraGeneralInfo,
  setPatchObject,
  setGeneralInfoChangesMade,
}: {
  cameraGeneralInfo: any;
  setCameraGeneralInfo: any;
  setPatchObject: any;
  setGeneralInfoChangesMade: any;
}) => {
  const [titlesArray, setTitlesArray] = React.useState(["", ""]);

  React.useEffect(() => {
    if (cameraGeneralInfo.streams[0].show_nvr_alarm) {
      setTitlesArray(["Ses recipients", "NVR Alarm"]);
    } else {
      setTitlesArray(["Ses recipients", ""]);
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          <TableRow key={localStorage.currentCameraId}>
            <TableCell align="left" sx={{ fontFamily: "Mulish", width: 600 }}>
              <div key="ses_recipients">
                <TextField
                  multiline
                  id="ses_recipients"
                  label="Recipient emails"
                  name="ses_recipients"
                  defaultValue={cameraGeneralInfo.streams[0].ses_recipients}
                  sx={{ fontFamily: "Mulish", width: 600 }}
                  helperText="E-mails to receive notifications with images attached. You can specify more than one separated by comma."
                  rows={2}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newStreams = cameraGeneralInfo.streams;
                    newStreams.forEach((object: any) => {
                      object.ses_recipients = event.target.value;
                    });
                    setPatchObject((previousState: any) => {
                      return {
                        ...previousState,
                        streams: newStreams,
                      };
                    });
                    setCameraGeneralInfo((previousState: any) => {
                      return {
                        ...previousState,
                        streams: newStreams,
                      };
                    });
                    setGeneralInfoChangesMade(true);
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontFamily: "Mulish", zIndex: "modal" }}
            >
              {cameraGeneralInfo.streams[0].show_nvr_alarm && (
                <div>
                  {cameraGeneralInfo.streams[0].nvr_alarm ? (
                    <Switch
                      sx={{ top: -40 }}
                      defaultChecked
                      color="warning"
                      disabled={false}
                      onChange={(event: any) => {
                        const newStreams = cameraGeneralInfo.streams;
                        newStreams.forEach((object: any) => {
                          object.nvr_alarm = event.target.checked;
                        });
                        setCameraGeneralInfo((previousState: any) => {
                          return {
                            ...previousState,
                            streams: newStreams,
                          };
                        });
                        setPatchObject((previousState: any) => {
                          return {
                            ...previousState,
                            streams: newStreams,
                          };
                        });
                        setGeneralInfoChangesMade(true);
                      }}
                    />
                  ) : (
                    <Switch
                      sx={{ top: -40 }}
                      color="warning"
                      disabled={false}
                      onChange={(event: any) => {
                        const newStreams = cameraGeneralInfo.streams;
                        newStreams.forEach((object: any) => {
                          object.nvr_alarm = event.target.checked;
                        });
                        setCameraGeneralInfo((previousState: any) => {
                          return {
                            ...previousState,
                            streams: newStreams,
                          };
                        });
                        setPatchObject((previousState: any) => {
                          return {
                            ...previousState,
                            streams: newStreams,
                          };
                        });
                        setGeneralInfoChangesMade(true);
                      }}
                    />
                  )}
                </div>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
