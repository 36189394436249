import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { MyComponentProps } from "./auth";
import { getRefreshToken } from "./getRefreshToken";
import { useNavigate } from "react-router-dom";
import { handleRedirect } from "../pages/Login";

export const RequireAuth = ({ children }: { children: MyComponentProps }) => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  const refreshToken = cookies.get("token_secret");
  const navigate = useNavigate();

  const localStorageLoginStatus = localStorage.getItem("loggedIn");

  if (token && localStorageLoginStatus) {
    return children;
  }
  if (refreshToken && localStorageLoginStatus) {
    return children;
  }
  if (!token && !refreshToken && localStorageLoginStatus) {
    localStorage.clear();
    localStorage.setItem("redirectURL", window.location.href);
    return <Navigate to="/login" replace />;
  } else {
    localStorage.setItem("redirectURL", window.location.href);
    // localStorage.setItem(
    //   "idFromExternalLink",
    //   window.location.href.slice(document.location.origin.length + 17)
    // );
    handleRedirect();
  }
};
