import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getAllSitesInvestigations } from "./InvestigationUtilities/getAllSitesInvestigations";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const UserDropDownSentinel = ({
  userDropDownValueSentinel,
  userOptions,
  setUserDropDownValueSentinel,
  setCameraDropDownValueSentinel,
  setUserFiltersSentinel,
  setSiteDropDownValueSentinel,
  setSiteOptionsSentinel,
  setCameraOptionsSentinel,
  setLoadingSitesSentinel,
  navBarCollapsed,
  navigate,
}: {
  userDropDownValueSentinel: any;
  userOptions: any;
  setUserDropDownValueSentinel: any;
  setCameraDropDownValueSentinel: any;
  setUserFiltersSentinel: any;
  setSiteDropDownValueSentinel: any;
  setSiteOptionsSentinel: any;
  setCameraOptionsSentinel: any;
  setLoadingSitesSentinel: Dispatcher<boolean>;
  navBarCollapsed: boolean;
  navigate: NavigateFunction;
}) => {
  const options: string[] = [];

  const optionUserIds = new Map();

  userOptions.map((object: any) => {
    if (object.group) {
      options.push(object.group.name);
    }
  });

  userOptions.forEach((object: any) => {
    if (object.group) {
      optionUserIds.set(object.group.name, object.group.id);
    }
  });

  const optionUserNames = new Map();
  userOptions.forEach((object: any) => {
    if (object.group) {
      optionUserNames.set(object.group.name, object.username);
    }
  });

  return (
    <div key="user_dropdown">
      <Autocomplete
        value={userDropDownValueSentinel}
        disablePortal
        options={options}
        onChange={(event, value: any) => {
          if (value !== null) {
            setUserDropDownValueSentinel(value);
            setCameraDropDownValueSentinel("");
            setSiteDropDownValueSentinel("");
            setSiteOptionsSentinel([]);
            getAllSitesInvestigations(
              setSiteOptionsSentinel,
              setLoadingSitesSentinel,
              optionUserIds.get(value),
              navigate
            );
            setUserFiltersSentinel((previousState: any) => {
              return {
                ...previousState,
                user: optionUserNames.get(value),
                site_ids: [],
                camera_ids: [],
              };
            });
          } else {
            // setFetchingFilteredData(true);
            setUserDropDownValueSentinel(value);
            setSiteDropDownValueSentinel("");
            setSiteOptionsSentinel([]);
            setCameraDropDownValueSentinel("");
            setCameraOptionsSentinel([]);
            setUserFiltersSentinel((previousState: any) => {
              return {
                ...previousState,
                user: "testuser",
                site_ids: [],
                camera_ids: [],
              };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="User"
            autoComplete="off"
            sx={!navBarCollapsed ? { width: 250 } : { width: 275 }}
          />
        )}
      />
    </div>
  );
};
