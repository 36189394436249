import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { getSitesByParentGroupAlerts } from "../Alerts/AlertsUtils/getSitesByParentGroupAlerts";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const StepOneAddCalendar = ({
  newCalendarInfo,
  setNewCalendarInfo,
  parentAccounts,
  parentIds,
  parentNames,
  navigate,
  setSiteOptions,
  setLoadingSites,
}: {
  newCalendarInfo: any;
  setNewCalendarInfo: any;
  parentAccounts: any;
  parentIds: any;
  parentNames: any;
  navigate: NavigateFunction;
  setSiteOptions: any;
  setLoadingSites: Dispatcher<boolean>;
}) => {
  const [updatedCalendarInfo, setUpdatedCalendarInfo] =
    React.useState(newCalendarInfo);

  return (
    <div>
      <div className="absolute top-[160px] left-[30px]">
        <TextField
          id="sureview_subject"
          variant="standard"
          label="Calendar Name"
          value={updatedCalendarInfo.name}
          onChange={(event) => {
            setUpdatedCalendarInfo((previousState: any) => {
              return { ...previousState, name: event.target.value };
            });
            setNewCalendarInfo((previousState: any) => {
              return { ...previousState, name: event.target.value };
            });
          }}
          sx={{ width: "350px" }}
          helperText=""
          type="string"
        />
        <br></br>
        <br></br>
        <Autocomplete
          disablePortal
          value={parentNames.get(updatedCalendarInfo.group)}
          options={parentAccounts.map((object: any) => {
            return object.name;
          })}
          onChange={(event: any, newInputValue: any) => {
            getSitesByParentGroupAlerts(
              parentIds.get(newInputValue),
              navigate,
              setSiteOptions,
              setLoadingSites
            );
            setUpdatedCalendarInfo((previousState: any) => {
              return {
                ...previousState,
                group: parentIds.get(newInputValue),
              };
            });
            setNewCalendarInfo((previousState: any) => {
              return {
                ...previousState,
                group: parentIds.get(newInputValue),
              };
            });
          }}
          sx={{ width: 350 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Group"
              autoComplete="off"
              id="group_name"
              name="group_name"
            />
          )}
        />
      </div>
    </div>
  );
};

export default StepOneAddCalendar;
