import React from "react";
import { NavBar } from "../common/NavBar";
import { Dispatcher } from "./Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const NoPermissionsPage = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  return (
    <div>
      <div className="bg-actuate-grey absolute w-screen h-screen">
        <div className="relative top-[100px] text-center">
          {/* <p className="text-mulish text-actuate-blue text-[80px]">404</p> */}
          <p className="text-mulish text-actuate-blue text-[30px]">
            Your user permissions do not allow access to this page.
          </p>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
