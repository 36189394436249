export const handleSureviewCCInput = (
  setCamerasObject: any,
  camerasObject: any,
  inputString: any,
  objectId: number
) => {
  const newObject = camerasObject[objectId];

  newObject.sureview.sureview_cc = inputString;

  setCamerasObject((previousState: any) => {
    return { ...previousState, objectId: newObject };
  });
};
