export const pointsCreator = (
  array: any[],
  height: number,
  width: number
): string => {
  let returnString = "";
  array.forEach((point: number[]) => {
    returnString += `${point[0] * width},${point[1] * height} `;
  });
  return returnString.trim();
};
