import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../../common/TableHead";
import { TextField } from "@mui/material";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";

const WebhooksTabEditMode = ({
  webhooksInfo,
  setWebhooksPatchObject,
  setWebhooksChangesMade,
}: {
  webhooksInfo: any;
  setWebhooksPatchObject: any;
  setWebhooksChangesMade: Dispatcher<boolean>;
}) => {
  const titlesArray = [
    "Display Name",
    "Webhook Type",
    "Target URL",
    "Message Body",
  ];
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          <TableRow>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              <TextField
                defaultValue={webhooksInfo[0].display_name}
                variant="standard"
                sx={{ width: 350 }}
                type="string"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setWebhooksPatchObject((previousState: any) => {
                    return {
                      ...previousState,
                      display_name: event.target.value,
                    };
                  });
                  setWebhooksChangesMade(true);
                }}
              />
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {webhooksInfo[0].webhook_type.charAt(0).toUpperCase() +
                webhooksInfo[0].webhook_type.slice(1)}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              <TextField
                defaultValue={webhooksInfo[0].target_url}
                variant="standard"
                sx={{ width: 350 }}
                type="string"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setWebhooksPatchObject((previousState: any) => {
                    return {
                      ...previousState,
                      target_url: event.target.value,
                    };
                  });
                  setWebhooksChangesMade(true);
                }}
              />
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              <TextField
                multiline
                rows={4}
                defaultValue={webhooksInfo[0].message_body}
                variant="outlined"
                sx={{ width: 400 }}
                type="string"
                helperText="*optional"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setWebhooksPatchObject((previousState: any) => {
                    return {
                      ...previousState,
                      message_body: event.target.value,
                    };
                  });
                  setWebhooksChangesMade(true);
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WebhooksTabEditMode;
