import React from "react";

export const handleCreateInitialTimeSchedules = (siteSchedules: any) => {
  const returnedSiteSchedules = ["", "", "", "", "", "", ""];

  siteSchedules.forEach((object: any) => {
    object.day_of_week.forEach((day: string) => {
      const dayIndex = Number(day);
      if (
        (!object.start_time || !object.end_time) &&
        !object.always_on &&
        !object.location_dusk_dawn
      ) {
        returnedSiteSchedules[dayIndex] = "";
      } else if (object.always_on) {
        returnedSiteSchedules[dayIndex] = "Always On";
      } else if (object.location_dusk_dawn) {
        returnedSiteSchedules[dayIndex] = "Dusk to Dawn";
      } else {
        returnedSiteSchedules[dayIndex] = `${object.start_time.slice(
          0,
          -3
        )} - ${object.end_time.slice(0, -3)}`;
        //set time ranges for dayIndex to startTime - endTime
      }
    });
  });
  return returnedSiteSchedules;
};
