import { getIndiviualGroup } from "./getIndiviualGroup";

export const handleUnnestedLevelClick = async (
  setPage: any,
  setCurrentTitle: any,
  name: string,
  id: any,
  setRenderedRows: any,
  setPageLoading: any,
  navigate: any,
  setSiteBreadcrumbs: any,
  setSelected: any
) => {
  localStorage.setItem("clickedId", id);
  setSiteBreadcrumbs("");
  setPage(0);
  setCurrentTitle(name);
  getIndiviualGroup(
    id,
    setRenderedRows,
    setPageLoading,
    setSiteBreadcrumbs,
    setSelected,
    navigate
  );
};
