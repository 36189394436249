import { Alert } from "@mui/material";
import React from "react";

export const SitePageAlerts = ({
  siteScreenAlerts,
  setSiteScreenAlerts,
}: {
  siteScreenAlerts: {
    armGroupSuccess: boolean;
    disarmGroupSuccess: boolean;
    deployGroupSuccess: boolean;
  };
  setSiteScreenAlerts: any;
}) => {
  return (
    <div>
      {siteScreenAlerts.armGroupSuccess && (
        <Alert
          severity="success"
          onClose={() =>
            setSiteScreenAlerts({
              ...siteScreenAlerts,
              armGroupSuccess: false,
            })
          }
        >
          Group armed command sent successfully. It may take up to 5 minutes for
          armed status to update.
        </Alert>
      )}
      {siteScreenAlerts.disarmGroupSuccess && (
        <Alert
          severity="success"
          onClose={() =>
            setSiteScreenAlerts({
              ...siteScreenAlerts,
              disarmGroupSuccess: false,
            })
          }
        >
          Group disarm command sent successfully. It may take up to 5 minutes
          for armed status to update.
        </Alert>
      )}
      {siteScreenAlerts.deployGroupSuccess && (
        <Alert
          severity="success"
          onClose={() =>
            setSiteScreenAlerts({
              ...siteScreenAlerts,
              deployGroupSuccess: false,
            })
          }
        >
          Group sync command sent successfully.
        </Alert>
      )}
    </div>
  );
};
