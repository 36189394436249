import React from "react";
import { StatusNotificationScreen } from "../../common/StatusNotificationScreen";
import { createApi } from "../../utils/createApi";
import { createSubmittedCameraObject } from "./createSubmittedCameraObject";
import { AxiosError, AxiosResponse } from "axios";
import { getRefreshToken } from "../../utils/getRefreshToken";
import { WizardCameraScreen } from "./WizardCameraScreen";
import { syncSite } from "../../utils/syncSite";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
import ErrorBoundary from "../../../ErrorBoundary";

export const handleSubmitAndAddAnotherCamera = async (
  values: any,
  setVisibleScreen: any,
  responseObject: any,
  setCameraCheckFinsihedAlertOpen: any,
  setGoodCameras: any,
  setConnectivityCameras: any,
  setLowResolutionCameras: any,
  setCameraCheckResultDownloadURL: any,
  navigate: any
) => {
  const retryFunction = () => {
    handleSubmitAndAddAnotherCamera(
      values,
      setVisibleScreen,
      responseObject,
      setCameraCheckFinsihedAlertOpen,
      setGoodCameras,
      setConnectivityCameras,
      setLowResolutionCameras,
      setCameraCheckResultDownloadURL,
      navigate
    );
  };
  setVisibleScreen(
    <StatusNotificationScreen
      styling="absolute first-line:font-black font-mulish text-[20px] right-[150px] text-center text-[#283E6D] top-80"
      text={
        <p>
          We&apos;re adding a new Camera.
          <br />
          This might take a few seconds.
        </p>
      }
    />
  );
  const AxiosUI = createApi(`camera/onboarding/`);
  const ActuateUI = createApi(``);
  let cameraObjectSubmitFail = false;
  let deployFail = false;
  await AxiosUI.post("", createSubmittedCameraObject(values)).then(
    (response: AxiosResponse) => {
      if (localStorage.firstCameraAdded === "true") {
        localStorage.setItem("copiedValues", JSON.stringify(values));
      }
      cameraObjectSubmitFail = false;
      localStorage.setItem("cameraId", response.data.id);
      localStorage.removeItem("addCameraError");
      localStorage.removeItem("cameraNamingError");
      localStorage.setItem("firstCameraAdded", "false");
      syncSite(localStorage.siteId, navigate);
      setVisibleScreen(
        <StatusNotificationScreen
          styling="absolute first-line:font-black font-mulish text-[20px] right-[170px] text-center text-[#283E6D] top-80"
          text="Camera Added successfully!"
        />
      );
      setTimeout(() => {
        setVisibleScreen(
          <WizardCameraScreen
            setVisibleScreen={setVisibleScreen}
            responseObject={responseObject}
            setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
            setGoodCameras={setGoodCameras}
            setConnectivityCameras={setConnectivityCameras}
            setLowResolutionCameras={setLowResolutionCameras}
            setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
          />
        );
      }, 2000);
    },
    (reason: AxiosError) => {
      cameraObjectSubmitFail = true;
      if (
        reason.request.response.includes(
          "Please select a different camera name"
        )
      ) {
        localStorage.setItem("cameraNamingError", "true");
        localStorage.setItem(
          "cameraNumber",
          `${localStorage.cameraNumber - 1}`
        );
        setVisibleScreen(
          <WizardCameraScreen
            setVisibleScreen={setVisibleScreen}
            responseObject={responseObject}
            setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
            setGoodCameras={setGoodCameras}
            setConnectivityCameras={setConnectivityCameras}
            setLowResolutionCameras={setLowResolutionCameras}
            setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
          />
        );
      } else {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          localStorage.setItem("addCameraError", "true");
          localStorage.setItem(
            "cameraNumber",
            `${localStorage.cameraNumber - 1}`
          );
          setVisibleScreen(
            <WizardCameraScreen
              setVisibleScreen={setVisibleScreen}
              responseObject={responseObject}
              setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
              setGoodCameras={setGoodCameras}
              setConnectivityCameras={setConnectivityCameras}
              setLowResolutionCameras={setLowResolutionCameras}
              setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
            />
          );
        });
      }
    }
  );
  !cameraObjectSubmitFail &&
    (await ActuateUI.post(
      `customer/${localStorage.siteId}/action/deploy_next/`
    ).then(
      () => {
        deployFail = false;
        localStorage.setItem("syncNeeded", "false");
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !cameraObjectSubmitFail &&
    !deployFail &&
    (await ActuateUI.get(
      `camera/site/?customer__id=${localStorage.siteId}`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
};
