import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";

export const alertTitleCreator = (nameString: string) => {
  switch (nameString) {
    case "connectivity":
      return (
        <p className="font-mulish text-red-600">
          <CancelIcon sx={{ color: "#D32F2F" }} />
          &nbsp; Connectivity
        </p>
      );
    case "scene_change":
      return (
        <p className="font-mulish text-red-600">
          <CancelIcon sx={{ color: "#D32F2F" }} />
          &nbsp; Scene Change
        </p>
      );
    case "recording_status":
      return (
        <p className="font-mulish text-red-600">
          <CancelIcon sx={{ color: "#D32F2F" }} />
          &nbsp; Recording Status
        </p>
      );
    case "motion_status":
      return (
        <p className="font-mulish text-actuate-orange">
          <ErrorIcon sx={{ color: "#F57C00" }} />
          &nbsp; Motion Status
        </p>
      );
    case "image_quality":
      return (
        <p className="font-mulish text-actuate-orange">
          <ErrorIcon sx={{ color: "#F57C00" }} />
          &nbsp; Image Quality
        </p>
      );
    case "stream_quality":
      return (
        <p className="font-mulish text-actuate-orange">
          <ErrorIcon sx={{ color: "#F57C00" }} />
          &nbsp; Stream Quality
        </p>
      );
    // case "license_info":
    //   return (
    //     <p className="font-mulish text-actuate-orange">
    //       <ErrorIcon sx={{ color: "#F57C00" }} />
    //       &nbsp; License Info
    //     </p>
    //   );
  }
};
