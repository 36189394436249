import React from "react";
import { NavigateFunction } from "react-router-dom";
import { findSelectedRowIndex } from "../../Alerts/AlertsUtils/findSelectedRowIndex";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { handleButtonShading } from "./handleButtonShading";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleArrowButtonClick = (
  investigationData: any,
  selectedRow: any,
  clipsIndexOrder: any,
  directionString: string,
  setSelectedRow: any,
  setInvestigationData: any,
  setVideoClipLoading: Dispatcher<boolean>,
  setVerifiedClicked: Dispatcher<boolean>,
  setUnverifiedClicked: Dispatcher<boolean>,
  setVideoURL: Dispatcher<string>,
  userFilters: any,
  navigate: NavigateFunction
) => {
  // const investigationData = JSON.parse(localStorage.investigationData);

  // const selectedRow = JSON.parse(localStorage.investigationsSelectedRow);

  // const clipsIndexOrder = JSON.parse(localStorage.clipsIndexOrder);

  if (
    (directionString === "forward" &&
      selectedRow.index !== clipsIndexOrder[clipsIndexOrder.length - 1]) ||
    (directionString === "back" && selectedRow.index !== clipsIndexOrder[0])
  ) {
    setVideoClipLoading(true);
    const currentIndex = findSelectedRowIndex(
      clipsIndexOrder,
      selectedRow.index
    );
    let newSelectedRow: any;

    directionString === "forward"
      ? (newSelectedRow = investigationData[currentIndex + 1])
      : (newSelectedRow = investigationData[currentIndex - 1]);

    setSelectedRow(newSelectedRow);
    localStorage.setItem(
      "investigationsSelectedRow",
      JSON.stringify(newSelectedRow)
    );

    const updatedInvestigationData = investigationData;

    updatedInvestigationData.forEach((object: any) => {
      object.selected = false;
    });

    directionString === "forward"
      ? (updatedInvestigationData[currentIndex + 1].selected = true)
      : (updatedInvestigationData[currentIndex - 1].selected = true);

    setInvestigationData(updatedInvestigationData);

    // localStorage.setItem(
    //   "investigationData",
    //   JSON.stringify(updatedInvestigationData)
    // );

    const AxiosUI = createApi("");

    const retryFunction = () => {
      handleArrowButtonClick(
        investigationData,
        selectedRow,
        clipsIndexOrder,
        directionString,
        setSelectedRow,
        setInvestigationData,
        setVideoClipLoading,
        setVerifiedClicked,
        setUnverifiedClicked,
        setVideoURL,
        userFilters,
        navigate
      );
    };
    AxiosUI.get(
      `clip/url/?username=${userFilters.user}&file_id=${newSelectedRow.file_id}`
    ).then(
      (response: AxiosResponse) => {
        handleButtonShading(
          newSelectedRow,
          setVerifiedClicked,
          setUnverifiedClicked
        );
        setVideoURL(response.data);
        setVideoClipLoading(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          handleButtonShading(
            newSelectedRow,
            setVerifiedClicked,
            setUnverifiedClicked
          );
          const newInvestigationData = investigationData;
          newInvestigationData.forEach((object: any) => {
            object.selected = false;
          });
          directionString === "forward"
            ? (newInvestigationData[currentIndex + 1].selected = true)
            : (newInvestigationData[currentIndex - 1].selected = true);
          setInvestigationData(newInvestigationData);
          localStorage.setItem(
            "investigationData",
            JSON.stringify(newInvestigationData)
          );
          setVideoURL("");
          setVideoClipLoading(false);
        });
      }
    );
  }
};
