export const getBaseURL = (): string => {
  const { protocol, hostname, port } = window.location;
  let baseURL = `${protocol}//${hostname}`;

  // Append port if it's not the default port for the protocol
  if (
    port &&
    ((protocol === "http:" && port !== "80") ||
      (protocol === "https:" && port !== "443"))
  ) {
    baseURL += `:${port}`;
  }

  return baseURL;
};
