// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AllAlertsData, LoadingData } from "./analyticsInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getAllAlertsData = (
  setAllAlertsData: Dispatcher<AllAlertsData[]>,
  groupId: string,
  start_date: string,
  end_date: string,
  siteIdsArray: string[],
  cameraIdsArray: string[],
  navigate: NavigateFunction,
  setLoadingData: Dispatcher<LoadingData>
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getAllAlertsData(
      setAllAlertsData,
      groupId,
      start_date,
      end_date,
      siteIdsArray,
      cameraIdsArray,
      navigate,
      setLoadingData
    );
  };

  let searchParamsString = ``;

  if (!groupId && !end_date && start_date) {
    searchParamsString = `?start_date=${start_date}`;
  }
  if (!groupId && end_date && !start_date) {
    searchParamsString = `?end_date=${end_date}`;
  }
  if (groupId && !end_date && start_date) {
    searchParamsString = `?group=${groupId}&start_date=${start_date}`;
  }
  if (groupId && end_date && !start_date) {
    searchParamsString = `?group=${groupId}&end_date=${end_date}`;
  }
  if (groupId && end_date && start_date) {
    searchParamsString = `?group=${groupId}&start_date=${start_date}&end_date=${end_date}`;
  }
  if (!groupId && end_date && start_date) {
    searchParamsString = `?start_date=${start_date}&end_date=${end_date}`;
  }
  if (siteIdsArray[0]) {
    let additionalSiteString = `&site=${siteIdsArray[0]}`;
    if (siteIdsArray.length > 1) {
      siteIdsArray.forEach((siteId: string, index: number) => {
        if (index !== 0) {
          additionalSiteString += `,${siteId}`;
        }
      });
    }
    searchParamsString += additionalSiteString;
  }
  if (cameraIdsArray[0]) {
    let additionalCamerasString = `${cameraIdsArray[0]}`;
    if (cameraIdsArray.length > 1) {
      cameraIdsArray.forEach((cameraId: string, index: number) => {
        if (index !== 0) {
          additionalCamerasString += `,${cameraId}`;
        }
      });
    }
    searchParamsString = `?camera=${additionalCamerasString}&start_date=${start_date}&end_date=${end_date}`;
  }
  AxiosUI.get(`analytics/alert_volume/${searchParamsString}`).then(
    (response: AxiosResponse) => {
      setAllAlertsData(response.data);
      setLoadingData((previousState) => {
        return { ...previousState, allAlertsData: false };
      });
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setLoadingData((previousState) => {
          return { ...previousState, allAlertsData: false };
        });
      });
    }
  );
};
