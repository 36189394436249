import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getNumbersAfterActionLogs = (url: string) => {
  const match = url.match(/\/actionlogs\/(\d+)/);
  if (match) {
    return match[1];
  } else {
    return "";
  }
};

export const handleFetchActionLogsData = (
  setActionLogsData: any,
  setFetchingActionLogsData: Dispatcher<boolean>,
  setSiteName: Dispatcher<string>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleFetchActionLogsData(
      setActionLogsData,
      setFetchingActionLogsData,
      setSiteName,
      navigate
    );
  };

  const customerID = getNumbersAfterActionLogs(location.href);

  AxiosUI.get(`command_history/action_log/?customer=${customerID}`).then(
    (response: AxiosResponse) => {
      const results = response.data.results;
      setActionLogsData(results);

      AxiosUI.get(`customer/${customerID}/`).then(
        (response: AxiosResponse) => {
          setSiteName(response.data.name);
          setFetchingActionLogsData(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setFetchingActionLogsData(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setFetchingActionLogsData(false);
      });
    }
  );
};
