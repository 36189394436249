import React from "react";

export const checkInitialDataForImmixOrSentinelValues = (initialData: any) => {
  const immixAlertPostObject = JSON.parse(localStorage.immixAlertPostObject);
  const sentinelAlertPostObject = JSON.parse(
    localStorage.sentinelAlertPostObject
  );

  const updatedInitialData = initialData;

  if (immixAlertPostObject.immix_server) {
    updatedInitialData.immix_server = immixAlertPostObject.immix_server;
  }

  if (immixAlertPostObject.immix_port) {
    updatedInitialData.immix_port = immixAlertPostObject.immix_port;
  }

  if (sentinelAlertPostObject.sentinel_server) {
    updatedInitialData.sentinel_server =
      sentinelAlertPostObject.sentinel_server;
  }

  if (sentinelAlertPostObject.sentinel_site_id) {
    updatedInitialData.sentinel_site_id =
      sentinelAlertPostObject.sentinel_site_id;
  }

  if (sentinelAlertPostObject.sentinel_device_address) {
    updatedInitialData.sentinel_device_address =
      sentinelAlertPostObject.sentinel_device_address;
  }

  if (sentinelAlertPostObject.draw_ignore_zones) {
    updatedInitialData.draw_ignore_zones = sentinelAlertPostObject.draw_ignore;
  }

  return updatedInitialData;
};
