/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { PrecisionAndRecallTable } from "./PrecisionAndRecallTable";
import { NavigateFunction } from "react-router-dom";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { handleArrowButtonClickSentinel } from "./InvestigationUtilities/handleArrowButtonClickSentinel";
import { SentinelTable } from "./SentinelTable";
import { LoadingSpinner } from "../../common/LoadingSpinner";
import { saveAs } from "file-saver";
import { ShowMoreDialogSentinel } from "./ShowMoreDialogSentinel";
import { createSiteHyperlink } from "../Alerts/AlertsUtils/createSiteHyperlink";
import { createCameraHyperlink } from "../Alerts/AlertsUtils/createCameraHyperlink";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const SentinelTab = ({
  showMoreDialogOpenSentinel,
  setShowMoreDialogOpenSentinel,
  selectedRowSentinel,
  videoURLSentinel,
  sentinelData,
  pageSentinel,
  setPageSentinel,
  precisionRecallDataSentinel,
  videoClipLoadingSentinel,
  setVideoURLSentinel,
  setVideoClipLoadingSentinel,
  setSelectedRowSentinel,
  setSentinelData,
  scrollToTop,
  setPageLoadingSentinel,
  clipsIndexOrderSentinel,
  setClipsIndexOrderSentinel,
  fetchingFilteredDataSentinel,
  userFiltersSentinel,
  loadingAdditionalData,
  navBarCollapsed,
  userInfo,
  navigate,
}: {
  showMoreDialogOpenSentinel: boolean;
  setShowMoreDialogOpenSentinel: Dispatcher<boolean>;
  selectedRowSentinel: any;
  videoURLSentinel: string;
  sentinelData: any;
  pageSentinel: any;
  setPageSentinel: any;
  precisionRecallDataSentinel: any;
  videoClipLoadingSentinel: boolean;
  setVideoURLSentinel: Dispatcher<string>;
  setVideoClipLoadingSentinel: Dispatcher<boolean>;
  setSelectedRowSentinel: any;
  setSentinelData: any;
  scrollToTop: any;
  setPageLoadingSentinel: Dispatcher<boolean>;
  clipsIndexOrderSentinel: any;
  setClipsIndexOrderSentinel: any;
  fetchingFilteredDataSentinel: boolean;
  userFiltersSentinel: any;
  loadingAdditionalData: boolean;
  navBarCollapsed: boolean;
  userInfo: any;
  navigate: NavigateFunction;
}) => {
  return (
    <div
      className={
        navBarCollapsed
          ? userInfo.permission.sentinel && userInfo.permission.ailink
            ? "relative left-[75px] top-[150px] w-[1200px]"
            : "relative left-[75px] top-[90px] w-[1200px]"
          : userInfo.permission.sentinel && userInfo.permission.ailink
          ? "relative left-[150px] top-[150px] w-[1200px]"
          : "relative left-[150px] top-[90px] w-[1200px]"
      }
    >
      <p className="relative left-[20px] top-[15px] text-actuate-blue font-mulish text-[20px]">
        {selectedRowSentinel.display_name
          ? createSiteHyperlink(
              selectedRowSentinel.display_name,
              selectedRowSentinel.customer_pk
            )
          : createSiteHyperlink(
              selectedRowSentinel.customer_name,
              selectedRowSentinel.customer_pk
            )}{" "}
        |{" "}
        {createCameraHyperlink(
          selectedRowSentinel.camera_name,
          selectedRowSentinel.customer_pk,
          selectedRowSentinel.camera_pk
        )}
      </p>
      {!videoClipLoadingSentinel && (
        <div className="absolute left-[1150px] top-[0px] w-[150px] pt-[10px]">
          <ShowMoreDialogSentinel
            showMoreDialogOpenSentinel={showMoreDialogOpenSentinel}
            setShowMoreDialogOpenSentinel={setShowMoreDialogOpenSentinel}
            selectedRowSentinel={selectedRowSentinel}
            videoURLSentinel={videoURLSentinel}
          />
        </div>
      )}
      <div className="flex pt-[20px] pl-[10px]">
        <div>
          {!fetchingFilteredDataSentinel && (
            <div>
              <div>
                <div className={!navBarCollapsed ? "w-[655px]" : "w-[725px]"}>
                  <SentinelTable
                    sentinelData={sentinelData}
                    setSentinelData={setSentinelData}
                    setSelectedRowSentinel={setSelectedRowSentinel}
                    pageSentinel={pageSentinel}
                    setPageSentinel={setPageSentinel}
                    setVideoClipLoadingSentinel={setVideoClipLoadingSentinel}
                    setVideoURLSentinel={setVideoURLSentinel}
                    scrollToTop={scrollToTop}
                    selectedRowSentinel={selectedRowSentinel}
                    setClipsIndexOrderSentinel={setClipsIndexOrderSentinel}
                    userFiltersSentinel={userFiltersSentinel}
                    navigate={navigate}
                  />
                </div>
                {loadingAdditionalData && (
                  <div className="relative pt-[5px]">
                    <div className="relative left-[10px]">
                      <LoadingSpinner />
                    </div>
                    <p className="absolute left-[58px] top-[7px] font-mulish text-actuate-orange">
                      Loading Addtional Data
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="w-[660px]">
            <div className="w-[660px]">
              <div>
                {videoClipLoadingSentinel && (
                  <div className="text-center">
                    <ThemeProvider theme={theme}>
                      <div>
                        <CircularProgress color="primary" />
                      </div>
                      <br></br>
                      <p className="font-mulish text-[20px]">
                        Video Loading...
                      </p>
                    </ThemeProvider>
                  </div>
                )}
                {!videoClipLoadingSentinel && (
                  <div className="flex items-center justify-between w-[660px]">
                    <div className="flex-none" id="arrow_back">
                      <OrangeTextButton
                        onClick={() => {
                          handleArrowButtonClickSentinel(
                            sentinelData,
                            selectedRowSentinel,
                            clipsIndexOrderSentinel,
                            "back",
                            setSelectedRowSentinel,
                            setSentinelData,
                            setVideoClipLoadingSentinel,
                            setVideoURLSentinel,
                            userFiltersSentinel,
                            navigate
                          );
                        }}
                        text={
                          <ArrowBackIosIcon fontSize="large" id="BackButton" />
                        }
                        disabled={
                          selectedRowSentinel.index ===
                          clipsIndexOrderSentinel[0]
                        }
                      />
                    </div>
                    <div className="flex-auto" id="video">
                      <React.Fragment>
                        <video width="550" autoPlay controls loop>
                          <source src={videoURLSentinel} type="video/mp4" />
                        </video>
                      </React.Fragment>
                      <div>
                        <OrangeTextButton
                          onClick={() => saveAs(videoURLSentinel, "video.mp4")}
                          text="Download Video"
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="flex-none" id="arrow_forward">
                      <OrangeTextButton
                        onClick={() => {
                          handleArrowButtonClickSentinel(
                            sentinelData,
                            selectedRowSentinel,
                            clipsIndexOrderSentinel,
                            "forward",
                            setSelectedRowSentinel,
                            setSentinelData,
                            setVideoClipLoadingSentinel,
                            setVideoURLSentinel,
                            userFiltersSentinel,
                            navigate
                          );
                        }}
                        text={
                          <ArrowForwardIosIcon
                            fontSize="large"
                            id="forwardButton"
                          />
                        }
                        disabled={
                          selectedRowSentinel.index ===
                          clipsIndexOrderSentinel[
                            clipsIndexOrderSentinel.length - 1
                          ]
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div
        className={
          !navBarCollapsed
            ? "bg-white h-[55px] w-[1305px]"
            : "bg-white h-[55px] w-[1380px]"
        }
      >
        <div className="bg-white h-[55px]">
          <p className="font-black font-mulish text-[20px] p-3 indent-4">
            Precision and Recall
          </p>
        </div>
        <PrecisionAndRecallTable
          precisionRecallData={precisionRecallDataSentinel}
        />
      </div>
    </div>
  );
};
