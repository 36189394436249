import React from "react";
import { useFormikContext } from "formik";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { createTheme, ThemeProvider } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

const BackButton = ({ previous }: { previous: any }) => {
  const formik = useFormikContext();

  return (
    <div className="absolute right-[288px]">
      <ThemeProvider theme={theme}>
        <Button
          onClick={() => previous(formik.values)}
          type="button"
          color="primary"
        >
          <ArrowBackIcon /> BACK
        </Button>
      </ThemeProvider>
    </div>
  );
};

export default BackButton;
