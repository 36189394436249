import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getCaledarsData = (
  setCalendarData: any,
  setFetchingCalendarData: Dispatcher<boolean>,
  querryString: string,
  setPage: any,
  setShowMoreDialogOpen: any,
  setSelectedRow: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    getCaledarsData(
      setCalendarData,
      setFetchingCalendarData,
      querryString,
      setPage,
      setShowMoreDialogOpen,
      setSelectedRow,
      navigate
    );
  };

  let fullQuerryString = "";

  if (querryString !== "") {
    fullQuerryString = `?search=${querryString}`;
  }

  AxiosUI.get(`calendar/${fullQuerryString}`).then(
    (response: AxiosResponse) => {
      const results = response.data.results;
      // const updatedResults: any[] = [];
      setCalendarData(results);
      if (results[0]) {
        setSelectedRow(results[0]);
      }
      setFetchingCalendarData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setFetchingCalendarData(false);
      });
    }
  );
};
