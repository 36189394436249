import { productsMatcher } from "../../../utils/productsMatcher";

export const handleCheckBoxClick = (
  productName: string,
  setCamerasObject: any,
  camerasObject: any,
  objectId: number,
  checked: boolean,
  productsArray: any[]
) => {
  const newObject = camerasObject[objectId];

  const oldProductsArray = camerasObject[objectId].products;

  const newProductsArray: any[] = [];

  checked
    ? oldProductsArray.push(productsMatcher("Id", productName, productsArray))
    : oldProductsArray.forEach((id: number) => {
        if (id !== productsMatcher("Id", productName, productsArray)) {
          newProductsArray.push(id);
        }
      });

  setCamerasObject((previousState: any) => {
    checked
      ? (newObject.products = oldProductsArray)
      : (newObject.products = newProductsArray);

    return { ...previousState, objectId: newObject };
  });
};
