import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const ErrorWarningTabs = ({
  dashboardTotal,
  setActiveTab,
  setPage,
  navBarCollapsed,
}: {
  dashboardTotal: any;
  setActiveTab: any;
  setPage: any;
  navBarCollapsed: boolean;
}) => {
  const [clickedTab, setClickedTab] = React.useState("one");

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Tabs
          value={clickedTab}
          onChange={(event, tabValue) => {
            setClickedTab(tabValue);
            if (tabValue === "one") {
              setPage(0);
              setActiveTab("error");
            }
            if (tabValue === "two") {
              setPage(0);
              setActiveTab("warning");
            }
          }}
          aria-label="tabs"
          color="primary"
          variant="fullWidth"
          sx={navBarCollapsed ? { width: "575px" } : { width: "500px" }}
        >
          <Tab
            value="one"
            label={`Error (${dashboardTotal ? dashboardTotal.error : `N/A`})`}
            sx={{ fontFamily: "Mulish", fontWeight: "Bold", color: "primary" }}
          />
          <Tab
            value="two"
            label={`Warning (${
              dashboardTotal ? dashboardTotal.warning : `N/A`
            })`}
            sx={{ fontFamily: "Mulish", fontWeight: "Bold", color: "primary" }}
          />
        </Tabs>
      </ThemeProvider>
    </Box>
  );
};
