import React from "react";

export const addOptimalSettingsNumber = (renderedRows: any) => {
  const updatedRenderedRows: any[] = [];

  renderedRows.forEach((row: any) => {
    const newRow = row;

    let optimalSettingsNumber = 0;

    if (row.requirements_warning > 0 && row.requirements_bad < 1) {
      optimalSettingsNumber = 1;
    } else if (row.requirements_bad > 0) {
      optimalSettingsNumber = 2;
    } else {
      optimalSettingsNumber = 0;
    }

    newRow.optimal_settings_number = optimalSettingsNumber;

    updatedRenderedRows.push(newRow);
  });

  return updatedRenderedRows;
};
