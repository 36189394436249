import React from "react";
import {
  AlertState,
  AllAlertsData,
  LoadingData,
  ParentGroupsAlertData,
  UserFilters,
} from "./AnalyticsUtils/analyticsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { yyyymmddGenerator } from "./AnalyticsUtils/yyyymmddGenerator";
import { NavigateFunction } from "react-router-dom";
import { getAllAlertsData } from "./AnalyticsUtils/getAllAlertsData";
import { getParentGroupsAlertData } from "./AnalyticsUtils/getParentGroupsAlertData";
import { getFalsePositiveDataAnalyticsPage } from "./AnalyticsUtils/getFalsePositiveDataAnalyticsPage";
import { getPercentageMotionFilteredData } from "./AnalyticsUtils/getPercentageMotionFilteredData";

export const EndDateDropDown = ({
  userFilters,
  setUserFilters,
  setAllAlertsData,
  navigate,
  setLoadingData,
  setParentGroupsAlertsData,
  setFalsePostiveData,
  setPercetageOfMotionData,
  setAveragePercentageOfMotion,
  setAlertState,
  setDisplayOnLeftBool,
  setVisibleArray,
  setConversionArray,
  setShowMoreVisible,
  setShowMoreOpen,
  setAlertsGraphName,
  setDateChangesMade,
}: {
  userFilters: UserFilters;
  setUserFilters: Dispatcher<any>;
  setAllAlertsData: Dispatcher<AllAlertsData[]>;
  navigate: NavigateFunction;
  setLoadingData: Dispatcher<LoadingData>;
  setParentGroupsAlertsData: Dispatcher<ParentGroupsAlertData[]>;
  setFalsePostiveData: Dispatcher<any>;
  setPercetageOfMotionData: Dispatcher<any>;
  setAveragePercentageOfMotion: Dispatcher<number>;
  setAlertState: Dispatcher<AlertState>;
  setDisplayOnLeftBool: Dispatcher<boolean>;
  setVisibleArray: Dispatcher<any[]>;
  setConversionArray: any;
  setShowMoreVisible: Dispatcher<boolean>;
  setShowMoreOpen: Dispatcher<boolean>;
  setAlertsGraphName: any;
  setDateChangesMade: any;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="End Date"
        value={userFilters.end_date}
        onChange={(newValue) => {
          setDateChangesMade(true);
          setShowMoreVisible(false);
          setShowMoreOpen(false);
          if (localStorage.filtersSavedClicked === "true") {
            localStorage.setItem(
              "defaultUserFilters",
              JSON.stringify({
                parent_group: userFilters.parent_group,
                site: userFilters.site,
                camera: userFilters.camera,
                start_date: userFilters.start_date,
                end_date: newValue,
              })
            );
          }
          setAlertState((previousState: AlertState) => {
            return {
              ...previousState,
              dateTooFar: false,
              startAfterEnd: false,
              endBeforeStart: false,
            };
          });
          if (
            newValue !== null &&
            (newValue > userFilters.start_date ||
              newValue.toString() === userFilters.start_date.toString())
          ) {
            setUserFilters((previousState: any) => {
              return { ...previousState, end_date: newValue };
            });
          }
          if (
            newValue !== null &&
            newValue < userFilters.start_date &&
            newValue.toString() !== userFilters.start_date.toString()
          ) {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, endBeforeStart: true };
            });
          }
        }}
        renderInput={(params) => <TextField sx={{ width: 150 }} {...params} />}
      />
    </LocalizationProvider>
  );
};
