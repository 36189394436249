import * as React from "react";
import { ModuleScreen } from "../../common/ModuleScreen";
import { WizardCameraScreen } from "./WizardCameraScreen";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { checkFormsForImmixValues } from "./checkFormsForImmixValues";
import ErrorBoundary from "../../../ErrorBoundary";
import { checkInitialDataForImmixOrSentinelValues } from "./checkInitialDataForImmixOrSentinelValues";

export const AddCamera = ({
  groupTrees,
  setGroupTrees,
  setCameraCheckFinsihedAlertOpen,
  setGoodCameras,
  setConnectivityCameras,
  setLowResolutionCameras,
  setCameraCheckResultDownloadURL,
}: {
  groupTrees: any;
  setGroupTrees: Dispatcher<any>;
  setCameraCheckFinsihedAlertOpen: any;
  setGoodCameras: any;
  setConnectivityCameras: any;
  setLowResolutionCameras: any;
  setCameraCheckResultDownloadURL: any;
}) => {
  const [visibleScreen, setVisibleScreen] = React.useState(<></>);

  const [addCameraInfo] = React.useState(
    JSON.parse(localStorage.addCameraInfo)
  );

  const [response] = React.useState({
    initial_data: checkInitialDataForImmixOrSentinelValues(
      addCameraInfo.initial_data
    ),
    forms: checkFormsForImmixValues(addCameraInfo.forms),
  });

  React.useEffect(() => {
    setVisibleScreen(
      <ErrorBoundary>
        <WizardCameraScreen
          setVisibleScreen={setVisibleScreen}
          responseObject={response}
          setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
          setGoodCameras={setGoodCameras}
          setConnectivityCameras={setConnectivityCameras}
          setLowResolutionCameras={setLowResolutionCameras}
          setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
        />
      </ErrorBoundary>
    );
  }, []);

  return (
    <ModuleScreen
      text="Add Camera"
      visibleScreen={visibleScreen}
      styling={
        "text-white absolute top-[1px] right-[450px] font-mulish text-[18px] p-3"
      }
      groupTrees={groupTrees}
      setGroupTrees={setGroupTrees}
    />
  );
};
