import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Field } from "formik";

export const CheckBoxFieldType = ({
  config,
  formik,
}: {
  config: any;
  formik: any;
}) => {
  React.useEffect(() => {
    formik.values[config.field] = false;
  }, []);

  return (
    <div key={config.id}>
      <FormControlLabel
        sx={{
          fontFamily: "Mulish",
          color: "#001943",
        }}
        label={config.label}
        control={
          <Field
            color="primary"
            checked={config.checked}
            disabled={config.disabled}
            onChange={(item: any) => {
              formik.values[config.field] = item.target.checked;
            }}
            as={CheckBoxComponent}
          />
        }
      />
    </div>
  );
};

const CheckBoxComponent = (props: any) => <Checkbox {...props} />;
