import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "./siteAboutInterfaces";
import { RecordingServerTab } from "../RecordingServerTab";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

const handlePatchRecordingServerData = (
  recordingServerData: any,
  recordingServerPatchObject: any,
  setRecordingServerData: any,
  setRecordingServerPatchObject: any,
  setRecordingServerChangesMade: Dispatcher<boolean>,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setEditMode: Dispatcher<boolean>,
  setActiveTab: any,
  setAlertState: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handlePatchRecordingServerData(
      recordingServerData,
      recordingServerPatchObject,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerChangesMade,
      setBackdropLoadingScreenOn,
      setEditMode,
      setActiveTab,
      setAlertState,
      navigate
    );
  };

  const updatedRecordingServerData: any[] = [];

  let errorNumber = 0;

  recordingServerPatchObject.map((object: any) => {
    AxiosUI.patch(`recording_server/${object.id}/`, object).then(
      (response: AxiosResponse) => {
        updatedRecordingServerData.push(response.data);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          errorNumber += 1;
        });
      }
    );
  });

  if (errorNumber === 0) {
    localStorage.setItem(
      "recordingServerBackup",
      JSON.stringify(updatedRecordingServerData)
    );
    setRecordingServerData(updatedRecordingServerData);
    setRecordingServerPatchObject(updatedRecordingServerData);
    setRecordingServerChangesMade(false);
    setEditMode(false);
    setActiveTab(
      <RecordingServerTab recordingServerData={recordingServerData} />
    );
    setBackdropLoadingScreenOn(false);
    setAlertState((previousState: any) => {
      return {
        ...previousState,
        changeSuccess: true,
      };
    });
  }
  if (errorNumber > 0) {
    setRecordingServerData(JSON.parse(localStorage.recordingServerBackup));
    setRecordingServerPatchObject(
      JSON.parse(localStorage.recordingServerBackup)
    );
    setEditMode(false);
    setActiveTab(
      <RecordingServerTab
        recordingServerData={JSON.parse(localStorage.recordingServerBackup)}
      />
    );
    setRecordingServerChangesMade(false);
    setBackdropLoadingScreenOn(false);
    alert(
      "Changes to one ore more recording servers not saved. Please check and try again."
    );
  }
};

export default handlePatchRecordingServerData;
