import React from "react";
import Chip from "@mui/material/Chip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { handleChangeStatus } from "./HealthMonitoringUtils/handleChangeStatus";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const ChipMenu: any = ({
  labelString,
  row,
  setFectchingAllData,
  setAlertsData,
  alertsData,
  setUnhealthyCamsData,
  unhealthyCamsData,
  clickedTab,
  setActiveTable,
  setAlertStatusDialogOpen,
  setSelectedRow,
  setSelectedRowIndex,
  setSortedData,
  singleCellActivated,
  activeName,
  setUpdatingStatus,
  filters,
  alertFilter,
  setSceneChangeOutcomesDialogOpen,
  setBackgroundFrame,
  setDetectedFrame,
  setSelectedRows,
  selectedRows,
  setErrorsOverviewData,
  setCameraStatsData,
  setLoadingCameraStatsData,
  setBandwidthData,
  setLoadingBandwidthData,
  setShowSiteStats,
  cameraDropDownValue,
  setFilteredDataLengths,
  navigate,
}: {
  labelString: string;
  row: any;
  setFectchingAllData: any;
  setAlertsData: any;
  alertsData: any;
  setUnhealthyCamsData: any;
  unhealthyCamsData: any;
  clickedTab: string;
  setActiveTable: any;
  setAlertStatusDialogOpen: any;
  setSelectedRow: any;
  setSelectedRowIndex: any;
  setSortedData: any;
  singleCellActivated: boolean;
  activeName: any;
  setUpdatingStatus: Dispatcher<boolean>;
  filters: any;
  alertFilter: any;
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  setBackgroundFrame: Dispatcher<string>;
  setDetectedFrame: Dispatcher<string>;
  setSelectedRows: any;
  selectedRows: any;
  setErrorsOverviewData: any;
  setCameraStatsData: any;
  setLoadingCameraStatsData: any;
  setBandwidthData: any;
  setLoadingBandwidthData: any;
  setShowSiteStats: any;
  cameraDropDownValue: any;
  setFilteredDataLengths: any;
  navigate: NavigateFunction;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  switch (labelString) {
    case "ongoing":
      return (
        <div>
          <Chip
            label={
              <>
                Ongoing
                <ArrowDropDownIcon />
              </>
            }
            color="error"
            variant="outlined"
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
              setSelectedRow(row);
            }}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              id="ongoing_menu_item"
              onClick={() => {
                setUpdatingStatus(true);
                handleChangeStatus(
                  setAlertsData,
                  "ongoing",
                  "ongoing",
                  row,
                  alertsData,
                  setFectchingAllData,
                  setUnhealthyCamsData,
                  unhealthyCamsData,
                  clickedTab,
                  setActiveTable,
                  setAlertStatusDialogOpen,
                  setSelectedRow,
                  setSelectedRowIndex,
                  setSortedData,
                  singleCellActivated,
                  activeName,
                  setUpdatingStatus,
                  filters,
                  alertFilter,
                  setSceneChangeOutcomesDialogOpen,
                  setBackgroundFrame,
                  setDetectedFrame,
                  setSelectedRows,
                  selectedRows,
                  setErrorsOverviewData,
                  setCameraStatsData,
                  setLoadingCameraStatsData,
                  setBandwidthData,
                  setLoadingBandwidthData,
                  setShowSiteStats,
                  cameraDropDownValue,
                  setFilteredDataLengths,
                  navigate
                );
                handleClose();
              }}
            >
              Ongoing
            </MenuItem>
            <MenuItem
              id="pending_menu_item"
              onClick={() => {
                setUpdatingStatus(true);
                handleChangeStatus(
                  setAlertsData,
                  "pending",
                  "ongoing",
                  row,
                  alertsData,
                  setFectchingAllData,
                  setUnhealthyCamsData,
                  unhealthyCamsData,
                  clickedTab,
                  setActiveTable,
                  setAlertStatusDialogOpen,
                  setSelectedRow,
                  setSelectedRowIndex,
                  setSortedData,
                  singleCellActivated,
                  activeName,
                  setUpdatingStatus,
                  filters,
                  alertFilter,
                  setSceneChangeOutcomesDialogOpen,
                  setBackgroundFrame,
                  setDetectedFrame,
                  setSelectedRows,
                  selectedRows,
                  setErrorsOverviewData,
                  setCameraStatsData,
                  setLoadingCameraStatsData,
                  setBandwidthData,
                  setLoadingBandwidthData,
                  setShowSiteStats,
                  cameraDropDownValue,
                  setFilteredDataLengths,
                  navigate
                );
                handleClose();
              }}
            >
              Pending
            </MenuItem>
            {/* {row.error_type !== "connectivity" && ( */}
            <MenuItem
              id="resolved_menu_item"
              onClick={() => {
                if (row.error_type === "scene_change") {
                  setSceneChangeOutcomesDialogOpen(true);
                } else {
                  setUpdatingStatus(true);
                  handleChangeStatus(
                    setAlertsData,
                    "resolved",
                    "ongoing",
                    row,
                    alertsData,
                    setFectchingAllData,
                    setUnhealthyCamsData,
                    unhealthyCamsData,
                    clickedTab,
                    setActiveTable,
                    setAlertStatusDialogOpen,
                    setSelectedRow,
                    setSelectedRowIndex,
                    setSortedData,
                    singleCellActivated,
                    activeName,
                    setUpdatingStatus,
                    filters,
                    alertFilter,
                    setSceneChangeOutcomesDialogOpen,
                    setBackgroundFrame,
                    setDetectedFrame,
                    setSelectedRows,
                    selectedRows,
                    setErrorsOverviewData,
                    setCameraStatsData,
                    setLoadingCameraStatsData,
                    setBandwidthData,
                    setLoadingBandwidthData,
                    setShowSiteStats,
                    cameraDropDownValue,
                    setFilteredDataLengths,
                    navigate
                  );
                }

                handleClose();
              }}
            >
              Resolved
            </MenuItem>
            {/* )} */}
          </Menu>
        </div>
      );
    case "resolved":
      return (
        <div>
          <Chip
            label={
              <>
                Resolved
                <ArrowDropDownIcon />
              </>
            }
            color="success"
            variant="outlined"
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
              setSelectedRow(row);
            }}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              id="ongoing_menu_item"
              onClick={() => {
                setUpdatingStatus(true);
                handleChangeStatus(
                  setAlertsData,
                  "ongoing",
                  "resolved",
                  row,
                  alertsData,
                  setFectchingAllData,
                  setUnhealthyCamsData,
                  unhealthyCamsData,
                  clickedTab,
                  setActiveTable,
                  setAlertStatusDialogOpen,
                  setSelectedRow,
                  setSelectedRowIndex,
                  setSortedData,
                  singleCellActivated,
                  activeName,
                  setUpdatingStatus,
                  filters,
                  alertFilter,
                  setSceneChangeOutcomesDialogOpen,
                  setBackgroundFrame,
                  setDetectedFrame,
                  setSelectedRows,
                  selectedRows,
                  setErrorsOverviewData,
                  setCameraStatsData,
                  setLoadingCameraStatsData,
                  setBandwidthData,
                  setLoadingBandwidthData,
                  setShowSiteStats,
                  cameraDropDownValue,
                  setFilteredDataLengths,
                  navigate
                );
                handleClose();
              }}
            >
              Ongoing
            </MenuItem>
            <MenuItem
              id="pending_menu_item"
              onClick={() => {
                setUpdatingStatus(true);
                handleChangeStatus(
                  setAlertsData,
                  "pending",
                  "resolved",
                  row,
                  alertsData,
                  setFectchingAllData,
                  setUnhealthyCamsData,
                  unhealthyCamsData,
                  clickedTab,
                  setActiveTable,
                  setAlertStatusDialogOpen,
                  setSelectedRow,
                  setSelectedRowIndex,
                  setSortedData,
                  singleCellActivated,
                  activeName,
                  setUpdatingStatus,
                  filters,
                  alertFilter,
                  setSceneChangeOutcomesDialogOpen,
                  setBackgroundFrame,
                  setDetectedFrame,
                  setSelectedRows,
                  selectedRows,
                  setErrorsOverviewData,
                  setCameraStatsData,
                  setLoadingCameraStatsData,
                  setBandwidthData,
                  setLoadingBandwidthData,
                  setShowSiteStats,
                  cameraDropDownValue,
                  setFilteredDataLengths,
                  navigate
                );
                handleClose();
              }}
            >
              Pending
            </MenuItem>
            <MenuItem
              id="resolved_menu_item"
              onClick={() => {
                setUpdatingStatus(true);
                handleChangeStatus(
                  setAlertsData,
                  "resolved",
                  "resolved",
                  row,
                  alertsData,
                  setFectchingAllData,
                  setUnhealthyCamsData,
                  unhealthyCamsData,
                  clickedTab,
                  setActiveTable,
                  setAlertStatusDialogOpen,
                  setSelectedRow,
                  setSelectedRowIndex,
                  setSortedData,
                  singleCellActivated,
                  activeName,
                  setUpdatingStatus,
                  filters,
                  alertFilter,
                  setSceneChangeOutcomesDialogOpen,
                  setBackgroundFrame,
                  setDetectedFrame,
                  setSelectedRows,
                  selectedRows,
                  setErrorsOverviewData,
                  setCameraStatsData,
                  setLoadingCameraStatsData,
                  setBandwidthData,
                  setLoadingBandwidthData,
                  setShowSiteStats,
                  cameraDropDownValue,
                  setFilteredDataLengths,
                  navigate
                );
                handleClose();
              }}
            >
              Resolved
            </MenuItem>
          </Menu>
        </div>
      );
    case "pending":
      return (
        <div>
          <Chip
            label={
              <>
                Pending
                <ArrowDropDownIcon />
              </>
            }
            color="warning"
            variant="outlined"
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
              setSelectedRow(row);
            }}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              id="ongoing_menu_item"
              onClick={() => {
                setUpdatingStatus(true);
                handleChangeStatus(
                  setAlertsData,
                  "ongoing",
                  "pending",
                  row,
                  alertsData,
                  setFectchingAllData,
                  setUnhealthyCamsData,
                  unhealthyCamsData,
                  clickedTab,
                  setActiveTable,
                  setAlertStatusDialogOpen,
                  setSelectedRow,
                  setSelectedRowIndex,
                  setSortedData,
                  singleCellActivated,
                  activeName,
                  setUpdatingStatus,
                  filters,
                  alertFilter,
                  setSceneChangeOutcomesDialogOpen,
                  setBackgroundFrame,
                  setDetectedFrame,
                  setSelectedRows,
                  selectedRows,
                  setErrorsOverviewData,
                  setCameraStatsData,
                  setLoadingCameraStatsData,
                  setBandwidthData,
                  setLoadingBandwidthData,
                  setShowSiteStats,
                  cameraDropDownValue,
                  setFilteredDataLengths,
                  navigate
                );
                handleClose();
              }}
            >
              Ongoing
            </MenuItem>
            <MenuItem
              id="pending_menu_item"
              onClick={() => {
                setUpdatingStatus(true);
                handleChangeStatus(
                  setAlertsData,
                  "pending",
                  "pending",
                  row,
                  alertsData,
                  setFectchingAllData,
                  setUnhealthyCamsData,
                  unhealthyCamsData,
                  clickedTab,
                  setActiveTable,
                  setAlertStatusDialogOpen,
                  setSelectedRow,
                  setSelectedRowIndex,
                  setSortedData,
                  singleCellActivated,
                  activeName,
                  setUpdatingStatus,
                  filters,
                  alertFilter,
                  setSceneChangeOutcomesDialogOpen,
                  setBackgroundFrame,
                  setDetectedFrame,
                  setSelectedRows,
                  selectedRows,
                  setErrorsOverviewData,
                  setCameraStatsData,
                  setLoadingCameraStatsData,
                  setBandwidthData,
                  setLoadingBandwidthData,
                  setShowSiteStats,
                  cameraDropDownValue,
                  setFilteredDataLengths,
                  navigate
                );
                handleClose();
              }}
            >
              Pending
            </MenuItem>
            {/* {row.error_type !== "connectivity" && ( */}
            <MenuItem
              id="resolved_menu_item"
              onClick={() => {
                if (row.error_type === "scene_change") {
                  setSceneChangeOutcomesDialogOpen(true);
                } else {
                  setUpdatingStatus(true);
                  handleChangeStatus(
                    setAlertsData,
                    "resolved",
                    "pending",
                    row,
                    alertsData,
                    setFectchingAllData,
                    setUnhealthyCamsData,
                    unhealthyCamsData,
                    clickedTab,
                    setActiveTable,
                    setAlertStatusDialogOpen,
                    setSelectedRow,
                    setSelectedRowIndex,
                    setSortedData,
                    singleCellActivated,
                    activeName,
                    setUpdatingStatus,
                    filters,
                    alertFilter,
                    setSceneChangeOutcomesDialogOpen,
                    setBackgroundFrame,
                    setDetectedFrame,
                    setSelectedRows,
                    selectedRows,
                    setErrorsOverviewData,
                    setCameraStatsData,
                    setLoadingCameraStatsData,
                    setBandwidthData,
                    setLoadingBandwidthData,
                    setShowSiteStats,
                    cameraDropDownValue,
                    setFilteredDataLengths,
                    navigate
                  );
                }
                handleClose();
              }}
            >
              Resolved
            </MenuItem>
            {/* )} */}
          </Menu>
        </div>
      );
  }
};
