import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});
