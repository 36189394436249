import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError } from "axios";
import { handleButtonShading } from "./handleButtonShading";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleVerificationSelection = (
  inputString: string,
  selectedRow: any,
  investigationData: any,
  setInvestigationData: any,
  setSelectedRow: any,
  setPageLoading: any,
  setVerifiedClicked: any,
  setUnverifiedClicked: any,
  navigate: NavigateFunction
) => {
  const backupInvestigationData = investigationData;

  const updatedInvestigationData = investigationData;
  updatedInvestigationData.forEach((object: any, index: number) => {
    if (selectedRow.file_id === object.file_id) {
      updatedInvestigationData[index].ground_truth = inputString;
    }
  });
  setInvestigationData(updatedInvestigationData);
  // localStorage.setItem(
  //   "investigationData",
  //   JSON.stringify(updatedInvestigationData)
  // );

  const postedOutcome = {
    username: selectedRow.username,
    file_id: selectedRow.file_id,
    ground_truth: inputString,
  };

  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleVerificationSelection(
      inputString,
      selectedRow,
      investigationData,
      setInvestigationData,
      setSelectedRow,
      setPageLoading,
      setVerifiedClicked,
      setUnverifiedClicked,
      navigate
    );
  };

  AxiosUI.post(`/clip/ground_truth/`, postedOutcome).then(
    () => {
      setSelectedRow((previousState: any) => {
        return { ...previousState, ground_truth: inputString };
      });
      localStorage.setItem(
        "investigationsSelectedRow",
        JSON.stringify(selectedRow)
      );
      setPageLoading(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setInvestigationData(backupInvestigationData);
        localStorage.setItem(
          "investigationData",
          JSON.stringify(backupInvestigationData)
        );
        handleButtonShading(
          selectedRow,
          setVerifiedClicked,
          setUnverifiedClicked
        );
        setPageLoading(false);
        alert("Error submitting verificaiton status, please try again");
      });
    }
  );
};
