import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const InvestigationPageTabs = ({
  setActiveTab,
  clickedTab,
  setClickedTab,
}: {
  setActiveTab: Dispatcher<any>;
  clickedTab: string;
  setClickedTab: Dispatcher<string>;
}) => {
  const userInfo = JSON.parse(localStorage.userInfo);

  return (
    <>
      {userInfo.permission.sentinel && userInfo.permission.ailink && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <ThemeProvider theme={theme}>
            <Tabs
              value={clickedTab}
              onChange={(event, tabValue) => {
                setClickedTab(tabValue);
                if (tabValue === "one") {
                  //   setPage(0);
                  setActiveTab("ailink");
                }
                if (tabValue === "two") {
                  //   setPage(0);
                  setActiveTab("sentinel");
                }
                if (tabValue === "three") {
                  //   setPage(0);
                  setActiveTab("smtp");
                }
              }}
              aria-label="intestigation_tabs"
              color="primary"
              // variant="fullWidth"
              // sx={{ width: "500px" }}
            >
              {userInfo.permission.ailink && (
                <Tab
                  value="one"
                  label={`AI Link`}
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "primary",
                  }}
                  {...a11yProps(0)}
                />
              )}
              {userInfo.permission.sentinel && (
                <Tab
                  value="two"
                  label={`Sentinel`}
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "primary",
                  }}
                  {...a11yProps(1)}
                />
              )}
              {/* {userInfo.permission.smtp && (
            <Tab
              value="three"
              label={`SMTP`}
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )} */}
            </Tabs>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
};
