import { Tooltip } from "@mui/material";
import React from "react";

export const trimSelectedUserName = (userString: string) => {
  if (userString.length < 8) {
    return userString;
  } else
    return (
      <Tooltip
        title={userString}
        placement="top"
        sx={{ fontSize: "14px", fontFamily: "mulish" }}
      >
        <div>{userString.slice(0, 7)}...</div>
      </Tooltip>
    );
};
