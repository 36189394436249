import React from "react";

const findAspectLocation = (inputString: string): number => {
  // Find the index of the first occurrence of "A" in "Aspect" in the input string
  const aspectIndex: number = inputString.indexOf("Aspect");

  // Return the index of "A" in "Aspect"
  return aspectIndex !== -1 ? aspectIndex + 1 : -1; // Adding 1 to get the index of "A"
};

export const extractAspectRationInfo = (inputString: string): string | null => {
  if (inputString) {
    const aspectLocation = findAspectLocation(inputString);

    return inputString.slice(aspectLocation - 1);
  } else return "";

  // const pattern = /Aspect.*?padding\)/;

  // const match: RegExpMatchArray | null = inputString.match(pattern);

  // return match ? match[0] : null; // Return the matched substring or null if no match is found
};
