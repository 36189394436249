import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getNumbersAfterEditCamera } from "./EditCamera";

export const V1RedirectDialog = ({
  v1RedirectDialogOpen,
  setv1RedirectDialogOpen,
}: {
  v1RedirectDialogOpen: boolean;
  setv1RedirectDialogOpen: Dispatcher<boolean>;
}) => {
  return (
    <Dialog open={v1RedirectDialogOpen}>
      <DialogContent>
        <p>
          Edit Ignore Zones is current under reconstruction. Would you like to
          navigate to version 1 of the Actuate site to edit ignore zones?{" "}
        </p>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setv1RedirectDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            window.open(
              `https://admin.actuateui.net/inframap/label_zones/${getNumbersAfterEditCamera(
                location.href
              )}/?_changelist_filters=customer__id__exact%3D10257`
            );
            setv1RedirectDialogOpen(false);
          }}
          text="Ok"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
