export const yyyymmddGenerator = (inputDate: Date) => {
  if (!inputDate) {
    return "";
  }
  if (inputDate.toString().length < 25) {
    return inputDate.toString().slice(0, 10);
  }
  const year = inputDate.getFullYear();
  let month = "0";
  let date = "0";
  if (inputDate.getMonth() < 9) {
    month = `0${inputDate.getMonth() + 1}`;
  }
  if (inputDate.getMonth() > 8) {
    month = `${inputDate.getMonth() + 1}`;
  }
  if (inputDate.getDate() < 10) {
    date = `0${inputDate.getDate()}`;
  }
  if (inputDate.getDate() > 9) {
    date = `${inputDate.getDate()}`;
  }
  return `${year}-${month}-${date}`;
};
