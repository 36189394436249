export const ActuateButton = ({
  onClick,
  text,
  disabled,
}: {
  onClick: any;
  text: string;
  disabled: boolean;
}) => {
  return (
    <button
      className="shadow-button bg-actuate-orange rounded font-mulish text-white text-base	p-1.5 hover:bg-[#E67300]"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
