import { handleSetSelectedIndexLevel } from "./handleSetSelectedIndexLevel";
import { getIndiviualGroup } from "./getIndiviualGroup";

export const handleNestedLevelClick = async (
  setPage: any,
  setCurrentTitle: any,
  name: string,
  id: any,
  selectedIndex: any,
  levelName: any,
  setSelectedIndex: any,
  setRenderedRows: any,
  setPageLoading: any,
  navigate: any,
  setSiteBreadcrumbs: any,
  setSelected: any
) => {
  setSiteBreadcrumbs("");
  setPage(0);
  setCurrentTitle(name);
  handleSetSelectedIndexLevel(selectedIndex, levelName, id, setSelectedIndex);
  getIndiviualGroup(
    id,
    setRenderedRows,
    setPageLoading,
    setSiteBreadcrumbs,
    setSelected,
    navigate
  );
};
