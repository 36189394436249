import React from "react";
import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { AlertData, HeadCell } from "../Alerts/AlertsUtils/alertsInterfaces";

const headCells: readonly HeadCell[] = [
  {
    id: "display_name",
    numeric: false,
    label: "Site",
  },
  {
    id: "camera_name",
    numeric: false,
    label: "Camera",
  },
  {
    id: "formatted_start_date",
    numeric: false,
    label: `Start Time`,
  },
  {
    id: "formatted_end_date",
    numeric: false,
    label: `End Time`,
  },
  {
    id: "error_type",
    numeric: false,
    label: "Alert Type",
  },
  {
    id: "severity",
    numeric: true,
    label: "Severity",
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
  },
];

export const EnhancedAlertsTableHeadHM = ({
  onRequestSort,
  orderBy,
  order,
  activeData,
  setSelected,
  setAlertsArray,
}: {
  onRequestSort: any;
  orderBy: any;
  order: any;
  activeData: any;
  setSelected: any;
  setAlertsArray: any;
}) => {
  const createSortHandler =
    (property: keyof AlertData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      setAlertsArray(activeData);
      const newSelecteds = activeData.map((obj: any) => obj.start_timestamp);
      setSelected(newSelecteds);
      return;
    }
    if (!event.target.checked) {
      setAlertsArray([]);
    }
    setSelected([]);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onClick={(event) => {
              handleSelectAllClick(event);
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            // className="font-bold"
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontFamily: "mulish", fontWeight: 700, width: 208 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
