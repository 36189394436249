import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handlePatchCalendarEvent = (
  patchEvent: any,
  setCalendarEvents: any,
  setEditingCalendar: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handlePatchCalendarEvent(
      patchEvent,
      setCalendarEvents,
      setEditingCalendar,
      navigate
    );
  };

  const updatedPatchEvent = {
    calendar: patchEvent.calendar,
    event_date: patchEvent.event_date,
    name: patchEvent.name,
  };

  AxiosUI.patch(`calendar_event/${patchEvent.id}/`, updatedPatchEvent).then(
    (response: AxiosResponse) => {
      AxiosUI.get(
        `calendar_event/?calendar__id=${response.data.calendar}`
      ).then(
        (response: AxiosResponse) => {
          setCalendarEvents(response.data.results);
          setEditingCalendar(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setEditingCalendar(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error updating event, please try again");
        setEditingCalendar(false);
      });
    }
  );
};
