import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { checkOrX } from "./EditCameraUtils/checkOrX";

export const ActionsTable = ({
  actionHandlerData,
}: {
  actionHandlerData: any;
}) => {
  const titlesArray = [
    "Name",
    "ID",
    "Action",
    "Subactions",
    "Timeout",
    "Timetable",
  ];
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="time_tables_table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          {actionHandlerData.map((object: any) => {
            return (
              <TableRow key={object.id}>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.display_name ? <>{object.display_name}</> : "--"}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.id ? <>{object.id}</> : "--"}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.action ? (
                    <>
                      {checkOrX(object.is_actuate)} {object.action}
                    </>
                  ) : (
                    "--"
                  )}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.subactions ? <>{object.subactions}</> : "--"}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.timeout ? <>{object.timeout}</> : "--"}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.timetable ? (
                    <>
                      {checkOrX(object.is_timetable_ok)} {object.timetable}
                    </>
                  ) : (
                    "--"
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
