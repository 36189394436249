import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getAllSitesInvestigations } from "./InvestigationUtilities/getAllSitesInvestigations";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const UserDropDownAILink = ({
  userDropDownValue,
  userOptions,
  setUserDropDownValue,
  setCameraDropDownValue,
  setUserFilters,
  setSiteDropDownValue,
  setSiteOptions,
  setCameraOptions,
  setLoadingSites,
  groupID,
  setGroupID,
  navBarCollapsed,
  navigate,
}: {
  userDropDownValue: any;
  userOptions: any;
  setUserDropDownValue: any;
  setCameraDropDownValue: any;
  setUserFilters: any;
  setSiteDropDownValue: any;
  setSiteOptions: any;
  setCameraOptions: any;
  setLoadingSites: Dispatcher<boolean>;
  groupID: any;
  setGroupID: any;
  navBarCollapsed: boolean;
  navigate: NavigateFunction;
}) => {
  const options: string[] = [];

  const optionUserIds = new Map();

  userOptions.map((object: any) => {
    if (object.group) {
      options.push(object.group.name);
    }
  });

  userOptions.forEach((object: any) => {
    if (object.group) {
      optionUserIds.set(object.group.name, object.group.id);
    }
  });

  const optionUserNames = new Map();
  userOptions.forEach((object: any) => {
    if (object.group) {
      optionUserNames.set(object.group.name, object.username);
    }
  });

  return (
    <div key="user_dropdown">
      <Autocomplete
        value={userDropDownValue}
        disablePortal
        options={options}
        onChange={(event, value: any) => {
          if (value !== null) {
            setUserDropDownValue(value);
            setCameraDropDownValue("");
            setGroupID(optionUserIds.get(value));
            setSiteDropDownValue("");
            setSiteOptions([]);
            getAllSitesInvestigations(
              setSiteOptions,
              setLoadingSites,
              optionUserIds.get(value),
              navigate
            );
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                user: optionUserNames.get(value),
                site_ids: [],
                camera_ids: [],
              };
            });
          } else {
            // setFetchingFilteredData(true);
            setUserDropDownValue(value);
            setSiteDropDownValue("");
            setSiteOptions([]);
            setCameraDropDownValue("");
            setCameraOptions([]);
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                user: "testuser",
                site_ids: [],
                camera_ids: [],
              };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="User"
            autoComplete="off"
            sx={!navBarCollapsed ? { width: 150 } : { width: 175 }}
          />
        )}
      />
    </div>
  );
};
