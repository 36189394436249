import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const CameraDropDownAlertsPage = ({
  userFilters,
  setUserFilters,
  cameraOptions,
  cameraDropDownValue,
  setCameraDropDownValue,
  siteOptions,
  loadingCameras,
  navBarCollapsed,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  cameraOptions: any;
  cameraDropDownValue: any;
  setCameraDropDownValue: Dispatcher<string>;
  siteOptions: any;
  loadingCameras: boolean;
  navBarCollapsed: boolean;
}) => {
  const cameraIds = new Map();
  const cameraNames = new Map();
  cameraOptions.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });
  cameraOptions.forEach((object: any) => {
    cameraNames.set(object.id, object.camera_name);
  });

  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  return (
    <div>
      <Autocomplete
        value={
          cameraOptions[0] ? cameraNames.get(Number(userFilters.camera)) : ""
        }
        disablePortal
        options={
          cameraOptions[0]
            ? cameraOptions.map((object: any) => {
                return object.camera_name;
              })
            : []
        }
        loading={loadingCameras}
        onChange={(event, value: string) => {
          if (value !== null) {
            setCameraDropDownValue(value);
            setUserFilters((previousState: any) => {
              return { ...previousState, camera: cameraIds.get(value) };
            });
          } else {
            setCameraDropDownValue(value);
            setUserFilters((previousState: any) => {
              return { ...previousState, camera: cameraIds.get(value) };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Camera"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCameras ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
            sx={!navBarCollapsed ? { width: 150 } : { width: 175 }}
          />
        )}
      />
    </div>
  );
};
