import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";

export const PrecisionAndRecallTable = ({
  precisionRecallData,
}: {
  precisionRecallData: any;
}) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent
          titlesArray={[
            "Date",
            "Clips",
            "TP",
            "FP",
            "FN",
            "TN",
            "N/A",
            "Precision",
            "Recall",
          ]}
        />
        <TableBody>
          <TableRow>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.date_str}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.total_clips}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.true_positives}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.false_positives}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.false_negatives}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.true_negatives}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.unclassified}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.precision}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {precisionRecallData.recall}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
