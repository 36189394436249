import React from "react";
import { StepsStepper } from "./StepsStepper";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleSubmitIntegerationType } from "./handleSubmitIntegerationType";
import { Autocomplete, TextField } from "@mui/material";
import { handleSubmitSite } from "./handleSubmitSite";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ErrorBoundary from "../../../ErrorBoundary";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "4rem",
        },
      },
    },
  },
});

export const IntegrationSelectionScreen = ({
  setVisibleScreen,
}: {
  setVisibleScreen: any;
}) => {
  const [integrationType, setIntegrationType] = React.useState<any>("");

  const [integrationsTypes] = React.useState<any>(
    JSON.parse(localStorage.integrationTypes)
  );

  const [integrationSubmitError, setIntegrationSubmitError] =
    React.useState(false);

  const integrationIds = new Map();

  const navigate = useNavigate();

  const assignIntegrationIds = () => {
    integrationsTypes.forEach((element: any) => {
      integrationIds.set(`${element.display_name}`, element.id);
    });
  };

  return (
    <ErrorBoundary>
      <div>
        {integrationSubmitError && (
          <div className="absolute w-[600px] h-[50px]">
            <Alert
              severity="error"
              onClose={() => {
                setIntegrationSubmitError(false);
              }}
            >
              <p className="font-mulish font-bold text-[16px]">
                Problem Submitting Integration. Please try again.
              </p>
            </Alert>
          </div>
        )}
        <div className="absolute top-20 right-[15px]">
          <div className="flex justify-end">
            <StepsStepper activeStep={0} />
          </div>
        </div>
        <div className="absolute right-4 top-32">
          <OrangeTextButton
            disabled={!integrationType}
            onClick={() => {
              handleSubmitIntegerationType(
                setVisibleScreen,
                handleSubmitSite,
                navigate,
                setIntegrationSubmitError
              );
            }}
            text="Next"
          />
          <ArrowForwardIcon />
        </div>

        <p
          className={
            "font-mulish absolute top-[180px] text-[#283E6D] right-[244px] text-[21px]"
          }
        >
          Please select the Integration type
        </p>
        <p
          className={
            "font-mulish absolute top-[235px] font-bold text-[#283E6D] right-[442px] text-[16px]"
          }
        >
          Integration type
        </p>
        <ThemeProvider theme={theme}>
          <div className="absolute right-[215px] top-[280px]">
            <Autocomplete
              value={integrationType}
              isOptionEqualToValue={(option: any, value: any) =>
                option === value
              }
              onChange={(event: any, newInputValue: any) => {
                assignIntegrationIds();
                setIntegrationType(newInputValue);
                localStorage.setItem(
                  "integrationType",
                  integrationIds.get(newInputValue)
                );
                localStorage.setItem("selectedIntegrationType", newInputValue);
              }}
              disablePortal
              id="integration-options"
              options={JSON.parse(localStorage.integrationTypes).map(
                (object: any) => {
                  return `${object.display_name}`;
                }
              )}
              sx={{ width: 350 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Integration *"
                  sx={{}}
                />
              )}
            />
          </div>
        </ThemeProvider>
      </div>
    </ErrorBoundary>
  );
};
