import React from "react";
import { AlertsDataToggleSwitch } from "./AlertsDataToggleSwitch";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from "recharts";
import { handleSiteGroupClick } from "../Sites/SitesPage/SitesPageUtils/handleSiteGroupClick";
import { useNavigate } from "react-router-dom";
import { handleGetNewDataUsageData } from "./DashbordUtils/handleGetNewDataUsageData";
import { handleGetNewDataUsageDataByType } from "./DashbordUtils/handleGetNewDataUsageDataByType";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const DataUsageGraph = ({
  usageData,
  currentData,
  setCurrentData,
  setPageLoading,
  setUsageData,
  dateRangeName,
  setDateRangeName,
  dataIsLoading,
  setDataIsLoading,
}: {
  usageData: any;
  currentData: any;
  setCurrentData: any;
  setPageLoading: Dispatcher<boolean>;
  setUsageData: Dispatcher<any>;
  dateRangeName: string;
  setDateRangeName: Dispatcher<string>;
  dataIsLoading: boolean;
  setDataIsLoading: Dispatcher<boolean>;
}) => {
  const navigate = useNavigate();

  const [dataUsageOn, setDataUsageOn] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [last5AnchorEl, setLast5AnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const [last5Name, setLast5Name] = React.useState("Data Usage");

  const open = Boolean(anchorEl);

  const last5Open = Boolean(last5AnchorEl);

  const [rangeChoices, setRangeChoices] = React.useState(
    localStorage.rangeChoices
      ? JSON.parse(localStorage.rangeChoices)
      : [
          { name: "Last Hour", value: "last_hour" },
          { name: "Last 8 hours", value: "last_8_hours" },
          { name: "Last 24 hours", value: "last_24_hours" },
          { name: "Last week", value: "last_week" },
        ]
  );

  const dataUsageAlertVolumeArray = [
    { name: "Data Usage", value: "bandwidth" },
    { name: "Alert Volume", value: "alert_volume" },
  ];

  React.useEffect(() => {
    if (localStorage.rangeChoices) {
      setRangeChoices(JSON.parse(localStorage.rangeChoices));
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLast5Click = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLast5AnchorEl(event.currentTarget);
  };

  const handleLast5Close = () => {
    setLast5AnchorEl(null);
  };

  return (
    <div className="absolute top-[450px] left-[735px] right-[1px] h-[425px] w-[610px] bg-white">
      <p className="text-actuate-blue font-mulish text-[26px] font-bold absolute top-[15px] left-[40px]">
        Data Usage vs Alert Volume
      </p>
      <p className="text-actuate-blue font-mulish text-[16px] absolute top-[52px] left-[40px]">
        Sort Top 5 Sites by
      </p>
      <div className="text-actuate-blue font-mulish text-[16px] absolute top-[47px] left-[175px]">
        <Button
          id="sort-type-selector"
          aria-controls={last5Open ? "sort-type-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={last5Open ? "true" : undefined}
          onClick={handleLast5Click}
        >
          {last5Name}
        </Button>
        <Menu
          id="sort-type-menu"
          anchorEl={last5AnchorEl}
          open={last5Open}
          onClose={handleLast5Close}
          MenuListProps={{
            "aria-labelledby": "sort-type-selector",
          }}
        >
          {dataUsageAlertVolumeArray.map((object: any) => {
            return (
              <MenuItem
                key={object.value}
                onClick={() => {
                  const rangeChoiceValues = new Map();
                  rangeChoices.forEach((option: any) => {
                    rangeChoiceValues.set(`${option.name}`, `${option.value}`);
                  });
                  if (object.value === "alert_volume") {
                    setCurrentData("alertVolumeData");
                    setDataUsageOn(true);
                    setLast5Name("Data Usage");
                  }
                  if (object.value === "bandwidth") {
                    setCurrentData("usageData");
                    setLast5Name("Alert Volume");
                  }
                  setLast5Name(object.name);
                  setDataIsLoading(true);
                  handleClose();
                  handleGetNewDataUsageDataByType(
                    rangeChoiceValues.get(dateRangeName),
                    object.value,
                    setDataIsLoading,
                    navigate,
                    setUsageData,
                    setDateRangeName
                  );
                }}
              >
                {object.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>

      <div className="text-actuate-blue font-mulish text-[16px] absolute top-[15px] left-[470px]">
        <Button
          id="time-frame-selector"
          aria-controls={open ? "time-frame-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {dateRangeName}
        </Button>
        <Menu
          id="time-frame-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "time-frame-selector",
          }}
        >
          {rangeChoices.map((rangeChoiceObject: any) => {
            return (
              <MenuItem
                key={rangeChoiceObject.value}
                onClick={() => {
                  const timeChoiceValues = new Map();
                  dataUsageAlertVolumeArray.forEach((option: any) => {
                    timeChoiceValues.set(`${option.name}`, `${option.value}`);
                  });
                  setDateRangeName(rangeChoiceObject.name);
                  setDataIsLoading(true);
                  handleClose();
                  handleGetNewDataUsageData(
                    rangeChoiceObject.value,
                    timeChoiceValues.get(last5Name),
                    setDataIsLoading,
                    navigate,
                    setUsageData,
                    setDateRangeName
                  );
                }}
              >
                {rangeChoiceObject.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
      {currentData === "usageData" && !dataIsLoading && (
        <>
          <p className="text-[#757575] font-mulish text-[16px] absolute top-[240px] left-[-85px] -rotate-90">
            Real Time Bandwidth Usage GB
          </p>
        </>
      )}
      {currentData === "alertVolumeData" && (
        <>
          <p className="text-[#757575] font-mulish text-[16px] absolute top-[235px] left-[-35px] -rotate-90">
            Number of Alerts
          </p>
        </>
      )}
      {last5Name === "Data Usage" ? (
        <p className="text-[#757575] font-mulish text-[16px] absolute top-[395px] left-[200px]">
          {usageData.length > 1
            ? `${usageData.length} sites with Highest Data Usage`
            : `Site with highest Data Usage`}
        </p>
      ) : (
        <p className="text-[#757575] font-mulish text-[16px] absolute top-[395px] left-[200px]">
          {usageData.length > 1
            ? `${usageData.length} sites with Highest Alert Volume`
            : `Site with highest Alert Volume`}
        </p>
      )}
      <div className="absolute top-[100px] left-[40px]">
        <AlertsDataToggleSwitch
          setCurrentData={setCurrentData}
          dataUsageOn={dataUsageOn}
          setDataUsageOn={setDataUsageOn}
        />
      </div>
      {!dataIsLoading && (
        <div className="absolute top-[130px] left-[30px]">
          {currentData === "usageData" ? (
            <BarChart
              width={580}
              height={260}
              data={usageData}
              margin={{
                top: 5,
                right: 20,
                left: 20,
                bottom: 20,
              }}
              onClick={(event: any) => {
                navigate(
                  `/sites/aboutsite/${event.activePayload[0].payload.id}`
                );
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="display_name" type="category" hide />
              <YAxis />
              <Tooltip />
              <Bar
                cursor="pointer"
                dataKey="kbs"
                fill="#FF8400"
                name="GB"
                onClick={(event) => navigate(`/sites/aboutsite/${event.id}`)}
              >
                <LabelList dataKey="abreviated_name" position="bottom" />
              </Bar>
            </BarChart>
          ) : (
            <BarChart
              width={600}
              height={260}
              data={usageData}
              margin={{
                top: 5,
                right: 20,
                left: 20,
                bottom: 20,
              }}
              onClick={(event: any) => {
                navigate(
                  `/sites/aboutsite/${event.activePayload[0].payload.id}`
                );
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="display_name" type="category" hide />
              <YAxis />
              <Tooltip />
              <Bar
                cursor="pointer"
                dataKey="alert_volume"
                fill="#283E6D"
                name="Alerts"
                onClick={(event) => navigate(`/sites/aboutsite/${event.id}`)}
              >
                <LabelList dataKey="abreviated_name" position="bottom" />
              </Bar>
            </BarChart>
          )}
        </div>
      )}
      {dataIsLoading && (
        <div className="absolute top-[190px] left-[250px]">
          <CircularProgress size={25} sx={{ position: "relative", left: 40 }} />
          <div className="font-mulish text-actuate-blue test-bold">
            Fetching Data...
          </div>
        </div>
      )}
    </div>
  );
};
