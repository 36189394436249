import React from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { HeadCell } from "../Alerts/AlertsUtils/alertsInterfaces";

type Order = "asc" | "desc";

const headCells: readonly HeadCell[] = [
  {
    id: "request_date",
    numeric: false,
    label: "Date",
  },
  {
    id: "description",
    numeric: false,
    label: "Action",
  },
  {
    id: "user",
    numeric: false,
    label: "User",
  },
];

export const EnhancedActionLogsTableHead = ({
  orderBy,
  onRequestSort,
  order,
}: {
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
}) => {
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              sx={{
                fontSize: "15px",
                fontFamily: "mulish",
                fontWeight: "Bold",
              }}
              style={{ maxWidth: 100 }}
              key={headCell.id}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
