import React from "react";

const extractWidthInfo = (inputString: string): string | null => {
  // Define the pattern to match the desired substring
  const pattern = /Width.*?\)/;

  // Use regex to find the matching substring
  const match: RegExpMatchArray | null = inputString.match(pattern);

  return match ? match[0] : null; // Return the matched substring or null if no match is found
};

const extractHeightInfo = (inputString: string): string | null => {
  // Define the pattern to match the desired substring
  const pattern = /Height.*?\)/;

  // Use regex to find the matching substring
  const match: RegExpMatchArray | null = inputString.match(pattern);

  return match ? match[0] : null; // Return the matched substring or null if no match is found
};

export const extractResolutionInfo = (inputString: string): string | null => {
  if (inputString) {
    return `${extractWidthInfo(inputString)} ${extractHeightInfo(inputString)}`;
  } else return "";
};
