import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleEditCalendar = (
  rowIndex: any,
  calendarId: any,
  groupName: string,
  patchObject: any,
  calendarData: any,
  setCalendarData: any,
  setCalendarAlerts: any,
  setSelectedRow: any,
  setEditingCalendar: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleEditCalendar(
      rowIndex,
      calendarId,
      groupName,
      patchObject,
      calendarData,
      setCalendarData,
      setCalendarAlerts,
      setSelectedRow,
      setEditingCalendar,
      navigate
    );
  };

  AxiosUI.patch(`calendar/${calendarId}/`, patchObject).then(
    () => {
      AxiosUI.get(`calendar/${calendarId}/`).then(
        (response: AxiosResponse) => {
          setCalendarData((prevState: any) => {
            const newState = [...prevState];
            newState[rowIndex] = response.data;
            return newState;
          });
          setSelectedRow(response.data);
          setEditingCalendar(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setEditingCalendar(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error updating calendar, please try again");
        setEditingCalendar(false);
      });
    }
  );
};
