import React from "react";

export const siteGraphStringReturner = (dateGraphName: string) => {
  switch (dateGraphName) {
    case "Parent Group":
      return `Total alerts for selected Parent Group during selected dates`;
    case "":
      return `Total alerts for all Parent Groups during selected dates`;
    case "Site":
      return `Total alerts for selected Site(s) during selected dates`;
    case "Camera":
      return `Total alerts for selected Camera(s) during selected dates`;
  }
};
