import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;

export const AddGroupOrSiteDropDownMenu = ({
  navigate,
  setAddGroupDialogOpen,
}: {
  navigate: any;
  setAddGroupDialogOpen: any;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          key="add-group"
          onClick={() => {
            setAddGroupDialogOpen(true);
            handleClose();
          }}
        >
          Add Group
        </MenuItem>
        <MenuItem
          key="add-group"
          onClick={() => {
            handleClose();
            localStorage.setItem("clickedFromSiteAboutPage", "false");
            navigate("/sites/addsite");
            localStorage.setItem("addAnotherScheduleScreen", "false");
          }}
        >
          Add Site
        </MenuItem>
        <MenuItem
          key="add-group"
          onClick={() => {
            handleClose();
            navigate("/calendars");
          }}
        >
          Calendars
        </MenuItem>
      </Menu>
    </div>
  );
};
