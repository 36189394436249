import React from "react";
import { NavBar } from "../../common/NavBar";

export const Alerts = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: any;
  setNavBarCollapsed: any;
}) => {
  return (
    <div>
      <div className="bg-actuate-grey absolute w-screen h-screen">
        <div className="absolute top-[55px] left-[150px] right-[1px] h-[19px]] bg-white">
          <p className="font-black font-mulish text-[20px] p-3 indent-6">
            Alerts
          </p>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
