import React from "react";
import { useFormikContext } from "formik";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

const SubmitButton = ({
  next,
  isLastStep,
  errorState,
  choicesChanger,
  buttonDisabled,
  setButtonDisabled,
}: {
  next: any;
  isLastStep: boolean;
  errorState: any;
  choicesChanger: any;
  buttonDisabled: any;
  setButtonDisabled: any;
}) => {
  const formik: any = useFormikContext();

  React.useEffect(() => {
    let anyDisabled = false;
    Object.keys(errorState).forEach((key: string) => {
      if (Array.isArray(formik.values[key]) && !formik.values[key][0]) {
        anyDisabled = true;
      } else if (!Array.isArray(formik.values[key]) && !formik.values[key]) {
        anyDisabled = true;
      }
    });
    if (anyDisabled) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [formik, choicesChanger]);

  return (
    <div className="absolute right-[-169px] bottom-[-25px]">
      <ThemeProvider theme={theme}>
        {isLastStep ? (
          <Button
            className="shadow-button bg-actuate-orange rounded font-mulish text-white text-base	p-1.5"
            type="submit"
            disabled={
              // !formik.isValid && formik.isSubmitting
              // checkErrors()
              // buttonDisabled
              false
            }
          >
            Submit
          </Button>
        ) : (
          <Button
            type="submit"
            color="primary"
            disabled={
              // !formik.isValid && formik.isSubmitting
              // buttonDisabled
              false
            }
          >
            NEXT <ArrowForwardIcon />
          </Button>
        )}
      </ThemeProvider>
    </div>
  );
};

export default SubmitButton;
