import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createApi } from "../../utils/createApi";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";

export const handleGetCameraCheckResultDownloadURL = (
  setCameraCheckResultDownloadURL: Dispatcher<string>,
  siteId: any,
  setCameraCheckFinsihedAlertOpen: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleGetCameraCheckResultDownloadURL(
      setCameraCheckResultDownloadURL,
      siteId,
      setCameraCheckFinsihedAlertOpen,
      navigate
    );
  };

  AxiosUI.get(`proactive_alerting/${siteId}/download/`).then(
    (response: AxiosResponse) => {
      setCameraCheckResultDownloadURL(response.data.url);
      setCameraCheckFinsihedAlertOpen(true);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setTimeout(() => {
          handleGetCameraCheckResultDownloadURL(
            setCameraCheckResultDownloadURL,
            siteId,
            setCameraCheckFinsihedAlertOpen,
            navigate
          );
        }, 2000);
      });
    }
  );
};
