import React from "react";

export const handleCreateFilterTypeName = (filterString: string) => {
  if (!filterString) {
    return "";
  }
  switch (filterString) {
    case "ALL":
      return "All Alerts";
    case "CLAIMED":
      return "Resolved Alerts";
    case "UNCLAIMED":
      return "Unresolved Alerts";
  }
};
