import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../common/SearchBar";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const CalendarsSearchBar = ({
  setCalendarsSearchString,
}: {
  setCalendarsSearchString: Dispatcher<string>;
}) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        // placeholder="Search Calendar Names"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setCalendarsSearchString(event.target.value)
        }
        inputProps={{ "aria-label": "search" }}
      />
    </Search>
  );
};
