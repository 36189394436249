import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const GroundTruthDropDown = ({
  userFilters,
  setUserFilters,
  showUserDropDown,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  showUserDropDown: boolean;
}) => {
  const sx = showUserDropDown ? { width: 150 } : { width: 190 };

  return (
    <div key="ground_truth_dropdown">
      <Autocomplete
        value={userFilters.ground_truth}
        disablePortal
        options={["All", "Verified", "Unverified"]}
        onChange={(event, value: any) => {
          if (value !== "All") {
            setUserFilters((previousState: any) => {
              return { ...previousState, ground_truth: value };
            });
          } else {
            setUserFilters((previousState: any) => {
              return { ...previousState, ground_truth: "" };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Ground Truth"
            autoComplete="off"
            sx={sx}
          />
        )}
      />
    </div>
  );
};
