import * as React from "react";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import CancelIcon from "@mui/icons-material/Cancel";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const DeleteCameraButton = ({
  selected,
  setDeleteDialogOpen,
}: {
  selected: readonly string[];
  setDeleteDialogOpen: Dispatcher<boolean>;
}) => {
  const text = (
    <>
      <CancelIcon fontSize="small" />
      &nbsp;DELETE
    </>
  );
  return (
    <OrangeTextButton
      disabled={!selected[0]}
      onClick={() => {
        setDeleteDialogOpen(true);
      }}
      text={text}
    />
  );
};
