import { Typography } from "@mui/material";
import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { TimeTablesTable } from "./TimeTablesTable";
import { handleAddTimeTable } from "./EditCameraUtils/handleAddTimeTable";
import { IPNotifyTable } from "./IPNotifyTable";
import { handleAddIPNotify } from "./EditCameraUtils/handleAddIPNotify";
import { ActionsTable } from "./ActionsTable";
import { handleAddAction } from "./EditCameraUtils/handleAddAction";

export const MobotixTab = ({
  timeTablesData,
  navigate,
  setBackdropLoadingScreenOn,
  setTimeTablesData,
  setActiveTab,
  iPNotifyData,
  setIPNotifyData,
  actionHandlerData,
  setActionHandlerData,
}: {
  timeTablesData: any;
  navigate: NavigateFunction;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setTimeTablesData: Dispatcher<any>;
  setActiveTab: Dispatcher<any>;
  iPNotifyData: any;
  setIPNotifyData: Dispatcher<any>;
  actionHandlerData: any;
  setActionHandlerData: Dispatcher<any>;
}) => {
  const currentCameraInfo = JSON.parse(localStorage.currentCameraInfo);

  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  return (
    <div className="relative min-w-[1150px] left-[0px] bg-white">
      <div key="time_tables">
        <div>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            fontWeight="bold"
            sx={{
              fontFamily: "Mulish",
              color: "#001943",
              paddingTop: 2,
              paddingLeft: 2,
              paddingBottom: 1,
            }}
          >
            Time Tables
          </Typography>
          <div className="absolute top-[15px] left-[150px]">
            {permissions.edit_cameras && (
              <OrangeTextButton
                onClick={() => {
                  setBackdropLoadingScreenOn(true);
                  handleAddTimeTable(
                    currentCameraInfo.id,
                    navigate,
                    setBackdropLoadingScreenOn,
                    setTimeTablesData,
                    setActiveTab,
                    iPNotifyData,
                    setIPNotifyData,
                    actionHandlerData,
                    setActionHandlerData
                  );
                }}
                text="+ Add Time table"
                disabled={false}
              />
            )}
          </div>
          <TimeTablesTable timeTablesData={timeTablesData} />
        </div>
        <div key="ip_notify" className="relative min-w-[1150px]">
          <div>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              fontWeight="bold"
              sx={{
                fontFamily: "Mulish",
                color: "#001943",
                paddingTop: 2,
                paddingLeft: 2,
              }}
            >
              IP Notify
            </Typography>
            <div className="absolute top-[15px] left-[150px]">
              {permissions.edit_cameras && (
                <OrangeTextButton
                  onClick={() => {
                    setBackdropLoadingScreenOn(true);
                    handleAddIPNotify(
                      currentCameraInfo.id,
                      navigate,
                      setBackdropLoadingScreenOn,
                      setIPNotifyData,
                      setActiveTab,
                      timeTablesData,
                      setTimeTablesData,
                      actionHandlerData,
                      setActionHandlerData
                    );
                  }}
                  text="+ Add IP Notify"
                  disabled={false}
                />
              )}
            </div>
            <IPNotifyTable iPNotifyData={iPNotifyData} />
          </div>
        </div>
        <div key="actions" className="relative min-w-[1150px]">
          <div>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              fontWeight="bold"
              sx={{
                fontFamily: "Mulish",
                color: "#001943",
                paddingTop: 2,
                paddingLeft: 2,
              }}
            >
              Actions
            </Typography>
            <div className="absolute top-[15px] left-[150px]">
              {permissions.edit_cameras && (
                <OrangeTextButton
                  onClick={() => {
                    setBackdropLoadingScreenOn(true);
                    handleAddAction(
                      currentCameraInfo.id,
                      navigate,
                      setBackdropLoadingScreenOn,
                      setIPNotifyData,
                      setActiveTab,
                      timeTablesData,
                      setTimeTablesData,
                      iPNotifyData,
                      setActionHandlerData
                    );
                  }}
                  text="+ Add Action"
                  disabled={false}
                />
              )}
            </div>
            <ActionsTable actionHandlerData={actionHandlerData} />
          </div>
        </div>
      </div>
    </div>
  );
};
