// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { CameraOptions } from "./analyticsInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getCamerasBySite = (
  idArray: string[],
  navigate: NavigateFunction,
  setCameraOptions: Dispatcher<CameraOptions[]>,
  setLoadingCameras: Dispatcher<boolean>
) => {
  setLoadingCameras(true);
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getCamerasBySite(idArray, navigate, setCameraOptions, setLoadingCameras);
  };

  let idString = "";

  if (idArray.length === 1) {
    idString = `${idArray[0]}`;
  }
  if (idArray.length > 1) {
    idString = `${idArray[0]}`;
    idArray.forEach((id: string, index: number) => {
      if (index > 0) {
        idString += `,${id}`;
      }
    });
  }

  idArray.length === 0
    ? setCameraOptions([])
    : AxiosUI.get(`camera/site/?customers=${idString}`).then(
        (response: AxiosResponse) => {
          setCameraOptions(response.data.results);
          setLoadingCameras(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert("Error retrieving sites. Please try again");
            setLoadingCameras(false);
          });
        }
      );
};
