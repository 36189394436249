import React from "react";
import { createApi } from "../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { createTheme } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
import { handleGetCameraCheckResultDownloadURL } from "./handleGetCameraCheckResultDownloadURL";

export const handleCheckCameras = (
  setCameraCheckFinsihedAlertOpen: Dispatcher<boolean>,
  setGoodCameras: Dispatcher<any>,
  setConnectivityCameras: Dispatcher<any>,
  setLowResolutionCameras: Dispatcher<any>,
  siteId: string,
  setCameraCheckResultDownloadURL: Dispatcher<string>,
  navigate: NavigateFunction
) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF8400",
      },
    },
  });

  const AxiosUI = createApi(``);

  const checkProgress = (siteId: string) => {
    AxiosUI.get(`proactive_alerting/${siteId}/check_progress/`).then(
      (response: AxiosResponse) => {
        const data = response.data;
        if (
          data.percent_completed !== 100 &&
          !data.connectivity_reason &&
          data.total_cameras !== 0
        ) {
          setTimeout(() => {
            checkProgress(siteId);
          }, 2000);
        } else {
          if (data.total_cameras !== 0) {
            const totalCamerasLength = Number(data.total_cameras);
            AxiosUI.get(`camera/site/?customer__id=${siteId}`).then(
              (response: AxiosResponse) => {
                const cameras = response.data.results;
                const cameraNames = new Map();
                cameras.forEach((object: any) => {
                  cameraNames.set(object.id, object.camera_name);
                });
                AxiosUI.get(`proactive_alerting/${siteId}/check_cameras/`).then(
                  (response: AxiosResponse) => {
                    const goodCameras: string[] = [];
                    const connectivityCameras: string[] = [];
                    const lowResolutionCameras: any[] = [];
                    const cameraResults = response.data;

                    cameraResults.forEach((object: any) => {
                      if (object.section === "connectivity") {
                        object.camera_list.forEach((object: any) => {
                          connectivityCameras.push(
                            cameraNames.get(object.camera)
                          );
                        });
                      }
                      if (object.section === "good") {
                        object.camera_list.forEach((object: any) => {
                          goodCameras.push(cameraNames.get(object.camera));
                        });
                      }
                      if (object.section === "suboptimal_settings") {
                        object.camera_list.forEach((object: any) => {
                          lowResolutionCameras.push({
                            cameraName: cameraNames.get(object.camera),
                            detail: object.detail,
                          });
                        });
                      }
                    });
                    if (
                      goodCameras.length +
                        connectivityCameras.length +
                        lowResolutionCameras.length !==
                      totalCamerasLength
                    ) {
                      setTimeout(() => {
                        checkProgress(siteId);
                      }, 2000);
                    } else {
                      setGoodCameras(goodCameras);
                      setConnectivityCameras(connectivityCameras);
                      setLowResolutionCameras(lowResolutionCameras);
                      handleGetCameraCheckResultDownloadURL(
                        setCameraCheckResultDownloadURL,
                        siteId,
                        setCameraCheckFinsihedAlertOpen,
                        navigate
                      );
                    }
                  }
                );
              }
            );
          }
        }
      }
    );
  };

  AxiosUI.post(`/customer/${siteId}/action/refresh_preview/`).then(() => {
    checkProgress(siteId);
  });
};
