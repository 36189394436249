import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Autocomplete, TextField } from "@mui/material";
import { TableHeadComponent } from "../../common/TableHead";

export const WebhooksCameraTabEditMode = ({
  cameraWebhookData,
  setCameraWebhooksPatchObject,
  setCameraWebhooksChangesMade,
}: {
  cameraWebhookData: any;
  setCameraWebhooksPatchObject: any;
  setCameraWebhooksChangesMade: any;
}) => {
  const titlesArray = ["Webhook", "Custom Fields", "Event Type"];

  const webHookEventTypeOptions = JSON.parse(
    localStorage.webHookEventTypeOptions
  );

  const webHookEventTypeNames = new Map();
  webHookEventTypeOptions.forEach((option: any) => {
    webHookEventTypeNames.set(option.name, option.id);
  });

  const handleConvertTypeToString = (string: any) => {
    if (string === "no_motion") {
      return "No Motion";
    } else if (string === "unable_to_connect") {
      return "Unable to Connect";
    } else if (string === "detection") {
      return "Detection";
    } else return "";
  };

  return (
    <TableContainer component={Paper} sx={{ width: "screen" }}>
      <Table
        sx={{
          postion: "absolute",
          width: "screen",
          fontFamily: "Mulish",
        }}
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          <TableRow>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {cameraWebhookData.webhook.display_name}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              <TextField
                defaultValue={cameraWebhookData.custom_fields}
                variant="standard"
                sx={{ width: 200 }}
                type="string"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCameraWebhooksPatchObject((previousState: any) => {
                    return {
                      ...previousState,
                      custom_fields: event.target.value,
                    };
                  });
                  setCameraWebhooksChangesMade(true);
                }}
              />
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              <Autocomplete
                disablePortal
                options={webHookEventTypeOptions.map((option: any) => {
                  return option.name;
                })}
                defaultValue={handleConvertTypeToString(
                  cameraWebhookData.event_types[0]
                )}
                onChange={(event: any, value: any) => {
                  setCameraWebhooksPatchObject((previousState: any) => {
                    return {
                      ...previousState,
                      event_types: [webHookEventTypeNames.get(value)],
                    };
                  });
                  setCameraWebhooksChangesMade(true);
                }}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" sx={{}} />
                )}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
