import React from "react";

export const extractFrameRateInfo = (inputString: string): string | null => {
  if (inputString) {
    const pattern = /Aspect[\s\S]*$/;

    const match: RegExpMatchArray | null = inputString.match(pattern);

    return match ? match[0] : null; // Return the matched substring or null if no match is found
  } else return "";
};
