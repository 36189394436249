import React from "react";
import { TextField } from "@mui/material";
import { Field } from "formik";

export const TextAreaFieldType = ({
  config,
  formik,
  setErrorState,
}: {
  config: any;
  formik: any;
  setErrorState: any;
}) => {
  React.useEffect(() => {
    if (config.validation) {
      setErrorState((previousState: any) => {
        return { ...previousState, [config.id]: true };
      });
    }
  }, []);

  return (
    <div key={config.id}>
      <Field
        id={config.id}
        label={config.label}
        name={config.field}
        sx={{ width: 350 }}
        helperText={config.helperText}
        rows={config.rows ? config.rows : 2}
        as={TextComponent}
      />
    </div>
  );
};

const TextComponent = (props: any) => <TextField multiline {...props} />;
