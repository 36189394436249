import React from "react";
import { createApi } from "../../utils/createApi";
import { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";

export const getQRCode = (
  setQRCode: Dispatcher<string>,
  setPageLoading: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    getQRCode(setQRCode, setPageLoading, navigate);
  };

  const AxiosUI = createApi(``);

  AxiosUI.get(`auth/mfa_info/`).then(
    (response: any) => {
      setQRCode(response.data.qr_code_url);
      setPageLoading(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setPageLoading(false);
      });
    }
  );
};
