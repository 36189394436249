import React from "react";

export const resizeImageIgnoreZones = (
  input: string
): { width: number; height: number } => {
  if (input) {
    const [widthStr, heightStr] = input
      .split("x")
      .map((str) => parseInt(str.trim()));
    const aspectRatio = widthStr / heightStr;

    let adjustedWidth = widthStr;
    let adjustedHeight = heightStr;

    if (adjustedWidth / aspectRatio > 360) {
      adjustedWidth = 640;
      adjustedHeight = adjustedWidth / aspectRatio;
    } else {
      adjustedHeight = 360;
      adjustedWidth = adjustedHeight * aspectRatio;
    }

    if (adjustedWidth > 640) {
      adjustedWidth = 640;
      adjustedHeight = adjustedWidth / aspectRatio;
    }

    if (adjustedHeight > 360) {
      adjustedHeight = 360;
      adjustedWidth = adjustedHeight * aspectRatio;
    }

    return {
      width: adjustedWidth,
      height: adjustedHeight,
    };
  } else {
    return {
      height: 360,
      width: 640,
    };
  }
};
