import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { TableHeadComponent } from "../../common/TableHead";
import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import DeleteIcon from "@mui/icons-material/Delete";
import useWindowDimensions from "../../common/useWindowDimensions";
import { DialogActions, Paper, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { ObjectSchema } from "yup";
import ErrorBoundary from "../../../ErrorBoundary";

export const StepTwoAddCalendar = ({
  calendarEvents,
  setCalendarEvents,
}: {
  calendarEvents: any;
  setCalendarEvents: any;
}) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getMonthName = (monthNumber: number) => {
    return monthNames[monthNumber - 1];
  };

  const formatDate = (date: any) => {
    if (date === "--") {
      return "--";
    }
    const parts = date.split("-");
    return `${getMonthName(parts[1])} ${parts[2]}, ${parts[0]}`;
  };

  const { width, height } = useWindowDimensions();

  const [calcuatedHeight, setCalculatedHeight] = React.useState(0);

  React.useEffect(() => {
    setCalculatedHeight(height - 225);
  }, [height, width]);

  const [calendarDate, setCalendarDate] = React.useState(new Date());

  const [tempString, setTempString] = React.useState("");

  const [addDateDisabled, setAddDateDisabled] = React.useState(false);

  //   const [calendarEvents, setCalendarEvents] = React.useState<any[]>([]);

  const [currentCalendarEvents, setCurrentCalendarEvents] =
    React.useState(calendarEvents);

  return (
    <ErrorBoundary>
      <div>
        <div className="absolute top-[160px] left-[30px]">
          {/* <Paper sx={{ overflow: "hidden" }}> */}
          <TableContainer sx={{ maxHeight: calcuatedHeight, width: 540 }}>
            <Table
              sx={{ fontFamily: "Mulish", width: "100%", overflow: "scroll" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHeadComponent
                titlesArray={["Event Date", "Event Name", ""]}
              />
              <TableBody>
                {currentCalendarEvents.map((object: any) => {
                  if (object.editMode) {
                    return (
                      <TableRow key={object.name}>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={calendarDate}
                              onChange={(newValue: any) => {
                                const index = currentCalendarEvents.findIndex(
                                  (obj: any) => obj.name === object.name
                                );
                                setCalendarDate(newValue);
                                setCurrentCalendarEvents((prevState: any) => {
                                  const newState = [...prevState];
                                  newState[index].event_date =
                                    yyyymmddGenerator(newValue);
                                  return newState;
                                });
                                setCalendarEvents(currentCalendarEvents);
                              }}
                              renderInput={(params) => (
                                <TextField sx={{ width: 150 }} {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          <TextField
                            value={tempString}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setTempString(event.target.value);
                            }}
                            variant="standard"
                            sx={{ fontFamily: "Mulish", width: 200 }}
                          />
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          <OrangeTextButton
                            onClick={() => {
                              const index = currentCalendarEvents.findIndex(
                                (obj: any) => obj.name === object.name
                              );

                              setCurrentCalendarEvents((prevState: any) => {
                                const newState = [...prevState];
                                newState[index].editMode = false;
                                newState[index].name = tempString;
                                newState[index].event_date =
                                  yyyymmddGenerator(calendarDate);
                                return newState;
                              });
                              setCalendarEvents(currentCalendarEvents);
                              setAddDateDisabled(false);
                            }}
                            disabled={false}
                            text="Save"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  } else
                    return (
                      <TableRow key={object.name}>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {formatDate(object.event_date)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontFamily: "Mulish", width: 250 }}
                        >
                          {object.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontFamily: "Mulish", width: 100 }}
                        >
                          <OrangeTextButton
                            onClick={() => {
                              setCurrentCalendarEvents(
                                currentCalendarEvents.filter(
                                  (obj: any) => obj.name !== object.name
                                )
                              );
                              setCalendarEvents(
                                currentCalendarEvents.filter(
                                  (obj: any) => obj.name !== object.name
                                )
                              );
                            }}
                            text={<DeleteIcon />}
                            disabled={false}
                          />
                        </TableCell>
                      </TableRow>
                    );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions>
            <span>
              <OrangeTextButton
                onClick={() => {
                  setCurrentCalendarEvents([
                    ...currentCalendarEvents,
                    {
                      event_date: yyyymmddGenerator(new Date()),
                      name: "",
                      editMode: true,
                    },
                  ]);
                  setTempString("");
                  setAddDateDisabled(true);
                  setCalendarDate(new Date());
                }}
                text="+ Add Event"
                disabled={addDateDisabled}
              />
            </span>
          </DialogActions>
          {/* </Paper> */}
        </div>
        {/* <div className="absolute top-[625px] left-[480px] w-[150px]">
      <OrangeTextButton
              onClick={() => {
                // setAddDateDialogOpen(true);
              }}
              text="+ Add Event"
              disabled={false}
            />
      </div> */}
      </div>
    </ErrorBoundary>
  );
};
