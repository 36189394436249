import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const severityTypeIconCreator = (severityNumber: number) => {
  switch (severityNumber) {
    case 1:
      return (
        <>
          <ArrowUpwardIcon fontSize="small" sx={{ color: "#D32F2F" }} />
          &nbsp; High
        </>
      );
    case 2:
      return (
        <>
          <HorizontalRuleIcon fontSize="small" sx={{ color: "#FF9800" }} />
          &nbsp; Medium
        </>
      );
    case 3:
      return (
        <>
          <ArrowDownwardIcon fontSize="small" sx={{ color: "#0288D1" }} />
          &nbsp; Low
        </>
      );
  }
};
