import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { CircularProgress } from "@mui/material";
import { LoadingData } from "./AnalyticsUtils/analyticsInterfaces";

export const FalsePositiveReductionGraphAnalytics = ({
  falsePostiveData,
  loadingData,
  navBarCollapsed,
}: {
  falsePostiveData: any;
  loadingData: LoadingData;
  navBarCollapsed: boolean;
}) => {
  return (
    <div
      className={
        navBarCollapsed
          ? "absolute top-[495px] left-[20px] right-[1px] h-[370px] w-[652px] bg-[#BDBDBD]"
          : "absolute top-[495px] left-[20px] right-[1px] h-[370px] w-[610px] bg-[#BDBDBD]"
      }
    >
      <div
        className={
          navBarCollapsed
            ? "absolute top-[1px] left-[1px] right-[1px] h-[368px] w-[650px] bg-white"
            : "absolute top-[1px] left-[1px] right-[1px] h-[368px] w-[608px] bg-white"
        }
      >
        <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[7px] left-[40px]">
          False Positive Reduction
        </p>
        <p className="text-actuate-blue font-mulish text-[14px] absolute top-[36px] left-[40px]">
          Compare alerts you received against motion counts during the selected
          dates
        </p>

        {loadingData.falsePositiveData && (
          <div className="absolute top-[170px] left-[230px]">
            <CircularProgress
              size={25}
              sx={{ position: "relative", left: 40 }}
            />
            <div className="font-mulish text-actuate-blue test-bold">
              Fetching Data...
            </div>
          </div>
        )}
        {!loadingData.falsePositiveData && (
          <div className="absolute top-[60px] left-[0px]">
            <BarChart
              width={!navBarCollapsed ? 580 : 622}
              height={310}
              barSize={falsePostiveData.length < 5 ? 120 : "auto"}
              data={falsePostiveData}
              margin={{
                top: 5,
                right: 0,
                left: 40,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              {falsePostiveData[0] && <Tooltip />}
              <Legend verticalAlign="top" height={36} />
              <Bar
                dataKey="detections"
                stackId="a"
                fill="#283E6D"
                name="Detections"
              />
              <Bar
                dataKey="motion_counts"
                stackId="b"
                fill="#FF8400"
                name="Motion Counts"
              />
            </BarChart>
          </div>
        )}
      </div>
    </div>
  );
};
