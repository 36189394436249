import React from "react";
import { getMax7Array } from "./getMax7Array";
import { getConversionArray } from "./getConversionArray";

export const handleFilterCameras = (
  cameraIdsArray: any[],
  parentGroupsAlertsData: any,
  setParentGroupsAlertsData: any,
  setLoadingData: any,
  setVisibleArray: any,
  setConversionArray: any,
  setShowMoreVisible: any,
  setShowMoreOpen: any
) => {
  const updatedParentGroupsAlertsData: any[] = [];
  cameraIdsArray.forEach((id: any) => {
    parentGroupsAlertsData.forEach((object: any) => {
      if (object.id === id) {
        updatedParentGroupsAlertsData.push(object);
      }
    });
  });
  setParentGroupsAlertsData(updatedParentGroupsAlertsData);
  setVisibleArray(
    getMax7Array(
      getConversionArray(
        updatedParentGroupsAlertsData,
        setConversionArray,
        setShowMoreVisible
      )
    )
  );
  setShowMoreOpen(false);
  setLoadingData((previousState: any) => {
    return {
      ...previousState,
      parentGroupsData: false,
    };
  });
};
