import React from "react";
import { TextField } from "@mui/material";
import { Field } from "formik";
import { requiredResponseValidator } from "../formikContainer/requiredResponseValidator";

export const StringFieldType = ({
  config,
  formik,
  setErrorState,
  stepNumber,
}: {
  config: any;
  formik: any;
  setErrorState: any;
  stepNumber: any;
}) => {
  // const validateString = (value: any) => {
  //   requiredResponseValidator(
  //     value,
  //     config.id,
  //     config.validation,
  //     setErrorState
  //   );
  // };

  React.useEffect(() => {
    if (
      localStorage.copyValues === "true" &&
      localStorage.firstCameraAdded === "false" &&
      stepNumber === 1
    ) {
      const values = JSON.parse(localStorage.copiedValues);
      formik.values.ip = values.ip;
      formik.values.username = values.username;
      formik.values.channel = values.channel;
      formik.values.immix_server = values.immix_server;
      formik.values.immix_email = values.immix_email;
      formik.values.immix_port = values.immix_port;
      formik.values.immix_input1 = values.immix_input1;
      formik.values.sureview_server = values.sureview_server;
      formik.values.sureview_subject = values.sureview_subject;
      formik.values.sureview_cc = values.sureview_cc;
      formik.values.sureview_email = values.sureview_email;
    }
    if (config.validation) {
      setErrorState((previousState: any) => {
        return { ...previousState, [config.id]: true };
      });
    }
  }, []);

  return (
    <div key={config.id}>
      <Field
        id={config.id}
        label={config.label}
        name={config.field}
        autoComplete="off"
        variant="standard"
        sx={{ width: 350 }}
        helperText={config.helperText}
        defaultValue={config.defaultValue ? config.defaultValue : ""}
        type="string"
        // validate={validateString}
        as={TextComponent}
      />
    </div>
  );
};

const TextComponent = (props: any) => <TextField {...props} />;
