import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getTotalCamerasInfo = async (
  setDashboardTotal: any,
  setCamerasErrorInfo: any,
  setCamerasWarningInfo: any,
  setActiveTab: any,
  activeTab: any,
  cameraTotalBoolean: boolean,
  dashaboardTotal: any,
  errorBoolean: boolean,
  warningBoolean: boolean,
  navigate: any
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    getTotalCamerasInfo(
      setDashboardTotal,
      setCamerasErrorInfo,
      setCamerasWarningInfo,
      setActiveTab,
      activeTab,
      cameraTotalBoolean,
      dashaboardTotal,
      errorBoolean,
      warningBoolean,
      navigate
    );
  };
  await AxiosUI.get(`camera/dashboard_total/`).then(
    (response: AxiosResponse) => {
      // localStorage.setItem("dashboardTotal", JSON.stringify(response.data));
      setDashboardTotal(response.data);
      AxiosUI.get(
        // `camera/dashboard/?page_size=${dashaboardTotal.error}&status=error`
        `camera/dashboard/?status=error`
      ).then(
        (response: AxiosResponse) => {
          // if (response.data.count < 8001) {
          //   localStorage.setItem(
          //     `camerasErrorInfo`,
          //     JSON.stringify(response.data.results)
          //   );
          // }
          setCamerasErrorInfo(response.data.results);
          if (activeTab === "error") {
            setActiveTab("warning");
            setActiveTab("error");
          }
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setCamerasErrorInfo([]);
          });
        }
      );
      AxiosUI.get(
        // `camera/dashboard/?page_size=${dashaboardTotal.warning}&status=warning`
        `camera/dashboard/?status=warning`
      ).then(
        (response: AxiosResponse) => {
          // if (response.data.count < 8001) {
          //   localStorage.setItem(
          //     `camerasWarningInfo`,
          //     JSON.stringify(response.data.results)
          //   );
          // }
          setCamerasWarningInfo(response.data.results);
          if (activeTab === "warning") {
            setActiveTab("error");
            setActiveTab("warning");
          }
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setCamerasWarningInfo([]);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
  cameraTotalBoolean &&
    !errorBoolean &&
    AxiosUI.get(
      // `camera/dashboard/?page_size=${dashaboardTotal.error}&status=error`
      `camera/dashboard/?status=error`
    ).then(
      (response: AxiosResponse) => {
        // if (response.data.count < 5001) {
        //   localStorage.setItem(
        //     `camerasErrorInfo`,
        //     JSON.stringify(response.data.results)
        //   );
        // }
        setCamerasErrorInfo(response.data.results);
        if (activeTab === "error") {
          setActiveTab("warning");
          setActiveTab("error");
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setCamerasErrorInfo([]);
        });
      }
    );
  cameraTotalBoolean &&
    !warningBoolean &&
    AxiosUI.get(
      // `camera/dashboard/?page_size=${dashaboardTotal.warning}&status=warning`
      `camera/dashboard/?status=warning`
    ).then(
      (response: AxiosResponse) => {
        // if (response.data.count < 5001) {
        //   localStorage.setItem(
        //     `camerasWarningInfo`,
        //     JSON.stringify(response.data.results)
        //   );
        // }
        setCamerasWarningInfo(response.data.results);
        if (activeTab === "warning") {
          setActiveTab("error");
          setActiveTab("warning");
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setCamerasWarningInfo([]);
        });
      }
    );
};
