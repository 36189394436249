import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleSubmitNoteChanges = (
  selectedRowsToSend: any,
  setSelectedRowsToSend: any,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    handleSubmitNoteChanges(
      selectedRowsToSend,
      setSelectedRowsToSend,
      navigate
    );
  };

  const arrayToParse = selectedRowsToSend;

  setSelectedRowsToSend([]);

  const AxiosUI = createApi(``);

  arrayToParse.forEach((object: any) => {
    AxiosUI.post(
      `/healthcheck_result/update_healthcheck/?camera_id=${object.camera_id}&created_timestamp=${object.created_timestamp}&healthcheck_type=${object.error_type}&status=${object.status}&notes=${object.notes}`,
      object
    ).then(
      () => {},
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    );
  });
};
