import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleSendImmixTest = (
  immixAlert: any,
  setSendingImmixTest: Dispatcher<boolean>,
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>,
  setImmixTestFailDialogOpen: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const postedObject = {
    alert_url: "",
    immix_server: immixAlert.immix_server,
    immix_port: immixAlert.immix_port,
    immix_email: immixAlert.immix_email,
    immix_frames: 5,
    immix_input1: immixAlert.immix_input1,
    immix_text: "",
    ses_cc: "",
  };

  const retryFunction = () => {
    handleSendImmixTest(
      immixAlert,
      setSendingImmixTest,
      setImmixTestSuccessDialogOpen,
      setImmixTestFailDialogOpen,
      navigate
    );
  };

  AxiosUI.post(`/alarm/immix/`, postedObject).then(
    () => {
      setSendingImmixTest(false);
      setImmixTestSuccessDialogOpen(true);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setSendingImmixTest(false);
        setImmixTestFailDialogOpen(true);
      });
    }
  );
};
