import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { createAboutTableInfoLabel } from "./SiteAboutPageUtils/createAboutTableInfoLabel";
export const AboutTableHead = ({ titlesArray }: { titlesArray: string[] }) => {
  return (
    <TableHead>
      <TableRow>
        {titlesArray.map((title: string) => {
          if (title === "Armed" || title === "Synced" || title === "Motion %") {
            return <>{createAboutTableInfoLabel(title)}</>;
          } else {
            return (
              <TableCell
                key={title}
                align="left"
                sx={{ fontFamily: "Mulish", fontWeight: "Bold" }}
              >
                {title}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};
