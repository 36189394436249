import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const getRecordingServerData = (
  customerID: string,
  setRecordingServerData: any,
  setRecordingServerPatchObject: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    getRecordingServerData(
      customerID,
      setRecordingServerData,
      setRecordingServerPatchObject,
      navigate
    );
  };

  AxiosUI.get(`recording_server/?customer=${customerID}`).then(
    (response: AxiosResponse) => {
      setRecordingServerData(response.data);
      setRecordingServerPatchObject(response.data);
      localStorage.setItem(
        "recordingServerBackup",
        JSON.stringify(response.data)
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
