import React from "react";

export const updateAlertData = (
  postedAlert: any,
  previousStatus: string,
  alertsData: any
) => {
  let updatedAlertsAllData = alertsData.all;
  let updatedAlertsErrorsData = alertsData.errors;
  let updatedAlertsWarningsData = alertsData.warnings;
  let updatedAlertsPendingData = alertsData.pending;
  let updatedAlertsResolvedData = alertsData.resolved;

  if (
    postedAlert.error_type === "connectivity" ||
    postedAlert.error_type === "scene_change"
  ) {
    const selectedIndexAllData = updatedAlertsAllData.findIndex(
      (object: any) => object.start_timestamp === postedAlert.start_timestamp
    );
    updatedAlertsAllData[selectedIndexAllData] = postedAlert;

    const selectedIndexErrorsData = updatedAlertsErrorsData.findIndex(
      (object: any) => object.start_timestamp === postedAlert.start_timestamp
    );
    updatedAlertsErrorsData[selectedIndexErrorsData] = postedAlert;
  }

  if (
    postedAlert.error_type === "motion_status" ||
    postedAlert.error_type === "image_quality" ||
    postedAlert.error_type === "stream_quality" ||
    postedAlert.error_type === "recording_status"
  ) {
    const selectedIndexAllData = updatedAlertsAllData.findIndex(
      (object: any) => object.start_timestamp === postedAlert.start_timestamp
    );
    updatedAlertsAllData[selectedIndexAllData] = postedAlert;

    const selectedIndexWarningsData = updatedAlertsWarningsData.findIndex(
      (object: any) => object.start_timestamp === postedAlert.start_timestamp
    );
    updatedAlertsWarningsData[selectedIndexWarningsData] = postedAlert;
  }

  if (postedAlert.status === "resolved") {
    updatedAlertsResolvedData.push(postedAlert);
    // filter through updatedAlertsAllData and remove any alert that matches the start_timestamp and camera_id of the postedAlert
    updatedAlertsAllData = updatedAlertsAllData.filter(
      (object: any) => JSON.stringify(object) !== JSON.stringify(postedAlert)
    );
    updatedAlertsPendingData = updatedAlertsPendingData.filter(
      (object: any) => JSON.stringify(object) !== JSON.stringify(postedAlert)
    );
  }

  if (postedAlert.status === "pending") {
    updatedAlertsPendingData.push(postedAlert);
  }

  if (postedAlert.status === "ongoing") {
    if (
      postedAlert.error_type === "connectivity" ||
      postedAlert.error_type === "scene_change"
    ) {
      updatedAlertsErrorsData.push(postedAlert);
    }
    if (
      postedAlert.error_type === "motion_status" ||
      postedAlert.error_type === "image_quality" ||
      postedAlert.error_type === "stream_quality" ||
      postedAlert.error_type === "recording_status"
    ) {
      updatedAlertsWarningsData.push(postedAlert);
    }
  }

  if (previousStatus === "ongoing") {
    const selectedIndexWarningsData = updatedAlertsWarningsData.findIndex(
      (object: any) => object.start_timestamp === postedAlert.start_timestamp
    );
    updatedAlertsWarningsData = updatedAlertsWarningsData.filter(
      (obj: any, index: number) => index !== selectedIndexWarningsData
    );
    const selectedIndexErrorsData = updatedAlertsErrorsData.findIndex(
      (object: any) => object.start_timestamp === postedAlert.start_timestamp
    );
    updatedAlertsErrorsData = updatedAlertsErrorsData.filter(
      (obj: any, index: number) => index !== selectedIndexErrorsData
    );
  }

  if (previousStatus === "resolved") {
    const selectedIndexResolvedData = updatedAlertsResolvedData.findIndex(
      (object: any) => object.start_timestamp === postedAlert.start_timestamp
    );
    updatedAlertsResolvedData = updatedAlertsResolvedData.filter(
      (obj: any, index: number) => index !== selectedIndexResolvedData
    );
    updatedAlertsAllData.unshift(postedAlert);
  }

  if (previousStatus === "pending") {
    const selectedIndexPendingData = updatedAlertsPendingData.findIndex(
      (object: any) => object.start_timestamp === postedAlert.start_timestamp
    );
    updatedAlertsPendingData = updatedAlertsPendingData.filter(
      (obj: any, index: number) => index !== selectedIndexPendingData
    );
  }

  return {
    all: updatedAlertsAllData,
    errors: updatedAlertsErrorsData,
    warnings: updatedAlertsWarningsData,
    pending: updatedAlertsPendingData,
    resolved: updatedAlertsResolvedData,
  };
};
