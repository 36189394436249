import React from "react";
import { getAllHMData } from "./HealthMonitoringUtils/getAllHMData";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleDownloadReport } from "./HealthMonitoringUtils/handleDownloadReport";
import { updateSearchParamValues } from "../../utils/updateSearchParamValues";

export const ApplyFiltersButtonHM = ({
  userFilters,
  setUserFilters,
  setErrorsOverviewData,
  setCameraStatsData,
  setLoadingCameraStatsData,
  setUnhealthyCamsData,
  setAlertsData,
  setFectchingAllData,
  setActiveTable,
  setAlertStatusDialogOpen,
  setSelectedRow,
  setSelectedRowIndex,
  setUpdatingStatus,
  setBandwidthData,
  setLoadingBandwidthData,
  setShowSiteStats,
  setSingleCellActivated,
  setActiveName,
  cameraDropDownValue,
  setDownloadURL,
  searchParams,
  setSearchParams,
  metricsSelected,
  setBandwidthLoadingError,
  setNotesValue,
  setBackgroundFrame,
  setDetectedFrame,
  setNoAlertsDialogOpen,
  setFiltersApplied,
  setClickedTab,
  navigate,
}: {
  userFilters: any;
  setUserFilters: any;
  setErrorsOverviewData: any;
  setCameraStatsData: any;
  setLoadingCameraStatsData: any;
  setUnhealthyCamsData: any;
  setAlertsData: any;
  setFectchingAllData: any;
  setActiveTable: any;
  setAlertStatusDialogOpen: any;
  setSelectedRow: any;
  setSelectedRowIndex: any;
  setUpdatingStatus: any;
  setBandwidthData: any;
  setLoadingBandwidthData: any;
  setShowSiteStats: any;
  setSingleCellActivated: Dispatcher<boolean>;
  setActiveName: Dispatcher<string>;
  cameraDropDownValue: string;
  setDownloadURL: Dispatcher<string>;
  searchParams: any;
  metricsSelected: string[];
  setBandwidthLoadingError: Dispatcher<boolean>;
  setSearchParams: Dispatcher<any>;
  setNotesValue: Dispatcher<string>;
  setBackgroundFrame: Dispatcher<string>;
  setDetectedFrame: Dispatcher<string>;
  setNoAlertsDialogOpen: Dispatcher<boolean>;
  setFiltersApplied: Dispatcher<boolean>;
  setClickedTab: Dispatcher<string>;
  navigate: NavigateFunction;
}) => {
  return (
    <button
      className="shadow-button bg-actuate-orange border-2	border-actuate-orange rounded font-mulish text-white text-base p-1.5 w-[120px]"
      onClick={() => {
        setFiltersApplied(true);
        updateSearchParamValues(
          {
            parent_group: userFilters.parent_group,
            site: userFilters.site,
            camera: userFilters.camera,
            metrics: metricsSelected,
            start_date: userFilters.start_date,
            end_date: userFilters.end_date,
          },
          searchParams,
          setSearchParams,
          ""
        );
        setDownloadURL("");
        handleDownloadReport(userFilters, setDownloadURL, navigate);
        setSingleCellActivated(false);
        setActiveName("");
        setLoadingCameraStatsData(true);
        setFectchingAllData(true);
        setLoadingBandwidthData(true);
        setUserFilters((previousState: any) => {
          return {
            ...previousState,
            metrics: metricsSelected,
          };
        });
        setAlertsData({
          all: [],
          errors: [],
          warnings: [],
          pending: [],
          resolved: [],
        });
        getAllHMData(
          setErrorsOverviewData,
          setCameraStatsData,
          setLoadingCameraStatsData,
          setUnhealthyCamsData,
          setAlertsData,
          setFectchingAllData,
          {
            parent_group: userFilters.parent_group,
            site: userFilters.site,
            camera: userFilters.camera,
            metrics: metricsSelected,
            start_date: userFilters.start_date,
            end_date: userFilters.end_date,
          },
          setActiveTable,
          setAlertStatusDialogOpen,
          setSelectedRow,
          setSelectedRowIndex,
          setUpdatingStatus,
          setBandwidthData,
          setLoadingBandwidthData,
          setShowSiteStats,
          cameraDropDownValue,
          setBandwidthLoadingError,
          setSearchParams,
          setNotesValue,
          setBackgroundFrame,
          setDetectedFrame,
          setNoAlertsDialogOpen,
          setClickedTab,
          navigate
        );
      }}
      disabled={false}
    >
      Apply Filters
    </button>
  );
};
