import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { LoadingData, UserFilters } from "./AnalyticsUtils/analyticsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { yyyymmddGenerator } from "./AnalyticsUtils/yyyymmddGenerator";
import { getFalsePositiveDataAnalyticsPage } from "./AnalyticsUtils/getFalsePositiveDataAnalyticsPage";
import { NavigateFunction } from "react-router-dom";
import { getPercentageMotionFilteredData } from "./AnalyticsUtils/getPercentageMotionFilteredData";

export const CameraDropDown = ({
  userFilters,
  setUserFilters,
  cameraOptions,
  setLoadingData,
  setFalsePostiveData,
  setPercetageOfMotionData,
  navigate,
  cameraDropDownValue,
  setCameraDropDownValue,
  setAveragePercentageOfMotion,
  setShowMoreVisible,
  setShowMoreOpen,
  loadingCameras,
}: {
  userFilters: UserFilters;
  setUserFilters: Dispatcher<any>;
  cameraOptions: any;
  setLoadingData: Dispatcher<LoadingData>;
  setFalsePostiveData: any;
  setPercetageOfMotionData: Dispatcher<any>;
  navigate: NavigateFunction;
  cameraDropDownValue: any;
  setCameraDropDownValue: Dispatcher<any[]>;
  setAveragePercentageOfMotion: Dispatcher<number>;
  setShowMoreVisible: Dispatcher<boolean>;
  setShowMoreOpen: Dispatcher<boolean>;
  loadingCameras: boolean;
}) => {
  const cameraIds = new Map();
  cameraOptions.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });

  return (
    <div key="camera_dropdown">
      <Autocomplete
        disablePortal
        multiple
        limitTags={1}
        value={cameraDropDownValue}
        // fullWidth={true}
        options={
          cameraOptions[0]
            ? cameraOptions.map((object: any) => {
                return object.camera_name;
              })
            : []
        }
        loading={loadingCameras}
        onChange={(event, value: string[]) => {
          if (value !== null) {
            const cameraIdsArray: string[] = [];
            const cameraValuesArray: string[] = [];
            value.forEach((element: string) => {
              cameraIdsArray.push(cameraIds.get(element));
              cameraValuesArray.push(element);
            });
            if (localStorage.filtersSavedClicked === "true") {
              localStorage.setItem(
                "cameraDropDownValues",
                JSON.stringify(cameraValuesArray)
              );
              localStorage.setItem(
                "defaultUserFilters",
                JSON.stringify({
                  parent_group: userFilters.parent_group,
                  site: userFilters.site,
                  camera: cameraIdsArray,
                  start_date: userFilters.start_date,
                  end_date: userFilters.end_date,
                })
              );
            }
            setCameraDropDownValue(cameraValuesArray);
            // setShowMoreVisible(false);
            // setShowMoreOpen(false);
            setUserFilters((previousState: any) => {
              return { ...previousState, camera: cameraIdsArray };
            });
            // setLoadingData((previousState) => {
            //   return {
            //     ...previousState,
            //     falsePositiveData: true,
            //     percetageOfMotionData: true,
            //   };
            // });
            // cameraIdsArray[0]
            //   ? getFalsePositiveDataAnalyticsPage(
            //       setFalsePostiveData,
            //       navigate,
            //       setLoadingData,
            //       yyyymmddGenerator(userFilters.start_date),
            //       yyyymmddGenerator(userFilters.end_date),
            //       userFilters.site,
            //       cameraIdsArray,
            //       userFilters.parent_group
            //     )
            //   : getFalsePositiveDataAnalyticsPage(
            //       setFalsePostiveData,
            //       navigate,
            //       setLoadingData,
            //       yyyymmddGenerator(userFilters.start_date),
            //       yyyymmddGenerator(userFilters.end_date),
            //       userFilters.site,
            //       [],
            //       userFilters.parent_group
            //     );
            // cameraIdsArray[0]
            //   ? getPercentageMotionFilteredData(
            //       setPercetageOfMotionData,
            //       setAveragePercentageOfMotion,
            //       navigate,
            //       setLoadingData,
            //       yyyymmddGenerator(userFilters.start_date),
            //       yyyymmddGenerator(userFilters.end_date),
            //       userFilters.site,
            //       cameraIdsArray,
            //       userFilters.parent_group
            //     )
            //   : getPercentageMotionFilteredData(
            //       setPercetageOfMotionData,
            //       setAveragePercentageOfMotion,
            //       navigate,
            //       setLoadingData,
            //       yyyymmddGenerator(userFilters.start_date),
            //       yyyymmddGenerator(userFilters.end_date),
            //       userFilters.site,
            //       [],
            //       userFilters.parent_group
            //     );
          }
        }}
        sx={{ width: 285 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cameras"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCameras ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
          />
        )}
      />
    </div>
  );
};
