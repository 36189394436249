import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import {
  AllAlertsData,
  LoadingData,
  ParentGroupsAlertData,
  UserFilters,
} from "./AnalyticsUtils/analyticsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getSitesByParentGroup } from "./AnalyticsUtils/getSitesByParentGroup";
import { NavigateFunction } from "react-router-dom";
import { getParentGroupsAlertData } from "./AnalyticsUtils/getParentGroupsAlertData";
import { yyyymmddGenerator } from "./AnalyticsUtils/yyyymmddGenerator";
import { getFalsePositiveDataAnalyticsPage } from "./AnalyticsUtils/getFalsePositiveDataAnalyticsPage";
import { getPercentageMotionFilteredData } from "./AnalyticsUtils/getPercentageMotionFilteredData";
import { getAllAlertsData } from "./AnalyticsUtils/getAllAlertsData";

export const ParentGroupsDropDown = ({
  userFilters,
  setUserFilters,
  navigate,
  setSiteOptions,
  setPageLoading,
  setParentGroupsAlertsData,
  setLoadingData,
  setFalsePostiveData,
  setPercetageOfMotionData,
  setSiteDropDownValue,
  setCameraDropDownValue,
  setAveragePercentageOfMotion,
  setAllAlertsData,
  setDisplayOnLeftBool,
  setAlertsGraphName,
  setVisibleArray,
  setConversionArray,
  setShowMoreVisible,
  setShowMoreOpen,
  setCameraOptions,
  setLoadingSites,
  parentAccounts,
  parentIds,
  parentNames,
  parentAccountsLoading,
  navBarCollapsed,
}: {
  userFilters: UserFilters;
  setUserFilters: Dispatcher<any>;
  navigate: NavigateFunction;
  setSiteOptions: Dispatcher<any>;
  setPageLoading: Dispatcher<boolean>;
  setParentGroupsAlertsData: Dispatcher<ParentGroupsAlertData[]>;
  setLoadingData: Dispatcher<LoadingData>;
  setFalsePostiveData: Dispatcher<any>;
  setPercetageOfMotionData: Dispatcher<any>;
  setSiteDropDownValue: Dispatcher<any[]>;
  setCameraDropDownValue: Dispatcher<any[]>;
  setAveragePercentageOfMotion: Dispatcher<number>;
  setAllAlertsData: Dispatcher<AllAlertsData[]>;
  setDisplayOnLeftBool: Dispatcher<boolean>;
  setAlertsGraphName: Dispatcher<string>;
  setVisibleArray: Dispatcher<any[]>;
  setConversionArray: any;
  setShowMoreVisible: Dispatcher<boolean>;
  setShowMoreOpen: Dispatcher<boolean>;
  setCameraOptions: Dispatcher<any>;
  setLoadingSites: Dispatcher<boolean>;
  parentAccounts: any;
  parentIds: any;
  parentNames: any;
  parentAccountsLoading: boolean;
  navBarCollapsed: boolean;
}) => {
  return (
    <div key="parent_groups_dropdown">
      <Autocomplete
        disablePortal
        options={
          parentAccounts[0]
            ? parentAccounts.map((object: any) => {
                return object.name;
              })
            : []
        }
        defaultValue={
          parentAccounts[0]
            ? localStorage.navigatedToAnalyticsFromSitesPage === "true" ||
              localStorage.filtersSavedClicked === "true"
              ? parentNames.get(Number(localStorage.analyticsParentGroupId))
              : userFilters.parent_group
            : ""
        }
        onChange={(event, value: string | null) => {
          setSiteDropDownValue([]);
          setCameraDropDownValue([]);
          localStorage.setItem("cameraDropDownValues", "[]");
          localStorage.setItem("siteDropDownValues", "[]");
          // setShowMoreVisible(false);
          // setShowMoreOpen(false);
          if (localStorage.filtersSavedClicked === "true") {
            localStorage.setItem(
              "defaultUserFilters",
              JSON.stringify({
                parent_group: parentIds.get(value),
                site: [],
                camera: [],
                start_date: userFilters.start_date,
                end_date: userFilters.end_date,
              })
            );
          }
          if (value !== null) {
            localStorage.setItem(
              "analyticsParentGroupId",
              parentIds.get(value)
            );
            // setPageLoading(true);
            getSitesByParentGroup(
              parentIds.get(value),
              navigate,
              setSiteOptions,
              setLoadingSites
            );
            // getParentGroupsAlertData(
            //   setParentGroupsAlertsData,
            //   parentIds.get(value),
            //   yyyymmddGenerator(userFilters.start_date),
            //   yyyymmddGenerator(userFilters.end_date),
            //   userFilters.site,
            //   navigate,
            //   setLoadingData,
            //   setDisplayOnLeftBool,
            //   setVisibleArray,
            //   setConversionArray,
            //   setShowMoreVisible,
            //   setShowMoreOpen,
            //   userFilters,
            //   setAlertsGraphName
            // );
            // getFalsePositiveDataAnalyticsPage(
            //   setFalsePostiveData,
            //   navigate,
            //   setLoadingData,
            //   yyyymmddGenerator(userFilters.start_date),
            //   yyyymmddGenerator(userFilters.end_date),
            //   [],
            //   [],
            //   parentIds.get(value)
            // );
            // getPercentageMotionFilteredData(
            //   setPercetageOfMotionData,
            //   setAveragePercentageOfMotion,
            //   navigate,
            //   setLoadingData,
            //   yyyymmddGenerator(userFilters.start_date),
            //   yyyymmddGenerator(userFilters.end_date),
            //   [],
            //   [],
            //   parentIds.get(value)
            // );
            // setLoadingData((previousState) => {
            //   return {
            //     ...previousState,
            //     parentGroupsData: true,
            //     falsePositiveData: true,
            //     percetageOfMotionData: true,
            //   };
            // });
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                parent_group: parentIds.get(value),
                site: [],
                camera: [],
              };
            });
          } else {
            setSiteDropDownValue([]);
            setSiteOptions([]);
            setCameraOptions([]);
            localStorage.setItem("analyticsParentGroupId", "");
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                parent_group: "",
                site: [],
                camera: [],
              };
            });
            // setAlertsGraphName("Parent Group");
            // setLoadingData((previousState) => {
            //   return {
            //     ...previousState,
            //     parentGroupsData: true,
            //     falsePositiveData: true,
            //     percetageOfMotionData: true,
            //   };
            // });
            // getAllAlertsData(
            //   setAllAlertsData,
            //   "",
            //   yyyymmddGenerator(userFilters.start_date),
            //   yyyymmddGenerator(userFilters.end_date),
            //   navigate,
            //   setLoadingData
            // );
            // getParentGroupsAlertData(
            //   setParentGroupsAlertsData,
            //   "",
            //   yyyymmddGenerator(userFilters.start_date),
            //   yyyymmddGenerator(userFilters.end_date),
            //   userFilters.site,
            //   navigate,
            //   setLoadingData,
            //   setDisplayOnLeftBool,
            //   setVisibleArray,
            //   setConversionArray,
            //   setShowMoreVisible,
            //   setShowMoreOpen,
            //   userFilters,
            //   setAlertsGraphName
            // );
            // getFalsePositiveDataAnalyticsPage(
            //   setFalsePostiveData,
            //   navigate,
            //   setLoadingData,
            //   yyyymmddGenerator(userFilters.start_date),
            //   yyyymmddGenerator(userFilters.end_date),
            //   [],
            //   [],
            //   userFilters.parent_group
            // );
            // getPercentageMotionFilteredData(
            //   setPercetageOfMotionData,
            //   setAveragePercentageOfMotion,
            //   navigate,
            //   setLoadingData,
            //   yyyymmddGenerator(userFilters.start_date),
            //   yyyymmddGenerator(userFilters.end_date),
            //   [],
            //   [],
            //   userFilters.parent_group
            // );
          }
        }}
        sx={navBarCollapsed ? { width: 275 } : { width: 200 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Parent Group"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {parentAccountsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
          />
        )}
      />
    </div>
  );
};
