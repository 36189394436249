import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { createErrorsOverviewData } from "./createErrorsOverviewData";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createCameraStatsData } from "./createCameraStatsData";
import { yyyymmddGenerator } from "../../Analytics/AnalyticsUtils/yyyymmddGenerator";
import {
  filterAllObjects,
  filterErrorObjects,
  filterPendingObjects,
  filterResolvedObjects,
  filterWarningObjects,
} from "./filteringFunctions";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { handleFilterByMetrics } from "../../CameraCheck/handleFilterByMetrics";
import { handleOpenAlertsDialogFromSearchParams } from "./handleOpenAlertsDialogFromSearchParams";
import { handleAddSeverityToHealthCheckSites } from "./handleAddSeverityToHealthCheckSites";

export const getCurrentTime = (): string => {
  const now: Date = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
  const day = now.getDate();
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const meridiem = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
  const formattedTime = `${formattedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}${meridiem}`;

  return `${formattedDate} ${formattedTime}`;
};

export const checkTimestamp = (startTimestamp: any) => {
  const currentTime = Date.now() / 1000; // Get the current time in Unix epoch format in seconds
  const timeDifference = currentTime - startTimestamp; // Calculate the time difference in seconds
  const hoursDifference = timeDifference / 3600; // Convert the time difference to hours
  return hoursDifference > 24;
};

export const convertToMilitary = (inputString: string, aMPMString: string) => {
  if (aMPMString === "PM") {
    return `${Number(inputString) + 12}`;
  }
  if (aMPMString === "AM" && inputString !== "12") {
    return inputString;
  }
  if (aMPMString === "AM" && inputString === "12") {
    return "00";
  }
};

export const getAllHMData = (
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: Dispatcher<boolean>,
  setUnhealthyCamsData: any,
  setAlertsData: any,
  setFectchingAllData: any,
  filters: any,
  setActiveTable: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setUpdatingStatus: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: Dispatcher<boolean>,
  cameraDropDownValue: string,
  setBandwidthLoadingError: Dispatcher<boolean>,
  setSearchParams: any,
  setNotesValue: Dispatcher<string>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setNoAlertsDialogOpen: Dispatcher<boolean>,
  setClickedTab: Dispatcher<string>,
  navigate: NavigateFunction
) => {
  setErrorsOverviewData({
    connectivity: 0,
    image_quality: 0,
    license_info: 0,
    motion_status: 0,
    recording_status: 0,
    scene_change: 0,
    stream_quality: 0,
  });

  setUnhealthyCamsData({
    unhealthy_cameras: 0,
    total_cameras: 0,
    errors: 0,
    warnings: 0,
    resolved: 0,
  });
  setCameraStatsData([]);

  setAlertsData({
    all: [],
    errors: [],
    warnings: [],
    pending: [],
    resolved: [],
  });

  const retryFunction = () => {
    getAllHMData(
      setErrorsOverviewData,
      setCameraStatsData,
      setLoadingCameraStatsData,
      setUnhealthyCamsData,
      setAlertsData,
      setFectchingAllData,
      filters,
      setActiveTable,
      setAlertStatusDialogOpen,
      setSelectedRow,
      setSelectedRowIndex,
      setUpdatingStatus,
      setBandwidthData,
      setLoadingBandwidthData,
      setShowSiteStats,
      cameraDropDownValue,
      setBandwidthLoadingError,
      setSearchParams,
      setNotesValue,
      setBackgroundFrame,
      setDetectedFrame,
      setNoAlertsDialogOpen,
      setClickedTab,
      navigate
    );
  };

  let tempShowSiteStats = true;

  setShowSiteStats(true);

  const AxiosUI = createApi(``);

  let searchQueryString = `?`;

  if (filters.start_date) {
    searchQueryString += `start_date=${yyyymmddGenerator(filters.start_date)}`;
  }

  if (filters.end_date) {
    filters.start_date
      ? (searchQueryString += `&end_date=${yyyymmddGenerator(
          filters.end_date
        )}`)
      : (searchQueryString += `end_date=${yyyymmddGenerator(
          filters.end_date
        )}`);
  }

  if (filters.parent_group) {
    filters.start_date || filters.end_date
      ? (searchQueryString += `&group=${filters.parent_group}`)
      : (searchQueryString += `group=${filters.parent_group}`);
    tempShowSiteStats = true;
  }

  if (filters.site) {
    filters.start_date || filters.end_date || filters.parent_group
      ? (searchQueryString += `&site=${filters.site}`)
      : (searchQueryString += `site=${filters.site}`);
    setShowSiteStats(false);
    tempShowSiteStats = false;
  }

  if (filters.camera) {
    filters.start_date ||
    filters.end_date ||
    filters.parent_group ||
    filters.site
      ? (searchQueryString += `&camera=${filters.camera}`)
      : (searchQueryString += `camera=${filters.camera}`);
    setShowSiteStats(false);
    tempShowSiteStats = false;
  }

  AxiosUI.get(`analytics/data_usage/${searchQueryString}&limit=20`).then(
    (response: any) => {
      const updatedData = response.data;
      for (const key in updatedData) {
        const updatedKey: any = updatedData[key];
        updatedKey.abreviated_name = `${updatedKey.display_name.slice(
          0,
          7
        )}...`;
        if (updatedKey.kbs) {
          const updatedKBS = Math.round(updatedKey.kbs / 10000) / 100;
          updatedKey.kbs = updatedKBS;
        }
      }
      setBandwidthData(updatedData);
      setLoadingBandwidthData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setLoadingBandwidthData(false);
        localStorage.setItem("bandwidthLoadingError", "true");
        setBandwidthLoadingError(true);
      });
    }
  );

  const functionalityAfterAllResolved = (
    resolvedOlderUnhealthyCamsData: any,
    cameraStatsData: any
  ) => {
    const healthCheckSitesSorted = handleFilterByMetrics(
      filters,
      resolvedOlderUnhealthyCamsData
    );

    healthCheckSitesSorted.sort((a: any, b: any) => {
      return (
        Number(
          `${b.formatted_start_date.slice(6, 10)}${b.formatted_start_date.slice(
            3,
            5
          )}${b.formatted_start_date.slice(0, 2)}${convertToMilitary(
            b.formatted_start_date.slice(11, 13),
            b.formatted_start_date.slice(19, 21)
          )}${b.formatted_start_date.slice(
            14,
            16
          )}${b.formatted_start_date.slice(17, 19)}`
        ) -
        Number(
          `${a.formatted_start_date.slice(6, 10)}${a.formatted_start_date.slice(
            3,
            5
          )}${a.formatted_start_date.slice(0, 2)}${convertToMilitary(
            a.formatted_start_date.slice(11, 13),
            a.formatted_start_date.slice(19, 21)
          )}${a.formatted_start_date.slice(
            14,
            16
          )}${a.formatted_start_date.slice(17, 19)}`
        )
      );
    });
    const filterDuplicatesAndUnhealthy = (arr: any[]) => {
      const uniqueCombinations: any = {};
      return arr.filter((obj) => {
        const key = obj.camera_id + obj.error_type + obj.created_timestamp;
        if (obj.status === "healthy") {
          return false;
        }
        if (uniqueCombinations[key]) {
          return false;
        } else {
          uniqueCombinations[key] = true;
          return true;
        }
      });
    };

    const sortedAndNonDuplictdHealthCheckSites = filterDuplicatesAndUnhealthy(
      healthCheckSitesSorted
    );

    const cameraFilteredData = sortedAndNonDuplictdHealthCheckSites.filter(
      (camera: any) => camera.camera_id === Number(filters.camera)
    );

    const newAlertsData = filters.camera
      ? {
          all: filterAllObjects(cameraFilteredData),
          errors: filterErrorObjects(cameraFilteredData),
          warnings: filterWarningObjects(cameraFilteredData),
          pending: filterPendingObjects(cameraFilteredData),
          resolved: filterResolvedObjects(cameraFilteredData),
        }
      : {
          all: filterAllObjects(sortedAndNonDuplictdHealthCheckSites),
          errors: filterErrorObjects(sortedAndNonDuplictdHealthCheckSites),
          warnings: filterWarningObjects(sortedAndNonDuplictdHealthCheckSites),
          pending: filterPendingObjects(sortedAndNonDuplictdHealthCheckSites),
          resolved: filterResolvedObjects(sortedAndNonDuplictdHealthCheckSites),
        };

    setAlertsData(newAlertsData);

    createErrorsOverviewData(newAlertsData, setErrorsOverviewData);

    createCameraStatsData(
      cameraStatsData,
      setCameraStatsData,
      setLoadingCameraStatsData,
      tempShowSiteStats,
      filters,
      newAlertsData.all
    );

    handleOpenAlertsDialogFromSearchParams(
      setSearchParams,
      setAlertStatusDialogOpen,
      newAlertsData,
      setSelectedRowIndex,
      setSelectedRow,
      setNotesValue,
      setBackgroundFrame,
      setDetectedFrame,
      setNoAlertsDialogOpen,
      setClickedTab,
      navigate
    );

    setFectchingAllData(false);
  };

  AxiosUI.get(`healthcheck_result/rollup/${searchQueryString}`).then(
    (response: AxiosResponse) => {
      setUnhealthyCamsData(response.data.unhealthy_cams);

      const cameraStatsData = response.data.camera_stats;

      const healthCheckSitesWithSeverity = handleAddSeverityToHealthCheckSites(
        response.data.healthcheck_sites
      );

      const resolvedOlderUnhealthyCamsData = [...healthCheckSitesWithSeverity];

      if (resolvedOlderUnhealthyCamsData[0]) {
        let finalIndex = 0;
        let totalFound = 0;
        resolvedOlderUnhealthyCamsData.forEach((alert: any, index) => {
          if (alert.status === "pending") {
            if (
              (alert.error_type === "connectivity" &&
                checkTimestamp(alert.start_timestamp)) ||
              (alert.error_type === "scene_change" &&
                checkTimestamp(alert.end_timestamp))
            ) {
              finalIndex = index;
              totalFound++;
            }
          }
        });
        if (totalFound === 0) {
          functionalityAfterAllResolved(
            resolvedOlderUnhealthyCamsData,
            cameraStatsData
          );
        } else {
          resolvedOlderUnhealthyCamsData.forEach(
            (alert: any, index: number) => {
              const newAlert = { ...alert };
              const previousAlert = { ...alert };
              if (alert.status) {
                if (alert.status === "pending") {
                  if (
                    (alert.error_type === "connectivity" &&
                      checkTimestamp(alert.start_timestamp)) ||
                    (alert.error_type === "scene_change" &&
                      checkTimestamp(alert.end_timestamp))
                  ) {
                    newAlert.status = "resolved";
                    // newAlert.formatted_end_date = getCurrentTime();
                    if (alert.notes) {
                      newAlert.notes =
                        alert.notes + " Resolved automatically after 24 hours.";
                    } else {
                      newAlert.notes = "Resolved automatically after 24 hours.";
                    }
                    AxiosUI.post(
                      `/healthcheck_result/update_healthcheck/?camera_id=${alert.camera_id}&created_timestamp=${alert.created_timestamp}&healthcheck_type=${alert.error_type}&status=${alert.status}`,
                      newAlert
                    ).then(
                      () => {
                        if (!newAlert.formatted_end_date) {
                          newAlert.formatted_end_date = getCurrentTime();
                        }
                        resolvedOlderUnhealthyCamsData[index] = newAlert;
                        if (index === finalIndex) {
                          functionalityAfterAllResolved(
                            resolvedOlderUnhealthyCamsData,
                            cameraStatsData
                          );
                        }
                      },
                      (reason: AxiosError) => {
                        // eslint-disable-next-line no-console
                        console.log("Reason: ", reason);
                        resolvedOlderUnhealthyCamsData[index] = previousAlert;
                        if (index === finalIndex) {
                          functionalityAfterAllResolved(
                            resolvedOlderUnhealthyCamsData,
                            cameraStatsData
                          );
                        }
                      }
                    );
                  }
                }
              }
            }
          );
        }
      } else {
        setAlertsData({
          all: [],
          errors: [],
          warnings: [],
          pending: [],
          resolved: [],
        });
        setFectchingAllData(false);
      }
    },
    (reason: AxiosError) => {
      if (reason.request.status === 500) {
        setLoadingCameraStatsData(false);
      } else {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setFectchingAllData(false);
          setLoadingCameraStatsData(false);
        });
      }
    }
  );
};
