/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import * as React from "react";
import { NavBar } from "../../common/NavBar";
import { IgnoreZonesBreadCrumbs } from "./IgnoreZonesBreadCrumbs";
import { createIgnoreZonesLinksArray } from "./EditIgnoreZonesUtils/createIgnoreZonesLinksArray";
import { useNavigate } from "react-router-dom";
import { pointsCreator } from "./EditIgnoreZonesUtils/pointsCreatorLarger";
import ZwibblerComponent from "./ZwibblerComponet";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { ActuateButton } from "../../common/ActuateButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { handleSubmitIgnoreZones } from "./EditIgnoreZonesUtils/handleSubmitIgnoreZones";
import * as LinesIcon from "../../../../assets/LinesIcon.png";
import * as CornerBoxes from "../../../../assets/CornerBoxes.png";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { getEditIgnoreZonesInfo } from "./EditIgnoreZonesUtils/getEditIgnoreZonesInfo";
import { pointsCreatorIgnoreZones } from "./EditIgnoreZonesUtils/pointCreatorIgnoreZones";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getAllCameras } from "../EditCamera/EditCameraUtils/getAllCameras";
import {
  getNumbersAfterEditCamera,
  getNumbersAfterSites,
} from "../EditCamera/EditCamera";
import { resetEditIgnoreZonesState } from "./EditIgnoreZonesUtils/resetEditIgnoreZonesState";
import { PopOutEditor } from "./PopOutEditor";

export const EditIgnoreZones = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [breadcrumbs, setBreadCrumbs] = React.useState([]);

  const [visibleIgnoreZones, setVisibleIgnoreZones] = React.useState([]);

  const [ignorezoneEditorVisible, setIgnorezoneEditorVisible] =
    React.useState(false);

  const [selectedIgnoreZone, setSelectedIgnoreZone] = React.useState("");

  const [editsMade, setEditsMade] = React.useState(false);

  const [pageLoading, setPageLoading] = React.useState(true);

  // const [dimensions, setDimensions] = React.useState({
  //   width: 640,
  //   height: 360,
  // });

  const [dimensions, setDimensions] = React.useState<{
    width: number;
    height: number;
  } | null>(null);

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;

    // setDimensions(resizeImage(naturalWidth, naturalHeight));
  };

  const navigate = useNavigate();

  const siteId = Number(getNumbersAfterSites(location.href));

  const handleSitesBreadcrumbClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    localStorage.setItem("level0Click", "false");
    localStorage.setItem("navigatedFromSitesAboutPage", "false");
    navigate("/sites");
  };

  const handleSingularSiteBreadcrumbClick = () => {
    navigate(`/sites/aboutsite/${siteId}`);
  };

  const handleIgnoreZoneClick = (object: any) => {
    setIgnorezoneEditorVisible(true);
    localStorage.setItem("ignoreZoneColor", object.line_color);
    localStorage.setItem("ignoreZones", JSON.stringify(object.coordinates));
  };

  const [allCamerasData, setAllCamerasData] = React.useState<any[]>([]);

  const [cameraIndex, setCameraIndex] = React.useState(0);

  const [popOutEditorDialogOpen, setPopOutEditorDialogOpen] =
    React.useState(false);

  React.useEffect(() => {
    getEditIgnoreZonesInfo(
      setPageLoading,
      setBreadCrumbs,
      setVisibleIgnoreZones,
      setDimensions
    );
    getAllCameras(
      Number(getNumbersAfterSites(location.href)),
      Number(getNumbersAfterEditCamera(location.href)),
      setAllCamerasData,
      setCameraIndex
    );
  }, [pageLoading]);

  // React.useEffect(() => {
  //   if (localStorage.ignoreZones) {
  //     setVisibleIgnoreZones(JSON.parse(localStorage.ignoreZones));
  //   }
  // }, []);

  const movedCoordinatesArrayCreator = (
    inputArray: any[],
    xShiftAmount: number,
    yShiftAmount: number
  ) => {
    const returnArray: any = [];
    inputArray.forEach((array: any[]) => {
      const pushedArray = [];
      if (dimensions) {
        pushedArray.push(
          (array[0] * dimensions.width + xShiftAmount) / dimensions.width
        );
        pushedArray.push(
          (array[1] * dimensions.height + yShiftAmount) / dimensions.height
        );
      }
      returnArray.push(pushedArray);
    });
    return returnArray;
  };

  return (
    <div>
      <BackdropLoadingScreen openState={pageLoading} />
      <PopOutEditor
        popOutEditorDialogOpen={popOutEditorDialogOpen}
        setPopOutEditorDialogOpen={setPopOutEditorDialogOpen}
      />
      <div className="bg-actuate-grey absolute w-full h-full overflow-scroll">
        {!pageLoading && allCamerasData[0] && (
          <div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[15px] left-[150px] right-[1px] h-[59px] bg-white"
                  : "absolute top-[15px] left-[75px] right-[1px] h-[59px] bg-white"
              }
            >
              <IgnoreZonesBreadCrumbs
                linksArray={createIgnoreZonesLinksArray(breadcrumbs)}
                handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
                handleSingularSiteBreadcrumbClick={
                  handleSingularSiteBreadcrumbClick
                }
                styling="absolute top-[15px] left-[30px]"
              />
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[87px] left-[150px] right-[1px] h-[58px] bg-white"
                  : "absolute top-[87px] left-[75px] right-[1px] h-[58px] bg-white"
              }
            >
              <p className="font-black font-mulish text-[20px] p-3 indent-4">
                {allCamerasData[cameraIndex].camera_name}
              </p>
            </div>
            <div className="absolute top-[97px] right-[250px]">
              {ignorezoneEditorVisible && (
                <OrangeTextButton
                  onClick={() => {
                    setIgnorezoneEditorVisible(false);
                  }}
                  text="Cancel"
                  disabled={false}
                />
              )}
            </div>
            <div className="absolute top-[97px] right-[100px]">
              {ignorezoneEditorVisible && (
                <ActuateButton
                  onClick={() => {
                    setIgnorezoneEditorVisible(false);
                    setEditsMade(true);
                    const updatedVisibleIgnoreZones: any = [];
                    const shapesObject = JSON.parse(localStorage.shapesObject);
                    const shiftedObj = JSON.parse(localStorage.shiftedObj);
                    visibleIgnoreZones.forEach((object: any) => {
                      if (object.label === selectedIgnoreZone && shapesObject) {
                        const updatedCoordinates: any = [];
                        for (const key in shapesObject) {
                          if (`${key}` in shiftedObj) {
                            shapesObject[key] = movedCoordinatesArrayCreator(
                              shapesObject[key],
                              shiftedObj[key][0],
                              shiftedObj[key][1]
                            );
                          }
                        }
                        for (const key in shapesObject) {
                          updatedCoordinates.push(shapesObject[key]);
                        }
                        object.coordinates = updatedCoordinates;
                        updatedVisibleIgnoreZones.push(object);
                      } else if (
                        object.label !== selectedIgnoreZone &&
                        shapesObject
                      ) {
                        updatedVisibleIgnoreZones.push(object);
                      }
                    });

                    setVisibleIgnoreZones(updatedVisibleIgnoreZones);
                  }}
                  text="Save Edits"
                  disabled={false}
                />
              )}
            </div>
            <div className="absolute top-[97px] right-[100px]">
              {!ignorezoneEditorVisible && (
                <OrangeTextButton
                  onClick={() => {
                    handleSubmitIgnoreZones(
                      visibleIgnoreZones,
                      navigate,
                      setPageLoading
                    );
                  }}
                  text="Submit Changes"
                  disabled={!editsMade}
                />
              )}
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[145px] left-[150px] right-[1px] h-[710px] bg-white"
                  : "absolute top-[145px] left-[75px] right-[1px] h-[710px] bg-white"
              }
            >
              {!ignorezoneEditorVisible && allCamerasData.length > 1 && (
                <div className="pt-[200px] w-[40px]">
                  <Tooltip
                    title={`Previous Camera: ${
                      cameraIndex === 0
                        ? allCamerasData[allCamerasData.length - 1].camera_name
                        : allCamerasData[cameraIndex - 1].camera_name
                    }`}
                    placement="top"
                    sx={{ fontSize: "12px", fontFamily: "mulish" }}
                  >
                    <div>
                      <OrangeTextButton
                        onClick={() => {
                          resetEditIgnoreZonesState(
                            setBreadCrumbs,
                            setVisibleIgnoreZones,
                            setIgnorezoneEditorVisible,
                            setSelectedIgnoreZone,
                            setEditsMade,
                            setPageLoading,
                            setDimensions,
                            setAllCamerasData,
                            setCameraIndex
                          );
                          if (cameraIndex === 0) {
                            navigate(
                              `/sites/${siteId}/editcamera/${
                                allCamerasData[allCamerasData.length - 1].id
                              }/editignorezones`
                            );
                          } else {
                            navigate(
                              `/sites/${siteId}/editcamera/${
                                allCamerasData[cameraIndex - 1].id
                              }/editignorezones`
                            );
                          }
                        }}
                        text={
                          <ArrowBackIosIcon fontSize="large" id="backButton" />
                        }
                        disabled={allCamerasData.length < 2}
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
              {!ignorezoneEditorVisible && allCamerasData.length > 1 && (
                <div className="absolute top-[200px] left-[1250px]">
                  <Tooltip
                    title={`Next Camera: ${
                      cameraIndex + 1 === allCamerasData.length
                        ? allCamerasData[0].camera_name
                        : allCamerasData[cameraIndex + 1].camera_name
                    }`}
                    placement="top"
                    sx={{ fontSize: "12px", fontFamily: "mulish" }}
                  >
                    <div>
                      <OrangeTextButton
                        onClick={() => {
                          resetEditIgnoreZonesState(
                            setBreadCrumbs,
                            setVisibleIgnoreZones,
                            setIgnorezoneEditorVisible,
                            setSelectedIgnoreZone,
                            setEditsMade,
                            setPageLoading,
                            setDimensions,
                            setAllCamerasData,
                            setCameraIndex
                          );
                          if (cameraIndex + 1 === allCamerasData.length) {
                            navigate(
                              `/sites/${siteId}/editcamera/${allCamerasData[0].id}/editignorezones`
                            );
                          } else {
                            navigate(
                              `/sites/${siteId}/editcamera/${
                                allCamerasData[cameraIndex + 1].id
                              }/editignorezones`
                            );
                          }
                        }}
                        text={
                          <ArrowForwardIosIcon
                            fontSize="large"
                            id="backButton"
                          />
                        }
                        disabled={allCamerasData.length < 2}
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
              {!ignorezoneEditorVisible && (
                <div>
                  <div className="absolute top-[60px] left-[925px] font-mulish text-[20px] text-actuate-blue">
                    Ignore Zones
                  </div>

                  <div className="overflow-auto absolute left-[925px] top-[110px] min-h-[360px] w-[305px]">
                    <div className="absolute left-[0px] top-[0px] w-[275px]">
                      {visibleIgnoreZones.map((object: any) => {
                        return (
                          <div
                            key={object.label}
                            role="button"
                            onClick={() => {
                              setSelectedIgnoreZone(object.label);
                              handleIgnoreZoneClick(object);
                            }}
                          >
                            <div
                              key={object.label}
                              style={{
                                height: 25,
                                width: 6,
                                background: `${object.line_color}`,
                              }}
                              className="absolute"
                            ></div>
                            <p className="font-mulish text-[16px] indent-5">
                              {object.label_display_name}
                            </p>
                            &nbsp;
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {!pageLoading && (
                <div className="absolute top-[180px] left-[152px] w-[768px] h-[432px]">
                  <img
                    src={JSON.parse(localStorage.preview_url)}
                    style={{
                      width: dimensions ? `${dimensions.width}px` : "auto",
                      height: dimensions ? `${dimensions.height}px` : "auto",
                    }}
                    onLoad={handleImageLoad}
                    alt="TestCameraPhoto"
                  />
                  {!ignorezoneEditorVisible &&
                    visibleIgnoreZones.map((object: any) => {
                      return (
                        <div className="absolute top-[0px]" key={object.label}>
                          {object.coordinates[0] &&
                            object.coordinates.map((array: any, index: any) => {
                              return (
                                <svg
                                  className="absolute top-[0px]"
                                  key={index}
                                  style={{
                                    width: dimensions
                                      ? `${dimensions.width}px`
                                      : "auto",
                                    height: dimensions
                                      ? `${dimensions.height}px`
                                      : "auto",
                                  }}
                                >
                                  {dimensions && (
                                    <polygon
                                      points={pointsCreator(
                                        object.coordinates[index],
                                        dimensions.height,
                                        dimensions.width
                                      )}
                                      stroke={object.line_color}
                                      strokeWidth="5"
                                      fill={object.line_color}
                                      fillOpacity="50%"
                                    />
                                  )}
                                </svg>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              )}

              <div
                className="player absolute top-[5px] left-[20px] w-[775px] h-[200px]"
                role="button"
                tabIndex={0}
              >
                {ignorezoneEditorVisible && <ZwibblerComponent />}
              </div>
              <div className="absolute top-[7px] left-[150px] right-[1px] h-[170px] w-[640px] bg-white"></div>
            </div>
            {!ignorezoneEditorVisible && (
              <div
                className={
                  !navBarCollapsed
                    ? "absolute top-[167px] left-[980px] w-[420px] font-mulish text-[18px] text-actuate-blue"
                    : "absolute top-[167px] left-[905px] w-[420px] font-mulish text-[18px] text-actuate-blue"
                }
              >
                <InfoOutlinedIcon />
                &nbsp; Click the Ignore Zone below you&apos;d like to edit.
              </div>
            )}
            {ignorezoneEditorVisible && (
              <div>
                <div
                  className={
                    !navBarCollapsed
                      ? "absolute top-[157px] left-[300px] w-[720px] font-mulish text-[15px] text-actuate-blue"
                      : "absolute top-[157px] left-[225px] w-[720px] font-mulish text-[15px] text-actuate-blue"
                  }
                >
                  Our models will not send alerts for any detections made within
                  the drawn ignore zones.
                </div>
                <div
                  className={
                    !navBarCollapsed
                      ? "absolute top-[177px] left-[300px] w-[720px] font-mulish text-[15px] text-actuate-blue"
                      : "absolute top-[177px] left-[225px] w-[720px] font-mulish text-[15px] text-actuate-blue"
                  }
                >
                  Ensure that detections do not need to be made within the
                  highlighted areas.
                </div>
              </div>
            )}
            {ignorezoneEditorVisible && (
              <div
                className={
                  !navBarCollapsed
                    ? "absolute top-[172px] left-[980px] font-mulish text-[20px] text-actuate-blue min-w-[300px]"
                    : "absolute top-[172px] left-[905px] font-mulish text-[20px] text-actuate-blue min-w-[300px]"
                }
              >
                <p className="font-bold text-[17px]">TO CREATE A SHAPE:</p>
                <p className="font-normal text-[17px] ">
                  Click the Lines Icon{" "}
                  <img
                    className="w-10 inline"
                    src={LinesIcon}
                    alt="lines_icon"
                  />{" "}
                  and use <strong>single clicks </strong>
                  to build the shape.{" "}
                  <strong>Do not hold mouse and drag to create lines.</strong>
                </p>
                <br></br>
                <p className="font-normal text-[17px]">
                  Final click <strong>must be</strong> in the same spot as the
                  first click to create shape.
                </p>
                <br></br>
                <p className="font-bold text-[17px]">TO MODIFY A SHAPE:</p>
                <p className="font-normal text-[17px]">
                  Click on the shape until square points appear on each corner:{" "}
                  <img className="w-44" src={CornerBoxes} alt="corner_boxes" />
                </p>
                <p className="font-normal text-[17px]">
                  Drag the points to modify.
                </p>
                <br></br>
                <p className="font-bold text-[17px]">TO DELETE A SHAPE:</p>
                <p className="font-normal text-[17px]">
                  Click on the shape, then press delete on keyboard.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
