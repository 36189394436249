import { createApi } from "../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { fetchAddCameraInfo } from "../Sites/SitesPage/SitesPageUtils/fetchAddCameraInfo";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";

export const handleNavigateToSitesPage = async (navigate: any) => {
  const AxiosUI = createApi(``);
  localStorage.setItem("clickedId", JSON.stringify(localStorage.siteId));
  const retryFunction = () => {
    handleNavigateToSitesPage(navigate);
  };
  let getSiteInfoFail = false;
  let getSiteCamerasFail = false;

  await fetchAddCameraInfo(localStorage.siteId, navigate);
  await AxiosUI.get(`customer/${localStorage.siteId}/about/`).then(
    (response: AxiosResponse) => {
      getSiteInfoFail = false;
      localStorage.setItem("siteInfo", JSON.stringify(response.data));
    },
    (reason: AxiosError) => {
      getSiteInfoFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
  !getSiteInfoFail &&
    (await AxiosUI.get(`camera/site/?customer__id=${localStorage.siteId}`).then(
      (response: AxiosResponse) => {
        getSiteCamerasFail = false;
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
      },
      (reason: AxiosError) => {
        getSiteCamerasFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    ));
  !getSiteInfoFail &&
    !getSiteCamerasFail &&
    (await AxiosUI.get(`schedule/by_customer/${localStorage.siteId}/`).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteSchedule", JSON.stringify(response.data));
        navigate(`/sites/aboutsite/${localStorage.siteId}`);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert(`${reason}`);
        });
      }
    ));
};
