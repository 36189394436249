import React from "react";

export const handleProductMasterSwitchEvent = (
  productIndex: number,
  setLocalProductsData: any,
  setProductsData: any,
  setPatchObject: any,
  event: any
) => {
  setLocalProductsData((previousState: any) => {
    const updatedLocalProductsData = [...previousState];
    // update the local products data by using a for in loop to iterate through the metrics object
    for (const key in updatedLocalProductsData[productIndex].metrics) {
      updatedLocalProductsData[productIndex].metrics[key].active =
        event.target.checked;
    }
    updatedLocalProductsData[productIndex].open = true;
    return updatedLocalProductsData;
  });
  setProductsData((previousState: any) => {
    const updatedProductsData = [...previousState];
    for (const key in updatedProductsData[productIndex].metrics) {
      updatedProductsData[productIndex].metrics[key].active =
        event.target.checked;
    }
    const streamMetricsPatchArray: any[] = [];

    updatedProductsData.forEach((product: any) => {
      const metrics: any = product.metrics;
      for (const key in metrics) {
        streamMetricsPatchArray.push(metrics[key]);
      }
    });
    // set the patch object at streams at stream_metrics to streamMetricsPatchArray
    setPatchObject((previousState: any) => {
      const newPatchObject = { ...previousState };
      newPatchObject.streams[0].stream_metrics = streamMetricsPatchArray;
      return newPatchObject;
    });
    updatedProductsData[productIndex].open = true;
    return updatedProductsData;
  });
};
