import React from "react";

export const handleUpdateSiteSchedules = (
  timeRanges: any,
  duskToDawnLocation: any,
  matchingSchedules: any,
  setAllSiteSchedules: any
) => {
  const returnArray: any[] = [];

  matchingSchedules.forEach((array: any) => {
    const alwaysOn = timeRanges[array[0]] === "Always On";
    const duskToDawn = duskToDawnLocation[array[0]][0];
    const bufferTime = duskToDawnLocation[array[0]][1];
    const endTime =
      timeRanges[array[0]] === "Always On" ||
      timeRanges[array[0]] === "Dusk to Dawn"
        ? "23:59:59"
        : `${timeRanges[array[0]].slice(8, 13)}:00`;
    const startTime =
      timeRanges[array[0]] === "Always On" ||
      timeRanges[array[0]] === "Dusk to Dawn"
        ? "00:00:00"
        : `${timeRanges[array[0]].slice(0, 5)}:00`;

    returnArray.push({
      always_on: alwaysOn,
      buffer_time: bufferTime ? bufferTime : 0,
      customer: localStorage.siteId,
      day_of_week: array,
      enabled: true,
      end_time: endTime === "24:00:00" ? "23:59:59" : endTime,
      is_overide: false,
      location_dusk_dawn: duskToDawn,
      start_time: startTime,
    });
  });
  setAllSiteSchedules(returnArray);
};
