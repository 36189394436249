import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../../common/TableHead";

export const WebhooksTab = ({ webhooksInfo }: { webhooksInfo: any }) => {
  const titlesArray = [
    "Display Name",
    "Webhook Type",
    "Target URL",
    "Message Body",
  ];

  return (
    <TableContainer component={Paper} sx={{ width: "screen" }}>
      {webhooksInfo.length > 0 && (
        <Table
          sx={{
            postion: "absolute",
            width: "screen",
            fontFamily: "Mulish",
          }}
        >
          <TableHeadComponent titlesArray={titlesArray} />

          <TableBody>
            <TableRow>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {webhooksInfo[0].display_name}
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {webhooksInfo[0].webhook_type.charAt(0).toUpperCase() +
                  webhooksInfo[0].webhook_type.slice(1)}
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {webhooksInfo[0].target_url}
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {webhooksInfo[0].message_body}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {webhooksInfo.length === 0 && (
        <p className="font-mulish text-[#283E6D] text-[20px] text-center">
          No Webhooks info added
        </p>
      )}
    </TableContainer>
  );
};
