// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxisTab } from "../AxisTab";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleDeleteAxisActionRule = (
  cameraId: string,
  ruleId: string,
  navigate: NavigateFunction,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setActionRulesData: any,
  setActionRulesTitlesArray: Dispatcher<any>,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  setActiveTab: any,
  recipientsConfiguredData: any,
  titlesArray: any,
  actionRulesData: any,
  actionRulesTitlesArray: any,
  applicationsConfiguredData: any,
  cameraGeneralInfo: any,
  axisFinished: any
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleDeleteAxisActionRule(
      cameraId,
      ruleId,
      navigate,
      setBackdropLoadingScreenOn,
      setActionRulesData,
      setActionRulesTitlesArray,
      setRecipientsConfiguredData,
      setTitlesArray,
      setActiveTab,
      recipientsConfiguredData,
      titlesArray,
      actionRulesData,
      actionRulesTitlesArray,
      applicationsConfiguredData,
      cameraGeneralInfo,
      axisFinished
    );
  };

  AxiosUI.delete(`/axis/${cameraId}/action_rule/${ruleId}/`).then(
    (response: any) => {
      setActionRulesData(response.data);
      const newActionRulesTitlesArray = ["Name"];
      response.data.forEach((object: any) => {
        object.additional_fields.forEach((obj: any) => {
          if (
            obj.id === "host" &&
            !newActionRulesTitlesArray.includes("Host")
          ) {
            newActionRulesTitlesArray.push("Host");
          }
          if (
            obj.id === "port" &&
            !newActionRulesTitlesArray.includes("Port")
          ) {
            newActionRulesTitlesArray.push("Port");
          }
          if (
            obj.id === "email_to" &&
            !newActionRulesTitlesArray.includes("E-mail to")
          ) {
            newActionRulesTitlesArray.push("E-mail to");
          }
          if (
            obj.id === "email_from" &&
            !newActionRulesTitlesArray.includes("E-mail from")
          ) {
            newActionRulesTitlesArray.push("E-mail from");
          }
          if (
            obj.id === "message" &&
            !newActionRulesTitlesArray.includes("Message")
          ) {
            newActionRulesTitlesArray.push("Message");
          }
          if (
            obj.id === "enabled" &&
            !newActionRulesTitlesArray.includes("Enabled")
          ) {
            newActionRulesTitlesArray.push("Enabled");
          }
          if (
            obj.id === "profile_selected" &&
            !newActionRulesTitlesArray.includes("Profile")
          ) {
            newActionRulesTitlesArray.push("Profile");
          }
        });
      });
      const orderedFinalTitles = ["Name"];
      if (!orderedFinalTitles.includes("Host")) {
        orderedFinalTitles.push("Host");
      }
      if (!orderedFinalTitles.includes("Port")) {
        orderedFinalTitles.push("Port");
      }
      if (!orderedFinalTitles.includes("E-mail to")) {
        orderedFinalTitles.push("E-mail to");
      }
      if (!orderedFinalTitles.includes("E-mail from")) {
        orderedFinalTitles.push("E-mail from");
      }
      if (!orderedFinalTitles.includes("Message")) {
        orderedFinalTitles.push("Message");
      }
      if (!orderedFinalTitles.includes("Enabled")) {
        orderedFinalTitles.push("Enabled");
      }
      if (!orderedFinalTitles.includes("Profile")) {
        orderedFinalTitles.push("Profile");
      }
      orderedFinalTitles.push("");
      setActionRulesTitlesArray(orderedFinalTitles);
      setBackdropLoadingScreenOn(false);
      setActiveTab(<></>);
      setActiveTab(
        <AxisTab
          recipientsConfiguredData={response.data}
          titlesArray={titlesArray}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setRecipientsConfiguredData={setRecipientsConfiguredData}
          setTitlesArray={setTitlesArray}
          actionRulesData={response.data}
          setActionRulesData={setActionRulesData}
          actionRulesTitlesArray={orderedFinalTitles}
          setActionRulesTitlesArray={setActionRulesTitlesArray}
          setActiveTab={setActiveTab}
          applicationsConfiguredData={applicationsConfiguredData}
          cameraGeneralInfo={cameraGeneralInfo}
          axisFinished={axisFinished}
        />
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        alert("Problem deleting action rule, please try again");
      });
    }
  );
};
