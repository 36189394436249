import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { handleSubmitNewGroup } from "./SitesPageUtils/handleSubmitNewGroup";

export const AddGroupDialog = ({
  addGroupDialogOpen,
  setAddGroupDialogOpen,
  setGroupTrees,
  setBackdropLoadingScreenOn,
  setGroupAddedAlert,
}: {
  addGroupDialogOpen: boolean;
  setAddGroupDialogOpen: any;
  setGroupTrees: any;
  setBackdropLoadingScreenOn: any;
  setGroupAddedAlert: any;
}) => {
  const navigate = useNavigate();

  const [postObject, setPostObject] = React.useState({
    name: "",
    parent: "",
    parent_account: true,
  });

  const options = JSON.parse(localStorage.parentGroups);

  const optionsIds = new Map();
  const optionNames = new Map();

  options.forEach((option: any) => {
    optionsIds.set(option.id, `${option.name} (group id: ${option.id})`);
  });

  options.forEach((option: any) => {
    optionNames.set(`${option.name} (group id: ${option.id})`, option.id);
  });

  const handleGroupNameChange = (event: any) => {
    setPostObject((previousState: any) => {
      return { ...previousState, name: event.target.value };
    });
  };

  return (
    <Dialog
      open={addGroupDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Group</DialogTitle>
      <DialogContent>
        <TextField
          id="group_name"
          variant="standard"
          label="Group Name"
          onChange={handleGroupNameChange}
          sx={{ width: "400px" }}
          helperText="What would you like to name this new group?"
          type="string"
        />
        <br></br>
        <br></br>

        <Autocomplete
          disablePortal
          id="parent_group"
          options={options.map((option: any) => {
            return `${option.name} (group id: ${option.id})`;
          })}
          onChange={(event: any, newInputValue: any) => {
            setPostObject((previousState: any) => {
              return {
                ...previousState,
                parent: optionNames.get(newInputValue),
              };
            });
          }}
          sx={{ width: 400 }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="standard"
              label="Parent Group"
              helperText="What parent group should this group be under?"
            />
          )}
        />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddGroupDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            handleSubmitNewGroup(
              postObject,
              navigate,
              setGroupTrees,
              setBackdropLoadingScreenOn,
              setGroupAddedAlert
            );
            setAddGroupDialogOpen(false);
          }}
          text="Submit"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
