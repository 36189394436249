export const handleSureviewSubjectInput = (
  camerasObject: any,
  setCamerasObject: any,
  inputString: string
) => {
  const newCamerasObject = camerasObject;

  for (const key in newCamerasObject) {
    newCamerasObject[key].sureview.sureview_subject = inputString;
  }

  setCamerasObject(newCamerasObject);
};
