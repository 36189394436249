import React from "react";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const disabledChecker = (labelName: string, selectedGroups: any) => {
  if (
    labelName === "intruder" ||
    labelName === "vehicle" ||
    labelName === "bike"
  ) {
    return selectedGroups.intruder.disabled;
  } else if (
    labelName === "intruder_plus" ||
    labelName === "vehicle_plus" ||
    labelName === "bike_plus"
  ) {
    return selectedGroups.intruderPlus.disabled;
  } else return false;
};

export const switchCreator = (
  object: any,
  selectedGroups: any,
  groupLabelName: string,
  setSelectedGroups: any,
  setLocalProductsData: any,
  setProductsData: any,
  setPatchObject: any,
  setGeneralInfoChangesMade: Dispatcher<boolean>
) => {
  return (
    <Switch
      checked={selectedGroups[groupLabelName][object.label_name]}
      color="warning"
      disabled={disabledChecker(object.label_name, selectedGroups)}
      onChange={(event: any) => {
        // update productsData and localProductsData with the new active state
        setLocalProductsData((previousState: any) => {
          //find the index of the product from the previous state whos label_name matches labelName
          const updatedProducts = [...previousState];
          const productIndex = updatedProducts.findIndex(
            (product: any) => product.label_name === groupLabelName
          );
          const updatedProduct = updatedProducts[productIndex];
          updatedProduct.metrics[object.label_name].active =
            event.target.checked;
          updatedProducts[productIndex] = updatedProduct;
          return updatedProducts;
        });
        setProductsData((previousState: any) => {
          const updatedProducts = [...previousState];
          const productIndex = updatedProducts.findIndex(
            (product: any) => product.label_name === groupLabelName
          );
          const updatedProduct = updatedProducts[productIndex];
          updatedProduct.metrics[object.label_name].active =
            event.target.checked;
          updatedProducts[productIndex] = updatedProduct;

          const streamMetricsPatchArray: any[] = [];

          updatedProducts.forEach((product: any) => {
            const metrics: any = product.metrics;
            for (const key in metrics) {
              streamMetricsPatchArray.push(metrics[key]);
            }
          });

          setPatchObject((previousState: any) => {
            const newPatchObject = { ...previousState };
            newPatchObject.streams[0].stream_metrics = streamMetricsPatchArray;
            return newPatchObject;
          });

          return updatedProducts;
        });
        setGeneralInfoChangesMade(true);
        if (event.target.checked) {
          setSelectedGroups((previousState: any) => {
            const updatedState = { ...previousState };
            const updatedMetric = updatedState[groupLabelName];
            updatedMetric[object.label_name] = event.target.checked;
            updatedMetric.selected = event.target.checked;
            if (groupLabelName === "intruder") {
              if (
                !updatedState.intruderPlus.intruder_plus &&
                !updatedState.intruderPlus.intruder_plus &&
                !updatedState.intruderPlus.vehicle_plus
              ) {
                updatedState.intruderPlus.disabled = true;
              }
            }
            if (groupLabelName === "intruderPlus") {
              if (
                !updatedState.intruder.bike &&
                !updatedState.intruder.vehicle &&
                !updatedState.intruder.intruder
              ) {
                updatedState.intruder.disabled = true;
              }
            }
            updatedState[groupLabelName] = updatedMetric;
            return updatedState;
          });
        }
        if (!event.target.checked) {
          setSelectedGroups((previousState: any) => {
            const updatedState = { ...previousState };
            const updatedMetric = updatedState[groupLabelName];
            let anyMetricTrue = false;
            for (const key in updatedMetric) {
              if (
                updatedMetric[key] &&
                key !== object.label_name &&
                key !== "selected" &&
                key !== "disabled"
              ) {
                anyMetricTrue = true;
              }
            }
            if (!anyMetricTrue) {
              updatedMetric.selected = false;
            }
            if (groupLabelName === "intruder" && !anyMetricTrue) {
              updatedState.intruderPlus.disabled = false;
            }
            if (groupLabelName === "intruderPlus" && !anyMetricTrue) {
              updatedState.intruder.disabled = false;
            }
            updatedMetric[object.label_name] = event.target.checked;
            updatedState[groupLabelName] = updatedMetric;
            return updatedState;
          });
        }

        // if (event.target.checked) {

        // }
        // if (event.target.checked) {
        //   if (
        //     object.label_name !== "vehicle_plus" &&
        //     object.label_name !== "bike_plus"
        //   ) {
        //     setProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[productIndex].active = true;
        //       return updatedProducts;
        //     });
        //     setLocalProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[productIndex].active = true;
        //       return updatedProducts;
        //     });
        //     switchDisabler(
        //       productsData[productIndex].metrics[productMetricsIndex]
        //         .display_name,
        //       event.target.checked,
        //       intruderDisabled,
        //       intruderPlusDisabled,
        //       setIntruderDisabled,
        //       setIntruderPlusDisabled,
        //       disabledProducts,
        //       setDisabledProducts
        //     );
        //   } else {
        //     setProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[1].active = true;
        //       return updatedProducts;
        //     });
        //     setLocalProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[1].active = true;
        //       return updatedProducts;
        //     });
        //     switchDisabler(
        //       productsData[1].metrics[productMetricsIndex].display_name,
        //       event.target.checked,
        //       intruderDisabled,
        //       intruderPlusDisabled,
        //       setIntruderDisabled,
        //       setIntruderPlusDisabled,
        //       disabledProducts,
        //       setDisabledProducts
        //     );
        //   }
        // }
        // if (!event.target.checked) {
        //   let anyMetricTrue = false;
        //   productsData[productIndex].metrics.forEach((metric: any) => {
        //     if (
        //       metric.active &&
        //       metric.label_name !==
        //         productsData[productIndex].metrics[productMetricsIndex]
        //           .label_name
        //     ) {
        //       anyMetricTrue = true;
        //     }
        //   });
        //   if (!anyMetricTrue) {
        //     setProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[productIndex].active = false;
        //       return updatedProducts;
        //     });
        //     setLocalProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[productIndex].active = false;
        //       return updatedProducts;
        //     });
        //   }
        //   switchDisabler(
        //     productsData[productIndex].metrics[productMetricsIndex]
        //       .display_name,
        //     event.target.checked,
        //     intruderDisabled,
        //     intruderPlusDisabled,
        //     setIntruderDisabled,
        //     setIntruderPlusDisabled,
        //     disabledProducts,
        //     setDisabledProducts
        //   );
        // }
        // if (
        //   object.label_name !== "vehicle_plus" &&
        //   object.label_name !== "bike_plus"
        // ) {
        //   setPatchObject((previousState: any) => {
        //     return {
        //       ...previousState,
        //       streams: [
        //         handlePatchActiveProducts(event.target.checked, metricIndex),
        //       ],
        //     };
        //   });
        //   setLocalProductsData((previousState: any) => {
        //     const updatedProducts = [...previousState];
        //     updatedProducts[productIndex].metrics[productMetricsIndex].active =
        //       event.target.checked;
        //     return updatedProducts;
        //   });
        //   setProductsData((previousState: any) => {
        //     const updatedProducts = [...previousState];
        //     updatedProducts[productIndex].metrics[productMetricsIndex].active =
        //       event.target.checked;
        //     return updatedProducts;
        //   });
        // } else if (object.label_name === "vehicle_plus") {
        //   console.log("selectedProducts", selectedProducts);
        //   setPatchObject((previousState: any) => {
        //     return {
        //       ...previousState,
        //       streams: [
        //         handlePatchActiveProducts(event.target.checked, metricIndex),
        //       ],
        //     };
        //   });
        //   if (!selectedProducts.intruder_plus && !selectedProducts.bike_plus) {
        //     setLocalProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[1].active = event.target.checked;
        //       return updatedProducts;
        //     });
        //     setProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[1].active = event.target.checked;
        //       return updatedProducts;
        //     });
        //   }
        // } else if (object.label_name === "bike_plus") {
        //   setPatchObject((previousState: any) => {
        //     return {
        //       ...previousState,
        //       streams: [
        //         handlePatchActiveProducts(event.target.checked, metricIndex),
        //       ],
        //     };
        //   });
        //   if (
        //     !selectedProducts.intruder_plus &&
        //     !selectedProducts.vehicle_plus
        //   ) {
        //     setLocalProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[1].active = event.target.checked;
        //       return updatedProducts;
        //     });
        //     setProductsData((previousState: any) => {
        //       const updatedProducts = [...previousState];
        //       updatedProducts[1].active = event.target.checked;
        //       return updatedProducts;
        //     });
        //   }
        // }

        // setGeneralInfoChangesMade(true);
      }}
    />
  );
};
