export const epochToUPCCovertor = (epochNum: number, timeZone: string) => {
  const deployedEpoch = epochNum;
  const date = new Date(0);
  date.setUTCSeconds(deployedEpoch);

  const options: any = timeZone
    ? {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone,
      }
    : {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

  // Format the date with locale options
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  // Remove the "at" and the time zone abbreviation if present
  const cleanDate = formattedDate.replace(/,? at /, " ");

  return cleanDate;
};
