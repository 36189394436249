import { Alert } from "@mui/material";
import React from "react";
import { AlertState } from "./AnalyticsUtils/analyticsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const AnalyticsAlerts = ({
  alertState,
  setAlertState,
}: {
  alertState: AlertState;
  setAlertState: Dispatcher<AlertState>;
}) => {
  return (
    <div>
      {alertState.dateTooFar && (
        <Alert
          severity="warning"
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, dateTooFar: false };
            });
          }}
        >
          Only data from 2 months or less can be filtered. Please choose a date
          within the past 2 months.
        </Alert>
      )}
      {alertState.startAfterEnd && (
        <Alert
          severity="warning"
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, startAfterEnd: false };
            });
          }}
        >
          Please choose a start date that is before your selected end date.
        </Alert>
      )}
      {alertState.endBeforeStart && (
        <Alert
          severity="warning"
          onClose={() => {
            setAlertState((previousState: AlertState) => {
              return { ...previousState, endBeforeStart: false };
            });
          }}
        >
          Please choose an end date that is after, or the same as, your selected
          start date.
        </Alert>
      )}
    </div>
  );
};
