import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { Autocomplete, TextField } from "@mui/material";

export const SureviewAlertsTabEditMode = ({
  sureviewAlerts,
  cameraGeneralInfo,
  setPatchObject,
  setGeneralInfoChangesMade,
}: {
  sureviewAlerts: any;
  cameraGeneralInfo: any;
  setPatchObject: any;
  setGeneralInfoChangesMade: any;
}) => {
  const serverOptions = [
    ["US SMTP Server", "us-smtp.sureviewops.com"],
    ["EU SMTP Server", "eu-smtp.sureviewops.com"],
  ];

  const optionsIds = new Map();
  const optionNames = new Map();
  serverOptions.forEach((option: any) => {
    optionsIds.set(option[0], option[1]);
  });
  serverOptions.forEach((option: any) => {
    optionNames.set(option[1], option[0]);
  });

  const createNewPatchStreams = (
    currentIndex: any,
    inputValue: any,
    titleString: string
  ) => {
    const updatedStreams = cameraGeneralInfo.streams;
    updatedStreams.forEach((object: any) => {
      if (titleString === "sureview_server") {
        object.sureview_alerts[currentIndex].sureview_server = inputValue;
      }
      if (titleString === "sureview_subject") {
        object.sureview_alerts[currentIndex].sureview_subject = inputValue;
      }
      if (titleString === "sureview_cc") {
        object.sureview_alerts[currentIndex].sureview_cc = inputValue;
      }
      if (titleString === "sureview_email") {
        object.sureview_alerts[currentIndex].sureview_email = inputValue;
      }
    });
    return updatedStreams;
  };

  const updatePatchObject = (patchStreams: any) => {
    setPatchObject((previousState: any) => {
      return { ...previousState, streams: patchStreams };
    });
  };

  React.useEffect(() => {
    setPatchObject((previousState: any) => {
      return { ...previousState, streams: cameraGeneralInfo.streams };
    });
  }, []);

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      {sureviewAlerts.map((object: any, index: any) => {
        const currentIndex = index;
        return (
          <div key={object.id} className="h-[235px]">
            <div className="min-w-[1150px] h-[5vh] bg-white">
              <div className="absolute right-[70px] top-[10px]"></div>
            </div>
            <div className="min-w-[1150px] h-[40px] bg-white">
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: "Bold",
                  color: "black",
                  textIndent: "1vw",
                }}
              >
                Sureview Alert {index + 1}
              </Typography>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 1000, fontFamily: "Mulish" }}
                  aria-label="simple table"
                >
                  <TableHeadComponent
                    titlesArray={[
                      "Sureview Server",
                      "Sureview Subject",
                      "Sureview cc",
                      "Sureview Email",
                    ]}
                  />
                  <TableBody>
                    <TableRow key={object.id}>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.sureview_server}>
                          <Autocomplete
                            disablePortal
                            id={object.sureview_server}
                            options={serverOptions.map((option: string[]) => {
                              return option[0];
                            })}
                            defaultValue={optionNames.get(
                              object.sureview_server
                            )}
                            onChange={(event: any, newInputValue: any) => {
                              const convertedInputValue =
                                optionsIds.get(newInputValue);
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  convertedInputValue,
                                  "sureview_server"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                            sx={{ width: 350 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Sureview Server"
                                autoComplete="off"
                                id={object.sureview_server}
                                name={object.sureview_server}
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.sureview_subject}>
                          <TextField
                            id={object.sureview_subject}
                            defaultValue={object.sureview_subject}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "sureview_subject"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.sureview_cc}>
                          <TextField
                            id={object.sureview_cc}
                            defaultValue={object.sureview_cc}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "sureview_cc"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                        <div key={object.sureview_email}>
                          <TextField
                            id={object.sureview_email}
                            defaultValue={object.sureview_email}
                            variant="standard"
                            sx={{ width: 150 }}
                            type="string"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updatePatchObject(
                                createNewPatchStreams(
                                  currentIndex,
                                  event.target.value,
                                  "sureview_email"
                                )
                              );
                              setGeneralInfoChangesMade(true);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        );
      })}
    </div>
  );
};
