import React from "react";

function generateBooleanArray(startTime: string, endTime: string): boolean[] {
  const totalIntervals = 96;
  const intervals: boolean[] = new Array(totalIntervals).fill(false);

  if (!startTime || !endTime) {
    return intervals;
  }

  // Parse the start and end times
  const [startHours, startMinutes] = startTime.split(":").map(Number);
  const [endHours, endMinutes] = endTime.split(":").map(Number);

  // Convert start time to interval index
  const startIndex = Math.floor((startHours * 60 + startMinutes) / 15);

  // Convert end time to interval index
  let endIndex = Math.ceil((endHours * 60 + endMinutes) / 15);
  if (endIndex === totalIntervals) {
    endIndex = 0; // Wrap around if end time is exactly 24:00
  }

  // Fill the intervals array with true between startIndex and endIndex
  if (startIndex < endIndex) {
    for (let i = startIndex; i < endIndex; i++) {
      intervals[i] = true;
    }
  } else {
    for (let i = startIndex; i < totalIntervals; i++) {
      intervals[i] = true;
    }
    for (let i = 0; i < endIndex; i++) {
      intervals[i] = true;
    }
  }

  return intervals;
}

export const handleConvertSchedulesToBooleanArrays = (siteSchedules: any) => {
  const width = 96; // 24 hours * 4 (15 minutes each)
  const height = 7; // Days of the week

  const booleanScheduleGrid = Array.from({ length: height }, () =>
    Array.from({ length: width }, () => false)
  );

  siteSchedules.forEach((object: any) => {
    object.day_of_week.forEach((day: string) => {
      const dayIndex = Number(day);
      if (!object.start_time || !object.end_time) {
        booleanScheduleGrid[dayIndex] = new Array(width).fill(false);
      } else if (object.always_on) {
        booleanScheduleGrid[dayIndex] = new Array(width).fill(true);
      } else {
        if (object.start_time < object.end_time) {
          booleanScheduleGrid[dayIndex] = generateBooleanArray(
            object.start_time,
            object.end_time
          );
        } else {
          const reversedArray = generateBooleanArray(
            object.end_time,
            object.start_time
          );
          //take every value in the reversed array and swap it for the opposite boolean value, so each true becomes and false and each false becomes a true
          booleanScheduleGrid[dayIndex] = reversedArray.map((value) => !value);
        }
      }
      if (object.always_on) {
        booleanScheduleGrid[dayIndex] = new Array(width).fill(true);
      }
    });
  });
  return booleanScheduleGrid;
};
