import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { checkOrX } from "./EditCameraUtils/checkOrX";

export const IPNotifyTable = ({ iPNotifyData }: { iPNotifyData: any }) => {
  const titlesArray = ["Name", "IP Notify Address", "Message"];

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="time_tables_table"
      >
        <TableHeadComponent titlesArray={titlesArray} />
        <TableBody>
          {iPNotifyData.map((object: any) => {
            return (
              <TableRow key={object.id}>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.display_name ? <>{object.display_name}</> : "--"}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.address ? (
                    <>
                      {checkOrX(object.is_address_ok)} {object.address}
                    </>
                  ) : (
                    "--"
                  )}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {object.message ? (
                    <>
                      {checkOrX(object.is_message_ok)} {object.message}
                    </>
                  ) : (
                    "--"
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
