import React from "react";
import { getRefreshToken } from "./getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";
import { getCookie } from "./getCookie";

export const universalAPIErrorHandler = (
  reason: AxiosError,
  navigate: NavigateFunction,
  retryFunction: any,
  errorHandlingFunction: any
) => {
  const token = getCookie("token");
  const refreshToken = getCookie("token_secret");
  if (localStorage.tokenRefreshing === "true" && refreshToken) {
    retryFunction();
  } else if (reason.message === "Network Error") {
    // eslint-disable-next-line no-console
    console.log("Network Error");
    if (errorHandlingFunction) {
      errorHandlingFunction();
    }
  } else {
    if (
      reason.message === "Network Error" ||
      reason.request.response.includes(
        "SocialToken matching query does not exist"
      ) ||
      reason.request.response.includes("Returned invalid token from cache") ||
      reason.request.response.includes("Token is expired") ||
      reason.request.response.includes("Token not found") ||
      Boolean(!token && refreshToken) === true
    ) {
      localStorage.setItem("tokenRefreshing", "true");
      getRefreshToken(navigate, retryFunction);
    } else {
      // eslint-disable-next-line no-console
      console.log(`${reason}`);
      if (errorHandlingFunction) {
        errorHandlingFunction();
      }
    }
  }
};
