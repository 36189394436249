export const createStreamsObject = (streams: any) => {
  if (!streams[0]) {
    return;
  }
  const passedStreamObj: any = {};
  streams.forEach((object: any) => {
    passedStreamObj[object.id] = object;
  });
  return passedStreamObj;
};
