import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosResponse } from "axios";
import { getRefreshToken } from "../../../../utils/getRefreshToken";
import { handleGetAllData } from "./handleGetAllData";
import { AlertState, Dispatcher } from "./siteAboutInterfaces";

export const handleRefreshCameras = (
  navigate: any,
  setSiteInfo: any,
  setSiteSchedules: any,
  setSiteCameras: any,
  setActiveTab: any,
  setDataLoaded: any,
  setBackdropLoadingScreenOn: any,
  setAllSiteSchedules: any,
  setVmsVariables: any,
  setNoAutoAddCamerasDialogOpen: any,
  setArmedStatus: any,
  setAiLinkSite: any,
  setSiteDeployed: any,
  setSyncNeeded: any,
  setAlertState: any,
  setCamerasNotDeployed: any,
  setToolTipTitle: any,
  setConfigureMotion: any,
  setPatchObject: any,
  setArmButtonDisabled: any,
  setRecordingServerData: any,
  setRecordingServerPatchObject: any,
  setRecordingServerVisible: Dispatcher<boolean>,
  setHealthCheckData: any,
  setCamerasRefreshing: Dispatcher<boolean>,
  setWebhooksInfo: any,
  setWebhooksPatchObject: any
) => {
  const returnFunction = () => {
    handleRefreshCameras(
      navigate,
      setSiteInfo,
      setSiteSchedules,
      setSiteCameras,
      setActiveTab,
      setDataLoaded,
      setBackdropLoadingScreenOn,
      setAllSiteSchedules,
      setVmsVariables,
      setNoAutoAddCamerasDialogOpen,
      setArmedStatus,
      setAiLinkSite,
      setSiteDeployed,
      setSyncNeeded,
      setAlertState,
      setCamerasNotDeployed,
      setToolTipTitle,
      setConfigureMotion,
      setPatchObject,
      setArmButtonDisabled,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerVisible,
      setHealthCheckData,
      setCamerasRefreshing,
      setWebhooksInfo,
      setWebhooksPatchObject
    );
  };
  const AxiosUI = createApi(``);

  AxiosUI.post(`/customer/${localStorage.siteId}/action/refresh_preview/`).then(
    () => {
      setAlertState((previousState: AlertState) => {
        return { ...previousState, refreshCameraSuccess: true };
      });
      handleGetAllData(
        localStorage.siteId,
        navigate,
        setSiteInfo,
        setSiteSchedules,
        setSiteCameras,
        setActiveTab,
        setDataLoaded,
        setBackdropLoadingScreenOn,
        setAllSiteSchedules,
        setVmsVariables,
        setNoAutoAddCamerasDialogOpen,
        setArmedStatus,
        setAiLinkSite,
        setSiteDeployed,
        setSyncNeeded,
        setAlertState,
        setCamerasNotDeployed,
        setToolTipTitle,
        setConfigureMotion,
        setPatchObject,
        setArmButtonDisabled,
        setRecordingServerData,
        setRecordingServerPatchObject,
        setRecordingServerVisible,
        setHealthCheckData,
        setWebhooksInfo,
        setWebhooksPatchObject
      );
      setCamerasRefreshing(false);
    },
    (reason: AxiosResponse) => {
      if (
        reason.request.response.slice(11, 53) ===
          "SocialToken matching query does not exist." ||
        reason.request.response.includes("Returned invalid token from cache") ||
        reason.request.response.slice(11, 53) === "Token is expired"
      ) {
        getRefreshToken(navigate, returnFunction);
      } else {
        setAlertState((previousState: AlertState) => {
          return { ...previousState, refreshCameraFail: true };
        });
        setBackdropLoadingScreenOn(false);
        setCamerasRefreshing(false);
        console.log(`${reason}`);
      }
    }
  );
};
