import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";

export const ProgressFinishedScreen = () => {
  const navigate = useNavigate();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF8400",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div className="absolute right-4 top-[80px]">
          <OrangeTextButton
            onClick={() => {
              localStorage.setItem("clickedId", localStorage.siteId);
              navigate(`/sites/aboutsite/${localStorage.siteId}`);
            }}
            text="GO TO SITE"
            disabled={false}
          />
          <ArrowForwardIcon />
        </div>
        <div className="absolute top-[137px] right-44">
          <LinearProgress
            color="primary"
            variant="determinate"
            value={100}
            sx={{ width: 275 }}
          />
        </div>
        <div className="absolute top-32 right-32">
          <Typography variant="body1">100%</Typography>
        </div>
        <div className="absolute top-[160px] right-[230px] font-mulish text-actuate-blue text-xl">
          You&apos;re All Set!
        </div>
        <div className="absolute top-[230px] right-[350px]">
          <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <FormControlLabel
              sx={{
                fontFamily: "Mulish",
                color: "#001943",
              }}
              label="Site Creation"
              control={<Checkbox color="primary" checked={true} />}
            />
            <br></br>
            <FormControlLabel
              sx={{
                fontFamily: "Mulish",
                color: "#001943",
              }}
              label="Camera Setup"
              control={<Checkbox color="primary" checked={true} />}
            />
            <br></br>
            {localStorage.healthcheckOnly === "false" && (
              <FormControlLabel
                sx={{
                  fontFamily: "Mulish",
                  color: "#001943",
                }}
                label="Schedule Setup"
                control={<Checkbox color="primary" checked={true} />}
              />
            )}
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
};
