// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { LoadingData, ParentGroupsAlertData } from "./analyticsInterfaces";
import { getMax7Array } from "./getMax7Array";
import { getConversionArray } from "./getConversionArray";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getParentGroupsAlertData = (
  setParentGroupsAlertsData: Dispatcher<ParentGroupsAlertData[]>,
  groupId: string,
  start_date: string,
  end_date: string,
  siteIds: string[],
  navigate: NavigateFunction,
  setLoadingData: Dispatcher<LoadingData>,
  setDisplayOnLeftBool: Dispatcher<boolean>,
  setVisibleArray: Dispatcher<any>,
  setConversionArray: Dispatcher<any>,
  setShowMoreVisible: Dispatcher<any>,
  setShowMoreOpen: Dispatcher<boolean>,
  userFilters: any,
  setAlertsGraphName: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getParentGroupsAlertData(
      setParentGroupsAlertsData,
      groupId,
      start_date,
      end_date,
      siteIds,
      navigate,
      setLoadingData,
      setDisplayOnLeftBool,
      setVisibleArray,
      setConversionArray,
      setShowMoreVisible,
      setShowMoreOpen,
      userFilters,
      setAlertsGraphName
    );
  };

  let searchParamsString = ``;

  if (!groupId && !end_date && start_date) {
    searchParamsString = `?start_date=${start_date}`;
  }
  if (!groupId && end_date && !start_date) {
    searchParamsString = `?end_date=${end_date}`;
  }
  if (groupId && !end_date && start_date) {
    searchParamsString = `?group=${groupId}&start_date=${start_date}`;
  }
  if (groupId && end_date && !start_date) {
    searchParamsString = `?group=${groupId}&end_date=${end_date}`;
  }
  if (groupId && end_date && start_date) {
    searchParamsString = `?group=${groupId}&start_date=${start_date}&end_date=${end_date}`;
  }
  if (!groupId && end_date && start_date) {
    searchParamsString = `?start_date=${start_date}&end_date=${end_date}`;
  }
  if (siteIds[0]) {
    let additionalSiteString = `&site=${siteIds[0]}`;
    if (siteIds.length > 1) {
      siteIds.forEach((siteId: string, index: number) => {
        if (index !== 0) {
          additionalSiteString += `,${siteId}`;
        }
      });
    }
    searchParamsString += additionalSiteString;
  }

  AxiosUI.get(`analytics/alerts_by_parent/${searchParamsString}`).then(
    (response: AxiosResponse) => {
      const updatedData = response.data;
      for (const key in updatedData) {
        const updatedKey = updatedData[key];
        if (updatedKey.name.length > 4) {
          updatedKey.abreviated_name = `${updatedKey.name.slice(0, 3)}...`;
        } else {
          updatedKey.abreviated_name = updatedKey.name;
        }
      }
      setParentGroupsAlertsData(updatedData);
      setVisibleArray(
        getMax7Array(
          getConversionArray(
            updatedData,
            setConversionArray,
            setShowMoreVisible
          )
        )
      );
      setShowMoreOpen(false);
      setLoadingData((previousState) => {
        return { ...previousState, parentGroupsData: false };
      });
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        if (userFilters.parent_group === "") {
          setAlertsGraphName("Parent Group");
        }
        if (userFilters.parent_group) {
          setAlertsGraphName("Site");
        }
        if (userFilters.site[0]) {
          setAlertsGraphName("Camera");
        }
        console.log(`${reason}`);
        setLoadingData((previousState) => {
          return { ...previousState, parentGroupsData: false };
        });
      });
    }
  );
};
