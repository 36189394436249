import React from "react";

export const updateUnhealthyCamsData = (
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  tableString: string,
  previousString: string
) => {
  if (tableString === "resolved") {
    setUnhealthyCamsData((previousState: any) => {
      return { ...previousState, resolved: (unhealthyCamsData.resolved += 1) };
    });
  }
  if (previousString === "resolved") {
    setUnhealthyCamsData((previousState: any) => {
      return { ...previousState, resolved: (unhealthyCamsData.resolved -= 1) };
    });
  }
};
