import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { checkedCircleIcon } from "../../../common/checkedCircleIcon";
import { cancelIconRed } from "../../../common/cancelIconRed";
import { epochToUPCCovertor } from "./SiteAboutPageUtils/epochToUPCCovertor";
import { timeSinceConvertor } from "./SiteAboutPageUtils/timeSinceConvertor";
import { roundToHundredth } from "./SiteAboutPageUtils/roundToHundredth";
import { AboutTableHead } from "./AboutTableHead";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { createArmedIcon } from "../SitesPage/SitesPageUtils/createArmedIcon";
import { SiteInfo } from "./SiteAboutPageUtils/siteAboutInterfaces";
import ErrorBoundary from "../../../../ErrorBoundary";

export const AboutTable = ({ siteInfo }: { siteInfo: SiteInfo }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <AboutTableHead
          titlesArray={[
            "Armed",
            "Synced",
            "Last Synchronized",
            "Last Motion",
            "Last Alert",
            "Next Schedule",
            "Motion %",
          ]}
        />
        <TableBody>
          <TableRow key={siteInfo.id}>
            <TableCell align="left">
              {" "}
              {createArmedIcon(siteInfo.armed, siteInfo.armed_status)}
            </TableCell>
            <TableCell align="left">
              {siteInfo.settings_deployed ? checkedCircleIcon : cancelIconRed}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.deployed_date
                ? epochToUPCCovertor(
                    siteInfo.deployed_date,
                    siteInfo.timezone
                  ).toString()
                : "--"}
              &nbsp;
              {siteInfo.deployed_date && siteInfo.timezone ? (
                <Tooltip
                  title={
                    siteInfo.timezone
                    // epochToUPCCovertor(siteInfo.deployed_date)
                    // .toString()
                    // .slice(34)
                  }
                  placement="top"
                  sx={{ fontSize: "15px", fontFamily: "mulish" }}
                >
                  <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                </Tooltip>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.last_motion
                ? timeSinceConvertor(siteInfo.last_motion)
                : "--"}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.last_alert
                ? timeSinceConvertor(siteInfo.last_alert)
                : "--"}
            </TableCell>
            {siteInfo.next_schedule ? (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {epochToUPCCovertor(
                  siteInfo.next_schedule,
                  siteInfo.timezone
                ).toString()}
                &nbsp;
                {siteInfo.next_schedule && siteInfo.timezone ? (
                  <Tooltip
                    title={
                      siteInfo.timezone
                      // epochToUPCCovertor(siteInfo.next_schedule)
                      // .toString()
                      //   .slice(34)
                    }
                    placement="top"
                    sx={{ fontSize: "15px", fontFamily: "mulish" }}
                  >
                    <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                  </Tooltip>
                ) : (
                  ""
                )}
              </TableCell>
            ) : (
              <TableCell align="left"> -- </TableCell>
            )}
            {siteInfo.motion_percentage ? (
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {roundToHundredth(siteInfo.motion_percentage)}%
              </TableCell>
            ) : (
              <TableCell align="left"> -- </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
