import React from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { AlertData } from "../Alerts/AlertsUtils/alertsInterfaces";
import { visuallyHidden } from "@mui/utils";

type Order = "asc" | "desc";

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AlertData
  ) => void;
  order: Order;
  orderBy: string;
}

const headCells: readonly any[] = [
  {
    id: "username",
    numeric: false,
    label: "User",
  },
  {
    id: "group",
    numeric: false,
    label: "Group",
  },
  {
    id: "self_service",
    numeric: false,
    label: "Self Service",
  },
  {
    id: "last_login",
    numeric: false,
    label: "Last Login",
  },
  {
    id: "site_count",
    numeric: false,
    label: "Site Access",
  },
];

export const EnhancedGroupUsersTableHead = (props: any) => {
  const { onRequestSort, orderBy, order } = props;
  const createSortHandler =
    (property: keyof AlertData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              sx={{
                fontSize: "15px",
                fontFamily: "mulish",
                fontWeight: "Bold",
                maxWidth: 250,
              }}
              style={{ minWidth: 200 }}
              key={headCell.id}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel> */}
              {headCell.label}
            </TableCell>
          );
        })}
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
};
