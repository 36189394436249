import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";

export const createArmedIcon = (
  armedInput: boolean,
  armedStatusInput: string
) => {
  let armed: string | JSX.Element = <></>;
  if (armedStatusInput === "OK") {
    armed = <CheckCircleIcon color="success" />;
  } else if (armedStatusInput === "WARNING") {
    armed = (
      <Tooltip title="Site is armed on schedule but it has no motion.">
        <WarningIcon sx={{ color: "#FF8400" }} />
      </Tooltip>
    );
  } else if (armedStatusInput === "ERROR") {
    armed = (
      <Tooltip title="Site is armed on schedule, but hasn't received a frame in more than 10 minutes.">
        <WarningIcon sx={{ color: "#FF8400" }} />
      </Tooltip>
    );
  } else if (armedStatusInput === "ARMED_OUTSIDE_SCHEDULE") {
    armed = (
      <Tooltip title="Site was manually armed (not in schedule)">
        <WarningIcon color="success" />
      </Tooltip>
    );
  } else if (armedInput && armedStatusInput === "NOT_RUNNING") {
    armed = (
      <Tooltip title="Site is not currently running">
        <WarningIcon sx={{ color: "#FF8400" }} />
      </Tooltip>
    );
  } else if (armedStatusInput === "ARMING") {
    armed = (
      <Tooltip title="Reboot command received. It may take up to 5 minutes for arm status to update.">
        <WarningIcon sx={{ color: "#FF8400" }} />
      </Tooltip>
    );
  } else if (armedStatusInput === "DISARMING") {
    armed = (
      <Tooltip title="Disarm command received. Site is in the process of disarming.  It may take up to 5 minutes for arm status to update.">
        <WarningIcon sx={{ color: "#FF8400" }} />
      </Tooltip>
    );
  } else if (armedStatusInput === "UNKNOWN") {
    armed = (
      <Tooltip title="Armed does not apply to this type of site (if AI Link), or armed status is unknown">
        <WarningIcon sx={{ color: "#b4b4b4" }} />
      </Tooltip>
    );
  } else {
    armed = <CancelIcon color="error" />;
  }
  return armed;
};
