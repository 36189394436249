export const temporaryProductRequirementData = [
  {
    product: "Intruder",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Intruder+",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Gun",
    resolution: "720p",
    fps: 6,
    aspect_ratio: "16:9",
    codec: "H264",
  },
  {
    product: "Loitering",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Vehicle Loitering",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Crowd",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Hard Hat",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Fire",
    resolution: "720p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Left Object",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Postal Vehicle ID",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "Slip & Fall",
    resolution: "720p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: "People Flow",
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: `Mask / No Mask`,
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: `Package`,
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: `Social Distancing`,
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
  {
    product: `Sudden Scene Change`,
    resolution: "480p",
    fps: 5,
    aspect_ratio: "4:3",
    codec: "H264",
  },
];
