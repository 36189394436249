import React from "react";
import { createApi } from "../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "./universalAPIErrorHandler";

export const getParentAccounts = (
  setParentAccountsLoading: Dispatcher<boolean>,
  setParentAccounts: Dispatcher<any>, // Consider specifying a more specific type than `any` if possible
  setParentIds: Dispatcher<Map<any, any>>, // Consider using more specific types instead of `any`
  setParentNames: Dispatcher<Map<any, any>>, // Consider using more specific types instead of `any`
  navigate: NavigateFunction
) => {
  setParentAccountsLoading(true);

  const AxiosUI = createApi("");

  AxiosUI.get("group/?parent_account=true&page_size=10000").then(
    (response: AxiosResponse) => {
      const results = response.data.results;
      setParentAccounts(results);

      const parentIds = new Map();
      const parentNames = new Map();

      results.forEach((object: any) => {
        // Consider defining a specific type for `object`
        parentIds.set(object.name, object.id);
        parentNames.set(object.id, object.name);
      });

      setParentIds(parentIds);
      setParentNames(parentNames);
      setParentAccountsLoading(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(
        reason,
        navigate,
        () =>
          getParentAccounts(
            setParentAccountsLoading,
            setParentAccounts,
            setParentIds,
            setParentNames,
            navigate
          ),
        () => {
          setParentAccounts([]);
          setParentAccountsLoading(false);
        }
      );
    }
  );
};

// import React from "react";
// import { createApi } from "../utils/createApi";
// import { AxiosError, AxiosResponse } from "axios";
// import { NavigateFunction } from "react-router-dom";
// import { Dispatcher } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
// import { universalAPIErrorHandler } from "./universalAPIErrorHandler";

// export const getParentAccounts = (
//   setParentAccountsLoading: Dispatcher<boolean>,
//   setParentAccounts: any,
//   setParentIds: any,
//   setParentNames: any,
//   navigate: NavigateFunction
// ) => {
//   const retryFunction = () => {
//     getParentAccounts(
//       setParentAccountsLoading,
//       setParentAccounts,
//       setParentIds,
//       setParentNames,
//       navigate
//     );
//   };

//   const AxiosUI = createApi(``);

//   const parentIds = new Map();
//   const parentNames = new Map();

//   AxiosUI.get(`group/?parent_account=true&page_size=10000`).then(
//     (response: AxiosResponse) => {
//       setParentAccounts(response.data.results);
//       response.data.results.forEach((object: any) => {
//         parentIds.set(object.name, object.id);
//       });
//       response.data.results.forEach((object: any) => {
//         parentNames.set(object.id, object.name);
//       });
//       setParentIds(parentIds);
//       setParentNames(parentNames);
//       setParentAccountsLoading(false);
//     },
//     (reason: AxiosError) => {
//       universalAPIErrorHandler(reason, navigate, retryFunction, () => {
//         setParentAccounts([]);
//         setParentAccountsLoading(false);
//       });
//     }
//   );
// };
