// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { MobotixTab } from "../MobotixTab";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleAddIPNotify = (
  cameraId: string,
  navigate: NavigateFunction,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setIPNotifyData: any,
  setActiveTab: any,
  timeTablesData: any,
  setTimeTablesData: any,
  actionHandlerData: any,
  setActionHandlerData: any
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleAddIPNotify(
      cameraId,
      navigate,
      setBackdropLoadingScreenOn,
      setIPNotifyData,
      setActiveTab,
      timeTablesData,
      setTimeTablesData,
      actionHandlerData,
      setActionHandlerData
    );
  };

  AxiosUI.post(`/mobotix/${cameraId}/ip_notify/`, {}).then(
    (response: AxiosResponse) => {
      setIPNotifyData(response.data);
      setBackdropLoadingScreenOn(false);
      setActiveTab(<></>);
      setActiveTab(
        <MobotixTab
          timeTablesData={timeTablesData}
          navigate={navigate}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setTimeTablesData={setTimeTablesData}
          setActiveTab={setActiveTab}
          iPNotifyData={response.data}
          setIPNotifyData={setIPNotifyData}
          actionHandlerData={actionHandlerData}
          setActionHandlerData={setActionHandlerData}
        />
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        alert("Problem adding IP Notify, please try again");
      });
    }
  );
};
