import React from "react";

export const filterData = (
  alertsData: any,
  selectedName: any,
  alertFilter: string,
  singleCellActivated: boolean
) => {
  const updatedAlertsAllData: any = [];
  const updatedAlertsErrorsData: any = [];
  const updatedAlertsWarningsData: any = [];
  const updatedAlertsPendingData: any = [];
  const updatedAlertsResolvedData: any = [];

  if (singleCellActivated && alertFilter !== "all") {
    alertsData.all.forEach((object: any) => {
      if (
        (object.camera_name === selectedName ||
          object.site_name === selectedName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (
        (object.camera_name === selectedName ||
          object.site_name === selectedName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (
        (object.camera_name === selectedName ||
          object.site_name === selectedName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (
        (object.camera_name === selectedName ||
          object.site_name === selectedName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (
        (object.camera_name === selectedName ||
          object.site_name === selectedName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else if (singleCellActivated && alertFilter === "all") {
    alertsData.all.forEach((object: any) => {
      if (
        object.camera_name === selectedName ||
        object.site_name === selectedName
      ) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (
        object.camera_name === selectedName ||
        object.site_name === selectedName
      ) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (
        object.camera_name === selectedName ||
        object.site_name === selectedName
      ) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (
        object.camera_name === selectedName ||
        object.site_name === selectedName
      ) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (
        object.camera_name === selectedName ||
        object.site_name === selectedName
      ) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else if (!singleCellActivated && alertFilter !== "all") {
    alertsData.all.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else {
    alertsData.all.forEach((object: any) => {
      updatedAlertsAllData.push(object);
    });
    alertsData.errors.forEach((object: any) => {
      updatedAlertsErrorsData.push(object);
    });
    alertsData.warnings.forEach((object: any) => {
      updatedAlertsWarningsData.push(object);
    });
    alertsData.pending.forEach((object: any) => {
      updatedAlertsPendingData.push(object);
    });
    alertsData.resolved.forEach((object: any) => {
      updatedAlertsResolvedData.push(object);
    });
  }

  return {
    all: updatedAlertsAllData,
    errors: updatedAlertsErrorsData,
    warnings: updatedAlertsWarningsData,
    pending: updatedAlertsPendingData,
    resolved: updatedAlertsResolvedData,
  };
};
