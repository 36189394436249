import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../../common/TableHead";
import { integrationsMatcher } from "../../../utils/integrationsMatcher";
import { Autocomplete, TextField, Switch } from "@mui/material";
import {
  SiteInfo,
  PatchObject,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

interface GroupOption {
  id: number;
  name: string;
  parent: number;
  parent_account: boolean;
}

export const GeneralInfoTabEditMode = ({
  setPatchObject,
  setSiteInfo,
  siteInfo,
  setSiteInfoChangesMade,
}: {
  setPatchObject: Dispatcher<PatchObject>;
  setSiteInfo: Dispatcher<SiteInfo>;
  siteInfo: SiteInfo;
  setSiteInfoChangesMade: Dispatcher<boolean>;
}) => {
  const groupOptions: GroupOption[] = JSON.parse(
    localStorage.parentGroups
  ).filter(
    //filter for groups that have the parent_account set to true
    (group: GroupOption) => group.parent_account === true
  );

  const groupOptionsIds: Map<number, string> = new Map();
  const groupOptionsNames: Map<string, number> = new Map();
  const groupStandardNames: Map<string, string> = new Map();

  groupOptions.forEach((option: GroupOption) => {
    groupOptionsIds.set(option.id, `${option.name} (group id: ${option.id})`);
  });

  groupOptions.forEach((option: GroupOption) => {
    groupOptionsNames.set(`${option.name} (group id: ${option.id})`, option.id);
  });

  groupOptions.forEach((option: GroupOption) => {
    groupStandardNames.set(
      `${option.name} (group id: ${option.id})`,
      option.name
    );
  });

  const alarmOptions = JSON.parse(localStorage.alarmOptions);

  const alarmIds = new Map();
  alarmOptions.forEach((object: any) => {
    alarmIds.set(object.value, object.name);
  });

  const alarmNames = new Map();
  alarmOptions.forEach((object: any) => {
    alarmNames.set(object.name, object.value);
  });

  const titlesArray = [
    "Site Name",
    "Timezone",
    "Autoadd",
    "Integration Type",
    "Alarm Type",
    "Parent Group",
  ];

  if (siteInfo.show_ecs_nat_instance) {
    titlesArray.push("Nat Instance");
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 1000, fontFamily: "Mulish" }}
          aria-label="simple table"
        >
          <TableHeadComponent titlesArray={titlesArray} />
          <TableBody>
            <TableRow key={siteInfo.id}>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={siteInfo.name}>
                  <TextField
                    defaultValue={siteInfo.name}
                    variant="standard"
                    sx={{ width: 200 }}
                    type="string"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPatchObject((previousState: PatchObject) => {
                        return { ...previousState, name: event.target.value };
                      });
                      setSiteInfo((previousState: SiteInfo) => {
                        return { ...previousState, name: event.target.value };
                      });
                      setSiteInfoChangesMade(true);
                    }}
                  />
                </div>
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={siteInfo.timezone}>
                  <Autocomplete
                    disablePortal
                    options={JSON.parse(localStorage.timeZoneArray)}
                    defaultValue={siteInfo.timezone}
                    sx={{ width: 200 }}
                    onInputChange={(event, newInputValue) => {
                      setPatchObject((previousState: PatchObject) => {
                        return { ...previousState, timezone: newInputValue };
                      });
                      setSiteInfo((previousState: SiteInfo) => {
                        return { ...previousState, timezone: newInputValue };
                      });
                      setSiteInfoChangesMade(true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        autoComplete="off"
                      />
                    )}
                  />
                </div>
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {siteInfo.auto_add}
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                {integrationsMatcher("Name", siteInfo.integration_type)}
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={siteInfo.default_alarm}>
                  <Autocomplete
                    disablePortal
                    options={alarmOptions.map((option: any) => {
                      return option.name;
                    })}
                    defaultValue={alarmIds.get(siteInfo.default_alarm)}
                    sx={{ width: 150 }}
                    onInputChange={(event, newInputValue) => {
                      setPatchObject((previousState: PatchObject) => {
                        return {
                          ...previousState,
                          default_alarm: alarmNames.get(newInputValue),
                        };
                      });
                      setSiteInfo((previousState: SiteInfo) => {
                        return {
                          ...previousState,
                          default_alarm: alarmNames.get(newInputValue),
                        };
                      });
                      setSiteInfoChangesMade(true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        autoComplete="off"
                      />
                    )}
                  />
                </div>
              </TableCell>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                <div key={siteInfo.breadcrumbs[0]}>
                  <Autocomplete
                    disablePortal
                    options={groupOptions.map((option: GroupOption) => {
                      return `${option.name} (group id: ${option.id})`;
                    })}
                    defaultValue={
                      siteInfo.breadcrumbs[siteInfo.breadcrumbs.length - 2]
                    }
                    sx={{ width: 150 }}
                    onInputChange={(event, newInputValue) => {
                      setPatchObject((previousState: PatchObject) => {
                        return {
                          ...previousState,
                          parent_group: {
                            parent: groupOptionsNames.get(newInputValue),
                          },
                        };
                      });
                      setSiteInfo((previousState: SiteInfo) => {
                        return {
                          ...previousState,
                          parent_group: {
                            name: groupStandardNames.get(newInputValue),
                          },
                        };
                      });
                      localStorage.setItem("groupNameChange", "true");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        autoComplete="off"
                      />
                    )}
                  />
                </div>
              </TableCell>
              {siteInfo.show_ecs_nat_instance && (
                <TableCell>
                  {siteInfo.ecs_nat_instance ? (
                    <Switch
                      defaultChecked
                      disabled={false}
                      color="warning"
                      onChange={(event: any) => {
                        setPatchObject((previousState: PatchObject) => {
                          return {
                            ...previousState,
                            ecs_nat_instance: event.target.value,
                          };
                        });
                        setSiteInfo((previousState: SiteInfo) => {
                          return {
                            ...previousState,
                            ecs_nat_instance: event.target.value,
                          };
                        });
                        setSiteInfoChangesMade(true);
                      }}
                    />
                  ) : (
                    <Switch
                      disabled={false}
                      color="warning"
                      onChange={(event: any) => {
                        setPatchObject((previousState: PatchObject) => {
                          return {
                            ...previousState,
                            ecs_nat_instance: event.target.value,
                          };
                        });
                        setSiteInfo((previousState: SiteInfo) => {
                          return {
                            ...previousState,
                            ecs_nat_instance: event.target.value,
                          };
                        });
                        setSiteInfoChangesMade(true);
                      }}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
