import React from "react";

export const removeNumberFromArray = (
  targetNumber: number,
  numbers: number[]
): number[] => {
  // Use filter to create a new array without the targetNumber
  const newArray = numbers.filter((num) => num !== targetNumber);
  return newArray;
};
