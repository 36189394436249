import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";

export const createHealthIcon = (
  num: number,
  errorNumber: number,
  warningNumber: number
) => {
  let message = "";

  if (warningNumber > 0 && errorNumber === 0) {
    message = `${warningNumber} camera(s) with broken frames since launch or blurry view.`;
  }

  if (errorNumber > 0 && warningNumber === 0) {
    message = `${errorNumber} camera(s) unable to connect or provided no frame since launch.`;
  }

  if (warningNumber > 0 && errorNumber > 0) {
    message = `${warningNumber} camera(s) with broken frames since launch or blurry view. ${errorNumber} camera(s) unable to connect or provided no frame since launch.`;
  }

  if (num !== 0) {
    return (
      <Tooltip title={message} placement="top">
        <div>
          {num}&nbsp;
          <CancelIcon color="error" fontSize="small" />
        </div>
      </Tooltip>
    );
  } else
    return (
      <div>
        {num}&nbsp;
        <CheckCircleIcon color="success" fontSize="small" />
      </div>
    );
};
