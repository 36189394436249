import React from "react";
import { string } from "yup";

export const handleUpdateFilteredDataCounts = (
  singleCellActivated: boolean,
  alertsData: any,
  activeName: any,
  setFilteredDataLengths: any,
  alertFilter: string
) => {
  // if (singleCellActivated) {
  const updatedAlertsAllData: any = [];
  const updatedAlertsErrorsData: any = [];
  const updatedAlertsWarningsData: any = [];
  const updatedAlertsPendingData: any = [];
  const updatedAlertsResolvedData: any = [];

  if (singleCellActivated && alertFilter !== "all") {
    alertsData.all.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (
        (object.camera_name === activeName ||
          object.site_name === activeName) &&
        object.error_type === alertFilter
      ) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else if (singleCellActivated && alertFilter === "all") {
    alertsData.all.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (
        object.camera_name === activeName ||
        object.site_name === activeName
      ) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else if (!singleCellActivated && alertFilter !== "all") {
    alertsData.all.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsAllData.push(object);
      }
    });

    alertsData.errors.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsErrorsData.push(object);
      }
    });
    alertsData.warnings.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsWarningsData.push(object);
      }
    });
    alertsData.pending.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsPendingData.push(object);
      }
    });
    alertsData.resolved.forEach((object: any) => {
      if (object.error_type === alertFilter) {
        updatedAlertsResolvedData.push(object);
      }
    });
  } else {
    alertsData.all.forEach((object: any) => {
      updatedAlertsAllData.push(object);
    });
    alertsData.errors.forEach((object: any) => {
      updatedAlertsErrorsData.push(object);
    });
    alertsData.warnings.forEach((object: any) => {
      updatedAlertsWarningsData.push(object);
    });
    alertsData.pending.forEach((object: any) => {
      updatedAlertsPendingData.push(object);
    });
    alertsData.resolved.forEach((object: any) => {
      updatedAlertsResolvedData.push(object);
    });
  }

  setFilteredDataLengths({
    all: updatedAlertsAllData.length,
    errors: updatedAlertsErrorsData.length,
    warnings: updatedAlertsWarningsData.length,
    pending: updatedAlertsPendingData.length,
    resolved: updatedAlertsResolvedData.length,
  });
  // }
};
