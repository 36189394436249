import React from "react";

export const findSelectedRowIndex = (
  alertsIndexOrder: any[],
  alertIndexNum: any
) => {
  let indexNumber = 0;
  alertsIndexOrder.forEach((num: any, index: any) => {
    if (num === alertIndexNum) {
      indexNumber = index;
    }
  });
  return indexNumber;
};
