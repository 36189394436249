import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AlertData } from "./alertsInterfaces";
import { getAlertMP4 } from "./getAlertMP4";
import { handleAlertButtonsFill } from "./handleAlertButtonsFill";
import { NavigateFunction } from "react-router-dom";
import { getActiveIgnoreZones } from "./getActiveIgnoreZones";
import { findSelectedRowIndex } from "./findSelectedRowIndex";

export const handleForwardButtonClick = (
  selectedRow: any,
  setAlertIndex: Dispatcher<number>,
  setSelectedRow: any,
  alertData: any,
  navigate: NavigateFunction,
  setMP4URL: any,
  setVideoClipLoading: Dispatcher<boolean>,
  setSelectedButtonsFilled: any,
  setSeeMoreURL: Dispatcher<string>,
  setAlertData: any,
  setRefreshingData: Dispatcher<boolean>,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  alertsIndexOrder: any,
  detectionType: string
) => {
  const currentIndex = findSelectedRowIndex(
    alertsIndexOrder,
    selectedRow.index
  );
  const newSelectedRow =
    detectionType === "All"
      ? alertData[currentIndex + 1]
      : alertData[alertsIndexOrder[currentIndex + 1]];
  setSelectedRow(newSelectedRow);
  localStorage.setItem("selectedRowAlerts", JSON.stringify(newSelectedRow));
  if (newSelectedRow.camera_pk) {
    getActiveIgnoreZones(
      newSelectedRow.camera_pk,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      navigate
    );
    getAlertMP4(newSelectedRow, navigate, setMP4URL, setVideoClipLoading);
    handleAlertButtonsFill(newSelectedRow, setSelectedButtonsFilled);
  } else {
    setMP4URL("");
    setVideoClipLoading(false);
    setSelectedAlertCameraInfo({});
    setIgnoreZonesSelectedAlert([]);
    alert(
      "This camera has not been added to this site, therefore video can not be retrieved. Please navigate to this site's page and add the camera"
    );
  }
  const newAlertData = alertData;
  newAlertData.forEach((object: AlertData) => {
    object.selected = false;
  });
  if (detectionType === "All") {
    newAlertData[currentIndex + 1].selected = true;
  } else {
    newAlertData[alertsIndexOrder[currentIndex + 1]].selected = true;
  }
  setSeeMoreURL(newSelectedRow.alert_url);
  setAlertData(newAlertData);
  setRefreshingData(false);
};
