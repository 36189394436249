import { Link } from "@mui/material";

export const createSiteHyperlink = (siteName: string, siteId: string) => {
  const href = `/sites/aboutsite/${siteId}`;
  if (siteId) {
    return (
      <Link
        underline="hover"
        color="inherit"
        href={href}
        sx={{
          fontSize: "20px",
          fontFamily: "Mulish",
        }}
        variant="h5"
      >
        {siteName}
      </Link>
    );
  } else {
    return <>{siteName}</>;
  }
};
