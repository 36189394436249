import { AxiosError } from "axios";
import { createApi } from "../../../../utils/createApi";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handleActivateSite = async (
  setBackdropLoadingScreenOn: any,
  patchObject: any,
  setSiteInfo: any,
  setAlertState: any,
  setPatchObject: any,
  siteInfo: any,
  navigate: any
) => {
  setBackdropLoadingScreenOn(true);
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleActivateSite(
      setBackdropLoadingScreenOn,
      patchObject,
      setSiteInfo,
      setAlertState,
      setPatchObject,
      siteInfo,
      navigate
    );
  };
  await AxiosUI.patch(`customer/${siteInfo.id}/about/`, { active: true }).then(
    () => {
      setPatchObject({ name: siteInfo.name });
      setBackdropLoadingScreenOn(false);
      setAlertState((previousState: any) => {
        return {
          ...previousState,
          changeSuccess: false,
          activateSuccess: true,
          deactivateSuccess: false,
          disarmSuccess: false,
          armSuccess: false,
          syncSuccess: false,
          cameraDeleteSuccess: false,
          armFail: false,
        };
      });
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error activating site, please try again");
        setSiteInfo(JSON.parse(localStorage.siteInfo));
        setBackdropLoadingScreenOn(false);
        setPatchObject({ name: siteInfo.name });
      });
    }
  );
};
