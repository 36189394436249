import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getPreviewURL = (
  cameraId: string,
  setLoadingImage: Dispatcher<boolean>,
  setPreviewURL: any,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    getPreviewURL(cameraId, setLoadingImage, setPreviewURL, navigate);
  };

  const AxiosUI = createApi(``);

  AxiosUI.get(`/camera/${cameraId}/status/`).then(
    (response: AxiosResponse) => {
      setPreviewURL(response.data.preview_url);
      setLoadingImage(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setPreviewURL("");
        setLoadingImage(false);
      });
    }
  );
};
