import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

const filterData = (
  camera_id: number,
  cameraStatsData: any,
  alertData: any
) => {
  const updatedCountObject: any = {};
  cameraStatsData.forEach((object: any) => {
    object.connectivity = 0;
    object.scene_change = 0;
    object.recording_status = 0;
    object.motion_status = 0;
    object.image_quality = 0;
    object.stream_quality = 0;
    object.license_info = 0;
    object.total = 0;
    updatedCountObject[`${object.camera_name}${object.site_name}`] = object;
  });
  alertData.forEach((object: any) => {
    if (updatedCountObject[`${object.camera_name}${object.site_name}`]) {
      if (object.error_type === "connectivity") {
        updatedCountObject[
          `${object.camera_name}${object.site_name}`
        ].connectivity =
          updatedCountObject[`${object.camera_name}${object.site_name}`]
            .connectivity + 1;
        updatedCountObject[`${object.camera_name}${object.site_name}`].total =
          updatedCountObject[`${object.camera_name}${object.site_name}`].total +
          1;
      }
      if (object.error_type === "scene_change") {
        updatedCountObject[
          `${object.camera_name}${object.site_name}`
        ].scene_change =
          updatedCountObject[`${object.camera_name}${object.site_name}`]
            .scene_change + 1;
        updatedCountObject[`${object.camera_name}${object.site_name}`].total =
          updatedCountObject[`${object.camera_name}${object.site_name}`].total +
          1;
      }
      if (object.error_type === "recording_status") {
        updatedCountObject[
          `${object.camera_name}${object.site_name}`
        ].recording_status =
          updatedCountObject[`${object.camera_name}${object.site_name}`]
            .recording_status + 1;
        updatedCountObject[`${object.camera_name}${object.site_name}`].total =
          updatedCountObject[`${object.camera_name}${object.site_name}`].total +
          1;
      }
      if (object.error_type === "motion_status") {
        updatedCountObject[
          `${object.camera_name}${object.site_name}`
        ].motion_status =
          updatedCountObject[`${object.camera_name}${object.site_name}`]
            .motion_status + 1;
        updatedCountObject[`${object.camera_name}${object.site_name}`].total =
          updatedCountObject[`${object.camera_name}${object.site_name}`].total +
          1;
      }
      if (object.error_type === "image_quality") {
        updatedCountObject[
          `${object.camera_name}${object.site_name}`
        ].image_quality =
          updatedCountObject[`${object.camera_name}${object.site_name}`]
            .image_quality + 1;
        updatedCountObject[`${object.camera_name}${object.site_name}`].total =
          updatedCountObject[`${object.camera_name}${object.site_name}`].total +
          1;
      }
      if (object.error_type === "stream_quality") {
        updatedCountObject[
          `${object.camera_name}${object.site_name}`
        ].stream_quality =
          updatedCountObject[`${object.camera_name}${object.site_name}`]
            .stream_quality + 1;
        updatedCountObject[`${object.camera_name}${object.site_name}`].total =
          updatedCountObject[`${object.camera_name}${object.site_name}`].total +
          1;
      }
      if (object.error_type === "license_info") {
        updatedCountObject[
          `${object.camera_name}${object.site_name}`
        ].license_info =
          updatedCountObject[`${object.camera_name}${object.site_name}`]
            .license_info + 1;
        updatedCountObject[`${object.camera_name}${object.site_name}`].total =
          updatedCountObject[`${object.camera_name}${object.site_name}`].total +
          1;
      }
    }
  });

  const returnData = [];
  for (const key in updatedCountObject) {
    returnData.push(updatedCountObject[key]);
  }

  if (camera_id) {
    return returnData.filter((camera: any) => camera.camera_id === camera_id);
  }

  return returnData;
};

export const createCameraStatsData = (
  cameraStatsData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: Dispatcher<boolean>,
  showSiteStats: boolean,
  userFilters: any,
  alertData: any
) => {
  let updatedCameraStatsData = [];
  if (!showSiteStats) {
    updatedCameraStatsData = filterData(
      Number(userFilters.camera),
      cameraStatsData,
      alertData
    );
    updatedCameraStatsData.forEach((object: any) => {
      if (object.camera_name.length > 11) {
        object.display_name = `${object.camera_name.slice(0, 10)}..`;
      }
      if (object.camera_name.length <= 11) {
        object.display_name = object.camera_name;
      }
      object.name = object.camera_name;
    });
  } else {
    const cameraNamesArray: string[] = [];
    cameraStatsData.forEach((object: any) => {
      if (!cameraNamesArray.includes(object.site_name)) {
        cameraNamesArray.push(object.site_name);
      }
    });
    const temporarySiteObject: any = {};
    const createAbreviatedDisplayName = (inputString: string) => {
      if (inputString.length > 11) {
        return `${inputString.slice(0, 10)}..`;
      }
      if (inputString.length <= 11) {
        return inputString;
      }
    };
    cameraNamesArray.forEach((name: string) => {
      temporarySiteObject[name] = {
        name: name,
        display_name: createAbreviatedDisplayName(name),
        connectivity: 0,
        scene_change: 0,
        recording_status: 0,
        motion_status: 0,
        image_quality: 0,
        stream_quality: 0,
        total: 0,
      };
    });
    alertData.forEach((object: any) => {
      if (temporarySiteObject[`${object.site_name}`]) {
        if (object.error_type === "connectivity") {
          temporarySiteObject[`${object.site_name}`].connectivity =
            temporarySiteObject[`${object.site_name}`].connectivity + 1;
          temporarySiteObject[`${object.site_name}`].total =
            temporarySiteObject[`${object.site_name}`].total + 1;
        }
        if (object.error_type === "scene_change") {
          temporarySiteObject[`${object.site_name}`].scene_change =
            temporarySiteObject[`${object.site_name}`].scene_change + 1;
          temporarySiteObject[`${object.site_name}`].total =
            temporarySiteObject[`${object.site_name}`].total + 1;
        }
        if (object.error_type === "recording_status") {
          temporarySiteObject[`${object.site_name}`].recording_status =
            temporarySiteObject[`${object.site_name}`].recording_status + 1;
          temporarySiteObject[`${object.site_name}`].total =
            temporarySiteObject[`${object.site_name}`].total + 1;
        }
        if (object.error_type === "motion_status") {
          temporarySiteObject[`${object.site_name}`].motion_status =
            temporarySiteObject[`${object.site_name}`].motion_status + 1;
          temporarySiteObject[`${object.site_name}`].total =
            temporarySiteObject[`${object.site_name}`].total + 1;
        }
        if (object.error_type === "image_quality") {
          temporarySiteObject[`${object.site_name}`].image_quality =
            temporarySiteObject[`${object.site_name}`].image_quality + 1;
          temporarySiteObject[`${object.site_name}`].total =
            temporarySiteObject[`${object.site_name}`].total + 1;
        }
        if (object.error_type === "stream_quality") {
          temporarySiteObject[`${object.site_name}`].stream_quality =
            temporarySiteObject[`${object.site_name}`].stream_quality + 1;
          temporarySiteObject[`${object.site_name}`].total =
            temporarySiteObject[`${object.site_name}`].total + 1;
        }
        if (object.error_type === "license_info") {
          temporarySiteObject[`${object.site_name}`].license_info =
            temporarySiteObject[`${object.site_name}`].license_info + 1;
          temporarySiteObject[`${object.site_name}`].total =
            temporarySiteObject[`${object.site_name}`].total + 1;
        }
      }
    });
    for (const key in temporarySiteObject) {
      updatedCameraStatsData.push(temporarySiteObject[key]);
    }
  }
  setCameraStatsData(updatedCameraStatsData);
  setLoadingCameraStatsData(false);
};
