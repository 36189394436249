import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { nameSlicer } from "./formikContainer/nameSlicer";
import { Tooltip } from "@mui/material";
import Link from "@mui/material/Link";
import { getIndiviualGroup } from "../pages/Sites/SitesPage/SitesPageUtils/getIndiviualGroup";
import { useNavigate } from "react-router-dom";
import { handleSetSelectedIndexLevel } from "../pages/Sites/SitesPage/SitesPageUtils/handleSetSelectedIndexLevel";

export const SiteHierarchyBreadcrumbs = ({
  siteBreadcrumbs,
  setRenderedRows,
  setPageLoading,
  setSiteBreadcrumbs,
  setSelectedIndex,
}: {
  siteBreadcrumbs: any;
  setRenderedRows: any;
  setPageLoading: any;
  setSiteBreadcrumbs: any;
  setSelectedIndex: any;
}) => {
  const navigate = useNavigate();
  // const [idMatch, setIdMatch] = React.useState(false);
  // let idMatch = false;

  return (
    <Stack spacing={2}>
      <div>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="large" />}
          aria-label="breadcrumb"
        >
          (
          <Typography color="text.primary" fontSize="18px" fontFamily="Mulish">
            {siteBreadcrumbs[0] ? siteBreadcrumbs[0].name : ""}
          </Typography>
          );
          {/* {siteBreadcrumbs.map((obj: any, index: any) => {
            if (index === 0 && obj.id !== Number(localStorage.clickedId)) {
              return (
                <Tooltip title={obj.name} placement="top">
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "20px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      localStorage.setItem("clickedId", obj.id);
                      setSiteBreadcrumbs("");
                      getIndiviualGroup(
                        obj.id,
                        setRenderedRows,
                        setPageLoading,
                        setSiteBreadcrumbs,
                        navigate
                      );
                      handleSetSelectedIndexLevel(
                        index,
                        "level1",
                        obj.id,
                        setSelectedIndex
                      );
                    }}
                  >
                    {nameSlicer(obj.name)}
                  </Link>
                </Tooltip>
              );
            } else if (
              index === 0 &&
              obj.id === Number(localStorage.clickedId)
            ) {
              idMatch = true;
              return (
                <Typography
                  key={obj.name}
                  color="text.primary"
                  fontSize="18px"
                  fontFamily="Mulish"
                >
                  {obj.name}
                </Typography>
              );
            } else if (
              index === 1 &&
              obj.id !== Number(localStorage.clickedId) &&
              !idMatch
            ) {
              return (
                <Tooltip title={obj.name} placement="top">
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "20px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      localStorage.setItem("clickedId", obj.id);
                      setSiteBreadcrumbs("");
                      getIndiviualGroup(
                        obj.id,
                        setRenderedRows,
                        setPageLoading,
                        setSiteBreadcrumbs,
                        navigate
                      );
                      handleSetSelectedIndexLevel(
                        index,
                        "level2",
                        obj.id,
                        setSelectedIndex
                      );
                    }}
                  >
                    {nameSlicer(obj.name)}
                  </Link>
                </Tooltip>
              );
            } else if (
              index === 1 &&
              obj.id === Number(localStorage.clickedId) &&
              !idMatch
            ) {
              idMatch = true;
              return (
                <Typography
                  key={obj.name}
                  color="text.primary"
                  fontSize="18px"
                  fontFamily="Mulish"
                >
                  {obj.name}
                </Typography>
              );
            } else if (
              index === 2 &&
              obj.id !== Number(localStorage.clickedId) &&
              !idMatch
            ) {
              return (
                <Tooltip title={obj.name} placement="top">
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "20px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      localStorage.setItem("clickedId", obj.id);
                      setSiteBreadcrumbs("");
                      getIndiviualGroup(
                        obj.id,
                        setRenderedRows,
                        setPageLoading,
                        setSiteBreadcrumbs,
                        navigate
                      );
                      handleSetSelectedIndexLevel(
                        index,
                        "level3",
                        obj.id,
                        setSelectedIndex
                      );
                    }}
                  >
                    {nameSlicer(obj.name)}
                  </Link>
                </Tooltip>
              );
            } else if (
              index === 2 &&
              obj.id === Number(localStorage.clickedId) &&
              !idMatch
            ) {
              idMatch = true;
              return (
                <Typography
                  key={obj.name}
                  color="text.primary"
                  fontSize="18px"
                  fontFamily="Mulish"
                >
                  {obj.name}
                </Typography>
              );
            } else if (
              index === 3 &&
              obj.id !== Number(localStorage.clickedId) &&
              !idMatch
            ) {
              return (
                <Tooltip title={obj.name} placement="top">
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "20px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      localStorage.setItem("clickedId", obj.id);
                      setSiteBreadcrumbs("");
                      getIndiviualGroup(
                        obj.id,
                        setRenderedRows,
                        setPageLoading,
                        setSiteBreadcrumbs,
                        navigate
                      );
                      handleSetSelectedIndexLevel(
                        index,
                        "level4",
                        obj.id,
                        setSelectedIndex
                      );
                    }}
                  >
                    {nameSlicer(obj.name)}
                  </Link>
                </Tooltip>
              );
            } else if (
              index === 3 &&
              obj.id === Number(localStorage.clickedId) &&
              !idMatch
            ) {
              idMatch = true;
              return (
                <Typography
                  key={obj.name}
                  color="text.primary"
                  fontSize="18px"
                  fontFamily="Mulish"
                >
                  {obj.name}
                </Typography>
              );
            } else if (
              index === 4 &&
              obj.id !== Number(localStorage.clickedId) &&
              !idMatch
            ) {
              return (
                <Tooltip title={obj.name} placement="top">
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "20px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      localStorage.setItem("clickedId", obj.id);
                      setSiteBreadcrumbs("");
                      getIndiviualGroup(
                        obj.id,
                        setRenderedRows,
                        setPageLoading,
                        setSiteBreadcrumbs,
                        navigate
                      );
                      handleSetSelectedIndexLevel(
                        index,
                        "level5",
                        obj.id,
                        setSelectedIndex
                      );
                    }}
                  >
                    {nameSlicer(obj.name)}
                  </Link>
                </Tooltip>
              );
            } else if (
              index === 4 &&
              obj.id === Number(localStorage.clickedId) &&
              !idMatch
            ) {
              idMatch = true;
              return (
                <Typography
                  key={obj.name}
                  color="text.primary"
                  fontSize="18px"
                  fontFamily="Mulish"
                >
                  {obj.name}
                </Typography>
              );
            } else if (
              index === 5 &&
              obj.id !== Number(localStorage.clickedId) &&
              !idMatch
            ) {
              return (
                <Tooltip title={obj.name} placement="top">
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "20px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      localStorage.setItem("clickedId", obj.id);
                      setSiteBreadcrumbs("");
                      getIndiviualGroup(
                        obj.id,
                        setRenderedRows,
                        setPageLoading,
                        setSiteBreadcrumbs,
                        navigate
                      );
                      handleSetSelectedIndexLevel(
                        index,
                        "level6",
                        obj.id,
                        setSelectedIndex
                      );
                    }}
                  >
                    {nameSlicer(obj.name)}
                  </Link>
                </Tooltip>
              );
            } else if (
              index === 5 &&
              obj.id === Number(localStorage.clickedId) &&
              !idMatch
            ) {
              idMatch = true;
              return (
                <Typography
                  key={obj.name}
                  color="text.primary"
                  fontSize="18px"
                  fontFamily="Mulish"
                >
                  {obj.name}
                </Typography>
              );
            } else if (index === 6 && !idMatch) {
              return (
                <Typography
                  key={obj.name}
                  color="text.primary"
                  fontSize="18px"
                  fontFamily="Mulish"
                >
                  {obj.name}
                </Typography>
              );
            }
          })} */}
        </Breadcrumbs>
      </div>
    </Stack>
  );
};
