"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "anchorRef", {
  enumerable: true,
  get: function get() {
    return _hooks.anchorRef;
  }
});
Object.defineProperty(exports, "bindContextMenu", {
  enumerable: true,
  get: function get() {
    return _hooks.bindContextMenu;
  }
});
Object.defineProperty(exports, "bindDialog", {
  enumerable: true,
  get: function get() {
    return _hooks.bindDialog;
  }
});
Object.defineProperty(exports, "bindDoubleClick", {
  enumerable: true,
  get: function get() {
    return _hooks.bindDoubleClick;
  }
});
Object.defineProperty(exports, "bindFocus", {
  enumerable: true,
  get: function get() {
    return _hooks.bindFocus;
  }
});
Object.defineProperty(exports, "bindHover", {
  enumerable: true,
  get: function get() {
    return _hooks.bindHover;
  }
});
Object.defineProperty(exports, "bindMenu", {
  enumerable: true,
  get: function get() {
    return _hooks.bindMenu;
  }
});
Object.defineProperty(exports, "bindPopover", {
  enumerable: true,
  get: function get() {
    return _hooks.bindPopover;
  }
});
Object.defineProperty(exports, "bindPopper", {
  enumerable: true,
  get: function get() {
    return _hooks.bindPopper;
  }
});
Object.defineProperty(exports, "bindToggle", {
  enumerable: true,
  get: function get() {
    return _hooks.bindToggle;
  }
});
Object.defineProperty(exports, "bindTrigger", {
  enumerable: true,
  get: function get() {
    return _hooks.bindTrigger;
  }
});
exports["default"] = PopupState;
var _propTypes = _interopRequireDefault(require("prop-types"));
var _hooks = require("./hooks");
function PopupState(_ref) {
  var children = _ref.children,
    popupId = _ref.popupId,
    variant = _ref.variant,
    parentPopupState = _ref.parentPopupState,
    disableAutoFocus = _ref.disableAutoFocus;
  var popupState = (0, _hooks.usePopupState)({
    popupId: popupId,
    variant: variant,
    parentPopupState: parentPopupState,
    disableAutoFocus: disableAutoFocus
  });
  var result = children(popupState);
  return result != null ? result : null;
}
PopupState.propTypes = {
  /**
   * The render function.
   *
   * @param {object} props the properties injected by `PopupState`:
   * <ul>
   *   <li>`open(eventOrAnchorEl)`: opens the popup</li>
   *   <li>`close()`: closes the popup</li>
   *   <li>`toggle(eventOrAnchorEl)`: opens the popup if it is closed, or
   *     closes the popup if it is open.
   *   </li>
   *   <li>`setOpen(open, [eventOrAnchorEl])`: sets whether the popup is open.
   *     `eventOrAnchorEl` is required if `open` is truthy.
   *   </li>
   *   <li>`isOpen`: `true`/`false` if the popup is open/closed</li>
   *   <li>`anchorEl`: the current anchor element (`null` the popup is closed)</li>
   *   <li>`popupId`: the `popupId` prop you passed</li>
   * </ul>
   *
   * @returns {React.Node} the content to display
   */
  children: _propTypes["default"].func.isRequired,
  /**
   * The `id` property to use for the popup.  Will be passed to the render
   * function as `bindPopup.id`, and also used for the `aria-controls` property
   * passed to the trigger component via `bindTrigger`.
   */
  popupId: _propTypes["default"].string,
  /**
   * Which type of popup you are controlling.  Use `'popover'` for `Popover`
   * and `Menu`; use `'popper'` for `Popper`s.  Right now this only affects
   * whether `aria-controls` or `aria-describedby` is used on the trigger
   * component.
   */
  variant: _propTypes["default"].oneOf(['popover', 'popper']).isRequired,
  /**
   * The popupState of the parent menu (for cascading menus)
   */
  parentPopupState: _propTypes["default"].object,
  /**
   * Will focus the popup when it opens unless disableAutoFocus is explicitly false.
   */
  disableAutoFocus: _propTypes["default"].bool
};