import SquareIcon from "@mui/icons-material/Square";

export const errorTypeCreator = (errorString: string) => {
  switch (errorString) {
    case "connectivity":
      return (
        <>
          <SquareIcon fontSize="small" sx={{ color: "#53B2AD" }} />
          &nbsp; Connectivity
        </>
      );
    case "scene_change":
      return (
        <>
          <SquareIcon fontSize="small" sx={{ color: "#E68B37" }} />
          &nbsp; Scene Change
        </>
      );
    case "recording_status":
      return (
        <>
          <SquareIcon fontSize="small" sx={{ color: "#CD4A81" }} />
          &nbsp; Recording Status
        </>
      );
    case "motion_status":
      return (
        <>
          <SquareIcon fontSize="small" sx={{ color: "#7F83F3" }} />
          &nbsp; Motion Status
        </>
      );
    case "image_quality":
      return (
        <>
          <SquareIcon fontSize="small" sx={{ color: "#8EDE79" }} />
          &nbsp; Image Quality
        </>
      );
    case "stream_quality":
      return (
        <>
          <SquareIcon fontSize="small" sx={{ color: "#3978EA" }} />
          &nbsp; Stream Quality
        </>
      );
    // case "license_info":
    //   return (
    //     <>
    //       <SquareIcon fontSize="small" sx={{ color: "#6A2BCA" }} />
    //       &nbsp; License Info
    //     </>
    //   );
  }
};
