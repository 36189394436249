import React from "react";

export const resetEditIgnoreZonesState = (
  setBreadCrumbs: any,
  setVisibleIgnoreZones: any,
  setIgnorezoneEditorVisible: any,
  setSelectedIgnoreZone: any,
  setEditsMade: any,
  setPageLoading: any,
  setDimensions: any,
  setAllCamerasData: any,
  setCameraIndex: any
) => {
  setBreadCrumbs([]);
  setVisibleIgnoreZones([]);
  setIgnorezoneEditorVisible(false);
  setSelectedIgnoreZone("");
  setEditsMade(false);
  setPageLoading(true);
  setDimensions({ width: 640, height: 360 });
  setAllCamerasData([]);
  setCameraIndex(0);
};
