import React from "react";

export const handleFilterByMetrics = (
  userFilters: any,
  healthCheckSites: any[]
) => {
  if (userFilters.metrics.length === 0) return healthCheckSites;

  const updatedHealthCheckSites: any[] = [];

  const filterTypes = [
    ["Connectivity", "connectivity"],
    ["Image Quality", "image_quality"],
    ["Scene Change", "scene_change"],
    ["Stream Quality", "stream_quality"],
    ["Motion Status", "motion_status"],
    ["Recording Status", "recording_status"],
  ];

  userFilters.metrics.forEach((metric: string) => {
    filterTypes.forEach((filterType: string[]) => {
      if (metric === filterType[0]) {
        healthCheckSites.forEach((site: any) => {
          if (site.error_type === filterType[1]) {
            updatedHealthCheckSites.push(site);
          }
        });
      }
    });
  });
  return updatedHealthCheckSites;
};
