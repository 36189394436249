export const integrationsMatcher = (type: string, input: string | number) => {
  const integrationTypes = JSON.parse(
    localStorage.integrationTypesNonOnboarding
  );
  const integrationIds = new Map();
  const integrationNames = new Map();
  const integrationFullNames = new Map();
  integrationTypes.forEach((element: any) => {
    integrationNames.set(`${element.display_name}`, element.id);
    integrationIds.set(element.id, `${element.display_name}`);
    integrationFullNames.set(`${element.name}`, `${element.display_name}`);
  });
  if (type === "Id") {
    return integrationNames.get(input);
  } else if (type === "Name") {
    return integrationIds.get(input);
  } else if (type === "FullName") {
    return integrationFullNames.get(input);
  }
};
