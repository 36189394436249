import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../../utils/createApi";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const getAndSaveSiteImages = async (
  siteCameras: any,
  setSrcImages: any,
  navigate: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getAndSaveSiteImages(siteCameras, setSrcImages, navigate);
  };

  siteCameras.results.forEach((obj: any) => {
    AxiosUI.get(`/camera/${obj.id}/status/`).then(
      (response: AxiosResponse) => {
        setSrcImages((previousState: any) => {
          const name = `${obj.id}full`;
          return {
            ...previousState,
            [obj.id]: <img src={response.data.preview_url} alt="site_image" />,
            [name]: response.data,
          };
        });
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
      }
    );
  });
};
