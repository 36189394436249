import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { TableHeadComponent } from "../../common/TableHead";
import { createCameraView } from "./EditCameraUtils/createCameraView";
import { createCameraAlarmType } from "./EditCameraUtils/createCameraAlarmType";

export const GeneralInfoEditCameraTab = ({
  siteInfo,
  cameraGeneralInfo,
}: {
  siteInfo: any;
  cameraGeneralInfo: any;
}) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 1000, fontFamily: "Mulish" }}
        aria-label="simple table"
      >
        <TableHeadComponent
          titlesArray={[
            "Customer",
            "Camera Name",
            "Camera View",
            "Alarm Type",
            "Active",
          ]}
        />
        <TableBody>
          <TableRow key={localStorage.currentCameraId}>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {siteInfo.parent_group.name}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {cameraGeneralInfo.camera_name}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {createCameraView(cameraGeneralInfo.camera_view)}
            </TableCell>
            {/* <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {cameraGeneralInfo.camera_type
                ? cameraGeneralInfo.camera_type.display_name
                : `N/A`}
            </TableCell> */}

            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {createCameraAlarmType(cameraGeneralInfo.default_alarm)}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
              {
                <Switch
                  checked={cameraGeneralInfo.active}
                  color="warning"
                  disabled={true}
                />
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
