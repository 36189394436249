import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { getSentinelClipsWithQuerry } from "./InvestigationUtilities/getSentinelClipsWithQuerry";

export const ApplyFiltersButtonSentinel = ({
  setSentinelData,
  setPageLoadingSentinel,
  setSelectedRowSentinel,
  setPrecisionRecallDataSentinel,
  setVideoURLSentinel,
  setVideoClipLoadingSentinel,
  setClipsIndexOrderSentinel,
  selectedRowSentinel,
  setNoDataToDisplaySentinel,
  setSiteOptionsSentinel,
  setLoadingSitesSentinel,
  userFiltersSentinel,
  setFetchingFilteredDataSentinel,
  setPageSentinel,
  setLoadingAdditionalData,
  setFiltersApplied,
  navigate,
}: {
  setSentinelData: any;
  setPageLoadingSentinel: Dispatcher<boolean>;
  setSelectedRowSentinel: any;
  setPrecisionRecallDataSentinel: any;
  setVideoURLSentinel: any;
  setVideoClipLoadingSentinel: Dispatcher<boolean>;
  setClipsIndexOrderSentinel: any;
  selectedRowSentinel: any;
  setNoDataToDisplaySentinel: Dispatcher<boolean>;
  setSiteOptionsSentinel: any;
  setLoadingSitesSentinel: any;
  userFiltersSentinel: any;
  setFetchingFilteredDataSentinel: Dispatcher<boolean>;
  setPageSentinel: any;
  setLoadingAdditionalData: Dispatcher<boolean>;
  setFiltersApplied: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  return (
    <button
      className="shadow-button bg-actuate-orange border-2	border-actuate-orange rounded font-mulish text-white text-base p-1.5 w-[120px]"
      onClick={() => {
        setFiltersApplied(true);
        setLoadingAdditionalData(true);
        setSentinelData([]);
        setPageSentinel(0);
        setNoDataToDisplaySentinel(true);
        setSelectedRowSentinel({});
        setPrecisionRecallDataSentinel({
          date: "",
          date_str: "",
          true_positives: 0,
          false_positives: 0,
          false_negatives: 0,
          true_negatives: 0,
          unclassified: 0,
          total_clips: 0,
          precision: null,
          recall: null,
        });
        setFetchingFilteredDataSentinel(true);
        localStorage.setItem("last_file_id_sentinal", "");
        localStorage.setItem("last_timestamp_sentinal", "");
        getSentinelClipsWithQuerry(
          setSentinelData,
          setPageLoadingSentinel,
          setSelectedRowSentinel,
          setPrecisionRecallDataSentinel,
          setVideoURLSentinel,
          setVideoClipLoadingSentinel,
          setClipsIndexOrderSentinel,
          selectedRowSentinel,
          setNoDataToDisplaySentinel,
          setSiteOptionsSentinel,
          setLoadingSitesSentinel,
          userFiltersSentinel,
          setFetchingFilteredDataSentinel,
          setPageSentinel,
          "",
          "",
          false,
          setLoadingAdditionalData,
          navigate
        );
      }}
      disabled={false}
    >
      Apply Filters
    </button>
  );
};
