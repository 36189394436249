import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxiosError } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleResendPassword = (
  groupUserId: any,
  setAlertState: any,
  setUpdatingGroupUserData: any,
  setUserNameString: Dispatcher<string>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleResendPassword(
      groupUserId,
      setAlertState,
      setUpdatingGroupUserData,
      setUserNameString,
      navigate
    );
  };

  AxiosUI.post(`group_user/${groupUserId}/resend_password/`).then(
    () => {
      setAlertState((previousState: any) => {
        return {
          ...previousState,
          updateUserSuccess: false,
          deleteUserSuccess: false,
          addUserSuccess: false,
          emailSent: true,
          noData: false,
        };
      });
      setUpdatingGroupUserData(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error sending email, please try again");
        setUpdatingGroupUserData(false);
      });
    }
  );
};
