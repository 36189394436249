import { GeneralInfoEditCameraTab } from "../GeneralInfoEditCameraTab";
import { ConnectionsTab } from "../ConnectionsTab";
import { NotificationsTab } from "../NotificationsTab";
import { ProductsTab } from "../ProductsTab";
import { ImmixAlertsTab } from "../ImmixAlertsTab";
import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { handleEditCameraCancelButtonClick } from "./handleEditCameraCancelButtonClick";
import { SureviewAlertsTab } from "../SureviewAlertsTab";
import { IgnoreZonesBreadCrumbs } from "../../EditIgnoreZones/IgnoreZonesBreadCrumbs";
import { createIgnoreZonesLinksArray } from "../../EditIgnoreZones/EditIgnoreZonesUtils/createIgnoreZonesLinksArray";
import { matchAllStreams } from "./numericallySortAlerts";
import { generateActiveProducts } from "./generateActiveProducts";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { CameraInfoRow } from "../../Sites/SiteAboutPage/CameraInfoRow";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { MobotixTab } from "../MobotixTab";
import ErrorBoundary from "../../../../ErrorBoundary";
import { WebhooksCameraTab } from "../WebhooksCameraTab";
import { AxisTab } from "../AxisTab";
import { SentinelAlertsTab } from "../SentinelAlertsTab";

export const handleEditCameraSaveChangesButtonClicked = async (
  setEditMode: any,
  clickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  cameraGeneralInfo: any,
  setBackdropLoadingScreenOn: any,
  generalInfoChangesMade: any,
  patchObject: any,
  setGeneralInfoChangesMade: any,
  setPatchObject: any,
  navigate: any,
  setCameraGeneralInfo: any,
  cameraGeneralInfoBackup: any,
  setCameraGeneralInfoBackup: any,
  immixAlerts: any,
  activeChangeMade: any,
  sureviewAlerts: any,
  setIgnoreZonesBreadCrumbs: any,
  handleSitesBreadcrumbClick: any,
  handleSingularSiteBreadcrumbClick: any,
  setAddImmixAlertDialogOpen: any,
  setAddSureviewAlertDialogOpen: any,
  activeProducts: any,
  setStreamsObject: any,
  setActiveProducts: any,
  setImmixAlertsOnCamera: any,
  setSureViewAlertsOnCamera: any,
  setSendingImmixTest: Dispatcher<boolean>,
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>,
  setImmixTestFailDialogOpen: Dispatcher<boolean>,
  setSrcImages: any,
  setCameraInfoRow: any,
  width: any,
  permissions: any,
  cameraWebhooksChangesMade: boolean,
  cameraWebhooksPatchObject: any,
  recipientsConfiguredData: any,
  titlesArray: any,
  setRecipientsConfiguredData: any,
  setTitlesArray: any,
  actionRulesData: any,
  setActionRulesData: any,
  actionRulesTitlesArray: any,
  setActionRulesTitlesArray: any,
  applicationsConfiguredData: any,
  axisFinished: any,
  timeTablesData: any,
  setTimeTablesData: any,
  iPNotifyData: any,
  setIPNotifyData: any,
  actionHandlerData: any,
  setActionHandlerData: any,
  cameraWebhookData: any,
  setCameraWebhookData: any,
  setCameraWebhooksPatchObject: any,
  setCameraWebhooksChangesMade: Dispatcher<boolean>,
  productsData: any,
  setProductsData: Dispatcher<any>,
  allTabsOpen: boolean,
  setAllTabsOpen: Dispatcher<boolean>,
  backupProductsData: any,
  setBackupProductsData: Dispatcher<any>,
  setSentinelAlertsOnCamera: Dispatcher<boolean>,
  sentinelAlerts: any,
  setAddSentinelAlertDialogOpen: Dispatcher<boolean>
) => {
  const AxiosUI = createApi(``);
  setBackdropLoadingScreenOn(true);
  const alarmOptions = JSON.parse(localStorage.alarmOptions);

  const alarmIds = new Map();
  alarmOptions.forEach((object: any) => {
    alarmIds.set(object.value, object.name);
  });

  const retryFunction = () => {
    handleEditCameraSaveChangesButtonClicked(
      setEditMode,
      clickedTab,
      setActiveTab,
      siteInfo,
      cameraGeneralInfo,
      setBackdropLoadingScreenOn,
      generalInfoChangesMade,
      patchObject,
      setGeneralInfoChangesMade,
      setPatchObject,
      navigate,
      setCameraGeneralInfo,
      cameraGeneralInfoBackup,
      setCameraGeneralInfoBackup,
      immixAlerts,
      activeChangeMade,
      sureviewAlerts,
      setIgnoreZonesBreadCrumbs,
      handleSitesBreadcrumbClick,
      handleSingularSiteBreadcrumbClick,
      setAddImmixAlertDialogOpen,
      setAddSureviewAlertDialogOpen,
      activeProducts,
      setStreamsObject,
      setActiveProducts,
      setImmixAlertsOnCamera,
      setSureViewAlertsOnCamera,
      setSendingImmixTest,
      setImmixTestSuccessDialogOpen,
      setImmixTestFailDialogOpen,
      setSrcImages,
      setCameraInfoRow,
      width,
      permissions,
      cameraWebhooksChangesMade,
      cameraWebhooksPatchObject,
      recipientsConfiguredData,
      titlesArray,
      setRecipientsConfiguredData,
      setTitlesArray,
      actionRulesData,
      setActionRulesData,
      actionRulesTitlesArray,
      setActionRulesTitlesArray,
      applicationsConfiguredData,
      axisFinished,
      timeTablesData,
      setTimeTablesData,
      iPNotifyData,
      setIPNotifyData,
      actionHandlerData,
      setActionHandlerData,
      cameraWebhookData,
      setCameraWebhookData,
      setCameraWebhooksPatchObject,
      setCameraWebhooksChangesMade,
      productsData,
      setProductsData,
      allTabsOpen,
      setAllTabsOpen,
      backupProductsData,
      setBackupProductsData,
      setSentinelAlertsOnCamera,
      sentinelAlerts,
      setAddSentinelAlertDialogOpen
    );
  };

  if (cameraWebhooksChangesMade) {
    AxiosUI.patch(
      `/webhook/${cameraWebhookData.id}/`,
      cameraWebhooksPatchObject
    ).then(
      () => {
        const webHookEventTypeOptions = JSON.parse(
          localStorage.webHookEventTypeOptions
        );

        const webHookEventTypeValue = new Map();
        webHookEventTypeOptions.forEach((option: any) => {
          webHookEventTypeValue.set(option.id, option.value);
        });

        const newCameraWebhookData = cameraWebhookData;

        newCameraWebhookData.custom_fields =
          cameraWebhooksPatchObject.custom_fields;

        newCameraWebhookData.event_types = [
          webHookEventTypeValue.get(cameraWebhooksPatchObject.event_types[0]),
        ];

        setCameraWebhookData(newCameraWebhookData);

        setCameraWebhooksPatchObject({});

        setCameraWebhooksChangesMade(false);

        setEditMode(false);
        if (!activeChangeMade) {
          setBackdropLoadingScreenOn(false);
        }
        if (clickedTab === "one") {
          setActiveTab(
            <GeneralInfoEditCameraTab
              siteInfo={siteInfo}
              cameraGeneralInfo={cameraGeneralInfo}
            />
          );
        } else if (clickedTab === "two") {
          setActiveTab(
            <ConnectionsTab cameraGeneralInfo={cameraGeneralInfo} />
          );
        } else if (clickedTab === "three") {
          setActiveTab(
            <NotificationsTab cameraGeneralInfo={cameraGeneralInfo} />
          );
        } else if (clickedTab === "four") {
          setActiveTab(
            <ProductsTab
              productsData={productsData}
              setProductsData={setProductsData}
              allTabsOpen={allTabsOpen}
              setAllTabsOpen={setAllTabsOpen}
              setBackupProductsData={setBackupProductsData}
            />
          );
        } else if (clickedTab === "five") {
          setActiveTab(
            <ImmixAlertsTab
              immixAlerts={immixAlerts}
              setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
              setSendingImmixTest={setSendingImmixTest}
              setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
              setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
              navigate={navigate}
            />
          );
        } else if (clickedTab === "six") {
          setActiveTab(
            <SureviewAlertsTab
              sureviewAlerts={sureviewAlerts}
              setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
            />
          );
        } else if (clickedTab === "seven") {
          setActiveTab(
            <AxisTab
              recipientsConfiguredData={recipientsConfiguredData}
              titlesArray={titlesArray}
              navigate={navigate}
              setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
              setRecipientsConfiguredData={setRecipientsConfiguredData}
              setTitlesArray={setTitlesArray}
              actionRulesData={actionRulesData}
              setActionRulesData={setActionRulesData}
              actionRulesTitlesArray={actionRulesTitlesArray}
              setActionRulesTitlesArray={setActionRulesTitlesArray}
              setActiveTab={setActiveTab}
              applicationsConfiguredData={applicationsConfiguredData}
              cameraGeneralInfo={cameraGeneralInfo}
              axisFinished={axisFinished}
            />
          );
        } else if (clickedTab === "eight") {
          setActiveTab(
            <MobotixTab
              timeTablesData={timeTablesData}
              navigate={navigate}
              setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
              setTimeTablesData={setTimeTablesData}
              setActiveTab={setActiveTab}
              iPNotifyData={iPNotifyData}
              setIPNotifyData={setIPNotifyData}
              actionHandlerData={actionHandlerData}
              setActionHandlerData={setActionHandlerData}
            />
          );
        } else if (clickedTab === "nine") {
          setActiveTab(
            <ErrorBoundary>
              <WebhooksCameraTab cameraWebhookData={newCameraWebhookData} />
            </ErrorBoundary>
          );
        } else if (clickedTab === "ten") {
          setActiveTab(
            <SentinelAlertsTab
              sentinelAlerts={sentinelAlerts}
              setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
            />
          );
        }
        setBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Failed to submit Webhook changes, please try again.");
          setCameraWebhooksPatchObject({});
          setCameraWebhooksChangesMade(false);
          setActiveTab(
            <ErrorBoundary>
              <WebhooksCameraTab cameraWebhookData={cameraWebhookData} />
            </ErrorBoundary>
          );
          setBackdropLoadingScreenOn(false);
        });
      }
    );
  } else if (generalInfoChangesMade) {
    let postPatchObjectFail = false;
    let getCameraInfoFail = false;
    let getSiteCamerasFail = false;
    let deployFail = false;
    await AxiosUI.patch(
      `/camera/${localStorage.currentCameraId}/general_info/`,
      patchObject
    ).then(
      () => {
        postPatchObjectFail = false;
        localStorage.setItem("rebootSite", "true");
      },
      (reason: AxiosError) => {
        postPatchObjectFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setEditMode(false);
          setCameraGeneralInfo(cameraGeneralInfoBackup);
          setPatchObject({
            camera_name: cameraGeneralInfoBackup.camera_name,
            streams: cameraGeneralInfoBackup.streams,
          });
          if (clickedTab === "one") {
            setActiveTab(
              <GeneralInfoEditCameraTab
                siteInfo={siteInfo}
                cameraGeneralInfo={cameraGeneralInfoBackup}
              />
            );
          } else if (clickedTab === "two") {
            setActiveTab(
              <ConnectionsTab cameraGeneralInfo={cameraGeneralInfoBackup} />
            );
          } else if (clickedTab === "three") {
            setActiveTab(
              <NotificationsTab cameraGeneralInfo={cameraGeneralInfoBackup} />
            );
          } else if (clickedTab === "four") {
            setActiveTab(
              <ProductsTab
                productsData={productsData}
                setProductsData={setProductsData}
                allTabsOpen={allTabsOpen}
                setAllTabsOpen={setAllTabsOpen}
                setBackupProductsData={setBackupProductsData}
              />
            );
          } else if (clickedTab === "five") {
            setActiveTab(
              <ImmixAlertsTab
                immixAlerts={immixAlerts}
                setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
                setSendingImmixTest={setSendingImmixTest}
                setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
                setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
                navigate={navigate}
              />
            );
          } else if (clickedTab === "six") {
            setActiveTab(
              <SureviewAlertsTab
                sureviewAlerts={sureviewAlerts}
                setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
              />
            );
          } else if (clickedTab === "seven") {
            setActiveTab(
              <AxisTab
                recipientsConfiguredData={recipientsConfiguredData}
                titlesArray={titlesArray}
                navigate={navigate}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setRecipientsConfiguredData={setRecipientsConfiguredData}
                setTitlesArray={setTitlesArray}
                actionRulesData={actionRulesData}
                setActionRulesData={setActionRulesData}
                actionRulesTitlesArray={actionRulesTitlesArray}
                setActionRulesTitlesArray={setActionRulesTitlesArray}
                setActiveTab={setActiveTab}
                applicationsConfiguredData={applicationsConfiguredData}
                cameraGeneralInfo={cameraGeneralInfo}
                axisFinished={axisFinished}
              />
            );
          } else if (clickedTab === "eight") {
            setActiveTab(
              <MobotixTab
                timeTablesData={timeTablesData}
                navigate={navigate}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setTimeTablesData={setTimeTablesData}
                setActiveTab={setActiveTab}
                iPNotifyData={iPNotifyData}
                setIPNotifyData={setIPNotifyData}
                actionHandlerData={actionHandlerData}
                setActionHandlerData={setActionHandlerData}
              />
            );
          } else if (clickedTab === "nine") {
            setActiveTab(
              <ErrorBoundary>
                <WebhooksCameraTab cameraWebhookData={cameraWebhookData} />
              </ErrorBoundary>
            );
          } else if (clickedTab === "ten") {
            setActiveTab(
              <SentinelAlertsTab
                sentinelAlerts={sentinelAlerts}
                setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
              />
            );
          }
          setBackdropLoadingScreenOn(false);
          alert("Failed to submit changes.  Redo changes and try again");
        });
      }
    );
    !postPatchObjectFail &&
      (await AxiosUI.get(`/camera/${cameraGeneralInfo.id}/general_info/`).then(
        (response: AxiosResponse) => {
          getCameraInfoFail = false;
          setCameraGeneralInfo(response.data);
          const cameraObject = response.data;
          if (alarmIds.get(cameraObject.default_alarm) === "Immix") {
            setImmixAlertsOnCamera(true);
          }
          if (alarmIds.get(cameraObject.default_alarm) === "SureView") {
            setSureViewAlertsOnCamera(true);
          }
          if (alarmIds.get(cameraObject.default_alarm) === "Sentinel") {
            setSentinelAlertsOnCamera(true);
          }
          if (response.data.streams[0]) {
            const passedStreamObj: any = {};
            response.data.streams.forEach((object: any) => {
              passedStreamObj[object.id] = object;
              setStreamsObject(passedStreamObj);
            });
            generateActiveProducts(
              passedStreamObj,
              setActiveProducts,
              setProductsData,
              setBackupProductsData
            );

            response.data.streams.forEach((obj: any) => {
              if (obj.immix_alerts[0]) {
                //make a clone object of response
                if (obj.immix_alerts.length > 1) {
                  const newSortedObect = response.data;
                  //make a clone streams of clone object and run numericallySortAlerts on the clone stream object
                  const newSortedStreams = matchAllStreams(
                    newSortedObect.streams,
                    "immix_alerts"
                  );
                  //reassign clone object's streams property to the clone streams
                  newSortedObect.streams = newSortedStreams;
                  //store the clone object in local storage
                  setCameraGeneralInfo(newSortedObect);
                }
              }
              if (obj.sureview_alerts[0]) {
                //make a clone object of response
                if (obj.sureview_alerts.length > 1) {
                  const newSortedObect = response.data;
                  //make a clone streams of clone object and run numericallySortAlerts on the clone stream object
                  const newSortedStreams = matchAllStreams(
                    newSortedObect.streams,
                    "sureview_alerts"
                  );
                  //reassign clone object's streams property to the clone streams
                  newSortedObect.streams = newSortedStreams;
                  //store the clone object in local storage
                  localStorage.setItem(
                    "currentCameraGeneralInfo",
                    JSON.stringify(newSortedObect)
                  );
                }
              }
            });
          }
          setCameraInfoRow("");
          AxiosUI.get(`/camera/${cameraObject.id}/status/`).then(
            (response: AxiosResponse) => {
              const name = `${cameraObject.id}full`;
              setSrcImages((previousState: any) => {
                return {
                  ...previousState,
                  [cameraObject.id]: (
                    <img src={response.data.preview_url} alt="site_image" />
                  ),
                  [name]: response.data,
                };
              });
              setCameraInfoRow(
                <CameraInfoRow
                  obj={cameraObject}
                  expanded={true}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  screenWidth={width}
                  siteInfo={siteInfo}
                  permissions={permissions}
                  srcImages={{
                    [cameraObject.id]: (
                      <img src={response.data.preview_url} alt="site_image" />
                    ),
                    [name]: response.data,
                  }}
                />
              );
            },
            (reason: AxiosError) => {
              universalAPIErrorHandler(
                reason,
                navigate,
                retryFunction,
                () => {}
              );
            }
          );
          localStorage.setItem("currentCamera", response.data.camera_name);
          setCameraGeneralInfo(response.data);
          setCameraGeneralInfoBackup(response.data);
          setGeneralInfoChangesMade(false);
          setPatchObject({});
        },
        (reason: AxiosError) => {
          getCameraInfoFail = true;
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            if (clickedTab === "one") {
              setActiveTab(
                <GeneralInfoEditCameraTab
                  siteInfo={siteInfo}
                  cameraGeneralInfo={cameraGeneralInfo}
                />
              );
            } else if (clickedTab === "two") {
              setActiveTab(
                <ConnectionsTab cameraGeneralInfo={cameraGeneralInfo} />
              );
            } else if (clickedTab === "three") {
              setActiveTab(
                <NotificationsTab cameraGeneralInfo={cameraGeneralInfo} />
              );
            } else if (clickedTab === "four") {
              setActiveTab(
                <ProductsTab
                  productsData={productsData}
                  setProductsData={setProductsData}
                  allTabsOpen={allTabsOpen}
                  setAllTabsOpen={setAllTabsOpen}
                  setBackupProductsData={setBackupProductsData}
                />
              );
            } else if (clickedTab === "five") {
              setActiveTab(
                <ImmixAlertsTab
                  immixAlerts={immixAlerts}
                  setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
                  setSendingImmixTest={setSendingImmixTest}
                  setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
                  setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
                  navigate={navigate}
                />
              );
            } else if (clickedTab === "six") {
              setActiveTab(
                <SureviewAlertsTab
                  sureviewAlerts={sureviewAlerts}
                  setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
                />
              );
            } else if (clickedTab === "seven") {
              setActiveTab(
                <AxisTab
                  recipientsConfiguredData={recipientsConfiguredData}
                  titlesArray={titlesArray}
                  navigate={navigate}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setRecipientsConfiguredData={setRecipientsConfiguredData}
                  setTitlesArray={setTitlesArray}
                  actionRulesData={actionRulesData}
                  setActionRulesData={setActionRulesData}
                  actionRulesTitlesArray={actionRulesTitlesArray}
                  setActionRulesTitlesArray={setActionRulesTitlesArray}
                  setActiveTab={setActiveTab}
                  applicationsConfiguredData={applicationsConfiguredData}
                  cameraGeneralInfo={cameraGeneralInfo}
                  axisFinished={axisFinished}
                />
              );
            } else if (clickedTab === "eight") {
              setActiveTab(
                <MobotixTab
                  timeTablesData={timeTablesData}
                  navigate={navigate}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setTimeTablesData={setTimeTablesData}
                  setActiveTab={setActiveTab}
                  iPNotifyData={iPNotifyData}
                  setIPNotifyData={setIPNotifyData}
                  actionHandlerData={actionHandlerData}
                  setActionHandlerData={setActionHandlerData}
                />
              );
            } else if (clickedTab === "nine") {
              setActiveTab(
                <ErrorBoundary>
                  <WebhooksCameraTab cameraWebhookData={cameraWebhookData} />
                </ErrorBoundary>
              );
            } else if (clickedTab === "ten") {
              setActiveTab(
                <SentinelAlertsTab
                  sentinelAlerts={sentinelAlerts}
                  setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
                />
              );
            }
            setSrcImages("");
            setBackdropLoadingScreenOn(false);
          });
        }
      ));
    !postPatchObjectFail &&
      !getCameraInfoFail &&
      (await AxiosUI.get(
        `camera/site/?customer__id=${localStorage.siteId}`
      ).then(
        (response: AxiosResponse) => {
          getSiteCamerasFail = false;
          localStorage.setItem("siteCameras", JSON.stringify(response.data));
        },
        (reason: AxiosError) => {
          getSiteCamerasFail = true;
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
        }
      ));
    !postPatchObjectFail &&
      !getCameraInfoFail &&
      !getSiteCamerasFail &&
      (await AxiosUI.post(
        `customer/${localStorage.siteId}/action/deploy_next/`
      ).then(
        () => {
          localStorage.setItem("syncNeeded", "false");
          deployFail = false;
        },
        (reason: AxiosError) => {
          deployFail = true;
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
        }
      ));
    !postPatchObjectFail &&
      !getCameraInfoFail &&
      !getSiteCamerasFail &&
      !deployFail &&
      (await AxiosUI.get(`customer/${localStorage.siteId}/about/`).then(
        (response: AxiosResponse) => {
          localStorage.setItem("siteInfo", JSON.stringify(response.data));
          localStorage.setItem(
            "integrationType",
            response.data.integration_type
          );
          setIgnoreZonesBreadCrumbs(
            <IgnoreZonesBreadCrumbs
              linksArray={createIgnoreZonesLinksArray(
                response.data.breadcrumbs
              )}
              handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
              handleSingularSiteBreadcrumbClick={
                handleSingularSiteBreadcrumbClick
              }
              styling="absolute top-[10px] left-[30px]"
            />
          );
          setEditMode(false);
          if (!activeChangeMade) {
            setBackdropLoadingScreenOn(false);
          }
          if (clickedTab === "one") {
            setActiveTab(
              <GeneralInfoEditCameraTab
                siteInfo={siteInfo}
                cameraGeneralInfo={cameraGeneralInfo}
              />
            );
          } else if (clickedTab === "two") {
            setActiveTab(
              <ConnectionsTab cameraGeneralInfo={cameraGeneralInfo} />
            );
          } else if (clickedTab === "three") {
            setActiveTab(
              <NotificationsTab cameraGeneralInfo={cameraGeneralInfo} />
            );
          } else if (clickedTab === "four") {
            setActiveTab(
              <ProductsTab
                productsData={productsData}
                setProductsData={setProductsData}
                allTabsOpen={allTabsOpen}
                setAllTabsOpen={setAllTabsOpen}
                setBackupProductsData={setBackupProductsData}
              />
            );
          } else if (clickedTab === "five") {
            setActiveTab(
              <ImmixAlertsTab
                immixAlerts={immixAlerts}
                setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
                setSendingImmixTest={setSendingImmixTest}
                setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
                setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
                navigate={navigate}
              />
            );
          } else if (clickedTab === "six") {
            setActiveTab(
              <SureviewAlertsTab
                sureviewAlerts={sureviewAlerts}
                setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
              />
            );
          } else if (clickedTab === "seven") {
            setActiveTab(
              <AxisTab
                recipientsConfiguredData={recipientsConfiguredData}
                titlesArray={titlesArray}
                navigate={navigate}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setRecipientsConfiguredData={setRecipientsConfiguredData}
                setTitlesArray={setTitlesArray}
                actionRulesData={actionRulesData}
                setActionRulesData={setActionRulesData}
                actionRulesTitlesArray={actionRulesTitlesArray}
                setActionRulesTitlesArray={setActionRulesTitlesArray}
                setActiveTab={setActiveTab}
                applicationsConfiguredData={applicationsConfiguredData}
                cameraGeneralInfo={cameraGeneralInfo}
                axisFinished={axisFinished}
              />
            );
          } else if (clickedTab === "eight") {
            setActiveTab(
              <MobotixTab
                timeTablesData={timeTablesData}
                navigate={navigate}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setTimeTablesData={setTimeTablesData}
                setActiveTab={setActiveTab}
                iPNotifyData={iPNotifyData}
                setIPNotifyData={setIPNotifyData}
                actionHandlerData={actionHandlerData}
                setActionHandlerData={setActionHandlerData}
              />
            );
          } else if (clickedTab === "nine") {
            setActiveTab(
              <ErrorBoundary>
                <WebhooksCameraTab cameraWebhookData={cameraWebhookData} />
              </ErrorBoundary>
            );
          } else if (clickedTab === "ten") {
            setActiveTab(
              <SentinelAlertsTab
                sentinelAlerts={sentinelAlerts}
                setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
              />
            );
          }
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            if (clickedTab === "one") {
              setActiveTab(
                <GeneralInfoEditCameraTab
                  siteInfo={siteInfo}
                  cameraGeneralInfo={cameraGeneralInfo}
                />
              );
            } else if (clickedTab === "two") {
              setActiveTab(
                <ConnectionsTab cameraGeneralInfo={cameraGeneralInfo} />
              );
            } else if (clickedTab === "three") {
              setActiveTab(
                <NotificationsTab cameraGeneralInfo={cameraGeneralInfo} />
              );
            } else if (clickedTab === "four") {
              setActiveTab(
                <ProductsTab
                  productsData={productsData}
                  setProductsData={setProductsData}
                  allTabsOpen={allTabsOpen}
                  setAllTabsOpen={setAllTabsOpen}
                  setBackupProductsData={setBackupProductsData}
                />
              );
            } else if (clickedTab === "five") {
              setActiveTab(
                <ImmixAlertsTab
                  immixAlerts={immixAlerts}
                  setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
                  setSendingImmixTest={setSendingImmixTest}
                  setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
                  setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
                  navigate={navigate}
                />
              );
            } else if (clickedTab === "six") {
              setActiveTab(
                <SureviewAlertsTab
                  sureviewAlerts={sureviewAlerts}
                  setAddSureviewAlertDialogOpen={setAddSureviewAlertDialogOpen}
                />
              );
            } else if (clickedTab === "seven") {
              setActiveTab(
                <AxisTab
                  recipientsConfiguredData={recipientsConfiguredData}
                  titlesArray={titlesArray}
                  navigate={navigate}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setRecipientsConfiguredData={setRecipientsConfiguredData}
                  setTitlesArray={setTitlesArray}
                  actionRulesData={actionRulesData}
                  setActionRulesData={setActionRulesData}
                  actionRulesTitlesArray={actionRulesTitlesArray}
                  setActionRulesTitlesArray={setActionRulesTitlesArray}
                  setActiveTab={setActiveTab}
                  applicationsConfiguredData={applicationsConfiguredData}
                  cameraGeneralInfo={cameraGeneralInfo}
                  axisFinished={axisFinished}
                />
              );
            } else if (clickedTab === "eight") {
              setActiveTab(
                <MobotixTab
                  timeTablesData={timeTablesData}
                  navigate={navigate}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setTimeTablesData={setTimeTablesData}
                  setActiveTab={setActiveTab}
                  iPNotifyData={iPNotifyData}
                  setIPNotifyData={setIPNotifyData}
                  actionHandlerData={actionHandlerData}
                  setActionHandlerData={setActionHandlerData}
                />
              );
            } else if (clickedTab === "nine") {
              setActiveTab(
                <ErrorBoundary>
                  <WebhooksCameraTab cameraWebhookData={cameraWebhookData} />
                </ErrorBoundary>
              );
            } else if (clickedTab === "ten") {
              setActiveTab(
                <SentinelAlertsTab
                  sentinelAlerts={sentinelAlerts}
                  setAddSentinelAlertDialogOpen={setAddSentinelAlertDialogOpen}
                />
              );
            }
            setBackdropLoadingScreenOn(false);
          });
        }
      ));
  } else if (activeChangeMade) {
    let cameraActivateOrDeactivateFail = false;
    let deployFail = false;
    if (cameraGeneralInfo.active) {
      const submittedObject = {
        camera_ids: [localStorage.currentCameraId],
      };
      await AxiosUI.post(`camera/action/activate/`, submittedObject).then(
        () => {
          cameraActivateOrDeactivateFail = false;
          setEditMode(false);
        },
        (reason: AxiosError) => {
          cameraActivateOrDeactivateFail = true;
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setBackdropLoadingScreenOn(false);
            setCameraGeneralInfo((previousState: any) => {
              return {
                ...previousState,
                active: !cameraGeneralInfo.active,
              };
            });
          });
        }
      );
    }
    if (!cameraGeneralInfo.active) {
      const submittedObject = {
        camera_ids: [localStorage.currentCameraId],
      };
      await AxiosUI.post(`camera/action/deactivate/`, submittedObject).then(
        () => {
          cameraActivateOrDeactivateFail = false;
          setEditMode(false);
        },
        (reason: AxiosError) => {
          cameraActivateOrDeactivateFail = true;
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setBackdropLoadingScreenOn(false);
            setCameraGeneralInfo((previousState: any) => {
              return {
                ...previousState,
                active: !cameraGeneralInfo.active,
              };
            });
          });
        }
      );
    }
    !cameraActivateOrDeactivateFail &&
      (await AxiosUI.post(
        `customer/${localStorage.siteId}/action/deploy_next/`
      ).then(
        () => {
          localStorage.setItem("syncNeeded", "false");
          deployFail = false;
        },
        (reason: AxiosError) => {
          deployFail = true;
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
        }
      ));
    !cameraActivateOrDeactivateFail &&
      !deployFail &&
      (await AxiosUI.get(
        `camera/site/?customer__id=${localStorage.siteId}`
      ).then(
        (response: AxiosResponse) => {
          localStorage.setItem("siteCameras", JSON.stringify(response.data));
          setBackdropLoadingScreenOn(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setBackdropLoadingScreenOn(false);
          });
        }
      ));
  } else if (!activeChangeMade && !generalInfoChangesMade) {
    handleEditCameraCancelButtonClick(
      setEditMode,
      clickedTab,
      setActiveTab,
      siteInfo,
      cameraGeneralInfo,
      setCameraGeneralInfo,
      cameraGeneralInfoBackup,
      setPatchObject,
      immixAlerts,
      sureviewAlerts,
      setAddImmixAlertDialogOpen,
      setAddSureviewAlertDialogOpen,
      activeProducts,
      setBackdropLoadingScreenOn,
      setSendingImmixTest,
      setImmixTestSuccessDialogOpen,
      setImmixTestFailDialogOpen,
      recipientsConfiguredData,
      titlesArray,
      setRecipientsConfiguredData,
      setTitlesArray,
      actionRulesData,
      setActionRulesData,
      actionRulesTitlesArray,
      setActionRulesTitlesArray,
      applicationsConfiguredData,
      axisFinished,
      timeTablesData,
      setTimeTablesData,
      iPNotifyData,
      setIPNotifyData,
      actionHandlerData,
      setActionHandlerData,
      cameraWebhookData,
      productsData,
      setProductsData,
      allTabsOpen,
      setAllTabsOpen,
      backupProductsData,
      setBackupProductsData,
      sentinelAlerts,
      setAddSentinelAlertDialogOpen,
      navigate
    );
  } else {
    handleEditCameraCancelButtonClick(
      setEditMode,
      clickedTab,
      setActiveTab,
      siteInfo,
      cameraGeneralInfo,
      setCameraGeneralInfo,
      cameraGeneralInfoBackup,
      setPatchObject,
      immixAlerts,
      sureviewAlerts,
      setAddImmixAlertDialogOpen,
      setAddSureviewAlertDialogOpen,
      activeProducts,
      setBackdropLoadingScreenOn,
      setSendingImmixTest,
      setImmixTestSuccessDialogOpen,
      setImmixTestFailDialogOpen,
      recipientsConfiguredData,
      titlesArray,
      setRecipientsConfiguredData,
      setTitlesArray,
      actionRulesData,
      setActionRulesData,
      actionRulesTitlesArray,
      setActionRulesTitlesArray,
      applicationsConfiguredData,
      axisFinished,
      timeTablesData,
      setTimeTablesData,
      iPNotifyData,
      setIPNotifyData,
      actionHandlerData,
      setActionHandlerData,
      cameraWebhookData,
      productsData,
      setProductsData,
      allTabsOpen,
      setAllTabsOpen,
      backupProductsData,
      setBackupProductsData,
      sentinelAlerts,
      setAddSentinelAlertDialogOpen,
      navigate
    );
  }
};
