import React from "react";

export const formattedDateGenerator = (inputDate: Date) => {
  if (!inputDate) {
    return "";
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = inputDate.getFullYear();
  const month = monthNames[inputDate.getMonth()];
  const date = inputDate.getDate().toString().padStart(2, "0");

  return `${month}-${date}-${year}`;
};

// Function to take in metrics array and replace values with their lowercase underscored versions
export const formatMetrics = (metrics: string[]) => {
  const formattedMetrics = metrics.map((metric) => {
    return metric.toLowerCase().replace(" ", "_");
  });

  return formattedMetrics;
};

export const updateSearchParamValues = (
  userFilters: any,
  searchParams: any,
  setSearchParams: any,
  detectionType: string
) => {
  const params = new URLSearchParams(searchParams);

  if (userFilters.parent_group) {
    params.set("group", userFilters.parent_group);
  } else {
    params.delete("group");
  }
  if (userFilters.site) {
    params.set("site", userFilters.site);
  } else {
    params.delete("site");
  }
  if (userFilters.camera) {
    params.set("camera", userFilters.camera);
  } else {
    params.delete("camera");
  }
  if (userFilters.start_date) {
    params.set("start_date", formattedDateGenerator(userFilters.start_date));
  } else {
    params.delete("start_date");
  }
  if (userFilters.end_date) {
    params.set("end_date", formattedDateGenerator(userFilters.end_date));
  } else {
    params.delete("end_date");
  }
  if (userFilters.start_time) {
    params.set(
      "start_time",
      userFilters.start_time.toString().slice(16, 21).replace(":", "")
    );
  } else {
    params.delete("start_time");
  }
  if (userFilters.end_time) {
    params.set(
      "end_time",
      userFilters.end_time.toString().slice(16, 21).replace(":", "")
    );
  } else {
    params.delete("end_time");
  }
  if (userFilters.metrics) {
    if (userFilters.metrics.length > 0) {
      params.set("metrics", formatMetrics(userFilters.metrics).join(","));
    } else {
      params.delete("metrics");
    }
  }
  if (userFilters.filter_type) {
    params.set("filter_type", userFilters.filter_type);
  } else {
    params.delete("filter_type");
  }
  if (detectionType) {
    params.set("detection_type", detectionType);
  } else {
    params.delete("detection_type");
  }
  setSearchParams(params);
};
