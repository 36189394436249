// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getMobotixData = async (
  inputId: string,
  navigate: NavigateFunction,
  setTimeTablesData: Dispatcher<any>,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setIPNotifyData: Dispatcher<any>,
  setActionHandlerData: Dispatcher<any>,
  axisFinished: any,
  mobotixFinshed: any,
  setMobotixFinished: Dispatcher<any>
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getMobotixData(
      inputId,
      navigate,
      setTimeTablesData,
      setBackdropLoadingScreenOn,
      setIPNotifyData,
      setActionHandlerData,
      axisFinished,
      mobotixFinshed,
      setMobotixFinished
    );
  };

  let getTimeTableFail = false;
  let getIPNotifyFail = false;

  await AxiosUI.get(`/mobotix/${inputId}/timetable/`).then(
    (response: any) => {
      if (response.data[0].level_tag) {
        setMobotixFinished((previousState: any) => {
          return {
            ...previousState,
            timeTable: true,
          };
        });
      } else {
        getTimeTableFail = false;
        setTimeTablesData(response.data);
        setMobotixFinished((previousState: any) => {
          return {
            ...previousState,
            timeTable: true,
          };
        });
      }
    },
    (reason: AxiosError) => {
      getTimeTableFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setMobotixFinished((previousState: any) => {
          return {
            ...previousState,
            timeTable: true,
          };
        });
      });
    }
  );

  !getTimeTableFail &&
    (await AxiosUI.get(`/mobotix/${inputId}/ip_notify/`).then(
      (response: any) => {
        if (response.data[0].level_tag) {
          setMobotixFinished((previousState: any) => {
            return {
              ...previousState,
              iPNotify: true,
            };
          });
        } else {
          getIPNotifyFail = false;
          setIPNotifyData(response.data);
          setMobotixFinished((previousState: any) => {
            return {
              ...previousState,
              iPNotify: true,
            };
          });
        }
      },
      (reason: AxiosError) => {
        getIPNotifyFail = true;
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setMobotixFinished((previousState: any) => {
            return {
              ...previousState,
              iPNotify: true,
            };
          });
        });
      }
    ));
  !getTimeTableFail &&
    !getIPNotifyFail &&
    (await AxiosUI.get(`/mobotix/${inputId}/action_handler/`).then(
      (response: any) => {
        if (response.data[0].level_tag) {
          setMobotixFinished((previousState: any) => {
            return {
              ...previousState,
              actionHandler: true,
            };
          });
        } else {
          setActionHandlerData(response.data);
          setMobotixFinished((previousState: any) => {
            return {
              ...previousState,
              actionHandler: true,
            };
          });
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setMobotixFinished((previousState: any) => {
            return {
              ...previousState,
              actionHandler: true,
            };
          });
        });
      }
    ));
};
