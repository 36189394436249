import React, { useState } from "react";
import { Form, Formik } from "formik";
import BackButton from "./BackButton";
import SubmitButton from "./SubmitButton";
import HandSubmitAndAddAnotherCameraButton from "./HandleSubmitAndAddAnotherCameraButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Wizard = ({
  children,
  initialValues,
  onSubmit,
  setVisibleScreen,
  responseObject,
  errorState,
  choicesChanger,
  stepNumber,
  setStepNumber,
  setCameraCheckFinsihedAlertOpen,
  setGoodCameras,
  setConnectivityCameras,
  setLowResolutionCameras,
  setCameraCheckResultDownloadURL,
}: {
  children: any;
  initialValues: any;
  onSubmit: any;
  setVisibleScreen: any;
  responseObject: any;
  errorState: any;
  choicesChanger: any;
  stepNumber: any;
  setStepNumber: any;
  setCameraCheckFinsihedAlertOpen: any;
  setGoodCameras: any;
  setConnectivityCameras: any;
  setLowResolutionCameras: any;
  setCameraCheckResultDownloadURL: any;
}) => {
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const step: any = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next: any = (values: any) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values: any) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  React.useEffect(() => {
    localStorage.setItem("stepNumber", JSON.stringify(stepNumber));
  }, [stepNumber]);

  const handleSubmit = async (values: any, bag: any) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      <Form>
        <p className={"font-mulish text-[#283E6D]"}>
          Step {stepNumber + 1} of {totalSteps}
        </p>
        <br></br>
        {step}
        {location.href.includes("addcamera") &&
          localStorage.integrationType === "1" &&
          localStorage.firstCameraAdded === "true" &&
          stepNumber === 1 && (
            <FormControlLabel
              sx={{
                fontFamily: "Mulish",
                color: "#001943",
              }}
              label="Copy Values"
              control={
                <Checkbox
                  color="primary"
                  defaultChecked
                  onChange={(event: any, result: any) => {
                    if (result === true) {
                      localStorage.setItem("copyValues", "true");
                    } else {
                      localStorage.setItem("copyValues", "false");
                    }
                  }}
                />
              }
            />
          )}
        <div></div>
        <br></br>
        <div style={{ display: "flex" }}>
          {stepNumber > 0 && <BackButton previous={previous} />}
          <SubmitButton
            next={next}
            isLastStep={isLastStep}
            errorState={errorState}
            choicesChanger={choicesChanger}
            buttonDisabled={buttonDisabled}
            setButtonDisabled={setButtonDisabled}
          />
          {location.href.includes("addcamera") && (
            <HandSubmitAndAddAnotherCameraButton
              isLastStep={isLastStep}
              setVisibleScreen={setVisibleScreen}
              responseObject={responseObject}
              buttonDisabled={buttonDisabled}
              setCameraCheckFinsihedAlertOpen={setCameraCheckFinsihedAlertOpen}
              setGoodCameras={setGoodCameras}
              setConnectivityCameras={setConnectivityCameras}
              setLowResolutionCameras={setLowResolutionCameras}
              setCameraCheckResultDownloadURL={setCameraCheckResultDownloadURL}
            />
          )}
        </div>
      </Form>
    </Formik>
  );
};

export default Wizard;
