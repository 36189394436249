import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handleSubmitNewGroup = async (
  submitObject: any,
  navigate: any,
  setGroupTrees: any,
  setBackdropLoadingScreenOn: any,
  setGroupAddedAlert: any
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleSubmitNewGroup(
      submitObject,
      navigate,
      setGroupTrees,
      setBackdropLoadingScreenOn,
      setGroupAddedAlert
    );
  };
  setBackdropLoadingScreenOn(true);
  await AxiosUI.post(`/group/`, submitObject).then(
    () => {
      setGroupAddedAlert(true);
      AxiosUI.get(`group/?page_size=10000`).then(
        (response: AxiosResponse) => {
          localStorage.setItem(
            "parentGroups",
            JSON.stringify(response.data.results)
          );
          setBackdropLoadingScreenOn(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setBackdropLoadingScreenOn(false);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        alert("Error submitting new group, please try again");
      });
    }
  );
};
