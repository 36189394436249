import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleDisarmSite } from "./SiteAboutPageUtils/handleDiarmSite";
import { useNavigate } from "react-router-dom";
import { AlertState, SiteInfo } from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const DisarmSiteDialog = ({
  disarmDialogOpen,
  setDisarmDialogOpen,
  setSiteInfo,
  setBackdropLoadingScreenOn,
  setAlertState,
  setArmedStatus,
  setArmButtonDisabled,
}: {
  disarmDialogOpen: boolean;
  setDisarmDialogOpen: Dispatcher<boolean>;
  setSiteInfo: Dispatcher<SiteInfo>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  setArmedStatus: Dispatcher<boolean>;
  setArmButtonDisabled: any;
}) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={disarmDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {" Are you sure you want to disarm this site?"}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Site will rearm that the next scheduled arm time.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setSiteInfo((previousState: SiteInfo) => {
              return { ...previousState, armed: true };
            });
            setDisarmDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setDisarmDialogOpen(false);
            handleDisarmSite(
              setBackdropLoadingScreenOn,
              setSiteInfo,
              setAlertState,
              setArmedStatus,
              navigate,
              setArmButtonDisabled
            );
            setSiteInfo((previousState: SiteInfo) => {
              return { ...previousState, armed: false };
            });
          }}
          text="Disarm"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
