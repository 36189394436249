export const timeSinceConvertor = (epochNum: number) => {
  const currentTime = Math.floor(new Date().getTime() / 1000.0);
  const difference = currentTime - Math.floor(epochNum);
  if (difference < 60) {
    return `${difference} seconds ago`;
  }
  if (difference >= 60 && difference < 3600) {
    const minutesSince = Math.floor(difference / 60);
    if (minutesSince === 1) {
      return `${minutesSince} minute ago`;
    } else return `${minutesSince} minutes ago`;
  } else if (difference >= 3600 && difference < 86400) {
    const hoursSince = Math.floor(difference / 3600);
    if (hoursSince === 1) {
      return `${hoursSince} hour ago`;
    } else return `${hoursSince} hours ago`;
  } else if (difference >= 86400 && difference < 604800) {
    const daysSince = Math.floor(difference / 86400);
    if (daysSince === 1) {
      return `${daysSince} day ago`;
    } else return `${daysSince} days ago`;
  } else if (difference >= 604800 && difference < 7257600) {
    const monthsSince = Math.floor(difference / 604800);
    if (monthsSince === 1) {
      return `${monthsSince} month ago`;
    } else return `${monthsSince} months ago`;
  } else return "Over 1 year ago";
};
