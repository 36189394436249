import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleDeleteGroupUser } from "./GroupUsersUtils/handleDeleteGroupUser";

const DeleteGroupUserDialog = ({
  deleteGroupUserDialogOpen,
  setDeleteGroupUserDialogOpen,
  setAlertState,
  groupUserId,
  activeData,
  setActiveData,
  setUpdatingGroupUserData,
  navigate,
}: {
  deleteGroupUserDialogOpen: any;
  setDeleteGroupUserDialogOpen: Dispatcher<boolean>;
  setAlertState: any;
  groupUserId: any;
  activeData: any;
  setActiveData: any;
  setUpdatingGroupUserData: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  return (
    <Dialog open={deleteGroupUserDialogOpen}>
      <DialogTitle id="alert-dialog-title">
        {" Are you sure you want to delete this User?"}
      </DialogTitle>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setDeleteGroupUserDialogOpen(false);
            setUpdatingGroupUserData(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setDeleteGroupUserDialogOpen(false);
            handleDeleteGroupUser(
              groupUserId,
              setAlertState,
              setUpdatingGroupUserData,
              activeData,
              setActiveData,
              navigate
            );
          }}
          text="Delete User"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGroupUserDialog;
