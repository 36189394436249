import React from "react";
import { IgnoreZone } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const ActiveIgnoreZonesWindow = ({
  selectedAlertCameraInfo,
  ignoreZonesSelectedAlert,
  loadingIgnoreZonesInfo,
}: {
  selectedAlertCameraInfo: any;
  ignoreZonesSelectedAlert: any;
  loadingIgnoreZonesInfo: boolean;
}) => {
  const pointsCreator = (array: any[]) => {
    let returnString = ``;
    array.forEach((array: number[]) => {
      returnString += `${array[0] * 333},${array[1] * 187} `;
    });
    return returnString;
  };

  return (
    <div className="h-[260px] w-[350px]">
      {selectedAlertCameraInfo.preview_url && ignoreZonesSelectedAlert && (
        <div className="">
          <img
            className="w-[333px] h-[187px]"
            src={selectedAlertCameraInfo.preview_url}
            alt="TestCameraPhoto"
          />
          {ignoreZonesSelectedAlert.map((object: IgnoreZone) => {
            return (
              <div className="absolute top-[20px]" key={object.label}>
                {object.coordinates.map((array: number[], index: number) => {
                  return (
                    <svg
                      className="absolute top-[0px]"
                      key={array[0]}
                      width="333"
                      height="187"
                    >
                      <polygon
                        points={pointsCreator(object.coordinates[index])}
                        stroke={object.line_color}
                        strokeWidth="5"
                        fill={object.line_color}
                        fillOpacity="50%"
                      />
                    </svg>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {selectedAlertCameraInfo.preview_url && ignoreZonesSelectedAlert && (
        <div className="absolute top-[220px] w-[330px] h-[57px] overflow-auto">
          <div className="absolute left-0 top-[0px] min-h-[57px] w-[330px]">
            <div className="absolute left-[0px] top-[0px] w-[330px]">
              {ignoreZonesSelectedAlert.map((object: IgnoreZone) => {
                return (
                  <div key={object.label}>
                    <div
                      key={object.label}
                      style={{
                        height: 10,
                        width: 6,
                        background: `${object.line_color}`,
                      }}
                      className="absolute"
                    ></div>
                    <p className="font-mulish text-[10px] indent-5">
                      {object.label_display_name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {!selectedAlertCameraInfo.preview_url && !loadingIgnoreZonesInfo && (
        <div>
          <p className="font-mulish text-[12px]">
            Unable to Display Ignore Zones
          </p>
        </div>
      )}
    </div>
  );
};
