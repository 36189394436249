import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { OrangeTextButton } from "../../common/OrangeTextButton";

export const SureviewAlertsTab = ({
  sureviewAlerts,
  setAddSureviewAlertDialogOpen,
}: {
  sureviewAlerts: any;
  setAddSureviewAlertDialogOpen: any;
}) => {
  const serverOptions = [
    ["US SMTP Server", "us-smtp.sureviewops.com"],
    ["EU SMTP Server", "eu-smtp.sureviewops.com"],
  ];
  const optionNames = new Map();
  serverOptions.forEach((option: any) => {
    optionNames.set(option[1], option[0]);
  });

  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      <div className="min-w-[1150px] h-[50px] bg-white">
        <div className="absolute right-[70px] top-[10px]">
          {permissions.edit_cameras && (
            <OrangeTextButton
              onClick={() => {
                setAddSureviewAlertDialogOpen(true);
              }}
              text="+ Add Sureview Alert"
              disabled={false}
            />
          )}
        </div>
      </div>
      {sureviewAlerts[0] &&
        sureviewAlerts.map((object: any, index: any) => {
          return (
            <div key={object.id} className="h-[208px]">
              <div className="min-w-[1150px] h-[40px] bg-white">
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "black",
                    textIndent: "1vw",
                  }}
                >
                  Sureview Alert {index + 1}
                </Typography>
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 1000, fontFamily: "Mulish" }}
                    aria-label="simple table"
                  >
                    <TableHeadComponent
                      titlesArray={[
                        "Sureview Server",
                        "Sureview Subject",
                        "Sureview cc",
                        "Sureview Email",
                      ]}
                    />
                    <TableBody>
                      <TableRow key={object.id}>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.sureview_server
                            ? optionNames.get(object.sureview_server)
                            : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.sureview_subject
                            ? object.sureview_subject
                            : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.sureview_cc ? object.sureview_cc : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.sureview_email ? object.sureview_email : "--"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          );
        })}
    </div>
  );
};
