import SyncIcon from "@mui/icons-material/Sync";
import {
  AlertState,
  SiteSchedule,
  SiteInfo,
  SiteCameras,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { handleSyncSite } from "./SiteAboutPageUtils/handleSyncSite";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const SyncSiteButton = ({
  syncNeeded,
  setBackdropLoadingScreenOn,
  setSyncNeeded,
  setAlertState,
  siteInfo,
  setSiteInfo,
  setAllSiteSchedules,
  setActiveTab,
  vmsVariables,
  setSiteDeployed,
  setDeativateInquiryDialogOpen,
  setSiteCameras,
  camerasNotDeployed,
  setCamerasNotDeployed,
  navigate,
  setVmsVariables,
  setClickedTab,
  setArmButtonDisabled,
  setArmedStatus,
}: {
  syncNeeded: boolean;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setSyncNeeded: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  siteInfo: SiteInfo;
  setSiteInfo: Dispatcher<SiteInfo>;
  setAllSiteSchedules: Dispatcher<SiteSchedule[]>;
  setActiveTab: Dispatcher<JSX.Element>;
  vmsVariables: (string | string[] | undefined)[][];
  setSiteDeployed: Dispatcher<boolean>;
  setDeativateInquiryDialogOpen: Dispatcher<boolean>;
  setSiteCameras: Dispatcher<SiteCameras>;
  camerasNotDeployed: boolean;
  setCamerasNotDeployed: Dispatcher<boolean>;
  navigate: NavigateFunction;
  setVmsVariables: Dispatcher<(string | string[] | undefined)[][]>;
  setClickedTab: Dispatcher<string>;
  setArmButtonDisabled: any;
  setArmedStatus: any;
}) => {
  return (
    <button
      className={
        syncNeeded
          ? "shadow-button bg-actuate-blue rounded font-mulish text-white text-base	p-1.5 hover:bg-[#142D57]"
          : "shadow-button bg-actuate-grey rounded font-mulish text-white text-base	p-1.5"
      }
      onClick={() => {
        setBackdropLoadingScreenOn(true);
        setSyncNeeded(false);
        handleSyncSite(
          setBackdropLoadingScreenOn,
          setAlertState,
          siteInfo,
          setSiteInfo,
          setSyncNeeded,
          setAllSiteSchedules,
          setActiveTab,
          vmsVariables,
          setSiteDeployed,
          setDeativateInquiryDialogOpen,
          setSiteCameras,
          camerasNotDeployed,
          setCamerasNotDeployed,
          navigate,
          setVmsVariables,
          setClickedTab,
          setArmButtonDisabled,
          setArmedStatus
        );
      }}
      disabled={!syncNeeded}
    >
      <SyncIcon fontSize="small" />
      &nbsp; Sync Site &nbsp;
    </button>
  );
};
