export const handleSureviewServerInput = (
  camerasObject: any,
  setCamerasObject: any,
  inputString: string
) => {
  const newCamerasObject = camerasObject;

  for (const key in newCamerasObject) {
    newCamerasObject[key].sureview.sureview_server = inputString;
  }
  setCamerasObject(newCamerasObject);
};
