import React from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { AlertData, HeadCell } from "../../Alerts/AlertsUtils/alertsInterfaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { visuallyHidden } from "@mui/utils";
import Checkbox from "@mui/material/Checkbox";

type Order = "asc" | "desc";

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AlertData
  ) => void;
  order: Order;
  orderBy: string;
  onSelectAllClick: any;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    label: "Site Name",
  },
  {
    id: "armed",
    numeric: false,
    label: "Armed",
  },
  {
    id: "cameras",
    numeric: true,
    label: `Cameras`,
  },
  {
    id: "health",
    numeric: false,
    label: "Health",
  },
  {
    id: "optimal_settings_number",
    numeric: false,
    label: "Optimal Settings",
  },
  {
    id: "schedules",
    numeric: false,
    label: "Schedules",
  },
  {
    id: "bandwidth",
    numeric: false,
    label: "Bandwidth Usage",
  },
  {
    id: "deployed",
    numeric: false,
    label: "Synced",
  },
  {
    id: "active",
    numeric: false,
    label: "Active",
  },
];

const activeText =
  "A green checkmark indicates that the site is running. A red X indicates the site is off.";
const armedText =
  "A metric that shares if the site is scheduled to send alerts at the time of viewing or not. If the icon is a yellow or grey alert symbol, hovering over it will specify any issues that are present. A red X indicates the site is disarmed.";
const bandwidth_usageText =
  "How much data Actuate is receiving from the site. Normal usage should be less than 100 kbps per camera at the site.";
const healthText =
  "An indicator of healthy cameras.  If all cameras are healthy an green checkmark is displayed. If 1 or more cameras is not healthy the number of unhealthy cameras is displayed along with a red X.";
const optimalText =
  "A green checkmark indicates that all cameras have optimal settings. A warning symbol indicate that some cameras have acceptable but not optimal settings that may result in performance issues. A red X indicates that some cameras have bad settings that will likely result in performance issues.";

export const EnhancedSitesGroupTableHead = (props: EnhancedTableProps) => {
  const { onRequestSort, orderBy, order, onSelectAllClick } = props;
  const createSortHandler =
    (property: keyof AlertData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox color="primary" onChange={onSelectAllClick} />
        </TableCell>
        {headCells.map((headCell) => {
          let itemText = "";
          if (headCell.id === "active") {
            itemText = activeText;
          } else if (headCell.id === "armed") {
            itemText = armedText;
          } else if (headCell.id === "bandwidth") {
            itemText = bandwidth_usageText;
          } else if (headCell.id === "health") {
            itemText = healthText;
          } else if (headCell.id === "optimal_settings_number") {
            itemText = optimalText;
          }
          if (
            headCell.id === "armed" ||
            headCell.id === "health" ||
            headCell.id === "bandwidth" ||
            headCell.id === "active" ||
            headCell.id === "optimal_settings_number"
          ) {
            return (
              <TableCell
                sx={{
                  fontSize: "12px",
                  fontFamily: "mulish",
                  fontWeight: "Bold",
                  maxWidth: 50,
                }}
                style={{
                  minWidth:
                    headCell.id === "optimal_settings_number" ? 90 : 110,
                }}
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  &nbsp;
                  <Tooltip
                    title={itemText}
                    placement="top"
                    sx={{ fontSize: "12px", fontFamily: "mulish" }}
                  >
                    <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                  </Tooltip>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                sx={{
                  fontSize: "12px",
                  fontFamily: "mulish",
                  fontWeight: "Bold",
                }}
                style={{ maxWidth: 100 }}
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
