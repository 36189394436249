import React from "react";
import { createApi } from "../../../utils/createApi";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AlertsTableHealthMonitoring } from "../AlertsTableHealthMonitoring";
import { AxiosError, AxiosResponse } from "axios";
import { yyyymmddGenerator } from "../../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { createAlertsData } from "./createAlertsData";
import { createReturnedAlertsData } from "./createReturnedAlertsData";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import {
  filterAllObjects,
  filterErrorObjects,
  filterPendingObjects,
  filterResolvedObjects,
  filterWarningObjects,
} from "./filteringFunctions";
import { createErrorsOverviewData } from "./createErrorsOverviewData";
import { createCameraStatsData } from "./createCameraStatsData";
import {
  checkTimestamp,
  convertToMilitary,
  getCurrentTime,
} from "./getAllHMData";
import { handleFilterByMetrics } from "../../CameraCheck/handleFilterByMetrics";
import { handleAddSeverityToHealthCheckSites } from "./handleAddSeverityToHealthCheckSites";

export const handleRerunHealthCheck = (
  alertsArray: any,
  setAlertsData: any,
  setFectchingAllData: Dispatcher<boolean>,
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  clickedTab: string,
  setActiveTable: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setSortedData: any,
  singleCellActivated: boolean,
  activeName: any,
  setUpdatingStatus: Dispatcher<boolean>,
  filters: any,
  alertFilter: string,
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setSelectedRows: any,
  selectedRows: any,
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: any,
  cameraDropDownValue: any,
  navigate: NavigateFunction
) => {
  setUpdatingStatus(true);

  const siteIds: any[] = [];

  alertsArray.forEach((object: any) => {
    if (!siteIds.includes(object.site_id)) {
      siteIds.push(object.site_id);
    }
  });

  const functionalityAfterAllResolved = (
    resolvedOlderUnhealthyCamsData: any,
    cameraStatsData: any
  ) => {
    const healthCheckSitesSorted = handleFilterByMetrics(
      filters,
      resolvedOlderUnhealthyCamsData
    );

    healthCheckSitesSorted.sort((a: any, b: any) => {
      return (
        Number(
          `${b.formatted_start_date.slice(6, 10)}${b.formatted_start_date.slice(
            3,
            5
          )}${b.formatted_start_date.slice(0, 2)}${convertToMilitary(
            b.formatted_start_date.slice(11, 13),
            b.formatted_start_date.slice(19, 21)
          )}${b.formatted_start_date.slice(
            14,
            16
          )}${b.formatted_start_date.slice(17, 19)}`
        ) -
        Number(
          `${a.formatted_start_date.slice(6, 10)}${a.formatted_start_date.slice(
            3,
            5
          )}${a.formatted_start_date.slice(0, 2)}${convertToMilitary(
            a.formatted_start_date.slice(11, 13),
            a.formatted_start_date.slice(19, 21)
          )}${a.formatted_start_date.slice(
            14,
            16
          )}${a.formatted_start_date.slice(17, 19)}`
        )
      );
    });
    const filterDuplicatesAndUnhealthy = (arr: any[]) => {
      const uniqueCombinations: any = {};
      return arr.filter((obj) => {
        const key = obj.camera_id + obj.error_type + obj.created_timestamp;
        if (obj.status === "healthy") {
          return false;
        }
        if (uniqueCombinations[key]) {
          return false;
        } else {
          uniqueCombinations[key] = true;
          return true;
        }
      });
    };

    const sortedAndNonDuplictdHealthCheckSites = filterDuplicatesAndUnhealthy(
      healthCheckSitesSorted
    );

    const cameraFilteredData = sortedAndNonDuplictdHealthCheckSites.filter(
      (camera: any) => camera.camera_id === Number(filters.camera)
    );

    const newAlertsData = filters.camera
      ? {
          all: filterAllObjects(cameraFilteredData),
          errors: filterErrorObjects(cameraFilteredData),
          warnings: filterWarningObjects(cameraFilteredData),
          pending: filterPendingObjects(cameraFilteredData),
          resolved: filterResolvedObjects(cameraFilteredData),
        }
      : {
          all: filterAllObjects(sortedAndNonDuplictdHealthCheckSites),
          errors: filterErrorObjects(sortedAndNonDuplictdHealthCheckSites),
          warnings: filterWarningObjects(sortedAndNonDuplictdHealthCheckSites),
          pending: filterPendingObjects(sortedAndNonDuplictdHealthCheckSites),
          resolved: filterResolvedObjects(sortedAndNonDuplictdHealthCheckSites),
        };

    setAlertsData(newAlertsData);

    createErrorsOverviewData(newAlertsData, setErrorsOverviewData);

    createCameraStatsData(
      cameraStatsData,
      setCameraStatsData,
      setLoadingCameraStatsData,
      true,
      filters,
      newAlertsData.all
    );

    setFectchingAllData(false);
  };

  const retryFunction = () => {
    handleRerunHealthCheck(
      alertsArray,
      setAlertsData,
      setFectchingAllData,
      setUnhealthyCamsData,
      unhealthyCamsData,
      clickedTab,
      setActiveTable,
      setAlertStatusDialogOpen,
      setSelectedRow,
      setSelectedRowIndex,
      setSortedData,
      singleCellActivated,
      activeName,
      setUpdatingStatus,
      filters,
      alertFilter,
      setSceneChangeOutcomesDialogOpen,
      setBackgroundFrame,
      setDetectedFrame,
      setSelectedRows,
      selectedRows,
      setErrorsOverviewData,
      setCameraStatsData,
      setLoadingCameraStatsData,
      setBandwidthData,
      setLoadingBandwidthData,
      setShowSiteStats,
      cameraDropDownValue,
      navigate
    );
  };

  const AxiosUI = createApi(``);

  let searchQueryString = `?start_date=${yyyymmddGenerator(
    filters.start_date
  )}&end_date=${yyyymmddGenerator(filters.end_date)}`;

  if (filters.parent_group) {
    searchQueryString += `&group=${filters.parent_group}`;
  }

  if (filters.site) {
    searchQueryString += `&site=${filters.site}`;
  }

  if (filters.camera) {
    searchQueryString += `&camera=${filters.camera}`;
  }

  siteIds.forEach((id: string, index: number) => {
    AxiosUI.post(`/customer/${id}/task/`, {
      batch_id: "string",
      deployment_type: "string",
    }).then(
      () => {
        if (index === siteIds.length - 1) {
          AxiosUI.get(
            `healthcheck_result/unhealthy_cameras/${searchQueryString}`
          ).then((response: AxiosResponse) => {
            setUnhealthyCamsData(response.data);
            AxiosUI.get(`healthcheck_result/rollup/${searchQueryString}`).then(
              (response: AxiosResponse) => {
                setUnhealthyCamsData(response.data.unhealthy_cams);

                const cameraStatsData = response.data.camera_stats;

                const healthCheckSitesWithSeverity =
                  handleAddSeverityToHealthCheckSites(
                    response.data.healthcheck_sites
                  );

                const resolvedOlderUnhealthyCamsData = [
                  ...healthCheckSitesWithSeverity,
                ];

                if (resolvedOlderUnhealthyCamsData[0]) {
                  let finalIndex = 0;
                  let totalFound = 0;
                  resolvedOlderUnhealthyCamsData.forEach(
                    (alert: any, index) => {
                      if (alert.status === "pending") {
                        if (
                          (alert.error_type === "connectivity" &&
                            checkTimestamp(alert.start_timestamp)) ||
                          (alert.error_type === "scene_change" &&
                            checkTimestamp(alert.end_timestamp))
                        ) {
                          finalIndex = index;
                          totalFound++;
                        }
                      }
                    }
                  );
                  if (totalFound === 0) {
                    functionalityAfterAllResolved(
                      resolvedOlderUnhealthyCamsData,
                      cameraStatsData
                    );
                  } else {
                    resolvedOlderUnhealthyCamsData.forEach(
                      (alert: any, index: number) => {
                        const newAlert = { ...alert };
                        const previousAlert = { ...alert };
                        if (alert.status) {
                          if (alert.status === "pending") {
                            if (
                              (alert.error_type === "connectivity" &&
                                checkTimestamp(alert.start_timestamp)) ||
                              (alert.error_type === "scene_change" &&
                                checkTimestamp(alert.end_timestamp))
                            ) {
                              newAlert.status = "resolved";
                              // camsToResolve.push(newAlert);
                              newAlert.formatted_end_date = getCurrentTime();
                              if (alert.notes) {
                                newAlert.notes =
                                  alert.notes +
                                  " Resolved automatically after 24 hours.";
                              } else {
                                newAlert.notes =
                                  "Resolved automatically after 24 hours.";
                              }
                              AxiosUI.post(
                                `/healthcheck_result/update_healthcheck/?camera_id=${alert.camera_id}&created_timestamp=${alert.created_timestamp}&healthcheck_type=${alert.error_type}&status=${alert.status}`,
                                newAlert
                              ).then(
                                () => {
                                  resolvedOlderUnhealthyCamsData[index] =
                                    newAlert;
                                  if (index === finalIndex) {
                                    functionalityAfterAllResolved(
                                      resolvedOlderUnhealthyCamsData,
                                      cameraStatsData
                                    );
                                  }
                                },
                                (reason: AxiosError) => {
                                  // eslint-disable-next-line no-console
                                  console.log("Reason: ", reason);
                                  resolvedOlderUnhealthyCamsData[index] =
                                    previousAlert;
                                  if (index === finalIndex) {
                                    functionalityAfterAllResolved(
                                      resolvedOlderUnhealthyCamsData,
                                      cameraStatsData
                                    );
                                  }
                                }
                              );
                            }
                          }
                        }
                      }
                    );
                  }
                } else {
                  setAlertsData({
                    all: [],
                    errors: [],
                    warnings: [],
                    pending: [],
                    resolved: [],
                  });
                  setFectchingAllData(false);
                }
              },
              (reason: AxiosError) => {
                if (reason.request.status === 500) {
                  setLoadingCameraStatsData(false);
                } else {
                  universalAPIErrorHandler(
                    reason,
                    navigate,
                    retryFunction,
                    () => {
                      setFectchingAllData(false);
                      setLoadingCameraStatsData(false);
                    }
                  );
                }
              }
            );
            // AxiosUI.get(
            //   `healthcheck_result/healthcheck_sites/${searchQueryString}`
            // ).then(
            //   (response: AxiosResponse) => {
            //     const healthCheckSitesSorted = response.data;

            //     const convertToMilitary = (
            //       inputString: string,
            //       aMPMString: string
            //     ) => {
            //       if (aMPMString === "PM") {
            //         return `${Number(inputString) + 12}`;
            //       }
            //       if (aMPMString === "AM" && inputString !== "12") {
            //         return inputString;
            //       }
            //       if (aMPMString === "AM" && inputString === "12") {
            //         return "00";
            //       }
            //     };

            //     healthCheckSitesSorted.sort((a: any, b: any) => {
            //       return (
            //         Number(
            //           `${b.formatted_start_date.slice(
            //             6,
            //             10
            //           )}${b.formatted_start_date.slice(
            //             3,
            //             5
            //           )}${b.formatted_start_date.slice(
            //             0,
            //             2
            //           )}${convertToMilitary(
            //             b.formatted_start_date.slice(11, 13),
            //             b.formatted_start_date.slice(19, 21)
            //           )}${b.formatted_start_date.slice(
            //             14,
            //             16
            //           )}${b.formatted_start_date.slice(17, 19)}`
            //         ) -
            //         Number(
            //           `${a.formatted_start_date.slice(
            //             6,
            //             10
            //           )}${a.formatted_start_date.slice(
            //             3,
            //             5
            //           )}${a.formatted_start_date.slice(
            //             0,
            //             2
            //           )}${convertToMilitary(
            //             a.formatted_start_date.slice(11, 13),
            //             a.formatted_start_date.slice(19, 21)
            //           )}${a.formatted_start_date.slice(
            //             14,
            //             16
            //           )}${a.formatted_start_date.slice(17, 19)}`
            //         )
            //       );
            //     });
            //     setActiveTable(<></>);

            //     setTimeout(() => {
            //       setFectchingAllData(false);
            //       createAlertsData(
            //         healthCheckSitesSorted,
            //         setAlertsData,
            //         cameraDropDownValue
            //       );
            //       const newAlertsData = createReturnedAlertsData(
            //         healthCheckSitesSorted,
            //         cameraDropDownValue
            //       );
            //       setActiveTable(
            //         <AlertsTableHealthMonitoring
            //           alertsData={newAlertsData}
            //           setAlertStatusDialogOpen={setAlertStatusDialogOpen}
            //           setSelectedRow={setSelectedRow}
            //           setSelectedRowIndex={setSelectedRowIndex}
            //           setFectchingAllData={setFectchingAllData}
            //           setAlertsData={setAlertsData}
            //           setUnhealthyCamsData={setUnhealthyCamsData}
            //           unhealthyCamsData={unhealthyCamsData}
            //           clickedTab={clickedTab}
            //           setActiveTable={setActiveTable}
            //           setSortedData={setSortedData}
            //           singleCellActivated={singleCellActivated}
            //           activeName={activeName}
            //           setUpdatingStatus={setUpdatingStatus}
            //           filters={filters}
            //           alertFilter={alertFilter}
            //           setSceneChangeOutcomesDialogOpen={
            //             setSceneChangeOutcomesDialogOpen
            //           }
            //           setBackgroundFrame={setBackgroundFrame}
            //           setDetectedFrame={setDetectedFrame}
            //           setSelectedRows={setSelectedRows}
            //           selectedRows={selectedRows}
            //           setErrorsOverviewData={setErrorsOverviewData}
            //           setCameraStatsData={setCameraStatsData}
            //           setLoadingCameraStatsData={setLoadingCameraStatsData}
            //           setBandwidthData={setBandwidthData}
            //           setLoadingBandwidthData={setLoadingBandwidthData}
            //           setShowSiteStats={setShowSiteStats}
            //           cameraDropDownValue={cameraDropDownValue}
            //           navigate={navigate}
            //         />
            //       );
            //     }, 1);
            //   },
            //   (reason: AxiosError) => {
            //     if (reason.request.status === 500) {
            //       setLoadingCameraStatsData(false);
            //     } else {
            //       universalAPIErrorHandler(
            //         reason,
            //         navigate,
            //         retryFunction,
            //         () => {
            //           setFectchingAllData(false);
            //           setLoadingCameraStatsData(false);
            //         }
            //       );
            //     }
            //   }
            // );
          });
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Unable to rerun health check, please try again");
          setUpdatingStatus(false);
        });
      }
    );
  });
};
