import { getCookie } from "./getCookie";
import { createApi } from "./createApi";
import Cookies from "universal-cookie";
import { createRefreshApi } from "./createRefreshApi";

export const getRefreshToken = async (navigate: any, retryFunction: any) => {
  const ActuateUI = createRefreshApi(``);
  const refreshToken = getCookie("token_secret");
  const cookies = new Cookies();
  const article = { refresh_token: refreshToken };

  if (refreshToken) {
    await ActuateUI.post(`/auth/refresh/`, article)
      .then((response) => {
        const apiResponse = response.data;
        cookies.set("token", apiResponse.token, { path: "/", maxAge: 3600 });
        localStorage.setItem(`token`, apiResponse.token);
        cookies.set("token_secret", apiResponse.token_secret, {
          path: "/",
        });
        localStorage.setItem(`token_secret`, apiResponse.token_secret);
        localStorage.setItem("tokenRefreshing", "false");
        if (retryFunction) {
          retryFunction();
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("loggedIn");
        localStorage.setItem("tokenRefreshing", "false");
        navigate("/login");
      });
  } else {
    localStorage.setItem("tokenRefreshing", "false");
    localStorage.removeItem("loggedIn");
    navigate("/login");
  }
};
