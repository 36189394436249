import { productsMatcher } from "../../../utils/productsMatcher";

export const handleSelectAllClick = (
  camerasObject: any,
  setCamerasObject: any,
  productName: any,
  checked: boolean,
  checkboxesChecked: any,
  setCheckBoxesChecked: any,
  productsArray: any
) => {
  const productId = productsMatcher("Id", productName, productsArray);

  if (checked) {
    for (const name in checkboxesChecked) {
      if (name.includes(productName)) {
        setCheckBoxesChecked((previousState: any) => {
          return { ...previousState, [name]: true };
        });
      }
    }
    for (const key in camerasObject) {
      const returnObject = camerasObject[key];
      const currentProducts = camerasObject[key].products;
      if (currentProducts.includes(productId)) {
        return;
      } else {
        currentProducts.push(productId);
        returnObject.products = currentProducts;
        setCamerasObject((previousState: any) => {
          return { ...previousState, key: returnObject };
        });
      }
    }
  } else {
    for (const name in checkboxesChecked) {
      if (name.includes(productName)) {
        setCheckBoxesChecked((previousState: any) => {
          return { ...previousState, [name]: false };
        });
      }
    }
    for (const key in camerasObject) {
      const returnObject = camerasObject[key];
      const currentProducts = camerasObject[key].products;
      const returnArray: any = [];
      currentProducts.forEach((num: number) => {
        if (num !== productId) {
          returnArray.push(num);
        }
      });
      returnObject.products = returnArray;
      setCamerasObject((previousState: any) => {
        return { ...previousState, key: returnObject };
      });
    }
  }
};
