/* eslint-disable jsx-a11y/media-has-caption */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { cancelIconRed } from "../../common/cancelIconRed";
import { checkedCircleIcon } from "../../common/checkedCircleIcon";
import {
  formatDateInUTC,
  formatTimeStamp,
} from "./InvestigationUtilities/formatTimeStamp";
import { roundToHundredth } from "../Sites/SiteAboutPage/SiteAboutPageUtils/roundToHundredth";

export const ShowMoreDialogSentinel = ({
  showMoreDialogOpenSentinel,
  setShowMoreDialogOpenSentinel,
  selectedRowSentinel,
  videoURLSentinel,
}: {
  showMoreDialogOpenSentinel: boolean;
  setShowMoreDialogOpenSentinel: Dispatcher<boolean>;
  selectedRowSentinel: any;
  videoURLSentinel: string;
}) => {
  const handleClickOpen = () => {
    setShowMoreDialogOpenSentinel(true);
  };

  const handleClose = () => {
    setShowMoreDialogOpenSentinel(false);
  };

  return (
    <div>
      <OrangeTextButton
        onClick={handleClickOpen}
        text="Full Clip Info"
        disabled={false}
      />
      <Dialog
        fullScreen
        open={showMoreDialogOpenSentinel}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedRowSentinel.customer_name} |{" "}
              {selectedRowSentinel.camera_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="flex pt-[20px] pl-[20px]">
          <div>
            <React.Fragment>
              <video width="550" controls autoPlay loop>
                <source src={videoURLSentinel} type="video/mp4" />
              </video>
            </React.Fragment>
          </div>
          <div className="flex">
            <div id="column1" className="pl-[20px]">
              <p className="text-black font-mulish text-[16px] font-bold">
                Site
              </p>
              <p className="w-[250px] text-black font-mulish text-[16px]">
                {selectedRowSentinel.customer_name
                  ? selectedRowSentinel.customer_name
                  : "--"}
              </p>
              <p className="pt-[20px] w-[250px] text-black font-mulish text-[16px] font-bold">
                Event ID
              </p>
              <p className="w-[250px] text-black font-mulish text-[16px]">
                {selectedRowSentinel.event_id
                  ? selectedRowSentinel.event_id
                  : "--"}
              </p>
              <p className="pt-[20px] w-[250px] text-black font-mulish text-[16px] font-bold">
                Camera Name
              </p>
              <p className="w-[250px] text-black font-mulish text-[16px]">
                {selectedRowSentinel.camera_name
                  ? selectedRowSentinel.camera_name
                  : "--"}
              </p>
              <p className="pt-[20px] w-[250px] text-black font-mulish text-[16px] font-bold">
                Camera ID
              </p>
              <p className="w-[250px] text-black font-mulish text-[16px]">
                {selectedRowSentinel.camera_id
                  ? selectedRowSentinel.camera_id
                  : "--"}
              </p>
            </div>
            <div id="column2" className="pl-[100px]">
              <p className="text-black font-mulish text-[16px] font-bold">
                Local Time
              </p>
              <p className="w-[250px] text-black font-mulish text-[16px]">
                {selectedRowSentinel.clip_received_local
                  ? formatTimeStamp(selectedRowSentinel.clip_received_local)
                  : "--"}
              </p>
              <p className="pt-[20px] w-[250px] text-black font-mulish text-[16px] font-bold">
                UTC Time
              </p>
              <p className="w-[250px] text-black font-mulish text-[16px]">
                {selectedRowSentinel.clip_header_received
                  ? formatDateInUTC(selectedRowSentinel.clip_header_received)
                  : ""}
              </p>
              <p className="pt-[20px] w-[250px] text-black font-mulish text-[16px] font-bold">
                Internal Log ID
              </p>
              <p className="w-[250px] text-black font-mulish text-[16px]">
                {selectedRowSentinel.internal_id
                  ? selectedRowSentinel.internal_id
                  : "--"}
              </p>
              <p className="pt-[20px] w-[250px] text-black font-mulish text-[16px] font-bold">
                File ID
              </p>
              <p className="w-[250px] text-black font-mulish text-[16px]">
                {selectedRowSentinel.file_id
                  ? selectedRowSentinel.file_id
                  : "--"}
              </p>
            </div>
          </div>
        </div>
        <br></br>
        <TableContainer component={Paper}>
          <Table
            sx={{ fontFamily: "Mulish", width: "100%" }}
            aria-label="simple table"
          >
            <TableHeadComponent
              titlesArray={[
                "Probability",
                "Analyzed FPS",
                "Clip FPS",
                "Slice",
                "Stationary",
              ]}
            />
            <TableBody>
              <TableRow>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {`${roundToHundredth(selectedRowSentinel.probability)}%`}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {selectedRowSentinel.fps}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {selectedRowSentinel.stream_fps}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {selectedRowSentinel.slice
                    ? checkedCircleIcon
                    : cancelIconRed}
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  {selectedRowSentinel.stationary_filter
                    ? checkedCircleIcon
                    : cancelIconRed}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="relative min-w-[1450px] "></div>
      </Dialog>
    </div>
  );
};
