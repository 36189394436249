import Tooltip from "@mui/material/Tooltip";
import TableCell from "@mui/material/TableCell";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const nameToTooltip = (
  nameString: string,
  typeString: string,
  setPageLoading: Dispatcher<boolean>,
  object: any,
  navigate: any
) => {
  if (!nameString) {
    return (
      <TableCell
        align="left"
        sx={{ fontFamily: "Mulish", width: "137.5px" }}
        onClick={() => {
          navigate(`/sites/${object.customer.id}/editcamera/${object.id}`);
        }}
      >
        --
      </TableCell>
    );
  } else if (nameString.length < 14 && typeString === "name") {
    return (
      <TableCell
        align="left"
        sx={{ fontFamily: "Mulish", width: "137.5px" }}
        onClick={() => {
          navigate(`/sites/${object.customer.id}/editcamera/${object.id}`);
        }}
      >
        {nameString}
      </TableCell>
    );
  } else if (nameString.length < 14 && typeString === "error") {
    return (
      <TableCell
        align="left"
        sx={{ fontFamily: "Mulish", width: "137.5px", color: "#D32F2F" }}
        onClick={() => {
          navigate(`/sites/${object.customer.id}/editcamera/${object.id}`);
        }}
      >
        {nameString}
      </TableCell>
    );
  } else if (nameString.length > 13 && typeString === "name") {
    return (
      <Tooltip title={nameString} placement="top">
        <TableCell
          align="left"
          sx={{ fontFamily: "Mulish", width: "137.5px" }}
          onClick={() => {
            navigate(`/sites/${object.customer.id}/editcamera/${object.id}`);
          }}
        >
          {`${nameString.slice(0, 10)}...`}
        </TableCell>
      </Tooltip>
    );
  } else if (nameString.length > 13 && typeString === "error") {
    return (
      <Tooltip
        title={nameString}
        placement="top"
        onClick={() => {
          navigate(`/sites/${object.customer.id}/editcamera/${object.id}`);
        }}
      >
        <TableCell
          align="left"
          sx={{ fontFamily: "Mulish", width: "137.5px", color: "#D32F2F" }}
          onClick={() => {
            navigate(`/sites/${object.customer.id}/editcamera/${object.id}`);
          }}
        >
          {`${nameString.slice(0, 10)}...`}
        </TableCell>
      </Tooltip>
    );
  } else {
    return (
      <TableCell
        align="left"
        sx={{ fontFamily: "Mulish", width: "137.5px" }}
        onClick={() => {
          navigate(`/sites/${object.customer.id}/editcamera/${object.id}`);
        }}
      >
        --
      </TableCell>
    );
  }
};
