import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { DialogActions, Toolbar, Tooltip, Typography } from "@mui/material";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { handleDeleteCalendarEvent } from "./CalendarsUtils/handleDeleteCalendarEvent";
import { useNavigate } from "react-router-dom";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const getMouthName = (monthNumber: number) => {
  return monthNames[monthNumber - 1];
};

const formatDate = (date: any) => {
  if (date === "--") {
    return "--";
  }
  const parts = date.split("-");
  return `${getMouthName(parts[1])} ${parts[2]}, ${parts[0]}`;
};

export const ShowMoreDialogCalendars = ({
  setShowMoreDialogOpen,
  selectedRow,
  calendarEvents,
  setCalendarEvents,
  setAddDateDialogOpen,
  editingCalendar,
  loadingEventData,
  setEditingCalendar,
  setPatchEvent,
  setEditEventDialogOpen,
  setEditCalendarDialogOpen,
}: {
  setShowMoreDialogOpen: Dispatcher<boolean>;
  selectedRow: any;
  calendarEvents: any;
  setCalendarEvents: any;
  setAddDateDialogOpen: any;
  editingCalendar: boolean;
  loadingEventData: boolean;
  setEditingCalendar: Dispatcher<boolean>;
  setPatchEvent: any;
  setEditEventDialogOpen: Dispatcher<boolean>;
  setEditCalendarDialogOpen: Dispatcher<boolean>;
}) => {
  const navigate = useNavigate();

  return (
    <div className="absolute align-middle">
      <BackdropLoadingScreen openState={editingCalendar || loadingEventData} />
      <Paper sx={{ width: 600, overflow: "hidden" }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {selectedRow.name} | {selectedRow.group.name}
            <OrangeTextButton
              onClick={() => {
                setEditCalendarDialogOpen(true);
              }}
              text={<ModeEditIcon />}
              disabled={false}
            />
          </Typography>
          <Tooltip title="Close Screen">
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowMoreDialogOpen(false);
                setCalendarEvents([]);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            sx={{ fontFamily: "Mulish", width: "100%", overflow: "scroll" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHeadComponent
              titlesArray={["Event Date", "Event Name", ""]}
            />
            <TableBody>
              {calendarEvents.map((object: any) => {
                return (
                  <TableRow key={object.name}>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      {formatDate(object.event_date)}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      {object.name}
                    </TableCell>
                    <TableCell align="right" sx={{ fontFamily: "Mulish" }}>
                      <OrangeTextButton
                        onClick={() => {
                          setPatchEvent(object);
                          setEditEventDialogOpen(true);
                        }}
                        text={<ModeEditIcon />}
                        disabled={false}
                      />
                      <OrangeTextButton
                        onClick={() => {
                          setEditingCalendar(true);
                          handleDeleteCalendarEvent(
                            object.id,
                            setCalendarEvents,
                            setEditingCalendar,
                            navigate
                          );
                        }}
                        text={<DeleteIcon />}
                        disabled={false}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <DialogActions>
          <span>
            <OrangeTextButton
              onClick={() => {
                setAddDateDialogOpen(true);
              }}
              text="+ Add Date"
              disabled={false}
            />
          </span>
        </DialogActions>
      </Paper>
    </div>
  );
};
