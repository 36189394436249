import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export default function AiLinkBackdropLoadingScreen({
  openState,
  aiLinkClipsTotal,
}: {
  openState: boolean;
  aiLinkClipsTotal: number;
}) {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openState}
        >
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <CircularProgress color="primary" />
            <p className="text-actuate-orange">
              {/* {`${aiLinkClipsTotal} clips loaded...`} */}
            </p>
          </div>
        </Backdrop>
      </div>
    </ThemeProvider>
  );
}
