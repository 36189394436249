import axios from "axios";
import { getCookie } from "./getCookie";
import { getApiUrl } from "./getApiUrl";

export const createRefreshApi = (path: string) => {
  const baseURL = getApiUrl();
  return axios.create({
    baseURL: `${baseURL}api/${path}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("token_secret")}`,
    },
  });
};
