import React from "react";

export const StatusNotificationScreen = ({
  text,
  styling,
}: {
  text: any;
  styling: string;
}) => {
  return (
    <div>
      <p className={styling}>{text}</p>
    </div>
  );
};
