import React from "react";

export const LoadingSpinner: React.FC = () => {
  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width="40"
      height="40"
      style={{
        shapeRendering: "auto",
        display: "block",
        background: "#E5E5E5",
      }}
    >
      <g>
        <circle
          cx="50"
          cy="50"
          r="32"
          strokeWidth="8"
          stroke="#FF8400"
          strokeDasharray="50.26548245743669 50.26548245743669"
          fill="none"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;1"
            values="0 50 50;360 50 50"
          />
        </circle>
      </g>
    </svg>
  );
};
