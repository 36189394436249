export const convertHHMMTime = (militaryTime: string): string => {
  // Extract hours and minutes from military time (e.g., "1800" -> 18:00)
  const hours = parseInt(militaryTime.substring(0, 2), 10);
  const minutes = parseInt(militaryTime.substring(2, 4), 10);

  // Get the current date
  const date = new Date();

  // Set the time from the military time
  date.setHours(hours); // Hours from military time
  date.setMinutes(minutes); // Minutes from military time
  date.setSeconds(0); // Set seconds to 58

  // Return the date string including timezone information
  return date.toString();
};
