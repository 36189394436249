// import React from "react";

// export const handleReorderIndex = (
//   visibleRows: any,
//   setIndexOrder: any,
//   alertData: any,
//   setData: any,
//   pageString: string
// ) => {
//   const updatedOrder: any[] = [];
//   const updatedData: any[] = [];
//   visibleRows.forEach((object: any) => {
//     updatedOrder.push(object.index);
//     alertData.forEach((obj: any) => {
//       if (object.index === obj.index) {
//         updatedData.push(obj);
//       }
//     });
//   });
//   setIndexOrder(updatedOrder), setData(updatedData);

//   if (pageString === "investigations") {
//     localStorage.setItem("investigationData", JSON.stringify(updatedData));
//     localStorage.setItem("clipsIndexOrder", JSON.stringify(updatedOrder));
//   }
// };

import React from "react";

export const handleReorderIndex = (
  visibleRows: any,
  setIndexOrder: any,
  alertData: any,
  setData: any,
  pageString: string,
  detectionType: string
) => {
  const updatedOrder: any[] = [];
  const updatedData: any[] = [];

  // Create a map to store alertData objects by index for faster lookup
  const alertDataMap = new Map();
  alertData.forEach((obj: any) => {
    alertDataMap.set(obj.index, obj);
  });

  // Iterate through visibleRows once
  if (detectionType === "All") {
    visibleRows.forEach((object: any) => {
      const alertObj = alertDataMap.get(object.index);
      if (alertObj) {
        updatedOrder.push(object.index);
        updatedData.push(alertObj);
      }
    });
  } else {
    const detectionTypeLower =
      detectionType.slice(0, 1).toLowerCase() + detectionType.slice(1);
    visibleRows.forEach((object: any) => {
      const alertObj = alertDataMap.get(object.index);
      if (alertObj && alertObj.alert_labels === detectionTypeLower) {
        updatedOrder.push(object.index);
        updatedData.push(alertObj);
      }
    });
  }

  // Update state
  setIndexOrder(updatedOrder);
  setData(updatedData);

  // If pageString is "investigations", update localStorage
  if (pageString === "investigations") {
    localStorage.setItem("investigationData", JSON.stringify(updatedData));
    localStorage.setItem("clipsIndexOrder", JSON.stringify(updatedOrder));
  }
};
