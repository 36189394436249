import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "../utils/getApiUrl";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

export const EagleEyeCallback = () => {
  const queryString = window.location.search;
  const code = queryString.slice(6);
  const navigate = useNavigate();
  const url = getApiUrl();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(undefined);

  const AcuateUI = axios.create({
    baseURL: `${url}api/eagle_eye/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.token}`,
    },
  });

  const sendCode = async () => {
    await AcuateUI.post(`callback/`, {
      code: code,
      customer_id: localStorage.getItem("siteId"),
    })
      .then((response: AxiosResponse) => {
        navigate("/sites/autoaddcamera");
      })
      .catch((err: AxiosError) => alert(`${err}`));
  };

  useEffect(() => {
    sendCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center align-center h-screen m-auto">
      <div className="lds-facebook font-black">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
