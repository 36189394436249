import React from "react";

export const SVGLine = ({ navBarCollapsed }: { navBarCollapsed: boolean }) => {
  return (
    <div>
      <svg className="absolute top-[35px] left-[20px]">
        <line
          pathLength="1535"
          x1="0"
          y1="0"
          x2="300"
          y2="0"
          style={{ stroke: `#BDBDBD`, strokeWidth: 2 }}
        />
      </svg>
      <svg className="absolute top-[35px] left-[320px]">
        <line
          pathLength="1535"
          x1="0"
          y1="0"
          x2="300"
          y2="0"
          style={{ stroke: `#BDBDBD`, strokeWidth: 2 }}
        />
      </svg>
      <svg className="absolute top-[35px] left-[620px]">
        <line
          pathLength="1535"
          x1="0"
          y1="0"
          x2="300"
          y2="0"
          style={{ stroke: `#BDBDBD`, strokeWidth: 2 }}
        />
      </svg>
      <svg className="absolute top-[35px] left-[920px]">
        <line
          pathLength="1535"
          x1="0"
          y1="0"
          x2="300"
          y2="0"
          style={{ stroke: `#BDBDBD`, strokeWidth: 2 }}
        />
      </svg>
      <svg className="absolute top-[35px] left-[970px]">
        <line
          pathLength="1535"
          x1="0"
          y1="0"
          x2="300"
          y2="0"
          style={{ stroke: `#BDBDBD`, strokeWidth: 2 }}
        />
      </svg>
      {navBarCollapsed && (
        <svg className="absolute top-[35px] left-[1045px]">
          <line
            pathLength="1535"
            x1="0"
            y1="0"
            x2="300"
            y2="0"
            style={{ stroke: `#BDBDBD`, strokeWidth: 2 }}
          />
        </svg>
      )}
    </div>
  );
};
