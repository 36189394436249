/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { createApi } from "./createApi";
import { NavigateFunction } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "./universalAPIErrorHandler";

export const fetchGroupTrees = (
  navigate: NavigateFunction,
  setGroupTrees: any
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    fetchGroupTrees(navigate, setGroupTrees);
  };
  AxiosUI.get(`group/tree/?page_size=1000`).then(
    (response: AxiosResponse) => {
      setGroupTrees(response.data.results);
      localStorage.setItem("groupTrees", JSON.stringify(response.data.results));
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
