import React from "react";

export const handleCreateDuskToDawn = (siteSchedules: any) => {
  const returnArray = [false, false, false, false, false, false, false];

  siteSchedules.forEach((object: any) => {
    object.day_of_week.forEach((day: string) => {
      const dayIndex = Number(day);
      if (object.location_dusk_dawn) {
        returnArray[dayIndex] = true;
      }
    });
  });
  return returnArray;
};
