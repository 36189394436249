import { getActiveIgnoreZones } from "./getActiveIgnoreZones";
import { getAlertMP4 } from "./getAlertMP4";
import { handleAlertButtonsFill } from "./handleAlertButtonsFill";
import { handleRefreshButtonsFill } from "./handleRefreshButtonsFill";
import { handleRowSelected } from "./handleRowSelected";

export const addSelectedAndDetectionTypeOnRefresh = (
  data: any,
  setAlertsIndexOrder: any,
  setSeeMoreURL: any,
  setSelectedRow: any,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  navigate: any,
  setMP4URL: any,
  setVideoClipLoading: any,
  setSelectedButtonsFilled: any,
  alertData: any,
  setAlertData: any,
  refreshingToken: boolean
) => {
  const newData: any = [];
  const indexOrder: any[] = [];
  const selectedRowAlerts = JSON.parse(localStorage.selectedRowAlerts);
  let selectedRowStillPresent = false;
  data.forEach((object: any, index: number) => {
    indexOrder.push(index);
    const newObject = object;
    newObject.index = index;
    newObject.selected = false;
    if (
      object.approx_capture_timestamp ===
      selectedRowAlerts.approx_capture_timestamp
    ) {
      if (object.customer_outcome) {
        handleRefreshButtonsFill(
          object.customer_outcome.outcome,
          setSelectedButtonsFilled
        );
      }
      newObject.selected = true;
      selectedRowStillPresent = true;
      setSelectedRow(object);
    }
    if (
      object.approx_capture_timestamp !==
      selectedRowAlerts.approx_capture_timestamp
    ) {
      newObject.selected = false;
    }
    if (object.engineering_outcome) {
      newObject.seen = true;
      newObject.notes = object.engineering_outcome.notes;
      newObject.user = object.engineering_outcome.user;
      newObject.updated = object.engineering_outcome.updated;
      if (object.engineering_outcome.outcome_v2) {
        newObject.detection_type = object.engineering_outcome.outcome_v2;
        if (object.engineering_outcome.outcome_v2 === "true_non_threat") {
          newObject.outcome_number = 2;
        }
        if (object.engineering_outcome.outcome_v2 === "true_threat") {
          newObject.outcome_number = 1;
        }
        if (object.engineering_outcome.outcome_v2 === "false_positive") {
          newObject.outcome_number = 3;
        }
      }
      if (!object.engineering_outcome.outcome_v2) {
        if (object.engineering_outcome.outcome === "intruder_detected") {
          newObject.detection_type = "true_threat";
          newObject.outcome_number = 1;
        }
        if (object.engineering_outcome.outcome === "false_positive") {
          newObject.detection_type = "false_positive";
          newObject.outcome_number = 3;
        }
      }
    } else if (object.customer_outcome) {
      newObject.seen = true;
      newObject.notes = object.customer_outcome.notes;
      newObject.user = object.customer_outcome.user;
      newObject.updated = object.customer_outcome.updated;
      if (object.customer_outcome.outcome_v2) {
        newObject.detection_type = object.customer_outcome.outcome_v2;
        if (object.customer_outcome.outcome_v2 === "true_non_threat") {
          newObject.outcome_number = 2;
        }
        if (object.customer_outcome.outcome_v2 === "true_threat") {
          newObject.outcome_number = 1;
        }
        if (object.customer_outcome.outcome_v2 === "false_positive") {
          newObject.outcome_number = 3;
        }
      }
      if (!object.customer_outcome.outcome_v2) {
        if (object.customer_outcome.outcome === "intruder_detected") {
          newObject.detection_type = "true_threat";
          newObject.outcome_number = 1;
        }
        if (object.customer_outcome.outcome === "false_positive") {
          newObject.detection_type = "false_positive";
          newObject.outcome_number = 3;
        }
      }
    } else {
      newObject.checked = false;
      newObject.seen = false;
      newObject.detection_type = "";
    }
    newData.push(newObject);
  });
  setAlertsIndexOrder(indexOrder);
  if (!selectedRowStillPresent) {
    newData[0].selected = true;
    handleRowSelected(
      newData[0],
      newData,
      setAlertData,
      setSelectedButtonsFilled,
      setSeeMoreURL,
      navigate,
      setMP4URL,
      setVideoClipLoading,
      setSelectedRow,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      refreshingToken
    );
    // setSeeMoreURL(newData[0].alert_url);
    // setSelectedRow(newData[0]);
    // newData[0].camera_pk &&
    //   getActiveIgnoreZones(
    //     newData[0].camera_pk,
    //     setSelectedAlertCameraInfo,
    //     setIgnoreZonesSelectedAlert,
    //     navigate
    //   );
    // localStorage.setItem("selectedRowAlerts", JSON.stringify(newData[0]));
    // getAlertMP4(newData[0], navigate, setMP4URL, setVideoClipLoading);
    // handleAlertButtonsFill(newData[0], setSelectedButtonsFilled);
    // setSeeMoreURL(newData[0].alert_url);
  }
  return newData;
};
