import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleDeleteCalendar } from "./CalendarsUtils/handleDeleteCalendar";

export const DeleteCalendarDialog = ({
  deleteCalendarDialogOpen,
  setDeleteCalendarDialogOpen,
  deletedCalendarId,
  setFetchingCalendarData,
  calendarData,
  setCalendarData,
  setCalendarAlerts,
  navigate,
}: {
  deleteCalendarDialogOpen: boolean;
  setDeleteCalendarDialogOpen: Dispatcher<boolean>;
  deletedCalendarId: any;
  setFetchingCalendarData: Dispatcher<boolean>;
  calendarData: any;
  setCalendarData: any;
  setCalendarAlerts: any;
  navigate: NavigateFunction;
}) => {
  return (
    <Dialog open={deleteCalendarDialogOpen}>
      <DialogTitle id="alert-dialog-title">
        {" Are you sure you want to delete this Calendar?"}
      </DialogTitle>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setDeleteCalendarDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setFetchingCalendarData(true);
            setDeleteCalendarDialogOpen(false);
            handleDeleteCalendar(
              deletedCalendarId,
              setFetchingCalendarData,
              calendarData,
              setCalendarData,
              setCalendarAlerts,
              navigate
            );
          }}
          text="Delete Calendar"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
