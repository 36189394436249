import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export const checkedCircleIcon = <CheckCircleIcon color="success" />;

export const checkedCircleIconSmall = (
  <CheckCircleIcon color="success" fontSize="small" />
);

export const checkedCircleIconGrey = <RemoveCircleIcon color="disabled" />;

export const checkedCircleIconGreySmall = (
  <RemoveCircleIcon color="disabled" fontSize="small" />
);
