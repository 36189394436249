import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../common/SearchBar";
import { handleSearchBarFilter } from "./SitesPageUtils/handleSearchBarFilter";
import { useNavigate } from "react-router-dom";

export const GroupSearchBar = ({
  setSearchBarValue,
  setEditedParentGroupList,
  setBackdropLoadingScreenOn,
}: {
  setSearchBarValue: any;
  setEditedParentGroupList: any;
  setBackdropLoadingScreenOn: any;
}) => {
  const navigate = useNavigate();
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search Groups..."
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleSearchBarFilter(
            event,
            setSearchBarValue,
            setEditedParentGroupList,
            navigate,
            setBackdropLoadingScreenOn
          )
        }
        inputProps={{ "aria-label": "search" }}
      />
    </Search>
  );
};
