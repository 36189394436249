import React from "react";
import { NavigateFunction } from "react-router-dom";
import { findSelectedRowIndex } from "../../Alerts/AlertsUtils/findSelectedRowIndex";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createApi } from "../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleArrowButtonClickSentinel = (
  sentinelData: any,
  selectedRowSentinel: any,
  clipsIndexOrderSentinel: any,
  directionString: string,
  setSelectedRowSentinel: any,
  setSentinelData: any,
  setVideoClipLoadingSentinel: Dispatcher<boolean>,
  setVideoURLSentinel: Dispatcher<string>,
  userFiltersSentinel: any,
  navigate: NavigateFunction
) => {
  // const investigationData = JSON.parse(localStorage.investigationData);

  // const selectedRow = JSON.parse(localStorage.investigationsSelectedRow);

  // const clipsIndexOrder = JSON.parse(localStorage.clipsIndexOrder);

  if (
    (directionString === "forward" &&
      selectedRowSentinel.index !==
        clipsIndexOrderSentinel[clipsIndexOrderSentinel.length - 1]) ||
    (directionString === "back" &&
      selectedRowSentinel.index !== clipsIndexOrderSentinel[0])
  ) {
    setVideoClipLoadingSentinel(true);
    const currentIndex = findSelectedRowIndex(
      clipsIndexOrderSentinel,
      selectedRowSentinel.index
    );
    let newSelectedRow: any;

    directionString === "forward"
      ? (newSelectedRow = sentinelData[currentIndex + 1])
      : (newSelectedRow = sentinelData[currentIndex - 1]);

    setSelectedRowSentinel(newSelectedRow);
    localStorage.setItem(
      "investigationsSelectedRow",
      JSON.stringify(newSelectedRow)
    );

    const updatedSentinelData = sentinelData;

    updatedSentinelData.forEach((object: any) => {
      object.selected = false;
    });

    directionString === "forward"
      ? (updatedSentinelData[currentIndex + 1].selected = true)
      : (updatedSentinelData[currentIndex - 1].selected = true);

    setSentinelData(updatedSentinelData);

    // localStorage.setItem("sentinelData", JSON.stringify(updatedSentinelData));

    const AxiosUI = createApi("");

    const retryFunction = () => {
      handleArrowButtonClickSentinel(
        sentinelData,
        selectedRowSentinel,
        clipsIndexOrderSentinel,
        directionString,
        setSelectedRowSentinel,
        setSentinelData,
        setVideoClipLoadingSentinel,
        setVideoURLSentinel,
        userFiltersSentinel,
        navigate
      );
    };
    AxiosUI.get(
      `clip/url/?username=${userFiltersSentinel.user}&file_id=${newSelectedRow.file_id}`
    ).then(
      (response: AxiosResponse) => {
        setVideoURLSentinel(response.data);
        setVideoClipLoadingSentinel(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          const newSentinelData = sentinelData;
          newSentinelData.forEach((object: any) => {
            object.selected = false;
          });
          directionString === "forward"
            ? (newSentinelData[currentIndex + 1].selected = true)
            : (newSentinelData[currentIndex - 1].selected = true);
          setSentinelData(newSentinelData);
          localStorage.setItem("sentinelData", JSON.stringify(newSentinelData));
          setVideoURLSentinel("");
          setVideoClipLoadingSentinel(false);
        });
      }
    );
  }
};
