import { GeneralInfoEditCameraTabEditMode } from "../GeneralInfoEditCameraTabEditMode";
import { ConnectionsTabEditMode } from "../ConnectionsTabEditMode";
import { NotificationsTabEditMode } from "../NotificationsTabEditMode";
import { ProductsTabEditMode } from "../ProductsTabEditMode";
import { ImmixAlertsTabEditMode } from "../ImmixAlertsTabEditMode";
import { SureviewAlertsTabEditMode } from "../SureviewAlertsTabEditMode";
import { WebhooksCameraTabEditMode } from "../WebhooksCameraTabEditMode";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { SentinelAlertsTabEditMode } from "../SentinelAlertsTabEditMode";

export const handleEditInfoButtonClick = (
  setEditMode: any,
  clickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  cameraGeneralInfo: any,
  setCameraGeneralInfo: any,
  setPatchObject: any,
  setGeneralInfoChangesMade: any,
  setActiveChangeMade: any,
  immixAlerts: any,
  sureviewAlerts: any,
  activeProducts: any,
  cameraWebhookData: any,
  setCameraWebhooksPatchObject: any,
  setCameraWebhooksChangesMade: any,
  productsData: any,
  setProductsData: Dispatcher<any>,
  allTabsOpen: boolean,
  setAllTabsOpen: Dispatcher<boolean>,
  setBackupProductsData: any,
  sentinelAlerts: any
) => {
  setEditMode(true);
  if (clickedTab === "one") {
    setActiveTab(
      <GeneralInfoEditCameraTabEditMode
        siteInfo={siteInfo}
        cameraGeneralInfo={cameraGeneralInfo}
        setCameraGeneralInfo={setCameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        setActiveChangeMade={setActiveChangeMade}
      />
    );
  } else if (clickedTab === "two") {
    setActiveTab(
      <ConnectionsTabEditMode
        cameraGeneralInfo={cameraGeneralInfo}
        setCameraGeneralInfo={setCameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  } else if (clickedTab === "three") {
    setActiveTab(
      <NotificationsTabEditMode
        cameraGeneralInfo={cameraGeneralInfo}
        setCameraGeneralInfo={setCameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  } else if (clickedTab === "four") {
    setActiveTab(
      <ProductsTabEditMode
        activeProducts={activeProducts}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
        cameraGeneralInfo={cameraGeneralInfo}
        productsData={productsData}
        setProductsData={setProductsData}
        allTabsOpen={allTabsOpen}
        setAllTabsOpen={setAllTabsOpen}
        setBackupProductsData={setBackupProductsData}
      />
    );
  } else if (clickedTab === "five") {
    setActiveTab(
      <ImmixAlertsTabEditMode
        immixAlerts={immixAlerts}
        cameraGeneralInfo={cameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  } else if (clickedTab === "six") {
    setActiveTab(
      <SureviewAlertsTabEditMode
        sureviewAlerts={sureviewAlerts}
        cameraGeneralInfo={cameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  } else if (clickedTab === "nine") {
    setActiveTab(
      <WebhooksCameraTabEditMode
        cameraWebhookData={cameraWebhookData}
        setCameraWebhooksPatchObject={setCameraWebhooksPatchObject}
        setCameraWebhooksChangesMade={setCameraWebhooksChangesMade}
      />
    );
  } else if (clickedTab === "ten") {
    setActiveTab(
      <SentinelAlertsTabEditMode
        sentinelAlerts={sentinelAlerts}
        cameraGeneralInfo={cameraGeneralInfo}
        setPatchObject={setPatchObject}
        setGeneralInfoChangesMade={setGeneralInfoChangesMade}
      />
    );
  }
};
