import React from "react";
import ErrorsWarningToggle from "./ErrorsWarningToggle";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from "recharts";
import { CircularProgress } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { colorsArray } from "./HealthMonitoringUtils/colorsArray";

export const ErrorsOverViewGraph = ({
  errorsOverviewData,
  errorWarningData,
  setErrorWarningData,
  navBarCollapsed,
  userFilters,
}: {
  errorsOverviewData: any;
  errorWarningData: string;
  setErrorWarningData: Dispatcher<string>;
  navBarCollapsed: boolean;
  userFilters: any;
}) => {
  const errorData: any[] = [];

  if (userFilters.metrics.includes("Connectivity")) {
    errorData.push({
      quantity: errorsOverviewData.connectivity,
      display_name: "Connectivity",
    });
  }
  if (userFilters.metrics.includes("Scene Change")) {
    errorData.push({
      quantity: errorsOverviewData.scene_change,
      display_name: "Scene Change",
    });
  }

  const warningData: any[] = [
    // {
    //   quantity: errorsOverviewData.license_info,
    //   display_name: "License Info",
    // },
  ];

  if (userFilters.metrics.length === 0) {
    errorData.push({
      quantity: errorsOverviewData.connectivity,
      display_name: "Connectivity",
    });
    errorData.push({
      quantity: errorsOverviewData.scene_change,
      display_name: "Scene Change",
    });
    warningData.push({
      quantity: errorsOverviewData.motion_status,
      display_name: "Motion Status",
    });
    warningData.push({
      quantity: errorsOverviewData.image_quality,
      display_name: "Image Quality",
    });
    warningData.push({
      quantity: errorsOverviewData.stream_quality,
      display_name: "Stream Quality",
    });
    warningData.push({
      quantity: errorsOverviewData.recording_status,
      display_name: "Recording Status",
    });
  }

  if (userFilters.metrics.includes("Motion Status")) {
    warningData.push({
      quantity: errorsOverviewData.motion_status,
      display_name: "Motion Status",
    });
  }
  if (userFilters.metrics.includes("Image Quality")) {
    warningData.push({
      quantity: errorsOverviewData.image_quality,
      display_name: "Image Quality",
    });
  }
  if (userFilters.metrics.includes("Stream Quality")) {
    warningData.push({
      quantity: errorsOverviewData.stream_quality,
      display_name: "Stream Quality",
    });
  }
  if (userFilters.metrics.includes("Recording Status")) {
    warningData.push({
      quantity: errorsOverviewData.recording_status,
      display_name: "Recording Status",
    });
  }

  return (
    <div
      className={
        !navBarCollapsed
          ? "absolute top-[50px] left-[20px] right-[1px] h-[425px] w-[610px] bg-[#BDBDBD]"
          : "absolute top-[50px] left-[20px] right-[1px] h-[425px] w-[648px] bg-[#BDBDBD]"
      }
    >
      <div
        className={
          !navBarCollapsed
            ? "absolute top-[1px] left-[1px] right-[1px] h-[423px] w-[608px] bg-white"
            : "absolute top-[1px] left-[1px] right-[1px] h-[423px] w-[646px] bg-white"
        }
      >
        <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[8px] left-[30px]">
          {errorWarningData === "warning"
            ? `Warnings Overview`
            : `Errors Overview`}
        </p>
        <p className="text-actuate-blue font-mulish text-[14px] absolute top-[40px] left-[31px]">
          {errorWarningData === "warning"
            ? `Warnings are non-fatal and lower priority issues that should still be attended to in a timely manner.`
            : `Errors are fatal issues that prevent the proper monitoring of cameras and require immediate attention.`}
        </p>
        <div
          className={
            navBarCollapsed
              ? "absolute top-[12px] left-[468px]"
              : "absolute top-[12px] left-[430px]"
          }
        >
          <ErrorsWarningToggle setErrorWarningData={setErrorWarningData} />
        </div>
        <div
          className={
            !navBarCollapsed
              ? "absolute top-[90px] left-[10px] h-[340px] w-[590px] overflow-scroll"
              : "absolute top-[90px] left-[10px] h-[340px] w-[618px] overflow-scroll"
          }
        >
          {errorWarningData === "warning" ? (
            <BarChart
              width={!navBarCollapsed ? 580 : 618}
              height={330}
              data={warningData}
              barSize={120}
              margin={{
                top: 5,
                right: 0,
                left: 18,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="display_name" type="category" />
              <YAxis
                label={{
                  value: "Warnings Volume",
                  angle: -90,
                  position: "insideLeft",
                  offset: -10,
                }}
              />
              <Tooltip />
              <Bar
                // cursor="pointer"
                dataKey="quantity"
                fill="#FF8400"
                name="Warnings"
                onClick={() => {}}
              >
                {/* <LabelList dataKey="abreviated_name" position="bottom" /> */}
              </Bar>
            </BarChart>
          ) : (
            <BarChart
              width={!navBarCollapsed ? 580 : 618}
              height={330}
              data={errorData}
              barSize={120}
              margin={{
                top: 5,
                right: 0,
                left: 18,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="display_name" type="category" />
              <YAxis
                label={{
                  value: "Errors Volume",
                  angle: -90,
                  position: "insideLeft",
                  offset: -10,
                }}
              />
              <Tooltip />
              <Bar
                // cursor="pointer"
                dataKey="quantity"
                fill="#F44336"
                name="Errors"
                onClick={() => {}}
              ></Bar>
            </BarChart>
          )}
        </div>
      </div>
    </div>
  );
};
