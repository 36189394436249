import React from "react";
import { AlertsTableHealthMonitoring } from "../AlertsTableHealthMonitoring";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";

export const handleClickedTabChangeHM = (
  event: any,
  newValue: any,
  setActiveTable: any,
  setClickedTab: any,
  alertsData: any,
  setAlertStatusDialogOpen: any,
  setSelectedRow: any,
  setSelectedRowIndex: any,
  setFectchingAllData: Dispatcher<boolean>,
  setAlertsData: any,
  setUnhealthyCamsData: any,
  unhealthyCamsData: any,
  clickedTab: string,
  setSortedData: any,
  singleCellActivated: boolean,
  activeName: any,
  setUpdatingStatus: any,
  filters: any,
  alertFilter: any,
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>,
  setBackgroundFrame: Dispatcher<string>,
  setDetectedFrame: Dispatcher<string>,
  setSelectedRows: any,
  selectedRows: any,
  setErrorsOverviewData: any,
  setCameraStatsData: any,
  setLoadingCameraStatsData: any,
  setBandwidthData: any,
  setLoadingBandwidthData: any,
  setShowSiteStats: any,
  cameraDropDownValue: any,
  setFilteredDataLengths: any,
  navigate: NavigateFunction
) => {
  setClickedTab(newValue);
  if (newValue === "all") {
    setTimeout(() => {
      setActiveTable(
        <AlertsTableHealthMonitoring
          alertsData={alertsData}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={"all"}
          setActiveTable={setActiveTable}
          setSortedData={setSortedData}
          singleCellActivated={singleCellActivated}
          activeName={activeName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          navigate={navigate}
        />
      );
    }, 1);
  }
  if (newValue === "errors") {
    setTimeout(() => {
      setActiveTable(
        <AlertsTableHealthMonitoring
          alertsData={alertsData}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={"errors"}
          setActiveTable={setActiveTable}
          setSortedData={setSortedData}
          singleCellActivated={singleCellActivated}
          activeName={activeName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          navigate={navigate}
        />
      );
    }, 1);
  }
  if (newValue === "warnings") {
    setTimeout(() => {
      setActiveTable(
        <AlertsTableHealthMonitoring
          alertsData={alertsData}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={"warnings"}
          setActiveTable={setActiveTable}
          setSortedData={setSortedData}
          singleCellActivated={singleCellActivated}
          activeName={activeName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          navigate={navigate}
        />
      );
    }, 1);
  }
  if (newValue === "pending") {
    setTimeout(() => {
      setActiveTable(
        <AlertsTableHealthMonitoring
          alertsData={alertsData}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={"pending"}
          setActiveTable={setActiveTable}
          setSortedData={setSortedData}
          singleCellActivated={singleCellActivated}
          activeName={activeName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          navigate={navigate}
        />
      );
    }, 1);
  }
  if (newValue === "resolved") {
    setTimeout(() => {
      setActiveTable(
        <AlertsTableHealthMonitoring
          alertsData={alertsData}
          setAlertStatusDialogOpen={setAlertStatusDialogOpen}
          setSelectedRow={setSelectedRow}
          setSelectedRowIndex={setSelectedRowIndex}
          setFectchingAllData={setFectchingAllData}
          setAlertsData={setAlertsData}
          setUnhealthyCamsData={setUnhealthyCamsData}
          unhealthyCamsData={unhealthyCamsData}
          clickedTab={"resolved"}
          setActiveTable={setActiveTable}
          setSortedData={setSortedData}
          singleCellActivated={singleCellActivated}
          activeName={activeName}
          setUpdatingStatus={setUpdatingStatus}
          filters={filters}
          alertFilter={alertFilter}
          setSceneChangeOutcomesDialogOpen={setSceneChangeOutcomesDialogOpen}
          setBackgroundFrame={setBackgroundFrame}
          setDetectedFrame={setDetectedFrame}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          setErrorsOverviewData={setErrorsOverviewData}
          setCameraStatsData={setCameraStatsData}
          setLoadingCameraStatsData={setLoadingCameraStatsData}
          setBandwidthData={setBandwidthData}
          setLoadingBandwidthData={setLoadingBandwidthData}
          setShowSiteStats={setShowSiteStats}
          cameraDropDownValue={cameraDropDownValue}
          setFilteredDataLengths={setFilteredDataLengths}
          navigate={navigate}
        />
      );
    }, 1);
  }
};
