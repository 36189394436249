import React from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip } from "@mui/material";

export const additionalFieldTableCellGenerator = (
  type: string,
  id: string,
  cameraObject: any
) => {
  switch (type) {
    case "checkbox":
      return cameraObject[id] ? (
        <>
          <CheckCircleIcon color="success" />
        </>
      ) : (
        <CancelIcon color="error" />
      );
    case "string":
      if (cameraObject[id]) {
        return cameraObject[id].length < 10 ? (
          <>{cameraObject[id]}</>
        ) : (
          <>
            <Tooltip title={cameraObject[id]} placement="top">
              <div>{`${cameraObject[id].slice(0, 7)}...`}</div>
            </Tooltip>
          </>
        );
      }
  }
};
