import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { Switch } from "@mui/material";

export const SentinelAlertsTab = ({
  sentinelAlerts,
  setAddSentinelAlertDialogOpen,
}: {
  sentinelAlerts: any;
  setAddSentinelAlertDialogOpen: any;
}) => {
  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  const label = { inputProps: { "aria-label": "Draw Ignore Zones Checkbox" } };

  return (
    <div className="absolute min-w-[1150px] right-[1px] left-[0px]">
      <div className="min-w-[1150px] h-[50px] bg-white">
        <div className="absolute right-[70px] top-[10px]">
          {permissions.edit_cameras && (
            <OrangeTextButton
              onClick={() => {
                setAddSentinelAlertDialogOpen(true);
              }}
              text="+ Add Sentinel Alert"
              disabled={false}
            />
          )}
        </div>
      </div>
      {sentinelAlerts[0] &&
        sentinelAlerts.map((object: any, index: any) => {
          return (
            <div key={object.id} className="h-[208px]">
              <div className="min-w-[1150px] h-[80px] bg-white">
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: "Bold",
                    color: "black",
                    textIndent: "1vw",
                  }}
                >
                  Sentinel Alert {index + 1}
                </Typography>
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 1000, fontFamily: "Mulish" }}
                    aria-label="simple table"
                  >
                    <TableHeadComponent
                      titlesArray={[
                        "Sentinel Server",
                        "Sentinel Site Id",
                        "Sentinel Device Address",
                        "Draw Ignore Zones",
                      ]}
                    />
                    <TableBody>
                      <TableRow key={object.id}>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.sentinel_server
                            ? object.sentinel_server
                            : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.sentinel_site_id
                            ? object.sentinel_site_id
                            : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.sentinel_device_address
                            ? object.sentinel_device_address
                            : "--"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                          {object.draw_ignore_zones !== null ? (
                            <Switch
                              color="warning"
                              checked={object.draw_ignore_zones}
                              disabled={true}
                            />
                          ) : (
                            "--"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          );
        })}
    </div>
  );
};
