export const handleLevel3IndexClick = async (
  breadcrumb_info: any,
  navigate: any
) => {
  if (breadcrumb_info[2].id !== Number(localStorage.clickedId)) {
    localStorage.setItem("sitesTablePage", "0");
  }
  localStorage.setItem(
    "storedSelectedIndex",
    JSON.stringify({
      level1: "",
      level2: "",
      level3: "",
      level4: "",
      level5: "",
      level6: "",
    })
  );
  localStorage.setItem("siteAboutScrollTop", "0");
  localStorage.setItem("expandedObjectSaved", "false");
  localStorage.setItem("navigatedFromSitesAboutPage", "true");
  localStorage.setItem("clickedId", JSON.stringify(breadcrumb_info[2].id));
  localStorage.setItem("level1Id", JSON.stringify(breadcrumb_info[0].id));
  localStorage.setItem("level2Id", JSON.stringify(breadcrumb_info[1].id));
  localStorage.setItem("level3Id", JSON.stringify(breadcrumb_info[2].id));
  localStorage.setItem("level3Click", "true");

  navigate("/sites");
};
