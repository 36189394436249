import React from "react";
import Table from "@mui/material/Table";
import { TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const SMTPTab = () => {
  const SMTPInstructions = JSON.parse(localStorage.SMTPInstructions);
  return (
    <div className="bg-white font-mulish">
      <div className="space-y-3 space-x-6">
        <h2 className="indent-6 font-bold">SMTP Camera Configuration</h2>
        <h3 className="font-bold">
          Follow These Directions to Configure Each of your Cameras
        </h3>
        <p>
          1. Ensure that Motion Detection is activated on the device. This is
          usually found under “Analytics” or a similar option.
        </p>
        <p>
          2. Adjust Motion Detection sensitivity and masking as appropriate.
        </p>
        <p>
          3. Navigate to SMTP/Email settings on the device. This is usually
          found under “Event,” “FTP/Email,” “Network,” “Email,” or similar
          options.
        </p>
        <p>
          4. Under Email/SMTP configuration on the device, input the following
          information:{" "}
          <ul className="list-disc list-inside">
            <li>
              Server address: <strong>{SMTPInstructions.server_address}</strong>
            </li>
            <li>
              Port: <strong>{SMTPInstructions.server_port}</strong>
            </li>
          </ul>
          <h4>If you can specify a different sender per camera</h4>
          <ol>
            <li>
              Subject: <strong>Actuate</strong>
            </li>
            <li>
              Per camera:
              <Table sx={{ width: 500, fontFamily: "Mulish" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Camera</TableCell>
                    <TableCell>Sender / Recipient</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {SMTPInstructions.cameras.map(
                    (object: { camera_name: string; recipient: string }) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell>{object.camera_name}</TableCell>
                            <TableCell>{object.recipient}</TableCell>
                          </TableRow>
                        </>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </li>
          </ol>
          <h4>If you can specify only one sender for the entire site</h4>
          <ol>
            <li>
              Subject: <strong>Actuate</strong>
            </li>
            <li>
              Sender / Recipient: <strong>{SMTPInstructions.recipient}</strong>
            </li>
          </ol>
        </p>
        <p>
          5. Leave authentication blank or select “none” if it is not required
          by the device. If it is, please enter:{" "}
          <ol>
            <li>
              Username: <strong>actuate</strong>
            </li>
            <li>
              Password: <strong>actuate</strong>
            </li>
          </ol>
        </p>
        <p>
          6. If there is an option to “include attachment,” select that option.{" "}
          <ol>
            <li>
              NOTE: Clicking “TEST” on the Email/SMTP page may not work at this
              time. If you&apos;re having issues come back and click “TEST” once
              you
              <br></br>
              have fully created the site within Actuate to ensure the device is
              working with Actuate.
            </li>
          </ol>
        </p>
        <p>
          {" "}
          7. Go into Event Setup or similar on your device, and ensure that
          motion detection is selected, and that it routes alerts to “Email” or
          SMTP”{" "}
          <ol>
            <li>
              Here is an example of what that looks like on a Wisenet camera:
              <img
                src="https://admin.actuateui.net/static/onboarding/smtp.png"
                alt="Wisenet SMTP"
              ></img>
            </li>
          </ol>
        </p>
        <p>
          Now SMTP setup is complete. Send a test message or wait a few minutes
          to confirm that images are being sent to Actuate and validate{" "}
          <br></br>
          that on the “Customer Sites” page.
        </p>
      </div>
    </div>
  );
};
