import React from "react";
import { createApi } from "./createApi";
import { NavigateFunction } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "./universalAPIErrorHandler";

export const handleSetPermissions = (
  navigate: NavigateFunction,
  setPermissions: any
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleSetPermissions(navigate, setPermissions);
  };

  AxiosUI.get(`user/info/`).then(
    (response: AxiosResponse) => {
      const responsePermissions = response.data.permission;

      setPermissions({
        arm_disarm: responsePermissions.arm_disarm,
        add_cameras: responsePermissions.add_cameras,
        edit_cameras: responsePermissions.edit_cameras,
        delete_cameras: responsePermissions.delete_cameras,
        manage_users: responsePermissions.manage_users,
      });
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
