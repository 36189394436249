import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosResponse } from "axios";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AddGroupUserStepTwo } from "../AddGroupUserStepTwo";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const handleSubmitNewGroupUser = (
  newUserInfo: any,
  setVisibleScreen: any,
  setAddGroupUserScreenOpen: any,
  setUserNameString: Dispatcher<string>,
  setAlertState: any,
  groupUsersSearchString: string,
  activeData: any,
  setActiveData: any,
  setNewUserInfo: any,
  setErrorStateMessage: any,
  setSubmitErrorState: any,
  setActiveStep: any,
  navigate: NavigateFunction
) => {
  const retryFunction = () => {
    handleSubmitNewGroupUser(
      newUserInfo,
      setVisibleScreen,
      setAddGroupUserScreenOpen,
      setUserNameString,
      setAlertState,
      groupUsersSearchString,
      activeData,
      setActiveData,
      setNewUserInfo,
      setErrorStateMessage,
      setSubmitErrorState,
      setActiveStep,
      navigate
    );
  };

  const AxiosUI = createApi(``);

  AxiosUI.post(`group_user/`, newUserInfo).then(
    (response: AxiosResponse) => {
      if (
        groupUsersSearchString === "" ||
        response.data.user.username.includes(groupUsersSearchString)
      ) {
        setActiveData([...activeData, response.data]);
      }
      setUserNameString(response.data.user.username);
      setAlertState((previousState: any) => {
        return {
          ...previousState,
          updateUserSuccess: false,
          deleteUserSuccess: false,
          addUserSuccess: true,
          emailSent: false,
          noData: false,
        };
      });
      setAddGroupUserScreenOpen(false);
    },
    (reason: any) => {
      if (reason.response.data) {
        if (reason.response.data.email) {
          alert(reason.response.data.email[0]);
        }
        if (reason.response.data.clips) {
          if (
            reason.response.data.clips[0].includes(
              `does not have any sites that process clips. Either uncheck clips or select a different group`
            )
          ) {
            setVisibleScreen(
              <AddGroupUserStepTwo
                newUserInfo={newUserInfo}
                setNewUserInfo={setNewUserInfo}
                setVisibleScreen={setVisibleScreen}
                setAddGroupUserScreenOpen={setAddGroupUserScreenOpen}
                setUserNameString={setUserNameString}
                setAlertState={setAlertState}
                groupUsersSearchString={groupUsersSearchString}
                activeData={activeData}
                setActiveData={setActiveData}
                setErrorStateMessage={setErrorStateMessage}
                setSubmitErrorState={setSubmitErrorState}
                setActiveStep={setActiveStep}
                navigate={navigate}
              />
            );
            alert(reason.response.data.clips[0]);
          }
        } else if (reason.response.data.detail === "Token is expired") {
          getRefreshToken(navigate, retryFunction);
        } else {
          alert("Error adding new user, please try again");
          setAddGroupUserScreenOpen(false);
          console.log(`${reason}`);
        }
      } else {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Error adding new user, please try again");
          setAddGroupUserScreenOpen(false);
        });
      }
    }
  );
};
