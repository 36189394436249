import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AlertState, Dispatcher } from "./siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";
import { WebhooksTab } from "../WebhooksTab";

export const handlePatchWebhooksData = (
  webhooksInfo: any,
  setWebhooksInfo: any,
  webhooksPatchObject: any,
  setWebhooksPatchObject: any,
  setWebhooksChangesMade: any,
  setActiveTab: any,
  setAlertState: any,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setEditMode: Dispatcher<boolean>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handlePatchWebhooksData(
      webhooksInfo,
      setWebhooksInfo,
      webhooksPatchObject,
      setWebhooksPatchObject,
      setWebhooksChangesMade,
      setActiveTab,
      setAlertState,
      setBackdropLoadingScreenOn,
      setEditMode,
      navigate
    );
  };

  AxiosUI.patch(
    `/webhook_customer/${webhooksInfo[0].id}/`,
    webhooksPatchObject
  ).then(
    (response: AxiosResponse) => {
      setWebhooksInfo([response.data]);
      setWebhooksPatchObject({
        customer: getNumbersAfterAboutSite(location.href),
        method: "GET",
        messsage_body: response.data.messsage_body,
      });
      setWebhooksChangesMade(false);
      setEditMode(false);
      setAlertState((previousState: AlertState) => {
        return { ...previousState, changeSuccess: true };
      });
      setActiveTab(<WebhooksTab webhooksInfo={[response.data]} />);
      setBackdropLoadingScreenOn(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setWebhooksPatchObject({
          customer: getNumbersAfterAboutSite(location.href),
          method: "GET",
          messsage_body: webhooksInfo[0].message_body,
        });
        setWebhooksChangesMade(false);
      });
      setAlertState((previousState: any) => {
        return { ...previousState, webhookChangesFail: true };
      });
      setActiveTab(<WebhooksTab webhooksInfo={webhooksInfo} />);
      setBackdropLoadingScreenOn(false);
    }
  );
};
