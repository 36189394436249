import React from "react";

export const getFullArray = (conversionArray: any) => {
  const totalsArray: (
    | number
    | {
        [x: string]: string | number;
        name: string;
        abreviated_name: string;
      }
  )[][] = [];

  conversionArray.forEach((obj: any) => {
    let total = 0;
    for (const key in obj) {
      if (key !== "name" && key !== "abreviated_name") {
        total += Number(obj[key]);
      }
    }
    totalsArray.push([total, obj]);
  });

  type LocalFunction = (a: any, b: any) => number;

  const sortingFucntion: LocalFunction = (a, b) => {
    return b[0] - a[0];
  };

  totalsArray.sort(sortingFucntion);

  const finalArray: any[] = [];

  totalsArray.forEach((array: any[]) => {
    finalArray.push(array[1]);
  });

  return finalArray;
};
