import React from "react";
import { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";
import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AlertData } from "./alertsInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const addUnresolvedNotes = (
  selectedRow: any,
  noteText: string,
  setEditMode: Dispatcher<boolean>,
  alertData: any,
  setAlertData: Dispatcher<any>,
  setSelectedRow: Dispatcher<any>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createMonitoringAPICall(``);

  const postedAlert = {
    approx_capture_timestamp: selectedRow.approx_capture_timestamp,
    window_id: selectedRow.window_id,
    unresolved_notes: noteText,
  };

  const retryFunction = () => {
    addUnresolvedNotes(
      selectedRow,
      noteText,
      setEditMode,
      alertData,
      setAlertData,
      setSelectedRow,
      navigate
    );
  };

  AxiosUI.post(`alert/unresolved/`, postedAlert).then(
    () => {
      const newSelectedRow = selectedRow;
      const newAlertData = alertData;
      newAlertData.forEach((object: AlertData, index: number) => {
        if (
          newSelectedRow.approx_capture_timestamp ===
          object.approx_capture_timestamp
        ) {
          newAlertData[index].unresolved_notes = noteText;
        }
      });
      setAlertData(newAlertData);
      localStorage.setItem("selectedRowAlerts", JSON.stringify(newSelectedRow));
      newSelectedRow.unresolved_notes = noteText;
      setSelectedRow(newSelectedRow);
      setEditMode(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error saving note, please try again");
      });
    }
  );
};
