import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const NoAutoAddCamerasDialog = ({
  noAutoAddCamerasDialogOpen,
  setNoAutoAddCamerasDialogOpen,
}: {
  noAutoAddCamerasDialogOpen: boolean;
  setNoAutoAddCamerasDialogOpen: Dispatcher<boolean>;
}) => {
  return (
    <Dialog
      open={noAutoAddCamerasDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"No Auto-Add Cameras Found"}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Check that information in VMS settings is accurate. Then try again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setNoAutoAddCamerasDialogOpen(false);
          }}
          text="OK"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
