import React from "react";
import { FormLabel, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { Field } from "formik";

export const RadioFieldType = ({
  config,
  formik,
}: {
  config: any;
  formik: any;
}) => (
  <div key={config.id}>
    <FormLabel id={config.label}>{config.label}</FormLabel>
    <RadioGroup
      row
      aria-labelledby={`row-radio-buttons-${config.label}`}
      name={config.field}
      onChange={(item) => {
        formik.values[config.field] = item.target.value;
      }}
      sx={{ width: 350 }}
    >
      <Field
        type="radio"
        name={config.field}
        value={config.option1}
        label={config.option1}
        control={<Radio />}
        as={RadioComponent}
      />
      <Field
        type="radio"
        name={config.field}
        value={config.option2}
        label={config.option2}
        control={<Radio />}
        as={RadioComponent}
      />
    </RadioGroup>
  </div>
);

const RadioComponent = (props: any) => <FormControlLabel {...props} />;
