import React from "react";
import { Alert } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const EditCameraAlerts = ({
  editCameraAlerts,
  setEditCameraAlerts,
  cameraName,
}: {
  editCameraAlerts: any;
  setEditCameraAlerts: Dispatcher<any>;
  cameraName: string;
}) => {
  return (
    <div>
      {editCameraAlerts.triggerMotionSuccess && (
        <Alert
          onClose={() => {
            setEditCameraAlerts((previousState: any) => {
              return { ...previousState, triggerMotionSuccess: false };
            });
          }}
        >
          <p className="font-mulish font-bold text-[16px]">
            {`Motion event sent successfully to ${cameraName}`}
          </p>
        </Alert>
      )}
      {editCameraAlerts.triggerMotionFail && (
        <Alert
          severity="warning"
          onClose={() => {
            setEditCameraAlerts((previousState: any) => {
              return { ...previousState, triggerMotionFail: false };
            });
          }}
        >
          <p className="font-mulish font-bold text-[16px]">
            {`Motion event failed to send, please try again`}
          </p>
        </Alert>
      )}
    </div>
  );
};
