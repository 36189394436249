import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { AxiosError, AxiosResponse } from "axios";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getAlertMP4 = (
  selectedRow: any,
  navigate: NavigateFunction,
  setMP4URL: Dispatcher<string>,
  setVideoClipLoading: Dispatcher<boolean>
) => {
  const AxiosUI = createMonitoringAPICall(``);

  const retryFunction = () => {
    getAlertMP4(selectedRow, navigate, setMP4URL, setVideoClipLoading);
  };

  setMP4URL("");

  if (selectedRow) {
    setVideoClipLoading(true);
    AxiosUI.get(
      `alert/clip/?approx_capture_timestamp=${selectedRow.approx_capture_timestamp}&window_id=${selectedRow.window_id}`
    ).then(
      (response: AxiosResponse) => {
        setMP4URL(response.data);
        setVideoClipLoading(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setTimeout(() => {
            setVideoClipLoading(false);
          }, 2000);
        });
      }
    );
  }
};
