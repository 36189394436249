import React from "react";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { VmsTab } from "../VmsTab";
import { SchedulesTab } from "../SchedulesTab";
import { SMTPTab } from "../SMTPTab";
import { RecordingServerTab } from "../RecordingServerTab";
import { CameraHealthTab } from "../CameraHealthTab";
import { WebhooksTab } from "../WebhooksTab";

export const handleCancelButtonClick = (
  setEditMode: any,
  clickedTab: any,
  setActiveTab: any,
  vmsVariables: any,
  allSiteSchedules: any,
  siteInfo: any,
  setDeleteScheduleDialogOpen: any,
  recordingServerData: any,
  setRecordingServerPatchObject: any,
  healthCheckData: any,
  webhooksInfo: any
) => {
  setEditMode(false);
  if (siteInfo.integration_type_name === "milestone") {
    setRecordingServerPatchObject(
      JSON.parse(localStorage.recordingServerBackup)
    );
  }
  if (clickedTab === "one") {
    setActiveTab(<GeneralInfoTab siteInfo={siteInfo} />);
  } else if (clickedTab === "two") {
    setActiveTab(<VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />);
  } else if (clickedTab === "three") {
    setActiveTab(
      <SchedulesTab
        allSiteSchedules={allSiteSchedules}
        siteInfo={siteInfo}
        setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
      />
    );
  } else if (clickedTab === "four") {
    setActiveTab(<SMTPTab />);
  } else if (clickedTab === "five") {
    setActiveTab(
      <RecordingServerTab
        recordingServerData={JSON.parse(localStorage.recordingServerBackup)}
      />
    );
  } else if (clickedTab === "six") {
    setActiveTab(
      <CameraHealthTab healthCheckData={healthCheckData} siteInfo={siteInfo} />
    );
  } else if (clickedTab === "seven") {
    setActiveTab(<WebhooksTab webhooksInfo={webhooksInfo} />);
  }
};
