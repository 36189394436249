import React from "react";
import { NavigateFunction } from "react-router-dom";
import { createApi } from "../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";

export const handleEmailCameraCheckReport = (
  inputEmailAddress: string,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleEmailCameraCheckReport(inputEmailAddress, navigate);
  };

  AxiosUI.post(`proactive_alerting/${localStorage.cameraChecksiteId}/send/`, {
    email: inputEmailAddress,
  }).then(
    (response: AxiosResponse) => {
      alert(response.data[0].message);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error sending email, please try again");
      });
    }
  );
};
